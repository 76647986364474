<template>
<div>
	<b-modal id="content-attendance-modal" @change="$emit('updateModalState', false)" :visible="visible" hide-header
		centered content-class="content-attendance-modal" size="xl" body-class="modal-body" hide-footer
		modal-class="custom-modal" no-close-on-backdrop>
		<button @click="$emit('updateModalState', false)" class="close-button" role="button">
			<img src="@/assets/images/cancel-icon.svg" />
		</button>

		<div class="body-container">
			<h1 class="add-content-title">{{ contentData.title }}</h1>
			<div class="mb-3 text-black d-flex flex-column">
				<div class="d-flex gap-5 align-items-center mb-5">
					<div class="flex-grow-1">
						<span class="text-bold">Content Attendance</span>
						<div class="text-muted" v-if="contentData.extraProperties">
							<span v-if="contentData.extraProperties.SizeInMB">
								<strong>Size: </strong>{{ contentData.extraProperties.SizeInMB }} MB | 
							</span>
							<span v-if="contentData.extraProperties.Duration">
								<strong>Duration: </strong
								>{{
									contentData.extraProperties.Duration
										? (contentData.extraProperties.Duration / 60).toFixed(1)
										: ""
								}}
								Minutes
							</span>
						</div>
					</div>
					
					<div class="searchbar flex-grow-1">
						<input type="search" autocomplete="off" id="variant-search" placeholder="Search students"
							class="form-input" v-model="searchText" />
						<a role="button"><img class="search-icon" src="@/assets/images/search-icon.svg" /></a>
					</div>
				</div>
			</div>
			<div v-if="!isShowDetail">
				<div class="d-flex mb-4 gap-3 ms-auto">
					<button
					  @click="setViewStatus(null)"
					  :class="['filter-user-type-btn',{active:viewStatus==null}]"
					>
					  All
					</button>
					<button
					 
					@click="setViewStatus('view')"
					  :class="[
						'filter-user-type-btn',
						{active:viewStatus && viewStatus.toLowerCase()=='view'}
					  ]"
					>
					  Viewed
					</button>
					<button
					@click="setViewStatus('noview')"
					  :class="[
						'filter-user-type-btn',
						{active:viewStatus && viewStatus.toLowerCase()=='noview'}
					  ]"
					>
					  Not Viewed
					</button>
				  </div>
				  <div class="table-container">
					<table class="table table-hover table-borderless">
					  <thead>
						<tr>
						  <th style="width: 2%; text-align: center;">#</th>
						  <th style="width: 35%;">Name</th>
						  <th style="width: 20%">Enrollment Status</th>   
						  <th style="width: 20%">Views</th>
						  <th style="width: 10%"></th>
						</tr>
						<!-- <th style="width: 5%"></th> -->
					  </thead>
					  <tbody v-if="!students">
						<tr>
							<td colspan="5"><center>Loading...</center></td>
						</tr>
					  </tbody>
					  <tbody v-else-if="searchFiltered(students).length>0">
						<tr v-for="(student,index) in searchFiltered(students)" @click="viewUser(student)">
							<td><center>{{(index+1)}}</center></td>
							<td>{{student.firstName}} {{student.lastName}}<br/><small>{{student.email}} | {{student.phone}}</small></td>
							<td>
								<div
								:class="[
								  'status-tag',
								  {
									warning: student.enrollmentStatus && student.enrollmentStatus.toLowerCase() != `allowed`,
									success: student.enrollmentStatus && student.enrollmentStatus.toLowerCase() == `allowed`,
								  },
								]"
							  >
								{{ student.enrollmentStatus }} 
							  </div>
							</td>
							<td >
								<div v-if=" !(student.viewsCount && student.viewsCount>0)"
								:class="[
								  'status-tag',
								  {
									warning: !(student.viewsCount && student.viewsCount>0),
									success: student.viewsCount && student.viewsCount>0,
								  },
								]"
							  >
								{{ student.viewsCount && student.viewsCount>0?(student.viewsCount +" VIEWS"):"NO VIEWS" }} 
							  </div>
							  <div v-else >
								{{student.viewsCount }}
							  </div>
							</td>
							<td @click.stop>
								<button @click="showDetail(student)" class="btn btn-outline" v-if="student.viewsCount && student.viewsCount>0">
									View Detail
								</button>
							</td>
						</tr>
					  </tbody>
					  <tbody v-else-if="searchFiltered(students).length<0">
						<tr>
							<td colspan="4"><center>No Data Found</center></td>
						</tr>
					  </tbody>
					</table>  
			</div></div>
			<div v-else-if="isShowDetail && selectedDetail">
				<div style="display: flex;">
				<div class="flex-grow-1" style="width: 50%;">
					<span class="text-bold">{{selectedDetail.firstName}} {{selectedDetail.lastName}}</span>
					<div class="text-muted" >
						<span>
							{{ selectedDetail.email }} | {{ selectedDetail.phone }} 
						</span>
						
					</div>
				</div>
				<div class="flex-grow-1" >
					<button class="step-btn" style="float: right;" @click="backToList">
						Back To List
					</button>
				</div>
			</div>
				<div class="table-container">
					<table class="table table-hover table-borderless">
					  <thead>
						<tr>
						  <th style="width: 2%; text-align: center;">#</th>
						  <th style="width: 10%">Viewed At</th>					
						  <th style="width: 35%;">Device</th>
						  <!-- <th style="width: 15%">Platform</th>   
						  <th style="width: 10%">IP</th> -->
						</tr>
						<!-- <th style="width: 5%"></th> -->
					  </thead>
					  <tbody  >
						<tr v-if="!selectedDetailViewLogs">
							<td colspan="5"><center>Loading...</center></td>
						</tr>
						<tr v-else-if=" selectedDetailViewLogs.length>0" v-for="(viewLog,index) in selectedDetailViewLogs">
							<td ><center>{{index+1}}</center></td>
							<td>{{timeParser(viewLog.viewedAt)}}</td>
							<td><span v-if="viewLog.device && viewLog.device.deviceToken">{{ viewLog.device.deviceToken }}</span><center v-else>-</center></td>
							<!-- <td><span v-if="viewLog.device && viewLog.device.platform">{{ viewLog.device.platform }}</span><span v-else>-</span></td>
							<td><span v-if="viewLog.device && viewLog.device.ip">{{ viewLog.device.ip }}</span><span v-else>-</span></td> -->
							
						</tr>
					  </tbody>
					
					</table>  
			</div>
			</div>
			<div class="step-btn-container">
				<button @click="closeModal" role="button" class="skip-step-btn">
					cancel
				</button>
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"><i
						class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<!-- <button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }} <i class="fas fa-long-arrow-alt-right"></i>
					<img src="@/assets/images/arrow-right.svg" />
				</button> -->
				<button class="step-btn" @click="refresh">
					<b-spinner v-if="loading" small class="me-2"></b-spinner>
					<i v-else class="far fa-redo me-2"></i> Refresh
				</button>
				<button
		       					    @click="exportAttendanceData"
									:disabled="exportLoading"
		       					    :class="['step-btn','export-excel-btn']"
		       					>
		       					  Export As CSV
		       					</button>
			</div>
		</div>
	</b-modal>
	
	<ViewEditUserModal
	:user="userToViewEdit"
	:openTab="0"
	@updateModalState="toggleViewEditUser"
	:showModal="showUserDetailModal"
	viewOnly
	/>
</div>	
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../../helpers/FormatHelpers";
import ViewEditUserModal from "../Users/ViewEditUserModal.vue";

export default {
	name: "ContentAttendanceModal",
	data() {
		return {
			contentViewedStudents: [],
			searchText: "",
			viewStatus:null,
			students:null,
			isIncludeInActiveStudents: false,
			exportLoading: false,
			userToViewEdit: null,
			showUserDetailModal: false,
			isShowDetail:false,
			selectedDetail:null,
			selectedDetailViewLogs:null
			
		};
	},
	props: { showModal: Boolean, productVariantId: String, contentData: Object },
	computed: {
		...mapState("user", ["loading"]),
		...mapState("user", ["usersByProductVariant"]),
		visible() {
			return this.showModal;
		},
		showPrev() { },
		stepButtonContent() {
			return "Save";
		},
		disableNext() { }
	},	
	components: { ViewEditUserModal },
	methods: {
		...mapActions("user", ["fetchUsersByProductVariant"]),
		...mapActions("content", [
			"fetchContentAttendance",
			"fetchContentAttendanceDetail",
			"exportContentAttendanceReport"
		]),
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		prev() { },
		next() { },
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		exportAttendanceData() {
			this.exportLoading = true;
			this.exportContentAttendanceReport(this.contentData.id).then((result) => {

				if (result && result.url) {
					const link = document.createElement('a');
					link.href = result.url;
					link.setAttribute('download', result.name ? result.name : `content_attendance_report.csv`);
					document.body.appendChild(link);
					this.exportLoading = false;
					link.click();
					link.parentNode.removeChild(link);
					// clean up Url
					window.URL.revokeObjectURL(result);
				}
			}).catch((err) => {
				console.log({ err });

				this.exportLoading = false;
			});
		},
		timeParser(date) {
			return formatter.getInputDateFormat(date) +' '+ formatter.getTimeIn12Hours(date);
		},
		async refresh() {
			
			this.selectedDetail=null;
			this.isShowDetail=false;
			await this.fetchContentAttendance({contentID:this.contentData.id}).then((res) => {
				this.contentViewedStudents=res;
			});
			this.fetchUsersByProductVariant(this.productVariantId).then((res) => {
				this.students=  this.usersByProductVariant[
						this.productVariantId
					].map((user) => {
						 var attendance = this.contentViewedStudents.find(x=>x.user.id==user.id);
						 if(attendance)
						 {
							user.viewsCount=attendance.viewsCount;
						 }
						 return user;
					});
				console.log(res);
			});
		},
		searchFiltered(list) {
			return list.filter(x=>
			(!this.searchText || `${x.firstName} ${x.lastName} ${x.email} ${x.phone}`.toLowerCase().indexOf(this.searchText)>-1)
			&& (this.viewStatus==null || 
			(this.viewStatus.toLowerCase()=="view" && x.viewsCount && x.viewsCount>0) ||  
			(this.viewStatus.toLowerCase()=='noview' && !x.viewsCount) )
			);
		},
		setViewStatus(status){
			this.viewStatus=status;
		},
		backToList(){
			this.selectedDetail=null;
			this.isShowDetail=false;
		},
		viewUser(user){
			this.userToViewEdit=user;
			this.showUserDetailModal = true;
			
		},
		toggleViewEditUser() {
			this.showUserDetailModal = !this.showUserDetailModal;
			if (!this.showUserDetailModal) {
				this.userToViewEdit.user = null;
			}
		},
		showDetail(selected)
		{
			this.selectedDetailViewLogs=null;
			this.selectedDetail=selected;
			this.isShowDetail=true;
			
			this.fetchContentAttendanceDetail({contentID:this.contentData.id,userID:this.selectedDetail.id}).then((data)=>{
				
				if(this.selectedDetail && data.length>0){
					this.selectedDetailViewLogs=data[0].viewLogs;
				}
			});
		}
	},
	watch: {
		visible: async function () {
			if (this.visible) {
				this.refresh();

			}
		},
	},
	created() { },
};
</script>

<style scoped>
.content-attendance-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.content-attendance-modal .body-container {
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.content-attendance-modal .add-btn {
	position: absolute;
	top: 2rem;
	right: 4.5rem;
	z-index: 99 !important;
}

.attendance-status-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 1rem;
}

.attendance-present h5,
.attendance-online h5,
.attendance-absent h5,
.attendance-unpaid h5 {
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #dddddd;
}

.attendance-absent,
.attendance-unpaid {
	margin-top: 1rem;
}

.attendance-unpaid .attendance-list-container {
	height: 25vh !important;
	overflow-y: auto !important;
}

/* {
    min-height: 25vh;
} */

.content-attendance-modal .close-button {
	z-index: 99 !important;
}

.content-attendance-modal .no-content {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 25vh !important;
}
.export-excel-btn {
	background: var(--primary-color);
	color: white;
  }

  .export-excel-btn:hover {
	background: white !important;
	color: black !important;
	border: 1px solid var(--primary-color) !important;
  }

  .table-container{
	overflow: auto;
	height: 50vh;
  }
</style>
