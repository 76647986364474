<template>
	<div id="login-register">
		<!-- <div class="width-100">
			<a role="button" class="form-control login-btn-social">
				<img src="https://img.icons8.com/color/24/000000/google-logo.png" />
				Continue with Google
			</a>
			<a role="button" class="form-control login-btn-social">
				<img src="https://img.icons8.com/color/24/000000/facebook-new.png" />
				Continue with Facebook
			</a>
		</div>
		<div class="or-divider">
			<span>or</span>
		</div> -->
		<div class="width-100">
			<form @submit="handleSubmit">
				<div class="form-group position-relative">
					<i class="input-icon-left far fa-user"></i>
					<input
						v-model="firstName"
						class="input-w-icon form-control"
						type="text"
						name="firstName"
						id="firstName"
						placeholder="First Name (required)"
						required
						autofocus
						tabindex="10"
						@change="setUsername"
					/>
				</div>

				<div class="form-group position-relative">
					<i class="input-icon-left far fa-user"></i>
					<input
						v-model="lastName"
						class="input-w-icon form-control"
						type="text"
						name="lastName"
						id="lastName"
						placeholder="Last Name (required)"
						required
						autofocus
						tabindex="12"
						@change="setUsername"
					/>
				</div>

				<div class="form-group">
					<vue-tel-input
						:class="`form-control ${isPhoneError && showError ? 'error' : ''}`"
						defaultCountry="PK"
						:inputOptions="telInputOptions"
						validCharactersOnly
						v-model="mobile"
						@input="validate"
						@blur="showError = true"
						@focus="showError = false"
						:autoFormat="true"
						mode="national"
						invalidMsg="Please enter a valid phone number"
					></vue-tel-input>
				</div>
				<p
					v-if="isPhoneError && showError"
					id="phone-error"
					class="text-danger text-500 text-sm m-2"
				>
					Invalid Phone Number
				</p>

				<div class="form-group">
					<i class="input-icon-left fas fa-envelope"></i>
					<input
						v-model="email"
						class="input-w-icon form-control"
						type="email"
						name="email"
						id="email"
						placeholder="Email (required)"
						required
						tabindex="20"
					/>
				</div>

				<!-- <div class="form-group">
					<i class="input-icon-left far fa-user-circle"></i>
					<input
						v-model="username"
						class="input-w-icon form-control"
						type="text"
						name="username"
						id="username"
						placeholder="Username (required)"
						required
						tabindex="30"
					/>
				</div> -->

				<div class="form-group">
					<i class="input-icon-left fas fa-unlock-alt"></i>
					<input v-model="password"
						:class="['input-w-icon form-control', { error: isPasswordError },{valid:passwordValidation.valid} ]"
						type="password" name="password" id="password" placeholder="Password (required)" required tabindex="40"
						autocomplete="on" />
				
					<transition name="hint" appear>
						<ul v-if='password.length>0 && passwordValidation.errors.length > 0' class="form-control bg-danger-light error-box" style="height: auto;">
							<li v-for='error in rules'>
								<span v-if="passwordValidation.errors.find(x=>x.regex==error.regex)!=null" class="text-danger">
									<i class="fa fa-times"></i>&nbsp;&nbsp;&nbsp;{{error.message}}
								</span>
								<span v-else class="text-success">
									<i class="fa fa-check"></i>&nbsp;&nbsp;{{error.message}}
								</span>
							</li>
							
						</ul>
					</transition>
				</div>

				<div class="form-group">
					<i class="input-icon-left fas fa-unlock-alt"></i>
					<input
						v-model="confirmPassword"
						:class="['input-w-icon form-control', { error: isPasswordError }]"
						type="password"
						name="confirmPassword"
						id="confirmPassword"
						placeholder="Confirm Password (required)"
						required
						tabindex="50"
						autocomplete="on"
					/>
				</div>
				<p id="password-error" class="text-danger text-500 text-sm m-2"></p>

				<div class="form-group">
					<i class="input-icon-left fas fa-map-marker-alt"></i>
					<input
						class="input-w-icon form-control"
						v-model="city"
						type="text"
						name="city"
						id="city"
						placeholder="City (required)"
						tabindex="60"
					/>
				</div>
				<div
					class="form-group"
					v-for="(field, index) in fieldsParsed"
					:key="field.Key"
				>
					<DynamicFormInput
						:inputFieldOptions="field"
						:hasIcon="true"
						@inputValue="
							(value) => {
								extraproperties.CustomFields[field.Key] = value;
							}
						"
						:required="true"
						:showLabel="false"
						:tabIndex="61 + index"
					/>
				</div>
				<div class="row" id="remember">
					<div class="col-1">
						<input
							v-model="acceptPolicy"
							type="checkbox"
							name="remember"
							id="termsCheck"
							class="form-check-input"
							required
							tabindex="80"
						/>
					</div>
					<div class="col-11">
						<label class="text-sm" for="termsCheck"
							>I agree to the
							<!-- {{ this.institute }}'s -->
							<a
								class="primary"
								type="button"
								@click="toggleModal($event, 'termsOfUse')"
								tabindex="81"
							>
								Terms of use
							</a>
							and
							<a
								class="primary"
								type="button"
								@click="toggleModal($event, 'privacyPolicy')"
								tabindex="82"
							>
								Privacy Policy</a
							>.</label
						>
					</div>
				</div>

				<button
					class="login-register-btn form-control mt-4"
					type="submit"
					:disabled="loading"
					tabindex="90"
				>
					<b-spinner v-if="loading" small> </b-spinner>
					Signup
				</button>
			</form>
		</div>
		<b-modal
			@change="toggleModal($event, 'termsOfUse')"
			:visible="termsOfUseModal"
			hide-header
			centered
			hide-footer
			modal-class="register-page-modal"
			size="xl"
		>
			<div class="body-container">
				<button
					@click="toggleModal($event, 'termsOfUse')"
					class="close-button"
					role="button"
				>
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
				<h1 class="add-content-title">Terms of Use</h1>
				<div
					v-if="systemParameters && systemParameters.TermsOfUseUrl"
					class="content"
				>
				<iframe :src="systemParameters.TermsOfUseUrl"></iframe>
				</div>
				<div v-else class="no-content">No Terms of Use found</div>
			</div>
		</b-modal>
		<b-modal
			@change="toggleModal($event, 'privacyPolicy')"
			:visible="privacyPolicyModal"
			hide-header
			centered
			hide-footer
			modal-class="register-page-modal"
			size="xl"
		>
			<div class="body-container">
				<button
					@click="toggleModal($event, 'privacyPolicy')"
					class="close-button"
					role="button"
				>
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
				<h1 class="add-content-title">Privacy Policy</h1>
				<div
					v-if="systemParameters && systemParameters.PrivacyPolicyUrl"
					class="content"
				>
				
				<iframe :src="systemParameters.PrivacyPolicyUrl"></iframe>
				</div>
				<div v-else class="no-content">No Privacy Policy found</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DynamicFormInput from "../Layout/DynamicFormInput.vue";

const getCountryISO3 = require("country-iso-2-to-3");
const telInputOptions = {
	autocomplete: "on",
	maxlength: 15,
	tabindex: 15,
	placeholder: "Phone Number (required)",
	mode: "national",
};

export default {
	name: "Register",
	data() {
		return {
			institute: "GoCBE Global",
			firstName: "",
			lastName: "",
			mobile: "",
			phone: "",
			email: "",
			username: "",
			password: "",
			confirmPassword: "",
			countryCode: "",
			city: "",
			extraproperties: { CustomFields: {} },
			acceptPolicy: false,
			telInputOptions,
			isPasswordError: false,
			privacyPolicyModal: false,
			termsOfUseModal: false,
			isPhoneError: false,
			showError: false,
			rules: [],
		};
	},
	components: {
		DynamicFormInput,
	},
	methods: {
		...mapActions("user", ["register", "login", "generateVerificationID"]),
		toggleModal(e, modal) {
			if (this[modal + "Modal"]) {
				this[modal + "Modal"] = !this[modal + "Modal"];
			} else {
				e.preventDefault();
				this[modal + "Modal"] = !this[modal + "Modal"];
			}
		},
		setUsername() {
			if (this.firstName && this.lastName)
				var length = 4,
					charset =
						"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
					randomCode = "";
			for (var i = 0, n = charset.length; i < length; ++i) {
				randomCode += charset.charAt(Math.floor(Math.random() * n));
			}
			this.username =
				this.firstName.trim().replace(/ +/g, "_").toLowerCase() +
				"_" +
				this.lastName.trim().replace(/ +/g, "_").toLowerCase() +
				"_" +
				randomCode;
		},

		async handleSubmit(e) {
			e.preventDefault();
			if (this.isPhoneError) {
				this.$toasted.error("Please enter a valid Phone Number", {
					theme: "outline",
					duration: 3000,
					icon: "exclamation-triangle",
					position: "bottom-center",
				});
				return;
			}
			this.username=this.username.trim();
			if(this.username.trim().indexOf(" ")>-1)
			{
				document.getElementById("password-error").innerText =
					"Username must not contain whitespaces";
				return;
			}
			if (this.passwordValidation.errors.length>0) {
				this.isPasswordError = true;
				document.getElementById("password-error").innerText =
					"Password must meet following requirements";
				return;
			}
			if (this.password !== this.confirmPassword) {
				this.isPasswordError = true;
				document.getElementById("password-error").innerText =
					"Password and Confirm Password do not match";
				return;
			} else {
				document.getElementById("password-error").innerText = "";
				this.isPasswordError = false;
			}
			var data = {
				firstName: this.firstName,
				lastName: this.lastName,
				mobile: this.phone,
				email: this.email,
				username: this.username,
				password: this.password,
				countryCode: this.countryCode,
				city: this.city,
				extraproperties: JSON.stringify(this.extraproperties),
			};
			// console.log(data);
			await this.register(data)
				.then((res) => {
					if (res) {
					}
					
					
                    
					this.login({
						username: this.username,
						password: this.password,
					})
						.then(() => {
							const user = JSON.parse(localStorage.getItem("user"));
							this.isError = false;
							this.errorText = "";
							this.$router.push(
								this.$route.query.nextUrl
									? this.$route.query.nextUrl
									: user.type.toLowerCase() == "management" ||
									  user.type.toLowerCase() == "trainer"
									? "/management/course"
									: "/user/dashboard"
							);
							this.$toasted.success(
								`Registration successful, Welcome ${user.firstName} ${user.lastName}!`,
								{
									theme: "outline",
									position: "bottom-center",
									duration: 3000,
								}
							);
							this.userCredentials.username = null;
            				this.userCredentials.password = null;
							// }
						})
						.catch(async (error) => {
							this.errorText = error.message;
							this.isError = true;

							if (error.errorCode != "102") {
								this.password = "";
							} else {
								this.showDevicesModal = true;
							}

							if (error.errorCode == "149") {
								this.userCredentials.username = data.username;
								this.userCredentials.password = data.password;

								var object = {
									email: error.args.email,
								};

								var res = await this.generateVerificationID(object);

								if (res.verificationID && error.args.email) {
									this.$toasted.success(`OTP has been sent to your Email`, {
										theme: "outline",
										position: "bottom-center",
										duration: 3000,
									});
									this.$router.push(
										`/account/user-verification?type=email&credentials=${error.args.email}&verificationId=${res.verificationID}`
									);
								}
							} else if (error.errorCode == "150") {
								this.userCredentials.username = data.username;
								this.userCredentials.password = data.password;

								var object = {
									phone: error.args.phone,
								};

								var res = await this.generateVerificationID(object);

								if (res.verificationID && error.args.phone) {
									this.$toasted.success(`OTP has been sent to your Phone number`, {
										theme: "outline",
										position: "bottom-center",
										duration: 3000,
									});
									this.$router.push(
										`/account/user-verification?type=phone&credentials=${error.args.phone}&verificationId=${res.verificationID}`
									);
								}
							}
							
						});
				})
				.catch((error) => {
					this.errorText = error.message;
					this.isError = true;
					this.$toasted.error(error.errorCode == 116 ? error.message + " with the same email or phone number" : error.message, {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						action: {
						text: "DISMISS",
						onClick: (e, toastObject) => {
							toastObject.goAway(0);
						},
					},
					});
				});
		},
		validate(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.country && obj.country.iso2) {
				this.countryCode = getCountryISO3(obj.country.iso2);
				this.phone = obj.number;
			}
			return false;
		},
	},
	computed: {
		...mapState("appState", [
			"systemParameters",
			"loading",
			"currentCountry",
			"currentCity",
		]),
		...mapState("user", ["userCredentials"]),

		fieldsParsed() {
			if (!this.loading && this.systemParameters.UserRegisterExtraFields) {
				return this.systemParameters?.UserRegisterExtraFields;
			}
			return [];
		},
		
		passwordValidation () {
			let errors = []
			for (let condition of this.rules) {
				if (!new RegExp(condition.regex).test(this.password)) {
					errors.push(condition)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
	},
	created() {
		this.countryCode = this.currentCountry;
		if (this.currentCity) this.city = this.currentCity;
		if (this.systemParameters && this.systemParameters.PasswordValidationRules) {
			this.rules = JSON.parse(this.systemParameters.PasswordValidationRules);
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "signup-page-loaded", data: null });
		});
	},
};
</script>

<style>
a.primary {
	color: var(--primary-color) !important;
}

.register-page-modal {
	font-size: 1rem !important;
}

.register-page-modal .body-container {
	padding: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.register-page-modal .body-container .content {
	max-height: 70vh;
	overflow-y: hidden;
	margin-right: -1rem;
	padding-right: 1rem;
}

.register-page-modal .body-container .close-button {
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 0 3px 6px #d5d5d529;
}

.close-button img {
	width: 1.5rem;
}

.form-control.error {
	border-color: var(--color-danger) !important;
	color: var(--color-danger) !important;
}
.error-box{
	
	color: var(--color-danger) !important;

	list-style: none;
}
.register-page-modal iframe{
	width: 100%;
	height: 75vh;
}
</style>
