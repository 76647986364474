<template>
	<!-- <div class="assign-btn-container">
		<button
			data-bs-toggle="dropdown"
			class="assign-trainer-btn dropdown-toggle"
			role="button"
			id="assign-trainer-btn"
		>
			assign
		</button> -->
	<div
		aria-labelledby="dropdownMenuLink"
		:id="`assign-trainer-${productVariantId}`"
		class="assign-trainer-dropdown dropdown-menu dropdown-menu-end"
	>
		<div class="dropdown-title-container">
			<h1 class="dropdown-title">Find your trainer</h1>
		</div>
		<div
			@click="
				(e) => {
					e.stopPropagation();
				}
			"
			id="content-container"
			class="content-container"
		>
			<input
				type="search"
				name="trainer-email"
				class="trainer-email-input"
				placeholder="Type name or email of trainer..."
				v-model="searchText"
				autocomplete="off"
			/>
			<div class="trainer-list-container">
				<ul class="all-trainers-list">
					<TrainerPermissionsListItem
						v-for="item in filteredTrainers"
						:key="item.id"
						:trainerData="item"
						@editPermissions="updatePermissions"
						:trainersList="trainersList"
						@checkboxToggle="checkboxToggle"
						:productVariantId="productVariantId"
					/>
				</ul>
			</div>
			<button
				@click="assignTrainersToContent"
				class="assign-trainer-btn in-dropdown"
				role="button"
			>
				{{ areTrainersAssigned ? "update" : "assign" }}
			</button>
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TrainerPermissionsListItem from "./TrainerPermissionsListItem.vue";
export default {
	name: "AssignTrainerToContentDropdown",
	props: {
		// showAssign: Boolean,
		productVariantId: String,
		assignedTrainers: Array,
		areTrainersAssigned: Boolean,
	},
	components: {
		TrainerPermissionsListItem,
	},
	data() {
		return {
			searchText: "",
			trainersList: [],
		};
	},
	computed: {
		...mapState("user", ["trainers"]),
		filteredTrainers() {
			if (this.trainers) {
				var filtered = this.trainers.filter((trainer) => {
					// console.log("trainer", trainer.firstName.includes("H"));
					if (
						trainer.firstName.toLowerCase().includes(this.searchText) ||
						trainer.lastName.toLowerCase().includes(this.searchText) ||
						trainer.email.toLowerCase().includes(this.searchText)
					) {
						return trainer;
					}
				});
				return filtered;
			}
		},
	},
	created() {
		if (!this.trainers) {
			this.fetchTrainersList().then(() => (this.allTrainers = this.trainers));
		}
		if (this.assignedTrainers && this.assignedTrainers.length > 0) {
			this.trainersList = this.assignedTrainers;
		}
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		checkboxToggle(e, item) {
			// console.log(e.target.checked, item);
			if (e.target.checked) {
				var trainerObject = {
					id: item.id,
					trainerID: item.id,
					firstName: item.firstName,
					lastName: item.lastName,
					IsShowInTrainerList: true,
					jobTitle: "Trainer",
					permissions: [],
					isActive: true,
					email: item.email,
				};
				console.log(e.target.checked, trainerObject);
				this.addTrainer(trainerObject);
			} else if (!e.target.checked) {
				this.removeTrainer(item.id);
			}
		},
		addTrainer(trainer) {
			// console.log(trainer);
			var found = this.trainersList.find(
				(item) => item.trainerID == trainer.trainerID
			);
			console.log(found);
			if (!found) {
				var list = this.trainersList;
				list.push(trainer);
				this.trainersList = list;
			}
			console.log(this.trainersList);
		},
		removeTrainer(id) {
			this.trainersList = this.trainersList.filter(
				(trainer) => trainer.trainerID != id
			);
		},
		updatePermissions(value, id) {
			// console.log(value, id);
			this.trainersList = this.trainersList.map((item) => {
				if (item.trainerID == id) {
					item.permissions = value;
					console.log(item.permissions);
				}
				return item;
			});
		},
		assignTrainersToContent() {
			this.$emit("assignTrainers", this.trainersList);
			document
				.getElementById(`assign-trainer-btn-${this.productVariantId}`)
				.click();
		},
	},
};
</script>

<style scoped>
.assign-trainer-btn {
	background: #ffffff;
	border: 1px solid #21bacf;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 0.375rem 0.625rem;
	color: #21bacf;
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	/* top: 0.875rem; */
}

.assign-trainer-btn.in-dropdown {
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	margin-left: auto;
	margin-top: 1rem;
	/* top: 0.875rem; */
}

.assign-trainer-dropdown {
	background: #ffffff;
	box-shadow: 0px 0px 30px rgba(138, 138, 138, 0.2);
	border-radius: 10px;
	padding: 1.5rem 1.75rem;
	border: none !important;
	width: 34.375rem;
	/* display: flex;
	flex-direction: column; */
}

.content-container {
	display: flex;
	flex-direction: column;
}

.dropdown-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1.125rem;
}

.dropdown-title {
	font-weight: 450;
	font-size: 1.625rem;
	line-height: 1.1;
	color: #000000;
	margin: 0;
}

.trainer-email-input {
	border: 0;
	border-bottom: 1.2px solid #b2b2b2;
	width: 100%;
	font-size: 1rem;
	outline: none;
	padding-bottom: 1.125rem;
}

.assigned-trainers-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.assigned-trainers-list li .form-group {
	display: flex;
	flex-direction: row !important;
	column-gap: 0.75rem;
	width: 100%;
	align-items: center;
}

.assigned-trainers-list li .form-group input,
.assigned-trainers-list li .form-group label {
	margin: 0;
}

.assigned-trainers-list li .form-group .multiselect {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 5px;
	width: 12.5rem;
	margin-left: auto;
	/* height: 2.25rem; */
}

.assigned-trainers-list li .form-group .multiselect__tags {
	border: 0;
}

.assigned-trainers-list li .form-group input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.assigned-trainers-list li .form-group input[type="checkbox"]:checked::before {
	background-color: var(--primary-color);
}
.all-trainers-list {
	list-style: none;
	margin: 0;
	padding: 0;
	/* margin-top: 0.75rem;
	padding-top: 0.75rem;
	border-top: 1px solid #b2b2b2; */
}

.trainer-list-container {
	/* max-height: 8.75rem;
	overflow-y: auto; */
	margin-top: 1.5rem;
}
</style>
