<template>
	<div class="question-bank-container">
		<div class="bank-container">
			<CategoriesColumn
				@selectCategory="selectCategory"
				:selectedCategory="selectedCategory"
				:productVariantId="productVariantId"
			/>
			<QuestionsColumn
				:selectedCategory="selectedCategory"
				:productVariantId="productVariantId"
			/>
		</div>
	</div>
</template>

<script>
import CategoriesColumn from "./CategoriesColumn.vue";
import QuestionsColumn from "./QuestionsColumn.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "CourseQuestionBank",
	data() {
		return {
			selectedCategory: [],
		};
	},
	components: {
		CategoriesColumn,
		QuestionsColumn,
	},
	methods: {
		...mapActions("exam", [
			"fetchQuestionCategories",
			"createQuestionCategory",
		]),
		selectCategory(category) {
			this.selectedCategory = category;
		},
	},
	props: {
		productVariantData: Object,
		productVariantId: String,
	},
	computed: {
		...mapState("exam", ["questionCategories"]),
		...mapState("productVariant", ["productVariants"]),
	},
	watch: {
		selectedCategory: function (newVal, oldVal) {
			if (this.selectedCategory) {
				var selected = [...this.selectedCategory];
				// selected.reverse();
				console.log(selected);
				if (selected.length > 0) {
					var queryString = "";
					selected.forEach((cat, index) => {
						queryString +=
							`${index != 0 ? "_" : ""}` + `${cat.id}$${cat.categoryName}`;
					});
					// console.log(queryString);
					if (queryString != this.$route.query.pool)
						this.$router.push({
							path: "questionbank",
							query: {
								pool: queryString,
							},
						});
				} else {
					this.$router.push({
						path: "questionbank",
						query: null,
					});
				}
			}
		},
	},
	created() {
		if (!this.questionCategories[this.productVariantId]) {
			this.fetchQuestionCategories({
				productVariantID: this.productVariantId,
				categoryID: null,
			}).then((res) => {
				if (res == null) {
					console.log(res, "res is null");
					var productVariantName =
						this.productVariants.items[this.productVariantId].title;
					console.log(productVariantName);
					var createCategoryObject = {
						categoryName: productVariantName,
						parentID: null,
						sourceID: this.productVariantId,
					};
					this.createQuestionCategory(createCategoryObject).then((res) => {
						if (res) {
							this.fetchQuestionCategories({
								productVariantID: this.productVariantId,
								categoryID: this.selectedCategory.at(-1)
									? this.selectedCategory.at(-1).id
									: null,
							});
						}
					});
				}
			});
		}

		if (this.$route.query && this.$route.query.pool) {
			if (this.questionCategories[this.productVariantId]) {
				var selectedCategory = this.$route.query.pool.split("_");
				selectedCategory = selectedCategory.map((cat) => {
					return {
						id: cat.split("$")[0],
						categoryName: cat.split("$")[1],
					};
				});
				// selectedCategory.reverse();
				this.selectedCategory = selectedCategory;
			} else {
				this.fetchQuestionCategories({
					productVariantID: this.productVariantId,
					categoryID: null,
				}).then((res) => {
					if (res) {
						if (this.$route.query && this.$route.query.pool) {
							var selectedCategory = this.$route.query.pool.split("_");
							selectedCategory = selectedCategory.map((cat) => {
								return {
									id: cat.split("$")[0],
									categoryName: cat.split("$")[1],
								};
							});
							// selectedCategory.reverse();
							this.selectedCategory = selectedCategory;
						}
					}
				});
			}
		}
	},
};
</script>

<style scoped>
.question-bank-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 1.875rem;
	overflow-y: auto;
}

.title-container {
	display: flex;
	align-items: center;
}

.page-title {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	margin: 0;
}

.bank-container {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border-radius: 0.5rem;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	height: calc(100vh - 9rem);
	border: 1px solid #ddd;
}
</style>
