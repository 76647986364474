<template>
	<div v-if="contentData">
		<div class="content-page-container" v-if="isDesktopApp || !isWebDisabled || contentData.isContentAvailableForDemo == true">
			<div :class="[{ 'col-lg-9 col-md-12 p-0': $route.name != 'LiveClass' }]">
				
			<router-view
					v-if="contentData && productVariantData"
					:productVariantData="productVariantData"
					:contentData="contentData"
				/>
			</div>
			<div class="col-lg-3 col-md-12 p-0" v-if="$route.name == 'VideoContent' || $route.name == 'Quiz'">
				
				<!-- <ul class="inline-list">
					<li
						id="chapters"
						@click="changeTab"
						:class="{ tab: true, active: tabActive == 'chapters' }"
					>
						Chapters
					</li>
					<li
						id="chat"
						@click="changeTab"
						:class="{ tab: true, active: tabActive == 'chat' }"
					>
						Chat
					</li>
				</ul> -->
				<div v-if="tabActive == 'chapters'">
					<ProductContentSidebar
						v-if="productVariantData"
						:productVariantData="productVariantData"
						viewMode="sidebar"
					/>
				</div>
			</div>
		</div>
		<AppsDownloadInstructions v-else />
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { contentService } from "../services/content.service";
import ProductContentSidebar from "../components/Product/ProductContentSidebar.vue";
import { ProductVariantPricingStatus } from "../constants/pricing";
import AppsDownloadInstructions from "@/components/Layout/AppsDownloadInstructions";


export default {
	name: "Content",
	data() {
		return {
			tabActive: "chapters",
			productVariantData: null,
			activeContent: "",
			productVariantId: "",
			id: "",
			contentData: null,
			showWebDisabled: false,
			
		};
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		...mapActions("productVariant", ["getProductVariantById"]),
		...mapActions("pricing", ["fetchInvoicesList"]),
		changeTab(e) {
			this.tabActive = e.target.id;
		},
		async checkUserExistingInvoices() {
			var isPending = await this.fetchInvoicesList({
				CustomerSourceID: this.user.id,
				ProductSourceID: this.productVariantData.id,
			}).then((invoices) => {
				if (!invoices.payload.length) return false;
				var invoicePending = false;
				invoices.payload.forEach((invoice) => {
					if (invoice.status.toLowerCase() != "paid") {
						invoicePending = true;
						return;
					}
				});
				return invoicePending;
			});
			return isPending;
		},
		async handleUnenrolledProductVariant() {
			if (
				this.productVariantData.subscriptionStatus.toLowerCase() ==
					"unenrolled" &&
				!this.contentData.isContentAvailableForDemo
			) {
				// If the user is logged out
				if (!this.user) {
					this.$toasted.error("You are not allowed to access this content.", {
						icon: "exclamation-triangle",
						duration: 5000,
						position: "bottom-center",
						theme: "outline",
					});
					this.$router.push("/");
					return;
				}
				// If the user id logged in
        console.log(`userType: ${this.user.type}`);
        console.log(`userProductVariants`, this.user.productVariants);
				if (this.user.type.toLowerCase() == "management") return;

        if (this.user.type.toLowerCase() == "trainer" && this.user.productVariants.contains(this.productVariantData.id)) return;

				var isPaymentPending = await this.checkUserExistingInvoices();

				if (
					this.productVariantData.pricingStatus ==
						ProductVariantPricingStatus.FREE ||
					!isPaymentPending
				) {
					this.$toasted.error(
						"You are not enrolled in this course so you cannot access this content.",
						{
							icon: "exclamation-triangle",
							duration: 5000,
							position: "bottom-center",
							theme: "outline",
						}
					);
					this.$router.push("/user/dashboard");
					return;
				}

				if (isPaymentPending) {
					this.$toasted.error(
						"You have pending unpaid invoices. Please pay your dues to access this content.",
						{
							icon: "exclamation-triangle",
							duration: 5000,
							position: "bottom-center",
							theme: "outline",
						}
					);
					this.$router.push("/user/invoices");
					return;
				}
				// console.log({ isPaymentPending });
			}
		},
	},
	computed: {
		...mapState("content", ["content", "loading"]),
		...mapState("user", ["user"]),
		...mapState("productVariant", ["productVariants"]),
		...mapState("appState", ["isMobile","systemParameters"]),		
		isDesktopApp() {
      		return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
    	},
		isWebDisabled(){
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled=='1';
		},
		isContentLoaded() {
			if (this.content[this.id]) {
				this.contentData = this.content[this.id];
				console.log({ msg: "content found in store" });
				return true;
			} else {
				console.log({ msg: "content not found in store" });
				if (this.loading == false) {
					// const contentType =
					// 	this.$route.name.toLowerCase() == "liveclass" ? "liveclass" : null;
					this.getContentById({
						id: this.id,
						extraParams: { Platform: contentService.getCurrentPlatform() },
					}).then(() => {
						this.contentData = this.content[this.id];
						window.document.title = this.contentData.title;
					});
				}
				return false;
			}
		},
		productAndContentIdFromUrl() {
			var idFromUrl = this.$route.params.id.split("-").at(-1);
			return idFromUrl.split("_");
		},

		isVSSServiceContent(){
			const contentId = this.$route.params.id.split("-").at(-1).split("_")[0];
			const contentData = this.content[contentId];
			
			return contentData && contentData?.externalService?.serviceCode?.toString().toLowerCase() == 'vss';
		}
	},
	components: {AppsDownloadInstructions, ProductContentSidebar },
	async created() {
		
		this.productVariantId = this.productAndContentIdFromUrl[1];
		this.id = this.productAndContentIdFromUrl[0];
		if (!this.productVariants[this.productVariantId]) {
			await this.getProductVariantById(this.productVariantId).then((data) => {
				this.productVariantData = data;
			});
		}
		
		await this.getContentById({
			id: this.id,
			extraParams: { Platform: contentService.getCurrentPlatform() },
		}).then(async () => {
			this.contentData = this.content[this.id];
			window.document.title = this.contentData.title;
			
			if (!this.isDesktopApp && this.isWebDisabled && this.contentData.isContentAvailableForDemo == false) {
				this.showWebDisabled = true;
				return;
			}
			this.handleUnenrolledProductVariant();
		});
	},
	watch: {
		$route(to, from) {
			this.id = this.$route.params.id.split("-").at(-1).split("_")[0];
			this.productVariantId = this.$route.query.productVariantId;
			const contentData = this.content[this.id];
	
			if (!contentData || this.isVSSServiceContent) {
				// const contentType =
				// 	this.$route.name.toLowerCase() == "liveclass" ? "liveclass" : null;
				this.getContentById({
					id: this.id,
					extraParams: { Platform: contentService.getCurrentPlatform() },
				}).then(() => {
					this.contentData = this.content[this.id];
					window.document.title = this.contentData.title;
				});
			} else {
				this.contentData = this.content[this.id];
				window.document.title = this.contentData.title;
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.content-page-container {
		flex-direction: column !important;
	}
}

.tab {
	width: 50%;
	padding: 1rem !important;
	font-weight: 500;
	font-size: 1.1rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.active,
.tab:hover {
	background-color: var(--primary-color-hover);
}

.content-page-container {
	display: flex;
	flex-direction: row;
}
</style>
