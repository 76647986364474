<template>
	<b-modal id="assignment-modal" @change="$emit('updateModalState', false)" :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')" :visible="visible" hide-header centered
		:content-class="'assignment-modal '" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container" v-if="contentData">
			
			<!-- 
			<button @click="$emit('updateModalState', false)" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button> -->
			<!-- <h1 class="add-content-title">
				Manage Assignment
			</h1> -->
			<b-tabs content-class="body-container p-0" v-model="activeTabIndex" align="center">

				<b-tab :title="contentData.title" title-item-class="modal-Heading add-content-title" disabled></b-tab>
				<b-tab title="Instructions" active>
					<div class="row" v-if="extraProperties" style="overflow-y: auto; overflow-x: hidden;">
						<div class="col-md-9 pr-5">
							<!-- <h5 class="mb-3 mt-3">
								{{ contentData.title }}
							</h5> -->
							<div id="instructions" class="instructions mt-3" v-if="extraProperties.Discription">
							<QuillRichTextEditor
								:text="extraProperties.Discription"
								:isInline="false"
								editorID="instruction-desc"
								:isReadOnly="true"
								v-model="extraProperties.Discription"
								/>
							</div>
							<div class="row mt-2" v-if="extraProperties.Files && extraProperties.Files.length > 0">

								<div class="pr-3 col-lg-3 col-md-4" v-for="file in extraProperties.Files">
									<a :class="'card file-card ' + file.status" @click="openInNewTab(file.url)">
										<label :title="file.name">
											{{ file.Name }}
											<small><br />{{ file.Type }}</small>
										</label>
									</a>

								</div>
							</div>
						</div>
						<div class="col-md-3 box-with-left-shadow mt-5">
							<h6>Assignment Due</h6>
							<input class="form-control no-due-date"  disabled readonly :value="extraProperties.Deadline? parseDateTime( extraProperties.Deadline):'No Due Date'" />
							<h6 class="mt-4">Marks</h6>
							<input class="form-control no-due-date" disabled readonly :value="extraProperties.Marks?extraProperties.Marks:'No Marks'" />
							<h6 class="mt-4">Passing Percentage</h6>
							<input class="form-control no-due-date" disabled readonly :value="extraProperties.Marks?extraProperties.PassingPercentage+'%':'No Passing Percentage'" />
							<h6 class="mt-4">Allowed Submissions</h6>
							<input class="form-control no-due-date" disabled readonly :value="extraProperties.AllowedSubmissions
							?extraProperties.AllowedSubmissions:'1'" />
						</div>

					</div>
				</b-tab>
				<b-tab title="Submissions">

					<div v-if="!selectedStudentAssignment" class="submissions pt-3" style="height: 100%;">
						
						<div style="height: inherit;">
							<div style="display: flex;">
								<div class="d-flex mb-2 gap-3">
									<button
										
										@click="setFilterType('all')"
										:class="['filter-user-type-btn',{active:filterType=='all'}]"
									>
									  All
									</button>
									<button
									@click="setFilterType('pending')"
										:class="['filter-user-type-btn',{active:filterType=='pending'}]"
									>
									  Pending
									</button>
									<button
									@click="setFilterType('submitted')"
										:class="['filter-user-type-btn',{active:filterType=='submitted'}]"
									>
									  Submitted
									</button>
									<button
									@click="setFilterType('latesubmitted')"
										:class="['filter-user-type-btn',{active:filterType=='latesubmitted'}]"
									>
									Late Submitted
									</button>

									<button
										@click="setFilterType('tograde')"
										:class="[
													'filter-user-type-btn'
													,{active:filterType=='tograde'}
												]"
									>
									  Not Graded
									</button>
									<button
										@click="setFilterType('graded')"
										:class="[
													'filter-user-type-btn',{active:filterType=='graded'}
												]"
									>
									  Graded 
									</button>

								  </div>
								  
									<div style="flex: 1;" >
									</div>
									<button
									style="margin-right: 10px;"
										@click="refresh"
										:class="[
													'filter-user-type-btn','active'
													
												]"
									>
									 <i class="fa fa-refresh"></i>&nbsp;Refresh
									</button>
									<input type="search" v-model="searchNameEmail" placeholder="Search By Name" class="form-control search-box" />
								
							</div>
							<div class="tableFixHead">
								
								<table v-if="assignmentSubmissionsResult && assignmentSubmissionsResult.length > 0"
									class="table table-hover table-borderless all-users-table ">
									<thead>
										<!-- <th style="width: 1%; padding-left: 8px;" class="text-center">
											<input type="checkbox" name="select_all" id="select_all" v-model="isSelectedAll"
																		@click="selectAll()" />
										</th> -->
										<th style="width: 3%;">
											#
										</th>
										<th style="width: 10%;" class="text-center">
											Name
										</th>
										<th style="width: 10%;">
											Status
										</th>
										<th style="width: 10%;">
											Submitted At
										</th>
										<th style="width: 5%;" class="text-center">
											Feedback
										</th>
										<th v-if="extraProperties.Marks > 0" style="width: 10%;">
											Marks (Out Of {{ extraProperties.Marks }})
										</th>
									</thead>
									<tbody>
										<tr v-for="(assignmentResult, i) in assignmentSubmissionsResult"  
										@click="selectStudentAssignment(assignmentResult)">
											<!-- <td class="text-center"><input type="checkbox" v-model="selectedUsers[assignmentResult.user.id]" name="select_all"
													id="select_all" /></td> -->
											<td>{{ i + 1 }}</td>
											<td class="user-name d-flex align-items-center">
												
												<img v-if="assignmentResult.user.profilePicture" class="user-image user-avatar"
													:src="assignmentResult.user.profilePicture" />
												<div v-else class="user-avatar">
													{{ assignmentResult.user.firstName[0] }}
												</div>
												<h6 style="margin-left: 10px; margin-bottom: 0px;">
													{{ assignmentResult.user.firstName }} {{ assignmentResult.user.lastName
													}}<br />
													<small style="color: gray !important;">{{
														assignmentResult.user.email }}</small>
												</h6>
											</td>
											<td>
												<h6 style="margin-top:9px;">
													<span v-if="assignmentResult.isGraded == true">
														Graded
													</span>													
													<span v-else-if="assignmentResult.isSubmitAssignment == true"
														class="submitted-status">
														<i class="fa fa-check"></i> Submitted
													</span>
													<span v-else-if="assignmentResult.isSubmitAssignment == false"
														class="pending-status">Pending
													</span>
												</h6>
											</td>
											<td class="submitted-box"><small :style="assignmentResult.isSubmitAssignment == true && assignmentResult.isLateSubmission == true?'font-size: 13px;':''">
												{{ parseDateTime(mapSubmitted(assignmentResult)) }}</small>
												<div
													v-if="assignmentResult.isSubmitAssignment == true && assignmentResult.isLateSubmission == true"
													class="status-tag danger late-submitted-status">
													<i class="fas fa-clock" style="margin-right: 5px;"></i> Late Submission
												</div>
											</td>
											<td class="text-center">											
												<div class="feedback-container" @click.stop>
													<b-tooltip v-if="mapFeedback(assignmentResult)" :target="'add-dropdown-btn-'+assignmentResult.user.id" triggers="hover">
														{{mapFeedback(assignmentResult) }}
													</b-tooltip>
													<a
														:title="mapFeedback(assignmentResult)"
														class="feedback-btn"
														data-bs-toggle="dropdown"
														:id="'add-dropdown-btn-'+assignmentResult.user.id"
														role="button"
														@click="addFeedback(assignmentResult.user.id)"
													>
													<span class="ping" v-if="mapFeedback(assignmentResult)"></span>
													<i class="fas fa-comments"></i>

													</a>
													<div
														@click.stop
														id="add-dropdown"
														
														class="dropdown-menu dropdown-menu-end"
														aria-labelledby="dropdownMenuLink"
													>
														
															<textarea
																class="add-feedback-input"
																placeholder="Enter feedback"
																v-model="feedback"
																id="add-feedback-input"
																required
															></textarea>
															<div class="btn-div" >
																<div style="flex:1;">

																</div>
																<b-spinner small v-if="loading" style="margin-right: 10px;"></b-spinner>
											
																<button
																	@click="saveFeedback(assignmentResult.user.id)"
																	:disabled="!feedback || loading"
																	type="submit"
																	class="filter-user-type-btn save-btn active"
																	>
																	Save
																	</button>
																<button
																type="button"
																:disabled="loading"
																	@click="hideDropdown('add-dropdown-btn-'+assignmentResult.user.id)"
																	class="filter-user-type-btn cancel-btn active"
																	>
																	Cancel
																	</button>
															</div>
															
													
													</div>
												</div>
											</td>
											<td v-if="extraProperties.Marks && extraProperties.Marks > 0" class="text-center"  style="">
												<div @click.stop
												class="marks-div">
													<input v-if="currentEditableUserID == assignmentResult.user.id"  v-on:keyup.enter="saveMarks" 
													  :class="'form-control score-box '+(!validateMarks?'error':'')" min="0" :max="extraProperties.Marks" v-model="updatedMarks" type="number"/>
													<input v-else  class="form-control score-box" @focus="$event.target.select()" @click="editMarks(assignmentResult.user.id)" v-model="assignmentResult.marks" readonly type="number"/>
													<a class="saveMarksBtn" @click="saveMarks" v-if="currentEditableUserID == assignmentResult.user.id && !loading"><i class="fa fa-check"></i></a>
													<a class="cancelMarksBtn" @click="cancelEditMarks" v-if="currentEditableUserID == assignmentResult.user.id  && !loading"><i class="fa fa-times"></i></a>
													<b-spinner small v-if="currentEditableUserID == assignmentResult.user.id  && loading" class="loading-btn"></b-spinner>
												</div>
											</td>
										</tr>
									</tbody>
								</table>

								<div v-else-if="!assignmentSubmissionsResult"
									class="d-flex align-items-center justify-content-center gap-3 p-3 font-500 m-5">
									<b-spinner></b-spinner> Loading...
								</div>
								<h5 v-else-if="assignmentSubmissionsResult && assignmentSubmissionsResult.length <= 0"
									class="text-center p-4 m-0">No users found</h5>


							</div>
						</div>


						<div class="step-btn-container">
							<!-- <a role="button" :disabled="!this.assignmentSubmissionsResult || this.assignmentSubmissionsResult.length<=0" class="step-btn export"><i class="fas fa-file-excel"></i> Export
							</a> -->
							<!-- <button :disabled="!selectedAssignments || selectedAssignments.length<=0" role="button" class="step-btn">
								Return
							</button> -->
						</div>
					</div>
					<div v-else-if="selectedStudentAssignment"
					style="height: 100%;"
						>
						<AssignmentDetail @closeSubmissionDetail="()=>{ selectedStudentAssignment=null }" :contentData="contentData" :assignmentResults="assignmentSubmissionsResult" :assignmentResult="selectedStudentAssignment"/>
					</div>					





					
				</b-tab>
				<template #tabs-end>
					<div style="display: flex; flex: 1; text-align: right; justify-content: right;">
						
						
						<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
						</button>

						<button @click="$emit('updateModalState', false)" class="close-button" role="button">
							<img src="@/assets/images/cancel-icon.svg" />
						</button>
					</div>

				</template>

			</b-tabs>

		</div>
	</b-modal>
</template>

<script>

import CookieHelpers from "../../../helpers/CookieHelpers";
import Loading from "../../../components/Layout/Loading.vue";
import draggable from "vuedraggable";
import { ContentType } from "../../../constants/content";
import { parseDateToYMD } from "../../../helpers/FormatHelpers";
import { mapActions } from "vuex";
import { POST_FILE_API } from "./../../../constants/urls";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
import AssignmentDetail from "./AssignmentDetail.vue";
import QuillRichTextEditor from "./../../Editors/QuillRichTextEditor.vue"
export default {
	name: "ManageAssignmentModal",
	props: {
		visible: Boolean,
		parentContentId: String,
		contentData: Object
	},
	data() {
		return {
			filterType:'all',
			quillEditor: null,
			activeTabIndex: null,
			assignmentResults: null,
			searchNameEmail: null,
			selectedUsers:{},
			isSelectedAll:false,
			currentEditableUserID:null,
			updatedMarks:null,
			loading:false,
			feedback:null,
			selectedStudentAssignment:null,
			isExpanded:false
		};
	},
	methods: {

		...mapActions("content", ["getContentById", "updateContent",
			"createAssignmentContent"]),
		...mapActions("assignment", ["getSubmittedAssignmentResults","submitAssignmentResult"]),
		openInNewTab(url) {
			window.open(url, "_blank");
		},
		closeSubmissionDetail(){
			this.selectStudentAssignment=null
		},
		refresh(){
			this.filterType="all";
			this.searchNameEmail=null;
			this.assignmentResults=null;
			this.getAssignmentResults();
		},
		getAssignmentResults() {
			
			this.getSubmittedAssignmentResults(this.contentData.id)
				.then(results => {
					var data={};
					results.forEach(result=>{
						data[result.user.id]=result;
					});
					this.assignmentResults = data;
				});
		},
		parseDateTime(time) {
			if (time)
				return new Date(time).toDateString() + " " + new Date(time).toLocaleTimeString();

			return "";
		},
		setFilterType(filterType){
			this.selectedUsers={};
			this.filterType=filterType;
		},
		selectAll(){
			if(this.isSelectedAll!=true)
			{
				if(this.assignmentSubmissionsResult && this.assignmentSubmissionsResult.length>0)
				{
					this.assignmentSubmissionsResult
					.forEach(assignmentResult => {
						this.selectedUsers[assignmentResult.user.id]=true;
					});
					var temp=this.selectedUsers;
					this.selectedUsers=null;
					this.selectedUsers=temp;
				}
				}else{
					this.selectedUsers={};
				}
		},
		editMarks(userId)
		{
			this.currentEditableUserID=userId;
			this.updatedMarks= this.assignmentResults[userId].marks??"0";
		},
		cancelEditMarks(){
			this.currentEditableUserID=null;
		},
		saveMarks(){
			var isValidMarks = this.validateMarks;
			if(this.currentEditableUserID && isValidMarks){
				var assignmentResult = {
					ContentId:this.contentData.id,
					UserId:this.currentEditableUserID,
					Marks: parseInt(this.updatedMarks)
				}
				this.loading=true;
				this.submitAssignmentResult(assignmentResult).then((submittedAssignmentResult)=>{
					this.loading=false;
					if(submittedAssignmentResult){
						this.assignmentResults[submittedAssignmentResult.user.id]=submittedAssignmentResult;
						this.currentEditableUserID=null;
					}
				});
			}
		},		
		hideDropdown(id) {
			document.getElementById(id).click();
		},		
		addFeedback(userId) {
			if(this.assignmentResults[userId] && this.mapFeedback(this.assignmentResults[userId]))
			{
				
				this.feedback=this.mapFeedback(this.assignmentResults[userId]);
			}
			else{
				this.feedback="";
			}
		},
		saveFeedback(userId) {
			if(this.feedback)
			{
				var assignmentResult = {
					ContentId:this.contentData.id,
					UserId:userId,
					Feedback: this.feedback
				}
				this.loading=true;
				this.submitAssignmentResult(assignmentResult).then((submittedAssignmentResult)=>{
					this.loading=false;
					if(submittedAssignmentResult){
						this.assignmentResults[submittedAssignmentResult.user.id]=submittedAssignmentResult;
						this.currentEditableUserID=null;
						this.hideDropdown('add-dropdown-btn-'+submittedAssignmentResult.user.id);
					}
				});
			}
		},
		selectStudentAssignment(studentAssignment){
			this.selectedStudentAssignment=studentAssignment;
		},
		mapFeedback(assignment){
			if(assignment.submissions && assignment.submissions.length>0)
			{
				return assignment.submissions[assignment.submissions.length-1].feedback;
			}
			return null;
		},
		mapSubmitted(assignment){
			if(assignment.submissions && assignment.submissions.length>0)
			{
				return assignment.submissions[assignment.submissions.length-1].submittedAt;
			}
			return null;
		}
	},
	components: {
		Loading,
		AssignmentDetail,
		QuillRichTextEditor
	},
	mounted() {
		if (this.visible) {
		}
	},
	created() {
		this.content = { ...this.contentData };
	},
	computed: {
		...mapState("appState", ["apiURL"]),
		extraProperties() {
			return this.contentData && this.contentData.extraProperties && typeof(this.contentData.extraProperties) == 'string' ? JSON.parse(this.contentData.extraProperties) : this.contentData.extraProperties
		},
		isSubmissionsActivePanel() {
			return this.activeTabIndex == 2;
		},
		validateMarks(){
			return	this.updatedMarks && this.extraProperties && this.extraProperties.Marks && parseInt(this.extraProperties.Marks)>=parseInt(this.updatedMarks)
		},
		assignmentSubmissionsResult(){
			if(this.assignmentResults)
			{
				var filtered = Object.values(this.assignmentResults);

				filtered = filtered.filter(x=>!this.searchNameEmail || `${x.user.firstName} ${x.user.lastName} ${x.user.email}`.toLowerCase().indexOf(this.searchNameEmail.trim().toLowerCase())>-1);

				if(this.filterType=='pending')
				{
					filtered = filtered.filter(x=>x.isSubmitAssignment==false && x.isGraded==false);
				}
				
				if(this.filterType=='latesubmitted')
				{
					filtered = filtered.filter(x=>x.isSubmitAssignment==true && x.isLateSubmission==true);
				}
				
				if(this.filterType=='submitted')
				{
					filtered = filtered.filter(x=>x.isSubmitAssignment==true);
				}
				
				if(this.filterType=='tograde')
				{
					filtered = filtered.filter(x=>x.isSubmitAssignment==true && x.isGraded==false);
				}
				
				if(this.filterType=='graded')
				{
					filtered = filtered.filter(x=>x.isGraded==true);
				}
				return filtered;
			}
			return null;
		},
		selectedAssignments(){
			if(!this.assignmentSubmissionsResult)
				return [];
			return this.assignmentSubmissionsResult.filter(x=>this.selectedUsers[x.user.id]==true);
		},
		

	},
	watch: {
		visible: function () {
			if (this.visible) {

				//this.loadEditContent()
			}
		},
		activeTabIndex: function () {
			if (this.isSubmissionsActivePanel) {
				this.getAssignmentResults();
			}
		},
		selectedUsers:function(){
			if(this.selectedUsers && Object.keys(this.selectedUsers).length>0 && this.assignmentSubmissionsResult.length==Object.keys(this.selectedUsers).length){
				this.isSelectedAll=true;
			}else{
				this.isSelectedAll=false;
			}
		},
	},
};
</script>

<style scoped>
.expanded-btn{
	color: black;
    font-size: 24px;
    margin-right: 15px;
	padding-top: 4px;
}
.user-image{
	object-fit: cover;
}
.close-button {
	position: inherit;
}


.assignment-modal .modal-body .body-container {
	padding: 0rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.info-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.form-textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 14px;
	line-height: 1.5;
	resize: vertical;
	transition: border-color 0.2s ease-in-out;
}

.form-textarea:focus {
	border-color: var(--color-red);
	outline: none;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.info-container .form-group {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.info-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

.form-input:focus {
	border-color: var(--primary-color) !important;
}

.info-container tbody img {
	height: 50px;
	width: 50px;
}

#delete-icon {
	height: 35px;
	width: 30px;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: auto;
}

.modal-body {
	padding: 0px;
}

.attach-resources-link {
	color: var(--primary-color);
	text-decoration: none;
	margin-top: 5px;
	margin-right: 15px;
	cursor: pointer;
}

.attach-resources-link i {
	margin-right: 5px;
}

.link-box {
	width: auto;
	max-width: none;
}

.link-box input {
	margin: 10px;

}

.file-card:hover {
	background-color: whitesmoke;
}

.file-card {
	display: flex;
	padding: 5px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid var(--primary-color);
	cursor: pointer;
	flex-direction: inherit;
	margin-bottom: 10px;
	text-decoration: none;
	color: black;
	overflow: hidden;
}

.file-card label {
	flex: 1;
	padding: 0px;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: small;
	font-weight: bold;
	cursor: pointer;
}

.file-card.failed {
	background-color: rgb(var(--bs-danger-rgb));
}

.file-card.failed label {
	color: white;
}

.file-card label small {
	color: var(--primary-color);
	text-transform: uppercase;
	padding: 0px;
	margin: 0px;
	cursor: pointer;
	font-weight: lighter;
}

.file-card.failed label small {
	color: white;
}


.file-card .progress {
	position: absolute;
	width: 100%;
	bottom: -2px;
	left: 0px;
	height: 7px;

}

.instructions {
	height: auto;
}

.assignment-modal .side-bar {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.box-with-left-shadow {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	border-radius: 15px;
	padding: 15px;
}

.no-due-date:disabled {
	background-color: #fafafa;
	border-radius: 5px;
	height: 40px;
	border: 0px;
}

.assignment-modal .tabs {
	height: 100%;
}

.submissions.body-container {
	height: 100%;
}

.step-btn.export {
	color: rgb(var(--bs-success-rgb)) !important;
	border: 0px;
}

.step-btn.export:hover {
	background-color: white !important;
}

.search-box {
	max-width: 200px;
	float: right;
	border-radius: 5px !important;
	height: 30px;
}

.late-submitted-status {
	padding: 5px;
    padding-bottom: 4px;
    color: white;
    font-size: xx-small;
}

.submitted-status {
	padding-top: 10px;
	color: rgb(var(--bs-success-rgb));
}

.pending-status {
	padding-top: 10px;
	color: rgb(var(--bs-danger-rgb));
}

table tr td {
	vertical-align: middle;
	display: table-cell;
}

table thead {
	border-top: 1px solid;
	border-color: inherit;
}

table th {
	padding: 10px;
	padding-left: 8px;
}

.tableFixHead {
	overflow-y: auto;
	/* make the table scrollable if height is more than 200 px  */
	height: 90%;
	/* gives an initial height of 200px to the table */
}

.tableFixHead thead {
	position: sticky;
	/* make the table heads sticky */
	top: -1px;
	/* table head will be placed from the top of the table and sticks to it */
}

table {
	margin: 0px;
	padding: 0px;
	width: 100%;
}

table thead {
	background: #fafafa;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	z-index: 9999;
}
.filter-user-type-btn{
	height: 30px;
	background-color: #fafafa;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	cursor: pointer !important;
}

.filter-user-type-btn.active{
	
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	background-color: #ffffff;
}
.score-box{
	height: 30px;
	width: 50px;
	font-size: 24px;
	margin: 0px;
	background-color: #fafafa;
}
.score-box:disabled{
	cursor: pointer;
}
.saveMarksBtn{
	margin-left: 10px;
	margin-top: 2px;
	font-size: large;
	color: rgb(var(--bs-success-rgb));
}
.cancelMarksBtn{
	margin-left: 10px;
	margin-top: 2px;
	font-size: large;
	color: rgb(var(--bs-danger-rgb));
}
.marks-div{
	display: flex;
	place-content: center; 
	text-align: center;
}
.submitted-box{
	padding-top: 3px;
	padding-bottom: 3px;
}
.feedback-container {
	margin-left: auto;
}
.feedback-container form{
	display: block !important;
}
.add-feedback-input {
	max-height: 10rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	border-radius: 8px;
	padding:1rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
	padding-right: 5.25rem;
}
.feedback-container div.btn-div{
	text-align: -webkit-right;
	display: flex;
	height: 100%;
	
} 
.feedback-container .save-btn{
	font-size: x-small;
    height: 20px;
    border-radius: 5px;
	background-color: var(--primary-color);
	color: white;
	margin-right: 10px;
} 
.feedback-container .cancel-btn{
	font-size: x-small;
    height: 20px;
    border-radius: 5px;
}
.feedback-btn{
	color: #000000;
	position: relative;
	text-align: right;
} 
.feedback-btn:active{
	color: #000000;
} 
.feedback-btn .ping{
	width: 6px;
    top: 2px;
    height: 6px;
    background-color: rgb(var(--bs-danger-rgb));
    border-radius: 4px;
    position: absolute;
    right: 1px;
} 
</style>
<style>

.assignment-modal
{
  height: 45.375rem;
  /* width: 70vw; */
}

#assignment-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#assignment-modal .expanded .assignment-modal
{
	height: 90vh;
	
}

.assignment-modal  .modal-body.modal-body
{
	display: flex;
    flex-direction: row;
    padding: 0 !important;
	overflow: auto;
}
.assignment-modal  .modal-body.modal-body .body-container
{
	
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

}



.instructions .ql-disabled {
	border: 0px;
}

.instructions .ql-disabled .ql-editor {
	padding: 0px;
	border: 0px;
}

.assignment-modal .modal-body .nav-item.modal-Heading {
	flex: 1;
	font-weight: bolder;
}

.assignment-modal .modal-body .nav-item.add-content-title a {
	padding: 0px;
	color: black;
	pointer-events: none;
	font-size: large;
	padding-bottom: 7px;
}

.assignment-modal .tabs .tab-content {
	height: 86%;
}

.assignment-modal .submissions .tab-content {
	height: 100%;
}

.assignment-modal .tab-pane.active {
	height: 100%;
	width: 100%;
}

.assignment-modal .nav.nav-tabs {

	border-bottom: solid 1px #d8dbe0;
}

.assignment-modal .nav-tabs .nav-link.active {
	border-bottom: 3px solid var(--primary-color);
	color: black;
	font-weight: bold !important;
}

.assignment-modal .nav-tabs .nav-item .nav-link {
	color: black;
	font-weight: lighter;
}
.loading-btn{
	color:#a2a3a2;
	align-self: center;
	margin-left: 10px;
	width: 1.4rem !important;
	height: 1.4rem !important;
}

</style>