<template>
	<div class="searchbar position-relative d-flex align-items-center">
		<input
			autocomplete="off"
			type="search"
			name="search"
			id="search"
			placeholder="What do you want to learn"
			v-model="searchText"
			@focus="showDropdown = true"
			@blur="closeDropdown"
		/>
		<!-- {{ searchOnInput }} -->
		<router-link :to="searchPageLink" class="search-icon"
			><i class="fa fa-search"></i
		></router-link>
		<div class="search-dropdown" v-if="showDropdown">
			<ul v-if="searchList.length > 0">
				<li
					@click="handleLinkClick(item)"
					v-for="(item, index) in searchList"
					:key="index"
				>
					<img
						class="searchbar-image"
						src="../../assets/images/sample-thumbnail.jpeg"
						alt=""
					/>
					{{ item.product.title }} - {{ item.title }} <br />
					<!-- {{ item.creator.name }} -->
				</li>
			</ul>
			<div
				v-if="searchList.length == 0 && searchText.length > 0"
				class="no-content p-4 text-center text-muted"
			>
				Sorry! No relevant results found.
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { parseForURL } from '../../helpers/FormatHelpers';

export default {
	name: "Searchbar",
	data() {
		return {
			searchText: "",
			showDropdown: false,
			// searchList: [],
		};
	},
	methods: {
		...mapActions("productVariant", ["fetchProductVariants"]),
		handleLinkClick(item) {
			this.searchText = "";
			if (
				this.user &&
				((this.user.type && this.user.type.toLowerCase() == "student") ||
					(this.user.role && this.user.role.toLowerCase() == "student")) &&
				this.user.productVariants.length > 0
			) {
				var found = this.user.productVariants.find((p) => p.id == item.id);
				if(found)
				{
					this.$router.push(
						`/product/dashboard/chapters/${item.id}`
					);
					return;
				}
			}
			this.$router.push(
				`/product/details/${parseForURL(item.product.friendlyTitle)}-${item.id}`
			);
		},
		closeDropdown() {
			setTimeout(() => (this.showDropdown = false), 300);
		},
	},
	computed: {
		...mapState("productVariant", ["productVariants"]),
		...mapState("user", ["user"]),
		searchList() {
			// console.log("searchOnInput called");
			var list = [];
			if (this.productVariants.length > 0 && this.searchText !== "") {
				var filteredVariants = Object.values(this.productVariants.items).filter(
					(v) =>
						v.status.toLowerCase() != "private" &&
						v.status.toLowerCase() != "draft"
				);
				for (var item of filteredVariants) {
					// console.log(
					// 	this.productVariants.items[item].product.title
					// 		.toLowerCase()
					// 		.includes(this.searchText.toLowerCase())
					// );
					console.log(item);
					if (
						(item.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(item.id || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
						(item.product && item.product.title || "")
							.toLocaleLowerCase()
							.indexOf(this.searchText.toLocaleLowerCase()) > -1
						// this.productVariants.items[item].product.title
						// 	.toLowerCase()
						// 	.includes(this.searchText.toLowerCase())
					) {
						// this.searchList.push(item);
						list.push(item);
						// console.log(
						// 	"Search result:",
						// 	this.productVariants.items[item].product.title
						// );
					}
				}
			}
			return list;
		},
		searchPageLink() {
			if (this.searchText != "") {
				return { path: "/search", query: { title: this.searchText } };
			} else return { path: "/search" };
		},
	},
	watch: {
		$route(to, from) {
			if (to.name.toLowerCase() !== "search") this.searchText = "";
		},
		showDropdown: function () {
			if (this.showDropdown) this.fetchProductVariants();
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.searchbar {
		display: none !important;
	}
}

.searchbar {
	flex-grow: 16;
	font-size: 1rem;
	/* max-width: 500px; */
}

.searchbar-mobile {
	height: 36px;
	border: none;
	outline: none;
	padding: 12px 36px 12px 24px;
	width: 100%;
}

#search {
	line-height: 1;
	height:42px;
	border: 1px solid var(--border-color);
	border-radius: 6px;
	background-color: #fafafa;
	outline: none;
	padding: .375rem .75rem;
	width: 100%;
	box-shadow: 0 3px 6px #dbdbdb29;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 1.5rem;
	right: 0;
	color: #000000;
}

.search-dropdown {
	position: absolute;
	top: 4.5rem;
	background-color: #fff;
	/* padding: 1rem; */
	z-index: 1000;
	width: 100%;
	box-shadow: 0 3px 6px #2f2f2f1f;
	border-radius: 8px;
}

.searchbar ul {
	margin: 0;
	padding: 0;
	list-style: none;
	border-radius: 8px;
	max-height: 70vh;
	overflow-y: auto;
}

.searchbar ul li {
	padding: 1rem;
	font-size: 1rem;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	cursor: pointer;
	transition: all 0.3s linear;
}

.searchbar ul li:hover {
	background-color: #f1f1f1;
}

.searchbar ul li:first-of-type {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

.searchbar ul li:last-of-type {
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.searchbar-image {
	width: 4rem;
	height: 4rem;
	object-fit: cover;
	object-position: top;
	border-radius: 6px;
	margin-right: 1rem;
}
</style>
