<template>
  <div class="login-webDisabled">
    <h1 class="heading-bold-20">Please download app for your device to access content.</h1>
    <ul class="download-btn-list">
      <li class="download-btn-list-item" v-if="systemParameters && systemParameters.AndroidAppUpdateUrl">
        <a :href="systemParameters.AndroidAppUpdateUrl" class="download android"  target="_blank" >
          <i class="fa fa-android fa-3x"></i>
          <span class="df">Download For</span>
          <span class="dfn">Android</span>
        </a>
      </lI>
      <li class="download-btn-list-item" v-if="systemParameters && systemParameters.IOSAppUpdateUrl">
        <a :href="systemParameters.IOSAppUpdateUrl"  class="download apple" target="_blank" >
          <i class="fa fa fa-apple fa-3x"></i>
          <span class="df">Download For</span>
          <span class="dfn">IOS / IPAD</span>
        </a>
      </li>
      <li class="download-btn-list-item" v-if="systemParameters && systemParameters.WindowsAppUpdateURL">
        <a class="download windows" :href="systemParameters.WindowsAppUpdateURL" target="_blank" >
          <i class="fa fa fa-windows fa-3x"></i>
          <span class="df">Download For</span>
          <span class="dfn">Windows</span>
        </a>
      </li>
      <li class="download-btn-list-item" v-if="systemParameters && systemParameters.MacAppUpdateURL">
        <a :href="systemParameters.MacAppUpdateURL" target="_blank" class="download apple">
          <i class="fa fa fa-apple fa-3x"></i>
          <span class="df">Download For</span>
          <span class="dfn">Mac</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AppsDownloadInstructions",
  data() {
    return {};
  },
  methods: {
  },
  computed: {
    ...mapState("appState", ["isMobile","systemParameters"])
  }
}
</script>

<style scoped>
.login-webDisabled{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 0 7% 0;
  margin-top: 20px;
}

.download-btn-list {
  text-align: center;
  margin: 0;
  padding: 0;
  display: inline;
}

.download-btn-list-item {
  display: inline;
}

.download-btn-list-item a{
  margin: 10px;
}

.download {
  width: 200px;
  height: 70px;
  background: black;
  float: left;
  border-radius: 5px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
}

.download > .fa {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.df,
.dfn {
  position: absolute;
  left: 60px;
  width: 50%;
}

.df {
  top: 16px;
  font-size: 12px;
}

.dfn {
  top: 28px;
  font-size: 18px;
}

.download:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
</style>