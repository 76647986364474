<template>
	<div class="sidebar-mini layout-fixed">
		<div class="page-wrapper">
			<ProductContentSidebar></ProductContentSidebar>
			<div class="wrapper align-items-center d-flex flex-column product-content-dash">
				<ProductContentSection v-if="currentPage.toLowerCase() == 'chapters'" />
				<ProductContentClasses class="body" v-if="currentPage.toLowerCase() == 'liveclasses'" />
				<ProductContentFlashCards class="body" v-if="currentPage.toLowerCase() == 'flashcards'" />
				<ProductContentExams class="body" v-if="currentPage.toLowerCase() == 'exams'" />
				<ProductContentAssignments class="body" v-if="currentPage.toLowerCase() == 'assignments'" />
				<ProductContentPlanners class="body" v-if="currentPage.toLowerCase() == 'planners'" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ProductContentSidebar from "../components/ProductContentDashboard/ProductContentSidebar.vue"
import ProductContentSection from "../components/ProductContentDashboard/ProductContentSection.vue"
import ProductContentClasses from '../components/ProductContentDashboard/ProductContentClasses.vue';
import ProductContentFlashCards from "../components/ProductContentDashboard/ProductContentFlashCards.vue";
import ProductContentAssignments from "../components/ProductContentDashboard/ProductContentAssignments.vue";
import ProductContentExams from "../components/ProductContentDashboard/ProductContentExams.vue";
import ProductContentPlanners from "../components/ProductContentDashboard/ProductContentPlanners.vue"
import { ProductVariantStatus,ProductVariantSubscriptionStatus } from "../constants/productVariant";

import { ContentType } from "../constants/content";
import Swal from "sweetalert2";
export default {
	name: "ProductContentDashboard",
	components: {
		ProductContentSidebar,
		ProductContentSection,
		ProductContentClasses,
		ProductContentFlashCards,
		ProductContentExams,
		ProductContentAssignments,
		ProductContentPlanners,
	},
	data() {
		return {
			currentPage:""
		};
	},
	methods: {		
		...mapActions("productVariant", ["getProductVariantById","getProductVariantProgress"]),		
		...mapActions("user", ["fetchCurrentUser","fetchTrainersList"]),
		...mapActions("content", ["getUserContentAction"]),
		checkProductvariant:function(){
			if(this.productVariantID)
			{
				var userProductVariant = (this.user && this.user.productVariants
					? this.user.productVariants.filter(
						(productVariant) =>
							productVariant.status != ProductVariantStatus.DRAFT && 
							productVariant.product && 
							productVariant.product.title && 
							productVariant.subscriptionStatus == ProductVariantSubscriptionStatus.ALLOWED &&
							(!productVariant.endDate || new Date(productVariant.endDate) >= new Date()) &&
							(!productVariant.expiresAt || new Date(productVariant.expiresAt) >= new Date())
					)
					: []).find(x=>x.id==this.productVariantID);

				if(userProductVariant){

					if (!userProductVariant.properties || !userProductVariant.properties.contentViewTheme || userProductVariant.properties.contentViewTheme.toLowerCase() != 'ebook') {
						this.$router.push("/product/details/"+userProductVariant.id);
					} 
				}else{
					this.$router.push("/product/details/"+this.productVariantID);
					
				}
				// console.log(userProductVariant,"userProductVariant");	
			}
		}
	},
	computed: {		
		
		...mapState("user", ["user"]),
		...mapState("content", ["content","contentActions"]),

	},
	created() {
		this.fetchCurrentUser();
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		if (this.$route.params.page && this.$route.params.page != this.currentPage) {
			this.currentPage = this.$route.params.page;
		}
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				console.log("Part 1");
				this.getProductVariantById(this.productVariantID);
				this.checkProductvariant();
		}
	},
	
	watch:{
		$route:function(to,from){
			if (from.query.contentId && from.query.contentId!=to.query.contentId) {

				if(	this.content[from.query.contentId] && 
					this.content[from.query.contentId].type==ContentType.RECORDED_VIDEOS &&
					!this.contentActions[from.query.contentId]?.find(x=>x.dataType.toLowerCase()=='completionstatus' && x.data.toLowerCase()=='completed'))
				{	
					var oldCID=from.query.contentId;
					this.getUserContentAction({contentId:oldCID}).then((actions)=>{
						if(actions?.find(x=>x.dataType.toLowerCase()=='completionstatus'))
						{							
							this.getProductVariantProgress(this.$route.params.id);
						}
					});
				}
			}
			
			if (this.$route.params.page && this.$route.params.page!=this.currentPage) {
				this.currentPage=this.$route.params.page;
			}
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				if (!this.productVariants || !this.productVariants.items[this.productVariantID]) {
					this.getProductVariantById(this.productVariantID);
				}
				this.checkProductvariant();
				
			}
		},
		productVariantID:function(){
			
		}
	}
};
</script>

<style>

  /*-======== Sidebar ================*/
  /*========== Body =======*/
.product-content-dash .spinner-border {
	border-color: var(--primary-color);
	border-right-color: transparent;
}
  .wrapper {
	width: 100%;
	will-change: auto;
	transition: padding .15s;
}

html:not([dir=rtl]) .wrapper {
	padding-left: var(--sidebar-occupy-start, 0);
	padding-right: var(--sidebar-occupy-end, 0);
}

.body {
	width: 100%;
}

@media (min-width: 1200px) {
	.body {
		/* max-width: 1200px; */
	}
}

.main-tabs-row {
	display: flex;
	border-bottom: solid 1px var(--border-color);
	padding: 0 20px;
	height:50px;
}

.main-tabs-row .nav-tabs {
	border: none;
}

.main-tabs-row h6 {
	margin-bottom: 0;
	line-height: 40px;
	margin-right: 20px;
	font-size: 16px;
}

.main-tabs a,
.main-tabs .nav-link {
	padding-top: 5px;
    padding-bottom: 5px;
    height: 50px;
    line-height: 44px;
}

.main-tabs a,
.main-tabs .nav-link,
.main-tabs .nav-link:hover {
	border: none !important;
	background: none;
	color: var(--primary);
	outline: none !important;
}

.main-tabs-row .nav-tabs .nav-link.active {
	background: none;
	border: none;
	border-bottom: solid var(--primary-color) !important;
	font-weight: 700;
}

.eb-content-wrapper {
	background-color: var(--white);
	padding: 20px 20px 15px 20px;
}

.eb-header {
	display: flex;
	/* border-bottom: solid 1px var(--border-color); */
	flex-wrap: wrap;
	height: 40px;
	align-items: center;
}

.eb-header.eb-header-auto {
	height: auto;
}

.eb-header.eb-header-auto {
	height: auto;
}

.border-bottom {
	border-bottom: solid 1px var(--border-color) !important;
}

.eb-sub-header {
	display: flex;
	border-bottom: solid 1px var(--border-color);
	flex-wrap: wrap;
	align-items: center;
}

.eb-header h5 {
	margin: 0 30px 0 0;
	line-height: 28px;
	font-size: 15px;
}

.eb-header h5 a {
	color: inherit;
}

.eb-header h5 i {
	margin-right: 5px;
}

.eb-header .eb-filter-toggle {
	padding: 0px 15px;
	line-height: 26px;
	font-size: 14px;
	vertical-align: middle;
	border-radius: 5px;
	background: none !important;
	border: solid 1px var(--border-color);
	font-weight: 500;
	height: 38px;
	min-width: 120px;
}

.eb-header .eb-filter-toggle img {
	width: auto;
	height: auto;
	max-height: 16px;
	margin-right: 3px;
}

.eb-header .eb-filter-toggle i {
	position: relative;
	bottom: -1px;
	left: 4px;
}

.eb-header .dropdown-toggle::after {
	display: none;
}
.eb-chapters-search{
	flex-grow: 1;
	padding-right: 10px;
}
.eb-chapters-search input[type="text"]{
	height: 38px;
	margin: 0;
	font-size: 14px !important;
}

.eb-filters {
	font-size: 14px;
}

.eb-filters .form-control,
.eb-filters .form-select,
.eb-filters .btn,
.eb-content-wrapper .form-control,
.eb-content-wrapper .form-select,
.eb-content-wrapper .btn {
	border-radius: 4px;
	box-shadow: none !important;
}

.eb-filters .form-select {
	cursor: pointer;
}

.eb-filter-area {
	padding: 5px 0px 10px;
	margin-bottom: 10px;
}

.eb-filters .btn-primary,
.eb-content-wrapper .btn-primary,
.eb-content .btn-primary {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	font-size: 15px;
}

.eb-filters .btn-primary:hover,
.eb-content-wrapper .btn-primary:hover,
.eb-content .btn-primary:hover {
	background-color: var(--primary-color-hover);
	border-color: var(--primary-color-hover);
}

.eb-filters .btn-outline-primary,
.eb-content-wrapper .btn-outline-primary,
.eb-content .btn-outline-primary {
	background-color: var(--btn-outline-primary); 
	border: solid 1px var(--border-color);
	color: var(--primary);
	font-size: 15px;
}

.eb-filters .btn-outline-primary:hover,
.eb-content-wrapper .btn-outline-primary:hover,
.eb-content .btn-outline-primary:hover {
	background-color: var(--gray-light-hover);
	border: solid 1px var(--border-color);
	color: var(--primary);
}

.eb-filters .btn-outline-primary:focus,
.eb-filters .btn-outline-primary:active,
.eb-content .btn-outline-primary:focus,
.eb-content .btn-outline-primary:active,
.eb-content .btn:first-child:active,
.eb-content :not(.btn-check)+.btn:active {
	background-color: var(--gray-light-hover);
	border: solid 1px var(--border-color);
	color: var(--primary);
}



/*=========== E-book Accordion =======*/
.eb-chapter-toggle {
	border: none;
	margin-bottom: 15px;
}

.eb-chapter-toggle .accordion-button {
	box-shadow: none !important;
}

.eb-chapter-toggle .accordion-button:focus {
	border-color: none;
	box-shadow: none;
}

.eb-chapter-toggle .accordion-button,
.eb-chapter-toggle .accordion-button:not(.collapsed) {
	background-color: #F8F8F8;
	font-weight: 600;
	padding: 2px 15px 1px 45px;
	justify-content: space-between;
	border-top-left-radius: 6px !important;
	border-top-right-radius: 6px !important;
	border: solid 1px var(--border-color);
	font-size: 15px;
	height: 42px;
}
.eb-chapter-toggle .accordion-button .head-text
{
	color: #231F20;
font-family: Circular Std;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.eb-chapter-toggle .accordion-button .side-text
{
	color: #4A4A4A;
font-family: Circular Std;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.eb-chapter-toggle .accordion-button.collapsed {
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
}

.eb-chapter-toggle .accordion-button:not(.collapsed)::after,
.eb-chapter-toggle .accordion-button::after {
	font-weight: 900;
	font-family: "Font Awesome 5 Pro";
	background: none;
	color: var(--white);
}

.eb-chapter-toggle .accordion-button:not(.collapsed)::after {
	content: "\f068";
	position: relative;
	left: -4px;
}

.eb-chapter-toggle .accordion-button::after {
	content: "\f067";
}

.eb-chapter-toggle .accordion-button:not(.collapsed)::after,
.eb-chapter-toggle .accordion-button::after {
	position: absolute;
	left: 10px;
	transform: none;
	color: white;
	background-color: #333;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: block;
	text-align: center;
	line-height: 22px;
	font-size: 12px;
}

.marked-count {
	font-size: 14px;
}
.eb-label{
	display: inline-block;
	border-radius: 6px;
	padding: 6px 10px 5px;
	border:solid 1px var(--border-color);
	color: var(--primary-link-color);
	font-weight: 400 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	line-height: 1;
}
.label-completed{	
	border:solid 1px var(--color-label-succes);
	color: var(--color-label-succes);
}
.eb-toggle-header .eb-label{
	font-size: 13px;
}

.eb-chapter-toggle .accordion-body {
	padding: 0;
	border: solid 1px var(--border-color);
	border-top: none;
	border-radius: 0 0 6px 6px;
}

.eb-chapter-list {
	display: flex;
	flex-flow: column nowrap;
}

.eb-chapter-row {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	border-bottom: 1px solid var(--border-color);
}

.chapter-row-item {
	flex: 1;
	font-size: 15px;
	transition: all 0.15s ease-in-out;
}

.chapter-row-item a {
	color: var(--primary-link-color);
	text-decoration: none;
	font-weight: 500;
	display: block;
	padding: 8px 0;
}

.chapter-row-item a:hover {
	color: var(--primary-color);
}

.eb-chapter-row>.chapter-row-item:first-of-type {
	padding-left: 15px;
	flex-grow: 0.3;
}

.chapter-row-sub-container {
	flex-flow: column nowrap;
	flex: 1;
}

.chapter-row-subitems {
	padding: 0;
	margin: 0;
	flex: 1;
	list-style: none;
}

.chapter-row-sub-container .chapter-row-item,
.chapter-row-subitems>.chapter-row-item {
	border-bottom: 1px solid #dadada;
	padding: 5px;
}

.chapter-row-subitems>.chapter-row-item>a {
	flex: 1;
}

.accordion-item {
	border: 0px !important;
	padding: 0px;
}

.eb-chapter-row:last-child,
.chapter-row-sub-container .chapter-row-item:last-child,
.chapter-row-subitems>.chapter-row-item:last-child {
	border-bottom: 0;
}

.confidence-label-low{	
	border-color: var(--confidence-label-low);
}
.confidence-label-medium{	
	border-color: var(--confidence-label-medium);
}
.confidence-label-high{	
	border-color: var(--confidence-label-high);
}
.confidence-label-low svg path{
  fill: var(--confidence-label-low);
  stroke: var(--confidence-label-low);
}
.confidence-label-medium svg path{
  fill: var(--confidence-label-medium);
  stroke: var(--confidence-label-medium);
}
.confidence-label-high svg path{
  fill: var(--confidence-label-high);
  stroke: var(--confidence-label-high);
}

/*======== Chapter Details =========*/

.eb-chapter-arrows {
	padding: 0 2px 0 4px;
}

.eb-chapter-arrows button,
.eb-chapter-controls button,
.eb-edit-bar button {
	background: none !important;
	border: none !important;
	width: 28px;
	height: 28px;
	margin: 0;
	text-align: center;
	padding: 0;
	margin: 0;
	font-size: 18px;
}

.eb-chapter-controls button {
	opacity: 0.70;
}

.eb-chapter-controls button:hover,
.eb-chapter-controls button:active {
	opacity: 0.90;
}

.eb-chapter-controls {
	padding: 0 4px;
}

.eb-header .vr {
	min-height: 28px;
	align-self: auto;
	opacity: 1;
	background-color: var(--border-color);
	margin: 0 4px;
}

.eb-chapter-controls .vr {
	margin: 0 8px;
}

.chapter-details {
	display: flex;
	font-size: 15px;
	flex-direction: column;
}

.cd-head {
	border: solid 1px var(--border-color);
	border-radius: 6px 6px 0 0;
	border-bottom-width: 0;
}

.cd-body {
	border: solid 1px var(--border-color);
	min-height: calc(100vh - 248px);
	height: calc(100vh - 248px); 
}
.round-top {
    border-radius: 6px 6px 0 0 !important;
}
.round {
	border-radius: 6px !important;
}

.round-bottom {
	border-radius: 0 0 6px 6px !important;
}
.btn-round{
	border-radius: 70px !important;
}

.cd-footer {
	border: solid 1px var(--border-color);
	border-top-width: 0;
	border-radius: 0 0 6px 6px;
	height: 44px;
}

.cd-left-col {
	flex: 3.4 1 0;
	display: flex;
	flex-direction: column;
}

.cd-right-col {
	flex: 1 1 0;
  }
  .chapter-content{
	padding: 0px;
	height: inherit; 
	overflow: auto;
  }
  .chapter-content.has-floating-menu{
	position: relative;
	padding-right: 20px;
  }
  .chapter-content.freeTextContent 
  {
	/* padding-right: 0px; */
  }
  .chapter-content.freeTextContent .chapter-content-left
  {
	padding-right: 0px;
  }
  .main-heading{
	font-weight: 700;
	color: var(--primary-color);
	margin: 5px 0 20px;
	font-size: 1.45rem;
}

.main-heading:after {
	content: '';
	display: block;
	border-bottom: solid 2px var(--primary-color);
	width: 25%;
	min-width: 100px;
	max-width: 150px;
	margin-top: 15px;
}

.cd-sidebar {
	padding: 20px 30px 30px;
}

.confidence-levels .confidence-btn {
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-bottom: 0;
	font-weight: 600;
	padding: 7px 5px;
	cursor: pointer;
}

.confidence-levels svg {
	margin-right: 5px;
	margin-top: -2px;
}

.confidence-levels svg path {
	stroke-width: 0.95pt;
}

.cd-sidebar .form-check {
	padding: 0;
}

.confidence-levels .form-check-input[type=radio],
.rating-bars .form-check-input[type=radio] {
	position: absolute;
	left: -999999px;
	width: 1px;
	height: 1px;
}

.form-check-input:checked+.rating-btn {
	background-color: var(--confidence-level-selected);
}

.form-check-input:checked+.confidence-btn svg path {
	fill: var(--progress-bar);
	stroke: var(--progress-bar);
}

.form-check-input.high:checked+.confidence-btn svg path {
	fill: var(--progress-bar);
	stroke: var(--progress-bar);
}

.form-check-input.medium:checked+.confidence-btn svg path {
	fill: gold;
	stroke: gold;
}

.form-check-input.low:checked+.confidence-btn svg path {
	fill: red;
	stroke: red;
}

.sidebar-chapter-ref p {
	font-size: 14px;
}

.eb-content-wrapper a.red {
	text-decoration: underline;
	color: var(--primary-color);
}

.eb-content-wrapper a.red:hover {
	text-decoration: none;
}

.eb-content-wrapper button.red {
	text-decoration: underline;
	color: var(--primary-color);
	background: none !important;
	border: none !important;
}

.eb-content-wrapper button.red:hover {
	text-decoration: none;
}

.chapter-dd-btn {
	width: 350px;
	text-align: left;
	display: flex;
	align-items: center;
	padding: 0px;
	height: 46px;
	border: none !important;
	border-right: solid 1px var(--border-color) !important;
}

.chapter-dd-btn img {
	margin-right: 6px;
	width: 20px;
}

.chapter-dd-btn span {
	flex-grow: 1;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.chapter-dd-btn::after {
	border: none;
	position: absolute;
	right: 15px;
}

.cd-chapter-selection .dropdown-menu {
	max-width: 350px;
	width: 100%;
	border-radius: 0 0 6px 6px;
	box-shadow: 2px 6px 8px 1px rgba(0, 0, 0, 0.09);
}

.cd-chapter-selection ul {
	list-style-type: none;
	margin: 0;
}

.cd-chapter-selection li a {
	border-bottom: solid 1px var(--border-color);
	line-height: 1.4;
	white-space: normal;
	font-size: 14px;
	padding: 10px 0;
}

.cd-chapter-selection li:last-child a {
	border-bottom: none;
  }
  .has-floating-menu .chapter-content-left{
	padding-right:50px;
  }
  .floating-menu{
	position: sticky;
	float:right;
	top: 0px;
  }
  .floating-icons{
	display: flex;
	flex-direction: column;
}
.RecordedVideos .floating-menu,
.StudyMaterial .floating-menu{
	top:10px;
	right:8px;
}
.RecordedVideos .chapter-content-left,
.RecordedVideos > .chapter-content-left > div{
	height: inherit;
}

.float-icon {
	border-radius: 6px;
	border: solid 1px var(--border-color);
	text-align: center;
	width: 32px;
	height: 32px;
	margin-bottom:10px;
	padding: 4px;
	line-height: 20px;
	transition: all 0.2s linear;
	color: black;
	background-color: white;
  }
  .float-icon:hover{
	background-color: #eee;
}

.float-icon.active {
	color: var(--primary-color);
}

.float-icon img {
	max-width: 100%;
	max-height: 18px;
	width: auto;
}

.cd-footer-nav .btn-prev img {
	margin-right: 2px;
}

.cd-footer-nav .btn-prev img {
	transform: rotate(90deg);
}

.cd-footer-nav .btn-next img {
	margin-left: 2px;
}

.cd-footer-nav .btn-next img {
	transform: rotate(-90deg);
}




.eb-single-note {
	border: solid 1px var(--border-color);
	border-radius: 6px;
}

.eb-edit-bar {
	display: flex;
	width: 100%;
	justify-content: end;
	align-items: center;
}

.eb-edit-bar button {
	margin: 0 4px;
	font-size: 15px;
	opacity: 0.65;
}

.eb-edit-bar button:hover {
	opacity: 0.75;
}

.note-ref-meta {
	color: var(--gray);
	font-size: 13px;
}

.eb-content hr {
	border-top-color: rgba(0, 0, 0, 0.35);
}

.eb-single-highlight {
	margin-top: 15px;
}

.highlight-text {
	padding-left: 20px;
	border-left: solid 3px var(--highlight-border);
}

.bookmarks-table>thead>tr>th {
	border-bottom: none !important;
	padding-bottom: 15px;
}

.bookmarks-table thead th {
	font-weight: 400;
}

.eb-table {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	margin-bottom: 0.25rem;
}

.eb-table>li {
	display: flex;
}

.eb-table>li>.t-cell {
	flex: 1;
	padding: 0.5rem 0.5rem;
	border-bottom-color: var(--bs-border-color);
	border-bottom-width: var(--bs-border-width);
	border-bottom-style: solid;
}

.eb-table>li:last-child>.t-cell {
	border-bottom: none;
}

.bookmarks-table>.thead>.t-cell {
	padding-bottom: 0.85rem;
	border-bottom: none;
	font-weight: 600;
}

.bookmarks-table>li>.t-cell:first-child {
	flex: 2;
}

.bookmarks-table>li>.t-cell:nth-child(2) {
	flex: 4;
}

.bookmarks-table>li>.t-cell:last-child {
	flex: 0.85;
}

.eb-table>li>.t-cell-link {
	padding-top: 0;
	padding-bottom: 0;
}

.eb-table>li>.t-cell-link>a {
	display: block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: var(--primary-link-color);
}

.eb-table>li>.t-cell-link>a:hover,
.eb-table>li>.t-cell-link>a:active,
.eb-table>li:hover>.t-cell-link>a {
	color: var(--primary-color);
}

/*========== Modal =============*/
.eb-content-wrapper .modal-content {
	border-radius: 0;
	border-top: solid 7px var(--border-color);
}

.eb-content-wrapper .modal-dialog-scrollable .modal-content {
	overflow: visible;
}

.eb-modal .modal-header .btn-close {
	position: absolute;
	right: -19px;
	top: -34px;
	color: white;
	opacity: 1;
	background: none;
	font-size: 20px;
}

.eb-modal .modal-footer {
	padding-top: 8px;
	padding-bottom: 8px;
}

.eb-modal .modal-title {
	font-size: 1.15rem;
}

.keypoint .card-header {
	background-color: var(--primary-color);
	color: white;
	border-radius: 0 !important;
}

.keypoint .card-body {
	border-color: var(--primary-color);
}

.accordion-item.eb-chapter-toggle .card-header {
	padding: 0px;
}

.keypoint {
	border-color: var(--primary-color);
	border-radius: 0;
}

.eb-modal .main-heading:after {
	margin-top: 15px;
}

.eb-modal .main-heading {
	margin: 5px 0 15px;
}

.eb-modal {
	background-color: rgba(0, 0, 0, 0.35);
}

.eb-modal .btn {
	font-size: 14px;
}

/*======= Rating Modal ======*/
.rating-info {
	font-size: 13px;
	color: var(--gray);
	justify-content: space-between;
}

.rating-labels .rating-btn {
	font-size: 14px;
	color: var(--gray);
  }
  
  /*======= Flashcards =========*/
  .flashcard-content{
	/* padding: 25px 0 50px; */
  }
 
 
 


  .flashcard-table>:not(caption)>*>*,
  .flashcard-table tr td {
	padding-bottom: 1.25rem;
	padding-top: 0.8rem;
  }
  .flashcard-table>:not(caption)>*>* {
	padding-bottom: 0.8rem;
  }
  .flashcard-table tbody tr:last-child td {
	border-bottom: none !important;
  }
  
  /*======== Practice ============*/
  .eb-head-progress .progress{
	height: 24px;
}

.progress-bar {
	background-color: var(--primary-color);
}

.eb-summary {
	display: flex;
}

.summary-item {
	border-left: solid 2px var(--primary-color);
	padding: 2px 25px 2px 10px;
	color: var(--gray);
	display: flex;
	flex-direction: column;
	min-width: 20%;
}

.summary-item>div {
	margin: 2px 0;
}

.sub-tabs>li:first-child .nav-link {
	padding-left: 5px;
	padding-right: 5px;
}

.questions-table .thead .t-cell {
	font-weight: 600;
}

.questions-table>li>.t-cell:first-child {
	flex: 2.5;
}

.questions-table>li>.t-cell-link>a {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}

.eb-table-arr>li>.t-cell-link:last-child,
.eb-table-arr>li>.t-cell:last-child {
	position: relative;

}

.eb-table-arr .t-cell-link:last-child a {
	position: relative;
	padding-right: 30px;
}

.eb-table-arr .t-cell-link:last-child a:before {
	/* content: "\f101";
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	position: absolute;
	right: 10px;
	font-size: 20px;
	font-weight: 700;
	-webkit-text-stroke: 1px #fff; */
}

.eb-table-arr .t-row {
	position: relative;
}

.eb-table-arr .t-row:before {
	position: relative;
	content: "\f101";
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	display: block;
	font-style: normal;
	font-variant: normal;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	right: 10px;
	font-size: 20px;
	font-weight: 700;
	-webkit-text-stroke: 1px #fff;
	opacity: 0;
	visibility: hidden;
}

.eb-table-arr .t-row:hover:before {
	opacity: 1;
	visibility: visible;
}

.qr-table>li>.t-cell {
	flex: 0.8;
}

.qr-table>li>.t-cell:nth-child(1),
.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.15;
	text-align: center;
}

.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.5;
}

.qr-table>li>.t-cell:nth-child(3) {
	flex: 5;
}

.qr-table>li>.t-cell:last-child {
	flex: 1.1;
}

.qr-table>li>.t-cell:nth-last-child(2) {
	flex: 0.6;
}

.practice-tabs .eb-header {
	height: auto;
}

.eb-table .status-icon {
	width: 24px;
	height: auto;
}

/*========== Quiz ===========*/
.quiz-result-box {
	background-color: rgba(var(--primary-color), 0.25);
	background-color: var(--gray-light);
	min-width: 150px;
	min-height: 140px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quiz-result-box .quiz-result-stat {
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: 10px;
	font-weight: 300;
}

.eb-quiz-summary .summary-item {
	min-width: 25%;
}

/*-------- Live Class -------*/
.datebox{
  border-radius: 8px;
  text-align: center;
  padding: 2px;
  border: solid 1px var(--border-color);
  font-weight: 700;
  width: 44px;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.datebox-day{
  color: var(--primary-color);
  font-size: 18px;  
  display: block;
  line-height: 16px;
}
.datebox-month{
  font-size: 12px;
  line-height: 14px;
  display: block;
  font-weight: 600;  
} 

.vs__clear {
	display: none;
}

.vue-treeselect__x {
	display: none;
}
.progress-bar {	
	background: linear-gradient(to right, #FFAA00, #9CB828 68.6%, #51C346 86.3%, #07CE64 100%);
}

@media (max-width:768px){
	html:not([dir=rtl]) .wrapper {
		padding-left: 0;
	}
	.main-tabs-row {
		padding: 0;
	}
	.eb-header {
		padding: 0 10px;
	}
	.eb-filter-area {
		padding: 5px 10px 10px;
	}
	.eb-filters .filter-bookmark[data-v-fd617276] {
		padding: 5px 0 0 0;
	}
	.eb-filters,
	.eb-filters .btn-outline-primary,
	.eb-content-wrapper .btn-outline-primary,
	.eb-content .btn-outline-primary {
		font-size: 1rem;
	}

	.eb-content-wrapper {
		padding: 15px 0px 15px 0px;
	}
	.eb-chapter-toggle .accordion-button,
	.eb-chapter-toggle .accordion-button:not(.collapsed) {
		border-radius: 0 !important;
    	border-left-style: none;
    	border-right-style: none;
		height: 48px;
	}
	.eb-chapter-toggle {
		margin-bottom: 5px;
	}
	.eb-chapter-toggle .accordion-body{
		border: none;
	}
	.eb-chapter-toggle .accordion-button .head-text{
		font-size: 1.2rem;
	}
	.eb-chapter-row {
		flex-flow: column;
		border-bottom: 1px solid var(--border-color);
	}
	.eb-chapter-row>.chapter-row-item:first-of-type {
		border-bottom: 1px solid var(--border-color);
	}
	.chapter-row-subitems>.chapter-row-item {
		border: none;
		padding: 8px 5px 8px 12px;
	}
	.chapter-row-item {
		font-size: 1.15rem; 
	}
	.chapter-row-item a {
		padding: 12px 0 10px;
	}
	.cd-head{
		border-radius: 0;
		border-left-style: none;
    	border-right-style: none;
	}
	.chapter-dd-btn {
		width:100%;
		border: none !important;
	}
	.cd-body {
		border-left-style: none;
    	border-right-style: none;
		height: auto;
	}
	.chapter-content.has-floating-menu{
		padding-right: 10px;
	}
	.has-floating-menu .chapter-content-left{
		padding-right: 10px;
	}
	.cd-footer-nav .btn-next span,
	.cd-footer-nav .btn-prev span {
		display: none;
	}
	.StudyMaterial .floating-menu{
		top: 60px;
		right: 10px;
		position: absolute;
		background-color: #fff;
		padding: 10px 8px 0;
		box-shadow: 0 0 6px rgba(0,0,0,0.09);
	}
	.RecordedVideos .floating-menu{
		position: absolute;
	}
	.RecordedVideos .chapter-content-left{
		padding-right: 0;
	}
	.Assignment .floating-menu{
		z-index: 3;
	}
	.Assignment.has-floating-menu .chapter-content-left{
		padding-right: 0;
	}

}
</style>

<style scoped>


</style>