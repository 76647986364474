<template>
  <div class="product-list-item" :id="productData.id">
    <button
      v-if="mode == 'view'"
      v-b-toggle="`collapse-${productData.id}-${categoryId}`"
      role="button"
      class="product-collapse-button"
      :id="`collapse-btn-${productData.id}-${categoryId}`"
      @click="toggleCollapse"
      @dragenter="expandCollapse(productData.id)"
    >
      <img src="@/assets/images/caret-down.svg" class="caret-icon" />
			<img src="../../../assets/images/drag-icon.svg" alt="" style="cursor: grab;" class="product-drag-handler" />
      {{ title }}
      <div class="right-menu">
        <a @click="toggleModal" role="button" class="right-menu-icon">
          <img src="@/assets/images/plus-icon.svg" />
        </a>
        <a
          role="button"
          @click="changeMode($event, 'edit')"
          class="right-menu-icon"
        >
          <img src="@/assets/images/edit-icon.svg" />
        </a>
        <a
          role="button"
          @click="showDeleteConfirmPopup"
          class="right-menu-icon"
        >
          <img src="@/assets/images/delete-icon.svg" />
        </a>
        <a
          @click="openCategoryModal"
          role="button"
          class="right-menu-icon"
          v-if="isUncategorized"
          title="Assign Qualification"
        >
          <i class="far fa-graduation-cap"></i>
        </a>
        <b-modal
          id="categoryModal"
          :visible="showCategoryModal"
          centered
          :title="`Assign Qualifications to ${productData.title}`"
          ok-title="Assign"
          @change="showCategoryModal = false"
          @ok="assignCategory"
        >
          <b-form-checkbox-group
            :options="categoryOptions"
            v-model="selectedCategories"
            name="categorySelected"
            plain
            stacked
          ></b-form-checkbox-group>
        </b-modal>
      </div>
    </button>
    <b-collapse
      v-if="mode == 'view'"
      :id="`collapse-${productData.id}-${categoryId}`"
      class="product-collapse-body"
    >
      <ProductVariantList
        :productVariantList="productVariantList"
        :productId="productData.id"
        @updateProductVariant="updateVariant"
        :categoryId="categoryId"
      />
    </b-collapse>
    <form v-if="mode == 'edit'" @submit="editProduct">
      <input
        type="search"
        class="update-product-input"
        v-model="updatedTitle"
        placeholder="Enter new title"
        id="update-product-input"
        required
        @blur="blur"
        v-on:keyup.enter="editProduct"
        v-on:keyup.esc="changeMode($event, 'view')"
      />
      <button
        @click="editProduct"
        type="button"
        class="content-action-btn save"
      >
        <img src="@/assets/images/check-icon-green.svg" alt="save" />
      </button>
      <button
        @click="changeMode($event, 'view')"
        class="content-action-btn cancel"
        type="button"
        role="button"
      >
        <img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
      </button>
    </form>
    <CreateProductVariantModal
      @updateModalState="updateModalState"
      :showModal="showModal"
      :productId="productData.id"
    />
  </div>
</template>

<script>
import ProductVariantList from "../ProductVariant/ProductVariantList.vue";
import CreateProductVariantModal from "../ProductVariant/CreateProductVariantModal.vue";
// import Product from "../../../models/product";
import { mapActions, mapState } from "vuex";
export default {
  name: "ProductListItem",
  components: { ProductVariantList, CreateProductVariantModal },
  data() {
    return {
      mode: "view",
      productVariantList: null,
      updatedTitle: "",
      title: "",
      expanded: false,
      isLoaded: false,
      showModal: false,
      selectedCategories: [],
      showCategoryModal: false,
    };
  },
  props: { productData: Object, categoryId: String, isUncategorized: Boolean },
  methods: {
    ...mapActions("productVariant", [
      "getProductVariantByProductId",
      "PasteProductVariants",
    ]),
    ...mapActions("product", [
      "updateProduct",
      "deleteProduct",
      "getProductByCategoryId",
    ]),
    ...mapActions("category", ["assignCategoryToProduct"]),
    changeMode(e, mode) {
      e.stopPropagation();
      console.log("changing mode to", mode);
      if (mode == "edit") {
        this.updatedTitle = this.productData.title;
        // this.mode = mode;
        setTimeout(
          () => document.getElementById("update-product-input").focus(),
          300
        );
      }
      this.mode = mode;
    },
    editProduct(e) {
      this.updatedTitle = this.updatedTitle.trim();
      e.preventDefault();
      if (
        this.title !== this.updatedTitle &&
        !this.loading &&
        this.updatedTitle.length > 0
      ) {
        var updatedObject = {
          title: this.updatedTitle,
          id: this.productData.id,
        };
        this.updateProduct({
          product: updatedObject,
          id: this.categoryId,
        }).then(() => {
          this.mode = "view";
          this.title = this.updatedTitle;
          // this.updatedTitle = "";
        });
        console.log("update called");
      } else {
        this.mode = "view";
      }
    },
    blur(e) {
      setTimeout(() => this.changeMode(e, "view"), 300);
    },
    toggleCollapse() {
      this.expanded = !this.expanded;
    },
    expandCollapse(id) {
      document.getElementById(`collapse-btn-${id}`).click();
    },
    updateVariant(response) {
      this.productVariantList = this.productVariantList.map((variant) => {
        if (variant.id == response.id) {
          return response;
        }
        return variant;
      });
    },
    updateModalState(value) {
      this.showModal = value;
    },
    toggleModal(e) {
      e.stopPropagation();
      this.showModal = !this.showModal;
    },
    showDeleteConfirmPopup(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete the Course: ${this.title}?`,
          {
            title: "Are you sure?",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
            bodyClass: "confirm-modal-body",
          }
        )
        .then((value) => {
          // this.isConfirm = value;
          if (value) {
            this.productDelete();
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
    productDelete() {
      if (!this.loading)
        this.deleteProduct({
          product: this.productData,
          id: this.categoryId,
        }).then(() => {
          console.log("Product deleted");
        });
    },
    openCategoryModal(e) {
      e.stopPropagation();
      this.showCategoryModal = true;
    },
    assignCategory(e) {
      if (this.selectedCategories.length > 0) {
        var categoryAssignProduct = {
          productID: this.productData.id,
          categories: this.selectedCategories,
        };
        this.assignCategoryToProduct(categoryAssignProduct).then(() => {
          this.selectedCategories.forEach((category) => {
            this.getProductByCategoryId(category);
          });
          this.selectedCategories = [];
          this.showCategoryModal = false;
        });
      }
    },
    pasteContent() {
      console.log("pasting content");
      var assignContentObject = {
        ProductVariantID: this.copiedProductVariant.id,
        TargetProductID: this.productData.id,
      };
      console.log({ assignContentObject });

      this.PasteProductVariants(assignContentObject).then(() => {
        // this.getProductVariantById(
        //   assignContentObject.TargetProductVariantID
        // ).then((response) => {
        //   this.filteredProductVariantItems =
        //     response.productVariantItems.length > 0
        //       ? response.productVariantItems
        //       : [];
        // });
        console.log("assigned content to productVariant", assignContentObject);
        this.getProductVariantByProductId(this.productData.id).then(
          (response) => {
            this.productVariantList = response;
          }
        );
        this.$toasted.success("Course pasted successfully", {
          duration: 3000,
          theme: "outline",
          icon: "check",
          position: "bottom-center",
        });
      });
    },
  },
  computed: {
    ...mapState("productVariant", [
      "productVariantsByProduct",
      "copiedProductVariant",
    ]),
    ...mapState("product", ["loading"]),
    ...mapState("category", ["categories"]),
    showSave() {
      const titleChanged = this.updatedTitle !== this.title;
      const titleNotEmpty = this.updatedTitle !== "";
      return titleChanged && titleNotEmpty;
    },
    categoryOptions() {
      return this.categories.map((category) => ({
        text: category.title,
        value: category.id,
      }));
    },
  },
  watch: {
    expanded: function () {
      if (this.expanded) {
        this.getProductVariantByProductId(this.productData.id).then(() => {
          this.productVariantList =
            this.productVariantsByProduct[this.productData.id];
        });
      }
    },
    productVariantsByProduct: function () {
      this.productVariantList =
        this.productVariantsByProduct[this.productData.id];
    },
  },
  created() {
    this.title = this.productData.title;
  },
  mounted() {
    var _this = this;
    $.contextMenu({
      selector: `#${_this.productData.id}`,
      callback: (key, options) => {
        switch (key) {
          case "paste":
            _this.pasteContent();
        }
      },
      items: {
        paste: {
          name: "Paste",
          icon: "paste",
          disabled: () => !_this.copiedProductVariant,
        },
      },
    });
  },
};
</script>

<style scoped>
.product-list-item form {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.product-collapse-button {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.875rem 0.75rem;
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  color: #000000;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  position: relative;

  /* transition: transform 0.3s; */
}

.update-product-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.875rem 0.75rem;
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.75rem;
  padding-right: 5.25rem;
}

.caret-icon {
  transition: transform 0.3s;
}

.product-collapse-button.not-collapsed .caret-icon {
  transform: rotate(-180deg);
}

.product-collapse-button .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  margin-left: auto;
  margin-right: 0.75rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 0.75rem;
  background: #ffffff;
}

.product-collapse-button:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.content-action-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  /* padding: 0.375rem; */
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.875rem;
}

.content-action-btn.save {
  border: 1px solid #21cf47;
  color: #21cf47;
  position: absolute;
  right: 3rem;
}
.content-action-btn.cancel {
  border: 1px solid #ff0000;
  color: #ff0000;
  position: absolute;
  right: 0.75rem;
}

.right-menu-icon {
  color: #000000;
  line-height: 1;
}
</style>
