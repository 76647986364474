<template>
  <b-modal
      @change="closeModal()"
      :id="`upload-study-material-modal-${parentContentId}`"
      :visible="visible"
      size="xl"
      hide-header
      hide-footer
      centered
      :content-class="`upload-study-material-modal ${parentContentId}`"
      body-class="modal-body"
      modal-class="custom-modal"
      no-close-on-backdrop
  >
    <div class="body-container">
      <button @click="closeModal()" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <button
          v-if="isFileAdded && !isLinkUpload && !isEditMode"
          @click="uploadInBackground()"
          class="minimize-button"
          role="button"
      >
        <i
            :class="[
						'fas',
						{ 'fa-window-minimize': !isMinimized, 'fa-clone': isMinimized },
					]"
        ></i>
      </button>
      <span class="video-title">
				{{ parseInt(fileProgress) }}% - {{ title }}
			</span>
      <h1 class="add-content-title">
        {{ isEditMode ? "Edit" : "Upload" }} Study Material
      </h1>
      <!-- <form> -->
      <div class="body-content-container">
        <div
            :class="[
						'info-container',
						{ expand: isFileAdded, link: isLinkUpload },
					]"
        >
          <div class="form-group">
            <label class="main-label" for="title"
            >Study Material Title (required)</label
            >
            <input
                placeholder="Type your lecture name here..."
                class="form-input"
                type="search"
                name="title"
                id="title"
                v-model="title"
                required
            />
          </div>
          <div v-if="isLinkUpload" class="form-group mt-5">
            <label class="main-label" for="fileUrl">File Link (required)</label>
            <input
                placeholder="Enter Link to upload ..."
                class="form-input"
                type="search"
                name="fileUrl"
                id="fileUrl"
                v-model="fileUrl"
                required
            />
          </div>
          <div class="form-group">
            <div class="flex-row mt-5">
              <div class="col-lg-6 ps-0">
                <div class="form-group">
                  <label for="start-date" class="main-label m-0"
                  >start date (optional)</label
                  >
                  <input
                      placeholder="Select start date"
                      class="form-input"
                      type="date"
                      name="start-date"
                      id="start-date"
                      v-model="startDate"
                  />
                </div>
              </div>
              <div class="col-lg-6 pe-0">
                <div class="form-group">
                  <label for="end-date" class="main-label m-0"
                  >end date (optional)</label
                  >
                  <input
                      placeholder="Select end date"
                      class="form-input"
                      type="date"
                      name="end-date"
                      id="end-date"
                      v-model="endDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <div class="form-check form-switch">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isAvailableForDemo"
                  v-model="isAvailableForDemo"
              />
              <label class="form-check-label" for="isAvailableForDemo"
              >Available for Demo</label
              >
            </div>
          </div>
          <div class="form-group mt-4" >
            <div class="form-check form-switch" v-if="!isPDFVSS">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="allowDownload"
                  v-model="allowdownload"
              />
              <label class="form-check-label" for="allowDownload"
              >Show Download/Print Button?</label
              >
            </div>
          </div>
        </div>
        <div
            :class="[
						'upload-container',
						{ shrink: isFileAdded, 'd-none': isLinkUpload },
					]"
        >
          <vue-dropzone
              v-if="!isEditMode"
              ref="fileDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              v-on:vdropzone-file-added="uploadFile"
              v-on:vdropzone-sending="sendingEvent"
              v-on:vdropzone-success="getFileUrl"
              v-on:vdropzone-total-upload-progress="updateStudyMaterialProgress"
          >
            <div class="dropzone-custom-content">
              <h3 class="dropzone-custom-title">
                Drag and drop to upload content!
              </h3>
              <div class="subtitle">
                ...or click to select a file from your computer
              </div>
              <button role="button" class="upload-btn mt-4">
                <i class="fas fa-file-import"></i>
                Select File
              </button>
              <button
                  @click="uploadWithLink"
                  role="button"
                  class="upload-btn ms-3 mt-4"
              >
                <i class="fas fa-link"></i>
                Upload Using Link
              </button>
            </div>
          </vue-dropzone>
          <b-overlay class="preview square" v-else :show="previewLoading">
            <img
                @click="toggleStudyMaterialModal(true)"
                v-b-tooltip.hover.bottom="'View'"
                src="@/assets/images/study-material.png"
            />
          </b-overlay>
        </div>
      </div>
      <p class="text-danger">{{ dateErrorText }}</p>
      <div v-if="isFileAdded" class="step-btn-container">
        <button
            @click="upload"
            role="button"
            class="step-btn"
            :disabled="disableNext() || loading"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ isEditMode ? "Update" : "Upload" }}
          <img src="@/assets/images/arrow-right.svg" />
        </button>
      </div>
      <!-- </form> -->
    </div>
    <StudyMaterial
        v-if="isEditMode && contentData.type == ContentType.STUDY_MATERIAL"
        :showModal="showStudyMaterialModal"
        @updateModalState="toggleStudyMaterialModal"
        :contentId="contentData.id"
    />
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";
import { POST_FILE_API } from "../../../constants/urls";
import { ContentType } from "../../../constants/content";
import CookieHelpers from "../../../helpers/CookieHelpers";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import StudyMaterial from "../../../components/Content/StudyMaterial.vue";

export default {
  name: "UploadStudyMaterialModal",
  components: {
    StudyMaterial,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: this.getUploadURL,
        timeout: 100000000,
        thumbnailWidth: 300,
        uploadMultiple: false,
        maxFiles: 1,
        method: "POST",
        autoProcessQueue: true,
        createImageThumbnails: false,
        headers: {
          AuthToken: "System123",
          Authorization: `Bearer ${CookieHelpers.getCookie("token")}`,
        },
      },
      fileName: null,
      isFileAdded: false,
      type: "StudyMaterial",
      entity: "Content",
      title: null,
      startDate: null,
      endDate: null,
      isAvailableForDemo: false,
      allowdownload: false,
      fileUrl: null,
      fileType: null,
      isLinkUpload: false,
      isMinimized: false,
      fileProgress: 0,
      allOk: true,
      dateErrorText: "",
      showStudyMaterialModal: false,
      previewLoading: false,
      ContentType,
    };
  },
  methods: {
    ...mapActions("content", [
      "createStudyMaterialContent",
      "uploadContentFile",
      "getContentById",
      "updateContent",
    ]),
    uploadFile(file) {
      // document.getElementById("fileaname").value = "";
      // var file = document.getElementById("file").files[0];
      console.log(file);
      this.isFileAdded = true;
      this.fileName = file.name;

      var fileExt = this.fileName.toLowerCase().split(".").at(-1);
      console.log(fileExt);
      switch (fileExt) {
        case "ppt":
        case "pptx":
        case "doc":
        case "docx":
        case "xls":
        case "xlsx":
        case "csv":
          this.fileType = "document";
          break;
        case "pdf":
          this.fileType = "pdf";
          break;
        case "txt":
          this.fileType = "text";
          break;
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
        case "gif":
          this.fileType = "image";
          break;
      }

      var parsedFilename = this.fileName.split(".")[0];

      this.title = parsedFilename;
      // this.isAvailableForDemo = true;
      document.getElementById("title").focus();
      // this.$refs.fileDropzone.dropzone.processQueue();

      // var formData = new FormData();
      // formData.append("type", this.type);
      // formData.append("entity", this.entity);
      // formData.append("file", file);
      // console.log(formData);

      // var fileUpload = {
      // 	type: this.type,
      // 	entity: this.entity,
      // 	file: file,
      // };

      // this.uploadContentFile(formData).then(() => {
      // 	console.log("file has been uploaded");
      // });
    },
    sendingEvent(file, xhr, formData) {
      formData.append("entity", this.entity);
      formData.append("type", this.type);
    },
    getFileUrl(file, response) {
      if (response && response.payload) this.fileUrl = response.payload;
      console.log(this.fileUrl);
    },
    disableNext() {
      if (this.isEditMode) return false;
      if (!this.isLinkUpload && this.fileProgress < 100) {
        return true;
      }
      if (this.isLinkUpload) {
        if (this.title && this.fileUrl) {
          return false;
        } else return true;
      } else if (this.title && this.fileUrl) return false;
      return true;
      // return false;
    },
    upload() {
      if (this.startDate && this.endDate) {
        this.allOk = new Date(this.startDate) <= new Date(this.endDate);
        console.log(this.allOk);
        if (!this.allOk) {
          this.dateErrorText = "Start Date cannot be after End Date";
        } else {
          this.allOk = true;
          this.dateErrorText = "";
        }
      }

      if (this.allOk) {
        if (this.isEditMode) {
          var obj = {
            id: this.id,
            parentDirectoryID: this.parentContentId,
            title: this.title,
            availableFrom: this.startDate
                ? new Date(this.startDate).toISOString()
                : null,
            availableTo: this.endDate
                ? new Date(this.endDate).toISOString()
                : null,
            isAvailableForDemo: this.isAvailableForDemo,
            data: {
              allowdownload: this.allowdownload.toString(),
            },
          };
          this.updateContent(obj).then(() => this.closeModal());
          return;
        }
        var studyMaterial = {
          title: this.title,
          data: {
            servicecode: this.serviceProvider ? this.serviceProvider : "local",
            source: this.fileUrl,
            type: this.fileType,
            allowdownload: this.allowdownload.toString(),
          },
          sequence: 1,
          isAvailableForDemo: this.isAvailableForDemo,
          type: this.type,
          parentDirectoryID: this.parentContentId,
          availableFrom: this.startDate,
          availableTo: this.endDate,
        };

        // if (this.startDate) {
        // 	studyMaterial.availableFrom = this.startDate;
        // }

        // if (this.endDate) {
        // 	studyMaterial.availableTo = this.endDate;
        // }

        this.createStudyMaterialContent(studyMaterial).then(() => {
          console.log("StudyMaterial upload successful");
          // this.getContentById(this.parentContentId);
          this.$toasted.success("Study Material uploaded successful", {
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
          this.closeModal();
        });
      }
      // this.closeModal();
      // this.$emit("updateModalState", false);
    },
    uploadWithLink(e) {
      e.stopPropagation();
      console.log("upload with link");
      this.isFileAdded = true;
      this.isLinkUpload = true;
      this.fileType = "link";
    },
    closeModal() {
      $(`.upload-study-material-modal.${this.parentContentId}`).removeClass(
          "min"
      );
      $(".minmaxCon").remove(
          $(`.upload-study-material-modal.${this.parentContentId}`)
      );
      // $(".upload-study-material-modal").removeClass("min");
      // $(".minmaxCon").remove($(".upload-study-material-modal"));
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit("updateModalState", false);
    },
    uploadInBackground() {
      $(`.modal-backdrop`).addClass("display-none");
      $(`.upload-study-material-modal.${this.parentContentId}`).toggleClass(
          "min"
      );

      if (
          $(`.upload-study-material-modal.${this.parentContentId}`).hasClass(
              "min"
          )
      ) {
        this.isMinimized = true;
        $(".minmaxCon").append(
            $(`.upload-study-material-modal.${this.parentContentId}`)
        );
        $(`#upload-study-material-modal-${this.parentContentId}`).remove(
            $(`.upload-study-material-modal.${this.parentContentId}`)
        );
        $(`#upload-study-material-modal-${this.parentContentId}`).addClass(
            "display-none"
        );
        $(
            `.upload-study-material-modal.${this.parentContentId} .body-content-container`
        ).addClass("display-none");
        $(`[data-id=${this.parentContentId}]`).css("z-index", 1050);
      } else {
        this.isMinimized = false;
        $(`#upload-study-material-modal-${this.parentContentId}`).removeClass(
            "display-none"
        );
        $(
            `#upload-study-material-modal-${this.parentContentId} .modal-dialog`
        ).append($(`.upload-study-material-modal.${this.parentContentId}`));
        $(".minmaxCon").remove(
            $(`.upload-study-material-modal.${this.parentContentId}`)
        );
        $(
            `.upload-study-material-modal.${this.parentContentId} .body-content-container`
        ).removeClass("display-none");
        $(`[data-id=${this.parentContentId}]`).css("z-index", 9999);
      }
    },
    updateStudyMaterialProgress(
        totaluploadprogress,
        totalBytes,
        totalBytesSent
    ) {
      this.$emit("updateProgress", totaluploadprogress, this.id);
      this.fileProgress = totaluploadprogress;
    },
    getUploadURL() {
      console.log("getUploadURL");
      return this.apiURL + POST_FILE_API;
    },
    toggleStudyMaterialModal(state) {
      if (state !== undefined) {
        this.showStudyMaterialModal = state;
      } else {
        this.showStudyMaterialModal = !this.showStudyMaterialModal;
      }
    },
  },
  computed: {
    ...mapGetters("content", ["getContent"]),
    ...mapState("content", ["loading"]),
    ...mapState("appState", ["apiURL"]),
    visible() {
      // debugger;
      return this.showModal;
    },

    isPDFVSS() {
      return this.contentData && this.contentData.externalService
      && this.contentData.externalService.serviceCode.toString().toLowerCase() === 'pdfvss';
    }

  },
  props: {
    showModal: Boolean,
    parentContentId: String,
    serviceProvider: String,
    isEditMode: Boolean,
    contentData: Object,
    productVariantId: String,
    allowDownload: Boolean,
  },
  created() {
    // var token = CookieHelpers.getCookie("token");
    // var headerObject = {
    // 	AuthToken: "System123",
    // 	Authorization: `Bearer ${CookieHelpers.getCookie("token")}`,
    // };
    // this.dropzoneOptions.headers = headerObject;
    // this.dropzoneOptions.url = apiURL + POST_FILE_API;
    // if (this.systemParameters && this.systemParameters.StudyMaterial) {
		// 	  this.allowDownload = this.systemParameters.StudyMaterial.allowDownload;			
		// }
  },
  watch: {
    visible: async function () {
      if (this.visible) {
        if (this.isEditMode) {

         if (this.contentData.id && !isNaN(parseInt(this.contentData.id))) {
            var contentData = await this.getContentById({ id: this.contentData.id });
          }

          this.id = this.contentData.id;
          this.isFileAdded = true;
          this.title = this.contentData.title;
          this.startDate = this.contentData.availableFrom
              ? getInputDateFormat(this.contentData.availableFrom)
              : null;
          this.endDate = this.contentData.availableTo
              ? getInputDateFormat(this.contentData.availableTo)
              : null;
          this.isAvailableForDemo = this.contentData.isContentAvailableForDemo;
          // this is done the keep prop property seperate when updating 
          this.allowdownload = contentData && contentData.data && contentData.data.AllowDownload == 'true'; 
        }
      }
      // console.log(
      // 	"content in store was updated, logged from UploadStudyMaterialModal"
      // );
    },
  },
};
</script>

<style>
.upload-study-material-modal {
  height: 45.375rem;
  /* height: fit-content; */
  width: 70vw;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.upload-study-material-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.upload-study-material-modal .modal-body .body-container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.dropzone-form {
  border-radius: 8px;
  height: 100%;
}

.br_dropzone {
  position: relative;
  border: 1px solid rgba(#000, 0.1);
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 4px;
  box-sizing: border-box;

  background-image: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.03) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.03) 50%,
      rgba(0, 0, 0, 0.03) 75%,
      transparent 75%,
      transparent
  );
  background-color: #fafcfd;
  background-size: 16px 16px;
}

.br_dropzone input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  font-size: 2rem;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.br_dropzone input[type="text"] {
  background: none;
  border: none;
  padding: 0.5em;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 2rem;
  box-sizing: border-box;
}

.br_dropzone.dragover {
  background-color: #eee;
  border: 6px dashed rgba(#000, 0.1);
}

.upload-container.shrink .br_dropzone input[type="text"] {
  font-size: 1rem !important;
}

.file-icon {
  position: absolute;
  /* top: 5rem; */
  font-size: 5rem;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -20%);
  /* transform: translateY(-30%); */
}

.upload-container.shrink .file-icon {
  font-size: 2rem !important;
}

.body-content-container .upload-container .preview.square img:hover {
  border-radius: 1rem;
}

</style>
