<template>
	<div
		:class="`dashboard-products-container${isUpdatesCard ? ' shrink' : ''}`"
		id="dashboard-products-container"
	>
		<ProductList
			v-if="userProducts.length > 0 && !loading"
			viewMode="carousel"
			:productListData="userProducts"
			:carouselSettings="USER_PRODUCTS_CAROUSEL_SETTINGS"
		/>
		<div v-else-if="loading" class="mt-5">
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
		</div>
		<div v-else class="no-products">
			<h4>
				You are not enrolled in any of the courses. <br />
				<router-link to="/search">Click here</router-link> to browse through
				full list of our courses.
			</h4>
		</div>
		<div class="section-heading-container">
			<h1 class="section-heading">Recommended Courses</h1>
			<h5 class="section-subheading">
				Grab the best courses in economical prices
			</h5>
		</div>
		<ProductList
			v-if="filteredProductVariants.length > 0 && !loading"
			viewMode="carousel"
			:productListData="filteredProductVariants"
			:carouselSettings="USER_PRODUCTS_CAROUSEL_SETTINGS"
		/>
		<div v-else-if="loading" class="mt-5">
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
			<b-skeleton
				class="d-inline-block m-2 mt-0"
				type="button"
				width="16.25rem"
				height="25rem"
			></b-skeleton>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductList from "../../../components/Product/ProductList.vue";
import ProductListItem from "../../../components/Product/ProductListItem.vue";
import { USER_PRODUCTS_CAROUSEL_SETTINGS } from "../../../constants/productCarouselSettings";
import { ProductVariantSubscriptionStatus } from "../../../constants/productVariant";

export default {
	name: "UserProducts",
	data() {
		return {
			USER_PRODUCTS_CAROUSEL_SETTINGS,
			ProductVariantSubscriptionStatus,
		};
	},
	props: { userProducts: Array, isUpdatesCard: Boolean },
	components: { ProductListItem, ProductList },
	computed: {
		...mapState("productVariant", ["loading", "productVariants"]),
		filteredProductVariants() {
			return Object.values(this.productVariants.items).filter((variant) => {
				var found = this.userProducts.find(
					(product) => product.id == variant.id
				);
				if (!found) return variant;
			});
		},
	},
	methods: {
		...mapActions("productVariant", ["fetchProductVariants"]),
	},
	created() {
		this.fetchProductVariants();
	},
};
</script>

<style scoped>
@media screen and (min-width: 1440px) {
	.dashboard-products-container.shrink {
		width: 70% !important;
	}
}

@media screen and (max-width: 996px) {
	.dashboard-products-container.shrink {
		width: 100% !important;
		margin-top: 1.5rem;
	}
}

@media screen and (max-width: 600px) {
	.dashboard-products-container div {
		display: flex;
		row-gap: 1rem;
		flex-direction: column;
	}
}

.no-products {
	padding: 3rem 0 !important;
}

.dashboard-products-container {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	max-width: 100%;
}

.dashboard-products-container.shrink {
	width: 65%;
}
</style>
