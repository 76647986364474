<template>
	<div class="carousel-container">
		<img v-if="!bannerData" src="../../assets/images/banner-right-vector.svg" class="banner-right-img vector" />
		<img v-if="!bannerData" src="../../assets/images/banner-right-person.png" class="banner-right-img person" />
		<VueSlickCarousel v-if="bannerData && carouselOptions" class="carousel" v-bind="carouselOptions" fade
			ref="banner_carousel">
			<div v-for="slide in bannerData" :key="slide.order" class="carousel-slide">
				<img v-if="slide.type.toLowerCase() == 'image'" class="carousel-content" :src="getData(slide.sources)"
					alt="" />
				<div v-else-if="
					slide.type.toLowerCase() == 'video' &&
					(slide.source.provider.toLowerCase() == 'youtube' ||
						slide.source.provider.toLowerCase() == 'vimeo')
				" id="video-wrapper">
					<div class="video-container">
						<iframe class="carousel-content" :src="slide.source.url" frameborder="0" allow="autoplay;"
							autoplay></iframe>
					</div>
				</div>

				<video class="carousel-content" v-else-if="
					slide.type.toLowerCase() == 'video' &&
					slide.source.provider.toLowerCase() == 'mp4'
				" :src="slide.source.url" autoplay></video>

				<div v-else-if="slide.type.toLowerCase() == 'html'" class="carousel-slide html"
					v-html="getData(slide.sources)"></div>
				<!-- </div> -->
			</div>
			<div v-if="!bannerData" class="carousel-slide default">
				<div class="container d-flex">
					<div class="carousel-content">
						<h2 class="creator-name">Hamza Abdul Haq</h2>
						<h1 class="product-title">ACCA F8 Audit & Assurance</h1>
						<h2 class="product-variant-title">
							(UK) F8, CA (PK) CAF 9 & ICMA S2.
						</h2>
						<p class="sub-heading">
							Get a <span>FREE ITEM</span> only for away for a single click
						</p>
						<div class="features-container">
							<div v-for="feature in features" :key="feature" class="feature-tag">
								<i class="fas fa-check-circle feature-tag-icon"></i>{{ feature }}
							</div>
						</div>
						<div class="banner-cta-container">
							<p class="batch-time">
								Online Batch Will start from 15th July, 2019
							</p>
							<div class="banner-cta-btn-container">
								<a role="button" class="cta-btn primary"> 7 Day Free Trail </a>
								<span class="cta-btn-divider">OR</span>
								<a role="button" class="cta-btn secondary"> Enroll Now </a>
							</div>
							<div class="demo-container">
								<p class="watch-demo">Click here to watch demo 1:45 Minutes</p>
								<i class="fas fa-play-circle play-icon"></i>
							</div>
						</div>
					</div>
					<div class="product-stats-container">
						<div class="stats-card first">
							<h1 class="rating-text">4.7</h1>
							<div class="right-section">
								<b-form-rating value="4" readonly></b-form-rating>
								<p class="stats-text rating">Instructor Rating</p>
							</div>
						</div>
						<div class="stats-card">
							<img class="left-image" src="../../assets/images/bell.svg" />
							<div class="right-section">
								<p class="stats-number">240+</p>
								<p class="stats-text">Courses Completed</p>
							</div>
						</div>
						<div class="stats-card last">
							<img class="left-image" src="../../assets/images/bell.svg" />
							<div class="right-section">
								<p class="stats-number">100,000+</p>
								<p class="stats-text">Enrolled Students</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</VueSlickCarousel>
		<a v-if="bannerData.length > 1" class="carousel-arrow left" role="button" @click="prevSlide"><i
				class="fas fa-angle-left"></i></a>
		<a v-if="bannerData.length > 1" class="carousel-arrow right" role="button" @click="nextSlide"><i
				class="fas fa-angle-right"></i></a>
	</div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapActions, mapState } from "vuex";
export default {
	name: "HomePageBanner",
	data() {
		return {
			features: ["webinars", "free notes", "practice tests"],
			bannerData: null,
			carouselOptions: null,
			// {
			// 	autoplay: false,
			// 	autoplaySpeed: 3000,
			// 	pauseOnHover: true,
			// },
		};
	},
	components: { VueSlickCarousel },
	methods: {
		getData(source) {
			// console.log("get image path", source, this.bannerData);
			if (this.isMobile) {
				var src = source.find((s) => s.screen_size == "xs");
				if (src) return src.data;
			}
			var src = source.find((s) => s.screen_size == "lg");
			return src.data;
		},
		nextSlide() {
			this.$refs.banner_carousel.next();
		},
		prevSlide() {
			this.$refs.banner_carousel.prev();
		},
	},
	computed: {
		...mapState("appState", ["isMobile", "systemParameters"]),
	},
	created() {
		if (this.systemParameters && this.systemParameters.Banners) {
			var data = this.systemParameters.Banners;
			this.bannerData = data.slides;
			this.carouselOptions = {
				autoplay: data.autoplay,
				autoplaySpeed: parseInt(data.duration),
				pauseOnHover: data.pauseOnHover,
				arrows: false,
			};
		}
	},
};
</script>

<style scoped>
@media screen and (max-width: 1023px) {
	.banner-right-img {
		display: none !important;
	}
}

@media screen and (max-width: 996px) {
	.product-stats-container {
		display: none !important;
	}

	.carousel-content {
		width: 100% !important;
	}
}

@media screen and (max-width: 480px) {
	.creator-name {
		font-size: 2.5rem !important;
	}

	.product-title {
		font-size: 3rem !important;
	}

	.carousel-slide {
		padding: 0 !important;
		min-height: fit-content !important;
	}

	.carousel-slide .carousel-content {
		min-height: fit-content !important;
	}

	.product-variant-title {
		font-size: 1.5rem !important;
	}

	.sub-heading {
		font-size: 1.25rem !important;
	}

	.feature-tag {
		margin-bottom: 1rem !important;
	}

	.banner-cta-container .batch-time {
		/* font-size: 1rem !important; */
		margin-top: 1.5rem !important;
	}

	.banner-cta-btn-container {
		flex-direction: column !important;
	}

	.banner-cta-btn-container .cta-btn {
		width: 100% !important;
	}

	.banner-cta-btn-container .cta-btn-divider {
		margin: 1.125rem 0;
	}

	.watch-demo::after {
		display: none !important;
		width: 10rem !important;
		right: -10rem !important;
	}

	.demo-container .play-icon {
		margin-left: 2rem !important;
	}
}

.carousel-container {
	width: 100%;
	background: linear-gradient(#ebebeb, #ffffff);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
}

.carousel-slide.default {
	background-color: var(--color-gray);
	/* height: 100vh; */
	/* max-height: 57.5rem; */
	padding: 6.5rem 0;
	padding-bottom: 11rem;
	/* color: var(--color-white); */
	background-color: transparent;
}

.carousel-slide {
	background-color: var(--color-gray);
	/* height: 100vh; */
	/* max-height: 57.5rem; */
	padding: 0 0 2.5rem 0;
	background-color: transparent;
	min-height: 70vh;
}

.carousel-slide .html {
	/* padding: 0 0 12rem 0; */
	margin: 7rem 0;
	/* width: 60%; */
}

.carousel-slide.default .carousel-content {
	width: unset;
}

.carousel-content {
	flex-grow: 1;
	width: 100%;
	height: fit-content;
	min-height: 70vh !important;
	object-fit: cover;
	object-position: top;
}

.carousel-slide .creator-name {
	font-size: 3.125rem;
	color: #231f20;
}

.carousel-slide .product-title {
	font-size: 3.5rem;
	color: var(--primary-color);
	font-weight: 500;
	text-shadow: 0 15px 50px #00000029;
}

.carousel-slide .product-variant-title {
	font-size: 2.25rem;
	color: var(--primary-color);
	font-weight: 500;
	text-shadow: 0 15px 50px #00000029;
}

.banner-right-img {
	position: absolute;
	width: 41.25rem;
}

.banner-right-img.vector {
	width: 45.5rem;
	right: 1rem;
	top: 0;
}

.banner-right-img.person {
	width: 44.25rem;
	top: -1.5rem;
	right: 4rem;
}

.sub-heading {
	font-size: 1.75rem;
	text-shadow: 0 15px 50px #00000029;
	font-weight: 500;
	margin-top: 2.25rem;
	line-height: 1;
	vertical-align: middle;
	margin-bottom: 0;
}

.sub-heading span {
	color: #ffffff;
	font-size: 1.125rem;
	background-color: #05d678;
	border-radius: 0.25rem;
	padding: 0.25rem 0.5rem;
	line-height: 1.5;
	font-weight: normal;
}

.features-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 1.375rem;
}

.feature-tag {
	padding: 0.75rem;
	padding-right: 2rem;
	background-color: #ffffff;
	border-radius: 8px;
	text-decoration: underline;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1.6px;
	font-size: 1rem;
	line-height: 1;
	margin-right: 1rem;
}

.feature-tag-icon {
	font-size: 1rem;
	color: #05d678;
	margin-right: 0.5rem;
}

.banner-cta-container {
	display: flex;
	flex-direction: column;
}

.banner-cta-container .batch-time {
	font-size: 1.25rem;
	text-shadow: 0 15px 50px #00000029;
	margin-top: 2.5rem;
}

.banner-cta-btn-container {
	display: flex;
	flex-direction: row;
	margin-top: 1.25rem;
	align-items: center;
	/* justify-content: space-between; */
}

.cta-btn {
	font-weight: 500;
	color: #ffffff;
	font-size: 1.5rem;
	padding: 1rem 0;
	width: 19.75rem;
	border-radius: 10px;
	text-decoration: none;
	text-align: center;
}

.cta-btn.primary {
	background-color: var(--primary-color);
}

.cta-btn.secondary {
	background-color: var(--secondary-color);
}

.cta-btn-divider {
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 1.6px;
	color: #000000;
	margin: 0 1.375rem;
}

.demo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	margin-top: 3.375rem;
}

.watch-demo {
	font-size: 1.25rem;
	text-shadow: 0 15px 50px #00000029;
	margin: 0;
	position: relative;
}

.watch-demo::after {
	content: "";
	width: 18rem;
	height: 1px;
	background-color: #231f20;
	position: absolute;
	top: 50%;
	right: -19rem;
}

.demo-container .play-icon {
	margin-left: 20rem;
	font-size: 2.5rem;
}

.product-stats-container {
	display: flex;
	flex-direction: column;
	flex-grow: 2;
	justify-content: flex-end;
}

.stats-card {
	width: 17.25rem;
	border-radius: 12px;
	background-color: #ffffff;
	box-shadow: 3px 3px 99px #2f2f2f29;
	padding: 1.25rem;
	margin-bottom: 3.25rem;
	align-self: center;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
}

.stats-card.first {
	align-self: flex-start !important;
}

.stats-card.last {
	align-self: flex-end !important;
	margin-bottom: 0;
}

.stats-card .rating-text {
	font-size: 3.125rem;
	margin: 0;
	font-weight: bold;
	color: var(--primary-color);
	line-height: 1;
}

.stats-card .form-control {
	height: fit-content;
	background-color: transparent !important;
	border: none;
	height: unset !important;
	margin: 0;
	padding: 0;
	font-size: 1rem !important;
	width: fit-content;
	box-shadow: none;
	color: #ffba00;
}

.stats-card .form-control:focus {
	box-shadow: none !important;
}

.stats-card .rating.stats-text {
	font-size: 1.125rem;
}

.stats-card .stats-text {
	color: #575757;
	font-size: 1rem;
	margin-bottom: 0;
}

.stats-card .stats-number {
	color: var(--primary-color);
	font-size: 1.5rem;
	margin-bottom: 0.25rem;
	line-height: 1;
	font-weight: bold;
}

.stats-card .left-image {
	height: 3.5rem;
}

.stats-card .right-section {
	margin-left: 1rem;
}

/* @media screen and (max-width: 1024px) {
	.carousel-slide {
		max-height: 70vh !important;
	}
} */

#video-wrapper {
	width: 100%;
	height: 100%;
}

/* .video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; */
/* max-height: 450px; */
/* } */

.carousel-container .carousel-arrow {
	/* margin: 0.5rem; */
	font-size: 2rem;
	width: 2.5rem;
	height: 2.5rem;
	line-height: 1;
	align-items: center;
	justify-content: center;
	display: flex;
	border-radius: 50%;
	border: 2px solid var(--secondary-color);
	background-color: #ffffff;
	box-shadow: 3px 3px 6px #00000029;
	color: var(--secondary-color);
	text-decoration: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.carousel-container .carousel-arrow.left {
	left: 1.5rem;
}

.carousel-container .carousel-arrow.right {
	right: 1.5rem;
}
</style>
