<template>
	<div class="social-settings-wrapper">
        <div class="mb-4 d-flex flex-wrap gap-4 social-settings ">
            
            <div class="settings-container settings-social col-md-3 px-0">
                <h3 class="main-label">
                    <span>Facebook</span>                         
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('Facebook')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>               
                </h3>
                <div v-if="!editFacebook" class="info-content">
                    <a :href="SocialLinks.Facebook" target="_blank">{{SocialLinks.Facebook}}</a> 
                </div>
                <div v-if="editFacebook" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="pageFacebook" name="pageFacebook" class="form-input" v-model="tempFacebook" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('Facebook')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('Facebook')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                                
            </div>	

            <div class="settings-container settings-social col-md-3 px-0">
                <h3 class="main-label">
                    <span>Twitter </span>                    
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('Twitter')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                    
                </h3>
                <div v-if="!editTwitter" class="info-content">
                    <a :href="SocialLinks.Twitter" target="_blank">{{SocialLinks.Twitter}}</a>  
                                      
                </div>
                <div v-if="editTwitter" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="pageTwitter" name="pageTwitter" class="form-input" v-model="tempTwitter" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('Twitter')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('Twitter')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                                
            </div>	

            <div class="settings-container settings-social col-md-3 px-0">
                <h3 class="main-label">
                    <span>LinkedIn  </span>
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('LinkedIn')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div> 
                </h3>
                <div v-if="!editLinkedIn" class="info-content">
                    <a :href="SocialLinks.LinkedIn" target="_blank">{{SocialLinks.LinkedIn}}</a> 
                                     
                </div>
                <div v-if="editLinkedIn" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="pageLinkedIn" name="pageLinkedIn" class="form-input" v-model="tempLinkedIn" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('LinkedIn')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('LinkedIn')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                               
            </div>

            <div class="settings-container settings-social col-md-3 px-0">
                <h3 class="main-label">
                    <span>YouTube</span> 
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('YouTube')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                    
                </h3>
                <div v-if="!editYouTube" class="info-content">
                    <a :href="SocialLinks.YouTube" target="_blank">{{SocialLinks.YouTube}}</a>                                        
                </div>
                <div v-if="editYouTube" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="pageYouTube" name="pageYouTube" class="form-input" v-model="tempYouTube" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('YouTube')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('YouTube')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                              
            </div>

            <div class="settings-container settings-social col-md-3 px-0">
                <h3 class="main-label">
                    <span>Instagram</span> 
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('Instagram')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                      
                </h3>
                <div v-if="!editInstagram" class="info-content">
                    <a :href="SocialLinks.Instagram" target="_blank">{{SocialLinks.Instagram}}</a>                                      
                </div>
                <div v-if="editInstagram" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="pageInstagram" name="pageInstagram" class="form-input" v-model="tempInstagram" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('Instagram')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('Instagram')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                                
            </div>            

        </div>
        
        <div class="">            
            <b-spinner v-if="loading" class="loader"> </b-spinner> 
        </div>
	</div>
    
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "SocialLinksSettings",
    data() {
		return {    
            SocialLinks:{
                Facebook: null,
                Twitter: null,
                LinkedIn: null,
                YouTube: null,
                Instagram: null, 
            },       
            loading: false,	
            editFacebook: false,
            editTwitter: false,
            editLinkedIn: false,
            editYouTube: false,
            editInstagram: false,
            tempFacebook: null,
            tempTwitter: null,
            tempLinkedIn: null,
            tempYouTube: null,
            tempInstagram: null,		
		};
	},
    methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("appState", [
			"editSystemParameters",
			"fetchSystemParameters",			
		]),	
        enableEditing(el){
            //console.warn('Enable Editing ', el);
            this[`temp${el}`] = this.SocialLinks[el];            
            this[`edit${el}`] = true; 
            //this.editTwitter = true;           
        },   
        disableEditing(el){
            this[`temp${el}`] = null;
            this[`edit${el}`] = false;
        },
        saveEditing(el){	
            var socialUrl = this[`temp${el}`];
            if(socialUrl && socialUrl.indexOf("https://") == -1 && socialUrl.indexOf("http://") == -1){
                socialUrl = "https://" + socialUrl;
            }    
            this.SocialLinks[el] = socialUrl;
            this.updateSocialInfo();
            this.disableEditing(el);
		},
		updateSocialInfo(){	            
            this.loading = true;	            
			this.editSystemParameters({
				parameterName: "SocialLinks",
				parameterValue: JSON.stringify(this.SocialLinks),
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					console.log("Social Links Updated");  
					this.$toasted.success("Social Pages updated successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
                    this.loading = false;
				});
			});
		},
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
	},
	components: {},
	created() {
		if (this.systemParameters.SocialLinks && this.systemParameters.SocialLinks.Facebook) {
            this.SocialLinks.Facebook = this.systemParameters.SocialLinks.Facebook;
		}
        if (this.systemParameters.SocialLinks && this.systemParameters.SocialLinks.Twitter) {
            this.SocialLinks.Twitter = this.systemParameters.SocialLinks.Twitter;
		}
        if (this.systemParameters.SocialLinks && this.systemParameters.SocialLinks.LinkedIn) {
            this.SocialLinks.LinkedIn = this.systemParameters.SocialLinks.LinkedIn;
		}
        if (this.systemParameters.SocialLinks && this.systemParameters.SocialLinks.YouTube) {
            this.SocialLinks.YouTube = this.systemParameters.SocialLinks.YouTube;
		}
        if (this.systemParameters.SocialLinks && this.systemParameters.SocialLinks.Instagram) {
            this.SocialLinks.Instagram = this.systemParameters.SocialLinks.Instagram;
		}		
	},
	watch: {		
	},
}
</script>



<style>
.preferences-card .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
</style>
<style scoped>
.settings-social{
    min-width:140px;
    margin-bottom: 15px;
}
.form-input {
    font-size: 1rem;
    outline: none;
    padding: 0.5rem;
    width: 100%;
    border-radius: 0;
    box-shadow: none !important;
    position: relative; 
    border: solid 1px #dcdcdc;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 0.75rem;
    column-gap: 0.75rem;
    padding-right: 5.25rem;
}
.settings-container .upload-btn-wrapper .loader {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	position: absolute;
}
.info-content{
    position: relative;
    padding-right: 2.75rem;
}
.info-edit .info-edit-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
}
.info-content .right-menu,
.main-label .right-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    margin-left: auto;
    margin-right: 0.75rem;
    transition: opacity 0.3s, visibility 0s ease;
    visibility: hidden;
    opacity: 0; 
    top: 0.55rem;
    transform: translateY(-50%);
    position: absolute;
    right: 0.25rem;
}
.settings-social:hover .main-label .right-menu{
    visibility: visible;
    opacity: 1;
}
.info-content:hover .right-menu {
    visibility: visible;
    opacity: 1;
}

.content-action-btn.save {
    border: 1px solid #21cf47;
    color: #21cf47;
    position: absolute;
    right: 2.8rem;
    top: 0.45rem;
}
.content-action-btn.cancel {
    border: 1px solid #ff0000;
    color: #ff0000;
    position: absolute;
    right: 0.55rem;
    top: 0.45rem;
}
</style>
