<template>
  <div>
    <div v-if="!isMaximized && filesInQueueToUpload && filesInQueueToUpload.length>0" class="upload-lecture-side-background">
      <b-card class="upload-lecture-side-body">
        <div class="main-uploading-tab-head">
          <p v-if="currentlyUploadingItem" >Uploading {{currentlyUploadingItem.PercentComplete}}%</p>          
          <p v-else>Uploading Completed</p>
          <span></span>
          <button @click="openModal()"><i class="fas  fa-window-restore"></i></button>
          <button v-b-toggle="'uploadingList'"><i class="fas fa-chevron-down"></i></button>
          
        </div>
        <div>
          <b-collapse id="uploadingList">
           
                <div class="uploadingList-item" v-for="video in filesInQueueToUpload">
                      <img
					
        						class="page-view-icon"
        						src="@/assets/images/recorded-lecture.png"
        					/>
                  <div style="flex: 1; align-self:center">
                    <h6 style="margin: 0px;">{{ video.title }}</h6>
                  </div>
                  <div style="align-self: center; margin-right:10px " >
                        <i v-if="video.status=='failed'" class="fa fa-exclamation-triangle failed-icon"></i>
                        <b-spinner v-if="video.status=='uploading' || video.status=='processing'  || video.status=='processed'" small class="spinner"></b-spinner>
                        <i v-if="video.status=='uploaded'" class="fa fa-check uploaded-icon" ></i>
                        <i v-if="video.status=='cancelled'" class="fa fa-times failed-icon"></i>
                      </div>
                </div>
           
          </b-collapse>
        </div>
      </b-card>
    </div>
    <b-modal v-if="isMaximized" :id="`upload-lecture-background`" :visible="true" size="xl" hide-header hide-footer
      centered :content-class="`upload-lecture-background`" body-class="modal-body" modal-class="custom-modal"
      no-close-on-backdrop>
      <div class="body-container">
        <button @click="closeModal()" class="minimize-button" role="button">
          <i
            :class="[
              'fas',
              'fa-window-minimize'
            ]"
        ></i>
        </button>
        <button v-if="currentlyUploadingItem==null"  @click="closeCompleteModal()" class="close-button" role="button">
          <img src="@/assets/images/cancel-icon.svg" />
        </button>  
        <h1 class="add-content-title">
          Files Uploading<br>
          <small style="margin-top: 3px;">Uploaded {{currentlyUploaded.length}} / {{filesInQueueToUpload.length}}</small> <span  v-if="currentlyFailed.length>0">, <small>{{currentlyFailed.length}} Failed</small></span>   <span v-if="currentlyCancelled.length>0">, <small>{{currentlyCancelled.length}} Cancelled</small></span> 
        </h1>
        <div class="body-content-container">

          <div class="video-list">
            <div class="video-item" v-for="video in filesInQueueToUpload">
                <div class="video-thumbnail">
                      <img v-if="video.contentType=='RecordedVideos'" class="icon"	src="@/assets/images/recorded-lecture.png"	/>
                      <img v-if="video.contentType=='StudyMaterial'" class="icon"	src="@/assets/images/study-material.png"	/>
                    </div>
                  <div class="video-discription">                  
                   <h6>{{ video.title }} </h6>
                   <small>{{ convertToMBorGB(video.size) }}</small>
                   <br/>
                   <div class="progressbar" v-if="video.status == 'uploading'">
                    <div class="innerbar"  :style="`height:100%;width:${video.PercentComplete}%`"></div>
                   </div>
                   <small class="status-tag warning" v-if="video.status == 'queued'"><i class="fa fa-clock"></i> Queued</small> 
                   <small class="status-tag danger" v-if="video.status == 'failed'"><i class="fa fa-exclamation-triangle"></i> FAILED</small> 
                   <small class="queue-status" v-if="video.status == 'processing' || video.status == 'processed'"><b-spinner small class="spinner"></b-spinner> UPLOADING</small> 
                   <small class="status-tag success" v-if="video.status == 'uploaded'"><i class="fa fa-check-circle"></i> UPLOADED</small> 
                   <small class="status-tag danger" v-if="video.status == 'cancelled'"><i class="fa fa-times"></i> CANCELLED</small> 
                    
                  <br>
                  </div>
                  <div class="video-upload-status">
                    
                    <div class="button-div" style="display: flex; margin-top: 30px;">
                      <button v-if="video.status!='failed' && video.status!='cancelled'" @click="$event=>editContent(video)" class="edit-button"><i class="fa fa-pencil"></i></button>
                      <button v-if="video.status!='failed' && video.status!='cancelled' && video.status!='uploaded' && video.onCancelCallback" @click="video.onCancelCallback(video)" class="cancel-button"><i class="fa fa-times"></i></button>                    
                    </div>
                  </div>
              </div>
          </div>
        </div>

      </div>

    </b-modal>
    <UploadStudyMaterialModal
    :showModal="showEditModal && selectedContent && selectedContent.content.type == 'StudyMaterial' && showEditModal"
    :parentContentId="selectedContent?selectedContent.content.parentDirectoryID:'0'"
    @updateModalState="toggleEditModal"
    :contentData="selectedContent!=null?selectedContent.content:null"    
    :isEditMode="true"
  />
   <UploadRecordedLectureModal
    :showModal="showEditModal && selectedContent && selectedContent.content.type == 'RecordedVideos' && showEditModal"
    :parentContentId="selectedContent?selectedContent.content.parentDirectoryID:'0'"
    @updateModalState="toggleEditModal"
    :contentData="selectedContent!=null?selectedContent.content:null"    
    :service="selectedContent!=null?selectedContent.content.externalService:null"
    :contentType="selectedContent!=null?selectedContent.contentType:null"
    :isEditMode="true"
  />
  </div>
  
</template>

<script>

import { f } from "vue-slick-carousel";
import { mapActions, mapState } from "vuex";
export default {
  name: "UploadRecordedLectureInBackgroundModal",
  components: {
    UploadRecordedLectureModal: () => import('./UploadRecordedLectureModal.vue'),
    UploadStudyMaterialModal: () => import('./UploadStudyMaterialModal.vue')
    // UploadRecordedLectureModal
  },
  props: {
  },
  data: function () {
    return {
      selectedContent:null,
      showEditModal:false
    };
  },
  methods: {
    ...mapActions("bulkFileUploading", ["toggleBulkFileUploadingModel","CloseComplete","processQueuedFileAndFetchingUploadURL"]),
    ...mapActions("content", [
      "getContentById",
    ]),    
    openModal() {
      this.toggleBulkFileUploadingModel(true);
    },
    closeModal() {
      this.toggleBulkFileUploadingModel(false);
    },
    convertToMBorGB(bytes) {
      var result="";
      if (bytes < 1024 * 1024) {
        result = (bytes / 1024).toFixed(2) + ' KB';
      } else if (bytes < 1024 * 1024 * 1024) {
        result = (bytes / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        result = (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
      return result;
    },
    editContent(content){
      if(content.status.toLowerCase()=="queued" && !content.content)
      {
        var self=this;
        this.processQueuedFileAndFetchingUploadURL(content).then(function(object){
          self.selectedContent=object;
          self.selectedContent.content.parentDirectoryID=self.selectedContent.parentContentID;
          self.showEditModal = true;
        });
      }else{        
        this.selectedContent=content;        
        this.selectedContent.content.parentDirectoryID=this.selectedContent.parentContentID;
        this.showEditModal = true;
      }

    },
    async toggleEditModal(){
      var self=this;
      if(this.selectedContent){
        await this.getContentById({id:this.selectedContent.content.id}).then(function (content) {
         debugger;
          var tempData=self.selectedContent.content.data?JSON.parse(JSON.stringify(self.selectedContent.content.data)):null;
          var temp=self.selectedContent.content.parentDirectoryID;  
          self.selectedContent.content=content;
          self.selectedContent.title=content.title;
          self.selectedContent.content.parentDirectoryID=temp;
            
            self.selectedContent.content.data=tempData;
      });      
      }
      // this.selectedContent=null;
      this.showEditModal = false;      
    },
    closeCompleteModal(){
      if(this.currentlyUploadingItem==null)
      {   this.toggleBulkFileUploadingModel(false);
          this.CloseComplete();
      }
    },
    handleBeforeUnload(event) {
      console.log("before");
      if (this.currentlyUploadingItem != null) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
  },
  computed: {
    ...mapState("bulkFileUploading", ["isMaximized","filesInQueueToUpload"]),
    // ...mapState("content", ["copiedSection"]),
    currentlyUploadingItem(){
      return this.filesInQueueToUpload.find(x=>x.status=="uploading" || x.status=="processing" || x.status=="processed");
    },
    currentlyUploaded(){
      return this.filesInQueueToUpload.filter(x=>x.status=="uploaded");
    },
    currentlyFailed(){
      return this.filesInQueueToUpload.filter(x=>x.status=="failed");
    },
    currentlyCancelled(){
      return this.filesInQueueToUpload.filter(x=>x.status=="cancelled");
    }
  },
  watch: {
    visible: function () {

    },
  },
  mounted() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeUnmount() {
    // window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }
};
</script>

<style>
.upload-lecture-side-background {
  width: 360px;
  position: absolute;
  z-index: 500;
  bottom: 0px;
  right: 35px;
  border-radius: 10px 10px 0px 0px;
}

.upload-lecture-side-background .upload-lecture-side-body {
  border-radius: 10px 10px 0px 0px;
}

.upload-lecture-side-background .upload-lecture-side-body .card-body {
  padding: 0%;
  background: white;
  border-radius: 10px 10px 0px 0px;
}

.main-uploading-tab-head {
  padding: 10px 18px;
  background: #313131;
  color: white;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  display: flex;
}

.main-uploading-tab-head span {
  flex: 1;
}

.main-uploading-tab-head p {
  margin: 0px;
  font-size: 1.1rem;
}

.main-uploading-tab-head button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  box-shadow: 0 2px 4px darkslategray;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 5px;
}

.main-uploading-tab-head button i {
  font-size: 20px;
  font-weight: 0;
}

.main-uploading-tab-head button:hover {
  background-color: darkgray;
}
.video-list{
  margin-top: 10px;
  width: 100%;
  height: 100%;
  padding: 25px;;
}
.video-thumbnail
{
    margin-right: 15px;
    height: 94px;
    width: 168px;
    background-color: #e3e3e3;
    border-radius: 8px;
    text-align-last: center;
}
.video-thumbnail img
{
   
    height: 60px;
    width: 60px;
    margin-top:15px ;
}
.video-item{
  display: flex;
  margin-bottom: 15px;
}
.video-discription{
  flex: 1;
  padding-top: 5px;
}
.video-discription h6{
 color: black; 
 margin: 0px;
}
.minimize-button {
  position: absolute;
  color: #000000;
  right: 2rem;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: -0.25rem;
}
.video-upload-status
{
  text-align-last: right;
}
.video-upload-status small {
  font-size: medium;
}
.video-upload-status .button-div {
  margin-top: 10px;
}
.video-upload-status .cancel-button {
  background: white;
  flex: 1;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0.625rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  text-decoration: none;
  border: 1px solid var(--primary-color);
  transition: all 0.3s ease;
    margin-left: 10px;
    flex: 1;
  }
.video-upload-status .edit-button {
    background: white;
    flex: 1;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 0.625rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.05em;
    color:var(--primary-color);
    text-decoration: none;
    border: 1px solid var(--primary-color);
    transition: all 0.3s ease;

}
.progressbar {
  height: 20px;
  width: 98%;  
  background-color: #f1f1f1!important;
  margin-right: 10px;
}
.progressbar .innerbar
{
  background-color: #1c2e4e !important;
} 
.video-upload-status .queue-status .spinner
{
  color: #1c2e4e !important;
} 
.video-upload-status .queue-status{
  font-size: 18px;
}
.video-upload-status .queue-status.uploaded{
  color: #4BB543;
}

.video-upload-status .queue-status.failed{
  color: #f44336;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  box-shadow: 2px 3px 5px rgb(197 197 197 / 25%);
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  color: var(--primary-color);
  display: flex;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  align-items: center;
}
.uploadingList .uploadingList-item {
  padding: 5px;
}
.page-view-icon{
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.uploaded-icon
{
  color: #4BB543;
  size: 16px;
}

.failed-icon
{
  color: #f44336;
}
.uploadingList-item
{
  padding: 10px; display:flex; border-bottom:1px solid #e3e3e3
}

.uploadingList-item:hover 
{
  background-color: #e3e3e3;
}
.upload-lecture-background .modal-body
{
    max-height: 80vh;
    overflow: auto;
}
#uploadingList
{
  max-height: 40vh;
    overflow: auto;
}
</style>
