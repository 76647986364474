<template>
	<div class="container">
		<div class="instructor-flex">
			<!-- <div
            class="backgroundStudentImage"
            style="display: inline-block; background-size: 134%; padding: 0px"
          > -->
			<img
				src="https://gocbe-lms-files.b-cdn.net/edcentra/instructor-block-image.png"
				class="instructor-image"
			/>
			<!-- </div> -->
			<img
				src="https://gocbe-lms-files.b-cdn.net/edcentra/banner-image-4.tif.png"
				alt=""
				class="arrowImage"
			/>

			<div class="instructor-text-div">
				<p class="instructor-text">
					<strong
						>Become an
						<span style="background-color: #fdb12a; padding: 3px 0">
							Instructor</span
						>
						today</strong
					>
				</p>
				<p style="width: 360px; font-size: 17px">
					Teach what you love we gives you the tools to <br />Create an online
					courses.
				</p>
				<a href="/teach-with-us" class="gradientButton">
					Click here and start teaching today
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HompageBecomeInstructor",
};
</script>
