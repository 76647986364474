<template>
  <div class="user-modal-tab-body enrollment">
    <div
      v-if="user.productVariants == null || user.productVariants.length == 0"
      class="no-content"
    >
      <h5 class="text-center">No enrolled Courses</h5>
    </div>
    <div class="d-flex flex-column width-100 align-items-end">
      <table
        v-if="user.productVariants && user.productVariants.length > 0"
        id="product-variants"
        class="table table-hover table-borderless enrolled-users-table"
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Course Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- <div> -->
          <tr
            v-for="(productVariant, index) in user.productVariants"
            :key="index"
            class=""
          >
            <td class="col">{{ productVariant.productVariantID }}</td>
            <td class="col title">
              {{ productVariant.product.productTitle }} ({{
                productVariant.productVariantTitle
              }})
              <br />
              <span
                style="display: flex"
                v-if="
                  productVariant.enrollmentType ==
                  ProductVariantEnrollmentType.DIRECT_ENROLLMENT
                "
                ><span
                  >{{ productVariant.enrollmentType }} | <b>Expires At :</b>
                </span>
                <span
                  v-if="
                    productVariant.enrollmentType ==
                    ProductVariantEnrollmentType.DIRECT_ENROLLMENT
                  "
                >
                  <span v-if="editIndex == index">
                    <input
                      placeholder="Expires At"
                      class="form-input expires-at-input"
                      type="date"
                      v-model="updatedExpiresAt"
                      style="
                        width: 110px;
                        height: 20px;
                        padding: 0px;
                        margin-left: 10px;
                      "
                      name="expiresAt"
                      id="expires-at"
                    />
                    &nbsp;&nbsp;
                    <a
                      @click="updatedExpiresAtClick(productVariant)"
                      style="cursor: pointer"
                    >
                      <i class="fa fa-save fa-sm"></i
                    ></a>
                    &nbsp;&nbsp;
                    <a @click="editExpiresAt(-1, null)" style="cursor: pointer">
                      <i class="fa fa-times text-danger"></i
                    ></a>
                  </span>
                  <span v-else-if="productVariant.expiresAt"
                    >&nbsp;
                    {{
                      new Date(productVariant.expiresAt).toLocaleDateString(
                        "en-us",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}
                    &nbsp;&nbsp;
                    <a
                      @click="
                        editExpiresAt(
                          index,
                          new Date(
                            productVariant.expiresAt
                          ).toLocaleDateString()
                        )
                      "
                      style="cursor: pointer"
                    >
                      <img src="/img/edit-icon.637375ac.svg"
                    /></a>
                  </span>
                  <span v-else
                    >End of batch &nbsp;&nbsp;
                    <a
                      @click="
                        editExpiresAt(
                          index,
                          new Date(productVariant.endDate).toLocaleDateString()
                        )
                      "
                      style="cursor: pointer"
                    >
                      <img src="/img/edit-icon.637375ac.svg"
                    /></a>
                  </span>
                </span>
              </span>
              <span v-else>
                {{ productVariant.enrollmentType }}
              </span>
              <span class="text-muted">{{
                productVariant.shortDescription
              }}</span>
			  <div v-if="productVariant.subscriptionStatus == ProductVariantSubscriptionStatus.UNENROLLED">
				<span style="font-weight: bold">Unenrolled By:</span> {{ productVariant.unenrolledBy ? productVariant.unenrolledBy : '-' }}
			  </div>
      </td>
            <td>
              <div
                :class="[
                  'status-tag',
                  {
                    success:
                      productVariant.subscriptionStatus ==
                      ProductVariantSubscriptionStatus.ALLOWED,
                    danger:
                      productVariant.subscriptionStatus !=
                      ProductVariantSubscriptionStatus.ALLOWED,
                  },
                ]"
              >
                {{ productVariant.subscriptionStatus }}
              </div>
            </td>
            <td class="col-2">
              <div class="right-menu">
                <button
                  role="button"
                  class="right-menu-icon"
                  @click="showConfirmationModal('block', productVariant)"
                  title="Block"
                  v-if="
                    productVariant.subscriptionStatus &&
                    productVariant.subscriptionStatus !=
                      ProductVariantSubscriptionStatus.UNENROLLED &&
                    productVariant.subscriptionStatus.toLowerCase() != 'blocked'
                  "
                >
                  <i class="fa fa-ban"></i> <span>Block</span>
                </button>
                <button
                  role="button"
                  class="right-menu-icon"
                  @click="showConfirmationModal('allow', productVariant)"
                  title="Unblock"
                  v-else-if="
                    productVariant.subscriptionStatus.toLowerCase() == 'blocked'
                  "
                >
                  <i class="far fa-unlock"></i> <span>Unblock</span>
                </button>
                <button
                  v-if="
                    productVariant.enrollmentType ==
                      ProductVariantEnrollmentType.DIRECT_ENROLLMENT &&
                    productVariant.subscriptionStatus !=
                      ProductVariantSubscriptionStatus.UNENROLLED
                  "
                  role="button"
                  class="right-menu-icon delete text-danger"
                  @click="showConfirmationModal('unenroll', productVariant)"
                  title="Unenroll"
                >
                  <i class="fas fa-times"></i> <span>Unenroll</span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <AddUserEnrollmentModal
      :showModal="showEnrollModal"
      :user="user"
      @updateModalState="toggleEnrollModal"
      :enrolledCourses="user.productVariants"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AddUserEnrollmentModal from "./AddUserEnrollmentModal.vue";
import {
  ProductVariantEnrollmentType,
  ProductVariantSubscriptionStatus,
} from "../../../constants/productVariant";
export default {
  name: "UserEnrollments",
  data() {
    return {
      enrolledProductVariants: [],
      showEnrollModal: false,
      ProductVariantEnrollmentType,
      ProductVariantSubscriptionStatus,
      editIndex: -1,
      updatedExpiresAt: Date.now(),
    };
  },
  methods: {
    ...mapActions("user", [
      "editUserErollmentInProductVariant",
      "editUserSubscription",
      "fetchUserById",
    ]),
    handleBtnClick() {
      this.showEnrollModal = true;
    },
    toggleEnrollModal(state) {
      if (state !== undefined) {
        this.showEnrollModal = state;
      } else {
        this.showEnrollModal = !this.showEnrollModal;
      }

      this.$emit("updateMode", 0);
    },
    editExpiresAt(index, expiresAt) {
      expiresAt = new Date(expiresAt);
      this.editIndex = index;
      if (index > -1) {
        var day = ("0" + expiresAt.getDate()).slice(-2);
        var month = ("0" + (expiresAt.getMonth() + 1)).slice(-2);

        var today = expiresAt.getFullYear() + "-" + month + "-" + day;
        this.updatedExpiresAt = today;
      } else {
        this.updatedExpiresAt = null;
      }
    },
    updatedExpiresAtClick(variant) {
      var date = new Date(this.updatedExpiresAt);
      date.setHours(23, 59, 59);

      var requestObj = {
        userID: this.user.id,
        productVariantID: variant.productVariantID,
        expiresAt: date,
      };

      this.editUserSubscription(requestObj).then((res) => {
        this.$toasted.success("Expires date updated successfully", {
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        this.fetchUserById(this.user.id).then(() => {
          this.updatedExpiresAt = null;
          this.editIndex = -1;
        });
      });
    },
    showConfirmationModal(type, variant) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to ${
            type.toLowerCase() == "allow" ? "unblock" : type
          } ${this.user.firstName} ${this.user.lastName} from ${
            variant.productVariantTitle
          }?`,
          {
            title: "Are you sure?",
            size: "md",
            buttonSize: "md",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
            bodyClass: "confirm-modal-body",
          }
        )
        .then((value) => {
          console.log(variant);
          // this.isConfirm = value;
          if (value) {
            var requestObj = {
              userID: this.user.id,
              productVariantID: variant.productVariantID,
            };
            if (type.toLowerCase() == "unenroll")
              requestObj.status = "Unenrolled";
            else if (type.toLowerCase() == "block")
              requestObj.status = "Blocked";
            else if (type.toLowerCase() == "allow")
              requestObj.status = "Allowed";

            this.editUserErollmentInProductVariant(requestObj).then(() => {
              this.fetchUserById(this.user.id);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
  },
  computed: {
    ...mapState("user", ["usersByProductVariant"]),
  },
  components: {
    AddUserEnrollmentModal,
  },
  props: {
    user: Object,
    mode: Number,
  },
  watch: {
    mode: function () {
      if (this.mode == 2) {
        this.showEnrollModal = true;
      }
    },
  },
};
</script>

<style scoped>
/* .add-course-btn {
} */

.user-modal-tab-body.enrollment {
  flex-direction: row !important;
  /* align-items: center; */
  justify-content: space-between;
  /* max-height: 30rem;
	overflow-y: auto; */
}

.no-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.enrolled-users-table .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

/* .enrolled-users-table .unenrolled .title {
	opacity: 0.75;
} */

.right-menu-icon {
  /* width: 2rem; */
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  column-gap: 0.25rem;
  /* background: #ffffff; */
  /* border: 0.8px solid #cbcbcb; */
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07); */
  /* border-radius: 50%; */
}

.right-menu-icon img {
  width: 1rem;
  height: 1rem;
}

.right-menu-icon.delete {
  border-color: #ff0000;
}
</style>
