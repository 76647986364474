class Product {
	constructor(data) {
		console.log({ dataInConstructor: data });
		this.id = data.id || data.productID;
		this.title = data.title ? data.title : data.productTitle;
		this.friendlyTitle = data.friendlyTitle;
		this.properties = data.properties || data.productExtraProperties;
		this.sequence = data.sequence;
		this.categories = data.categories;
		this.thumbnailType = data.thumbnailType;
		this.thumbnailPath = data.thumbnailPath;
		this.isFeatured = data.isFeatured;
		this.isVisible = data.isVisible || data.productIsVisible;
	}
}

export default Product;
