<template>
    <div id="plans-grid">
      <div v-for="plan in plans" :key="plan.id" class="plan-card">
        <PlanCard :plan="plan" />
      </div>
    </div>
  </template>
  
  <script>
  import PlanCard from './PlanCard.vue';
  
  export default {
    name: 'Planners',
    data() {
      return {
        plans: [
        {
          title: "CA Foundation: Financial Accounting",
          isNew: true,
          duration: "3 Days",
          description: "Comprehensive study on financial accounting principles.",
          progress: 75
        },
        {
          title: "CA Intermediate: Corporate Laws",
          isNew: false,
          duration: "4 Days",
          description: "In-depth understanding of corporate laws and regulations.",
          progress: 60
        },
        {
          title: "ACCA Fundamentals: Management Accounting",
          isNew: true,
          duration: "5 Days",
          description: "Explore management accounting practices and techniques.",
          progress: 85
        },
        {
          title: "ACCA Professional: Audit and Assurance",
          isNew: false,
          duration: "6 Days",
          description: "Detailed coverage of audit and assurance standards.",
          progress: 70
        },
        {
          title: "CA Final: Strategic Financial Management",
          isNew: true,
          duration: "4 Days",
          description: "Advanced concepts in strategic financial management.",
          progress: 80
        },
        {
          title: "ACCA Essentials: Business Technology",
          isNew: false,
          duration: "3 Days",
          description: "Understanding the role of technology in business.",
          progress: 65
        },
        {
          title: "CA Intermediate: Cost Management",
          isNew: true,
          duration: "5 Days",
          description: "Study of cost management techniques and principles.",
          progress: 90
        },
        {
          title: "ACCA Professional: Financial Management",
          isNew: false,
          duration: "6 Days",
          description: "Comprehensive financial management practices.",
          progress: 70
        }
      ]
      };
    },
    components: {
      PlanCard,
    },
  };
  </script>
  
  <style scoped>

  #plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(299px, auto));
    gap: 16px;
    padding: 16px;
  }
  </style>
  