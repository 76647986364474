<template>
  <b-modal
    id="show-ticket-modal"
    @change="$emit('updateModalState')"
    :visible="visible"
    hide-header
    centered
    content-class="show-ticket-modal"
    size="xl"
    body-class="modal-body"
    hide-footer
    modal-class="custom-modal"
    no-close-on-backdrop
  >
    <div
      class="body-container"
      style=""
    >
      <button @click="closeModal" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <div v-if="!ticket">
        <h4>Loading...</h4>
      </div>
      <div v-if="ticket!=null">
        <div class="mb-3">
          <h1 class="add-enroll-title">{{parseTicketHash(ticket.id)}} - {{ ticket.subject }}</h1>
          <div  class="d-flex align-items-center gap-2">
            <div
              :class="[
                'status-tag',
                {
                  warning: ticket.status && ticket.status.toLowerCase() == `opened`,
                  success: ticket.status && ticket.status.toLowerCase() == `closed`,
                },
              ]"
            >
              {{ ticket.status }} 
            </div>
            <i class="fas fa-circle" style="font-size: 0.4em;"></i> {{ ticket.service ? ticket.service.title : "" }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-8 row">
            <div class="col-lg-12">
              <label
                ><strong>Opened By :</strong>
                {{
                  ticket.user
                  ? `${ticket.user.firstName} ${ticket.user.lastName}`
                  : ""
                }} ( {{ formatDate(ticket.createdAt) }} )</label
              >            
            </div>
            <div class="col-lg-12" v-if="ticket.lastTicketReply">
              <label
                ><strong>Last Modification :</strong> 
                <span
                  v-if="ticket.lastTicketReply && ticket.lastTicketReply.user"
                >
                  {{
                    ticket.lastTicketReply.user.firstName +
                    " " +
                    ticket.lastTicketReply.user.lastName
                  }}
                </span>
                ( {{
                  ticket.lastTicketReply
                  ? formatDate(ticket.lastTicketReply.createdAt)
                  : formatDate(ticket.createdAt)
                }} )</label
              >
            </div>
            <div
              class="col-lg-12"
              v-if="
                ticket && ticket.status && ticket.status.toLowerCase() == 'closed'
              "
            >
              <label
                ><strong>Closed By :  </strong><span v-if="ticket.closedBy">
                  {{ ticket.closedBy.firstName + " " + ticket.closedBy.lastName }}
                </span> ( 
                {{ ticket.closedAt ? formatDate(ticket.closedAt) : "" }} )</label
              >           
            </div>
          </div>
          <div class="col-lg-4" style="text-align: -webkit-right" v-if="ticket.status && ticket.status.toLowerCase() == `opened`">
            <button class="add-btn" role="button" @click="refresh()">
              Refresh
            </button>
          </div>
          <div class="col-lg-12 pt-2">
            <hr />
            <h4 class="add-enroll-title">Description</h4>
            {{ ticket.body }}
          </div>
        </div>
        <div v-if="replies && replies.length > 0">
          <div class="mt-4">
            <h4 class="add-enroll-title">Correspondence</h4>
          </div>
          <div
            v-for="reply in replies"
            :class="[
              'chat-box-style',
              'mb-3',
              { management: reply.user.type.toLowerCase() == 'management' },
            ]"
          >
            <label for=""
              ><span style="font-weight: bold">From</span>
              {{ reply.user.firstName }} {{ reply.user.lastName }}</label
            >
            <p for="">
              {{ reply.reply }}
            </p>
            <label for="" class="text-end w-100"
              >
              {{ formatDate(reply.createdAt) }}</label
            >
          </div>
        </div>
      </div>

      <div
        class="col-lg-12 mt-4 reply-body ps-0"
        v-if="
          ticket && ticket.status && ticket.status.toLowerCase() == 'opened'
        "
      >
        <hr />
        <div v-if="ticket!=null">
          <h4>Reply</h4>
          <textarea
            class="form-input"
            placeholder="Enter here..."
            v-model="reply"
            @input="resizeTextarea"
            :disabled="loading"
          ></textarea>
          <div class="step-btn-container mt-2">
            <button
              @click="sendTicketReply()"
              role="button"
              class="step-btn"
              :disabled="!reply || !ticket || loading"
            >
              Send Reply
            </button>
            <button @click="closeTicket()" role="button" :disabled="loading" class="step-btn">
              Close The Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { toShortDateString } from "../../helpers/FormatHelpers";

export default {
  name: "ViewTicketModal",

  data() {
    return {
      ticket: null,
      replies: null,
      reply: null,
    };
  },
  computed: {
    ...mapState("support", ["loading"]),
    visible() {
      return this.showModal;
    },
  },
  props: {
    showModal: Boolean,
    ticketID: String,
  },
  methods: {
    ...mapActions("support", [
      "fetchTickets",
      "fetchTicketByID",
      "fetchTicketReplies",
      "addTicketReply",
      "closeSupportTicket",
    ]),
    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    refresh(){
      this.ticket = null;
      this.replies = null;
      this.reply = null;
      this.fetchTicketByID(this.ticketID).then((_ticket) => {
          this.ticket = _ticket;
          this.fetchTicketReplies(this.ticketID).then((_replies) => {
            this.replies = _replies;
          });
        });
    },
    closeModal() {
      this.$emit("updateModalState", false);
    },
    formatDate(date) {
      return this.formatDateWithTime(new Date(date));
    },
    formatDateWithTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return toShortDateString(date) + "  " + strTime;
    },
    async sendTicketReply() {
      if (this.reply) {
        var replyObj = {
          ticketID: this.ticketID,
          reply: this.reply,
        };
        var replyResponse = await this.addTicketReply(replyObj);
        if (replyResponse) {
          this.reply = null;
          this.fetchTicketByID(this.ticketID).then((_ticket) => {
            this.ticket = _ticket;
            this.replies.push(replyResponse);
          });
        }
      }
    },
    async closeTicket() {
      var ticket = await this.closeSupportTicket(this.ticketID);
      if (ticket) {
        this.fetchTicketByID(this.ticketID).then((_ticket) => {
          this.ticket = _ticket;
        });
      }
    },
    parseTicketHash(ticketID)
    {
      var ticketHash="";
      if(ticketID){
      for (let index = 0; index < 4-(ticketID).toString().length; index++) {
        ticketHash+="0";        
      }
      ticketHash+=ticketID;
    }
      return ticketHash;
    
    }
  },
  watch: {
    showModal: function () {
      if (this.showModal == true)
      
        this.ticket=null;        
        this.replies= null;
        this.reply= null;
        this.fetchTicketByID(this.ticketID).then((_ticket) => {
          this.ticket = _ticket;
          this.fetchTicketReplies(this.ticketID).then((_replies) => {
            this.replies = _replies;
          });
        });
    },
  },
};
</script>

<style>
.col-lg-4 {
  padding-left: 0px;
}

.chat-box-style {
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;

  border-radius: 5px;
  padding: 10px 20px;
}
.chat-box-style.management {
  border: 1px solid skyblue !important;
  background-color: rgb(223, 238, 243) !important;
}
.view-ticket-modal {
  min-height: 45.375rem;
  width: 70vw;
}

.view-ticket-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.confirm-modal-body {
  padding: 1rem !important;
  font-size: 1.125rem;
}
.view-ticket-modal .body-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1.5rem;
  width: 100%;
}

.view-ticket-modal .add-enroll-title {
  text-transform: capitalize;
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 1rem;
  color: #000000;
}

.view-ticket-modal .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.view-ticket-modal .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0 0 0.875rem;
  /* max-width: 21.625rem; */
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  height: 40px;
}

.view-ticket-modal .upload-btn-wrapper {
  width: 11rem;
  max-width: 11rem;
  max-height: 11rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  background: #f5f5f5;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-btn-wrapper.preview {
  padding: 0;
}

#preview {
  max-width: 100%;
}

.upload-btn {
  padding: 0.5rem 1.25rem;
  font-weight: bold;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 6px;
  text-transform: uppercase;
  color: #000000;
  font-weight: normal;
}

.input-icon-right {
  /* position: absolute; */
  font-size: 1.25rem;
  right: 0rem;
  margin-top: 0.5rem;
  cursor: pointer;
}

.view-ticket-modal .upload-btn-wrapper .right-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  transition: opacity 0.3s, visibility 0s ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.view-ticket-modal .upload-btn-wrapper:hover .right-menu {
  visibility: visible;
  opacity: 1;
}

.view-ticket-modal .upload-btn-wrapper .right-menu-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.8px solid #cbcbcb;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
  border-radius: 50%;
}

.view-ticket-modal .upload-btn-wrapper .right-menu-icon img {
  width: 1rem;
  height: 1rem;
}

.view-ticket-modal .upload-btn-wrapper .right-menu-icon.delete {
  border-color: #ff0000;
}

.view-ticket-modal #profilePicturePath {
  pointer-events: none;
}

.view-ticket-modal .form-input.error {
  color: var(--color-danger) !important;
  border-color: var(--color-danger) !important;
}

.reply-body .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0 0 0.875rem;
  /* max-width: 21.625rem; */
  width: 100%;
  border-radius: 0;
  box-shadow: none !important;
  position: relative;
  height: 40px;
}
.show-ticket-modal{
  max-height: 85vh; overflow-x: hidden; overflow-y: auto
}
</style>
