<template>
  <div>
    <!-- <b-form-group> -->
    <h5
      v-for="(option, index) in answerOptions"
      :key="index"
      :class="'d-flex align-items-center gap-3'"
    >
      <input
        class="form-input"
        type="text"
        placeholder="Enter option here"
        :id="index"
        v-model="option.option"
      />
      <!-- <QuillRichTextEditor
        class="form-input answer-option"
        :text="option.option"
        :isInline="true"
        :editorID="'editor-answeroption-' + index"
        placeholder="Enter option here..."
        v-model="option.option"
      /> -->
      <button @click="removeOption(index)" class="" role="button">
        <img src="@/assets/images/cancel-icon.svg" style="max-height: 13px" />
      </button>
    </h5>
    <!-- </b-form-group> -->
  </div>
</template>

<script>
import QuillRichTextEditor from "../../../Editors/QuillRichTextEditor.vue";

export default {
  name: "TextboxButtonQuestion",
  data() {
    return {
      selectedAnswer: null,
    };
  },
  components: {
    QuillRichTextEditor,
  },
  props: {
    answerOptions: Array,
  },
  computed: {},
  mounted() {},
  methods: {
    removeOption(index) {
      if (this.answerOptions.length < 2) {
        this.$toasted.error("Atleast 1 option is required", {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          singleton: true,
        });
        return;
      }
      this.$emit("removeOpt", index);
    },
  },
  created() {},
  watch: {
    // selectedAnswer: function () {
    //   this.$emit("setAnswer", [this.selectedAnswer]);
    // },
    // answerOptions: function () {
    //   this.$emit("setAnswer", this.answerOptions);
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-option {
  margin-left: 10px;
  font-weight: lighter;
}
.radio-inputs {
  /* max-width: 100%; */
  padding: 5px;
  border-radius: 10px;
}
</style>

<style>
.quiz-radio input[type="radio"] {
  accent-color: var(--primary-color);
}

.radio-inputs .custom-control-label {
  margin: 0px;
}
</style>
