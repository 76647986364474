<template>
  <main>
    <div>
      <div class="container">
        <h5 class="text-danger" v-if="!joinUrl">
          Something went wrong, class details not available. Please go back to the courses dashboard and try joining the class again.
        </h5>
        <div v-else>
          <div v-if="openMeetingInSDK">
            <button class="btn btn-primary" @click="joinInSDK" v-if="status == 'NOT_STARTED'">Join Class</button>
            <button class="btn btn-primary" v-else-if="status == 'JOINING'" disabled>Joining</button>
            <div v-else-if="status == 'JOINED'">
              <p>Your class has been joined.</p>
              <button class="btn btn-link" @click="startZoomWebMeeting">Having trouble? Try in compatibility mode</button>
            </div>
            <div v-else-if="status == 'ERROR' && errorMsg">
              <p class="text-danger">{{errorMsg}}</p>
              <button class="btn btn-link" @click="startZoomWebMeeting">Try joining in compatibility mode</button>
            </div>
            <p class="text-danger" v-else-if="status == 'ERROR' && errorMsg">{{ errorMsg }}</p>
          </div>
          <div v-else>
            <h5>
              Your class will be joined on the Zoom application on your device. If you do not have Zoom already installed on your device, then install it first and then click on join button.
            </h5>
            <button class="btn btn-primary" @click="joinInZoomApp" v-if="status == 'NOT_STARTED'">Join Class</button>
            <button class="btn btn-primary" v-else-if="status == 'JOINING'" disabled>Joining</button>
            <p v-else-if="status == 'JOINED'">Your class has been joined. If you are having any trouble, please refresh the page.</p>
            <p class="text-danger" v-else-if="status == 'ERROR' && errorMsg">{{ errorMsg }}</p>
          </div>
        </div>

      </div>


<!--      <div className="container">-->
<!--        <center v-if="!isReadyToLoad"><h2 className="zoom-loading">Please wait while zoom is being loaded</h2></center>-->
<!--      </div>-->

    </div>

  </main>
</template>

<script>
import router from "../../router";
import {ZoomMtg} from '@zoom/meetingsdk'
import LiveClass from "../../models/content/liveClass";
import ProductVariant from "../../models/productVariant";
import {mapActions, mapState} from "vuex";
import {parseForURL} from "../../helpers/FormatHelpers";
import {setCookie, getCookie} from "../../helpers/CookieHelpers";

export default {
  name: "Zoom",
  props: {contentData: LiveClass, productVariantData: Object},
  data() {
    return {
      // leaveUrl: "",
      userEmail: null,
      userName: null,
      watermarkInterval: null,
      status: 'NOT_STARTED',
      errorMsg: undefined
    };
  },
  created() {

    if(this.joinUrl) {
      this.currentUser = JSON.parse(localStorage.getItem("user"));
      this.userEmail = this.currentUser?.email ?? `demo_${Math.random().toString(36).substring(2, 15)}@mail.com`;
      this.userName = this.currentUser?.firstName ?? "Demo Student";

      if(!this.openMeetingInSDK && !this.isDesktopApp) {
        this.loadZoomWebSDKDependencies();
      }

      /*if(this.openMeetingInSDK) {
        this.joinInSDK();
      } else {
        this.joinInZoomApp();
      }*/
    }
/*







    this.initObj = {
      meetingNumber: this.contentData.data.meetingId,
      userName: `${this.userName} (${this.userEmail})`,
      userEmail: this.userEmail,
      signature: this.signature,
      sdkKey: this.getSDKKey(),
      passWord: this.contentData.data.password,
      success: (success) => {

        if (!this.isDesktopApp) this.addWatermark(`${this.currentUser.id}`);
        this.isReadyToLoad = true;

        if (this.currentUser) {
          var attendanceObj = {
            contentID: this.contentData.id,
            userID: this.currentUser?.id,
            status: "Present",
            attendedFrom: "Online",
            timeIn: new Date().toISOString(),
            isMarkLate: false,
            isLeftEarly: false,
          };
          this.markLiveClassAttendance([attendanceObj]).then((res) => {
            if (res) {
              console.log(res);
            }
          });
        }

        console.log(success);

        if (!this.showParticipantsButton) this.hideParticipants()

        //this.ZoomMtg.showInviteFunction({ show: false });
        //this.ZoomMtg.showMeetingHeader({ show: false });

      },
      error: (error) => {
        console.log(error);
      },
    };

    if (!this.contentData.data.openInSDK) {
      if (this.currentUser) {
        var attendanceObj = {
          contentID: this.contentData.id,
          userID: this.currentUser?.id,
          status: "Present",
          attendedFrom: "Online",
          timeIn: new Date().toISOString(),
          isMarkLate: false,
          isLeftEarly: false,
        };
        this.markLiveClassAttendance([attendanceObj])
            .then((res) => {
              if (res) {
                console.log(res);
                this.saveJoinUrl(this.currentUser);
              }
            })
            .finally(() => {
              window.location.href = this.contentData.data.joining_Url;
            });
        return;
      }

      window.location.href = this.contentData.data.joining_Url;
    }

    if (this.contentData.data.registrantAllow && this.contentData?.data?.joining_Url) {

      const params = new URL(this.contentData?.data?.joining_Url).searchParams;
      let tk = params.get("tk");

      if (tk) this.initObj.tk = tk;

    }
    if (this.contentData) this.startMeeting();*/
  },
  methods: {
    ...mapActions("content", ["markLiveClassAttendance"]),

    getSDKKey() {
      let sdkKey = '';
      sdkKey = JSON.parse(
          this.contentData.externalService.serviceData
      ).SDKKey;

      return sdkKey;
    },

    async markAttendance() {
      if (this.currentUser) {
        const attendanceObj = {
          contentID: this.contentData.id,
          userID: this.currentUser?.id,
          status: "Present",
          attendedFrom: "Online",
          timeIn: new Date().toISOString(),
          isMarkLate: false,
          isLeftEarly: false,
        };

        const res = await this.markLiveClassAttendance([attendanceObj]);
        console.log('attendance marked', res);
      }
    },

    joinInZoomApp() {

      this.status = 'JOINING';

      this.markAttendance()
          .then(() => console.log('attendance marked'))
          .catch((err) => console.error('error while marking attendance', err))
          .finally(() => {
            window.location.href = this.joinUrl;
            this.status = 'JOINED';
          });
    },

    loadZoomWebSDKDependencies() {
      this.loadZoomCSS('https://source.zoom.us/3.6.1/css/bootstrap.css');
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();

      ZoomMtg.i18n.load("en-US");
      //ZoomMtg.i18n.reload("en-US");
    },

    async joinInSDK() {
      this.status = 'JOINING';
      this.errorMsg = '';

      if(!this.isDesktopApp) {
        this.startZoomWebMeeting();
      } else {
        this.isZoomWindowsSDKAvailable().then(async (isSDKAvailable) => {
          if(isSDKAvailable) {

            let zoomWindowsSDKParams = {
              token: this.signature,
              meetingNumber: this.contentData.data.meetingId,
              userName: `${this.userName}`,
              userEmail: this.userEmail,
              password: this.contentData.data.password,
              watermark: this.userEmail,
              showInviteUrl: this.showInviteUrl,
              showMeetingInfo: this.showMeetingID && this.showMeetingPassword,
              showParticipants: this.showParticipantsButton
            };

            if(this.isRegistrationRequired) {
              zoomWindowsSDKParams.registrantToken = this.registrantToken
            }

            const isExecuted = await this.sendMessageAndGetResponse({
              action: "OpenZoomSDK",
              value: zoomWindowsSDKParams
            }, window).then(function (response) {
              console.log('windows sdk execution success', response);
              return response.data;
            }).catch(function (error) {
              console.error('error executing windows sdk', error);
              return false;
            });

            if (!isExecuted) {
              // fallback to Zoom Web SDK when unable to start in Windows SDK
              this.startZoomWebMeeting();
            } else {
              await this.markAttendance();
              setTimeout(() => {
                this.status = 'JOINED';
              }, 7000);
            }
          } else {
            // fallback to Zoom Web SDK when unable to start in Windows SDK
            this.startZoomWebMeeting();
          }
        }).catch((err) => {
          // fallback to Zoom Web SDK
          console.error('Error in Zoom Windows SDK availability check', err);
          this.startZoomWebMeeting();
        })
      }
    },

    async startMeeting() {
      console.log(`Meeting STarted: Desktop: ${this.isDesktopApp}`)
      if (this.isDesktopApp) {
        var isZoomSDKAvailable = await this.sendMessageAndGetResponse({action: "isZoomSDKAvailable"}, window).then(function (response) {
          return response.data;
        }).catch(function (error) {
          return false;
        });
        if (isZoomSDKAvailable) {

          this.$bvModal
              .msgBoxConfirm(
                  `Click OK to start class automatically in best available quality. If you are facing issues, then press Cancel to manually join in compatibility mode.`,
                  {
                    title: "",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "danger",
                    okTitle: "Ok",
                    cancelTitle: "Cancel",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    bodyClass: "confirm-modal-body",
                  }
              )
              .then(async (value) => {
                if (value) {
                  var isExecuted = await this.sendMessageAndGetResponse({
                    action: "OpenZoomSDK",
                    value: {
                      token: this.desktopSDkToken,
                      meetingNumber: this.contentData.data.meetingId,
                      userName: `${this.userName} (${this.userEmail})`,
                      userEmail: this.userEmail,
                      password: this.contentData.data.password,
                      watermark: JSON.parse(localStorage.getItem("user")).id
                    }
                  }, window).then(function (response) {
                    return response.data;
                  }).catch(function (error) {
                    return false;
                  });
                  debugger;
                  if (!isExecuted) {

                    this.startZoomWebMeeting();
                  }

                } else {
                  this.startZoomWebMeeting();
                }
              })
              .catch((err) => {
                console.error(err);
                // An error occurred
              });
          return;
        }
      }


      this.startZoomWebMeeting();


    },

    sendMessageAndGetResponse(message, targetWindow) {
      return new Promise(function (resolve, reject) {
        function handleMessage(event) {
          if (event.source === targetWindow && event.data.responseTo && event.data.responseTo.action == message.action) {
            window.removeEventListener('message', handleMessage);
            resolve(event.data);
          }
        }

        window.addEventListener('message', handleMessage);
        targetWindow.postMessage(message, '*');
        setTimeout(() => {
          window.removeEventListener('message', handleMessage);
          reject(new Error('Response timeout'));
        }, 5000);
      });
    },

    async isZoomWindowsSDKAvailable() {
      return await this.sendMessageAndGetResponse({action: "isZoomSDKAvailable"}, window).then(function (response) {
        return response.data;
      }).catch(function (error) {
        return false;
      });
    },

    startZoomWebMeeting() {
      console.log(ZoomMtg);
      this.status = 'JOINING';
      this.errorMsg = '';

      console.log("Web meeting trying to initiate")
      //document.getElementById("zmmtg-root").style.display = "block";


      let meetingInfo = ['topic', 'host', 'dc', 'enctype'];

      if (this.showMeetingID) meetingInfo.push('mn')
      if (this.showMeetingPassword) meetingInfo.push('pwd')
      if (this.showMeetingPassword) meetingInfo.push('telPwd')
      if (this.showInviteUrl) meetingInfo.push('invite')
      if (this.showParticipantsButton) meetingInfo.push('participant')

      let meetingInitObj = {
        meetingNumber: this.contentData.data.meetingId,
        userName: `${this.userName} (${this.userEmail})`,
        userEmail: this.userEmail,
        signature: this.signature,
        sdkKey: this.getSDKKey(),
        passWord: this.contentData.data.password,
        success: (joinSuccess) => {
          this.addWatermark(this.userEmail);
          this.markAttendance()
              .then(() => console.log('attendance marked'))
              .catch((err) => console.error('error while marking attendance', err));

          console.log(joinSuccess);

          if (!this.showParticipantsButton) {
            this.hideParticipants();
          }

          if(this.isRegistrationRequired) {
            this.saveRegistrantJoinUrlInCookie();
          }

          //this.ZoomMtg.showInviteFunction({ show: false });
          //this.ZoomMtg.showMeetingHeader({ show: false });

          this.status = 'JOINED';

        },
        error: (error) => {
          console.error(error);
          this.status = 'ERROR';
          this.errorMsg = 'Some error occurred while joining class. [Code: JOIN_ERR]';
        },
      };

      ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        showMeetingHeader: false,
        disableInvite: !this.showInviteUrl,
        disablePreview: false,
        isSupportChat: true,
        isSupportAV: true,
        disableCallOut: true,
        isShowJoiningErrorDialog: true,
        disablePictureInPicture: true,
        isSupportCC: true,
        meetingInfo: meetingInfo,
        success: (success) => {
          console.log('zoom web sdk initialized success', success);

          if (this.isRegistrationRequired) {
            console.log('registrant Token', this.registrantToken);
            meetingInitObj.tk = this.registrantToken;
          }

          document.getElementById('zmmtg-root').style.display = 'block';

          ZoomMtg.join(meetingInitObj);
        },
        error: (error) => {
          console.error(error);
          this.status = 'ERROR';
          this.errorMsg = 'Some error occurred while joining class. [Code: SDK_INIT_ERR]';
        },
      });

      console.log("function ended ")
    },

    calculatedDuration() {
      var startTime = new Date(this.contentData.availableFrom).getTime();
      var endTime = new Date(this.contentData.availableTo).getTime();
      var diff = endTime - startTime;
      return diff / (1000 * 60);
    },

    saveRegistrantJoinUrlInCookie() {
      /*
        saving the current registrant join Url for current meeting
        to avoid empty join url issue when Zoom API to add registrant
        has been attempted more than 3 times
      */

      if (this.contentData.data.joining_Url) {
        const linkExpiryTime = new Date(this.contentData.availableTo).setTime(
            new Date(this.contentData.availableTo).getTime() + 60 * 60 * 1000
        );
        setCookie(
            `${this.contentData.id}_${this.currentUser?.id}`,
            this.contentData.data.joining_Url,
            new Date(linkExpiryTime).toUTCString()
        );
        console.log(
            `${this.contentData.id}_${this.currentUser?.id}`,
            linkExpiryTime
        );
      }
    },

    getRegistrantJoinUrlFromCookie() {

      if(this.currentUser) {
        return getCookie(`${this.contentData.id}_${this.currentUser?.id}`);
      }

      return;
    },

    loadZoomCSS(url) {
      // It is done so it could load only when loaded
      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);
    },

    hideParticipants() {
      const buttons = document.querySelectorAll('div[feature-type^="participants"] button');
      buttons.forEach(button => {
        button.style.display = 'none';
      });
    },

    addWatermark(watermarkText) {
      const watermark = document.createElement('div');
      watermark.id = 'watermark';
      watermark.style.position = 'fixed';
      watermark.style.opacity = '0.5';
      watermark.style.pointerEvents = 'none';
      watermark.style.zIndex = '999999999999';
      watermark.style.fontSize = '20px';
      watermark.style.color = 'red';
      watermark.textContent = watermarkText;
      document.body.appendChild(watermark);

      this.moveWatermark(watermark);
      this.watermarkInterval = setInterval(() => {
        this.moveWatermark(watermark);
      }, 4000);
    },

    moveWatermark(watermark) {
      const x = Math.random() * (window.innerWidth - watermark.offsetWidth);
      const y = Math.random() * (window.innerHeight - watermark.offsetHeight);
      watermark.style.left = `${x}px`;
      watermark.style.top = `${y}px`;
    },

    removeWatermark() {
      const watermark = document.getElementById('watermark');
      if (watermark) {
        document.body.removeChild(watermark);
      }
      if (this.watermarkInterval) {
        clearInterval(this.watermarkInterval);
      }
    }

  },
  computed: {
    leaveUrl() {
      console.log("leave function");
      if (this.productVariantData) {

        if (this.isEbookTheme) {
          return `/product/dashboard/chapters/${this.productVariantData && this.productVariantData.id}`
        }

        return `/product/content/${parseForURL(
            this.productVariantData && this.productVariantData.product ? this.productVariantData.product.friendlyTitle : ''
        )}-${this.productVariantData.id}`;
      } else {
        return `/user/dashboard/`;
      }
    },

    isEbookTheme() {

      if (this.productVariantData && this.productVariantData.properties && this.productVariantData.properties.contentViewTheme) {
        const contentViewTheme = this.productVariantData.properties.contentViewTheme;
        return contentViewTheme && contentViewTheme.toLowerCase() === "ebook";
      }

      return false;
    },

    isDesktopApp() {

      // const browser = bowser.getParser(window.navigator.userAgent);
      // if (browser &&
      //     browser.parsedResult &&
      //     browser.parsedResult.browser &&
      //     browser.parsedResult.browser.name && browser.parsedResult.browser.name.toLowerCase() == "electron") {
      //   this.isDesktopApp = true;
      // }
      //
      // if (this.isDesktopApp && this.contentData.data && this.contentData.data.token) {
      //   this.desktopSDkToken = this.contentData.data.token;
      // }

      console.log(window.navigator?.userAgent);

      return window.navigator?.userAgent?.toLowerCase().indexOf('electron') > -1;
    },

    signature() {
      return this.contentData?.data?.token;
    },

    joinUrl() {
      if(!this.contentData || !this.contentData.data)
        return;

      const url = this.contentData?.data?.joining_Url;

      if(url && url != '')
        return url;

      // if url not found, check if the meeting has registration required and get the previously saved url for the current registrant
      if(this.isRegistrationRequired) {
        const registrantUrl = this.getRegistrantJoinUrlFromCookie();

        if(registrantUrl && registrantUrl != '')
          return registrantUrl;

      }

      return;
    },

    isRegistrationRequired() {
      return this.contentData.data.registrantAllow ?? false;
    },

    openMeetingInSDK() {
      return this.contentData.data.openInSDK ?? true;
    },

    showInviteUrl() {
      return this.contentData?.extraProperties?.ShowInviteUrl?.toString()?.toLowerCase() === 'true' ||
          this.contentData?.extraProperties?.showInviteUrl?.toString()?.toLowerCase() === 'true';

    },

    showMeetingID() {
      return this.contentData?.extraProperties?.ShowMeetingID?.toString()?.toLowerCase() === 'true' ||
          this.contentData?.extraProperties?.showMeetingID?.toString()?.toLowerCase() === 'true';
    },

    showMeetingPassword() {
      return this.contentData?.extraProperties?.ShowMeetingPassword?.toString()?.toLowerCase() === 'true' ||
          this.contentData?.extraProperties?.showMeetingPassword?.toString()?.toLowerCase() === 'true';
    },

    showParticipantsButton() {
      return this.contentData?.extraProperties?.ShowParticipantsButton?.toString()?.toLowerCase() === 'true' ||
          this.contentData?.extraProperties?.showParticipantsButton?.toString()?.toLowerCase() === 'true';
    },

    registrantToken() {
      if (!this.isRegistrationRequired)
        return;

      const params = new URL(this.joinUrl).searchParams;
      const tk = params.get("tk");

      return tk;
    }
  },

  mounted() {
    ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
  },

  beforeDestroy() {
    this.removeWatermark();

    ZoomMtg.leaveMeeting({
      success: () => {
        console.log("leaveMeeting");
      },
      error: (e) => {
        console.error("error leaving meeting", e);
        document.getElementById("zmmtg-root").style.display = "none";
      },
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.zoom-loading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

main {
  width: 80%;
  margin: auto;
  text-align: center;
}


main button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main button:hover {
  background-color: #2681f2;
}
</style>

<style>
div[id="zmmtg-root"] {
  z-index: 999999999998 !important;
}

/* div[feature-type^="participants"] button {
	display: none;
} */

html:not([dir=rtl]) .chat-container .wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>