<template>
  <div :class="'editor-div '+(isReadOnly?'readonly':'')">
    <div :id="editorID" class="quill-editor"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import { VueEditor } from "vue2-editor";
import { getFileType } from "../../helpers/Converters";

export default {
  name: "QuillRichTextEditor",
  props: {
    text: String,
    isInline: Boolean,
    editorID: String,
    placeholder: String,
    isReadOnly: Boolean,
  },
  data() {
    return {
      quillEditor: null,
      toolbarOptions: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        ["link", "image", "video"],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ direction: "rtl" }],
        ["clean"],
      ],
      _text: "",
    };
  },
  methods: {
    ...mapActions("content", ["uploadContentFile"]),

    initQuillEditor() {
      const options = {
        modules: {
          toolbar: this.toolbarOptions,
        },
        theme: this.isInline ? "bubble" : "snow",

        placeholder: this.placeholder,
        readOnly: this.isReadOnly??false,
      };

      this.quillEditor = new Quill(`#${this.editorID}`, options);

      this.handleFileInput();
      this.handleSnippingImagePaste();
      this.quillEditor.on("text-change", this.handleTextChange);
    },

    async uploadFile(fileType, fileName, file) {
      let formData = new FormData();
      formData.append("Type", fileType);
      formData.append("Entity", "content/quiz");
      formData.append("File", file, fileName);
      formData.append("IsCompressionRequired", "false");

      try {
        return await this.uploadContentFile(formData);
      } catch (e) {
        return null;
      }
    },

    handleSnippingImagePaste() {
      const component = this;

      this.quillEditor.clipboard.addMatcher("img", async (node, delta) => {
        if (node && node.src) {
          try {
            const file = component.dataURItoBlob(node.src);
            file.name = "Picture.png";
            const range = component.quillEditor.getSelection(true);
            const url = await component.uploadFile("image", file.name, file);

            if (url) {
              component.quillEditor.insertEmbed(
                range.index,
                "image",
                url,
                Quill.sources.USER
              );
            }
          } catch (error) {
            console.error("Error handling image paste:", error);
          }
        }
        return delta;
      });
    },

    handleFileInput() {
      var component = this;
      this.quillEditor.getModule("toolbar").addHandler("image", function () {
        var input = document.createElement("input");
        input.setAttribute("type", "file");
        input.click();

        input.addEventListener("change", async function () {
          var uploadedFile = input.files[0];
          var fileName = uploadedFile.name;
          var fileType = getFileType(fileName);

          if (fileType !== "unknown") {
            try {
              const url = await component.uploadFile(
                fileType,
                fileName,
                uploadedFile
              );
              const range = component.quillEditor.getSelection();

              if (fileType === "image") {
                component.quillEditor.insertEmbed(range, "image", url);
              } else if (fileType !== "video") {
                const linkText = fileName.toUpperCase();
                let content = `<a href="${url}" class="editor-link" target="_blank">${linkText}</a>`;
                component.quillEditor.clipboard.dangerouslyPasteHTML(
                  range.index,
                  content
                );
              }
            } catch (error) {
              console.error("Error uploading and inserting link:", error);
            }
          }
        });
      });
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },

    getValue(event) {
      const content = event.target.innerHTML;
      console.log("Current Content:", content);
    },

    handleTextChange() {
      this._text = this.quillEditor.root.innerHTML;
      console.log({ _Text: this._text });
      this.$emit("input", this._text);
    },
  },
  components: {},
  created() {
    this._text = this.text;
  },
  mounted() {
    var self = this;
    setTimeout(() => {
      self.initQuillEditor();

      if (self.quillEditor) {
        self.quillEditor.root.innerHTML = self._text;
      }
    }, 1000);
  },
  computed: {},
  watch: {
    text() {
      if (this.quillEditor && this.text != this._text) {
        this._text = this.text;
        this.quillEditor.root.innerHTML = this._text;
      }
    },
  },
};
</script>

<style>
.editor-div.readonly .ql-toolbar.ql-snow{
  display: none;

}
.editor-div.readonly .ql-editor.ql-blank{
  padding: 0px;

}
.editor-div.readonly .quill-editor.ql-container
{
  border: 0px;
}

</style>
