<template>
	<div>
		<header>
			<div id="nav">
				<div class="logo-container">
					<!-- mobile-hamburger -->
					<a role="button" class="my-nav-link nav-link-mobile hamburger"
						><i class="fas fa-bars"></i
					></a>
					<!--  -->

					<!-- logo-image -->
					<router-link to="/" class="d-flex"
						><img class="logo" :src="require(`@/${LOGO_LIGHT_PATH}`)"
					/></router-link>
					<!--  -->
				</div>
				<div class="searchbar position-relative d-flex align-items-center">
					<input type="search" name="search" id="search" placeholder="Search" />
					<router-link to="/search" class="search-icon"
						><i class="fa fa-search"></i
					></router-link>
				</div>
				<div id="header-links">
					<a
						@click="openProductModal"
						type="button"
						class="my-nav-link d-inline btn-bold courses-link"
						>{{ HEADER_COURSES }}
						<img :src="require(`@/${HEADER_HAMBURGER_PATH}`)" alt="" />
					</a>
					<router-link
						class="my-nav-link hide-tab-mobile"
						v-if="this.loggedIn"
						to="/user/dashboard"
						>{{ HEADER_DASHBOARD }}</router-link
					>
					<router-link
						class="my-nav-link hide-tab-mobile"
						v-if="this.loggedIn"
						to="/user/profile"
						>{{ HEADER_PROFILE }}</router-link
					>
					<a
						v-if="this.loggedIn"
						type="button"
						@click="logout"
						class="my-nav-link hide-tab-mobile"
						>{{ HEADER_LOGOUT }}</a
					>
					<router-link
						v-if="this.loggedIn"
						class="my-nav-link hide-tab-mobile"
						to="/shop/checkout"
						><i class="fas fa-shopping-cart"></i
						><span class="cart-icon-badge">
							{{ cartItemsNumber }}
						</span></router-link
					>

					<a
						@click="toggleLoginDropdown"
						role="button"
						class=" my-nav-link nav-link-mobile"
						>{{ this.loggedIn ? HEADER_COURSES : HEADER_LOGIN }}</a
					>
					<div v-if="loginDropdown" class="dropdown-menu-custom">
						<router-link
							v-if="!this.loggedIn"
							class="my-nav-link d-inline"
							to="/account/login"
							>{{ HEADER_LOGIN }}</router-link
						>
						<router-link
							v-if="!this.loggedIn"
							class="my-nav-link d-inline"
							to="/account/register"
							>{{ HEADER_REGISTER }}</router-link
						>
						<router-link
							class="my-nav-link d-inline"
							v-if="this.loggedIn"
							to="/user/dashboard"
							>{{ HEADER_DASHBOARD }}</router-link
						>
						<router-link
							class="my-nav-link d-inline"
							v-if="this.loggedIn"
							to="/user/profile"
							>{{ HEADER_PROFILE }}</router-link
						>
						<a
							v-if="this.loggedIn"
							type="button"
							@click="logout"
							class="my-nav-link d-inline"
							>{{ HEADER_LOGOUT }}</a
						>
						<router-link
							v-if="this.loggedIn"
							class="my-nav-link d-inline"
							to="/shop/checkout"
							><i class="fas fa-shopping-cart"></i>
							<span class="cart-icon-badge">
								{{ cartItemsNumber }}
							</span></router-link
						>
					</div>
					<a
						@click="toggleSearchDropdown"
						role="button"
						class=" my-nav-link nav-link-mobile"
						><i v-if="!searchDropdown" class="fa fa-search"></i>
						<i v-else class="fas fa-times"></i>
					</a>
					<div
						v-if="searchDropdown"
						class="dropdown-menu-custom d-flex align-items-center"
					>
						<input
							type="search"
							name="search"
							class="searchbar-mobile"
							placeholder="Search"
						/>
						<router-link to="/search" class="search-icon mr-5"
							><i class="fa fa-search"></i
						></router-link>
					</div>

					<div v-if="!this.loggedIn" class="d-inline">
						<router-link
							class="my-nav-link hide-tab-mobile"
							to="/account/login"
							>{{ HEADER_LOGIN }}</router-link
						>
						<router-link
							class="my-nav-link hide-tab-mobile"
							to="/account/register"
							>{{ HEADER_REGISTER }}</router-link
						>
					</div>
				</div>
			</div>
		</header>

		<ProductModal v-if="productModal" @close-modal="closeProductModal" />
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ProductModal from "../Product/ProductDropdown/ProductDropdown.vue";
import {
	HEADER_DASHBOARD,
	HEADER_LOGIN,
	HEADER_REGISTER,
	HEADER_COURSES,
	HEADER_PROFILE,
	HEADER_LOGOUT,
} from "../../constants/globalTextConstants";

import { LOGO_LIGHT_PATH, HEADER_HAMBURGER_PATH } from "../../constants/urls";
export default {
	name: "HeaderOld",
	data() {
		return {
			loginDropdown: false,
			searchDropdown: false,
			productModal: false,
			HEADER_DASHBOARD,
			HEADER_LOGIN,
			HEADER_REGISTER,
			HEADER_COURSES,
			HEADER_PROFILE,
			HEADER_LOGOUT,
			LOGO_LIGHT_PATH,
			HEADER_HAMBURGER_PATH,
		};
	},
	computed: {
		...mapState("user", ["loggedIn"]),
		...mapGetters("cart", ["cartItemsNumber"]),
	},
	methods: {
		...mapActions("user", ["logout"]),
		toggleLoginDropdown() {
			this.loginDropdown = !this.loginDropdown;
			this.searchDropdown = false;
		},
		toggleSearchDropdown() {
			this.searchDropdown = !this.searchDropdown;
			this.loginDropdown = false;
		},
		closeDropdowns() {
			this.loginDropdown = false;
			this.searchDropdown = false;
		},
		openProductModal() {
			this.productModal = true;
			document.querySelector("body").classList.add("modal-open");
		},
		closeProductModal() {
			this.productModal = false;
			document.querySelector("body").classList.remove("modal-open");
		},
	},
	components: {
		ProductModal,
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.courses-link {
		display: none !important;
	}
	.nav-link-mobile {
		display: inline !important;
		text-transform: none !important;
	}
	.searchbar {
		display: none !important;
	}

	.dropdown-menu-custom {
		display: block !important;
	}
	.my-nav-link {
		font-size: 0.9rem;
		padding: 0.5rem !important;
	}
	#nav {
		padding: 4px 8px !important;
	}

	#nav .logo-container {
		height: 45px !important;
	}

	.searchbar {
		display: none !important;
	}
}
.my-nav-link {
	display: inline-block;
	padding: 0.5rem 1rem;
}

#nav {
	padding: 15px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 5px 5px 5.5px #eaeaea;
	/* border-bottom: 1px solid #eee; */
}

#nav .logo-container {
	display: flex;
	height: 56px;
	margin-right: 1rem;
}

#nav a {
	text-transform: uppercase;
	color: var(--color-text-default);
	text-decoration: none;
	font-weight: 500;
}

/* #nav a.router-link-exact-active {
	color: var(--primary-color);
} */

.searchbar {
	flex: 2;
	max-width: 500px;
}

.searchbar-mobile {
	height: 36px;
	border: none;
	outline: none;
	padding: 12px 36px 12px 24px;
	width: 100%;
}

#search {
	height: 36px;
	border: none;
	border-radius: 18px;
	background-color: var(--color-light-blue);
	outline: none;
	padding: 12px 36px 12px 24px;
	width: 100%;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 12px;
	right: 0;
}

.hamburger {
	/* display: none; */
	padding: 0;
	padding-right: 1rem !important;
	font-size: 1.2rem;
	align-self: center;
}

.nav-link-mobile {
	display: none;
}

.dropdown-toggle::after {
	display: none;
}

.dropdown-menu-custom {
	display: none;
	width: 100% !important;
	position: absolute;
	top: 3.3rem;
	padding: 0.5rem;
	left: 0;
	z-index: 1000;
	background-color: white;
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
}

.cart-icon-badge {
	position: relative;
	top: -0.75rem;
	right: 0.3rem;
	font-size: 0.7rem;
	line-height: 1;
	background-color: var(--color-success);
	display: inline-block;
	padding: 0.2rem 0.3rem;
	border-radius: 5rem;
	font-weight: 500;
}
</style>
