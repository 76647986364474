<template>
	<div class="eb-course-list-wrapper py-3 px-1">
		<div class="d-flex mb-4 gap-3 overflow-auto">
			<button
			  v-for="tab in tabs"
              @click="toggleTabState(tab)"
              :class="[
                'filter-button d-flex',
                { active: tab.id === activeTab.id },
              ]"
            >
              {{ tab.title }}
			  <b-badge variant="primary" class="bg-secondary ms-2">{{userProducts(tab.productVariantStatusToShow).length}}</b-badge>
            </button>
          </div>
		<div v-if="userProducts(activeTab.productVariantStatusToShow).length === 0" class="no-courses-message">
			You do not have any {{ activeTab.title }} courses right now.
		</div>
		<ul class="eb-course-list">
			<UserDashboardProductsListItem v-for="product in userProducts(activeTab.productVariantStatusToShow)" :key="product.id"			
			:data="product"			
			:purchased="true"></UserDashboardProductsListItem>
		</ul>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus } from "../../constants/productVariant";
import  UserDashboardProductsListItem  from "./UserDashboardProductsListItem.vue";
export default {
	name: "UserDashboardProducts",
	// props: {  
	// 	tabStatus:String
	// },
	data(){
		return {
			//productVariantProgress:{}
			tabStatus: "Allowed",
			tabs: [
				{
					id: 'currently-active',
					title: 'active',
					productVariantStatusToShow: 'allowed',
					isActive: false
				},
				{
					id: 'unenrolled-expired',
					title: 'unenrolled/expired',
					productVariantStatusToShow: 'unenrolled',
					isActive: false
				},
				{
					id: 'blocked',
					title: 'blocked',
					productVariantStatusToShow: 'blocked',
					isActive: false
				},
			],
			activeTab: undefined,
		};
	},
	components: { UserDashboardProductsListItem },
	methods:{
		...mapActions("user", ["fetchTrainersList"]),		
		...mapActions("productVariant", ["getProductVariantProgress"]),
		userProducts(subscriptionStatus) {
			var myProducts =
				this.user && this.user.productVariants
					? this.user.productVariants.filter(
							(productVariant) =>
								productVariant.status != ProductVariantStatus.DRAFT
								&& productVariant.product && productVariant.product.title
								&& productVariant.subscriptionStatus.toLowerCase() === subscriptionStatus
							// &&
							// ? productVariant.endDate
							// 	? new Date() >= productVariant.endDate
							// 	: true
							// : false
							// productVariant.subscriptionStatus &&
							// productVariant.subscriptionStatus.toLowerCase() != "unenrolled"
					  )
					: [];
			return myProducts
		},
		toggleTabState(tab) {
			this.activeTab = tab;
		}
	},
	computed: {
		...mapState("user", ["user","trainers"])		
	},
	created:function(){
		this.activeTab = this.tabs[0];
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		this.getProductVariantProgress(null).then((data)=>{
		});
	},
	watch:{
		user:function(){
			console.log(this.user);
		}
	}
};
</script>

<style scoped>
.no-courses-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}
.filter-button.active{
  color: black;
  border-bottom: 2px solid var(--primary-color);
  border-radius: 0px;
}
.filter-button{
    background: #ffffff;
    padding: 0.625rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.05em;
    border: 1px solid transparent;
}

@media screen and (max-width: 600px){
  .filter-button{
    padding: 0.5rem 0.25rem;
    font-size: 0.75rem;
  }
}
</style>
