<template>
  <b-modal @change="closeModal()" id="view-study-material-modal" :visible="visible" size="lg" hide-header hide-footer
    centered content-class="view-study-material-modal" body-class="modal-body" modal-class="custom-modal"
    @contextmenu="() => console.log('context menu')">
    <div class="body-container position-relative">
      <button @click="closeModal()" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <!-- <div id="no-click"></div> -->
      <div id="document-div" class="row document-div">
        <h4 v-if="isPDFLoading" class="loader px-4 py-3" style="position: absolute;z-index: -1;">Loading document, please wait...</h4>

        <!--
          PDFJS Viewer will be shown inside iFrame when downloadAllowed is either false
          or user is using through mobile
        -->
        <iframe v-if="type == 'pdf' && PDFJSViewerUrl && (isMobile || !isDownloadAllowed)" @change="hideShare" @loadeddata="hideShare" id="no-click" :src="PDFJSViewerUrl" frameborder="0"
                width="100%" height="100%" @contextmenu="disableRightClick" tabindex="1" @load="iframeLoadEvent" sandbox="allow-scripts allow-same-origin allow-forms"></iframe>

        <PDFExpressViewer v-else-if="type == 'pdf' && !isDownloadAllowed && pdfJsExpressApiKey && !isMobile"
          :path="`${publicPath}lib`" :url="iframeLink" :apiKey="pdfJsExpressApiKey" />

        <embed id="no-click" v-else-if="type == 'pdf' && isDownloadAllowed && !isMobile"
          :src="`${iframeLink}#embedded=true&navpanes=0`" frameborder="0" navpanes="0" width="100%"
          height="100%" />

        <AdobePDFViewer v-else-if="type == 'pdf' && isDownloadAllowed && isMobile && AdobePDFReaderAPIKey" :apiKey="AdobePDFReaderAPIKey" :source="src" :fileName="content[contentId].title"  />

        <!--
          Following iFrame will be used for non-PDF docs.
          Or, it will be used as fallback if AllowDownload of PDF is set to false
          and PDFExpress/AdobeViewer/PDFJSViewer none of them are configured for current tenant
        -->
        <iframe v-else @change="hideShare" @loadeddata="hideShare" id="no-click" :src="iframeLink" frameborder="0"
          width="100%" height="100%" @contextmenu="disableRightClick" tabindex="1" @load="iframeLoadEvent"
          sandbox="allow-scripts allow-same-origin allow-forms"></iframe>

      </div>
      <!--      <div class="overlay"></div>-->
      <div class="row">
        <div class="download-btn-div" v-if="this.isDownloadAllowed">
          <button class="btn btn-primary" @click="handleDownloadDocumentButtonClick">Download</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { default as download } from 'downloadjs';
//import pdf from 'vue-pdf';
import PDFExpressViewer from "@/components/Content/PDFExpressViewer";
import AdobePDFViewer from "../PDFViewer/AdobePDFViewer.vue"


export default {
  name: "StudyMaterial",
  components: {
    //pdf,
    PDFExpressViewer,
    AdobePDFViewer
  },
  data() {
    return {
      type: null,
      src: null,
      isDownloadAllowed: false,
      numberPages: 1,
      isPDFLoading: true,
      publicPath: process.env.BASE_URL
    };
  },
  props: {
    contentId: String,
    showModal: Boolean,
  },
  methods: {
    ...mapActions("content", ["getContentById"]),
    closeModal() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit("updateModalState", false);
    },
    handleRightClick(e) {
      console.log("handle right click");
      e.preventDefault();
      return false;
    },
    hideShare() { },

    handleDownloadDocumentButtonClick(e) {
      download(this.src);
      //window.open(this.src, 'download');
    },
    disableRightClick(e) {
      e.preventDefault();
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    iframeLoadEvent(e) {
      this.isPDFLoading = false;
      console.log({ e });
    }
  },
  computed: {
    ...mapState("content", ["content"]),
    ...mapState("appState", ["isMobile", "systemParameters"]),
    visible() {
      return this.showModal;
    },
    iframeLink() {

      //debugger;


      if (this.type == "document") {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${this.src}`;
      }

      if (this.type == "pdf" && !this.isDownloadAllowed && !this.pdfJsExpressApiKey) {
        let src = `https://docs.google.com/viewer?embedded=true&url=${this.src}?randomizer=${this.randomNumber(1, 10000)}`;

        return src;

      } else if (this.type == "pdf" && this.isMobile && !this.AdobePDFReaderAPIKey) {
        return `https://docs.google.com/viewer?embedded=true&url=${this.src}?randomizer=${this.randomNumber(1, 10000)}`;
      }
      return this.src;
    },
    pdfJsExpressApiKey() {
      return this.systemParameters.PDFJSExpressAPIKey
        ? this.systemParameters.PDFJSExpressAPIKey
        : undefined;
    },
    AdobePDFReaderAPIKey() {
      return this.systemParameters.AdobePDFReaderAPIKey
        ? this.systemParameters.AdobePDFReaderAPIKey
        : undefined;
    },

    PDFJSViewerUrl() {
      const baseUrl = this.systemParameters && this.systemParameters.PDFJSVIEWERURL
      if(!baseUrl) return null;
      return `${baseUrl}?pdfUrl=${encodeURIComponent(this.src)}`;
    },
  },
  mounted() {
    $("#no-click").on("load", function () {
      console.log("hideShare", $("[data-tooltip='Pop-out']"));
    });
    // console.log($("#view-study-material-modal"));
  },
  // beforeDestroy() {
  // 	$("body").off("onmousedown");
  // },
  watch: {
    visible: function () {


      if (this.visible) {
        // $(document).ready(function () {
        // 	setTimeout(function () {
        // 		var object = $("#no-click")[0];
        // 		console.log(object);
        // 		object.addEventListener("click", (e) => {
        // 			console.log("mousedown");
        // 			return false;
        // 		});
        // 	}, 300);
        // });
        if (!this.content[this.contentId] || this.content[this.contentId].externalService.serviceProvider.toLowerCase() == "vss" || !this.content[this.contentId].data) {
          this.getContentById({ id: this.contentId }).then(() => {
            //debugger;
            console.log("content fetched");
            if (this.content[this.contentId].externalService.serviceProvider.toLowerCase() == "vss") {
              this.src = this.content[this.contentId].data?.viewUrl;
            } else {
              if (this.content[this.contentId].data?.Type == "link") {
                window.open(this.content[this.contentId].data?.Source, "_blank");
                this.closeModal();
              } else {
                this.type = this.content[this.contentId].data?.Type;
                this.src = this.content[this.contentId].data?.Source;
                this.isDownloadAllowed = this.content[this.contentId].data?.AllowDownload?.toString().toLowerCase() == "true" ? true : false;
              }
            }
          });
        } else {

          //debugger;
          if (this.content[this.contentId].data?.Type == "link") {

            // console.log("opening in new tab");
            window.open(this.content[this.contentId].data?.Source, "_blank");
            this.closeModal();
          } else {
            this.type = this.content[this.contentId].data?.Type;
            this.src = this.content[this.contentId].data?.Source;
            this.isDownloadAllowed = this.content[this.contentId].data?.AllowDownload?.toString().toLowerCase() == "true" ? true : false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.row{
  margin-right: 0px;
  --bs-gutter-x: 0px;
}
</style>

<style>
div:has(> #view-study-material-modal) { 
  z-index: 9999999999999999 !important;
}

@media screen and (min-width: 996px) {
  .view-study-material-modal {
    height: fit-content !important;
    width: 90vw !important;
  }

  #view-study-material-modal .modal-dialog {
    max-width: 90vw !important;
  }
}

@media screen and (min-width: 996px) {
  .view-study-material-modal {
    height: fit-content !important;
    width: 95vw !important;
  }

  #view-study-material-modal .modal-dialog {
    max-width: 90vw !important;
  }
}


.view-study-material-modal {
  height: 80vh !important;
  margin: 10px;
}


.loader{
  margin-top: 50px;
}

/* .view-study-material-modal {
	height: 90vh;
	width: 90vw !important;
}
#view-study-material-modal .modal-dialog {
	max-width: 90vw !important;
} */

.view-study-material-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
  overflow: hidden;
  height: inherit;
}

.view-study-material-modal .modal-body .body-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: inherit;
}

.document-div {
  overflow: scroll;
  height: 100% !important;
}

.document-div::-webkit-scrollbar {
  width: 1.5rem !important;
  height: auto !important;
}


.document-div::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background: rgb(0 0 0 / 35%) !important;
}

.document-div::-webkit-scrollbar-thumb:hover {
  background: rgb(0 0 0 / 60%) !important;
}

.view-study-material-modal .modal-body .body-container .download-btn-div {
  margin: 10px;
}

.view-study-material-modal .modal-body .body-container .close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 99;
  background-color: #ffffff;
  padding: 0.75rem;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  box-shadow: 0 3px 6px #d5d5d529;
}

.close-button img {
  width: 1.5rem;
}

.pdf-viewer {
  height: fit-content;
}

.overlay {
  position: absolute;
  top: 0;

  height: 5rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

@media print {
  .document-div {
    display: none !important;
  }
}

/* #no-click {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1250;
	pointer-events: click;
} */
</style>
