<template>
	<div class="trianer-edit-container">
		<h5 for="trainers" class="main-label mt-2">Update Trainers:</h5>
		<div class="trainer-list-container">
			<ul
				class="all-trainers-list"
				v-if="
					!loading && allTrainers && allTrainers.length > 0 && assignedTrainers
				"
			>
				<TrainerPermissionsListItem
					v-for="item in allTrainers"
					:key="item.id"
					:trainerData="item"
					@editPermissions="updatePermissions"
					:trainersList="assignedTrainers"
					@checkboxToggle="checkboxToggle"
					:productVariantId="productVariantID"
				/>
			</ul>
			<div
				class="text-black"
				v-else-if="!loading && allTrainers && allTrainers.length == 0"
			>
				No Trainers found.
			</div>
		</div>
	</div>
</template>

<script>
import TrainerPermissionsListItem from "../Trainer/TrainerPermissionsListItem.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "UpdateContentTrainers",
	data() {
		return {
			updateTrainersList: null,
			allTrainers: null,
			trainersToUnassign: null,
			loading: false,
		};
	},
	props: { assignedTrainers: Array, productVariantID: String },
	components: { TrainerPermissionsListItem },
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		updatePermissions(value, id) {
			var trainerObj = this.assignedTrainers.find(
				(trainer) => trainer.id == id
			);
			this.$emit("updateTrainerPermissions", value, id);
			if (this.updateTrainersList) {
				var found = this.updateTrainersList.find((trainer) => trainer.id == id);
				var updatedTrainer = { ...trainerObj, permissions: value };
				if (!found) {
					this.updateTrainersList.push(updatedTrainer);
				} else {
					this.updateTrainersList = this.updateTrainersList.map((trainer) => {
						if (trainer.id == id) {
							return updatedTrainer;
						}
						return trainer;
					});
				}
			} else {
				var updatedTrainer = { ...trainerObj, permissions: value };
				this.updateTrainersList = [updatedTrainer];
			}
			// this.updateTrainersList = this.updateTrainersList.map((item) => {
			// 	if (item.trainerID == id) {
			// 		item.permissions = value;
			// 		console.log(item.permissions);
			// 	}
			// 	return item;
			// });
		},
		checkboxToggle(e, item) {
			if (e.target.checked) {
				var found = this.assignedTrainers.find((t) => t.id == item.id);
				if (found) var permissionsAssigned = found.permissions;

				var trainerObject = {
					trainerID: item.id,
					firstName: item.firstName,
					lastName: item.lastName,
					IsShowInTrainerList: true,
					jobTitle: "Trainer",
					permissions: permissionsAssigned
						? permissionsAssigned
						: ["RD", "WD", "ED"],
					isActive: true,
					email: item.email,
				};
				console.log(e.target.checked, trainerObject);

				this.addTrainer(trainerObject);
			} else if (!e.target.checked) {
				var trainerObject = {
					trainerID: item.id,
				};
				this.removeTrainer(trainerObject);
			}
		},
		addTrainer(trainerObject) {
			if (this.trainersToUnassign) {
				var toBeUnassigned = this.trainersToUnassign.find(
					(t) => t.trainerID == trainerObject.trainerID
				);

				if (toBeUnassigned) {
					this.trainersToUnassign = this.trainersToUnassign.filter(
						(t) => t.trainerID != trainerObject.trainerID
					);
				}
			}

			if (this.updateTrainersList) this.updateTrainersList.push(trainerObject);
			else this.updateTrainersList = [trainerObject];
			this.$emit("assignTrainer", trainerObject);
			// }
		},
		removeTrainer(trainerObject) {
			if (this.updateTrainersList)
				this.updateTrainersList = this.updateTrainersList.filter(
					(t) => t.id != trainerObject.id
				);
			if (this.trainersToUnassign) {
				var found = this.trainersToUnassign.find(
					(t) => t.trainerID == trainerObject.trainerID
				);
				if (!found) {
					this.trainersToUnassign.push(trainerObject);
				}
			} else {
				this.trainersToUnassign = [trainerObject];
			}
			this.$emit("unassignTrainer", trainerObject);
			// }
		},
	},
	computed: {
		...mapState("user", ["trainers"]),
	},
	created() {
		if (!this.trainers) {
			this.loading = true;
			this.fetchTrainersList().then(() => {
				this.allTrainers = this.trainers;
				this.loading = false;
			});
		} else {
			this.allTrainers = this.trainers;
			this.loading = false;
		}
	},
	watch: {
		trainersToUnassign: function () {
			this.$emit("updateTrainersToUnassign", this.trainersToUnassign);
		},
		updateTrainersList: function () {
			this.$emit("updateTrainersToAssign", this.updateTrainersList);
		},
		trainers: function () {
			this.allTrainers = this.trainers;
			this.loading = false;
		},
	},
};
</script>

<style scoped></style>
