<template>
	<b-modal id="create-edit-enrollment-modal" @change="$emit('updateModalState')" :visible="visible" hide-header
		centered content-class="create-edit-enrollment-modal" size="xl" body-class="modal-body" hide-footer
		modal-class="custom-modal" no-close-on-backdrop>
		<div v-if="!isConfirm" class="custom-stepper-container">
			<div class="custom-stepper">
				<div class="custom-stepper-step">
					<div :class="[
						'step-icon first',
						{ active: currentStep == 1, complete: currentStep > 1 },
					]">
						1
					</div>
					<div class="step-text">
						<h1 class="title">Select Students</h1>
						<h3 class="subtitle">Select students you wish to enroll</h3>
					</div>
				</div>
				<div class="custom-stepper-step">
					<div :class="[
						'step-icon',
						{ active: currentStep == 2, complete: currentStep > 2 },
					]">
						2
					</div>
					<div class="step-text">
						<h1 class="title">Manage Pricing and Payments</h1>
						<h3 class="subtitle">Select Pricing Plans and generate Invoices</h3>
					</div>
				</div>
			</div>
			<!-- Custom Stepper -->
		</div>
		<div :class="['body-container', { 'width-100': isConfirm }]">
			<button @click="currentStep > 1 ? showCloseConfirmPopup() : closeModal()" class="close-button"
				role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>

			<div v-if="currentStep == 1 && !isConfirm" id="page1">
				<div class="mb-4">
					<h1 class="add-enroll-title">Add New Enrollment</h1>
					<span class="add-pricing-sub-title mb-4">
						Select students you wish to enroll
					</span>
				</div>
				<div class="form-group">
					<label class="main-label">Students</label>
					<div class="searchbar">
						<input type="search" autocomplete="off"
							placeholder="Search by student name or email or phone number" class="form-input"
							v-model="searchText" 
							@keyup.enter="searchData" />
						<a role="button" @click="searchData"><img class="search-icon" src="@/assets/images/search-icon.svg" /></a>
					</div>
				</div>
				<!-- <input
				type="search"
				id="student-search"
				placeholder="Search by student name or email"
				class="form-input"
			/> -->
				<b-overlay :show="isSearching" rounded="sm">
					<div class="students-list" @scroll="handleScroll"  id="students-list">
						<div v-for="item in filteredStudentList" :key="item.id" class="student">
							<input type="checkbox" name="student" :id="`student${item.id}`"
								@change="checkboxToggle(item)" :checked="isChecked(item.id)" />
							<label :for="`student${item.id}`">{{ item.firstName }} {{ item.lastName }} ({{ item.email
							}})
							</label>
						</div>
						<div v-if="(isNextRecordAvailable && !isSearching && !fetchingNextRows)" >
							<br />
							<button @click="fetchNextRows()" type="button" class="select-all">
								Load More..... 
							</button>
						</div>
					</div>
					
				</b-overlay>
			</div>
			<div v-if="currentStep == 2 && !isConfirm" id="page2">
				<div class="mb-4">
					<h1 class="add-enroll-title">Manage Pricing and Payments</h1>
					<!-- <span class="add-pricing-sub-title mb-4">
						Select Students you wish to enroll
					</span> -->
					<EnrollmentPricingPlans v-if="productVariantData.pricingStatus.toLowerCase() != 'free'"
						@error="setError" ref="payments" :productVariantId="productVariantData.id"
						@updatePlan="planUpdate" @updatePayments="getInvoicesAndPaymentData"
						@removePayments="paymentsRemove" parent="variantenroll" />
				</div>
			</div>
			<div v-if="isConfirm && !isError">
				<ValidateAndGenerateInvoices :selectedPlan="selectedPlan" :productVariantId="productVariantData.id"
					:selectedStudents="selectedStudents" @setLoading="setError" />
			</div>
			<p v-if="isError" class="text-danger">
				<i class="fa fa-exclamation-circle"></i> {{ errorText }}
			</p>
			<div class="step-btn-container">
				<a @click="prev" v-if="showPrev && !isConfirm" role="button" class="step-btn prev"><i
						class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<button v-if="!isConfirm" @click="next" role="button" class="step-btn"
					:disabled="disableNext || disableEnroll">
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }} <i class="fas fa-long-arrow-alt-right"></i>
				</button>
				<a @click="comfirmInvoices" v-if="isConfirm" role="button" class="step-btn">Done
				</a>
			</div>
		</div>
	</b-modal>
</template>

<script>
import EnrollmentPricingPlans from "./EnrollmentPricingPlans.vue";
import ValidateAndGenerateInvoices from "./ValidateAndGenerateInvoices.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "CreateEditEnrollmentModal",
	data() {
		return {
			currentStep: 1,
			totalSteps: 2,
			studentList: [],
			selectedStudents: [],
			disableEnroll: false,
			isConfirm: false,
			selectedPlan: {
				item: null,
				payments: null,
				planCycles: null,
			},
			searchText: "",
			enrolledStudents: [],
			errorText: "",
			isError: false,
			usersLoading: false,
			nextPageToken: undefined,
			pageSize: 50,
			searchString: undefined,
			fetchingNextRows: false,
			isSearching: false,

		};
	},
	methods: {
		...mapActions("user", ["fetchPaginatedUsersList", "fetchUsersList", "enrollUserInProductVariant"]),
		
		isChecked(id) {
		
			var found = this.selectedStudents.find((cycle) => cycle.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		checkboxToggle(student) {
			var found = this.selectedStudents.find((s) => s.id == student.id);
			// console.log(found);
			if (!found) {
				this.selectedStudents.push(student);
			} else {
				this.selectedStudents = this.selectedStudents.filter(
					(su) => su.id != student.id
				);
			}
		},
		toggleSelectAll() {
			if (
				this.selectedStudents.length > 0 &&
				this.filteredStudentList.length > 0
			) {
				if (this.filteredStudentList.length == this.selectedStudents.length) {
					this.selectedStudents = [];
				} else {
					this.selectedStudents = this.filteredStudentList;
				}
			}
		},
		next() {
			// console.log("selectedPlan", this.$refs);
			if (this.currentStep < this.totalSteps) {
				if (!this.isError) this.currentStep += 1;
			} else if (this.currentStep == this.totalSteps) {
				if (this.isFree) this.enrollUsersInVariant();
				else this.showEnrollConfirmPopup();
			}
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep -= 1;
				this.errorText = "";
				this.isError = false;
			}
		},
		setError(error) {
			this.disableEnroll = error.status;
		},
		showEnrollConfirmPopup() {
			this.$bvModal
				.msgBoxConfirm(
					"Please confirm that you want to enroll these students. Clicking yes will enroll them in this course and generate their invoice",
					{
						title: "Please Confirm Enrollment",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					if (value) {
						this.getInvoicesAndPaymentData();
						if (!this.isError) this.isConfirm = value;
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		showCloseConfirmPopup() {
			this.$bvModal
				.msgBoxConfirm(
					"Are you sure you want to exit? All your progress will be lost if you exit now.",
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.closeModal();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		comfirmInvoices() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		getInvoicesAndPaymentData() {
			if (this.$refs.payments.selectedPlan) {
				this.selectedPlan.item = this.$refs.payments.selectedPlan;
				if (this.selectedPlan.item.type == "OneTime") {
					if (this.$refs.payments.isSinglePlanPaid) {
						if (!this.$refs.payments.singlePlanReceived) {
							this.errorText = "Please enter received amount";
							this.isError = true;
						} else if (!this.$refs.payments.paymentMethod) {
							this.errorText = "Please select a payment method";
							this.isError = true;
						} else {
							this.errorText = "";
							this.isError = false;
							this.disableEnroll = false;
							var payments = {
								paymentMethod: this.$refs.payments.paymentMethod,
								paymentComments: this.$refs.payments.paymentComments,
							};
							payments[this.selectedPlan.item.details[0].id] = {
								received: this.$refs.payments.singlePlanReceived,
								discount: this.$refs.payments.singlePlanDiscount,
							};
							this.selectedPlan.payments = payments;
						}
					}
				} else {
					// console.log(
					// 	Object.keys(this.$refs.payments.multiplePlanPayments).length > 0
					// );
					if (
						Object.keys(this.$refs.payments.multiplePlanPayments).length > 0
					) {
						if (!this.$refs.payments.paymentMethod) {
							this.errorText = "Please select a payment method";
							this.isError = true;
						} else if (!this.$refs.payments.selectedCycles) {
							this.errorText = "Please enter received amount";
							this.isError = true;
						} else {
							this.isError = false;
							this.disableEnroll = false;
							this.errorText = "";
							this.selectedPlan.payments =
								this.$refs.payments.multiplePlanPayments;
							this.selectedPlan.payments.paymentMethod =
								this.$refs.payments.paymentMethod;
							this.selectedPlan.payments.paymentComments =
								this.$refs.payments.paymentComments;
						}
					}
					this.selectedPlan.planCycles = this.$refs.payments.selectedCycles;
				}
			}
		},
		enrollUsersInVariant() {
			this.selectedStudents.forEach((student) => {
				var requestObj = {
					userID: student.id,
					productVariantID: this.productVariantData.id,
					expiresAt: this.productVariantData.endDate
						? this.productVariantData.endDate
						: new Date("12/31/2070").toISOString(),
					allowedRanges: [
						{
							startsFrom: this.productVariantData.startDate
								? this.productVariantData.startDate
								: new Date("12/31/1970").toISOString(),
							endsAt: this.productVariantData.endDate
								? this.productVariantData.endDate
								: new Date("12/31/2070").toISOString(),
						},
					],
				};
				this.disableEnroll = true;
				this.enrollUserInProductVariant({
					user: student,
					requestObj,
				}).then(() => {
					console.log(
						`User ${student.id} enrolled in variant ${this.productVariantData.id}.`
					);
					this.disableEnroll = false;
					this.enrolledStudents.push(student);
					if (this.enrolledStudents.length == this.selectedStudents.length)
						this.comfirmInvoices();
				});

				// .then(this.comfirmInvoices());
			});
		},
		planUpdate(plan) {
			this.selectedPlan.item = plan;
		},
		paymentsRemove() {
			console.log("Payments remove");
			this.selectedPlan.payments = null;
		},
		searchData() {
			this.nextPageToken = undefined;
			this.searchString = this.searchText;
			this.fetchingNextRows = true;
			this.isSearching = true;

			this.fetchPaginatedUsersList({ type: "student", pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString }).then((result) => {
								
				this.studentList = this.allUsers.filter((student) => {
					if (this.enrolledUsers) {
						var found = this.enrolledUsers.find((s) => s.id == student.id);
						if (!found) {
							return student;
						}
					} else return student;
				});
				
				this.fetchingNextRows = false;
				if(this.studentList && this.studentList.length<=5)
				{
					this.fetchNextRows();
				}			
					
				this.isSearching = false;
                
				if (result && result.nextPageToken) {
					this.nextPageToken = result.nextPageToken;
				}
			}).catch((err) => {
				this.fetchingNextRows = false;
				this.isSearching = false;
				console.log({ err });

			});
		},
		fetchNextRows() {
			if (!this.fetchingNextRows && this.isNextRecordAvailable) {
				this.fetchingNextRows = true;
				this.fetchPaginatedUsersList({ pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString }).then((result) => {
					this.fetchingNextRows = false;
					this.studentList = this.allUsers.filter((student) => {
						if (this.enrolledUsers) {
							var found = this.enrolledUsers.find((s) => s.id == student.id);
							if (!found) {
								return student;
							}
						} else return student;
					});
					if (result && result.nextPageToken) {
						this.nextPageToken = result.nextPageToken;
					}
				}).catch((err) => {
					this.fetchingNextRows = false;
					console.log({ err });
				});
			}
		},

		handleScroll(e){
			if($(e.target).scrollTop() + $(e.target).innerHeight() >= $(e.target)[0].scrollHeight - 10){
				this.fetchNextRows();
			}
		},
	},
	computed: {
		...mapState("user", ["allUsers", "loading", "isNextRecordAvailable"]),
		// ...mapState("user", ["allStudents", "loading"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			} else return "Enroll";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		disableNext() {
			// if (value) return value;
			if (this.currentStep == 1) {
				if (this.selectedStudents && this.selectedStudents.length > 0) {
					return false;
				} else return true;
				// if (this.topic && this.startTime && this.duration && this.zoomId) {
				// return false;
				// } else return true;
			} else if (this.currentStep == 2) {
				if (
					!this.selectedPlan.item ||
					this.isError ||
					((!this.selectedPlan.planCycles ||
						(this.selectedPlan.planCycles &&
							this.selectedPlan.planCycles.length == 0)) &&
						this.selectedPlan.item &&
						this.selectedPlan.item.type == "MultiplePaymentCycles")
				) {
					return true;
				}
				// console.log(this.$refs.payments ? this.$refs.payments.isError : false);
				// }
				// console.log(this.$refs.payments);
				// if (this.$refs.payments && this.$refs.payments.selectedPlan) {
				return false;
				// } else return true;
			}
		},
		selectBtnText() {
			if (
				this.selectedStudents.length > 0 &&
				this.filteredStudentList.length > 0
			) {
				if (this.selectedStudents.length == this.filteredStudentList.length) {
					return "Deselect All";
				} else {
					return "Select All";
				}
			}
		},
		filteredStudentList() {
			
			return this.studentList;
		},
	
	},
	props: {
		showModal: Boolean,
		productVariantId: String,
		enrolledUsers: Array,
		productVariantData: Object,
		isFree: Boolean,
	},
	components: {
		EnrollmentPricingPlans,
		ValidateAndGenerateInvoices,
	},
	watch: {
		visible: function () {
			if (this.visible) {
				this.usersLoading = true;
				if (this.searchData) {
					this.searchData();
				}
				if (this.isFree) {
					// this.isFree = true;
					this.totalSteps = 1;
				}
			}
		},
		currentStep: function () {
			if (this.currentStep == 2) {
				setTimeout(() => console.log(this.$refs.payments.selectedPlan), 300);
			}
		},
	},
};

</script>

<style>
.create-edit-enrollment-modal {
	min-height: 45.375rem;
	width: 70vw;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.create-edit-enrollment-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.confirm-modal-body {
	padding: 1rem !important;
	font-size: 1.125rem;
}

.create-edit-enrollment-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 70%;
}

.create-edit-enrollment-modal .add-enroll-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

.create-edit-enrollment-modal .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.students-list {
	max-height: 22rem;
	overflow-y: auto;
	margin-bottom: 2rem;
}

.students-list .student {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75rem;
	padding: 0.75rem 0;
	font-size: 1rem;
	border-bottom: 1px solid #dcdcdc;
	color: #000000;
}

.students-list .student input,
.students-list .student label {
	margin: 0;
}

.students-list input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.create-edit-enrollment-modal .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0 0 0.875rem;
	/* max-width: 21.625rem; */
	width: 100%;
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	height: 40px;
}

.create-edit-enrollment-modal .searchbar {
	/* flex-grow: 16; */
	font-size: 1rem;
	position: relative;
	/* max-width: 45.75rem; */
	/* margin-bottom: 1rem; */
}

.create-edit-enrollment-modal .searchbar .search-icon {
	position: absolute;
	margin-right: 0.75rem;
	right: 0;
	width: 1.375rem;
	top: 0.25rem;
}

.create-edit-enrollment-modal .select-all {
	margin-left: auto;
	font-size: 1rem;
	color: var(--primary-color);
}
</style>

