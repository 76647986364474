import store from "./index";

const state = {
	cart: localStorage.getItem("cart")
		? JSON.parse(localStorage.getItem("cart"))
		: {
				items: [],
				promoCode: "",
				totalAmount: 0,
				currency: null,
		  },
};

const getters = {
	getCartItems: () => {
		// console.log("gettingCartItems");
		return state.cart.items;
	},
	isCartEmpty: () => {
		return state.cart.items.length == 0;
	},
	cartItemsNumber: () => {
		return state.cart.items.length;
	},
	getCartTotalAmount: () => {
		return state.cart.totalAmount;
	},
};

const actions = {
	addToCart({ commit }, product) {
		if (!actions.isProductInCart(product.id)) {
			var total = state.cart.totalAmount;
			total += parseInt(product.pricing.amount);
			commit("setCartItem", product);
			commit("updateCartTotalAmount", total);
			commit("updateCartCurrency", product.pricing.currency);
		}
	},

	removeFromCart({ commit }, product) {
		if (!getters.isCartEmpty()) {
			var cartItems = state.cart.items;
			cartItems = cartItems.filter((item) => item.id != product.id);
			var total = state.cart.totalAmount;
			total -= product.pricing.amount;
			console.log(total);
			commit("setCartItems", cartItems);
			commit("updateCartTotalAmount", total);
		}
	},

	isProductInCart(productId) {
		var cartItems = state.cart.items;
		var found = cartItems.find((item) => {
			console.log("checking for item");
			if (item.id == productId) {
				return true; //found item
			}
		});
		return found;
	},

	setPromoCode({ commit }, promoCode) {
		commit("setPromoCode", promoCode);
	},

	addToCartTotalAmount({ commit }, amount) {
		var currentTotal = state.cart.totalAmount;
		currentTotal += amount;
		commit("updateCartTotalAmount", currentTotal);
	},

	subtractFromCartTotalAmount({ commit }, amount) {
		var currentTotal = state.cart.totalAmount;
		if (currentTotal > 0) {
			currentTotal -= amount;
		}
		commit("updateCartTotalAmount", currentTotal);
	},
	emptyCart: function ({ commit }, currency) {
		commit("setCart", {
			items: [],
			promoCode: "",
			totalAmount: 0,
			currency: store.state.appState.currentCurrency,
		});
	},
};

const mutations = {
	setCart(state, data) {
		state.cart = data;
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
	setCartItem(state, product) {
		state.cart.items.push(product);
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
	setCartItems(state, cartItems) {
		state.cart.items = cartItems;
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
	setPromoCode(state, promoCode) {
		state.cart.promoCode = promoCode;
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
	updateCartTotalAmount(state, totalAmount) {
		state.cart.totalAmount = totalAmount;
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
	updateCartCurrency(state, currency) {
		console.log("updateCartCurrency", currency);
		state.cart.currency = currency;
		localStorage.setItem("cart", JSON.stringify(state.cart));
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
