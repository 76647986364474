<template>
    <div class="card">
      <div class="header">
        <div class="info">
          <span class="title">{{ plan.title }}</span>
          <div v-if="plan.isNew" class="new-tag">NEW</div>
        </div>
        
        <div class="duration">
          <div class="days"> {{ plan.duration }}</div>
          <div class="label">DURATION</div>
        </div>
      </div>
      
      <div class="description">
        {{ plan.description }}
      </div>
      <div class="progress-wrapper">
        <div class="progress">
          <div class="progress-bar" :style="{ '--progress-value': `${plan.progress}%` }"></div>
        </div>
        <div class="progress-text"> {{ plan.progress }}% </div>
      </div>
      <div class="details">
        <div>
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path d="M10.5082 21C4.71201 20.9965 0.00861978 16.3206 1.18842e-05 10.5118C-0.00859601 4.7307 4.66 0.0124771 10.4828 2.40661e-05C16.2763 -0.0122458 20.9872 4.6688 21 10.4774C21.0128 16.2723 16.3307 20.9883 10.5082 21ZM10.4972 19.5346C15.5025 19.526 19.5458 15.4769 19.5359 10.4834C19.5258 5.49839 15.4686 1.45117 10.4936 1.46362C5.49698 1.47607 1.45493 5.52385 1.46409 10.5056C1.47343 15.4947 5.52372 19.5432 10.4972 19.5346Z" fill="black"/>
              <path d="M11.2328 7.1631C11.2328 8.13956 11.2335 9.11602 11.2315 10.0925C11.2315 10.1591 11.2505 10.2047 11.2993 10.2509C12.4879 11.3786 13.6739 12.5091 14.8631 13.6365C15.1067 13.8674 15.2127 14.1337 15.103 14.4609C14.9422 14.94 14.3426 15.1176 13.9584 14.7887C13.662 14.5351 13.3875 14.256 13.1044 13.987C12.076 13.0094 11.0487 12.0309 10.0198 11.0541C9.85057 10.8935 9.76724 10.7012 9.76724 10.4681C9.76816 8.40217 9.76724 6.33644 9.76816 4.27052C9.76834 3.84254 10.0856 3.5195 10.5004 3.51822C10.913 3.51712 11.2315 3.84108 11.2322 4.27052C11.2341 5.23472 11.2326 6.19891 11.2328 7.1631Z" fill="black"/>
            </svg>
          </span>
          10 Days Planner - 03 Aug to 10 Sep
        </div>
        <div>
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
              <path d="M9.49892 16.9995C6.66464 16.9995 3.83036 16.9991 0.996077 16.9996C0.606964 16.9996 0.306989 16.8408 0.116614 16.5011C-0.21681 15.9059 0.203439 15.1385 0.886656 15.1174C1.52343 15.0977 2.16128 15.1094 2.79859 15.1094C7.82379 15.109 12.849 15.1094 17.8742 15.109C18.0612 15.109 18.248 15.106 18.4241 15.1853C18.8415 15.3732 19.0714 15.8127 18.9777 16.2462C18.8821 16.688 18.5046 16.9975 18.0462 16.9984C16.7656 17.0009 15.4847 16.9995 14.204 16.9995C12.6357 16.9995 11.0671 16.9995 9.49892 16.9995Z" fill="black"/>
              <path d="M9.50052 10.8548C6.67816 10.8548 3.85562 10.8542 1.03326 10.8555C0.788622 10.8555 0.563907 10.805 0.367483 10.6523C0.0643052 10.4167 -0.063442 10.032 0.0383288 9.65292C0.134584 9.29461 0.449148 9.02123 0.823138 8.97301C0.881674 8.96539 0.941278 8.96166 1.00035 8.96166C6.66304 8.96113 12.3257 8.96149 17.9884 8.96078C18.3871 8.96078 18.6974 9.11662 18.8882 9.47084C19.2097 10.0676 18.8024 10.7996 18.1187 10.8468C17.8354 10.8663 17.5497 10.8544 17.2652 10.8546C14.677 10.8549 12.0887 10.8549 9.50052 10.8548Z" fill="black"/>
              <path d="M0.006303 7.5593V0C1.90721 1.26266 3.79282 2.51539 5.69604 3.77947C3.79762 5.04071 1.91184 6.29345 0.006303 7.5593Z" fill="black"/>
              <path d="M13.2959 4.7239C11.7306 4.7239 10.1652 4.72266 8.59988 4.72478C8.2033 4.72531 7.89514 4.57125 7.70601 4.22146C7.3676 3.59527 7.82006 2.83895 8.53619 2.83558C9.67453 2.83026 10.813 2.83398 11.9516 2.83398C13.9735 2.83398 15.9953 2.83363 18.0172 2.83434C18.4555 2.83434 18.8118 3.08201 18.9451 3.47436C19.1476 4.07076 18.7296 4.6989 18.0985 4.71734C17.4972 4.73489 16.8951 4.72372 16.2932 4.72407C15.2942 4.72461 14.295 4.7239 13.2959 4.7239Z" fill="black"/>
            </svg>
          </span>
          14 Lessons Items
        </div>
      </div>
      <a href="#" class="view-button" @click="viewPlanner">View Planner</a>
    </div>
  </template>
  
  <script>
  export default {
    name: "PlanCard",
    props: {
      plan: Object,
    },
    methods: {
      viewPlanner() {
        this.$emit('view-planner');
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    padding: 10px 16.67px 15.33px 16.67px;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
  
  .card .header {
    display: flex;
    align-items: self-start;
    margin-bottom: 5px;
    justify-content: space-between;
  }

  .card .header .info {
    display: flex;
    width: 60%;
    align-items: baseline;
  }
  
  .header .title {
    margin-left: 4px;
    font-weight: bold;
    font-size: 16px;
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis; 
    line-height: 1.2; 
  }

  
  .header .new-tag {
    background-color: #52c41a;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 1.5px 4.5px;
    margin-left: 5px;
    border-radius: 4px;
  }
  
  .duration {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid #FFD3D4;
    background: #FFF;
    color: black;
    text-align: center;
    margin-left: 20px;
  }
  
  .duration .days {
    font-size: 13.5px;
    font-weight: 700;
    flex-shrink: 0;
    padding: 4px 8px;
  }
  
  .duration .label {
    font-size: 8px;
    font-weight: 450;
    letter-spacing: 0.64px;
    color: black;
    background-color: #FBF8F8;
    padding: 6px 8px 8px 8px;
  }
  .description {
    color: #767676;
    font-family: "Circular Std";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    margin-bottom: 8px;
  }

  .progress-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .progress {
    flex: 1;
    background-color: #e5e5e5;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    margin: 10px 0;
  }
  
  .progress-bar {
    border-radius: 7px;
    background: #4CBD99;
    width: var(--progress-value);
  }

  .progress-text {
    font-family: "Circular Std";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 8px;
  }
  
  .details {
    color: #000;
    font-family: "Circular Std";
    font-size: 11px;
    font-weight: 450;
    margin-bottom: 10px;
  }

  .details > div {
    margin-bottom: 4px;
  }
  
  .details .icon {
    margin-right: 5px;
  }
  
  .view-button {
    display: block;
    border-radius: 8px;
    background: #4A4A4A;
    color: white;
    text-align: center;
    padding: 10px;
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
  
  </style>
  