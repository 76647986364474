<template>
	<div class="product-card">
		<div class="product-image-container">
			<img
				:src="require(`@/${productVariantData.product.thumbnailPath}`)"
				alt=""
				class="cart-product-image"
			/>
		</div>
		<div
			class="d-flex justify-content-between flex-grow-1 flex-lg-row flex-md-row flex-column"
		>
			<div class="col-md-8 cart-product-content-container">
				<h3 class="text-bold">
					{{ productVariantData.product.title }} -
					{{ productVariantData.title }}
				</h3>
				<p v-if="productVariantTrainers && productVariantTrainers.length > 0">
					<span class="text-500">Trainers:</span>
					{{
						productVariantTrainers
							.map((p) => {
								return p.firstName + " " + p.lastName;
							})
							.toString()
							.split(",")
							.join(",\n")
					}}
				</p>
				<p>
					<span class="text-500">Course Start Date:</span>
					{{
						productVariantData.startDate
							? new Date(productVariantData.startDate).toDateString()
							: "-"
					}}
				</p>
				<p>
					<span class="text-500">Course End Date:</span>
					{{
						productVariantData.endDate
							? new Date(productVariantData.endDate).toDateString()
							: "-"
					}}
				</p>
			</div>
			<div class="col-md-3 cart-product-price-container">
				<h4 class="text-bold">
					{{
						productVariantData.pricing.amount
							? parseCurrency(
									productVariantData.pricing.amount,
									productVariantData.pricing.currency,
									0
							  )
							: "Free"
					}}
				</h4>
				<a
					role="button"
					class="remove-btn"
					@click="removeFromCart(productVariantData)"
					>Remove</a
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";

export default {
	name: "CartProductItem",
	props: { productVariantData: Object },
	methods: {
		...mapActions("cart", ["removeFromCart"]),
		...mapActions("user", ["fetchUsersList","fetchTrainersList"]),

		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
	},
	computed: {
		...mapState("user", ["trainers"]),
		productVariantTrainers() {
			var trainerList = [];
			if (
				this.productVariantData.properties &&
				this.productVariantData.properties.trainers
			) {
				if (this.trainers) {
					var list = JSON.parse(this.productVariantData.properties.trainers);
					// console.log(list);
					list.forEach((trainerID) => {
						var found = this.trainers.find((t) => t.id == trainerID);
						if (found) {
							trainerList.push(found);
						}
					});
					return trainerList;
				}
				this.fetchTrainersList().then(() => {
					var list = JSON.parse(this.productVariantData.properties.trainers);
					// console.log(list);
					list.forEach((trainerID) => {
						var found = this.trainers.find((t) => t.id == trainerID);
						if (found) {
							trainerList.push(found);
						}
					});
				});
			}
			return trainerList;
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.cart-product-image {
		width: 100px !important;
	}
}

@media screen and (max-width: 768px) {
	h3 {
		font-size: 1.1rem;
	}

	h4 {
		font-size: 1.2rem;
	}

	.cart-product-price-container {
		text-align: left !important;
		margin-left: 1rem;
		margin-top: 0.5rem;
	}

	.cart-product-content-container {
		font-size: 0.9rem;
	}

	.cart-product-image {
		width: 70px !important;
	}

	.product-card {
		padding: 1rem !important;
	}
}

p {
	margin: 0;
}

.product-card {
	width: 100%;
	display: flex;
	flex-direction: row;
	border: 1px solid var(--color-light-blue);
	background-color: var(--color-white);
	box-shadow: 3px 3px 10px var(--color-light-blue);
	/* height: 200px; */
	border-radius: 8px;
	padding: 1.5rem 2rem;
	margin-bottom: 1rem !important;
}

.cart-product-image {
	width: 170px;
	border-radius: 4px;
}

.cart-product-content-container {
	margin-left: 1rem;
}

.cart-product-price-container {
	text-align: right;
}

.cart-product-price-container h4 {
	margin-bottom: 1rem;
}

.remove-btn {
	padding: 0.5rem 0.75rem;
	text-transform: uppercase;
	font-size: 0.75rem;
	background-color: var(--color-light-blue);
	text-decoration: none;
	color: var(--color-text-default);
	border-radius: 1rem;
}
</style>
