//USER API URLS
export const POST_USER_LOGIN = `/user/login`;
export const POST_USER_REGISTER = `/user/signup`;
export const FETCH_CURRENT_USER = `/user/me`;
export const PUT_USER_PROFILE = `/user/profile`;
export const PATCH_USER_STATUS = `/user/status`;
export const UPDATE_USER_PROFILE = `/user/changepassword`;
export const REGENERATE_USER_TOKEN = `/token/regenerate`;

//USER CATEGORY URLS
export const FETCH_POST_PUT_DELETE_CATEGORIES_API = `/category`;
export const ASSIGN_PRODUCT_TO_CATEGORY_POST_API = `/product/category`;
export const UNASSIGN_PRODUCT_TO_CATEGORY_POST_API = `/product/category`;

//SYSTEM API URLS
export const SYSTEM_PARAMS_API = `/systemparameters`;
export const EXTERNAL_SERVICES_API = `/services/external`;
export const POST_SERVICES_API = `/services`;

//PRODUCT API URLS
export const FETCH_POST_PUT_PRODUCT_API = `/product`;
export const FETCH_PRODUCT_BY_CATEGORY_API = `/product/category`;
export const UPDATE_PRODUCT_SEQUENCE = `/product/sequence`;
export const UPDATE_CATEGORY_SEQUENCE = `/Category/sequence`;

//PRODUCTVARIANT API URLS
export const FETCH_POST_PUT_PRODUCT_VARIANTS_API = `/productvariant`;
export const FETCH_PRODUCT_VARIANTS_BY_CATEGORY_API = `/productvariant?CategoryID=`;
export const FETCH_PRODUCT_VARIANTS_BY_PRODUCT_API = `/productvariant?ProductID=`;
export const ASSIGN_CONTENT_TO_PRODUCT_VARIANTS_API = `/productvariant/content`;
export const PATCH_PRODUCT_VARIANT_SEQUENCE = `/productvariant/sequence`;
export const POST_PRODUCT_VARIANT_ENROLL_USER = `/productvariant/user`;
export const PUT_UPDATE_PRODUCT_VARIANT_ENROLL_USER = `/productvariant/user/subscription`;
export const PRODUCT_VARIANT_FEEDBACK_RATING = `/productvariant/rating`;
export const UNENROLL_USER_COURSE_SUBSCRIPTION = `/productvariant/users/subscription`;
export const ENROLL_USERS_COURSES_SUBSCRIPTION = `/productvariants/users`;
export const UNENROLL_USERS_COURSES_SUBSCRIPTION = `/productvariants/users/subscription`;
export const FETCH_PRODUCT_VARIANT_PROGRESS = `/productvariant/progress`;
export const PASTE_PRODUCT_VARIANT_ITEMS = `/subject/copy`;
export const PASTE_PRODUCT_VARIANTS = `/course/copy`;
export const DELETE_PRODUCTVARIANT_ITEM = `/productvariant/content`;
export const PUT_RATING_API = `/productvariant/rating`;

//CONTENT API URLS
export const FETCH_PUT_POST_CONTENT_API = `/content`;
export const POST_FILE_API = `/file/upload`;
export const FETCH_POST_CONTENT_TRAINERS_API = `/content/trainer`;
export const PATCH_CONTENT_SEQUENCE = `/content/sequence`;
export const PATCH_CONTENT_ITEMS_SEQUENCE = `/contentitems/sequence`;
export const FETCH_CONTENT_ATTENDANCE_API = `/Content/Attendance/Report`;
export const FETCH_CONTENT_ATTENDANCE_DETAIL_API = `/Content/Attendance/Detail`;
export const FETCH_POST_LIVE_CLASS_ATTENDANCE_API = `/LiveClassAttendance/Report`;
export const MARK_LIVE_CLASS_ATTENDANCE_API = `/LiveClassAttendance/Mark`;
export const MARK_ALL_IN_PROGRESS_ATTENDANCE_API = `/LiveClassAttendance/MarkCurrentClasses`;
export const EXPORT_LIVE_ATTENDANCE_API = `/Export/LiveAttendance`;
export const EXPORT_CONTENT_ATTENDANCE_API = `/Export/ContentAttendance`;
export const COPY_CONTENT_API = `/Content/Copy`;
export const FETCH_POST_USER_CONTENT_ACTION_API = `/Content/action`;
export const FETCH_USER_CONTENT_ACTIONS_BY_PRODUCT_VARIANT = '/content/action/productvariant';
export const FETCH_USER_CONTENT_BY_PRODUCT_VARIANT = '/content/productvariant';

//PRICING API URLS
export const FETCH_PRICING_BY_PRODUCTVARIANT_API = `/productvariant/`;
export const POST_UPDATE_DELETE_PRICING_API = `/productvariant/plan`;

//INVOICE API URLS
export const FETCH_POST_INVOICES_API = `/invoice`;
export const FETCH_INVOICE_BY_USER_API = `/invoice/user/`;
export const FETCH_INVOICE_BY_ID_API = `/invoice/`;
export const POST_INVOICE_PAYMENT_API = `/invoice/payment`;
export const DELETE_INVOICE_DETAIL_API = `/invoice/detail`;

//PAYMENT API URLS
export const FETCH_PAYMENTS_BY_USER_API = `/invoice/payment/user/`;
export const FETCH_PAYMENTS_LIST = `/invoice/payment`;
export const PROCESS_PAYMENT_API = `/payment/process`;
export const COMPLETE_PAYMENT_API = `/payment/complete`;

// TRAINER API URLS
export const FETCH_TRAINERS_API = `/trainer`;

// USERS API URLS
export const FETCH_POST_USERS_API = `/user`;
export const EXPORT_POST_USERS_API = `/user/export`;
export const PUT_USERS_API = `/user`;
export const FETCH_POST_USERS_BY_PRODUCT_VARIANT_API = `/user/productvariant/`;
export const GET_GENERATE_OTP = `/user/verificationcode`;
export const VERIFY_USER = `/user/verificationcode/verify`;
export const POST_VERIFY_OTP = `/user/verificationcode/verify`;
export const POST_RESET_PASSWORD = `/user/verificationcode/resetpassword`;
export const FETCH_USERS_BY_EMAILS = `/users/emails`;

// EXPORT API URLS
export const EXPORT_USERS_ENROLLMENT_API = `/Export/Users/Enrollment`;

// DEVICES API URLS
export const FETCH_POST_USER_DEVICES_API = `/devices`;
export const FETCH_USER_DEVICE_RESTRICTIONS_API = `/devices/restrictions?UserID=`;
export const POST_USER_DEVICES_RESET_API = `/devices/reset?UserID=`;
export const POST_USER_DEVICE_REMOVE_API = `/device/reset`;
export const RESET_ALL_USERS_DEVICES = `/devices/reset/all`;
export const MARK_USERS_STATUS_ACTIVE_OR_INACTIVE = `/users/status/bulk`;

// QUESTION API URLS
export const FETCH_PUT_POST_QUESTION_API = `/question`;
export const FETCH_PUT_POST_QUESTION_CATEGORY_API = `/question/category`;

// EXAM API URLS
export const FETCH_POST_PUT_EXAM_THEME_API = "/exam/theme";
export const FETCH_EXAM_RESULT_PRODUCT_VARIANT = "/exam/result/productvariant";
export const FETCH_EXAM_CONTENT_RESULT = "/exam/result";


//PRODUCT URLS
export const PURCHASED_PRODUCT_LINK = "/product/content/";

//IMAGE URLS
export const LOGO_LIGHT_PATH = "assets/images/gocbe_logo.png";
export const HEADER_HAMBURGER_PATH = "assets/images/hamburger.svg";
export const HEADER_CART_ICON = "assets/images/cart.svg";
export const LOGO_DARK_PATH = "assets/images/gocbe_logo_dark_bg.png";

//FORM API URLS
export const FETCH_FORM_API = `/form`;
export const POST_FORM_SUBMISSION_API = `/form/submission`;

//SUPPORT API URLS
export const FETCH_SUPPORT_TICKETS = `/Support/Ticket`;
export const POST_SUPPORT_TICKETS = `/Support/Ticket`;
export const FETCH_SERVICES_SUPPORT_TICKETS = `/Support/Ticket/Service`;
export const FETCH_SUPPORT_TICKETS_REPLIES = `/Support/Ticket`;

//QUIZ API URLS
export const POST_QUIZ_SUBMIT = `/Quiz/Submit`;
export const QUIZ_QUESTION = `/Quiz/Question`;
export const QUIZ_ATTEMPT_DETAIL = `/Quiz/Result/Details`;

//Assignment API URLS
export const GET_POST_ASSIGNMENT_SUBMISSION = `/assignment`;