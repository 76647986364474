import store from "../store/index";

import {
	FETCH_PUT_POST_QUESTION_API,
	FETCH_PUT_POST_CONTENT_API,
	FETCH_PUT_POST_QUESTION_CATEGORY_API,
	FETCH_POST_PUT_EXAM_THEME_API,
	FETCH_EXAM_RESULT_PRODUCT_VARIANT,
	FETCH_EXAM_CONTENT_RESULT,
} from "../constants/urls";
import { ServiceCodes } from "../constants/serviceCodes";
import ApiHelpers from "../helpers/ApiHelpers";

export const examService = {
	fetchQuestions,
	fetchQuestionCategories,
	createQuestion,
	deleteQuestion,
	createExam,
	createLegacyExam,
	createQuiz,
	updateExam,
	createQuestionCategory,
	updateQuestionCategory,
	deleteQuestionCategory,
	fetchExamThemes,
	getExamResultByProductVariant,
	getExamContentResult,
};

async function fetchQuestions({
	productVariantID,
	categoryID,
	pageSize,
	currentPage,
}) {
	console.log(productVariantID, categoryID, pageSize, currentPage);
	store.dispatch("appState/startLoading");
	var requestQuery = `?ServiceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	if (categoryID) {
		requestQuery += `&CategoryID=${categoryID}`;
	} else if (productVariantID) {
		requestQuery += `&ProductVariantID=${productVariantID}`;
	}
	if (pageSize) {
		requestQuery += `&pageSize=${pageSize}`;
	}
	if (currentPage) {
		requestQuery += `&currentPage=${currentPage}`;
	}
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_QUESTION_API + requestQuery,
		"GET",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	console.log(response);
	return response;
}

async function fetchQuestionCategories({ productVariantID, categoryID }) {
	store.dispatch("appState/startLoading");
	var requestQuery = `?ServiceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	if (categoryID) {
		requestQuery += `&CategoryID=${categoryID}`;
	} else if (productVariantID) {
		requestQuery += `&ProductVariantID=${productVariantID}`;
	}
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL +
			FETCH_PUT_POST_QUESTION_CATEGORY_API +
			requestQuery,
		"GET",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	console.log(response);
	return response;
}

async function getExamResultByProductVariant({ productVariantID }) {
	store.dispatch("appState/startLoading");

	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL +
			FETCH_EXAM_RESULT_PRODUCT_VARIANT +`/${productVariantID}`,
		"GET",
		null,
		false
	).then(handleResponse);

	store.dispatch("appState/stopLoading");
	console.log(response);
	return response;
}


async function getExamContentResult({ examID, contentID }) {
	store.dispatch("appState/startLoading");

	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL +
			FETCH_EXAM_CONTENT_RESULT +`/${contentID}/${examID}`,
		"GET",
		null,
		false
	).then(handleResponse);

	store.dispatch("appState/stopLoading");
	console.log(response);
	return response;
}

async function createQuestion(id) {
	store.dispatch("appState/startLoading");
	var requestQuery = `?ProductVariantID=${id}&ServiceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_QUESTION_API + requestQuery,
		"POST",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function deleteQuestion(id) {
	store.dispatch("appState/startLoading");
	var requestQuery = `?ServiceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL +
			FETCH_PUT_POST_QUESTION_API +
			`/${id}` +
			requestQuery,
		"DELETE",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function createExam(examObject) {
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
		"POST",
		JSON.stringify(examObject),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function createLegacyExam(examObject) {
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
		"POST",
		JSON.stringify(examObject),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function createQuiz(obj) {
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
		"POST",
		JSON.stringify(obj),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function updateExam(examObject) {
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_CONTENT_API,
		"PUT",
		JSON.stringify(examObject),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	// console.log(response);
	return response;
}

async function createQuestionCategory(categoryObject) {
	categoryObject.serviceCode = ServiceCodes.EXAM.ExamTestingSystem;
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_QUESTION_CATEGORY_API,
		"POST",
		JSON.stringify(categoryObject),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	return response;
}

async function updateQuestionCategory(categoryObject) {
	categoryObject.serviceCode = ServiceCodes.EXAM.ExamTestingSystem;
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_PUT_POST_QUESTION_CATEGORY_API,
		"PUT",
		JSON.stringify(categoryObject),
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	return response;
}

async function deleteQuestionCategory(categoryID) {
	// categoryObject.serviceCode = ServiceCodes.EXAM.ExamTestingSystem;
	var requestQuery = `?categoryID=${categoryID}&serviceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	store.dispatch("appState/startLoading");
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL +
			FETCH_PUT_POST_QUESTION_CATEGORY_API +
			requestQuery,
		"DELETE",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	return response;
}

async function fetchExamThemes(themeID) {
	store.dispatch("appState/startLoading");
	var requestQuery = `?ServiceCode=${ServiceCodes.EXAM.ExamTestingSystem}`;
	if (themeID) {
		requestQuery += `&themeID=${themeID}`;
	}
	const response = await ApiHelpers.makeAPICall(
		store.state.appState.apiURL + FETCH_POST_PUT_EXAM_THEME_API + requestQuery,
		"GET",
		null,
		false
	).then(handleResponse);
	store.dispatch("appState/stopLoading");
	return response;
}

async function handleResponse(response) {
	console.log("handling response", response);
	if (!response.ok) {
		var res = await response
			.json()
			.then(
				(data) =>
					(res = {
						error: data.error,
					})
			)
			.then((res) => {
				// console.log(res);
				return res;
			});
		throw new Error(res.error.errorCode);
	} else {
		return response.json();
	}
}
