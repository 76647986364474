<template>
	<div id="qrcode-attendance" class="qrcode-scanner-screen">
		<img v-if="isFullScreen" :src="logoPath" class="logo top-logo" />
		<h1 class="m-0">Attendance</h1>
		<!-- </div> -->
		<h4>Please scan your QR code below to mark your attendance</h4>
		<!-- <div id="interactive"></div> -->
		<b-overlay :show="loading || loadingUsers">
			<video id="qr-code-scanner"></video>
		</b-overlay>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapActions } from "vuex";
import QrScanner from "qr-scanner";

export default {
	name: "AttendanceWithBarcode",
	data() {
		return {
			isFullScreen: false,
			scanningDisabled: false,
			lastScannedID: null,
			scannedUser: null,
			availableCameras: null,
			selectedCamera: null,
			qrScanner: null,
			loadingUsers: false,
		};
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		...mapState("content", ["loading"]),
		logoPath() {
			return this.systemParameters.LogoPath
				? this.systemParameters.LogoPath
				: require("@/assets/images/gocbe_logo.png");
		},
	},
	methods: {
		...mapActions("user", ["logout"]),
		...mapActions("content", ["markAllLiveClassAttendance"]),
		Scan() {
			Quagga.init(
				{
					inputStream: {
						name: "Live",
						type: "LiveStream",
						target: document.querySelector("#interactive"),
					},
					decoder: {
						readers: ["code_128_reader"],
					},
				},
				(err) => {
					if (err) {
						console.log(err);
						return;
					}
					console.log("Initialization finished. Ready to start");
					Quagga.start();
					document.querySelector("#interactive .drawingBuffer").hidden = true;
				}
			);
		},
		StopScan() {
			Quagga.stop();
			if (document.fullscreenElement == null) {
				this.isFullScreen = false;
				this.logout();
			}
		},
		startTimeout() {
			console.log("disabling scan");
			this.scanningDisabled = true;
			setTimeout(() => (this.scanningDisabled = false), 3000);
		},
		onDetectedCallback(result) {
			const id = result.data;
			if (this.scanningDisabled || this.lastScannedID == id) return;
			this.startTimeout();
			// this.scannedUser = this.allUsers.find((u) => u.id == id);
			// if (!this.scannedUser) {
			// 	Swal.fire({
			// 		title: "Could not mark Attendance!",
			// 		text: "Error reading QR code, please try again!",
			// 		icon: "error",
			// 		type: "error",
			// 		target: document.querySelector("#qrcode-attendance"),
			// 		showConfirmButton: false,
			// 		timer: 2000,
			// 	});
			// 	return;
			// }

			var attendanceObject = {
				userID: id,
				status: "Present",
				attendedFrom: "Physical",
				timeIn: new Date().toISOString(),
				isMarkLate: false,
			};
			this.markAllLiveClassAttendance(attendanceObject).then((res) => {
				if (res) {
					if (!res.length) {
						Swal.fire({
							title: "No Ongoing Class",
							text: "Cannot mark attendance. You do not have any ongoing classes right now",
							icon: "warning",
							type: "warning",
							target: document.querySelector("#qrcode-attendance"),
							showConfirmButton: false,
							timer: 5000,
						});
						return;
					}

					this.scannedUser=res[0].user;
					this.lastScannedID = id;
					var sound = new Howl({
						src: ["https://gocbe-lms-files.b-cdn.net/success_alert.mp3"],
					});

					sound.play();
					Swal.fire({
						title: "Attendance Marked!",
						html: `
						<div class='d-flex flex-column justify-content-center align-items-center gap-2'>

							<div class="user-avatar large">
								<img v-if="${this.scannedUser.profilePicture}" :src="${
							this.scannedUser.profilePicture
						}" />
								<span v-else>${this.scannedUser.firstName[0]}</span>
							</div>
							<p>${this.scannedUser.firstName + " " + this.scannedUser.lastName}
							<br/>
							${this.scannedUser.email}</p>
						</div>
						`,
						icon: "success",
						type: "success",
						target: document.querySelector("#qrcode-attendance"),
						showConfirmButton: false,
						timer: 3000,
					});
				}
			});
		},
	},
	mounted() {
		setTimeout(() => { 
			var elem = document.querySelector("#qrcode-attendance");
			if (elem.requestFullscreen) {
				elem.requestFullscreen().then((value) => {
					this.isFullScreen = true;
				});
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				elem.webkitRequestFullscreen().then((value) => {
					this.isFullScreen = true;
				});
			} else if (elem.msRequestFullscreen) {
				/* IE11 */
				elem.msRequestFullscreen().then((value) => {
					this.isFullScreen = true;
				});
			}
			document.addEventListener("fullscreenchange", (e) => {
				if (document.fullscreenElement == null) {
					this.isFullScreen = false;
					this.logout();
				}
			});
		}, 1000);
		var videoElem = document.getElementById("qr-code-scanner");
		// console.log(videoElem);
		this.qrScanner = new QrScanner(videoElem, this.onDetectedCallback, {
			highlightCodeOutline: true,
			highlightScanRegion: true,
		});
		console.log(this.qrScanner);
		QrScanner.listCameras(true).then((camera) => {
			this.availableCameras = camera;
			Swal.fire({
				title: "Select a camera",
				html: `
						<div class='d-flex flex-column justify-content-center align-items-center gap-2'>
							<select id="camera-select" v-model="${this.selectedCamera}">
								
							</select>
						</div>
						`,
				target: document.querySelector("#qrcode-attendance"),
				preConfirm: () => {
					var selectElem = document.getElementById("camera-select");
					this.selectedCamera = selectElem.value;
					this.qrScanner.setCamera(this.selectedCamera);
					this.qrScanner.start();
				},
			});
			var selectElem = document.getElementById("camera-select");
			camera.forEach((cam) => {
				var option = document.createElement("option");
				option.text = cam.label;
				option.value = cam.id;
				selectElem.add(option);
			});
		});

	},
	beforeDestroy() {
		var elem = document.querySelector("#qr-code-scanner");
		elem.srcObject = null;
		this.qrScanner.stop();
		this.qrScanner = null;
	},
};
</script>

<style scoped>
.qrcode-scanner-screen {
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	row-gap: 1rem;
	position: relative;
}

.qrcode-scanner-screen .logo {
	height: 56px;
}

.top-logo {
	position: absolute;
	top: 2rem;
	left: 2rem;
}

#qr-code-scanner {
	height: 50vh;
}
</style>
