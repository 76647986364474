<template>
	<div>
		<component :is="liveClassComponent" :contentData="contentData" :productVariantData="productVariantData" />
	</div>
</template>

<script>
import Zoom from "./Zoom.vue";
import ProductVariant from "../../models/productVariant";
import LiveClass from "../../models/content/liveClass";
import Vue from "vue";

export default {
	name: "LiveClass",
	props: { productVariantData: ProductVariant, contentData: LiveClass },
	computed: {
		liveClassComponent() {
			switch (this.contentData.externalService.serviceProvider) {
				case "Zoom":
					return Zoom;
			}
		},
	},
	created() {
		Vue.prototype.ZoomMtg = window.ZoomMtg;
	},
};
</script>

<style scoped>
</style>
