<template>
	<b-modal id="assignment-modal-action" @change="$emit('updateModalState', false)" :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')" :visible="visible" hide-header centered
		content-class="assignment-modal-action" size="xl" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">
			<div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
				<button @click="$emit('updateModalState', false)" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			</div>
			
		
			<h1 class="add-content-title">
				{{ this.isEditMode ? "Edit" : "Create" }} Assignment
			</h1>

			<div v-if="stepIndex === 0" class="body-content-container">
				<div class="info-container expand">

					<div class="form-group my-3">
						<label for="title" class="main-label">Assignment Title (Required)</label>
						<input placeholder="Type your assignment title" class="form-input" type="search" name="title"
							id="title" v-model="assignment.title" required>
					</div>

					<div class="form-group mt-4">
						<label for="instructions" class="main-label">Assignment Instructions (Required)</label>
							<QuillRichTextEditor
								v-if="visible && (!isEditMode || assignment.instructions)"
								:text="assignment.instructions"
								:isInline="false"
								editorID="instruction-desc"
								placeholder="Type your assignment instructions"
								v-model="assignment.instructions"
								/>
						<div style="display: flex;">
							<input type="file" style="display: none;" @change="onFilePicked" multiple ref="fileinput" />
							<a class="attach-resources-link"  @click="uploadFile">
								<i class="fa fa-file"></i>
								Add File</a>

							<a class="attach-resources-link dropdown-toggle" data-bs-toggle="dropdown" role="button">
								<!-- <i class="fa fa-plus"></i> -->
								<i class="fa fa-link"></i> Add Link</a>
							<div id="add-dropdown" class="dropdown-menu dropdown-menu-end"
								aria-labelledby="dropdownMenuLink">
								<form >
									<input type="search" class="form-control" v-model="link"
										placeholder="Enter or Paste Link" required v-on:keyup.esc="hideDropdown" />
									<button type="button" @click="addLink"  class="content-action-btn save">
										<img src="@/assets/images/check-icon-green.svg" alt="save" />
									</button>
									<button class="content-action-btn cancel" type="button"
										role="button">
										<img src="@/assets/images/cancel-icon-red.svg" alt="cancel" />
									</button>
								</form>
							</div>
						</div>
						<div class="row mt-4" v-if="files && files.length>0">

							<div class="pr-3 col-lg-3 col-md-4" v-for="file in files">
								<a :class="'card file-card '+file.status"  @click="openInNewTab(file.url)" >
									<b-progress class="progress m-0" v-if="file.status == 'uploading'" :value="file.uploadingProgress" :max="100"
										animated></b-progress>
									<label :title="file.name">
										{{ file.name }}
										<small><br />{{ file.type }}</small>
										<small v-if="file.status == 'pending'" style="color: gray;"> Waiting For Upload</small>
										<small v-if="file.status == 'failed'" > Failed ! {{file.error}}</small>
									</label>
									<div @click.stop style="display: contents;">
										<button @click.stop="removeFile(file.id)" class="downloadbtn"> <i style="margin-top: 60%;"
												class="fa fa-times"></i></button>
										</div>
								</a>
								
							</div>
						</div>
					</div>

					<!-- <div class="form-group my-3">
						<label for="passing-percentage" class="main-label">Passing Percentage (0 - 100)</label>
						<input class="form-input" type="number" name="passing-percentage" id="passing-percentage"
							v-model="assignment.passingPercentage" min="0" max="100" required>
					</div>-->
					

					<div class="form-group my-0">
						<div class="row">
							<div class="col-lg-3">
								<div class="form-group">
									<label for="marks" class="main-label m-0">Marks</label>
									<input placeholder="Assignment Without Marks" class="form-input" type="number"
										name="marks" id="marks" v-model="assignment.marks" />
								</div>
							</div>
							<div class="col-lg-3 pe-0" >
								<div class="form-group">
									<label for="passingPercentage" class="main-label m-0">Passing Percentage</label>
									<input :placeholder="assignment.marks?'Enter Passing Percentage':'No Passing Percentage'" :disabled="!assignment.marks" class="form-input" type="number"
										name="passingPercentage" max="100" id="passingPercentage" v-model="assignment.passingPercentage" />
								</div>
							</div>		
							<div class="col-lg-3">
								<div class="form-group">
									<label for="due-date" class="main-label m-0">Due Date and Time <span v-if="!assignment.dueDateTime">( Default: No Due Date )</span></label>
									<input placeholder="No Due Date" class="form-input" type="datetime-local"
										name="due-date" id="due-date" v-model="assignment.dueDateTime"/>
								</div>
							</div>		
							<div class="col-lg-3">
								<div class="form-group">
									<label for="due-date" class="main-label m-0">Allowed Submission <span v-if="!assignment.dueDateTime">( Default: 1 Submission )</span></label>
									<input placeholder="Allowed Submissions" min="1" class="form-input" type="number"
										name="allowed-sub" id="allowed-sub" v-model="assignment.allowedSubmissions"/>
								</div>
							</div>					
						</div>
					</div>

					<div class="form-group my-0">
						<div class="row">
							<div class="col-lg-6">
								<div class="form-group">
									<label for="start-date" class="main-label m-0">Start Date and Time (Required)</label>
									<input placeholder="Select start date and time" class="form-input" type="datetime-local"
										name="start-date" id="start-date" v-model="assignment.startDateTime" required/>
								</div>
							</div>
							<div class="col-lg-6 pe-0">
								<div class="form-group">
									<label for="end-date" class="main-label m-0">End Date and Time (Required)</label>
									<input placeholder="Select end date and time" class="form-input" type="datetime-local"
										name="end-date" id="end-date" v-model="assignment.endDateTime" required/>
								</div>
							</div>
							
						</div>
					</div>

					<div class="row">
						<div class="col-md-4 form-group mt-4" v-if="assignment.dueDateTime">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" role="switch" id="isLateSubmissionAllowed"
									v-model="assignment.isLateSubmissionAllowed" />
								<label class="form-check-label" for="isLateSubmissionAllowed">Late Submission Allowed</label>
							</div>
						</div>
						<div class="col-md-4 form-group mt-4">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" role="switch" id="isReSubmissionAllowed"
									v-model="assignment.isReSubmissionAllowed" />
								<label class="form-check-label" for="isReSubmissionAllowed">Resubmission Allowed</label>
							</div>
						</div>
						<div class="col-md-4 form-group mt-4">
							<div class="form-check form-switch">
								<input class="form-check-input" type="checkbox" role="switch" id="isAvailableForDemo"
									v-model="assignment.isAvailableForDemo" />
								<label class="form-check-label" for="isAvailableForDemo">Available for Demo</label>
							</div>
						</div>
					</div>
 
				</div>

				<div class="step-btn-container">
					<button :disabled="!validateState" @click="submitAssignment()" role="button" class="step-btn">
						Submit
						<img src="@/assets/images/arrow-right.svg" />
					</button>
				</div>

			</div>



		</div>
	</b-modal>
</template>

<script>

import CookieHelpers from "../../../helpers/CookieHelpers";
import Loading from "../../../components/Layout/Loading.vue";
import draggable from "vuedraggable";
import { ContentType } from "../../../constants/content";
import { parseDateToYMD } from "../../../helpers/FormatHelpers";
import { mapActions } from "vuex";
import { POST_FILE_API } from "./../../../constants/urls";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
import  QuillRichTextEditor from "./../../Editors/QuillRichTextEditor.vue"
export default {
	name: "AssignmentActionModal",
	props: {
		visible: Boolean,
		isEditMode: Boolean,
		parentContentId: String,
		contentData: Object,
	},
	data() {
		return {
			link:"",
			assignment: {
				instructions:"",
				allowedSubmissions:1
			},
			startDateTime: null,
			endDateTime: null,
			isAvailableForDemo: false,
			stepIndex: 0,
			passingPercentage: null,
			instructions: null,
			assignmentFiles: [{
				title: null,
				totalMarks: null,
				file: null
			}],
			content: null,
			quillEditor: null,
			fileSizeLimit:100,
			files:[],
			isExpanded:false
		};
	},
	methods: {
	
		...mapActions("content", ["createFlashCards", "getContentById", "updateContent",
      "createAssignmentContent"]), uploadFile() {
			this.$refs.fileinput.click();
		},
		openInNewTab(url){
			window.open(url,"_blank");
		},
		onFilePicked(event) {
			const files = event.target.files;
			files.forEach(file => {
				this.addFileForUpload(file);
			});			
		},		
		removeFile(fileId){
			var file=this.files.find(x=>x.id==fileId);
			if(file)
			{
				if (file.xhr) {
					file.xhr.abort();
				}
				this.files.splice(this.files.indexOf(file),1);
				this.uplaodNextFileInQueue();
			}

		},
		addFileForUpload(file) {

			var nameParts = file.name.split(".");
			var extension = nameParts.pop();
			var name = nameParts.join(".").toUpperCase();
			var sizeInMB = (file.size / 1024) / 1024;
			var isSizeIncreasedLimit = sizeInMB > this.fileSizeLimit;

			this.files.push({
				id: uuidv4(),
				name: name,
				file: file,
				extension: extension,
				type: this.fileTypeByExtentions(file.name) ?? this.fileTypeByType(file.type) ?? "File",
				url: null,
				status: isSizeIncreasedLimit ? 'failed' : 'pending',
				error: isSizeIncreasedLimit ? `File size should be less then ${this.fileSizeLimit}` : null,
				uploadingProgress: 0
			});
			this.uplaodNextFileInQueue();
		},
		fileTypeByExtentions(filename) {
			if (filename.endsWith(".pdf")) {
				return "PDF";
			}
			if (filename.endsWith(".ppt") || filename.endsWith(".pptx")) {
				return "Powerpoint";
			}
			if (filename.endsWith(".xls") || filename.endsWith(".xlsx")) {
				return "Excel Worksheet";
			}
			if (filename.endsWith(".csv")) {
				return "Comma Seperated Values";
			}
			if (filename.endsWith(".doc") || filename.endsWith(".docx")) {
				return "Document";
			}
		},
		fileTypeByType(fileType) {
			fileType = fileType.toLowerCase();
			if (fileType.indexOf("image") > -1) {
				return "Image";
			}
			if (fileType.indexOf("video") > -1) {
				return "Video";
			}

		},
		uplaodNextFileInQueue() {
			var self=this;
			var minUploadinglimit = 1;
			var inUploadingFiles = this.files.filter(x=>x.status.toLowerCase() == "uploading");
			var queuedFile = this.files.find(x=>x.status.toLowerCase() != "uploading" && x.status.toLowerCase() == "pending");
			if (inUploadingFiles.length <= (minUploadinglimit - 1) && queuedFile) {
				queuedFile.status = "uploading";
				queuedFile.uploadingProgress = 0;
				var uploadurl = this.apiURL + POST_FILE_API;

				const formData = new FormData();
      			formData.append("entity", "Assignment");
      			formData.append("type", queuedFile.type);
				formData.append('file', queuedFile.file);

				// post form data
				const xhr = new XMLHttpRequest()
				xhr.upload.onprogress = function (event) {
					var percentComplete = Math.floor((event.loaded / event.total) * 100);
					queuedFile.uploadingProgress = percentComplete;
					queuedFile.status = "uploaded";
					queuedFile.status = "uploading";
				};

				// Set up a handler for when the request completes
				xhr.onload = function () {
					if (xhr.status === 201) {
						setTimeout(function () {
							var response=JSON.parse(xhr.responseText);
							if(response && response.payload)
							{
								queuedFile.url=response.payload;
							}
							queuedFile.status = "uploaded";
							self.uplaodNextFileInQueue();
						}, 2000);
					} else {
						queuedFile.status = "failed";
						queuedFile.error = "Due to some error occurred";
						self.uplaodNextFileInQueue();
					}
				};

				xhr.open('POST', uploadurl,);
				queuedFile.xhr = xhr;
				xhr.setRequestHeader("AuthToken", "System123");
				xhr.setRequestHeader("Authorization", `Bearer ${CookieHelpers.getCookie("token")}`);
				xhr.send(formData);
			}
		},
		
		addLink(){
			if(this.link)
			{
				this.files.push({
				id: uuidv4(),
				name: this.link,
				type: "Link",
				url: this.link
				});
				this.link="";
			}
		},
		submitAssignment(){
			if(this.validateState)
			{
				var assignment = {					
                    id: this.assignment.id ? this.assignment.id : null, 
					title: this.assignment.title,
					data: {
						description: this.assignment.instructions,
						marks:this.assignment.marks+"",
						passingpercentage:this.assignment.passingPercentage+"",
						deadline:this.assignment.dueDateTime,
						isresubimissionallowed:this.assignment.isReSubmissionAllowed+"",
						islatesubimissionallowed:this.assignment.isLateSubmissionAllowed+"",
						allowedSubmissions:this.assignment.allowedSubmissions?(this.assignment.allowedSubmissions+''):null,
						files:JSON.stringify( this.files.map(x=>{
							return {
								Name:x.name,
								Type:x.type,
								Extension:x.extension,
								Url:x.url
							};
						}))
					},
					sequence: 1,
					isAvailableForDemo: this.assignment.isAvailableForDemo??false,
					type: "Assignment",
					parentDirectoryID: this.parentContentId,
					availableFrom: this.assignment.startDateTime,
					availableTo: this.assignment.endDateTime,
				};
				if(this.isEditMode) {
                    this.updateContent(assignment).then(x => {
                        this.$toasted.success("Assignment updated successful", {
                            theme: "outline",
                            position: "bottom-center",
                            duration: 3000,
                        });
                        this.$emit('updateModalState', false);
                    });
                    return;
                }

				this.createAssignmentContent(assignment).then(x=>{
					this.$toasted.success("Assignment created successful", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					this.$emit('updateModalState', false);
				});
			}
		},

        convertToDatetimeLocal(dateTimeString) {
            const date = new Date(dateTimeString);
            const offset = date.getTimezoneOffset();
            const localDate = new Date(date.getTime() - offset * 60 * 1000);
            return localDate.toISOString().slice(0, -8); // Format as "YYYY-MM-DDTHH:mm"
        },
        mapAssignmentFiles(files) {
            var _files = files;

            for(let i=0; i<_files.length;i++){
                _files[i].name = _files[i].Name;
                _files[i].url = _files[i].Url;
                _files[i].type = _files[i].Type;
                _files[i].extension = _files[i].Extension;
                _files[i].status = "submitted";
            }

            return _files;
        }

	},
	components: {
		Loading,
		draggable,
		QuillRichTextEditor
	},

	mounted() {
		if (this.visible) {
		}
	},
	created() {
		this.content = { ...this.contentData };
	},
	computed: {		
		...mapState("appState", ["apiURL"]),		
		validateState(){
			return this.assignment && this.assignment.title && this.assignment.instructions && this.assignment.startDateTime && this.assignment.endDateTime && (!this.assignment.marks || this.assignment.passingPercentage);
		}
	},
	watch: {
		visible: async function () {
			if (this.visible) {

				// setTimeout(this.initQuillEditor, 500);
                //this.loadEditContent()

                if(this.isEditMode) {
                    await this.getContentById({id: this.content.id}).then((res) => {
                        if(res) {
                            this.content = res;
                        }
                    });
                    var extraProperties = JSON.parse(this.content.extraProperties);

                    this.assignment = {
                        id: this.content.id,
                        title: this.content.title,
						instructions: extraProperties.Discription,
                        marks: extraProperties.Marks ? extraProperties.Marks : 0,
                        passingPercentage: extraProperties.PassingPercentage ? extraProperties.PassingPercentage : 0,
                        dueDateTime: extraProperties.Deadline ? this.convertToDatetimeLocal(extraProperties.Deadline) : null,
                        isLateSubmissionAllowed: extraProperties.IsLateSubimissionAllowed ? extraProperties.IsLateSubimissionAllowed : false,
                        isReSubmissionAllowed: extraProperties.IsReSubimissionAllowed ? extraProperties.IsReSubimissionAllowed : false,
                        isAvailableForDemo: this.content.isContentAvailableForDemo ?? false,
                        startDateTime: this.content.availableFrom ? this.convertToDatetimeLocal(this.content.availableFrom) : null,
                        endDateTime: this.content.availableTo ? this.convertToDatetimeLocal(this.content.availableTo) : null,
                        Discription: extraProperties.Discription ? extraProperties.Discription : '',
						allowedSubmissions:extraProperties.AllowedSubmissions ? extraProperties.AllowedSubmissions : 1,
                    };
                    
                    this.files = extraProperties.Files ? this.mapAssignmentFiles(extraProperties.Files) : [];
			}
			}
		},
	},
};
</script>

<style scoped>

	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}
	.body-content-container{
	flex-direction: column;
}

.body-content-container .info-container.expand
{
	width: 100% !important;
	padding-right: 0px;
}



.info-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.form-textarea {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 14px;
	line-height: 1.5;
	resize: vertical;
	transition: border-color 0.2s ease-in-out;
}

.form-textarea:focus {
	border-color: var(--color-red);
	outline: none;
	box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.info-container .form-group {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.info-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

.form-input:focus {
	border-color: var(--primary-color) !important;
}

.info-container tbody img {
	height: 50px;
	width: 50px;
}

#delete-icon {
	height: 35px;
	width: 30px;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: auto;
}

.modal-body {
	padding: 0px;
}

.attach-resources-link {
	color: var(--primary-color);
	text-decoration: none;
	margin-top: 5px;
	margin-right: 15px;
	cursor: pointer;
}

.attach-resources-link i {
	margin-right: 5px;
}

.link-box {
	width: auto;
	max-width: none;
}

.link-box input {
	margin: 10px;

}

.file-card:hover {
	background-color: whitesmoke;
}

.file-card {
	display: flex;
	padding: 5px;
	padding-top: 8px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid var(--primary-color);
	cursor: pointer;
	flex-direction: inherit;
	margin-bottom: 10px;
	text-decoration: none;
	color: black;
	overflow: hidden;
}

.file-card label {
	flex: 1;
	padding: 0px;
	margin: 0px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: small;
	font-weight: bold;
	cursor: pointer;
}
.file-card.failed
{
	background-color:rgb(var(--bs-danger-rgb)) ;
}
.file-card.failed label {
	color: white;
}

.file-card label small {
	color: var(--primary-color);
	text-transform: uppercase;
	padding: 0px;
	margin: 0px;
	cursor: pointer;
	font-weight: lighter;
}

.file-card.failed label small {
	color: white;
}


.file-card .progress {
	position: absolute;
	width: 100%;
	bottom: -2px;
	left: 0px;
	height: 7px;

}
</style>
<style>
.assignment-modal-action
{
  height: 45.375rem;
  /* width: 70vw; */
}
.assignment-modal-action .modal-body.modal-body
{
	display: flex;
    flex-direction: row;
    padding: 0 !important;
	overflow: auto;
}
.assignment-modal-action .modal-body.modal-body .body-container
{
	
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

}

#assignment-modal-action .expanded
{
	max-width: 96%;
	max-height: 96%;		
}

#assignment-modal-action .expanded .assignment-modal-action
{
	height: 90vh;	
}

</style>