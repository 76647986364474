<template>
  <b-modal :draggable="true" id="calculator-modal" @change="$emit('updateModalState', false)" :visible="visible" hide-header centered
		content-class="calculator-modal" size="sm" body-class="modal-body" hide-footer modal-class="custom-modal"
		no-close-on-backdrop>
		<div class="body-container">
      <button @click="$emit('updateModalState', false)" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
      
      <div class="calculator-display m-1 p-3 text-end lead fw-bold text-black">
       {{ prevCalcVal || "" }} {{ operators || "" }} {{ calcVal || 0 }}
      </div>

      <div class="row g-0">
        <div class="col-3 mt-3" v-for="btn in calcBtns">
          <div class="calculator-button lead text-center m-1 py-2"
            :class="{ 'cal-color-primary': ['C', '*', '/', '+', '-', '=', '%'].includes(btn) }"
            @click="action(btn)">
            {{ btn }}
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Calculator',
  props: {
    msg: String,
    visible: Boolean
  },
  data() {
    return {
      calcVal: '',
      calcBtns: ['C', '%', '=', '+', 7, 8, 9, '-', 4, 5, 6, '*', 1, 2, 3, '/', 0, '.'],
      operators: null,
      prevCalcVal: '',
    };
  },
  methods: {
    action(btn) {
      if (!isNaN(btn) || btn === '.') {
        this.calcVal += btn + '';
      }

      if (btn === 'C') {
        this.calcVal = '';
        this.prevCalcVal = '';
        this.operators = null;
      }

      if (btn === '%') {
        this.calcVal = this.calcVal / 100 + '';
      }

      if (['/', '+', '-', '*'].includes(btn)) {
        this.operators = btn;
        this.prevCalcVal = this.calcVal;
        this.calcVal = '';
      }

      if (btn === '=') {
        this.calcVal = eval(this.prevCalcVal + this.operators + this.calcVal);
        this.prevCalcVal = '';
        this.operators = null;
      }
    }
  }
};
</script>

<style scoped>

.calculator-modal {
  width: 450px;
  max-width: 15%;
  border-radius: 18px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.close-button img{
  width: 13px;
  height: 13px;
}

.calculator-display {
  margin-bottom: 10px;
  border: 0.5px solid var(--color-red);
  border-radius: 10px;
}

.calculator-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  background-color: white;
  border: 0.5px solid var(--color-red);
}

.calculator-button:hover {
  background: var(--color-red);
  color: white;
  cursor: pointer;
}


.cal-color-primary {
  background: var(--color-red);
  color: white;
}
</style>
