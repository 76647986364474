<template>
	<div class="container">
		<div class="clients-section">
			<span class="heading">TRUSTED BY COMPANIES OF ALL SIZE</span>
			<div class="client-container">
				<VueSlickCarousel v-bind="CLIENTS_CAROUSEL_SETTINGS">
					<div class="client-item">
						<div class="client-image-container">
							<img class="client-image" src="@/assets/images/escribir.png" />
						</div>
					</div>
					<div class="client-item">
						<div class="client-image-container">
							<img class="client-image" src="@/assets/images/tsa.png" />
						</div>
					</div>
					<div class="client-item">
						<div class="client-image-container">
							<img class="client-image" src="@/assets/images/pwcacademy.png" />
						</div>
					</div>
					<div class="client-item">
						<div class="client-image-container">
							<img class="client-image" src="@/assets/images/ihdinaa.png" />
						</div>
					</div>
				</VueSlickCarousel>
			</div>
		</div>
	</div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
// import { CLIENTS_CAROUSEL_SETTINGS } from "../../constants/productCarouselSettings";

const CLIENTS_CAROUSEL_SETTINGS = {
	autoplay: false,
	infinite: false,
	slidesToShow: 4,
	initialSlide: 0,
	rows: 1,
	arrows: false,
	draggable: false,
	responsive: [
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				initialSlide: 0,
				rows: 2,
				arrows: false,
				draggable: false,
				autoplay: false,
				infinite: false,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				initialSlide: 0,
				rows: 4,
				arrows: false,
				draggable: false,
				autoplay: false,
				infinite: false,
			},
		},
	],
};

export default {
	name: "HomePageClientsSection",
	data() {
		return {
			CLIENTS_CAROUSEL_SETTINGS,
		};
	},
	components: {
		VueSlickCarousel,
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.client-item {
		padding: 2rem 1rem !important;
	}
}

.clients-section {
	padding: 4rem 2.25rem 2.25rem 2.25rem;
	background-color: #f5f5f5;
	box-shadow: 0 3px 6px #a8a8a829;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 10.625rem;
	align-items: center;
}

.heading {
	background-color: var(--secondary-color);
	padding: 0.625rem 2.375rem;
	border-radius: 8px;
	border: 1px solid #707070;
	text-transform: uppercase;
	color: #ffffff;
	position: absolute;
	top: -1.375rem;
}

.client-container {
	width: 100%;
}

.client-item {
	display: flex !important;
	align-items: center;
	justify-content: center;
	background-color: #fafafa;
	box-shadow: 0 3px 6px #a8a8a829;
	border-radius: 10px;
	padding: 3.5rem 4.25rem;
	/* width: fit-content !important; */
	/* height: 10.5rem; */
	margin-bottom: 1rem;
	margin-right: 1rem;
}

.client-image-container {
	height: 2.875rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.client-image {
	max-width: 13.75rem;
	width: 100%;
}
</style>
