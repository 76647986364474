<template >
  <div  @scroll="handleScroll" class="page-content-container">
    <div class="title-container mb-4">
      <h1 class="page-title">Users Management</h1>
      <button class="add-btn ms-auto me-3" @click="toggleBulkUploadModal(true)">
        <i class="fas fa-arrow-to-top me-2"></i> Bulk Upload Users
      </button>
      <BulkUserUploadModal
          :showModal="showBulkUploadModal"
          @updateModalState="toggleBulkUploadModal"
      />
      <a
          class="add-btn dropdown-toggle"
          data-bs-toggle="dropdown"
          role="button"
      >
        <img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
        add new user
      </a>
      <ul class="dropdown-menu dropdown-menu-end">
        <li @click="showAddUserModal('Student')" class="dropdown-item">
          New Student
        </li>
        <li @click="showAddUserModal('Management')" class="dropdown-item">
          New Management User
        </li>
        <li @click="showAddUserModal('Trainer')" class="dropdown-item">
          New Trainer
        </li>
      </ul>
      <CreateNewUserModal
          :showModal="showNewUserModal"
          @updateModalState="toggleAddUser"
          :createUserType="createUserType"
          @enrollStudent="addStudentEnroll"
      />
      <ViewEditUserModal
          :user="userToViewEdit.user"
          :openTab="userToViewEdit.openTab"
          @updateModalState="toggleViewEditUser"
          :showModal="showUserDetailModal"
      />
    </div>
    <div class="filters-container">
      <div class="buttons-container">
        <div class="searchbar position-relative d-flex align-items-center">
          <input
              autocomplete="off"
              type="search"
              name="search"
              id="search"
              placeholder="Search user by id, name, email or phone number"
              v-model="searchText"
              class="search-input"
              @keyup.enter="searchData"
          />
          <a role="button" class="search-icon" @click="searchData">
            <i class="fa fa-search"></i>
          </a>
        </div>
        <!-- <download-csv
            :data="filteredUsersList"
            name="users_export.csv"
            :fields="fieldsToExport"
            :labels="csvLabels"
        > -->
        <!-- <button
            class="add-btn dropdown-toggle"
            id="exportDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            @click="exportUsersData()"
            :disabled="exportLoading"
            >
              <b-spinner v-if="exportLoading" small></b-spinner>
          <i v-if="!exportLoading" class="fas fa-file-csv me-2"></i>
          {{exportLoading?"&nbsp;Exporting CSV":"Export As CSV"}}
        </button> -->
        <a :disabled="exportLoading" class="add-btn export-btn dropdown-toggle" data-bs-toggle="dropdown" role="button">
          <b-spinner v-if="exportLoading" small></b-spinner>
          <i v-if="!exportLoading" class="fas fa-file-csv me-2"></i>
          {{exportLoading?"&nbsp;Exporting CSV":"Export As CSV"}}
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li  @click="exportUsersData()" :disabled="exportLoading" class="dropdown-item">
            Export Users
          </li>
          <li @click="exportUsersEnrollmentData()" :disabled="exportLoading" class="dropdown-item">
            Export Users Enrollments
          </li>
        </ul>
<router-link to="/management/scan/user" class="add-btn ms-auto me-3" role="button">
        <i class="fas fa-sharp fa-solid fa-qrcode me-2"></i>Scan Qr
			</router-link>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-flex mb-4 gap-3">
        <button
            @click="setType('all')"
            :class="['filter-user-type-btn', { active: userType == 'all' }]"
        >
          All
        </button>
        <button
            @click="setType('student')"
            :class="['filter-user-type-btn', { active: userType == 'student' }]"
        >
          Students
        </button>
        <button
            @click="setType('trainer')"
            :class="['filter-user-type-btn', { active: userType == 'trainer' }]"
        >
          Trainers
        </button>
        <button
            @click="setType('management')"
            :class="[
						'filter-user-type-btn',
						{ active: userType == 'management' },
					]"
        >
          Management
        </button>
      </div>
      <div class="d-flex mb-4 gap-3 ms-auto">
        <button
            @click="setStatus('all')"
            :class="['filter-user-type-btn', { active: userStatus == 'all' }]"
        >
          All
        </button>
        <button
            @click="setStatus('active')"
            :class="['filter-user-type-btn', { active: userStatus == 'active' }]"
        >
          Active
        </button>
        <button
            @click="setStatus('inactive')"
            :class="[
						'filter-user-type-btn',
						{ active: userStatus == 'inactive' },
					]"
        >
          Inactive
        </button>
      </div>
    </div>
    <div class="table-container">
      <table
          v-if="allUsers && allUsers.length > 0 && !isSearching"
          class="table table-hover table-borderless all-users-table"
      >
        <thead>
        <th style="width: 5%;" class="text-center">
          #
          <!--						<input-->
          <!--							type="checkbox"-->
          <!--							name="select_all"-->
          <!--							id="select_all"-->
          <!--							@click="toggleSelectAll()"-->
          <!--							:checked="isSelectedAll()"-->
          <!--						/>-->
        </th>
        <th style="width: 5%;">ID</th>
        <th style="width: 20%;">Name</th>
        <th style="width: 10%;">Username</th>
        <th style="width: 20%;">Email</th>
        <th style="width: 7.5%;">Role</th>
        <th style="width: 15%;">City/Country</th>
        <th style="width: 10%;">Phone Number</th>
        <th style="width: 7.5%;">Status</th>
        </thead>
        <tbody>
        <tr
            @click="showViewEditUserModal($event, user)"
            v-for="(user, i) in allUsers"
            :key="user.id"
        >
          <td class="text-center">
            {{ i + 1 }}
            <!--							<input-->
            <!--								@click="toggleUserSelect($event, user)"-->
            <!--								type="checkbox"-->
            <!--								name="select_1"-->
            <!--								:id="user.id"-->
            <!--								:checked="isChecked(user.id)"-->
            <!--							/>-->
          </td>
          <td>{{ user.id }}</td>
          <td class="user-name d-flex align-items-center" :id="`tooltip-target-${user.id }`">
            <img
                v-if="user.profilePicture"
                class="user-image"
                :src="user.profilePicture"
            />
            <div v-else class="user-avatar">
              {{ user.firstName[0] }}
            </div>
            {{ shortenedFullName(user.firstName, user.lastName) }}

            <b-tooltip :target="`tooltip-target-${user.id }`" triggers="hover">
              {{ user.firstName }} {{ user.lastName }}
            </b-tooltip>
          </td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <!-- <td>{{ dateParser(user.access_start_date) }}</td> -->
          <!-- <td>{{ dateParser( user.access_end_date) }}</td> -->
          <!-- <td>{{ user.pricing_plan }}</td> -->
          <td>{{ user.type }}</td>
          <td>{{ user.city }}, {{ countryName(user.countryCode) }}</td>
          <td>{{ user.phone ? user.phone : "-" }}</td>
          <td
              :class="`${
								user.status == UserStatus.ACTIVE
									? 'success-text'
									: 'danger-text secondary'
							}`"
          >
            {{ user.status }}
          </td>
        </tr>
        </tbody>
      </table>

      <div
          v-else-if="!allUsers && loading"
          class="d-flex align-items-center justify-content-center gap-3 p-3 font-500 m-5"
      >
        <b-spinner></b-spinner> Loading...
      </div>
      <h5 v-else-if="!loading && (!allUsers || allUsers.length<=0)" class="text-center p-4 m-0">No users found</h5>

      <div v-if="fetchingNextRows" class="text-center p-4">
        <b-spinner></b-spinner>
      </div>
      
      
    </div>
    <br/>
    <center>
      <button v-if="fetchingNextRows==false && isNextRecordAvailable==true" @click="fetchNextRows()" :class="['filter-user-type-btn']">
        Load More
      </button>
    </center>

    <!--		<b-pagination-->
    <!--			align="right"-->
    <!--			v-model="currentPage"-->
    <!--			:total-rows="rows()"-->
    <!--			:per-page="perPage"-->
    <!--		></b-pagination>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AdminHeader from "../../components/Admin/Layout/AdminHeader.vue";
import { countryCodes } from "../../constants/countryCodes";
import CreateNewUserModal from "../../components/Admin/Users/CreateNewUserModal.vue";
import ViewEditUserModal from "../../components/Admin/Users/ViewEditUserModal.vue";
import BulkUserUploadModal from "../../components/Admin/Users/BulkUserUploadModal.vue";
import { UserStatus } from "../../constants/user";

export default {
  name: "UsersManagement",
  components: {
    AdminHeader,
    CreateNewUserModal,
    ViewEditUserModal,
    BulkUserUploadModal,
  },
  data() {
    return {
      currentPage: 1,
      // rows: 0,
      perPage: 10,
      searchText: "",
      selectedUsers: [],
      showNewUserModal: false,
      showUserDetailModal: false,
      showBulkUploadModal: false,
      createUserType: "",
      // productVariantData: null,
      countryCodes,
      userToViewEdit: {
        user: null,
        openTab: 0,
      },
      fieldsToExport: [
        "id",
        "firstName",
        "lastName",
        "username",
        "email",
        "countryCode",
        "phone",
        "type",
      ],
      csvLabels: {
        id: "ID",
        firstName: "First Name",
        lastName: "Last Name",
        username: "Username",
        email: "Email",
        countryCode: "Country",
        phone: "Phone Number",
        type: "Type",
      },
      userType: "all",
      userStatus: "all",
      UserStatus,
      nextPageToken: undefined,
      pageSize: 25,
      searchString: undefined,
      fetchingNextRows: false,
      isSearching: false,
      exportLoading: false
    };
  },
  computed: {
    ...mapState("user", ["allUsers", "loading","isNextRecordAvailable"]),
    ...mapState("appState", [
			"systemParameters"
		]),
    // ...mapState("productVariant", [""]),
    offset() {
      return (this.currentPage - 1) * this.perPage;
    },
    filteredUsersList() {
      if (this.allUsers && this.allUsers.length > 0) {
        // this.searchText = this.searchText.spl
        var result = this.allUsers.filter((user) => {
          return (
              ((user.firstName + " " + user.lastName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.firstName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.lastName || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.id || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.email || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.username || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
                  (user.phone || "")
                      .toLocaleLowerCase()
                      .indexOf(this.searchText.toLocaleLowerCase()) > -1) &&
              (this.userType == "all" ||
                  user.type.toLowerCase() == this.userType.toLowerCase()) &&
              (this.userStatus == "all" ||
                  user.status.toLowerCase() == this.userStatus.toLowerCase())
          );
        });
        return result;
      }
      return [];
    }
  },
  methods: {
    ...mapActions("user", ["fetchUsersList", "fetchPaginatedUsersList","exportUsersList","exportUsersEnrollmentReport"]),
    toggleBulkUploadModal(state) {
      if (state != undefined) {
        this.showBulkUploadModal = state;
      } else this.showBulkUploadModal = !this.showBulkUploadModal;
      if (!this.showBulkUploadModal) {
        this.createUserType = "";
      }
    },
    toggleAddUser(state) {
      if (state != undefined) {
        this.showNewUserModal = state;
      } else this.showNewUserModal = !this.showNewUserModal;
      if (!this.showNewUserModal) {
        this.createUserType = "";
      }
    },
    toggleViewEditUser() {
      this.showUserDetailModal = !this.showUserDetailModal;
      if (!this.showUserDetailModal) {
        this.userToViewEdit.user = null;
        this.userToViewEdit.openTab = 0;
      }
    },
    toggleSelectAll() {
      if (
          this.allUsers &&
          this.allUsers.length > 0 &&
          this.selectedUsers.length != this.allUsers.length
      ) {
        this.selectedUsers = this.allUsers;
      } else {
        this.selectedUsers = [];
      }
    },
    isSelectedAll() {
      if (this.allUsers) {
        return this.allUsers.length == this.selectedUsers.length;
      }
    },
    isChecked(id) {
      var found = this.selectedUsers.find((user) => user.id == id);
      // console.log(found);
      if (found) {
        return true;
      } else return false;
    },
    toggleUserSelect(e, user) {
      e.stopPropagation();
      var found = this.selectedUsers.find((s) => s.id == user.id);
      console.log(found);
      if (!found) {
        this.selectedUsers.push(user);
      } else {
        this.selectedUsers = this.selectedUsers.filter(
            (su) => su.id != user.id
        );
      }
    },
    showAddUserModal(type) {
      this.createUserType = type;
      this.showNewUserModal = true;
    },
    showViewEditUserModal(e, user) {
      // e.preventDefault();
      e.stopPropagation();
      this.userToViewEdit.user = user;
      this.showUserDetailModal = true;
    },
    countryName(code) {
      var countryName = this.countryCodes.find((c) => c["alpha-3"] == code)
          ? this.countryCodes.find((c) => c["alpha-3"] == code).name
          : "-";
      // console.log(countryName);
      return countryName;
    },
    addStudentEnroll(user) {
      console.log("addstudentenroll");
      this.userToViewEdit.openTab = 1;
      this.userToViewEdit.user = user;
      this.toggleViewEditUser(user);
    },
    setType(type) {
      this.userType = type;
      this.searchData();
    },
    setStatus(status) {
      this.userStatus = status;      
      this.searchData();
    },
    rows() {
      if (this.allUsers) {
        return this.filteredUsersList.length;
      } else return 0;
    },
    fetchNextRows() {
      if (!this.fetchingNextRows && this.isNextRecordAvailable) {
        this.fetchingNextRows = true;
        this.fetchPaginatedUsersList({pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString}).then((result) => {
          this.fetchingNextRows = false;

          if(result && result.nextPageToken) {
            this.nextPageToken = result.nextPageToken;
          }
        }).catch((err) => {
          this.fetchingNextRows = false;
          console.log({err});
        });
      }
    },
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (!this.fetchingNextRows && scrollTop + clientHeight >= scrollHeight) {
          this.fetchNextRows();
      }
    },
    searchData(e) {
      this.nextPageToken = undefined;
      this.searchString = this.searchText;
      this.fetchingNextRows = true;

      this.isNextRecordAvailable=true;
      this.isSearching = true;

      this.fetchPaginatedUsersList({pageSize: this.pageSize, nextPageToken: this.nextPageToken, searchString: this.searchString, type: this.userType.toLowerCase()!="all"?this.userType:null, status: this.userStatus.toLowerCase()!="all"?this.userStatus:null}).then((result) => {
        this.fetchingNextRows = false;
        this.isSearching = false;

        if(result && result.nextPageToken) {
          this.nextPageToken = result.nextPageToken;
        }
      }).catch((err) => {
        this.fetchingNextRows = false;
        this.isSearching = false;
        console.log({err});

      });
    },
    exportUsersData() {
      this.exportLoading = true;
      this.searchString = this.searchText;

      this.exportUsersList({ searchString: this.searchString, type: this.userType.toLowerCase() != "all" ? this.userType : null, status: this.userStatus.toLowerCase() != "all" ? this.userStatus : null }).then((result) => {

        if (result) {
          const link = document.createElement('a');
          link.href = result;
          link.setAttribute('download', `${this.systemParameters && this.systemParameters.tenantName ? this.systemParameters.tenantName+"_" : ""}users_export_${Date.now()}.csv`);
          document.body.appendChild(link);
          
          this.exportLoading = false;
          link.click();
          link.parentNode.removeChild(link);
                    // clean up Url
          window.URL.revokeObjectURL(result);
        }
      }).catch((err) => {
        console.log({ err });
        
        this.exportLoading = false;
      });
    },
    exportUsersEnrollmentData() {
      this.exportLoading = true;
      this.exportUsersEnrollmentReport().then((result) => {

        if (result && result.url) {
          const link = document.createElement('a');
          link.href = result.url;
          link.setAttribute('download',result.name?result.name:`users-enrollments-export.csv`);
          document.body.appendChild(link);          
          this.exportLoading = false;
          link.click();
          link.parentNode.removeChild(link);
                    // clean up Url
          window.URL.revokeObjectURL(result);
        }
      }).catch((err) => {
        console.log({ err });
        
        this.exportLoading = false;
      });
    },
    shortenedFullName(firstname, lastname) {
      let fullName = firstname + ' ' + lastname;

      return fullName ? fullName.length > 30 ? fullName.substring(0,28) + '...' : fullName : '';
    }
  },


  
  created() {
    console.log({ps: this.pageSize, npt: this.nextPageToken, searchStr: this.searchString});
    // if (!this.allUsers) {
    this.fetchPaginatedUsersList( {pageSize: this.pageSize, nextPageToken: this.nextPageToken}).then((result) => {
      // this.rows = this.allUsers.length;

      console.log('result from action');
      console.log(result);

      if(result && result.nextPageToken) {
        this.nextPageToken = result.nextPageToken;
      }
    });
    // } else {
    // this.rows = this.allUsers.length;
    // }
  },
  watch: {
    isNextRecordAvailable:function(){
        console.log("dist");
    },
    allUsers: function () {
      console.log("users updated");
      if (this.userToViewEdit.user) {
        console.log("user to view", this.userToViewEdit.user);
        this.userToViewEdit.user = this.allUsers.find(
            (user) => user.id == this.userToViewEdit.user.id
        );
        // this.rows = this.allUsers.length;
        // console.log(
        // 	"user after update",
        // 	this.allUsers[this.userToViewEdit.user.id]
        // );
      }
    },
  },
};
</script>

<style scoped>
.page-content-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
}

.page-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
  display: inline-block;
  /* margin: 0; */
}

.add-btn {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease;
}

.add-btn:hover {
  box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-icon {
  margin-right: 0.625rem;
  width: 1rem;
  height: 1rem;
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
}

.all-users-table thead,
.all-users-table td {
  height: 4rem;
  vertical-align: middle;
}

.all-users-table td:first-of-type {
  padding-left: 0;
}

.all-users-table input[type="checkbox"] {
  margin-left: 1.5rem;
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 1.25rem;
  height: 1.25rem;
}

.filters-container {
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
  background-color: #ffffff;
  margin-bottom: 1.5rem;
}

.filters-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.filter-inputs {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.search-input {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  width: 100%;
  text-align: left;
  /* margin-bottom: 1rem; */
  /* font-weight: 500; */
  color: #000000;
  font-size: 1rem;
  column-gap: 0.75rem;
  position: relative;
  outline: none;

  /* transition: transform 0.3s; */
}

.search-button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0.5rem 2rem;
  filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
  text-decoration: none;
  color: #000000;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.buttons-container .searchbar {
  flex-grow: 1;
}

.search-icon {
  font-size: 18px;
  position: absolute;
  margin-right: 1rem;
  right: 0;
  color: #000000;
}

.export-btn {
  padding: 0.5rem 1rem;
}

.dropdown-item {
  cursor: pointer;
}

.user-avatar {
  width: 2rem;
  height: 2rem;
  color: var(--color-white);
  background-color: #231f20;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 1.2rem;
  min-width: 2rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  /* z-index: 100; */
}

.user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  object-position: top;
  margin-right: 0.5rem;
}

.user-name {
  cursor: pointer;
}

.filter-user-type-btn {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.filter-user-type-btn.active {
  border: 1px solid var(--primary-color);
}

td.success-text {
  color: var(--color-success);
}

td.danger-text {
  color: var(--color-danger);
}

/*table > thead > th:first-child, table > tbody > tr > td:first-child {*/
/*  margin-left: 1.5rem!important;*/
/*}*/
</style>
