export const PRODUCT_CAROUSEL_SETTINGS = {
	arrows: false,
	autoplay: true,
	infinite: true,
	draggable: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	lazyLoad: "ondemand",
	responsive: [
		{
			breakpoint: 1920,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1600,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1366,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1.5,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// rtl: true,
				centerMode: true,
			},
		},
	],
};

export const USER_PRODUCTS_CAROUSEL_SETTINGS = {
	arrows: false,
	autoplay: false,
	infinite: true,
	draggable: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	lazyLoad: "ondemand",
	responsive: [
		{
			breakpoint: 1920,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1600,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1366,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 1.5,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				// rtl: true,
				centerMode: true,
			},
		},
	],
};

export const INSTRUCTOR_CAROUSEL_SETTINGS = {
	arrows: false,
	autoplay: true,
	infinite: true,
	draggable: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	initialSlide: 0,
	rows: 1,
	lazyLoad: "ondemand",
	responsive: [
		{
			breakpoint: 1920,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1600,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		// {
		// 	breakpoint: 1024,
		// 	settings: {
		// 		slidesToShow: 3,
		// 		slidesToScroll: 1,
		// 	},
		// },
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
			},
		},
	],
};

export const UPDATES_CAROUSEL_SETTINGS = {
	autoplay: true,
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	initialSlide: 0,
	arrows: false,
};
