<template>
	<ul
		class="product-dropdown-container dropdown-menu"
		aria-labelledby="coursesMenu"
	>
		<div id="product-dropdown" class="product-dropdown" @click="handleClick">
			<div
				v-if="!isMobile || (isMobile && !activeCategory)"
				class="col-lg-2 col-md-3 col-sm-12 left-panel"
			>
				<ProductDropdownLeftPanel
					:categories="categories"
					:loading="loading"
					:activeCategory="activeCategory"
					@categoryClick="categoryClick"
				/>
			</div>
			<div
				v-if="
					!isMobile || (isMobile && activeCategory && activeCategory.length > 0)
				"
				class="col-lg-10 col-md-12 col-sm-12 right-panel"
			>
				<ProductDropdownRightPanel
					:categoryData="categoryData"
					:activeCategory="activeCategory"
					@resetCategory="categoryClick(null)"
				/>
			</div>
		</div>
	</ul>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductDropdownLeftPanel from "./ProductDropDownLeftPanel.vue";
import ProductDropdownRightPanel from "./ProductDropdownRightPanel.vue";

export default {
	name: "ProductDropdown",
	data() {
		return {
			activeCategory: null,
		};
	},
	components: { ProductDropdownLeftPanel, ProductDropdownRightPanel },
	props: { showModal: Boolean },
	computed: {
		...mapState("appState", ["isMobile"]),
		...mapState("category", ["categories", "loading"]),
		visible() {
			return this.showModal;
		},
		categoryData() {
			if (this.categories) {
				var found = this.categories.find((category) => {
					return category.id == this.activeCategory;
				});
				if (found && found.childCategories && found.childCategories.length) {
					return found.childCategories;
				} else return [];
			}

			// console.log(
			// 	"data loaded",
			// 	this.categories.length > 0 && this.activeCategory != ""
			// );
		},
	},
	methods: {
		...mapActions("category", ["fetchCategories"]),
		handleClick(e) {
			e.stopPropagation();
			// var container = document.getElementById("product-dropdown");
			// if (!container.contains(e.target)) {
			// 	// this.$emit("close-dropdown");
			// 	$("#coursesMenu").click();
			// }
		},
		categoryClick(id) {
			this.activeCategory = id;
		},
	},
	watch: {
		showModal: function () {
			if (this.showModal) {
				if (this.categories && this.categories.length == 0) {
					this.fetchCategories().then(() => {
						console.log("active category:", this.activeCategory);
						if (this.categories && this.categories.length > 0) {
							if (!this.isMobile) this.activeCategory = this.categories[0].id;
						}
					});
				}
			}
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.product-dropdown {
		/* height: calc(100vh - var(--header-height)) !important; */
		width: 100vw !important;
	}
}

@media screen and (max-width: 1280px) {
	.product-dropdown {
		width: 100vw !important;
	}
}

.product-dropdown-container {
	width: 100vw;
	position: fixed;
	top: var(--header-height);
	top: 0.5rem !important;
	z-index: 1000;
	height: 100vh;
	background-color: #000000bb;
	padding: 0;
}

.product-dropdown {
	background: #ffffff;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: auto;
	width: 85vw;
	height: 80vh;
	padding: 0;
	z-index: 1001;
}

.product-dropdown .left-panel {
	padding: 1.75rem 1.125rem;
	height: 100%;
}

.product-dropdown .right-panel {
	padding: 1.75rem 2rem;
	background-color: #f0f0f0;
	height: 100%;
	overflow: auto;
}

.product-dropdown-container.dropdown-menu {
	background-clip: none !important;
	border: none !important;
	border-radius: 0 !important;
	top: 1rem !important;
}
</style>
