<template>
	<b-modal
		id="add-banner-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="add-banner-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-banner-title">{{ isEdit ? "Edit" : "Add New" }} Banner</h1>
			<div class="body-content-container">
				<div
					:class="[
						'info-container',
						{ expand: isFileAdded, link: (isLinkUpload && !fileUrl) || isHTML },
					]"
				>
					<div class="form-group">
						<label class="main-label" for="type">Type (required)</label>
						<v-select
							class="form-input"
							placeholder="Select type"
							v-model="type"
							name="type"
							id="type"
							:options="filteredBannerTypes"
							label="name"
							:reduce="(type) => type.value"
							:clearable="true"
							:deselectFromDropdown="true"
							:appendToBody="true"
							:autoscroll="true"
							:disabled="
								(isFileAdded && !isLinkUpload) ||
								(isLinkUpload && isEdit) ||
								(isLinkUpload && fileUrl != null)
							"
							:calculate-position="withPopper"
						>
						</v-select>
					</div>

					<div class="inline-group" v-if="isLinkUpload && type">
						<div
							v-if="isLinkUpload"
							:class="[
								'form-group mt-5',
								{ 'col-12': !mobileBanners, 'col-6': mobileBanners },
							]"
						>
							<label class="main-label" for="fileUrl"
								>Video/Image Link (required)</label
							>
							<input
								placeholder="Enter link of file to upload ..."
								class="form-input"
								type="search"
								name="fileUrl"
								id="fileUrl"
								v-model="fileUrl"
								required
								@change="isEdit && update()"
							/>
						</div>
						<div
							v-if="isLinkUpload && mobileBanners"
							class="form-group mt-5 col-6"
						>
							<label class="main-label" for="mobileFileUrl"
								>Mobile Video/Image Link (required)</label
							>
							<input
								placeholder="Enter link of file to upload ..."
								class="form-input"
								type="search"
								name="mobileFileUrl"
								id="mobileFileUrl"
								v-model="mobileFileUrl"
								required
								@change="isEdit && update()"
							/>
						</div>
					</div>
					<div v-if="isHTML" class="inline-group">
						<div
							v-if="isHTML"
							:class="[
								'form-group mt-5',
								{ 'col-12': !mobileBanners, 'col-6': mobileBanners },
							]"
						>
							<label class="main-label" for="htmlCode"
								>HTML Code (required)</label
							>
							<textarea
								placeholder="Enter HTML code of banner to upload"
								class="form-input"
								type="search"
								name="htmlCode"
								id="htmlCode"
								v-model="htmlCode"
								required
								@change="isEdit && update()"
							/>
						</div>
						<div v-if="isHTML && mobileBanners" class="form-group mt-5 col-6">
							<label class="main-label" for="mobileHtmlCode"
								>Mobile HTML Code (required)</label
							>
							<textarea
								placeholder="Enter HTML code of banner to upload"
								class="form-input"
								type="search"
								name="mobileHtmlCode"
								id="mobileHtmlCode"
								v-model="mobileHtmlCode"
								required
								@change="isEdit && update()"
							/>
						</div>
					</div>
					<div class="inline-group">
						<div class="form-group mt-5 col-6">
							<label class="main-label" for="caption">Caption (optional)</label>
							<input
								placeholder="Enter Caption"
								class="form-input"
								type="search"
								name="caption"
								id="caption"
								v-model="caption"
								required
								@change="isEdit && update()"
							/>
						</div>
						<div class="form-group mt-5 col-6">
							<label class="main-label" for="order">Order (optional)</label>
							<input
								placeholder="Enter the position at which you wish to display this banner"
								class="form-input"
								type="search"
								name="order"
								id="order"
								v-model="order"
								required
								@change="isEdit && update()"
							/>
						</div>
					</div>
					<div
						class="form-check form-switch mt-3"
						v-if="type != 'youtube' && type != 'vimeo' && type != 'video'"
					>
						<input
							class="form-check-input"
							type="checkbox"
							role="switch"
							id="mobileBanners"
							v-model="mobileBanners"
							@change="isEdit && update()"
						/>
						<label class="form-check-label" for="mobileBanners"
							>Separate banner for mobile</label
						>
					</div>
				</div>
				<!-- <div
					:class="[
						'upload-container max-height',
						{ shrink: isFileAdded, 'd-none': isLinkUpload || isHTML },
					]"
				> -->
				<div
					:class="[
						'upload-container max-height',
						{
							shrink: isFileAdded,
							'd-none': (isLinkUpload && !fileUrl) || isHTML,
						},
					]"
				>
					<label
						v-if="isFileAdded && fileUrl"
						class="main-label"
						for="dropzone"
						>{{
							type != "vimeo" && type != "youtube" && type != "video"
								? "Desktop banner"
								: "Preview"
						}}</label
					>
					<vue-dropzone
						v-if="!fileUrl && fileProgress < 100 && !isLinkUpload"
						ref="desktop-banner"
						id="dropzone"
						:options="dropzoneOptions"
						:useCustomSlot="true"
						v-on:vdropzone-file-added="uploadBanner"
						v-on:vdropzone-total-upload-progress="updateProgress"
					>
						<div class="dropzone-custom-content">
							<h3 class="dropzone-custom-title">
								Drag and drop to upload banner!
							</h3>
							<div class="subtitle">
								...or click to select a file from your computer
							</div>
							<button role="button" class="upload-btn mt-4">
								<i class="fas fa-file-import"></i>
								Select File
							</button>
							<button
								v-if="!isFileAdded"
								@click="uploadWithLink"
								role="button"
								class="upload-btn ms-3 mt-4"
							>
								<i class="fas fa-link"></i>
								Upload Using Link
							</button>
							<button
								v-if="!isFileAdded"
								@click="uploadHTML"
								role="button"
								class="upload-btn ms-3 mt-4"
							>
								<i class="fas fa-code"></i>
								Upload HTML Banner
							</button>
						</div>
					</vue-dropzone>
					<div v-if="fileUrl" class="file-preview">
						<img v-if="type == 'image'" :src="fileUrl" class="preview-img" />
						<video
							controls
							v-if="type == 'video'"
							:src="fileUrl"
							class="preview-img"
						></video>
						<iframe
							v-if="type == 'vimeo' || type == 'youtube'"
							class="preview-img"
							:src="fileUrl"
							frameborder="0"
							allow="autoplay;"
							autoplay
						></iframe>
						<div class="right-menu">
							<a
								v-if="type == 'image'"
								role="button"
								class="right-menu-icon"
								@click="openPreview(fileUrl)"
							>
								<img src="@/assets/images/view-icon.svg" />
							</a>
							<a
								v-if="!isLinkUpload"
								role="button"
								class="right-menu-icon"
								@click="editBannerMedia('fileUrl')"
							>
								<img src="@/assets/images/edit-icon.svg" />
							</a>
							<a
								v-if="!isLinkUpload"
								@click="removeFile('fileUrl')"
								role="button"
								class="right-menu-icon delete"
							>
								<img src="@/assets/images/delete-icon.svg" />
							</a>
							<input type="file" hidden id="fileUrl" @input="updateMedia" />
						</div>
					</div>
					<label
						v-if="mobileBanners && mobileFileUrl"
						class="main-label mt-4"
						for="dropzone"
						>Mobile banner</label
					>
					<vue-dropzone
						v-if="
							mobileBanners &&
							!mobileFileUrl &&
							!isHTML &&
							(type == 'video' || type == 'image')
						"
						ref="mobile-banner"
						id="dropzone"
						:options="dropzoneOptions"
						:useCustomSlot="true"
						v-on:vdropzone-file-added="uploadMobileBanner"
						v-on:vdropzone-total-upload-progress="updateProgress"
					>
						<div class="dropzone-custom-content">
							<h3 class="dropzone-custom-title">
								Drag and drop to upload banner!
							</h3>
							<div class="subtitle">
								...or click to select a file from your computer
							</div>
							<button role="button" class="upload-btn mt-4">
								<i class="fas fa-file-import"></i>
								Select File
							</button>
							<button
								v-if="!isFileAdded"
								@click="uploadWithLink"
								role="button"
								class="upload-btn ms-3 mt-4"
							>
								<i class="fas fa-link"></i>
								Upload Using Link
							</button>
						</div>
					</vue-dropzone>
					<div v-else-if="mobileBanners && mobileFileUrl" class="file-preview">
						<img
							v-if="type == 'image'"
							:src="mobileFileUrl"
							class="preview-img"
						/>
						<video
							controls
							v-if="type == 'video'"
							:src="mobileFileUrl"
							class="preview-img"
						></video>
						<iframe
							v-if="type == 'vimeo' || type == 'youtube'"
							class="preview-img"
							:src="mobileFileUrl"
							frameborder="0"
							allow="autoplay;"
							autoplay
						></iframe>
						<div class="right-menu">
							<a
								v-if="type == 'image'"
								role="button"
								class="right-menu-icon"
								@click="openPreview(mobileFileUrl)"
							>
								<img src="@/assets/images/view-icon.svg" />
							</a>
							<a
								v-if="!isLinkUpload"
								role="button"
								class="right-menu-icon"
								@click="editBannerMedia('mobileFileUrl')"
							>
								<img src="@/assets/images/edit-icon.svg" />
							</a>
							<a
								v-if="!isLinkUpload"
								@click="removeFile('mobileFileUrl')"
								role="button"
								class="right-menu-icon delete"
							>
								<img src="@/assets/images/delete-icon.svg" />
							</a>
							<input
								type="file"
								hidden
								id="mobileFileUrl"
								@input="updateMedia"
							/>
						</div>
					</div>
					<!-- </keep-alive> -->
				</div>
				<p class="text-danger">{{ errorText }}</p>
			</div>
			<p v-if="!allOk" class="text-danger">{{ dateErrorText }}</p>
			<div v-if="isFileAdded" class="step-btn-container">
				<button
					@click="upload"
					role="button"
					class="step-btn"
					:disabled="
						disableNext() ||
						(!isLinkUpload &&
							!isBannerUploaded &&
							!isMobileBannerUploaded &&
							!isHTML) ||
						contentLoading ||
						(isEdit && !isChanged) ||
						loading
					"
				>
					<b-spinner small v-if="contentLoading || loading"></b-spinner>
					{{ isEdit ? "Update" : "Upload" }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
			<vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible="showPreview"
				:imgs="previewItem"
				@hide="handleHide"
			></vue-easy-lightbox>
		</div>
	</b-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { mapActions, mapState } from "vuex";
import VueEasyLightbox from "vue-easy-lightbox";
import { v4 as uuidv4 } from "uuid";
import { createPopper } from "@popperjs/core";
import { getCookie } from "../../../../helpers/CookieHelpers";

export default {
	name: "AddEditBannerModal",
	data() {
		return {
			bannerTypes: [
				{
					name: "Image",
					value: "image",
				},
				{
					name: "Youtube Video",
					value: "youtube",
				},
				{
					name: "Vimeo Video",
					value: "vimeo",
				},
				{
					name: "HTML banner",
					value: "html",
				},
				{
					name: "Video File (mp4)",
					value: "video",
				},
			],
			dropzoneOptions: {
				url: " ",
				thumbnailWidth: 300,
				timeout: 100000000,
				// maxFilesize: 40,
				// addRemoveLinks: true,
				uploadMultiple: false,
				maxFiles: 1,
				method: "PUT",
				autoProcessQueue: false,
				acceptedFiles: "image/*, video/mp4",
				headers: {
					AuthToken: "System123",
					Authorization: `Bearer ${getCookie("token")}`,
				},
			},
			type: null,
			fileName: null,
			isFileAdded: false,
			isError: false,
			errorText: "",
			fileUrl: null,
			isLinkUpload: false,
			id: null,
			allOk: true,
			contentLoading: false,
			fileProgress: 0,
			caption: null,
			mobileBanners: false,
			mobileFileUrl: null,
			isHTML: false,
			htmlCode: null,
			mobileHtmlCode: null,
			order: null,
			showPreview: false,
			previewItem: "",
			isBannerUploaded: false,
			isMobileBannerUploaded: false,
			isChanged: false,
		};
	},
	props: {
		showModal: Boolean,
		bannersList: Array,
		isEdit: Boolean,
		bannerData: Object,
	},
	methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("appState", ["editSystemParameters"]),
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		uploadWithLink(e) {
			e.stopPropagation();
			this.isLinkUpload = true;
			this.isFileAdded = true;
		},
		uploadBanner(file) {
			this.isFileAdded = true;
			console.log("file uploaded", file);
			this.type = file.type.split("/")[0];

			// const [file] = e.target.files;
			if (file) {
				this.fileUrl = URL.createObjectURL(file);
				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "Banner");
				formData.append("IsCompressionRequired", true);
				formData.append(
					"type",
					this.type == "image" ? "Image" : this.type == "video" ? "Video" : ""
				);

				// let formData = formElem.serialize();
				// formData.append("file", file);
				console.log(...formData);
				this.contentLoading = true;
				this.uploadContentFile(formData).then((data) => {
					this.fileUrl = data;
					this.contentLoading = false;
					$(".upload-btn-wrapper").addClass("success");
					this.isBannerUploaded = true;
					this.isChanged = true;
					// if (this.isEdit) {
					// 	this.updateObject.fileUrl = this.fileUrl;
					// 	this.updateObject.type = this.type;
					// }
				});
			}
		},
		uploadMobileBanner(file) {
			this.isFileAdded = true;
			console.log("file uploaded", file);
			this.type = file.type.split("/")[0];

			// const [file] = e.target.files;
			if (file) {
				this.mobileFileUrl = URL.createObjectURL(file);
				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "Banner");				
				formData.append("IsCompressionRequired", true);
				formData.append(
					"type",
					this.type == "image" ? "Image" : this.type == "video" ? "Video" : ""
				);

				// let formData = formElem.serialize();
				// formData.append("file", file);
				console.log(...formData);

				this.contentLoading = true;
				this.uploadContentFile(formData).then((data) => {
					this.mobileFileUrl = data;
					this.contentLoading = false;
					// $(".upload-btn-wrapper").addClass("success");
					this.isMobileBannerUploaded = true;
					this.isChanged = true;
				});
			}
		},
		uploadHTML(e) {
			e.stopPropagation();
			this.isHTML = true;
			this.isFileAdded = true;
			this.type = "html";
		},
		removeFile(id) {
			// if (id == 'fileUrl')
			// 	this.fileUrl = null;
			this.$data[id] = null;
		},
		disableNext() {
			if (!this.isFileAdded && !this.type && !this.fileUrl) {
				console.log("File is not added");
				return true;
			} else if (this.isHTML && !this.htmlCode) {
				return true;
			} else {
				console.log("File is added");
				if (this.mobileBanners && !this.mobileFileUrl) {
					return true;
				}
				return false;
			}
		},
		updateMedia(e) {
			const file = e.target.files[0];
			if (e.target.id == "fileUrl") this.uploadBanner(file);
			else if (e.target.id == "mobileFileUrl") this.uploadMobileBanner(file);
			console.log(file);
		},
		editBannerMedia(id) {
			console.log(id);
			document.getElementById(id).click();
		},
		upload() {
			if (
				((this.isBannerUploaded || this.isMobileBannerUploaded) &&
					!this.isLinkUpload) ||
				(this.isLinkUpload && this.fileUrl) ||
				(this.isHTML && this.htmlCode)
			) {
				var bannerObj = {
					order: this.order ? parseInt(this.order) : null,
					caption: this.caption,
					type:
						this.type == "youtube" || this.type == "vimeo"
							? "video"
							: this.type,
					isLink: this.isLinkUpload,
				};
				if (this.type.toLowerCase() == "image") {
					bannerObj.sources = [];
					if (this.fileUrl) {
						bannerObj.sources.push({
							screen_size: "lg",
							data: this.fileUrl,
						});
					}
					if (this.mobileFileUrl) {
						bannerObj.sources.push({
							screen_size: "xs",
							data: this.mobileFileUrl,
						});
					}
				} else if (this.type == "html") {
					bannerObj.sources = [];
					if (this.htmlCode) {
						bannerObj.sources.push({
							screen_size: "lg",
							data: this.htmlCode,
						});
					}
					if (this.mobileHtmlCode) {
						bannerObj.sources.push({
							screen_size: "xs",
							data: this.mobileHtmlCode,
						});
					}
				} else {
					bannerObj.source = {
						provider: this.type.toLowerCase() == "video" ? "mp4" : this.type,
						url: this.fileUrl,
					};
				}
				if (this.isEdit) {
					bannerObj.id = this.bannerData.id;
					this.$emit("editBanner", bannerObj);
				} else {
					bannerObj.id = uuidv4();
					this.$emit("addBanner", bannerObj);
				}
			}
		},
		handleHide() {
			this.showPreview = false;
		},
		openPreview(url) {
			if (this.type.toLowerCase() == "image") {
				this.previewItem = url;
				this.showPreview = true;
			}
		},
		updateProgress(totaluploadprogress, totalBytes, totalBytesSent) {
			this.fileProgress = totaluploadprogress;
		},
		update() {
			this.isChanged = true;
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
	},
	computed: {
		...mapState("appState", ["loading"]),
		visible() {
			return this.showModal;
		},
		filteredBannerTypes() {
			if (this.isEdit) return this.bannerTypes;
			else
				return this.bannerTypes.filter((type) => {
					if (this.isLinkUpload && !this.isHTML && type.value != "html") {
						return type;
					} else if (
						!this.isLinkUpload &&
						!this.isHTML &&
						(type.value == "image" || type.value == "video")
					) {
						return type;
					} else if (
						!this.isLinkUpload &&
						this.isHTML &&
						type.value == "html"
					) {
						return type;
					}
				});
		},
	},
	watch: {
		visible: function () {
			if (this.visible && this.isEdit) {
				this.isFileAdded = true;
				this.caption = this.bannerData.caption;
				this.order = this.bannerData.order;
				this.isLinkUpload = this.bannerData.isLink;
				if (
					this.bannerData.type == "video" &&
					this.bannerData.source.provider != "mp4"
				) {
					this.isLinkUpload = true;
					this.fileUrl = this.bannerData.source.url;
					this.type = this.bannerData.source.provider;
				} else if (this.bannerData.type == "video") {
					this.fileUrl = this.bannerData.source.url;
					this.type = "video";
				} else if (this.bannerData.type == "html") {
					this.type = this.bannerData.type;
					this.isHTML = true;
					this.htmlCode = this.bannerData.sources[0].data;
					if (this.htmlCode) this.isBannerUploaded = true;
					this.mobileHtmlCode =
						this.bannerData.sources.length > 1
							? this.bannerData.sources[1].data
							: null;
					if (this.mobileFileUrl) {
						this.mobileBanners = true;
						this.isMobileBannerUploaded = true;
					}
				} else {
					this.type = this.bannerData.type;
					this.fileUrl = this.bannerData.sources[0].data;
					if (this.fileUrl) this.isBannerUploaded = true;
					this.mobileFileUrl =
						this.bannerData.sources.length > 1
							? this.bannerData.sources[1].data
							: null;
					if (this.mobileFileUrl) {
						this.mobileBanners = true;
						this.isMobileBannerUploaded = true;
					}
				}
			}
		},
		type: function () {
			// if (this.type == "vimeo" || this.type == "youtube") {
			// 	this.isLinkUpload = true;
			// } else {
			// 	this.isLinkUpload = false;
			// }
		},
		mobileBanners: function () {
			if (this.mobileBanners == false) {
				this.isMobileBannerUploaded = false;
				this.mobileFileUrl = null;
			}
		},
	},
	components: {
		vueDropzone: vue2Dropzone,
		VueEasyLightbox,
	},
};
</script>

<style>
.add-banner-modal {
	min-height: 40rem;
	width: 70vw;
}

.add-banner-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.add-banner-modal .modal-body .body-container {
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.add-banner-modal .add-banner-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}

.add-edit-banner-container {
	display: flex;
	flex-direction: column;
}

.add-banner-modal .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

.add-edit-banner-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.5rem 0;
	/* max-width: 21.625rem; */
	/* width: 100%; */
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	background-color: transparent;
}

.add-banner-modal .upload-container.shrink #dropzone {
	height: fit-content !important;
}

.add-banner-modal .upload-container.shrink.max-height {
	height: unset !important;
	margin-bottom: 1rem;
}

.file-preview {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: unset;
	overflow: hidden;
	max-height: 34.375rem;
	min-height: 10rem;
	border: 2px solid #dddddd;
	cursor: pointer;
}

.file-preview .preview-img {
	width: 100%;
}

.file-preview .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.file-preview:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.file-preview .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.file-preview .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.file-preview .right-menu-icon.delete {
	border-color: #ff0000;
}

.file-preview .right-menu-icon img {
	max-height: 1rem;
}
</style>
