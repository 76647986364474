<template>
	<div class="variant" :title="titleText" v-b-tooltip:hover>
		<input
			type="checkbox"
			name="variant"
			:id="`variant${item.id}`"
			@change="checkboxToggle(item)"
			:checked="isChecked"
			class="mt-1"
			:disabled="isDisabled || isCourseEnded"
		/>
		<label
			class="d-flex flex-grow-1 flex-column cursor-pointer"
			:for="`variant${item.id}`"
		>
			<div class="d-flex flex-grow-1">
				<span class="col-1 text-center">{{ item.id }}</span>
				<span class="flex-grow-1">
					<span class="text-bold">
						{{ item.product && item.product.title }} ({{ item.title }})
					</span>
					<div v-if="isChecked && isCustomEnrollment" class="d-flex mt-2 gap-2">
						<strong>Enrollment Type:</strong>
						<input
							:disabled="item.pricingStatus == ProductVariantPricingStatus.FREE"
							type="radio"
							:id="`pricing-plan-${item.id}`"
							:value="ProductVariantEnrollmentType.PRICING_PLAN"
							:name="`enrollmentType-${item.id}`"
							v-model="variantEnrollmentType"
						/>
						<label
							:title="
								item.pricingStatus == ProductVariantPricingStatus.FREE
									? 'Please change course pricing status to Paid to enroll with pricing plan'
									: ''
							"
							:for="`pricing-plan-${item.id}`"
							v-b-tooltip.hover
						>
							Pricing Plan
						</label>
						<input
							type="radio"
							:id="`direct-enroll-${item.id}`"
							:value="ProductVariantEnrollmentType.DIRECT_ENROLLMENT"
							:name="`enrollmentType-${item.id}`"
							v-model="variantEnrollmentType"
						/>
						<label :for="`direct-enroll-${item.id}`"> Direct Enrollment </label>
					</div>
				</span>
			</div>
		</label>
	</div>
</template>

<script>
import { ProductVariantEnrollmentType } from "../../../constants/productVariant";
import { ProductVariantPricingStatus } from "../../../constants/pricing";

export default {
	name: "EnrollmentCourseListItem",
	data() {
		return {
			ProductVariantEnrollmentType,
			ProductVariantPricingStatus,
			variantEnrollmentType: null,
		};
	},
	props: {
		item: Object,
		enrollmentType: String,
		selectedVariants: Array,
		directEnrollVariants: Array,
	},
	methods: {
		checkboxToggle() {
			if (this.variantEnrollmentType) {
				this.variantEnrollmentType = null;
				return;
			}
			if (!this.isCustomEnrollment) {
				this.variantEnrollmentType = this.enrollmentType;
				return;
			}
			this.variantEnrollmentType =
				this.item.pricingStatus == ProductVariantPricingStatus.PAID
					? ProductVariantEnrollmentType.PRICING_PLAN
					: ProductVariantEnrollmentType.DIRECT_ENROLLMENT;
		},
	},
	computed: {
		isChecked() {
			return this.variantEnrollmentType != null;
		},
		isCourseEnded() {
			return this.item.endDate && new Date(this.item.endDate) < new Date();
		},
		isDisabled() {
			return (
				this.enrollmentType == ProductVariantEnrollmentType.PRICING_PLAN &&
				this.item.pricingStatus == ProductVariantPricingStatus.FREE
			);
		},
		isCustomEnrollment() {
			return this.enrollmentType == "Custom";
		},
		titleText() {
			if (this.isDisabled) {
				return "You cannot enroll with pricing plan in a free course.";
			}
			if (this.isCourseEnded) {
				return "You cannot enroll course that has ended.";
			}
			return "";
		},
	},
	watch: {
		enrollmentType: function () {
			if (!this.isCustomEnrollment && this.variantEnrollmentType) {
				if (this.isDisabled) {
					this.variantEnrollmentType = null;
					return;
				}
				this.variantEnrollmentType = this.enrollmentType;
			}
		},
		variantEnrollmentType: function () {
			this.$emit(
				"updateEnrollmentLists",
				this.item,
				this.variantEnrollmentType
			);
		},
		directEnrollVariants: function () {
			var found;
			found = this.directEnrollVariants.find((dv) => dv.id == this.item.id);
			if (!found) {
				found = this.selectedVariants.find((sv) => sv.id == this.item.id);
				if (!found) {
					this.variantEnrollmentType = null;
					return;
				}
				this.variantEnrollmentType = ProductVariantEnrollmentType.PRICING_PLAN;
				return;
			}
			this.variantEnrollmentType =
				ProductVariantEnrollmentType.DIRECT_ENROLLMENT;
		},
		selectedVariants: function () {
			var found;
			found = this.selectedVariants.find((dv) => dv.id == this.item.id);
			if (!found) {
				found = this.directEnrollVariants.find((sv) => sv.id == this.item.id);
				if (!found) {
					this.variantEnrollmentType = null;
					return;
				}
				this.variantEnrollmentType =
					ProductVariantEnrollmentType.DIRECT_ENROLLMENT;
				return;
			}
			this.variantEnrollmentType = ProductVariantEnrollmentType.PRICING_PLAN;
		},
	},
	created() {
		var found;
		if (this.selectedVariants.length && !found) {
			found = this.selectedVariants.find((sv) => sv.id == this.item.id);
		}
		if (this.directEnrollVariants.length && !found) {
			found = this.directEnrollVariants.find((dv) => dv.id == this.item.id);
		}
		if (!found) {
			return;
		}
		this.variantEnrollmentType =
			this.item.pricingStatus == ProductVariantPricingStatus.PAID
				? ProductVariantEnrollmentType.PRICING_PLAN
				: ProductVariantEnrollmentType.DIRECT_ENROLLMENT;
	},
};
</script>

<style>
.product-variant-list .variant {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	column-gap: 0.75rem;
	padding: 0.75rem 0;
	font-size: 1rem;
	border-bottom: 1px solid #dcdcdc;
	color: #000000;
}

.product-variant-list .variant input,
.product-variant-list .variant label {
	margin: 0;
}

.product-variant-list input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}
</style>
