<template>
      <div class="task-item">
          <div class="task-body">
            <div class="task-leading">
            <div class="completion-icon" :class="task.isCompleted ? 'completed' : 'uncompleted'">
              <svg v-if="task.isCompleted" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22 22" fill="none">
                <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="#11AE00"/>
                <path d="M9.225 13.5044L14.9262 7.65908C15.0526 7.52077 15.1862 7.38954 15.3263 7.26597C15.5161 7.08964 15.7646 6.99454 16.0207 7.00024C16.2768 7.00595 16.521 7.11203 16.7031 7.29665C16.8852 7.48126 16.9913 7.73037 16.9995 7.99269C17.0077 8.255 16.9175 8.51057 16.7473 8.70678C16.6614 8.81285 16.5696 8.91372 16.4723 9.00889C14.3798 11.1532 12.2872 13.2968 10.1942 15.4399C9.46902 16.1828 8.98702 16.1855 8.27135 15.4549C7.29412 14.4573 6.31949 13.4582 5.34744 12.4576C5.17314 12.3042 5.0559 12.0938 5.01552 11.862C4.97514 11.6302 5.01411 11.3913 5.12583 11.1855C5.21656 10.9769 5.37973 10.8101 5.58367 10.7177C5.78762 10.6253 6.01779 10.6139 6.22952 10.6856C6.48114 10.7741 6.70806 10.9239 6.89087 11.1219C7.6669 11.8887 8.41534 12.6749 9.22586 13.5071" fill="white"/>
              </svg>
            </div>
            <div class="item-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="29" viewBox="0 0 21 29" fill="none">
                <path d="M13.723 0.5C14.1158 0.5 14.4929 0.654071 14.7733 0.92911L20.0503 6.1048C20.3379 6.38689 20.5 6.77282 20.5 7.17569V26.875C20.5 27.7034 19.8284 28.375 19 28.375H2C1.17157 28.375 0.5 27.7034 0.5 26.875V2C0.5 1.17157 1.17157 0.5 2 0.5H13.723Z" fill="#FEFEFE" stroke="black"/>
                <path d="M21 6.53028L14.4375 0V6.5625L21 6.53028Z" fill="#A9A9A9"/>
                <rect x="3.9375" y="14.4375" width="9.1875" height="1.3125" rx="0.65625" fill="#3D3D3D"/>
                <rect x="3.9375" y="18.375" width="13.125" height="1.3125" rx="0.65625" fill="#A9A9A9"/>
                <rect x="3.9375" y="22.3125" width="13.125" height="1.3125" rx="0.65625" fill="#F7A1A4"/>
              </svg>
            </div>
            </div>

            <div class="task-details">
              <h3 class="task-title">{{ task.title }}</h3>
              <p class="task-subtitle">{{ task.subtitle }}</p>
              <div class="details">
            <div>
              <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 21 21" fill="none">
                  <path
                    d="M10.5082 21C4.71201 20.9965 0.00861978 16.3206 1.18842e-05 10.5118C-0.00859601 4.7307 4.66 0.0124771 10.4828 2.40661e-05C16.2763 -0.0122458 20.9872 4.6688 21 10.4774C21.0128 16.2723 16.3307 20.9883 10.5082 21ZM10.4972 19.5346C15.5025 19.526 19.5458 15.4769 19.5359 10.4834C19.5258 5.49839 15.4686 1.45117 10.4936 1.46362C5.49698 1.47607 1.45493 5.52385 1.46409 10.5056C1.47343 15.4947 5.52372 19.5432 10.4972 19.5346Z"
                    fill="black" />
                  <path
                    d="M11.2328 7.1631C11.2328 8.13956 11.2335 9.11602 11.2315 10.0925C11.2315 10.1591 11.2505 10.2047 11.2993 10.2509C12.4879 11.3786 13.6739 12.5091 14.8631 13.6365C15.1067 13.8674 15.2127 14.1337 15.103 14.4609C14.9422 14.94 14.3426 15.1176 13.9584 14.7887C13.662 14.5351 13.3875 14.256 13.1044 13.987C12.076 13.0094 11.0487 12.0309 10.0198 11.0541C9.85057 10.8935 9.76724 10.7012 9.76724 10.4681C9.76816 8.40217 9.76724 6.33644 9.76816 4.27052C9.76834 3.84254 10.0856 3.5195 10.5004 3.51822C10.913 3.51712 11.2315 3.84108 11.2322 4.27052C11.2341 5.23472 11.2326 6.19891 11.2328 7.1631Z"
                    fill="black" />
                </svg>
              </span>
              <span>{{ task.dates }}</span>
            </div>
            <div>
           
            </div>
          </div>
            </div>
          </div>

          <div class="duration">
            <div class="days"> {{ task.duration }}</div>
            <div class="label">DURATION</div>
          </div>
      </div>
</template>

<script>

export default {
    name: 'TaskCard',
    props: {
        task: Object
    },
    data() {
      return {

      };
    },
    computed: {
  
    }
  };
  </script>

<style scoped>

.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid #A7A9AC;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.task-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa;
}

.task-leading {
  display: flex;
  align-items: baseline;
}

.task-body {
  display: flex;
}

.task-details {
    flex-grow: 1;
}

.task-title {
  color: #000;
  font-family: "Circular Std";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.task-subtitle {
  color: #767676;
  font-family: "Circular Std";
  font-size: 10px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-bottom: 5px;
}

.task-duration,
.task-completion {
  font-size: 0.9em;
  color: #666666;
  margin: 0;
}

.completion-icon {
  font-size: 18px;
  margin: 5px 2px;
}

.completion-icon.uncompleted {
    height: 18px;
    width: 18px;
}

.task-body .item-icon {
  margin: 0px 15px
}

.details {
  display: flex;
  color: #000;
  font-family: "Circular Std";
  font-weight: 450;
  margin-bottom: 10px;
}

.details>div {
  margin-right: 20px;
  font-size: 10px;
}

.details .icon {
  margin-right: 3px;
}

.duration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #FFD3D4;
  background: #FFF;
  color: black;
  text-align: center;
  margin-right: 10px;
}

.duration .days {
  font-size: 13.5px;
  font-weight: 700;
  flex-shrink: 0;
  padding: 4px 8px;
}

.duration .label {
  font-size: 8px;
  font-weight: 450;
  letter-spacing: 0.64px;
  color: black;
  background-color: #FBF8F8;
  padding: 6px 8px 8px 8px;
}

</style>
  