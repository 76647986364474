import { pricingService } from "../services/pricing.service";
// import productObject from "../models/product";
import { PricingPlanType } from "../constants/pricing";
import Vue from "vue";
const state = {
  pricingPlanByProductVariantId: {},
  loading: false,
  invoicesByUserId: {},
  paymentsByUserId: {},
  allPayments: [],
  allInvoices: [],
};

const getters = {
  getPricingPlanByProductVariantId: (state) =>
    state.pricingPlanByProductVariantId,
};

const actions = {
  fetchPricingPlanByProductVariantId: async ({ commit }, id) => {
    commit("setLoading", true);
    console.log("fetchPricingPlanByProductVariantId", id);
    // state.loading = true;
    await pricingService.fetchPricingPlanByProductVariantId(id).then(
      (plan) => {
        var data = plan?.payload;
        data = actions.sortPlanDetailsByDate(data);
        commit("setPricingPlanByProductVariantId", { data, id });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  sortPlanDetailsByDate(data) {
    return data.map((plan) => {
      if (plan.type == PricingPlanType.ONE_TIME) return plan;

      plan.details = plan.details.sort(
        (a, b) => new Date(a.endAt) - new Date(b.endAt)
      );
      return plan;
    });
  },

  createPricingPlan: async ({ commit }, { plan, id }) => {
    console.log("createPricingPlan", { plan, id });
    commit("setLoading", true);
    // state.loading = true;
    await pricingService.createPricingPlan(plan).then(
      (plan) => {
        var data = plan?.payload;
        commit("addPricingPlanByProductVariantId", {
          data,
          id,
        });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  updatePricingPlan: async ({ commit }, plan) => {
    console.log("updatePricingPlan", plan);
    commit("setLoading", true);
    // state.loading = true;
    await pricingService.updatePricingPlan(plan).then(
      (res) => {
        var data = res?.payload;
        console.log("updatePricingPlan", data);
        commit("editPricingPlanByProductVariantId", {
          data: data,
          id: plan.productVariantID,
        });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  deletePricingPlan: async ({ commit }, plan) => {
    console.log("deletePricingPlan", plan);
    commit("setLoading", true);
    // state.loading = true;
    await pricingService.deletePricingPlan(plan.id).then(
      (res) => {
        // var data = res?.payload;
        console.log("deletePricingPlan", plan.id, "response", res);
        commit("deletePricingPlan", {
          data: plan,
          id: plan.productId,
        });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  async createInvoice({ commit }, request) {
    console.log("createInvoice", request);
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.createInvoice(request).then(
      (invoice) => {
        var data = invoice?.payload;
        console.log("createInvoice", data);
        commit("addInvoiceByCustomerId", {
          data,
          id: data.customerID,
        });
        commit("setLoading", false);
        return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async updateInvoice({ commit }, request) {
    console.log("updateInvoice", request);
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.updateInvoice(request).then(
      (invoice) => {
        var data = invoice?.payload;
        console.log("updateInvoice", data);
        commit("editInvoiceByCustomerId", {
          data,
          id: data.customerID,
        });
        commit("setLoading", false);
        return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async deleteInvoice({ commit }, request) {
    console.log("deleteInvoice", request);
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.deleteInvoice(request.id).then(
      (invoice) => {
        // var data = invoice?.payload;
        console.log("deleteInvoice", request);
        commit("deleteInvoiceByCustomerId", {
          data: request,
          id: request.customerID,
        });
        commit("setLoading", false);
        Vue.toasted.success(`Invoice ID ${request.id} deleted Successfully`, {
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        // return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async createBulkInvoice({ commit }, request) {
    console.log("createBulkInvoice", request);
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.createBulkInvoice(request).then(
      (invoice) => {
        var data = invoice?.payload;
        if (data) {
          console.log("createBulkInvoice", data);
          commit("setLoading", false);
          return data;
        }
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  fetchInvoiceById: async ({ commit }, id) => {
    commit("setLoading", true);
    console.log("fetchInvoiceById", id);
    // state.loading = true;
    await pricingService.fetchInvoiceById(id).then(
      (plan) => {
        var data = plan?.payload;
        commit("setPricingPlanByProductVariantId", { data, id });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  fetchInvoiceByUserId: async ({ commit }, id) => {
    commit("setLoading", true);
    console.log("fetchInvoiceByUserId", id);
    // state.loading = true;
    await pricingService.fetchInvoiceByUserId(id).then(
      (plan) => {
        var data = plan?.payload;
        commit("setInvoiceByCustomerId", { data, id });
        commit("setLoading", false);
        return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },

  async createPayment({ commit }, payment) {
    console.log("createPayment", { payment });
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.createPayment(payment).then(
      (response) => {
        var data = response?.payload;
        console.log("createPayment", data);
        commit("addPaymentByUserId", {
          data,
          id: data.customerID,
        });
        commit("setLoading", false);
        return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async updatePayment({ commit }, payment) {
    console.log("updatePayment", { payment });
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.updatePayment(payment).then(
      (response) => {
        var data = response?.payload;
        console.log("updatePayment", data);
        commit("editPaymentByUserId", {
          data,
          id: data.customerID,
        });
        commit("setLoading", false);
        return data;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return data;
  },

  async deletePayment({ commit }, payment) {
    console.log("deletePayment", { payment });
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService.deletePayment(payment.id).then(
      (response) => {
        // var data = response?.payload;

        console.log("deletePayment", payment);
        commit("deletePaymentByUserId", {
          data: payment,
          id: payment.customerID,
        });
        commit("setLoading", false);
        Vue.toasted.success(`Payment ID ${payment.id} deleted Successfully`, {
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        return data;
      },
      (error) => {
        //alert(error);
        Vue.toasted.error("Payment delete failed, " + error, {
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        commit("setLoading", false);
      }
    );
    return data;
  },

  fetchPaymentsByUserId: async ({ commit }, id) => {
    commit("setLoading", true);
    console.log("fetchPaymentsByUserId", id);
    // state.loading = true;
    await pricingService.fetchPaymentsByUserId(id).then(
      (plan) => {
        var data = plan?.payload;
        commit("setPaymentByUserId", { data, id });
        commit("setLoading", false);
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
  },
  fetchPaymentsList: async ({ commit }, filters) => {
    commit("setLoading", true);
    console.log("fetchPaymentsList");
    // state.loading = true;
    const response = await pricingService.fetchPaymentsList(filters).then(
      (plan) => {
        var data = plan?.payload;
        commit("setAllPayment", data);
        commit("setLoading", false);
        return plan;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return response;
  },

  fetchInvoicesList: async ({ commit }, filters) => {
    commit("setLoading", true);
    console.log("fetchInvoicesList");
    // state.loading = true;
    const response = await pricingService.fetchInvoicesList(filters).then(
      (plan) => {
        var data = plan?.payload;
        commit("setAllInvoices", data);
        commit("setLoading", false);
        return plan;
      },
      (error) => {
        //alert(error);
        commit("setLoading", false);
      }
    );
    return response;
  },

  async deleteInvoiceDetail(
    { commit },
    { userId, invoiceId, invoiceDetailId }
  ) {
    var request = { userId, invoiceId, invoiceDetailId };

    console.log("deleteInvoiceDetail request", request);
    commit("setLoading", true);
    // state.loading = true;
    const data = await pricingService
      .deleteInvoiceDetail(request.invoiceDetailId)
      .then(
        (response) => {
          // var data = invoice?.payload;
          console.log(
            "pricingService.deleteInvoiceDetail",
            request.invoiceDetailId
          );
          commit("deleteInvoiceDetail", request);
          commit("setLoading", false);
          Vue.toasted.success(
            `Invoice line item # ${request.invoiceDetailId} deleted successfully`,
            {
              theme: "outline",
              position: "bottom-center",
              duration: 3000,
            }
          );
          // return data;
        },
        (error) => {
          commit("setLoading", false);
        }
      );
    return data;
  },

  processPayment: async ({ commit }, { invoiceId, serviceCode, data }) => {
    commit("setLoading", true);
    console.log("processPayment");
    // state.loading = true;
    const response = await pricingService
      .processPayment(invoiceId, serviceCode, data)
      .then(
        (paymentData) => {
          var data = paymentData?.payload;
          commit("setLoading", false);
          return data;
        },
        (error) => {
          //alert(error);
          commit("setLoading", false);
        }
      );
    return response;
  },

  completePayment: async (
    { commit },
    { serviceProvider, paymentReference, sessionId, action, orderReference }
  ) => {
    commit("setLoading", true);
    // state.loading = true;
    const response = await pricingService
      .completePayment(serviceProvider, paymentReference, sessionId, action, orderReference)
      .then(
        (paymentData) => {
          var data = paymentData;
          commit("setLoading", false);
          return data;
        },
        (error) => {
          //alert(error);
          commit("setLoading", false);
        }
      );
    return response;
  },
};

const mutations = {
  setPricingPlanByProductVariantId(state, { data, id }) {
    // console.log(data);
    state.pricingPlanByProductVariantId = {
      ...state.pricingPlanByProductVariantId,
      [id]: data,
    };
    state.loading = false;
  },
  addPricingPlanByProductVariantId(state, { data, id }) {
    state.pricingPlanByProductVariantId[id]
      ? state.pricingPlanByProductVariantId[id].push(data)
      : (state.pricingPlanByProductVariantId[id] = [data]);
    state.loading = false;
  },
  editPricingPlanByProductVariantId(state, pricingDetails) {
    console.log("editPricingPlanByProductVariantId", pricingDetails);
    // const planId = state.pricingPlanByProductVariantId[id].indexOf(data);
    const planToEdit = pricingDetails.data;
    console.log("updated plan: ", planToEdit);
    const updated = state.pricingPlanByProductVariantId[pricingDetails.id].map(
      (plan) => {
        console.log(plan.id, planToEdit);
        if (plan.id == planToEdit.id) {
          console.log("editingplan", planToEdit);
          return planToEdit;
        }
        return plan;
      }
    );
    console.log(updated);
    state.pricingPlanByProductVariantId[pricingDetails.id] = updated;
    // state.pricingPlanByProductVariantId[id]
    // 	? state.pricingPlanByProductVariantId[id].push(data)
    // 	: (state.pricingPlanByProductVariantId[id] = [data]);
    state.loading = false;
  },
  deletePricingPlan(state, pricingDetails) {
    console.log(pricingDetails);
    var updatedPlans = state.pricingPlanByProductVariantId[
      pricingDetails.id
    ].filter((plan) => plan.id != pricingDetails.data.id);
    console.log(updatedPlans);
    state.pricingPlanByProductVariantId = {
      ...state.pricingPlanByProductVariantId,
      [pricingDetails.id]: updatedPlans,
    };
  },
  setInvoiceByCustomerId(state, { data, id }) {
    state.invoicesByUserId = { ...state.invoicesByUserId, [id]: data };
    state.loading = false;
  },
  setPaymentByUserId(state, { data, id }) {
    state.paymentsByUserId[id] = data;
    state.loading = false;
  },
  addInvoiceByCustomerId(state, { data, id }) {
    if (state.invoicesByUserId[id]) {
      state.invoicesByUserId[id] = [...state.invoicesByUserId[id], data];
    } else {
      state.invoicesByUserId[id] = [data];
    }
    state.loading = false;
  },
  editInvoiceByCustomerId(state, { data, id }) {
    if (state.invoicesByUserId[id]) {
      var updatedInvoices = state.invoicesByUserId[id].map((invoice) =>
        invoice.id == data.id ? data : invoice
      );
      state.invoicesByUserId = {
        ...state.invoicesByUserId,
        [id]: updatedInvoices,
      };
    } else {
      state.invoicesByUserId[id] = [data];
    }
    state.loading = false;
  },
  deleteInvoiceByCustomerId(state, { data, id }) {
    if (state.invoicesByUserId[id]) {
      var updatedInvoices = state.invoicesByUserId[id].filter(
        (invoice) => invoice.id != data.id
      );
      state.invoicesByUserId = {
        ...state.invoicesByUserId,
        [id]: updatedInvoices,
      };
    } else {
      state.invoicesByUserId[id] = [data];
    }
    state.loading = false;
  },
  deleteInvoiceDetail(state, data) {
    var userInvoices = state.invoicesByUserId[data.userId];

    if (!userInvoices) return;

    var changedInvoice = userInvoices.find(
      (invoice) => invoice.id == data.invoiceId
    );
    changedInvoice.details = changedInvoice.details.filter(
      (invoiceDetail) => invoiceDetail.id != data.invoiceDetailId
    );

    userInvoices = userInvoices.map((invoice) =>
      invoice.id == changedInvoice.id ? changedInvoice : invoice
    );

    state.invoicesByUserId = {
      ...state.invoicesByUserId,
      [data.userId]: userInvoices,
    };

    state.allInvoices.map((invoice) =>
      invoice.id == data.invoiceId ? changedInvoice : invoice
    );
  },
  setAllPayment(state, data) {
    state.allPayments = data;
    state.loading = false;
  },
  setAllInvoices(state, data) {
    state.allInvoices = data;
    state.loading = false;
  },
  updatePayment(state, { data, id }) {
    state.allPayments = [
      ...state.allPayments.map((p) => {
        if (p.id == id) {
          return data;
        } else return p;
      }),
    ];
  },
  updateInvoice(state, { data, id }) {
    state.allInvoices = [
      ...state.allInvoices.map((p) => {
        if (p.id == id) {
          return data;
        } else return p;
      }),
    ];
  },
  addPaymentByUserId(state, { data, id }) {
    if (state.paymentsByUserId[id]) {
      state.paymentsByUserId[id].push(data);
    } else {
      state.paymentsByUserId[id] = [data];
    }
    state.loading = false;
  },
  editPaymentByUserId(state, { data, id }) {
    if (state.paymentsByUserId[id]) {
      var updatedPayments = state.paymentsByUserId[id].map((payment) => {
        if (payment.id == data.id) {
          console.log("found payment", payment, data);
          return data;
        } else return payment;
      });
      state.paymentsByUserId = {
        ...state.paymentsByUserId,
        [id]: updatedPayments,
      };
    } else {
      state.paymentsByUserId[id] = [data];
    }
    state.loading = false;
  },
  deletePaymentByUserId(state, { data, id }) {
    if (state.paymentsByUserId[id]) {
      console.log("delete payment", data, id);
      var updatedPayments = state.paymentsByUserId[id].filter(
        (payment) => payment.id != data.id
      );
      state.paymentsByUserId = {
        ...state.paymentsByUserId,
        [id]: updatedPayments,
      };
    }
    state.loading = false;
  },
  setLoading(state, data) {
    if (data) {
      console.log("pricing loading...");
    } else {
      console.log("pricing loading complete!");
    }
    state.loading = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
