<template>
	<form @submit="searchCourses">
		<div class="container flexRow gap-0 set-items-center">
			<div class="container flex-column dropdownStyling">
				<div>
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#Course"
							role="button"
							aria-expanded="false"
							aria-controls="Course"
							style="border-top-right-radius: 5px; border-top-left-radius: 5px"
						>
							Course
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="Course">
						<b-form-radio-group
							id="radio-group-2"
							v-model="courseSelected"
							name="course"
						>
							<b-form-radio
								class="d-flex gap-2 setLiStyle"
								v-for="course in courseList"
								:key="course.id"
								:value="course.id"
								>{{ course.title }}</b-form-radio
							>
						</b-form-radio-group>
					</div>
				</div>
				<div>
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#Qualification"
							role="button"
							aria-expanded="false"
							aria-controls="Qualification"
							style="border-top-right-radius: 5px; border-top-left-radius: 5px"
						>
							Qualification
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="Qualification">
						<b-form-radio-group
							id="radio-group-2"
							v-model="categorySelected"
							name="category"
						>
							<b-form-radio
								class="d-flex gap-2 setLiStyle"
								v-for="category in categoryList"
								:key="category.id"
								:value="category.id"
								>{{ category.title }}</b-form-radio
							>
						</b-form-radio-group>
					</div>
				</div>
        <!--
				<div>
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#category2"
							role="button"
							aria-expanded="false"
							aria-controls="category2"
						>
							Tutor
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="category2">
						<ul class="ulStyle">
							<li class="setLiStyle">
								<form action="" class="searchBoxForm" style="margin-left: 0px">
									<input
										class="form-control me-2"
										type="search"
										placeholder="ACCA"
										aria-label="Search"
										style="padding: 10px; width: 16rem; border-radius: 0.25rem"
									/>
									<i class="fa fa-search searchIcon" style="color: #a7a9ac"></i>
								</form>
							</li>
							<li class="setLiStyle">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefault"
									/>
									<label class="form-check-label" for="flexCheckDefault">
										Hamza Abdul Haq
									</label>
								</div>
							</li>
							<li class="setLiStyle">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefault"
									/>
									<label class="form-check-label" for="flexCheckDefault">
										Shahzaib Paracha
									</label>
								</div>
							</li>
							<li class="setLiStyle">
								<div class="form-check">
									<input
										class="form-check-input"
										type="checkbox"
										value=""
										id="flexCheckDefault"
									/>
									<label class="form-check-label" for="flexCheckDefault">
										Alber Afzal Khan
									</label>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#category3"
							role="button"
							aria-expanded="false"
							aria-controls="category3"
						>
							Date Uploaded
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="category3">
						<ul class="ulStyle">
							<li class="setLiStyle">Category 2</li>
							<li class="setLiStyle">Category 1</li>
							<li class="setLiStyle">Category 3</li>
						</ul>
					</div>
				</div>
				<div>
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#category4"
							role="button"
							aria-expanded="false"
							aria-controls="category4"
						>
							Rating
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="category4">
						<ul class="ulStyle">
							<li class="setLiStyle">
								<div class="setFlex">
									<div class="form-check">
										<input
											class="form-check-input"
											type="radio"
											name="flexRadioDefault"
											id="flexRadioDefault1"
										/>
										<label class="form-check-label" for="flexRadioDefault1">
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
										</label>
									</div>
									<div>
										<p class="pRating">4.5 & up</p>
										<p style="display: inline-block">(4960)</p>
									</div>
								</div>
							</li>
							<li class="setLiStyle">
								<div class="setFlex">
									<div class="form-check">
										<input
											class="form-check-input"
											type="radio"
											name="flexRadioDefault"
											id="flexRadioDefault1"
										/>
										<label class="form-check-label" for="flexRadioDefault1">
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
											<span class="fa fa-star checked"></span>
										</label>
									</div>
									<div>
										<p class="pRating">4.5 & up</p>
										<p style="display: inline-block">(4960)</p>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="borderBottomAdjustment">
					<p class="pStyle">
						<a
							class="buttonStyling"
							data-bs-toggle="collapse"
							href="#category5"
							role="button"
							aria-expanded="false"
							aria-controls="category5"
							style="
								border-bottom-right-radius: 5px;
								border-bottom-left-radius: 5px;
							"
						>
							Pricing
							<i class="fas fa-angle-down"></i>
						</a>
					</p>
					<div class="collapse" id="category5">
						<ul class="ulStyle">
							<li class="setLiStyle">Category 2</li>
							<li class="setLiStyle">Category 1</li>
							<li class="setLiStyle">Category 3</li>
						</ul>
					</div>
				</div>
        -->
			</div>
		</div>

		<div class="d-flex gap-3 mt-4">
			<button @click="clearFilters" class="border-btn flex-grow-1">
				<!-- <b-spinner v-if="loading" small></b-spinner> -->
				Clear
			</button>
			<button :disabled="loading" class="primary-btn flex-grow-1" type="submit">
				<b-spinner v-if="loading" small></b-spinner>
				Search
			</button>
		</div>
	</form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "ProductAdvancedFilters",
	data() {
		return {
			tutorsList: null,
			courseList: null,
			categoryList: null,
			tutorSelected: [],
			ratingSelected: 0,
			courseSelected: null,
			categorySelected: null,
			searchText: "",
		};
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		...mapActions("product", ["fetchAllProducts"]),
		...mapActions("category", ["fetchCategories"]),
		...mapActions("productVariant", ["fetchProductVariants"]),

		searchCourses(e) {
			e.preventDefault();

      let queryParams = this.filters || {};

      if(this.categorySelected) {
        queryParams['categoryId'] = this.categorySelected;
      }

      if(this.courseSelected) {
        queryParams['productId'] = this.courseSelected;
      }

      this.$router.push({ path: 'search', query: queryParams});


			// var filterObject = {};
			// if (this.courseSelected) {
			// 	filterObject.ProductID = this.courseSelected;
			// }
			// if (this.categorySelected) {
			// 	filterObject.CategoryID = this.categorySelected;
			// }
			// this.fetchProductVariants(filterObject).then((res) => {
			// 	document.body.scrollTop = 0; // For Safari
			// 	document.documentElement.scrollTop = 0;
			// });
		},
		clearFilters(e) {
			e.preventDefault();
			this.courseSelected = null;
			this.categorySelected = null;

      let queryParams = this.filters || {}; // retain productTitle filter
      queryParams['categoryId'] = undefined;
      queryParams['productId'] = undefined;

      this.$router.push({ path: 'search', query: queryParams });

			// this.fetchProductVariants().then((res) => {
			// 	document.body.scrollTop = 0; // For Safari
			// 	document.documentElement.scrollTop = 0;
			// });
		},
    loadFilterValuesFromQueryString(query) {
      this.categorySelected = null;
      this.courseSelected = null;

      if(query) {
        if(query.categoryId) {
          this.categorySelected = query.categoryId;
        }

        if(query.productId) {
          this.courseSelected = query.productId;
        }
      }
    }

	},
	created() {
		this.fetchCategories().then(() => {
			this.categoryList = this.categories;
		});
		this.fetchAllProducts().then(() => {
			console.log("products fetched");
			this.courseList = Object.values(this.products.items);
		});

    this.loadFilterValuesFromQueryString(this.$route.query);
	},
	computed: {
		...mapState("user", ["trainers"]),
		...mapState("product", ["products"]),
		...mapState("category", ["categories"]),
		...mapState("productVariant", ["loading"]),
	},
  watch: {
    filters: function() {
      this.loadFilterValuesFromQueryString();
    }
  }
};
</script>

<style></style>
