<template>
	<div>
		<BannerSmall>
			<h2 class="pt-5 pb-5">KuickPay BPS</h2>
		</BannerSmall>
		<div class="pt-5 container ibft-evidence">
			<h5 v-html="instructions"></h5>
		</div>
	</div>
</template>

<script>
import BannerSmall from "../Layout/BannerSmall.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "KuickPayBPS",
	data() {
		return {
            placeholders: {
                consumernumber_invoice: null,
                consumernumber_user: null,
            },
            invoiceToPay: {}
		};
	},
	props: { instructions: String, paymentMode: Object, merchantcode: String },
	components: { BannerSmall },
	computed: {
		...mapState("user", ["user"]),
		...mapState("pricing", ["invoicesByUserId", "loading"]),
	},
	methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("pricing", [
			"fetchInvoiceByUserId",
			"createPayment",
			"fetchPaymentsList",
		]),
        
        replacePlaceholders(htmlString, data) {
            const placeholderRegex = /\{\{([^}]+)\}\}/g;

            const replacedHtml = htmlString.replace(placeholderRegex, (match, key) => {
                const replacement = data[key.trim()];
                return replacement !== undefined ? replacement : match;
            });

            return replacedHtml;
        },

        prependZeros(numberString) {
            const targetLength = 10;
            const currentLength = numberString.length;

            if (currentLength >= targetLength) {
                return numberString;
            }

            const numberOfZerosToAdd = targetLength - currentLength;
            const zeros = "0".repeat(numberOfZerosToAdd);
            const paddedNumber = zeros + numberString;

            return paddedNumber;
        }
	},
	async created() {
		var invoiceID = this.$route.params.id;
		var invoice;
        var merchantcode = this.$route.query.merchantcode;

        this.placeholders.consumernumber_user = merchantcode.toString() + "877" + this.prependZeros(this.user.id).toString();
        this.placeholders.consumernumber_invoice = merchantcode.toString() + "468" + this.prependZeros(invoiceID).toString();

		if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
			invoice = this.invoicesByUserId[this.user.id].find(
				(inv) => inv.id == invoiceID
			);
			this.invoiceToPay = invoice;
		}
        else {
			this.fetchInvoiceByUserId(this.user.id).then(() => {
				invoice = this.invoicesByUserId[this.user.id].find(
					(invoice) => invoice.id == invoiceID
				);
				this.invoiceToPay = invoice;
			});
		}

        if(this.instructions){
            this.instructions = await this.replacePlaceholders(this.instructions, this.placeholders);
        }
	},
};
</script>

<style scoped>
.main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

/* .ibft-evidence .upload-btn-wrapper {
	max-width: unset !important;
} */

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	background: #f5f5f5;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	width: fit-content;
	align-items: center;
	max-width: 10rem;
	max-height: 6.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

#evidence {
	pointer-events: none;
}
</style>
