<template>
	<div class="category-list-container">
		<QuestionCategoryListItem
			v-for="category in filteredQuestionCategories"
			:key="category.categoryID"
			:categoryData="category"
			@selectCategory="selectCategory"
			:selectedCategory="selectedCategory"
			:productVariantId="productVariantId"
			:selectMode="selectMode"
		/>
		<QuestionCategoryListItem
			v-if="createMode"
			@selectCategory="selectCategory"
			:selectedCategory="selectedCategory"
			:createMode="createMode"
			:productVariantId="productVariantId"
			@cancelCreate="cancelCreate"
			:selectMode="selectMode"
		/>
		<div
			class="d-flex justify-content-center"
			v-if="
				!createMode &&
				filteredQuestionCategories &&
				filteredQuestionCategories.length == 0 &&
				!loading
			"
		>
			<span class="text-muted">
				{{
					searchText
						? "No question pools found"
						: "You haven't created any question pools yet"
				}}
			</span>
		</div>
		<div
			v-if="!filteredQuestionCategories && loading"
			class="d-flex flex-column gap-1"
		>
			<b-skeleton height="2rem"></b-skeleton>
			<b-skeleton height="2rem"></b-skeleton>
			<b-skeleton height="2rem"></b-skeleton>
		</div>
		<div
			@click="selectCategory([])"
			class="category-collapse-button create mt-auto"
		>
			<!-- v-if="hasUncategorizedQuestions" -->
			Uncategorized Questions
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import QuestionCategoryListItem from "./QuestionCategoryListItem.vue";
export default {
	name: "QuestionCategoryList",
	components: {
		QuestionCategoryListItem,
	},
	computed: {
		...mapState("exam", ["questionCategories", "loading"]),
		...mapState("productVariant", ["productVariants"]),
		filteredQuestionCategories() {
			if (
				this.questionCategories[this.productVariantId] &&
				this.questionCategories[this.productVariantId].root &&
				this.questionCategories[this.productVariantId].root.childCategories
			)
				if (this.searchText && this.searchText.length > 0) {
					return Object.values(
						this.questionCategories[this.productVariantId]
					).filter((category) => {
						return (
							(category.id !==
								this.questionCategories[this.productVariantId].root.id &&
								(category.categoryName || "")
									.toLocaleLowerCase()
									.indexOf(this.searchText.toLocaleLowerCase()) > -1) ||
							(category.id || "")
								.toLocaleLowerCase()
								.indexOf(this.searchText.toLocaleLowerCase()) > -1
						);
					});
				} else {
					return this.questionCategories[
						this.productVariantId
					].root.childCategories.filter((category) => {
						return (
							(category.categoryName || "")
								.toLocaleLowerCase()
								.indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
							(category.id || "")
								.toLocaleLowerCase()
								.indexOf(this.searchText.toLocaleLowerCase()) > -1
						);
					});
				}
			else return null;
		},
		// hasUncategorizedQuestions() {
		// 	return this.allQuestions.find();
		// },
	},
	methods: {
		...mapActions("exam", ["fetchQuestionCategories", "createQuestion"]),
		selectCategory(category) {
			this.$emit("selectCategory", category);
		},
		cancelCreate() {
			this.$emit("cancelCreate");
		},
	},
	props: {
		selectedCategory: Array,
		productVariantId: String,
		createMode: { type: Boolean, default: false },
		searchText: String,
		selectMode: { type: Boolean, default: false },
	},
	created() {},
};
</script>

<style scoped>
.category-list-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
}

.category-collapse-button {
	background: #ffffff;
	/* border: 1px solid #dcdcdc; */
	box-sizing: border-box;
	border-radius: 8px;
	padding: 0.5rem 0.75rem;
	width: 100%;
	text-align: left;
	margin-bottom: 0.5rem;
	color: #000000;
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.75rem;
	position: relative;
	cursor: pointer;
}

.category-collapse-button:hover,
.category-collapse-button.create {
	background-color: #fafafa;
}
</style>
