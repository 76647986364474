<template>
	<div>
		<label
			:class="[
				{
					'form-check-label': !customLabelClass,
					[customLabelClass]: customLabelClass,
				},
			]"
			v-if="showLabel"
			:for="inputFieldOptions.DisplayName"
			>{{ inputFieldOptions.DisplayName }}
			{{ inputFieldOptions.IsRequired ? " (Required)" : "" }}</label
		>
		<textarea
			v-if="inputFieldOptions.Type.toLowerCase() == 'textarea'"
			:class="[
				{
					'form-control': !customInputClass,
					[customInputClass]: customInputClass,
					'input-w-icon': hasIcon,
				},
			]"
			type="textarea"
			:placeholder="`${inputFieldOptions.DisplayName} ${
				inputFieldOptions.IsRequired == 'true' ? '(required)' : ''
			}`"
			:required="inputFieldOptions.IsRequired == 'true'"
			v-model="inputValue"
			@change="$emit('inputValue', inputValue)"
			:tabindex="tabIndex"
			:disabled="disabled"
			:readonly="readonly"
		/>
		<div v-else-if="inputFieldOptions.Type.toLowerCase() == 'select'">
			<select
				:class="[{ 'form-control': !customInputClass, customInputClass }]"
				type="search"
				:placeholder="`${inputFieldOptions.DisplayName} ${
					inputFieldOptions.IsRequired == 'true' ? '(required)' : ''
				}`"
				:required="inputFieldOptions.IsRequired == 'true'"
				v-model="inputValue"
				@change="$emit('inputValue', inputValue)"
				:tabindex="tabIndex"
				:disabled="disabled || readonly"
			>
				<option
					v-for="option in inputFieldOptions.SelectOptions"
					:key="option.Value"
					:value="option.Value"
				>
					{{ option.DisplayName }}
				</option>
			</select>
		</div>
		<div
			class="mb-2"
			v-else-if="inputFieldOptions.Type.toLowerCase() == 'checkbox'"
		>
			<div
				class="form-check"
				v-for="(checkbox, index) in inputFieldOptions.CheckboxItems"
				:key="index"
			>
				<input
					type="checkbox"
					:value="checkbox.value"
					:id="checkbox.value"
					v-model="inputValue[checkbox.value]"
					class="form-check-input"
					@change="$emit('inputValue', inputValue)"
					:tabindex="tabIndex"
					:disabled="disabled"
					:readonly="readonly"
				/>
				<label
					:class="[
						{
							'form-check-label': !customLabelClass,
							[customLabelClass]: customLabelClass,
						},
					]"
					:for="checkbox.value"
					>{{ checkbox.DisplayName }}</label
				>
			</div>
		</div>
		<div
			class="mb-2 d-flex flex-column"
			v-else-if="inputFieldOptions.Type.toLowerCase() == 'radio'"
		>
			<b-form-radio-group
				:id="inputFieldOptions.DisplayName"
				:buttons="inputFieldOptions.radioStyle.toLowerCase() == 'buttons'"
				v-model="inputValue"
				html-field="DisplayName"
				:options="inputFieldOptions.RadioButtonItems"
				@change="$emit('inputValue', inputValue)"
				button-variant="outline"
				:tabindex="tabIndex"
				:disabled="disabled || readonly"
			>
			</b-form-radio-group>
		</div>
		<div
			class="d-flex justify-content-between mb-2"
			v-else-if="inputFieldOptions.Type.toLowerCase() == 'range'"
		>
			<input
				class="d-flex flex-grow-2"
				type="range"
				:min="inputFieldOptions.Start"
				:max="inputFieldOptions.End"
				:step="inputFieldOptions.Step"
				oninput="this.nextElementSibling.innerHTML = this.value"
				:tabindex="tabIndex"
				:disabled="disabled"
				:readonly="readonly"
			/>
			<output class="d-flex flex-grow-1 justify-content-center"></output>
		</div>
		<div
			class="d-flex justify-content-between mb-2"
			v-else-if="inputFieldOptions.Type.toLowerCase() == 'tel'"
		>
			<vue-tel-input
				:class="`${customInputClass ? customInputClass : 'form-control'} ${
					isPhoneError && showError ? 'error' : ''
				}`"
				defaultCountry="PK"
				:inputOptions="telInputOptions"
				validCharactersOnly
				v-model="inputValue"
				@input="validate"
				@blur="showError = true"
				@focus="showError = false"
				:autoFormat="true"
				mode="national"
				invalidMsg="Please enter a valid phone number"
				id="mobile"
				:disabled="readonly || disabled"
			></vue-tel-input>
		</div>
		<div class="form-group" v-else>
			<i v-if="hasIcon" class="input-icon-left far fa-user-circle"></i>
			<input
				:class="[
					{
						'form-control': !customInputClass,
						[customInputClass]: customInputClass,
						'input-w-icon': hasIcon,
					},
				]"
				:type="inputFieldOptions.Type.toLowerCase()"
				:placeholder="`${inputFieldOptions.DisplayName} ${
					inputFieldOptions.IsRequired == 'true' ? '(required)' : ''
				}`"
				:required="inputFieldOptions.IsRequired == 'true'"
				v-model="inputValue"
				@change="$emit('inputValue', inputValue)"
				:tabindex="tabIndex"
				:disabled="disabled"
				:readonly="readonly"
			/>
		</div>
	</div>
</template>

<script>
const getCountryISO3 = require("country-iso-2-to-3");

export default {
	name: "DynamicFormInput",
	props: {
		inputFieldOptions: Object,
		customInputClass: { type: String, default: null },
		hasIcon: Boolean,
		showLabel: Boolean,
		customLabelClass: { type: String, default: null },
		tabIndex: Number,
		inputSetValue: { type: String, default: null },
		disabled: Boolean,
		readonly: Boolean,
	},
	data() {
		return {
			inputValue: null,
			phone: null,
			isPhoneError: false,
			showError: false,
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: this.$props.tabIndex,
				placeholder: this.$props.inputFieldOptions.DisplayName,
				mode: "national",
			},
		};
	},
	methods: {
		validate(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.country && obj.country.iso2) {
				this.countryCode = getCountryISO3(obj.country.iso2);
				this.phone = obj.number;
				this.$emit("inputValue", this.phone);
			}
			return false;
		},
	},
	created() {
		// console.log(this.inputSetValue);
		if (this.inputFieldOptions.Type.toLowerCase() == "tel") {
			console.log(this.inputSetValue);
			if (this.inputSetValue) this.phone = this.inputSetValue ?? null;
			if (this.inputSetValue) this.inputValue = this.inputSetValue ?? null;
			this.phone = this.inputFieldOptions.DefaultValue ?? null;
			return;
		}
		this.inputValue = this.inputFieldOptions.DefaultValue ?? null;
		if (this.inputSetValue) this.inputValue = this.inputSetValue ?? null;
		if (this.inputFieldOptions.Type.toLowerCase() == "checkbox") {
			this.inputFieldOptions.CheckboxItems.forEach((item) => {
				this.inputValue[item.value] = item.checked ?? null;
			});
		}
	},
};
</script>

<style scoped>
.flex-grow-2 {
	flex-grow: 2 !important;
}
</style>
