<template>
	<div>
		<BannerSmall>
			<h2 class="pt-5 pb-5">Bank Transfer</h2>
		</BannerSmall>
		<div class="pt-5 container ibft-evidence">
			<h5 v-html="instructions"></h5>
			<label class="main-label mt-4" for="ibft_evidence"
				>Picture of transaction:</label
			>
			<div class="d-flex flex-column gap-4">
				<div :class="['upload-btn-wrapper', { preview: evidence }]">
					<img
						:class="[{ 'd-none': !evidence }]"
						id="preview"
						:src="evidence"
						alt="preview"
					/>
					<button
						v-if="!evidence"
						type="button"
						class="upload-btn"
						@click="openDialog"
					>
						<b-spinner v-if="fileUploading" small></b-spinner>
						upload
					</button>
					<input
						class="form-input"
						type="file"
						accept="image/*"
						name="file"
						id="image-upload"
						@change="getFileLink"
					/>
					<div v-if="evidence && !pendingPayment" class="right-menu">
						<b-spinner
							class="right-menu-icon"
							small
							v-if="fileUploading"
						></b-spinner>
						<a
							v-if="!fileUploading"
							role="button"
							class="right-menu-icon"
							@click="openDialog"
						>
							<img src="@/assets/images/edit-icon.svg" />
						</a>
						<a
							v-if="!fileUploading"
							@click="removeFile"
							role="button"
							class="right-menu-icon delete"
						>
							<img src="@/assets/images/delete-icon.svg" />
						</a>
					</div>
				</div>
				<div v-if="!pendingPayment" class="col">
					<button
						:disabled="!evidence || loading"
						@click="payInvoice"
						class="primary-btn"
						type="submit"
					>
						<b-spinner v-if="loading" small></b-spinner>
						Submit
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BannerSmall from "../Layout/BannerSmall.vue";
import { mapActions, mapState } from "vuex";

export default {
	name: "BankTransfer",
	data() {
		return {
			evidence: null,
			invoiceToPay: null,
			fileUploading: false,
			pendingPayment: null,
		};
	},
	props: { instructions: String, paymentMode: Object },
	components: { BannerSmall },
	computed: {
		...mapState("user", ["user"]),
		...mapState("pricing", ["invoicesByUserId", "loading"]),
	},
	methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("pricing", [
			"fetchInvoiceByUserId",
			"createPayment",
			"fetchPaymentsList",
		]),
		getFileLink(e) {
			this.fileUploading = true;
			const [file] = e.target.files;
			if (file) {
				document.getElementById("preview").title = file.name;

				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "PaymentEvidence");
				formData.append("type", "Image");
				
				formData.append("IsCompressionRequired", true);

				console.log(...formData);
				this.uploadContentFile(formData).then((data) => {
					console.log(data);
					this.evidence = data;
					$(".upload-btn-wrapper").addClass("success");
					this.fileUploading = false;
				});
				return;
			}
			this.fileUploading = false;
			console.log("file: ", e.target.files);
		},
		openDialog() {
			$("#image-upload").click();
		},
		payInvoice() {
			var paymentObject = {
				customerID: this.user.id,
				paymentDate: new Date().toISOString(),
				method: this.paymentMode.serviceName,
				methodProvider: this.paymentMode.serviceProvider,
				methodProviderID: this.paymentMode.serviceCode,
				details: this.invoiceToPay.details.map((detail) => {
					return {
						invoiceDetailID: detail.id.toString(),
						amount: parseFloat(detail.amount),
						type: "Received",
					};
				}),
				status: "Pending",
				metadata: JSON.stringify({ evidence: this.evidence }),
			};
			// console.log(paymentObject);
			this.createPayment(paymentObject).then((res) => {
				console.log(res);
				if (res) {
					this.fetchInvoiceByUserId(this.user.id).then(() => {
						this.$toasted.success("Payment evidence submitted for review", {
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						});
						this.$router.push("/user/invoices");
					});
				}
			});
		},
		removeFile() {
			this.evidence = null;
		},
	},
	created() {
		if (this.$route.query.paymentID) {
			this.fetchPaymentsList({ ID: this.$route.query.paymentID }).then(
				(res) => {
					var data = res.payload;
					console.log(data);
					this.pendingPayment = data[0];

					this.evidence = JSON.parse(data[0].metadata).evidence;
				}
			);
		}
		var invoiceID = this.$route.params.id;
		var invoice;
		if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
			invoice = this.invoicesByUserId[this.user.id].find(
				(inv) => inv.id == invoiceID
			);
			this.invoiceToPay = invoice;
		} else {
			this.fetchInvoiceByUserId(this.user.id).then(() => {
				invoice = this.invoicesByUserId[this.user.id].find(
					(invoice) => invoice.id == invoiceID
				);
				this.invoiceToPay = invoice;
			});
		}
	},
};
</script>

<style scoped>
.main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

/* .ibft-evidence .upload-btn-wrapper {
	max-width: unset !important;
} */

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	background: #f5f5f5;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	width: fit-content;
	align-items: center;
	max-width: 10rem;
	max-height: 6.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

#evidence {
	pointer-events: none;
}
</style>
