<template>
	<div class="home">
		<HomePageBanner
			v-if="
				systemParameters.Banners && systemParameters.Banners.slides.length > 0
			"
		/>
		<ProductCategoryTabs
			v-if="categories && categories.length"
			carouselType="featured"
		/>
		<HompageBecomeInstructor />
		<HompageTestimonials />
		<ProductCategoryTabs
			v-if="categories && categories.length"
			carouselType="standard"
		/>
	</div>
</template>

<script>
import HomePageBanner from "@/components/Layout/HomePageBanner.vue";
import ProductCategoryTabs from "@/components/Product/ProductCategoryTabs.vue";
import HompageBecomeInstructor from "./HomepageBecomeInstructor.vue";
import HompageTestimonials from "./HomepageTestimonials.vue";
import { mapActions, mapState } from "vuex";
export default {
	name: "Home",
	components: {
		HomePageBanner,
		ProductCategoryTabs,
		HompageBecomeInstructor,
		HompageTestimonials,
	},
	data() {
		return {
			// banners: banners,
		};
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		...mapState("user", ["trainers"]),
		...mapState("category", ["categories"]),
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		...mapActions("category", ["fetchCategories"]),
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "homepage-loaded", data: null });
		});
	},
	created() {
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		
		this.fetchCategories().then(() => {});
	},
};
</script>

<style scoped></style>
