import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import {
  FETCH_POST_INVOICES_API,
  FETCH_PRICING_BY_PRODUCTVARIANT_API,
  POST_UPDATE_DELETE_PRICING_API,
  FETCH_INVOICE_BY_ID_API,
  FETCH_INVOICE_BY_USER_API,
  POST_INVOICE_PAYMENT_API,
  FETCH_PAYMENTS_BY_USER_API,
  FETCH_PAYMENTS_LIST,
  DELETE_INVOICE_DETAIL_API,
  PROCESS_PAYMENT_API,
  COMPLETE_PAYMENT_API,
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const pricingService = {
  fetchPricingPlanByProductVariantId,
  createPricingPlan,
  updatePricingPlan,
  deletePricingPlan,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  deleteInvoiceDetail,
  createPayment,
  updatePayment,
  deletePayment,
  createBulkInvoice,
  fetchInvoiceById,
  fetchInvoiceByUserId,
  fetchPaymentsByUserId,
  fetchPaymentsList,
  fetchInvoicesList,
  processPayment,
  completePayment,
};

async function fetchPricingPlanByProductVariantId(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_PRICING_BY_PRODUCTVARIANT_API +
        `${id}/plan`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createPricingPlan(body) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + POST_UPDATE_DELETE_PRICING_API,
      "POST",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // return body;
}

async function updatePricingPlan(body) {
  store.dispatch("appState/startLoading");
  try {
    let resBody = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + POST_UPDATE_DELETE_PRICING_API,
      "PUT",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return resBody?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // return resBody;
}

async function deletePricingPlan(id) {
  store.dispatch("appState/startLoading");
  try {
    let resBody = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        POST_UPDATE_DELETE_PRICING_API +
        `?PlanID=${id}`,
      "DELETE",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    // return resBody?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // return body;
}

async function createInvoice(body) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_INVOICES_API,
      "POST",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  // return body;
  // }, 2000);
}

async function updateInvoice(body) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_INVOICES_API,
      "PUT",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  // return body;
  // }, 2000);
}
async function deleteInvoice(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_INVOICES_API +
        `?invoiceID=${id}`,
      "DELETE",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    // return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  // return body;
  // }, 2000);
}

async function deleteInvoiceDetail(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        DELETE_INVOICE_DETAIL_API +
        `?invoiceDetailID=${id}`,
      "DELETE",
      null,
      false
    );
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  // return body;
  // }, 2000);
}

async function createBulkInvoice(body) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_INVOICES_API + "/bulk",
      "POST",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  // return body;
  // }, 2000);
}

async function createPayment(body) {
  store.dispatch("appState/startLoading");
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + POST_INVOICE_PAYMENT_API,
      "POST",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return res?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  return body;
  // }, 2000);
}

async function updatePayment(body) {
  store.dispatch("appState/startLoading");
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + POST_INVOICE_PAYMENT_API,
      "PUT",
      JSON.stringify(body),
      false
    );
    store.dispatch("appState/stopLoading");
    return res?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  return body;
  // }, 2000);
}

async function deletePayment(id) {
  store.dispatch("appState/startLoading");
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        POST_INVOICE_PAYMENT_API +
        `?paymentID=${id}`,
      "DELETE",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    // return res?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
  // setTimeout(() => {
  return id;
  // }, 2000);
}

async function fetchInvoiceById(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_INVOICE_BY_ID_API + `${id}`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchInvoiceByUserId(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_INVOICE_BY_USER_API + `${id}`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchPaymentsByUserId(id) {
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_PAYMENTS_BY_USER_API + `${id}`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchPaymentsList(filters) {
  var queryParams = "";
  if (
    filters.invoiceID ||
    filters.ID ||
    filters.Status ||
    filters.MethodProvider ||
    filters.StartFrom ||
    filters.EndAt ||
    filters.pageSize ||
    filters.currentpage
  ) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        queryParams +=
          `${queryParams.length == 0 ? "?" : "&"}` + key + "=" + value;
      }
    });
  }
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_PAYMENTS_LIST +
        `${queryParams.length > 0 ? queryParams : ""}`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function fetchInvoicesList(filters) {
  var queryParams = "";
  if (
    filters.invoiceID ||
    filters.ID ||
    filters.Status ||
    filters.MethodProvider ||
    filters.StartFrom ||
    filters.EndAt ||
    filters.pageSize ||
    filters.currentpage ||
    filters.ProductSourceID
  ) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        queryParams +=
          `${queryParams.length == 0 ? "?" : "&"}` + key + "=" + value;
      }
    });
  }
  store.dispatch("appState/startLoading");
  try {
    let response = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_INVOICES_API +
        `${queryParams.length > 0 ? queryParams : ""}`,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return response?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function processPayment(invoiceId, serviceCode, data) {
  store.dispatch("appState/startLoading");

  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + PROCESS_PAYMENT_API,
      "POST",
      JSON.stringify({ invoiceId, serviceCode, data }),
      false
    );
    store.dispatch("appState/stopLoading");
    return res?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function completePayment(
  serviceProvider,
  paymentReference,
  sessionId,
  action,
  orderReference
) {
  store.dispatch("appState/startLoading");

  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        COMPLETE_PAYMENT_API +
        `/${serviceProvider}?paymentReference=${paymentReference}&sessionId=${sessionId}&action=${action}&orderReference=${orderReference}`,
      "GET",
      null,
      false
    );
    // store.dispatch("appState/stopLoading");
    return res?.json();
  } finally {
    // store.dispatch("appState/stopLoading");
  }
}

// async function createCategory(title) {
// 	store.dispatch("appState/startLoading");
// 	try {
// 		let requestBody = JSON.stringify({ title: title, isVisible: true });
// 		let body = await ApiHelpers.makeAPICall(
// 			FETCH_POST_PUT_DELETE_CATEGORIES_API,
// 			"POST",
// 			requestBody,
// 			false
// 		);
// 		store.dispatch("appState/stopLoading");
// 		return body?.json();
// 	} finally {
// 		store.dispatch("appState/stopLoading");
// 	}
// }

// async function updateCategory(updatedObject) {
// 	store.dispatch("appState/startLoading");
// 	try {
// 		let requestBody = JSON.stringify(updatedObject);
// 		let body = await ApiHelpers.makeAPICall(
// 			FETCH_POST_PUT_DELETE_CATEGORIES_API,
// 			"PUT",
// 			requestBody,
// 			false
// 		);
// 		store.dispatch("appState/stopLoading");
// 		return body?.json();
// 	} finally {
// 		store.dispatch("appState/stopLoading");
// 	}
// }
