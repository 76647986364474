import store from "../store/index";
import { SYSTEM_PARAMS_API, EXTERNAL_SERVICES_API, POST_SERVICES_API } from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

// const apiURL = store.state.appState.apiURL;

export const appService = {
	getSystemParameters,
	editSystemParameters,
	getExternalServices,
	createExternalServices
};

async function getSystemParameters() {
	store.dispatch("appState/startLoading");
	// const requestOptions = { ...REQUEST_OPTIONS };
	try {
		const response = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + SYSTEM_PARAMS_API,
			"GET",
			null,
			false
		);
		// const response = await fetch(SYSTEM_PARAMS_API, requestOptions);
		// if (!response.ok) throw new Error(response.statusText);
		// let body = await response.json();
		store.dispatch("appState/stopLoading");
		return response.json();
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function editSystemParameters(params) {
	store.dispatch("appState/startLoading");
	// const requestOptions = { ...REQUEST_OPTIONS };
	try {
		const response = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + SYSTEM_PARAMS_API,
			"POST",
			JSON.stringify(params),
			false
		);
		store.dispatch("appState/stopLoading");
		return response.json();
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function getExternalServices(type) {
	store.dispatch("appState/startLoading");

	try {
		const response = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + EXTERNAL_SERVICES_API + `/${type}`,
			"GET",
			null,
			false
		);
		// const response = await fetch(SYSTEM_PARAMS_API, requestOptions);
		// if (!response.ok) throw new Error(response.statusText);
		// let body = await response.json();
		store.dispatch("appState/stopLoading");
		return response.json();
	} finally {
		store.dispatch("appState/stopLoading");
	}
}
async function createExternalServices(type,provider,data) {
	store.dispatch("appState/startLoading");

	try {
		const response = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + POST_SERVICES_API + `/${type}/${provider}`,
			"POST",
			JSON.stringify(data),
			false
		);
		// const response = await fetch(SYSTEM_PARAMS_API, requestOptions);
		// if (!response.ok) throw new Error(response.statusText);
		// let body = await response.json();
		store.dispatch("appState/stopLoading");
		return response.json();
	} finally {
		store.dispatch("appState/stopLoading");
	}
}
