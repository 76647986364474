<template>
	<b-modal
		@change="closeModal"
		id="add-question-modal"
		:visible="visible"
		size="xl"
		hide-header
		hide-footer
		centered
		content-class="add-question-modal"
		body-class="modal-body"
		modal-class="custom-modal"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="custom-stepper-body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-content-title">Add Question</h1>
			<div class="add-question-container">
				<div
					v-if="this.examThemes && this.examThemes.length > 1"
					class="form-group"
				>
					<label for="theme" class="main-label"> Theme (Required)</label>
					<div class="status-cards exam-type">
						<div
							v-for="(themeOption, index) in examThemes"
							:key="index"
							class="status-card-container col"
						>
							<input
								type="radio"
								name="theme"
								:value="themeOption"
								:id="`theme-${themeOption.themeID}`"
								v-model="theme"
								@change="isEditMode && update($event)"
							/>
							<label class="status-card" :for="`theme-${themeOption.themeID}`">
								<p class="m-0 text-center">{{ themeOption.themeName }}</p>
							</label>
						</div>
					</div>
				</div>
				<div v-if="theme" class="form-group">
					<label for="type" class="main-label"> Question Type (required)</label>
					<div class="status-cards exam-type">
						<div
							v-for="(typeOption, index) in theme.questionTypes"
							:key="index"
							class="status-card-container col"
						>
							<input
								type="radio"
								name="type"
								:value="typeOption.questionTypeID"
								:id="`type-${typeOption.questionTypeID}`"
								v-model="type"
								@change="isEditMode && update($event)"
							/>
							<label
								class="status-card"
								:for="`type-${typeOption.questionTypeID}`"
							>
								<p class="m-0 text-center">{{ typeOption.questionTypeName }}</p>
							</label>
						</div>
					</div>
				</div>
				<!-- <div class="form-group mb-3">
					<label for="title" class="main-label"
						>Question Title (Optional)
					</label>
					<input
						type="search"
						class="form-input"
						id="title"
						name="title"
						v-model="title"
						placeholder="Enter question title"
					/>
				</div> -->
			</div>
			<div class="step-btn-container">
				<a @click="closeModal" role="button" class="step-btn">Cancel </a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					Add <i class="fas fa-long-arrow-alt-right"></i>
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "AddQuestionModal",
	data() {
		return {
			title: null,
			type: null,
			isEditMode: false,
			theme: null,
		};
	},
	methods: {
		...mapActions("exam", ["createQuestion", "fetchExamThemes"]),
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		next() {
			this.createQuestion(this.productVariantId).then((res) => {
				console.log(res);
				var token = res;
				var categoryID;
				if (this.selectedCategory && this.selectedCategory.length > 0) {
					categoryID = this.selectedCategory.at(-1).id;
				} else {
					categoryID = this.questionCategories[this.productVariantId].root.id;
				}
				var examinationURL = `http://examapp.gocbeglobal.com/question/create?QuestionTypeID=${this.type}&CategoryID=${categoryID}&Token=${token}&RedirectURL=${window.location.href}`;
				if (this.title) {
					examinationURL += `&QuestionTitle=${this.title}`;
				}
				console.log(examinationURL);
				window.location.href = examinationURL;
			});
		},
	},
	props: {
		showModal: Boolean,
		productVariantId: String,
		selectedCategory: Array,
	},
	computed: {
		...mapState("exam", [
			"questionTypes",
			"examThemes",
			"loading",
			"questionCategories",
		]),
		visible() {
			return this.showModal;
		},
		disableNext() {
			if (this.type && this.theme) {
				return false;
			} else return true;
		},
	},
	watch: {
		visible: function () {
			if (this.visible) {
				if (this.examThemes && this.examThemes.length == 0) {
					this.fetchExamThemes().then((res) => {
						if (res && res.length == 1) {
							this.theme = res[0];
						}
					});
				} else {
					if (this.examThemes && this.examThemes.length == 1) {
						this.theme = this.examThemes[0];
					}
				}
			}
		},
	},
};
</script>

<style>
.add-question-modal {
	min-height: 80vh;
	height: fit-content;
	width: 60vw;
}
#add-question-modal.custom-modal .modal-dialog {
	max-width: 60vw;
}

#add-question-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.add-question-modal .modal-body .custom-stepper-body-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 2.5rem 1.5rem;
	position: relative;
	border-radius: 0 8px 8px 0;
}

.add-question-container {
	margin-top: 1.625rem;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.add-question-container .status-cards {
	flex-wrap: wrap;
	padding: 1rem 0;
	row-gap: 0.75rem;
	column-gap: 0.75rem;
}

.add-question-container .status-cards .status-card-container {
	min-width: 18% !important;
}
</style>
