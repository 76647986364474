<template>
	<b-modal
		id="user-devices-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="user-devices-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<h1 class="add-content-title">Your Devices</h1>
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<div id="page1">
				<p>
					Remove an existing device from the list below to login from this
					device.
				</p>
				<div class="table-container">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Device Info</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="device in devices" :key="device.id">
								<!-- <tr> -->
								<td class="user-device-item">
									<div class="img-container">
										<img
											:src="deviceImage(device.platform.toLowerCase())"
											alt=""
											class="user_device_image"
										/>
									</div>
									<span class="d-flex align-items-center"
										>{{ device.brandName ? device.brandName : "Other |" }}
										{{ device.model ? device.model : "Other |" }}
										{{ Platforms[device.platform.toLowerCase()] }}</span
									>
								</td>
								<td class="text-danger align-middle">
									<div>
										<button :id="device.id" @click="removeUserDevice">
											Remove
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- {{ device.id }} -->
				<!-- </div> -->
				<div
					v-if="loading"
					class="d-flex justify-content-center gap-3 no-content"
				>
					<b-spinner></b-spinner>
					Loading...
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Platform, Platforms } from "../../constants/platforms";

export default {
	name: "UserDevicesModal",
	data() {
		return {
			devices: [],
			Platforms,
		};
	},
	props: { showModal: Boolean, username: String },
	computed: {
		...mapState("user", ["userDevices", "loading"]),
		visible() {
			return this.showModal;
		},
	},
	components: {},
	methods: {
		...mapActions("user", ["fetchUserDevices", "resetUserDeviceById"]),
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		deviceImage(platform) {
			if (platform == Platform.DESKTOP || platform == Platform.DESKTOP_WEB) {
				return require("@/assets/images/desktop.png");
			} else if (
				platform == Platform.ANDROID ||
				platform == Platform.MOBILE_WEB
			) {
				return require("@/assets/images/android.png");
			} else if (platform == Platform.IOS) {
				return require("@/assets/images/ios.png");
			}
		},
		removeUserDevice(e) {
			var deviceID = e.target.id;
			console.log("deviceid", deviceID);
			var deviceData = {
				username: this.username,
				deviceID: deviceID,
			};

			this.resetUserDeviceById(deviceData)
				.then(() => {
					console.log("device removed", e.target.id);
					this.devices = this.devices.filter((device) => device.id != deviceID);
					this.$emit("resetSuccess");
					this.closeModal();
					this.$toasted.success(
						`Device removed successfully. Logging you in...`,
						{
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						}
					);
				})
				.catch((error) => {
					this.$toasted.error(error.message, {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
					console.log(error);
				});
		},
	},
	created() {},
	watch: {
		visible: function () {
			if (this.visible)
				this.fetchUserDevices({ username: this.username }).then((res) => {
					console.log("devices fetched");
					this.devices = res;
				});
		},
	},
};
</script>

<style>
@media screen and (max-width: 600px) {
	.user-devices-modal {
		min-height: unset !important;
	}
}
.user-devices-modal {
	min-height: 45.375rem;
	width: 70vw;
}
/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.user-devices-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem !important;
}
.user-devices-modal .body-container {
	display: flex;
	flex-direction: column;
	padding: 2.5rem 2.5rem 1.5rem;
	width: 100%;
	font-size: 1.125rem;
}
.user-devices-modal .body-container #page1 .table-container {
	max-height: 32rem !important;
	overflow-y: auto;
}

.user-devices-modal .add-content-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}

.user-device-item {
	height: 7rem;
	display: flex;
	column-gap: 1rem;
}

.user-device-item i {
	font-size: 2rem;
}
.user-device-item .img-container {
	width: 5rem;
	align-items: center;
	justify-content: center;
	display: flex;
}

.user_device_image {
	max-height: 5rem;
}
</style>
