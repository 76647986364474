<template>
	<!-- <sidebar-menu :menu="menu" /> -->
	<div class="page-container">
		<div
			v-if="this.$route.meta.isHideAdminLayout!=true"
			id="admin-sidebar"
			:class="['custom-sidebar', { collapsed: collapsed }]"
		>
			<ul class="sidebar-list">
				<li
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'CourseManagement' },
					]"
				>
					<router-link to="/management/course" class="link" role="button">
						<object
							:data="require('@/assets/images/course-management-icon.svg')"
							type="image/svg+xml"
							class="sidebar-item-icon color-change-svg"
						></object>
						<!-- <img
							src="@/assets/images/course-management-icon.svg"
							class="sidebar-item-icon"
						/> -->
						<span v-if="!collapsed">Course Management</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'UsersManagement' },
					]"
				>
					<router-link to="/management/users" class="link" role="button">
						<object
							:data="require('@/assets/images/admission-processing-icon.svg')"
							type="image/svg+xml"
							class="sidebar-item-icon color-change-svg"
						></object>
						<!-- <img
							src="@/assets/images/admission-processing-icon.svg"
							class="sidebar-item-icon"
						/> -->
						<span v-if="!collapsed">Users Management</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'InvoiceManagement' },
					]"
				>
					<router-link to="/management/invoices" class="link" role="button">
						<i class="fal fa-receipt sidebar-item-icon primary"></i>
						<span v-if="!collapsed">Invoices</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'PaymentManagement' },
					]"
				>
					<router-link to="/management/payments" class="link" role="button">
						<i class="fal fa-cash-register sidebar-item-icon primary"></i>
						<span v-if="!collapsed">Payments</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'SystemPreferences' },
					]"
				>
					<router-link to="/management/preferences" class="link" role="button">
						<i class="fal fa-cogs sidebar-item-icon primary"></i>
						<span v-if="!collapsed">System Preferences</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'AttendanceWithBarcode' },
					]"
				>
					<router-link
						to="/management/barcode-attendance"
						class="link"
						role="button"
					>
						<i class="fal fa-qrcode sidebar-item-icon primary"></i>
						<span v-if="!collapsed">Attendance With QR Code</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'BulkOperations' },
					]"
				>
					<router-link
						to="/bulk-operations"
						class="link"
						role="button"
					>
						<i class="fal fa-users-class sidebar-item-icon primary"></i>
						<span v-if="!collapsed">Bulk Operations</span>
					</router-link>
				</li>
				<li
					v-if="!isTrainer"
					:class="[
						'sidebar-list-item',
						{ active: activeTab == 'Support' },
					]"
				>
					<router-link
						to="/management/Support"
						class="link"
						role="button"
					>	<i class="fal fa-user-headset sidebar-item-icon primary"></i>
					
						<span v-if="!collapsed">Support Tickets</span>
					</router-link>
				</li>
			</ul>
		</div>
		<div :class="['page-content', { collapsed: collapsed }]">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	name: "AdminSidebar",
	data() {
		return {
			activeTab: "CourseManagement",
			courseIcon: require("@/assets/images/course-management-icon.svg"),
			barcodeScanner: false,
		};
	},
	props: { collapsed: Boolean },
	computed: {
		...mapState("user", ["user"]),
		...mapState("appState", ["systemParameters"]),
		isTrainer() {
			if (
				(this.user.type && this.user.type.toLowerCase() == "trainer") ||
				(this.user.role && this.user.role.toLowerCase() == "trainer")
			)
				return true;
			return false;
		},
	},
	watch: {
		$route: function () {
			this.activeTab =
				this.$route.meta && this.$route.meta.activeTab
					? this.$route.meta.activeTab
					: null;
		},
	},
	created() {
		this.activeTab =
			this.$route.meta && this.$route.meta.activeTab
				? this.$route.meta.activeTab
				: null;
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				var svg = document.querySelectorAll(".color-change-svg");
				// console.log(svg);
				for (var j = 0; j < svg.length; j++) {
					var data = svg[j].contentDocument;
					var elements = data.querySelectorAll(".primary-color");
					console.log(elements);
					for (var i = 0; i < elements.length; i++) {
						elements[i].attributes[1].value =
							this.systemParameters.Theme.PrimaryColor;
						console.log(elements[i].attributes[1].value);
					}
				}
			}, 300);
		});
	},
	methods: {
		showBarcodeScanner() {
			this.barcodeScanner = true;
		},
	},
};
</script>

<style scoped>
.custom-sidebar {
	background-color: #ffffff;
	box-shadow: 6px 3px 6px 0px #b9b9b912;
	height: calc(100vh - 5rem);
	padding: 1.5rem;
	z-index: 100;
	transition: all 0.3s ease;
}

.custom-sidebar.collapsed {
	width: 6.25rem;
}

.custom-sidebar:not(.collapsed) {
	width: 20.5rem;
}

.page-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.page-content {
	/* flex-grow: 1; */
	overflow-y: auto;
	width: calc(100vw - 20.5rem);
	transition: width 0.3s ease;
}

.page-content.collapsed {
	width: calc(100vw - 6.25rem);
}

.sidebar-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.sidebar-list-item {
	background-color: #ffffff;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.1);
	border: 1px solid #dcdcdc;
	border-radius: 10px;
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.25rem;
	margin-bottom: 1.75rem;
	transition: all 0.3s ease;
	display: flex;
}

.sidebar-list-item:hover {
	box-shadow: 4px 6px 8px 0px rgb(197 197 197 / 20%);
}
.sidebar-list-item.active {
	box-shadow: 4px 6px 8px 0px rgb(197 197 197 / 20%);
	border: 1px solid var(--primary-color);
}

.custom-sidebar.collapsed .sidebar-list-item {
	min-width: 3.125rem;
	min-height: 3.125rem;
	align-items: center;
	justify-content: center;
}

.custom-sidebar.collapsed .sidebar-list-item .link {
	padding: 0.5rem;
	justify-content: center;
}

.sidebar-list-item .link {
	display: flex;
	align-items: center;
	padding: 0.875rem;
	width: 100%;
	color: #000000;
	text-decoration: none;
}

/* .sidebar-list-item .link.router-link-active {
	border-color: var(--primary-color);
} */

.sidebar-item-icon.primary {
	color: var(--primary-color);
}

.sidebar-item-icon {
	font-size: 1.375rem;
	margin-right: 1rem;
}

.custom-sidebar.collapsed .sidebar-item-icon {
	margin-right: 0;
}
</style>
