<template>
	<div class="question-card">
		<div class="title-container">
			<span class="question-id text-muted text-bold">{{
				questionData.ID
			}}</span>
			<h4 class="question-title">{{ questionData.QuestionTitle }}</h4>
			<div class="ms-auto d-flex gap-2 align-items-center">
				<span class="question-marks text-bold text-muted">
					{{ questionData.QuestionMarks }}
					{{
						parseFloat(questionData.QuestionMarks) == 1 ? "mark" : "marks"
					}} </span
				>|
				<span class="question-difficulty">{{
					questionData.DifficultyLevel
				}}</span>
				|
				<div class="question-type-pill">
					{{ QuestionTypeText[questionData.QuestionType.QuestionTypeName] }}
				</div>
			</div>
		</div>
		<div class="question-content-container">
			<div class="d-flex flex-column flex-grow-1 question-content">
				<div
					v-html="questionData.QuestionText"
					:class="['question-text m-0', { 'show-all': showAllQuestionText }]"
				></div>
				<button
					@click="toggleShowAll"
					v-if="questionData.QuestionText.length > 200"
					class="d-flex align-items-center gap-1 ms-auto text-muted mt-2"
				>
					Show {{ showAllQuestionText ? "Less" : "More" }}
					<i :class="`fa fa-caret-${showAllQuestionText ? 'up' : 'down'}`"></i>
				</button>
				<!-- <div
					v-if="!viewOnly"
					class="d-flex pt-2 mt-2 gap-3 question-actions-container"
				>
					<button class="d-flex align-items-center gap-1">
						Answers <i class="fa fa-caret-down"></i>
					</button>
				</div> -->
			</div>
			<div class="right-menu" v-if="!selectMode">
				<a role="button" class="right-menu-icon">
					<img src="@/assets/images/edit-icon.svg" @click="showModal" />
				</a>

				<a
					role="button"
					class="right-menu-icon delete"
					@click="showDeleteConfirmPopup"
				>
					<img src="@/assets/images/delete-icon.svg" />
				</a>
			</div>
			<div class="right-menu" v-if="selectMode && showDelete">
				<a
					role="button"
					class="right-menu-icon delete"
					@click="$emit('removeQuestion')"
				>
					<img src="@/assets/images/delete-icon.svg" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { QuestionTypeText } from "../../../constants/question";
import { mapActions, mapState } from "vuex";

export default {
	name: "QuestionListItem",
	data() {
		return {
			showAllQuestionText: false,
			QuestionTypeText,
		};
	},
	methods: {
		...mapActions("exam", ["deleteQuestion"]),
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete this Question: ${this.questionData.QuestionTitle}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.questionDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		showModal() {},
		toggleShowAll() {
			this.showAllQuestionText = !this.showAllQuestionText;
		},
		questionDelete() {
			this.deleteQuestion(this.questionData.ID).then((res) => {
				console.log("Question deleted successfully");
			});
		},
	},
	props: {
		selectMode: { type: Boolean, default: false },
		showDelete: { type: Boolean, default: false },
		questionData: Object,
	},
};
</script>

<style scoped>
.question-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
}

.question-card .title-container {
	padding: 1rem;
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
}

.question-title {
	margin: 0;
	padding: 0;
}

.question-type-pill {
	padding: 0.25rem 0.75rem;
	font-size: 0.75rem;
	border: 1px solid var(--primary-color);
	border-radius: 1rem;
	display: flex;
}

.question-content-container {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: flex-start;
}

.question-content-container .question-content {
	overflow-x: hidden;
	/* max-width: 80%; */
}

.question-content-container .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	column-gap: 1rem;
	margin-left: auto;
	/* margin-right: 0.75rem; */
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.question-card:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.right-menu-icon {
	width: 1.75rem;
	height: 1.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.right-menu-icon img {
	width: 0.875rem;
	height: 0.875rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

.question-text {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	transition: all 0.3s ease;
}

.question-text.show-all {
	-webkit-line-clamp: unset;
}

.question-actions-container {
	border-top: 1px solid #eee;
}
/* 
.question-difficulty {
	color: var(--color-warning);
} */
</style>
