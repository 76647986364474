<template>
    <div>
        <div :id="'mobile-pdf-viewer-'"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            adobeApiReady: false
        };
    },
    props: {
        apiKey: String,
        source: String,
        fileName: String
    },
    created() {
    },
    mounted() {
        if (window.AdobeDC) {
            this.adobeApiReady = true
        } else {
            document.addEventListener('adobe_dc_view_sdk.ready', () => {
                this.adobeApiReady = true
            });
        }

    },
    watch: {
        adobeApiReady: function () {
            if (this.adobeApiReady) {
                const config = {
                    showZoomControl: true,
                    hasReadOnlyAccess: true,
                    showAnnotationTools: false,
                    enableFormFilling: false,
                    showDownloadPDF: true,
                    showPrintPDF: false,
                    showBookmarks: false
                }

                var adobeDCView = new AdobeDC.View({ clientId: this.apiKey, divId: "mobile-pdf-viewer-" });
                adobeDCView.previewFile(
                    {
                        content: { location: { url: this.source } },
                        metaData: {fileName: this.fileName}
                    }, config);
            }
        }
    },
    methods: {
    }
}
</script>

<style></style>