import helpers from "../../helpers/CookieHelpers";
import defaultRouteSettings from "./default";

const routes = defaultRouteSettings.routes.map((route) => {
	if (route.path == "/") {
		route.redirect = "/account/login";
	}
	return route;
});

function beforeEach(to, from, next) {
	let user = JSON.parse(localStorage.getItem("user"));
	if (to.meta && to.meta.title) {
		window.document.title = to.meta.title;
	}
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		//if route requires auth
		if (helpers.checkCookie("token") == false) {
			//if user is not logged in
			next({
				path: "/account/login", //redirect to the login page
				query: { nextUrl: to.path }, //then continue to requested page
			});
		} else {
			// console.log("checking for user");
			if (to.matched.some((record) => record.meta.isAdmin)) {
				//if route requires admin user
				var userType = user.type || user.role;
				// console.log(userType);
				if (userType && userType.toLowerCase() != "student") {
					//if user is admin
					next();
				} else {
					next({ name: "Dashboard" }); //redirect to dashboard
				}
			} else {
				//if route doesnot requires admin user
				next();
			}
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		//if route is accessible to guest\
		if (
			to.matched.some((record) => record.name.toLowerCase() == "login") &&
			user
		) {
			console.log("login page visited after auth");
			var userType = user.type || user.role;
			// console.log(userType);
			if (userType && userType.toLowerCase() != "student") {
				//if user is admin
				next({ path: "/management/course" });
			} else {
				next({ path: "/user/dashboard" }); //redirect to dashboard
			}
		}
		next();
		// }
	} else {
		//if route does not require user to login
		next();
	}
}

export default { routes, beforeEach };
