<template>
	<div class="container">
		<div class="signup-steps">
			<h1 class="title"><span>3</span> Steps away from signup</h1>
			<div class="signup-steps-container">
				<div class="signup-step">
					<div class="icon-box first">
						<object
							:data="require('@/assets/images/step1.svg')"
							alt=""
							type="image/svg+xml"
							class="color-change-svg"
						/>
					</div>
					<p class="label">Step 1</p>
					<p class="action">Click to Signup</p>
				</div>
				<div class="signup-step">
					<div class="icon-box second">
						<object
							:data="require('@/assets/images/step2.svg')"
							alt=""
							type="image/svg+xml"
							class="color-change-svg"
						/>
					</div>
					<p class="label">Step 2</p>
					<p class="action">Fill Basic Info</p>
				</div>
				<div class="signup-step">
					<div class="icon-box third">
						<object
							:data="require('@/assets/images/step3.svg')"
							alt=""
							type="image/svg+xml"
							class="color-change-svg"
						/>
					</div>
					<p class="label">Step 3</p>
					<p class="action">Start Learning</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "HomePageSignupSteps",
	computed: {
		...mapState("appState", ["systemParameters"]),
	},
	mounted() {
		this.$nextTick(() => {
			setTimeout(() => {
				var svg = document.querySelectorAll(".color-change-svg");
				// console.log(svg);
				for (var j = 0; j < svg.length; j++) {
					var data = svg[j].contentDocument;
					if (data) {
						var primary = data.querySelectorAll(".primary-color");
						var secondary = data.querySelectorAll(".secondary-color");
						// console.log(primary);
						for (var i = 0; i < primary.length; i++) {
							primary[i].attributes["fill"].value =
								this.systemParameters.Theme.PrimaryColor;
						}
						for (var i = 0; i < secondary.length; i++) {
							secondary[i].attributes["fill"].value =
								this.systemParameters.Theme.SecondaryColor;
						}
					}
				}
			}, 300);
		});
	},
};
</script>

<style scoped>
@media screen and (max-width: 1400px) {
	.signup-step .icon-box {
		width: 20rem !important;
	}
	.signup-step .icon-box img {
		height: 148px !important;
	}
}

@media screen and (max-width: 1200px) {
	.signup-step .icon-box {
		width: 18rem !important;
		padding: 3.5rem !important;
	}
	.signup-step .icon-box img {
		height: 120px !important;
	}
}

@media screen and (max-width: 996px) {
	.signup-step .icon-box {
		width: 15rem !important;
		padding: 2.5rem !important;
	}
	.signup-step .icon-box object {
		height: 100px !important;
	}
	.signup-steps {
		padding: 3rem !important;
	}
}

@media screen and (max-width: 767px) {
	.signup-steps .signup-steps-container {
		flex-direction: column !important;
	}

	.signup-steps {
		padding: 3rem !important;
	}

	.signup-step {
		margin-bottom: 1rem !important;
	}
}

@media screen and (max-width: 480px) {
	.signup-step .icon-box {
		padding: 2.5rem !important;
		width: 280px !important;
	}

	.signup-step .icon-box img {
		height: 148px !important;
	}

	.signup-steps {
		margin: 0 -1rem !important;
	}
}

.signup-steps {
	margin-top: 9.375rem;
	background-color: #f5f5f5;
	box-shadow: 0 3px 6px #a8a8a829;
	padding: 3.125rem 6.25rem;
}

.signup-steps .title {
	font-size: 3.375rem;
	color: var(--secondary-color);
	font-weight: 500;
	text-align: center;
	margin-bottom: 3.125rem;
	letter-spacing: -0.55px;
}

.signup-steps .title span {
	color: var(--primary-color);
	font-size: 3.75rem;
}

.signup-steps .signup-steps-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.signup-step {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.signup-step .icon-box {
	background-color: #ffffff;
	border-radius: 1.875rem;
	box-shadow: 2px 2px 50px #a8a8a829;
	padding: 4.5rem;
	width: 23.5rem;
	display: flex;
	justify-content: center;
	position: relative;
	margin-bottom: 3.875rem;
}

.icon-box::before {
	content: "";
	width: 3.5rem;
	height: 3.5rem;
	bottom: -1.5rem !important;
	background-color: #f5f5f5;
	position: absolute;
	bottom: 0;
	border-radius: 50%;

	/* box-shadow: 2px 2px 50px #a8a8a829; */
}

.icon-box::after {
	content: "";
	position: absolute;
	bottom: -1rem;
	width: 2.625rem;
	height: 2.625rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	box-shadow: 0 3px 6px #00000029;
	color: #ffffff;
	font-weight: bold;
	font-size: 2.5rem;
}

.icon-box.first::after {
	content: "1";
}
.icon-box.second::after {
	content: "2";
}
.icon-box.third::after {
	content: "3";
}

.signup-step .icon-box object {
	height: 168px;
}

.signup-step .label {
	font-size: 1.25rem;
	color: #898989;
	letter-spacing: 0.5px;
	font-weight: normal;
}

.signup-step .action {
	font-size: 1.875rem;
	color: var(--secondary-color);
	font-weight: 500;
	margin-bottom: 0;
}
</style>
