import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import {
  FETCH_SUPPORT_TICKETS,
  FETCH_SERVICES_SUPPORT_TICKETS,
  POST_SUPPORT_TICKETS,
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const supportService = {
  fetchTickets,
  fetchTicketServices,
  createSupportTicket,
  fetchTicketByID,
  fetchTicketReplies,
  addSupportTicketReply,
  closedSupportTicket
};

async function fetchTickets(ticketServiceID='',status='') {
  console.log("in services");
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_SUPPORT_TICKETS+`?TicketServiceID=${ticketServiceID?ticketServiceID:''}&Status=${status?status:''}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}
async function fetchTicketByID(id) {
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_SUPPORT_TICKETS+`/${id}`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}

async function fetchTicketReplies(id) {
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_SUPPORT_TICKETS+`/${id}/Reply`,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}
async function fetchTicketServices() {
  console.log("in services");
  store.dispatch("appState/startLoading");
  const res = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + FETCH_SERVICES_SUPPORT_TICKETS,
    "GET",
    null,
    false
  ).then(handleResponse);
  return res;
}



async function createSupportTicket(supportTicket) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + POST_SUPPORT_TICKETS,
			"POST",
			JSON.stringify(supportTicket),
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}


async function addSupportTicketReply(supportTicketReply) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_SUPPORT_TICKETS +`/${supportTicketReply.ticketID}/Reply`,
			"POST",
			JSON.stringify(supportTicketReply),
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function closedSupportTicket(supportTicketID) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_SUPPORT_TICKETS +`/${supportTicketID}/Closed`,
			"POST",null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}



function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // 	// if (response.status == 401) {
    // 	console.log("status", response.status);
    // 	store.dispatch("user/regenerateUserToken", {
    // 		token: helpers.getCookie("token"),
    // 	});
    // return;
    // }
    throw new Error(response.status);
  } else {
    return response.json();
  }
}
