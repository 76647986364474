// import helpers from "../../helpers/CookieHelpers";
import defaultRouteSettings from "./default";

const routes = defaultRouteSettings.routes.map((route) => {
	if (route.path == "/") {
		route.redirect = "/account/login";
	}
	return route;
});

export default { routes, beforeEach: defaultRouteSettings.beforeEach };
