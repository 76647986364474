<template>
	<div class="mb-4 d-flex gap-5">
		<div class="settings-container">
			<h3 class="main-label">
				Logo
				<i class="far fa-info-circle sys-icon" id="tooltip-target-2"></i>
				<b-tooltip target="tooltip-target-2" triggers="hover">
					Recommended dimensions for logo is 160 x 56 px
				</b-tooltip>
			</h3>
			<div
				id="logo-container"
				:class="['upload-btn-wrapper', { preview: logoPath }]"
			>
				<img
					:class="[{ 'd-none': !logoPath }]"
					id="preview"
					:src="logoPath"
					alt="preview"
				/>
				<button
					v-if="!logoPath"
					type="button"
					class="upload-btn"
					@click="openLogoDialog"
				>
					upload
				</button>
				<input
					class="form-input"
					type="file"
					accept="image/*"
					name="file"
					id="logo-upload"
					@input="getLogoFile"
				/>
				<div v-if="!logoLoading && logoPath" class="right-menu">
					<a role="button" class="right-menu-icon" @click="openLogoDialog">
						<img src="@/assets/images/edit-icon.svg" />
					</a>
					<a
						role="button"
						class="right-menu-icon delete"
						@click="showDeleteConfirmPopup($event, 'Logo')"
					>
						<img src="@/assets/images/delete-icon.svg" />
					</a>
				</div>
				<b-spinner v-if="logoLoading" class="loader"> </b-spinner>
			</div>
		</div>
		<div class="settings-container">
			<h3 class="main-label">
				Favicon
				<i class="far fa-info-circle sys-icon" id="tooltip-target-3"></i>
				<b-tooltip target="tooltip-target-3" triggers="hover">
					A Favicon is the image that appears on the browser tab. File extension
					should be .ico
				</b-tooltip>
			</h3>
			<div
				id="favicon-container"
				:class="['upload-btn-wrapper', { preview: faviconPath }]"
			>
				<img
					:class="[{ 'd-none': !faviconPath }]"
					id="preview"
					:src="faviconPath"
					alt="preview"
				/>
				<button
					v-if="!faviconPath"
					type="button"
					class="upload-btn"
					@click="openFaviconDialog"
				>
					upload
				</button>
				<input
					class="form-input"
					type="file"
					accept=".ico"
					name="file"
					id="favicon-upload"
					@change="getFaviconFile"
				/>
				<div v-if="!faviconLoading && faviconPath" class="right-menu">
					<a role="button" class="right-menu-icon" @click="openFaviconDialog">
						<img src="@/assets/images/edit-icon.svg" />
					</a>
					<a
						role="button"
						class="right-menu-icon delete"
						@click="showDeleteConfirmPopup($event, 'Favicon')"
					>
						<img src="@/assets/images/delete-icon.svg" />
					</a>
				</div>
				<b-spinner v-if="faviconLoading" class="loader"> </b-spinner>
			</div>
		</div>		
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "LogoFaviconSettings",
	data() {
		return {
			logoPath: null,
			faviconPath: null,
			logoLoading: false,
			faviconLoading: false,			
		};
	},
	methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("appState", [
			"editSystemParameters",
			"fetchSystemParameters",			
		]),
		openLogoDialog() {
			$("#logo-upload").click();
		},
		openFaviconDialog() {
			$("#favicon-upload").click();
		},
		getLogoFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.logoPath = URL.createObjectURL(file);

				document.getElementById("preview").title = file.name;
				// this.thumbnailMediaType = file.type.split("/")[0];

				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "Logo");
				formData.append("type", "Image");				
				formData.append("IsCompressionRequired", true);

				console.log(...formData);
				this.logoLoading = true;
				this.uploadContentFile(formData)
					.then((data) => {
						console.log(data);
						this.logoPath = data;
						$("#logo-container .upload-btn-wrapper").addClass("success");
						this.updateLogo();
					})
					.catch((error) => {
						console.log(error);
						this.logoLoading = false;
					});
			}
			console.log("file: ", e.target.files);
		},
		getFaviconFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.faviconPath = URL.createObjectURL(file);

				document.getElementById("preview").title = file.name;
				// this.thumbnailMediaType = file.type.split("/")[0];

				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "Logo");
				formData.append("type", "Image");
				formData.append("IsCompressionRequired", true);

				console.log(...formData);
				this.faviconLoading = true;
				this.uploadContentFile(formData)
					.then((data) => {
						console.log(data);
						this.faviconPath = data;
						$("#favicon-container .upload-btn-wrapper").addClass("success");
						// if (this.isEditMode) {
						// this.updateObject.faviconPath = this.faviconPath;
						// this.updateObject.thumbnailMediaType = this.thumbnailMediaType;
						// }
						this.updateFavicon();
					})
					.catch((error) => {
						console.log(error);
						this.faviconLoading = false;
					});
			}
			console.log("file: ", e.target.files);
		},
		updateLogo() {
			this.editSystemParameters({
				parameterName: "LogoPath",
				parameterValue: this.logoPath,
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					console.log("logoPath updated");
					this.logoLoading = false;
					this.$toasted.success("Logo updated successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			});
		},
		updateFavicon() {
			this.editSystemParameters({
				parameterName: "FaviconPath",
				parameterValue: this.faviconPath,
			}).then(() => {
				this.fetchSystemParameters().then(() => {
					console.log("FaviconPath updated");
					this.faviconLoading = false;
					this.$toasted.success("Favicon updated successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				});
			});
		},
		showDeleteConfirmPopup(e, type) {
			e.preventDefault();
			this.$bvModal
				.msgBoxConfirm(`Are you sure you want to delete the ${type}?`, {
					title: "Are you sure?",
					size: "md",
					buttonSize: "md",
					okVariant: "danger",
					okTitle: "Yes",
					cancelTitle: "No",
					footerClass: "p-2",
					hideHeaderClose: false,
					centered: true,
					bodyClass: "confirm-modal-body",
				})
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						if (type.toLowerCase() == "logo") {
							this.logoPath = null;
							this.logoLoading = true;
							this.updateLogo();
						} else if (type.toLowerCase() == "favicon") {
							this.faviconPath = null;
							this.faviconLoading = true;
							this.updateFavicon();
						}
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},		
	},

	computed: {
		...mapState("appState", ["systemParameters"]),
	},
	components: {},
	// watch: {
	// 	logoPath: function () {
	// 		this.updateLogo();
	// 	},
	// },
	created() {
		if (this.systemParameters.FaviconPath) {
			this.faviconPath = this.systemParameters.FaviconPath;
		}
		if (this.systemParameters.LogoPath) {
			this.logoPath = this.systemParameters.LogoPath;
		}
	},
	watch: {
		systemParameters: function () {
			if (this.systemParameters.LogoPath) {
				this.logoPath = this.systemParameters.LogoPath;
			}
			if (this.systemParameters.FaviconPath) {
				this.faviconPath = this.systemParameters.FaviconPath;
			}
			// if (this.bannersList) this.bannersList.sort();
		},
	},
};
</script>

<style scoped>
.preferences-card .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}
.form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.5rem 0;
	/* max-width: 21.625rem; */
	/* width: 100%; */
	border-radius: 0;
	box-shadow: none !important;
	position: relative;
	background-color: transparent;
}

.settings-container .upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	background: transparent;
	box-shadow: none !important;
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	width: fit-content;
	align-items: center;
	max-width: unset;
	max-height: 6.5rem;
	border: 2px dashed #dddddd;
}

.settings-container .upload-btn-wrapper.fail {
	border: 1px solid var(--color-danger);
}

.settings-container .upload-btn-wrapper.success {
	border: 1px solid var(--color-success);
}

.settings-container .upload-btn-wrapper.preview {
	padding: 0;
	width: 10rem;
	height: 6.5rem;
	align-items: center !important;
	justify-content: center !important;
	border: 1px solid var(--primary-color);
}

#preview {
	max-height: 100%;
}

.upload-btn {
	padding: 0.5rem 1.25rem;
	font-weight: bold;
	cursor: pointer;
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 6px;
	text-transform: uppercase;
	color: #000000;
	font-weight: normal;
}

.upload-btn:hover,
.upload-btn:focus {
	background-color: var(--primary-color) !important;
	border-color: #ffffff !important;
	color: #ffffff !important;
}

.settings-container .upload-btn-wrapper input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	padding: 0 !important;
	cursor: pointer;
	width: 0 !important;
}

.settings-container .upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.settings-container .upload-btn-wrapper .loader {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	position: absolute;
}

.settings-container .upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.settings-container .upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.settings-container .upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.settings-container .upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

.settings-container .upload-btn-wrapper .right-menu-icon img {
	max-height: 1rem;
}
</style>
