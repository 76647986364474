class ProductVariant {
	constructor(data) {
		// console.log({ dataInConstructor: data.properties });
		this.id = data.productVariantID;
		this.title = data.productVariantTitle;
		if (data.properties && data.properties.tutor) {
			this.creator = {
				imagePath: data.properties.tutor.imagePath,
				name: data.properties.tutor.name,
				description: data.properties.tutor.description,
			};
		}
		this.pricing = {
			currency: data.properties.pricing.currency,
			amount: data.properties.pricing.amount,
		};
		this.ratings = {
			value: data.properties.ratings.value,
			numberOfRatings: data.properties.ratings.numberOfRatings,
		};
		this.tagline = data.properties.tagline;
		this.shortDescription = data.shortDescription;
		this.longDescription = data.longDescription;
		this.startDate = data.startDate;
		this.endDate = data.endDate;
		this.isVisible = data.productVariantIsVisible;
		this.isFeatured = data.isFeaturedProductVariant;
		this.productVariantItems = data.productVariantItems;
		this.thumbnailType = data.productVariantThumbnailType;
		this.thumbnailPath = data.productVariantThumbnailPath;
		if (data.product) {
			this.product = {
				id: data.product.productID,
				title: data.product.productTitle,
				isFeatured: data.product.isFeaturedProduct,
				thumbnailType: data.product.productThumbnailType,
				thumbnailPath: data.product.productThumbnailPath,
				friendlyTitle: data.product.productFriendlyTitle,
			};
		}
		if (data.properties.features) {
			this.features = JSON.parse(data.properties.features);
			// console.log({ dataInConstructor: this.features });
		} else this.features = null;
		// this.features = [
		// 	"32 hours on-demand video",
		// 	"106 articles",
		// 	"47 downloadable resources",
		// 	"Full lifetime access",
		// 	"Access on mobile and TV",
		// 	"Certificate of Completion",
		// ];
		if (data.properties.objectives) {
			this.objectives = JSON.parse(data.properties.objectives);
		} else this.objectives = null;
		// this.objectives = [
		// 	"Learn to use Python professionally, learning both Python 2 and Python 3!",
		// 	"Learn advanced Python features, like the collections module and how to work with timestamps!",
		// 	"Understand complex topics, like decorators.",
		// 	"Get an understanding of how to create GUIs in the Jupyter Notebook system!",
		// 	"Create games with Python, like Tic Tac Toe and Blackjack!",
		// 	"Learn to use Object Oriented Programming with classes!",
		// 	"Understand how to use both the Jupyter Notebook and create .py files",
		// 	"Get an understanding of how to create GUIs in the Jupyter Notebook system!",
		// ];
		this.enrolledStudents = 1369312;
		this.subscriptionStatus = data.subscriptionStatus;
		this.sequence = data.sequence;
		this.status = (data.status || data.productVariantStatus) ?? null;
		this.pricingStatus = data.pricingStatus ? data.pricingStatus : "Free";
		this.properties = data.properties ? data.properties : null;
		this.enrollmentType = data.enrollmentType ?? null;
		this.accessDates = data.accessDates ?? null;
		this.expiresAt = data.expiresAt ?? null;
	}
}

export default ProductVariant;
