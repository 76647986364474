<template>
	<div class="create-content-item">
		<input
			:type="mode == 'edit' ? 'text' : 'button'"
			name="content"
			placeholder="Type here your subject name ..."
			:class="['create-content-input', { created: isCreated }]"
			v-model="title"
			v-on:keyup.esc="title = ''"
			v-on:keyup.enter="createContent"
		/>
		<b-spinner
			v-if="loading && mode == 'created'"
			class="content-loader"
		></b-spinner>
		<button
			@click="createContent"
			v-if="showSave"
			class="content-save-btn"
			type="button"
			role="button"
			:disabled="loading"
		>
			save
		</button>
		<div v-if="areTrainersAssigned && !loading" class="trainer-icons">
			<button
				v-for="(trainer, index) in assignedTrainers"
				:key="index"
				class="user-avatar"
				:title="
					`${trainer.firstName} ${
						trainer.lastName
					} ( Permissions: ${trainer.permissions.toString()} )`
				"
			>
				{{ trainer.email[0].toUpperCase() }}
			</button>
		</div>
		<div v-if="!loading" class="assign-btn-container">
			<button
				v-if="showAssign"
				data-bs-toggle="dropdown"
				class="assign-trainer-btn dropdown-toggle"
				role="button"
				:id="`assign-trainer-btn-${id}`"
			>
				{{ areTrainersAssigned ? "update" : "assign" }}
			</button>
			<AssignTrainerToContentDropdown
				@assignTrainers="assign"
				:productVariantId="id"
				:assignedTrainers="assignedTrainers"
				:areTrainersAssigned="areTrainersAssigned"
			/>
		</div>
		<button
			@click="deleteContent()"
			v-if="showDelete && !loading"
			class="content-delete-btn"
			type="button"
			role="button"
		>
			<img src="@/assets/images/delete-icon.svg" />
		</button>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AssignTrainerToContentDropdown from "../Trainer/AssignTrainerToContentDropdown.vue";
import { v4 as uuidv4 } from "uuid";
export default {
	name: "ProductVariantContentCreateItem",
	data() {
		return {
			id: "0",
			title: "",
			// mode: this.$props.mode,
			isCreated: this.$props.mode == "created",
			areTrainersAssigned: false,
			assignedTrainers: null,
		};
	},
	components: { AssignTrainerToContentDropdown },
	props: {
		mode: String,
		contentData: Object,
		availableTo: String,
		availableFrom: String,
	},
	computed: {
		...mapState("content", ["content", "loading"]),
		showSave() {
			return !this.isCreated && this.title !== "";
		},
		showDelete() {
			return this.isCreated;
		},
		showAssign() {
			return this.isCreated;
		},
	},
	methods: {
		...mapActions("content", ["createDirectoryContent"]),
		createContent() {
			console.log("save clicked");
			var contentObject = {
				title: this.title,
				// availableTo: this.availableTo,
				// availableFrom: this.availableFrom,
				isAvailableForDemo: false,
				// isNew: true,
			};
			if (this.availableFrom) contentObject.availableFrom = this.availableFrom;
			if (this.availableTo) contentObject.availableTo = this.availableTo;
			// this.createDirectoryContent(contentObject).then((id) => {
			this.$emit("addContent", { id: this.id, ...contentObject });
			this.title = "";
			this.id = "";
			// console.log(
			var len = document.querySelectorAll(".create-content-input").length;
			var div = document.querySelectorAll(".create-content-input")[len - 1];
			console.log(div);
			div.scrollIntoView();

			// );
			// });
			// this.mode = "created";
			// this.isCreated = true;
		},
		deleteContent() {
			console.log("delete clicked");
			// var currentElemIndex = e.target;

			// this.mode = "created";
			// this.isCreated = true;
			this.$emit("deleteContent", this.id);
		},
		assign(trainers) {
			var assignTrainers = {
				id: this.id,
				trainers: trainers,
			};
			console.log(assignTrainers);
			this.areTrainersAssigned = true;
			this.assignedTrainers = trainers;
			this.$emit("assignToContent", assignTrainers);
		},
	},
	created() {
		if (this.contentData) {
			this.title = this.contentData.title;
			this.id = this.contentData.id;
			if (this.contentData.trainers) {
				this.assignedTrainers = this.contentData.trainers;
				if (this.assignedTrainers.length > 0) {
					this.areTrainersAssigned = true;
				}
			}
		} else {
			// this.id = uuidv4();
		}
	},
	watch: {
		showSave: function() {
			this.id = `new-${uuidv4()}`;
		},
	},
};
</script>

<style scoped>
.create-content-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	width: 100%;
	margin-bottom: 1.25rem;
}

.create-content-input {
	height: 3.125rem;
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 8px;
	padding: 0.875rem 1.5rem;
	font-size: 1.125rem;
	color: #000000;
	width: 100%;
	text-align: left;
	line-height: 1;
}

.create-content-input:focus,
.create-content-input.created {
	border-color: #000000;
}

.content-save-btn {
	background: #ffffff;
	border: 1px solid #21cf47;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 0.375rem 0.875rem;
	color: #21cf47;
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	position: absolute;
	right: 0.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	/* top: 0.875rem; */
}

.content-loader {
	position: absolute;
	right: 2rem;
	border-color: var(--primary-color);
	border-right-color: transparent;
}

.content-delete-btn {
	background: #ffffff;
	border: 0.8px solid #ff0000;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
	padding: 0.375rem;
	position: absolute;
	right: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	/* top: 0.875rem; */
}

.user-avatar {
	width: 2rem;
	height: 2rem;
	color: var(--color-white);
	background-color: var(--secondary-color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2rem;
	margin-right: 0.5rem;
	/* z-index: 100; */
	cursor: pointer;
}

.trainer-icons {
	position: absolute;
	right: 9rem;
	display: flex;
	flex-direction: row;
}

.assign-btn-container {
	position: absolute;
	right: 3.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.assign-trainer-btn {
	background: #ffffff;
	border: 1px solid #21bacf;
	box-sizing: border-box;
	border-radius: 5px;
	padding: 0.375rem 0.625rem;
	color: #21bacf;
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	/* top: 0.875rem; */
}

.assign-trainer-btn:after {
	display: none !important;
}
</style>
