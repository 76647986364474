<template>
	<li
		:class="[
			{
				disabled: isDisabled,
			},
		]"
		@click="handleContentClick()"
	>
		<div
			v-if="item.type != ContentType.DIRECTORY"
			class="content-list-item-container"
		>
			<div class="content-icon-container">
				<div class="content-icon-border">
					<img
						v-if="item.type == ContentType.LIVE_CLASS"
						class="page-view-icon"
						src="@/assets/images/zoom-icon.png"
					/>
					<img
						v-if="item.type == ContentType.STUDY_MATERIAL"
						class="page-view-icon"
						src="@/assets/images/study-material.png"
					/>
					<img
						v-if="item.type == ContentType.DIRECTORY"
						class="page-view-icon"
						src="@/assets/images/folder-icon.png"
					/>
					<img
						v-if="item.type == ContentType.RECORDED_VIDEOS"
						class="page-view-icon"
						src="@/assets/images/recorded-lecture.png"
					/>
					<img
						v-if="item.type == ContentType.EXAM"
						class="page-view-icon"
						src="@/assets/images/exam-test.png"
					/>
					
					<img
						v-if="item.type == ContentType.ASSIGNMENT"
						class="page-view-icon"
						src="@/assets/images/assignment.png"
					/>
					<img
						v-if="item.type == ContentType.FREETEXT"
						class="page-view-icon"
						src="@/assets/images/freeText.png"
					/>
					<img
						v-if="item.type == ContentType.FLASHCARDS"
						class="page-view-icon"
						src="@/assets/images/flash-card.png"
					/>
					<img
						v-if="item.type == ContentType.QUIZ"
						class="page-view-icon"
						src="@/assets/images/exam-test.png"
					/>
				</div>
			</div>
			<div class="content-text">
				<span>{{ item.title }}</span> <br />
				<!-- <span> -->
				{{
					item.type == ContentType.RECORDED_VIDEOS
						? "Recorded Lecture"
						: item.type == ContentType.ASSIGNMENT
						? "Assignment"
						: item.type == ContentType.LIVE_CLASS
						? "Live Class"
						: item.type == ContentType.EXAM
						? "Exam"
						: item.type == ContentType.FREETEXT
						? "FreeText"
						: item.type == ContentType.FLASHCARDS
						? "Flashcards"
						: "Study Material"
				}}
				<!-- </span> -->
			</div>
			<div class="content-info">
				<p class="heading">
					{{
						item.type == ContentType.RECORDED_VIDEOS
							? "Duration"
							: item.type == ContentType.LIVE_CLASS
							? "Timing"
							: item.type == ContentType.ASSIGNMENT
							? "Deadline"
							: ""
					}}
				</p>
				<p v-if="item.type == ContentType.LIVE_CLASS">
					<span v-if="contentExtraProperties"
						>{{ timeParser(contentExtraProperties.Start_Time) }}, <br />
						{{ dateParser(contentExtraProperties.Start_Time) }}</span
					>
					<span v-else> 1 hour </span>
				</p>
				<p v-if="item.type == ContentType.RECORDED_VIDEOS">
					<span v-if="contentExtraProperties"
						>{{
							contentExtraProperties.Duration
								? (contentExtraProperties.Duration / 60).toFixed(1)
								: ""
						}}
						min</span
					>
					<span v-else> 45 min</span>
				</p>
			</div>
			<div v-if="isUnpaid && isDemo" class="content-cta demo">
				<button type="button" class="border-btn">Demo</button>
			</div>
			<div v-else-if="!isUnpaid" class="content-cta d-flex gap-2">
				<button 				
				v-if="item.type != ContentType.EXAM"
				:disabled="isDisabled" type="button" class="border-btn">
					{{ buttonText }}
				</button>
				<button 				
				v-else-if="!item.availableTo || new Date() <= new Date(item.availableTo)"
				:disabled="isDisabled" type="button" class="border-btn">
					{{ buttonText }}
				</button>
				<button
					v-if="
						item.type == ContentType.EXAM &&
						item.externalService.serviceProvider ==
							ServiceCodes.EXAM.V3ExamSystem &&
						viewMode != 'description_page'
					"
					:disabled="isDisabled"
					type="button"
					class="border-btn"
					@click="handleLegacyExamButtonClick"
				>
					View Results
				</button>
			</div>
		</div>
		<div v-else class="accordion nested-content-accordian" role="tablist">
			<ProductContentAccordian
				:key="item.id"
				:section="item"
				:accordianActive="activeAccordian"
				@nestedAccordianClick="handleAccordianClick"
				:isFirstAccordian="false"
				:purchased="purchased"
				viewMode="nested"
			/>
		</div>
		<StudyMaterial
			v-if="item.type == ContentType.STUDY_MATERIAL"
			:showModal="showStudyMaterialModal"
			@updateModalState="toggleStudyMaterialModal"
			:contentId="item.id"
		/>
		
		<b-modal
		:visible="showWebDisabledModel"
		hide-header
		centered
		hide-footer
		@change="closeWebDisabledModal"
		size="lg"
		body-class="modal-body"
		ok-title="Ok"
		>
		<AppsDownloadInstructions />

	</b-modal>

	</li>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StudyMaterial from "../Content/StudyMaterial.vue";
import { ContentType } from "../../constants/content";
import { LegacyExam } from "../../constants/exam";
import { getInputDateFormat } from "../../helpers/FormatHelpers";
import {
	toShortDateString,
	getTimeIn12Hours,
} from "../../helpers/FormatHelpers";
import { ServiceCodes } from "../../constants/serviceCodes";
import { getCookie } from "../../helpers/CookieHelpers";
import AppsDownloadInstructions from "@/components/Layout/AppsDownloadInstructions";

export default {
	name: "ProductContentListItem",
	data() {
		return {
			activeAccordian: "",
			showStudyMaterialModal: false,
			ContentType,
			ServiceCodes,
			LegacyExam,
			showWebDisabledModel: false
		};
	},
	components: {
    AppsDownloadInstructions,
		ProductContentAccordian: () => import("./ProductContentAccordian.vue"),
		StudyMaterial,
	},
	props: {
		item: Object,
		purchased: Boolean,
		productVariantId: String,
		viewMode: String,
		parentContentId: String
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		closeWebDisabledModal(isOpen){
			this.showWebDisabledModel=isOpen;
		},
		handleContentClick() {
			if(!this.isDesktopApp && this.isWebDisabled && this.item.isContentAvailableForDemo==false)
			{
				this.showWebDisabledModel=true;
				return;
			}

			if (this.item.type == ContentType.STUDY_MATERIAL) {
				this.toggleStudyMaterialModal();
			} else if (this.item.type == ContentType.EXAM) {
				if (
					this.item.externalService.serviceProvider ==
					ServiceCodes.EXAM.V3ExamSystem
				) {
					this.getContentById({
						id: this.item.id,
						extraParams: { action: LegacyExam.GET_EXAM_ACTION.ATTEMPT },
					}).then((content) => {
						if (content && this.loggedIn) {
							var examAttemptUrl =
								content.data.redirectUrl +
								"&RedirectUrl=" +
								window.location.href;
							window.open(examAttemptUrl, "_blank");
							// window.location.href = examAttemptUrl;
							return;
						}
						this.$toasted.error(
							"Please login/create an account to demo this Exam",
							{
								duration: 3000,
								theme: "outline",
								position: "bottom-center",
							}
						);
						this.$router.push({
							name: "Login",
							query: {
								nextUrl:
									this.$route.path + `?chapterId=${this.parentContentId}`,
							},
						});
					});
					return;
				}
				console.log("examClicked");
				this.getContentById({
					id: this.item.id,
					extraParams: { RedirectUrl: window.location.href },
				}).then((res) => {
					var data = res.data;
					if (this.loggedIn) {
						var examAttemptUrl = `http://examapp.gocbeglobal.com/exam/attempt?TestID=${
							data.examID
						}&Name=${data.name ?? ""}&Email=${data.email ?? ""}&UserID=${
							data.userID ?? ""
						}&RedirectUrl=${data.redirectUrl}&Signature=${data.signature}`;
						window.open(examAttemptUrl, "_blank");
						// window.location.href = examAttemptUrl;
						return;
					}
					this.$toasted.error(
						"Please login/create an account to demo this Exam",
						{
							duration: 3000,
							theme: "outline",
							position: "bottom-center",
						}
					);
					this.$router.push({
						name: "Login",
						query: {
							nextUrl: this.$route.path + `?chapterId=${this.parentContentId}`,
						},
					});
				});
			} else if (this.item.type == ContentType.LIVE_CLASS) {
				this.$router.push(this.contentLink);
			}
			 else this.$router.push(this.contentLink);

			console.log("content clicked of type: ", this.item.type);
		},
		handleLegacyExamButtonClick(e) {
			if(!this.isDesktopApp && this.isWebDisabled)
			{
				this.showWebDisabledModel=true;
				return;
			}
			e.stopPropagation();
			this.getContentById({
				id: this.item.id,
				extraParams: { action: LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS },
			}).then((content) => {
				if (content && this.loggedIn) {
					var examAttemptUrl =
						content.data.redirectUrl + "&RedirectUrl=" + window.location.href;
					window.open(examAttemptUrl, "_blank");
					return;
				}
			});
		},
		handleAccordianClick(e) {
			if (this.activeAccordian == e.target.id) {
				this.activeAccordian = "";
			} else {
				this.activeAccordian = e.target.id;
			}
		},
		productIdFromUrl() {
			const urlID = this.$route.params.id.split("-");
			const productID = urlID.at(-1);
			return productID;
		},
		toggleStudyMaterialModal(state) {
			
			if (state !== undefined) {
				this.showStudyMaterialModal = state;
			} else {
				this.showStudyMaterialModal = !this.showStudyMaterialModal;
			}
		},
		timeParser(date) {
			return getTimeIn12Hours(date);
		},
		dateParser(date) {
			return toShortDateString(date);
		},
	},
	computed: {
		...mapState("user", ["loggedIn", "user"]),		
		...mapState("appState", ["isMobile","systemParameters"]),		
		isDesktopApp() {
      		return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
    	},
		isWebDisabled(){
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled=='1';
		},
		contentLink() {
			switch (this.item.type) {
				case ContentType.RECORDED_VIDEOS:
					return {
						path:
							"/content/video/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id +
							"_" +
							this.productVariantId,
					};
				case ContentType.LIVE_CLASS:
					return {
						path:
							"/content/liveclass/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id +
							"_" +
							this.productVariantId,
					};
				case ContentType.QUIZ:
					return {
						path:
							"/content/quiz/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id +
							"_" +
							this.productVariantId,
					};
				case ContentType.ASSIGNMENT:
					return {
						path:
							"/content/assignment/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id +
							"_" +
							this.productVariantId,
					};
			}
		},
		isDisabled() {
			return (
				(this.viewMode == "description_page" &&
					!this.item.isContentAvailableForDemo) ||
				(getInputDateFormat(new Date(this.item.availableFrom)) !=
					getInputDateFormat(new Date()) &&
					this.item.type == ContentType.LIVE_CLASS) ||
				(new Date(this.item.availableFrom) > new Date() &&
					this.item.type == ContentType.LIVE_CLASS) ||
				(this.isUnpaid && !this.isDemo)
			);
		},
		isDemo() {
			return this.item.isContentAvailableForDemo;
		},
		isUnpaid() {
			return !this.purchased;
		},
		contentExtraProperties() {
			if (this.item.extraProperties) return this.item.extraProperties;
			else return null;
		},
		buttonText() {
			return this.item.type == ContentType.RECORDED_VIDEOS
				? "Watch"
				: this.item.type == ContentType.LIVE_CLASS
				? "Join Now"
				: this.item.type == ContentType.ASSIGNMENT
				? "Submit"
				: this.item.type == ContentType.EXAM
				? "Attempt"
				: "View";
		},
	},
};
</script>

<style scoped>
.content-item-list li {
	padding: 0;
	border-bottom: 1px solid var(--color-light-gray);
	cursor: pointer;
}

.content-item-list li.disabled {
	pointer-events: none;
}

.content-item.disabled .content-text {
	opacity: 0.5;
}

.content-list-item-container {
	color: var(--secondary-color);
	display: flex;
	flex-direction: row;
	align-items: center;
}

.content-item {
	font-size: 0.9rem;
	padding: 1rem !important;
}

/* .content-item span, */
/* .content-item-disabled span {
	font-size: 0.7rem;
	color: var(--color-gray);
} */

.content-item-list li:last-child {
	border-bottom: none;
}

.content-item-icon {
	/* margin-left: 0.25rem; */
	margin-right: 0.5rem;
	font-size: 1rem;
	color: var(--color-text-default);
}

.page-view-icon {
	font-size: 0.6rem;
	/* width: 36px; */
	height: 32px;
	/* border-radius: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
}
.page-view-icon.blue-bg {
	background-color: #2d8bfe;
	color: var(--color-white);
}

.content-item-list li.active {
	background-color: var(--color-light-gray);
}
/* .content-icon-container {
	width: 70px !important;
	display: flex;
	align-items: center;
	justify-content: center;
} */

.content-text {
	line-height: 1.3;
	font-size: 1rem;
	color: #666;
	font-size: 0.875rem;
}

.content-text span {
	font-weight: bold;
	color: black;
	font-size: 1rem;
}

.content-icon-border {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	/* border: 2px solid #d5d5d5; */
	border-radius: 50%;
}

.content-info {
	margin-left: auto;
	margin-right: 1rem;
	min-width: 3.425rem;
}

.content-info p {
	margin: 0;
	font-size: 0.675rem;
	line-height: 1.25;
}

.content-info .heading {
	text-transform: uppercase;
	color: var(--color-gray);
}

.content-cta button {
	padding: 0.425rem 0.5rem;
	min-width: 5.75rem;
	line-height: 1;
	border-color: #282828;
	font-size: 0.875rem;
	border-radius: 4px;
	color: #282828 !important;
	font-weight: 500;
}
</style>
