<template>
  <b-modal
    id="edit-content-modal"
    @change="$emit('updateModalState', false)"
    :visible="visible"
    hide-header
    centered
    :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')"
    content-class="edit-content-modal"
    size="xl"
    body-class="modal-body"
    hide-footer
    modal-class="custom-modal"
    no-close-on-backdrop
  >
    <div class="body-container">
     
      <div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
        <button @click="closeModal" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
			</div>
			
      <h1 class="edit-content-title">
        {{ isEditMode ? "Edit" : "Create" }} Quiz
      </h1>
      <div :class="['info-container']">
        <div class="inline-group">
          
          <div class="col">
            <label class="main-label" for="title">Title (required)</label>
            <input
              placeholder="Type Quiz Title here..."
              class="form-input"
              type="search"
              name="title"
              id="title"
              v-model="title"
              required
            />
          </div>
        </div>
        <div>
          <div class="inline-group">
            <div class="col">
              <label class="main-label" for="passingPercentage"
                >Passing Percentage</label
              >
              <div>
                <input
                  class="form-input"
                  style="display: inline"
                  type="number"
                  name="passingPercentage"
                  id="passingPercentage"
                  placeholder="Passing Percentage"
                  min="1"
                  max="100"
                  v-model="data.passingPercentage"
                />
              </div>
            </div>
            <div class="col">
              <label class="main-label" for="totalTime"
                >Total Time (optional)</label
              >
              <div>
                <input
                  class="form-input"
                  style="width: 15%; display: inline"
                  type="number"
                  name="hour"
                  id="hour"
                  placeholder="hh"
                  min="0"
                  max="24"
                  v-model="data.hour"
                  required
                /><strong> : </strong>
                <input
                  class="form-input"
                  style="width: 15%; display: inline"
                  type="number"
                  name="minute"
                  id="minute"
                  placeholder="mm"
                  min="0"
                  max="60"
                  v-model="data.minute"
                  required
                /><strong> : </strong>
                <input
                  class="form-input"
                  style="width: 15%; display: inline"
                  type="number"
                  name="second"
                  id="second"
                  placeholder="ss"
                  min="0"
                  max="60"
                  v-model="data.second"
                  required
                />
              </div>
            </div>
          </div>

          <div class="inline-group">
            <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isRandom"
                  v-model="data.isRandom"
                />
                <label class="form-check-label" for="isRandom"
                  >Randomized Question Order
                </label>
              </div>
            </div>
         
            <!-- <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="markCompleteOnPass"
                  v-model="data.markCompleteOnPass"
                />
                <label class="form-check-label" for="markCompleteOnPass"
                  >Mark Complete On Pass
                </label>
              </div>
            </div> -->
          </div>
        </div>

        <div class="inline-group">
          <div class="col">
            <label for="start-date" class="main-label m-0"
              >start date (optional)</label
            >
            <input
              placeholder="Select start date"
              class="form-input"
              type="date"
              name="availableFrom"
              id="availableFrom"
              v-model="availableFrom"
            />
          </div>
          <div class="col">
            <label for="end-date" class="main-label m-0"
              >end date (optional)</label
            >
            <input
              placeholder="Select end date"
              class="form-input"
              type="date"
              name="availableTo"
              id="availableTo"
              v-model="availableTo"
            />
          </div>
        </div>
      </div>
      <div class="step-btn-container">
        <button
          @click="createExam"
          role="button"
          class="step-btn"
          :disabled="
            !title ||
            !data.passingPercentage ||
            parseInt(data.passingPercentage) < 1 ||
            parseInt(data.passingPercentage) > 100 ||
            loading
          "
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ stepButtonContent }}
          <img src="@/assets/images/arrow-right.svg" />
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CreateEditQuizModal",
  data() {
    return {
      isExpanded: false,
      title: null,
      availableFrom: null,
      availableTo: null,
      data: {
        hour: null,
        minute: null,
        second: null,
        isRandom: true,
        passingPercentage: null,
      },
    };
  },
  computed: {
    ...mapState("exam", ["loading"]),
    ...mapState("user", ["user"]),
    visible() {
      return this.showModal;
    },
    stepButtonContent() {
      return this.isEditMode ? "Save" : "Create";
    },
    isEditMode() {
      return this.mode == "edit";
    },
  },
  watch: {
    visible: function () {
      if (this.mode == "edit" && this.visible) {
        this.title = this.contentData.title;
        if (this.contentData.availableFrom) {
          this.availableFrom = new Date(this.contentData.availableFrom)
            .toISOString()
            .split("T")[0];
        }
        if (this.contentData.availableTo) {
          this.availableTo = new Date(this.contentData.availableTo)
            .toISOString()
            .split("T")[0];
        }
        var prop = JSON.parse(this.contentData.extraProperties);
        
        if (prop.PassingPercentage)
        	this.data.passingPercentage = prop.PassingPercentage;

        if (prop.RandomQuestion != null || prop.RandomQuestion != undefined)
        	this.data.isRandom = prop.RandomQuestion;

        if (prop.HaveTimeLimit) {
            this.data.hour = Math.floor(prop.Time / 3600);
            this.data.minute = Math.floor((prop.Time % 3600) / 60);
            this.data.second = prop.Time % 60;
        }
        if(prop.MarkCompleteOnPass)
        {
          this.data.markCompleteOnPass=prop.MarkCompleteOnPass;
        }
      }
    },
  },
  props: {
    showModal: Boolean,
    contentData: { default: null, type: Object },
    parentContentId: String,
    productVariantId: String,
    mode: String,
  },
  methods: {
    ...mapActions("content", ["getContentById", "updateContent"]),
    ...mapActions("exam", ["createQuiz", "updateExam"]),
    closeModal() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit("updateModalState", false);
    },
    createExam() {
      var data = {};

      const totalSeconds =
        (this.data.hour ? parseInt(this.data.hour) * 3600 : 0) +
        (this.data.minute ? parseInt(this.data.minute) * 60 : 0) +
        (this.data.second ? parseInt(this.data.second) : 0);

      data = {
        randomizeQuestion: this.data.isRandom.toString() + "",
        passingPercentage: this.data.passingPercentage.toString(),
        markCompleteOnPass: (this.data.markCompleteOnPass??false).toString() + ""
      };

      if (totalSeconds && totalSeconds > 0) {
        data.time = totalSeconds.toString();
      }
      else {
        data.HaveTimeLimit = "false";
      }

      var createExamObj = {
        id: this.contentData?.id ?? null,
        title: this.title,
        data: data,
        availableFrom: this.availableFrom ? this.availableFrom : null,
        availableTo: this.availableTo ? this.availableTo : null,
        isAvailableForDemo: false,
        parentDirectoryID: this.parentContentId,
        type: "quiz",
      };

      if (this.isEditMode) {
        this.updateContent(createExamObj).then((res) => {
          if (res) {
            this.getContentById({ id: this.contentData.id });
            this.closeModal();
            this.$toasted.success("Quiz updated successfully", {
              theme: "outline",
              position: "bottom-center",
              duration: 3000,
            });
          }
        });
      } else {
        this.createQuiz(createExamObj)
          .then((res) => {
            if (res) {
              this.closeModal();
              this.$toasted.success("Quiz created successfully", {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
              });
            }
          })
          .catch((err) => {
            this.$toasted.error(err.message, {
              icon: "exclamation-triangle",
              theme: "outline",
              position: "bottom-right",
              singleton: true,
            });
          });
      }
    },
  },
};
</script>
<style>

.edit-content-modal 
{
  height: 45.375rem;
  /* width: 70vw; */
}
.edit-content-modal  .modal-body.modal-body
{
	display: flex;
    flex-direction: row;
    padding: 0 !important;
	overflow: auto;
}
.edit-content-modal  .modal-body.modal-body .body-container
{
	
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

}


#edit-content-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#edit-content-modal .expanded .edit-content-modal
{
	height: 90vh;
	
}


.import-btn-container {
  float: right;
}

.import-btn-container .step-btn {
  width: 150px;
}
</style>
<style scoped>
	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}



.edit-content-modal .edit-content-title {
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 2rem;
  color: #000000;
}

.all-trainers-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.trainer-list-container {
  margin-top: 1.5rem;
}
</style>
