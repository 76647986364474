<template>
  <div id="product-content-planners">
    <b-tabs v-model="currentActiveTab" nav-class="main-tabs nav nav-tabs" 
      nav-wrapper-class="main-tabs-row" content-class="eb-content-wrapper">
      
      <b-tab :active="isActiveTab(0)">
        <template v-slot:title>
          <div class="tab-title-wrapper">
            <span class="tab-title">All Courses</span>
            <span class="tab-subtitle">See all courses</span>
          </div>
        </template>
        <Planners />
      </b-tab>

      <b-tab :active="isActiveTab(1)">
        <template v-slot:title>
          <div class="tab-title-wrapper">
            <span class="tab-title">Chapt: Name will come here</span>
            <span class="tab-subtitle">10 Days Planner - 3 Aug to 10 Sep</span>
          </div>
        </template>
        <PlanDetail/>
      </b-tab>

      <b-tab :active="isActiveTab(2)">
        <template v-slot:title>
          <div class="tab-title-wrapper">
            <span class="tab-title">Chapt: Name will come here</span>
            <span class="tab-subtitle">10 Days Planner - 10 Sep to 10 Oct</span>
          </div>
        </template>
        <PlannerTasks />
      </b-tab>
    
    </b-tabs>
  </div>
</template>

<script>
import Planners from './Planners/Planners.vue'
import PlanDetail from "./Planners/PlanDetail.vue";
import PlannerTasks from "./Planners/PlannerTasks.vue"

export default {
  name: "ProductContentPlanners",
  components: {
    Planners,
    PlanDetail,
    PlannerTasks,
  },
  data() {
    return {
      currentActiveTab: 0,
    };
  },
  methods: {
    isActiveTab(index) {
      return this.currentActiveTab === index;
    },
    changeTab(tabName) {
      const query = { ...this.$route.query, tab: tabName };
      this.$router.push({ query }).catch(() => {});
    },
  },
  watch: {
    '$route.query.tab': {
      handler(newTab) {
        if (newTab) {
          this.currentActiveTab = newTab.toLowerCase() === 'plannersection' ? 0 : 1;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>

#product-content-planners .eb-content-wrapper {
  max-height: calc(100dvh - var(--header-height) - 50px);
  overflow: scroll;
}

#product-content-planners .main-tabs-row {
  height: auto; 
  padding: 0px;
}

#product-content-planners .main-tabs a,
#product-content-planners .main-tabs .nav-link {
  height: 60px;
  line-height: normal;
}

</style>

<style scoped>

.tab-title-wrapper {
  display: flex;
  flex-direction: column;
}

.tab-title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; 
  box-sizing: border-box; 
}

.tab-title {
  color: #000;
  font-family: "Circular Std";
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.36px;
}

.tab-subtitle {
  color: var(--primary-color);
  font-family: "Circular Std";
  font-size: 10.5px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.nav-tabs {
  padding: 0; 
  margin: 0; 
}

.nav-item {
  height: 60px;
  margin: 0; 
}

</style>
