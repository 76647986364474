<template>
  <div>
    <b-form-group>
      <h5
        v-for="(option, index) in answerOptions"
        :key="index"
        :class="'d-flex align-items-center'"
      >
        <b-form-radio
          v-model="selectedAnswer"
          :value="index"
          :disabled="!option.option"
          style="flex-grow: 9"
        >
          <QuillRichTextEditor
            class="form-input answer-option"
            :text="option.option"
            :isInline="true"
            :editorID="'editor-answeroption-' + index"
            placeholder="Enter option here..."
            v-model="option.option"
          />
        </b-form-radio>
        <div style="flex-grow: 1">
          <button @click="removeOption(index)" class="" role="button">
            <img
              src="@/assets/images/cancel-icon.svg"
              style="max-height: 13px"
            />
          </button>
        </div>
      </h5>
    </b-form-group>
  </div>
</template>

<script>
import QuillRichTextEditor from "../../../Editors/QuillRichTextEditor.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "RadioButtonQuestion",
  data() {
    return {
      selectedAnswer: null,
      text: "",
    };
  },
  props: {
    answerOptions: Array,
  },
  components: {
    QuillRichTextEditor,
  },
  computed: {},
  mounted() {},
  methods: {
    removeOption(index) {
      if (this.answerOptions.length < 3) {
        this.$toasted.error("Atleast 2 options are required", {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          singleton: true,
        });
        return;
      }
      this.$emit("removeOpt", index);
    },
  },
  created() {
    var self = this;
    if (this.answerOptions.length > 0) {
      this.answerOptions.forEach(function (val, ind, arr) {
        if (val.isCorrect) {
          self.selectedAnswer = ind;
        }
      });
    }
  },
  watch: {
    selectedAnswer: function () {
      var obj = this.answerOptions[this.selectedAnswer];
      obj.index = this.selectedAnswer;
      this.$emit("setAnswer", [obj]);
    },

    // answerOptions: function () {
    //   this.$emit("updateAnswerOptions", this.answerOptions);
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-option {
  margin-left: 10px;
  font-weight: lighter;
}
.radio-inputs {
  /* max-width: 100%; */
  padding: 5px;
  border-radius: 10px;
}
</style>

<style>
input[type="radio"] {
  margin-right: 5px;
}

.radio-inputs .custom-control-label {
  margin: 0px;
}

.custom-control-label {
  width: 95%;
}
</style>

<style scoped>
.answer-option {
  padding: 0px;
}
</style>
