import store from "../store/index";
import { POST_QUIZ_SUBMIT, QUIZ_QUESTION, QUIZ_ATTEMPT_DETAIL } from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const quizService = {
  submitQuizAttempt,
  createQuestion,
  getQuizQuestions,
  deleteQuestion,
  updateQuestion,
  getQuizDetail
};

async function submitQuizAttempt(quizAttempt) {
  try {
    let requestBody = JSON.stringify(quizAttempt);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + POST_QUIZ_SUBMIT,
      "POST",
      requestBody,
      false
    );
    return body?.json();
  } finally {
  }
}

async function getQuizDetail(quizAttemptId) {
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + QUIZ_ATTEMPT_DETAIL + `/${quizAttemptId}`,
      "GET",
      null,
      false
    );
    return body?.json();
  } finally {
  }
}

async function createQuestion(quizObj) {
  try {
    let requestBody = JSON.stringify(quizObj);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + QUIZ_QUESTION,
      "POST",
      requestBody,
      false
    );
    return body?.json();
  } finally {
  }
}

async function getQuizQuestions(contentID) {
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + QUIZ_QUESTION + `?contentId=${contentID}`,
      "GET",
      null,
      false
    );
    return res?.json();
  } finally {
  }
}

async function deleteQuestion(questionID) {
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + QUIZ_QUESTION + `/${questionID}`,
      "DELETE",
      null,
      false
    );
    return res?.json();
  } finally {
  }
}

async function updateQuestion(obj) {
  try {
    let res = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + QUIZ_QUESTION,
      "PUT",
      JSON.stringify(obj),
      false
    );
    return res?.json();
  } finally {
  }
}
