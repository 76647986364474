<template>
	<div class="exam-question-card">
		<div class="title-container">
			<h4 class="question-title">
				{{ "Question " + questionData.QuestionSequenceNo }}
				{{
					questionData.ExamQuestionType == ExamQuestionType.CRITERIA &&
					questionData.NoOfQuestions > 1
						? `- ${
								parseInt(questionData.QuestionSequenceNo) +
								parseInt(questionData.NoOfQuestions) -
								1
						  }`
						: ""
				}}
			</h4>
			<div class="ms-auto d-flex gap-2 align-items-center">
				<div class="question-type-pill">
					{{ questionData.ExamQuestionType }}
				</div>
			</div>
		</div>
		<div class="question-content-container">
			<div
				v-if="questionData.ExamQuestionType == ExamQuestionType.QUESTION"
				class="d-flex flex-column flex-grow-1"
			>
				<p class="question-detail mb-1">
					<span class="text-muted">Question ID: </span>
					{{ questionData.Question.QuestionID }}
				</p>
				<p class="question-detail mb-1">
					<span class="text-muted">Question Title: </span>
					{{ questionDetails(questionData.Question.QuestionID).QuestionTitle }}
				</p>
				<p class="question-detail mb-1">
					<span class="text-muted">Question Type: </span>
					{{
						questionDetails(questionData.Question.QuestionID).QuestionType
							.QuestionTypeName
					}}
				</p>
			</div>
			<div
				v-if="questionData.ExamQuestionType == ExamQuestionType.CRITERIA"
				class="d-flex flex-column flex-grow-1"
			>
				<p
					class="question-detail mb-1"
					v-for="(criteria, index) in criteriaItems"
					:key="index"
				>
					{{ ExamCriteriaText[criteria] }}:
					{{ criteriaValues(questionData.Criteria[criteria], criteria) }}
				</p>
			</div>
			<div class="right-menu">
				<a role="button" class="right-menu-icon">
					<img
						src="@/assets/images/edit-icon.svg"
						@click="toggleAddQuestionModal(true)"
					/>
				</a>
				<AddExamQuestionModal
					:showModal="showAddQuestionModal"
					@updateModalState="toggleAddQuestionModal"
					@addExamQuestions="updateQuestions"
					:productVariantId="productVariantId"
					:examQuestionSequence="questionData.QuestionSequenceNo"
					:isEditMode="true"
					:questionData="questionData"
					:examQuestionType="questionData.ExamQuestionType"
					:allowedQuestionTypes="allowedQuestionTypes"
				/>
				<a
					role="button"
					class="right-menu-icon delete"
					@click="removeQuestion(questionData.QuestionSequenceNo)"
				>
					<img src="@/assets/images/delete-icon.svg" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
	ExamQuestionType,
	ExamCriteriaText,
	ExamCriteria,
} from "../../../constants/exam";

import { QuestionTypeText } from "../../../constants/question";

import AddExamQuestionModal from "./AddExamQuestionModal.vue";

export default {
	name: "ExamQuestionListItem",
	data() {
		return {
			ExamQuestionType,
			ExamCriteriaText,
			ExamCriteria,
			QuestionTypeText,
			showAddQuestionModal: false,
		};
	},
	props: {
		questionData: Object,
		productVariantId: String,
		examQuestionType: { type: String, default: ExamQuestionType.QUESTION },
		allowedQuestionTypes: Array,
	},
	computed: {
		...mapState("exam", ["allQuestions", "questionCategories"]),
		criteriaItems() {
			return this.questionData.Criteria
				? Object.keys(this.questionData.Criteria)
				: [];
		},
	},
	methods: {
		questionDetails(id) {
			return this.allQuestions.find((question) => question.ID == id);
		},
		removeQuestion(id) {
			this.$emit("removeQuestion", id);
		},
		toggleAddQuestionModal(value) {
			if (value !== undefined) {
				this.isEditMode = value;
				this.showAddQuestionModal = value;
			} else {
				this.isEditMode = !this.isEditMode;
				this.showAddQuestionModal = !this.showAddQuestionModal;
			}
		},
		updateQuestions(questions) {
			this.$emit("updateQuestion", {
				questions,
				sequence: this.questionData.QuestionSequenceNo,
			});
		},
		criteriaValues(values, criteria) {
			switch (criteria) {
				case ExamCriteria.CATEGORY:
					return values
						.split(",")
						.map((c) => {
							return this.questionCategories[this.productVariantId][c]
								? this.questionCategories[this.productVariantId][c].categoryName
								: c;
						})
						.toString()
						.replaceAll(",", ", ");
				case ExamCriteria.QUESTION_TYPE:
					return values
						.split(",")
						.map((q) => {
							return QuestionTypeText[q];
						})
						.toString()
						.replaceAll(",", ", ");
				default:
					return values.replaceAll(",", ", ");
			}
		},
	},
	components: {
		AddExamQuestionModal,
	},
};
</script>

<style scoped>
.exam-question-card {
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
}

.exam-question-card .title-container {
	padding: 1rem;
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
}

.question-title {
	margin: 0;
	padding: 0;
}

.question-type-pill {
	padding: 0.25rem 0.75rem;
	font-size: 0.75rem;
	border: 1px solid var(--primary-color);
	border-radius: 1rem;
	display: flex;
}

.question-content-container {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: flex-start;
}

.question-content-container .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	column-gap: 1rem;
	margin-left: auto;
	/* margin-right: 0.75rem; */
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.exam-question-card:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.right-menu-icon {
	width: 1.75rem;
	height: 1.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.right-menu-icon img {
	width: 0.875rem;
	height: 0.875rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}
</style>
