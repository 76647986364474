<template>
  <div class="h-100">
    <div
      v-if="loadedCategories.length > 0"
      id="categoryList"
      class="category-list"
    >
      <CategoryListItem
        v-if="uncategorizedProducts.length > 0"
        @toggleModal="$emit('toggleModal', $event)"
        :categoryData="{
          id: '-1',
          title: 'Uncategorized Courses',
          isVisible: true,
          childCategories: null,
        }"
        :isUncategorized="true"
      />

      <draggable
        v-model="loadedCategories"
        @start="drag = true"
        @end="drag = false"
        ghost-class="ghost"
        group="content"
        animation="200"
        @change="update($event)"
        handle=".category-drag-handler"
        style="display: flex; column-gap: 1.875rem"
      >
        <CategoryListItem
          @toggleModal="$emit('toggleModal', $event)"
          v-for="item in loadedCategories"
          :key="item.id"
          :categoryData="item"
        />
      </draggable>
    </div>
    <div v-else-if="loading" id="categoryList" class="category-list skeleton">
      <b-skeleton
        v-for="(item, index) in 4"
        :key="index"
        class="category-item-skeleton"
      ></b-skeleton>
    </div>
    <div v-else class="no-content">
      <h3>No Qualifications found</h3>
    </div>
  </div>
</template>

<script>
import CategoryListItem from "./CategoryListItem.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "CategoryList",
  components: {
    CategoryListItem,
    draggable,
  },
  data() {
    return {
      loadedCategories: [],
    };
  },
  computed: {
    ...mapState("category", ["categories", "loading"]),
    ...mapState("product", ["products"]),
    ...mapGetters("product", ["uncategorizedProducts"]),
    // uncategorizedProducts() {
    // 	if (
    // 		this.products &&
    // 		this.products.items &&
    // 		Object.entries(this.products.items).length > 0
    // 	) {
    // 	}
    // 	return Object.values(this.products.items).filter(
    // 		(product) => product.categories.length == 0
    // 	);
    // },

    // loadedCategories: {
    //   get() {
    //     //debugger;
    //     return this.categories;
    //   },
    //   set(value) {
    //     this.loadedCategories = value;
    //   },
    // },
  },
  methods: {
    ...mapActions("category", ["fetchCategories"]),
    ...mapActions("category", ["editProductCategorySequence"]),
    ...mapActions("product", ["fetchAllProducts"]),

    update(e) {
      var categoryObj = {};
      var afterCategoryID;

      if (e.added) {
        if (e.added.newIndex != 0) {
          afterCategoryID = this.loadedCategories[e.added.newIndex - 1].id;
          categoryObj.UpperCategoryID = afterCategoryID;
        } else if (e.moved.newIndex == 0) {
          categoryObj.BelowCategoryID =
            this.loadedCategories[e.added.newIndex + 1].id;
        }
        // categoryObj = { ...categoryObj, parentContentID: this.categoryId };
        categoryObj.CategoryID = e.added.element.id;
      }

      if (e.moved) {
        if (e.moved.newIndex != 0) {
          afterCategoryID = this.loadedCategories[e.moved.newIndex - 1].id;
          categoryObj.UpperCategoryID = afterCategoryID;
        } else if (e.moved.newIndex == 0) {
          categoryObj.BelowCategoryID =
            this.loadedCategories[e.moved.newIndex + 1].id;
        }
        // categoryObj = { ...categoryObj, parentContentID: parent };
        categoryObj.CategoryID = e.moved.element.id;
      }

      if (!e.removed) {
        this.editProductCategorySequence(categoryObj).then(() => {
          console.log("editContentSequence product", categoryObj);
        });
      }
    },
  },
  created() {
    if (this.categories && this.categories.length == 0) {
      this.fetchCategories().then(() => {
        console.log("categories loaded", this.categories);
        this.loadedCategories = [...this.categories];
        this.fetchAllProducts();
      });
    } else {
      this.loadedCategories = this.categories;
    }
  },
  watch: {
    categories: function () {
      if (this.categories.length > 0) {
        this.loadedCategories = this.categories;
      } else {
        this.loadedCategories = [];
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.category-list {
  display: flex;
  flex-direction: row;
  column-gap: 1.875rem;
  overflow-y: hidden;
  padding: 1.75rem 0;
  /* overflow-x: hidden; */
  overflow-x: overlay;
  height: calc(100vh - 11.325rem);
}

/* .category-list:hover {
	overflow-x: overlay;
} */

.category-item-skeleton {
  width: 22.625rem;
  flex-shrink: 0;
  min-height: 37.5rem;
  box-shadow: 6px 3px 6px rgba(185, 185, 185, 0.07);
  border-radius: 12px;
}

.no-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
