<template>
	<div class="footer-settings-wrapper">
        <div class="mb-4 d-flex flex-wrap gap-5 footer-settings ">
            
            <div class="settings-container col-md-3 px-0 settings-comp-info">
                <h3 class="main-label">
                    <span>Address </span>                     
                    <i class="far fa-info-circle sys-icon" id="tooltip-target-5"></i>
                    <b-tooltip target="tooltip-target-5" triggers="hover">
                        Address appears at the bottom of the page
                    </b-tooltip>
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('Address')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>
                </h3>

                <div v-if="!editAddress" class="info-content">
                    <span>{{tenantInfo.Address}}</span> <br/>                   
                    <span v-if="tenantInfo.AddressMapLatitude && tenantInfo.AddressMapLongitude">
                        <a target="_blank" :href="`https://maps.google.com/?q=${tenantInfo.AddressMapLatitude},${tenantInfo.AddressMapLongitude}`"><i class="fa fa-map-marker"></i> {{tenantInfo.AddressMapLatitude}} , {{tenantInfo.AddressMapLongitude}}</a>
                    </span>
                </div>
                <div v-if="editAddress" class="form-group info-edit">
                    <div >
                                <input type="text" id="copmanyAddress" name="copmanyAddress" placeholder="Address" class="form-input" v-model="tempAddress" />  
                                <div class="info-edit-form">
                                <input type="number" id="copmanyAddressLat" name="copmanyAddress" placeholder="Latitude" class="form-input" v-model="tempAddressMapLatitude" />  
                                <input type="number" id="copmanyAddressLon" name="copmanyAddress" placeholder="Longitude" class="form-input" v-model="tempAddressMapLongitude" />  
                            </div>
                            </div>     
                            <br/> 
                            <div class="step-btn-container">
                                <button type="button" class="step-btn content-save-btn-address" @click="saveEditing('Address')">
                                    Save
                                </button>
                                <button type="button" role="button" class="skip-step-btn content-save-btn-address" @click="disableEditing('Address')">
                                    Cancel
                                </button>      
                            </div>  
                    </div>                
            </div>	

            <div class="settings-container col-md-3 px-0 settings-comp-info">
                <h3 class="main-label">
                    <span>Emails</span>    
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('Emails')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                 
                </h3>
                <div v-if="!editEmails && tenantInfo.Emails && tenantInfo.Emails.length>0" class="info-content">
                    <span v-for="email in tenantInfo.Emails">{{email}} <br/></span>                                       
                </div>
                <div v-if="editEmails" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="email" id="copmanyEmail" name="copmanyEmail" class="form-input" v-model="tempEmails" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('Emails')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('Emails')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>                               
            </div>	

            <div class="settings-container col-md-3 px-0 settings-comp-info">
                <h3 class="main-label">
                    <span>Contact Numbers</span>
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('ContactNumbers')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                     
                </h3>
                <div v-if="!editContactNumbers && tenantInfo.ContactNumbers && tenantInfo.ContactNumbers.length>0" class="info-content">
                    <span v-for="contactNumber in tenantInfo.ContactNumbers">{{contactNumber}} <br/></span>           
                </div>
                <div v-if="editContactNumbers" class="form-group info-edit">
                    <div class="info-edit-form">
                        <input type="text" id="copmanyPhone" name="copmanyPhone" class="form-input" v-model="tempContactNumbers" />  
                        <button type="button" class="content-action-btn save" @click="saveEditing('ContactNumbers')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('ContactNumbers')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div> 
            </div>


            <div class="settings-container col-md-3 px-0 settings-comp-info">
                <h3 class="main-label">
                    <span>WhatsApp</span>
                    <div class="right-menu">                        
                        <a role="button" class="right-menu-icon" @click="enableEditing('WhatsAppNumbers')">
                            <img src="@/assets/images/edit-icon.svg">
                        </a>
                    </div>                     
                </h3>
                <div v-if="!editWhatsAppNumbers && tenantInfo.WhatsAppNumbers && tenantInfo.WhatsAppNumbers.length>0" class="info-content">
                    <span v-for="whatsAppNumber in tenantInfo.WhatsAppNumbers">{{whatsAppNumber}} <br/></span>                 
                </div>
                <div v-if="editWhatsAppNumbers" class="form-group info-edit">
                    <div class="info-edit-form">                        
					<vue-tel-input
                    id="companyWhatsApp" name="companyWhatsApp" 
                    :class="`form-input`"
                    defaultCountry="PK"
                    :inputOptions="telInputOptions"
                    validCharactersOnly
                    v-model="tempWhatsAppNumbers"
                    :autoFormat="true"
                    @input="validateWhatsApp"
                    mode="national"
                    invalidMsg="Please enter a valid whatsApp number"
                ></vue-tel-input> 
                        <button type="button" class="content-action-btn save" @click="saveEditing('WhatsAppNumbers')">
                            <img src="@/assets/images/check-icon-green.svg" alt="save">
                        </button>
                        <button type="button" role="button" class="content-action-btn cancel" @click="disableEditing('WhatsAppNumbers')">
                            <img src="@/assets/images/cancel-icon-red.svg" alt="cancel">
                        </button>    
                    </div>          
                </div>
                              
            </div>

            <div class="settings-container col-md-12 px-0 settings-whatsapp-toggle">
                <img src="@/assets/images/whatsapp-us-button.png" alt="WhatsApp Button" class="whatsapp-button" />
                <div class="form-check form-switch mt-3">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="whatsappButton" 
                        v-model="tenantInfo.WhatsAppButton"
                        @change="updateCompanyInfo"
                    />
                    <label class="form-check-label" for="whatsappButton">Show WhatsApp Button</label>
                </div>
            </div>

        </div>
        <div class="">            
            <b-spinner v-if="loading" class="loader"> </b-spinner> 
        </div>
	</div>
    
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
	name: "CompanyInfoSettings",
    data() {
		return {
            tenantInfo:{
                Address: '',
			    Emails: [],
                ContactNumbers: [],
                WhatsAppNumbers: [],
                WhatsAppButton: false,
                AddressMapLatitude:0,
                AddressMapLongitude:0
            },
            telInputOptions: {
                autocomplete: "on",
                maxlength: 15,
                tabindex: 15,
                placeholder: "Number (required)",
                mode: "national",
            },
            loading: false,
            editAddress: false,
            editEmails: false,
            editContactNumbers: false,
            editWhatsAppNumbers: false,
            tempAddress: null,
            tempAddressMapLatitude: 0,
            tempAddressMapLongitude: 0,
            tempEmail: null,
            tempPhone: null,
            tempWhatsAppNumbers: null,

			
		};
	},
    methods: {
		...mapActions("content", ["uploadContentFile"]),
		...mapActions("appState", [
			"editSystemParameters",
			"fetchSystemParameters",			
		]),	
        enableEditing(el){
            console.warn('Enable Editing ', el);
            if(el.toLowerCase()=="emails" || el.toLowerCase()=="contactnumbers" || el.toLowerCase()=="whatsappnumbers")
            {
                if(this.tenantInfo[el] && this.tenantInfo[el].length>0){
                    this[`temp${el}`] = this.tenantInfo[el][0];
                }else{
                    this[`temp${el}`] = "";
                }

            }else if(el.toLowerCase()=="address")
            {
                
                this[`temp${el}`] = this.tenantInfo[el];                  
                this[`tempAddressMapLatitude`] = this.tenantInfo["AddressMapLatitude"];
                this[`tempAddressMapLongitude`] = this.tenantInfo["AddressMapLongitude"];
                
            }        
            else{ 
                this[`temp${el}`] = this.tenantInfo[el];   
            }
                     
            this[`edit${el}`] = true;            
        },   
        disableEditing(el){
            this[`temp${el}`] = null;
            this[`edit${el}`] = false;
            if(el.toLowerCase()=="address")
            {                             
                this[`tempAddressMapLatitude`] = null;
                this[`tempAddressMapLongitude`] = null;                
            }     
        },
        saveEditing(el){	   
            if(el.toLowerCase()=="emails" || el.toLowerCase()=="contactnumbers" || el.toLowerCase()=="whatsappnumbers")
            {
                if(this.tenantInfo[el] && this.tenantInfo[el].length>0){
                    this.tenantInfo[el][0] = this[`temp${el}`];
                }else{
                    this.tenantInfo[el] = [ this[`temp${el}`]];
                }
            }
            else if(el.toLowerCase()=="address")
            {
                
                this.tenantInfo[el] = this[`temp${el}`];                
                this.tenantInfo["AddressMapLatitude"] = this[`tempAddressMapLatitude`];
                this.tenantInfo["AddressMapLongitude"] = this[`tempAddressMapLongitude`];                
            }            
            else{ 
                this.tenantInfo[el] = this[`temp${el}`];
            }
            this.updateCompanyInfo();
            this.disableEditing(el);
		},
		updateCompanyInfo(){	            
            this.loading = true;            
			this.editSystemParameters({
				parameterName: "TenantInfo",
				parameterValue: JSON.stringify(this.tenantInfo),
			}).then(() => {
				this.fetchSystemParameters().then(() => {					 
					this.$toasted.success("Updated successfully", {
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
                    this.loading = false;                    
				});
			});
		},
        validateWhatsApp(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isWhatsAppError = true;
				return;
			}
			this.isWhatsAppError = false;
			if (obj.country && obj.country.iso2) {
				this.tempWhatsAppNumbers = obj.number;
			}

			return false;
		},
        
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
	},
	components: {},
	created() {
		if (this.systemParameters.TenantInfo) {
			//this.tenantInfo = this.systemParameters.TenantInfo;
            this.tenantInfo.Address = this.systemParameters.TenantInfo.Address;
            this.tenantInfo.AddressMapLatitude = this.systemParameters.TenantInfo.AddressMapLatitude;
            this.tenantInfo.AddressMapLongitude = this.systemParameters.TenantInfo.AddressMapLongitude;
            this.tenantInfo.Emails = this.systemParameters.TenantInfo.Emails;
            this.tenantInfo.ContactNumbers = this.systemParameters.TenantInfo.ContactNumbers;
            this.tenantInfo.WhatsAppNumbers = this.systemParameters.TenantInfo.WhatsAppNumbers;
            this.tenantInfo.WhatsAppButton = this.systemParameters.TenantInfo.WhatsAppButton;
		}		
	},
	watch: {
		systemParameters: function () {
			if (this.systemParameters.TenantInfo) {
				//this.tenantInfo = this.systemParameters.TenantInfo;
                this.tenantInfo.Address = this.systemParameters.TenantInfo.Address;
                this.tenantInfo.Emails = this.systemParameters.TenantInfo.Emails;
                this.tenantInfo.ContactNumbers = this.systemParameters.TenantInfo.ContactNumbers;
                this.tenantInfo.WhatsAppNumbers = this.systemParameters.TenantInfo.WhatsAppNumbers;
                this.tenantInfo.WhatsAppButton = this.systemParameters.TenantInfo.WhatsAppButton;
                this.tenantInfo.AddressMapLatitude = this.systemParameters.TenantInfo.AddressMapLatitude;
                this.tenantInfo.AddressMapLongitude = this.systemParameters.TenantInfo.AddressMapLongitude;
			}						
		},
	},
}
</script>

<style scoped>
/* .preferences-card .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
} */
.form-input {
    font-size: 1rem;
    outline: none;
    padding: 0.5rem;
    width: 100%;
    border-radius: 0;
    box-shadow: none !important;
    position: relative; 
    border: solid 1px #dcdcdc;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 0.75rem;
    column-gap: 0.75rem;
    padding-right: 5.25rem;
}
.settings-container .upload-btn-wrapper .loader {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	position: absolute;
}
.info-content{
    position: relative;
    padding-right: 2.75rem;
}
.info-edit .info-edit-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 100%;
}
.info-content .right-menu,
.main-label .right-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    margin-left: auto;
    margin-right: 0.75rem;
    transition: opacity 0.3s, visibility 0s ease;
    visibility: hidden;
    opacity: 0; 
    top: 0.55rem;
    transform: translateY(-50%);
    position: absolute;
    right: 0.25rem;
}
.settings-comp-info:hover .main-label .right-menu{
    visibility: visible;
    opacity: 1;
}
.info-content:hover .right-menu {
    visibility: visible;
    opacity: 1;
}

.content-action-btn.save {
    border: 1px solid #21cf47;
    color: #21cf47;
    position: absolute;
    right: 2.8rem;
    top: 0.45rem;
}
.content-action-btn.cancel {
    border: 1px solid #ff0000;
    color: #ff0000;
    position: absolute;
    right: 0.55rem;
    top: 0.45rem;
}
.whatsapp-button{
    width: 130px;
}
#copmanyAddressLat,#copmanyAddressLon{
    padding-right: 0px;
    margin-top: 5px;
}


#copmanyAddressLat{
    margin-right:5px;
}
#copmanyAddressLon{
    margin-left: 5px;
}
.content-save-btn-address{
    height: 30px;
    padding: 12px !important;
}
</style>