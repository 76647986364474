<template>
  <div class="body-content h-100" v-if="contentData && flashcards">
    <div class="eb-content flashcard-content h-100">



      <div class="flashcard-bg-row" v-if="viewMode == 'study'">
        <div class="flashcard-container justify-content-center h-100 align-items-center">
          <div :class="'flashcard-box ' + (isActive ? 'active' : '')" id="flashcardBox">

            <div class="flashcard" @click="toggleActive">
              <div class="fcard-front">
                <div class="fcard-body">
                  <div class="fcard-text">
                    {{ flashcards[activeFlashcardIndex].Slide1 }}
                  </div>
                </div><!-- /fcard-body -->

                <div class="fcard-footer justify-content-center align-items-center">
                  <div class="text-center" v-if="flashcardsPreparation[flashcards[activeFlashcardIndex].ID]">Previous
                    attempt</div>
                  <div v-if="flashcardsPreparation[flashcards[activeFlashcardIndex].ID]"
                    :class="'alert ' + (flashcardsPreparation[flashcards[activeFlashcardIndex].ID] == 'yes' ? 'alert-success' : 'alert-danger') + ' rounded-0 py-2 mb-0 ms-3'">
                    {{ (flashcardsPreparation[flashcards[activeFlashcardIndex].ID] ==
                      'yes' ?
                      'Correct' : 'Incorrect') }}
                  </div>
                </div><!-- /fcard-footer -->
              </div><!-- fcard-front -->

              <div class="fcard-back">
                <!-- <div class="fcard-header">
                  <div class="fcard-pages ms-auto align-self-start">
                    <span class="fcard-cur-page">{{ (activeFlashcardIndex + 1) }}</span> of <span
                      class="fcard-page-count">{{ flashcards.length }}</span>
                  </div>
                </div> -->

                <div class="fcard-body">
                  <div class="fcard-text">
                    {{ flashcards[activeFlashcardIndex].Slide2 }}
                  </div>
                </div><!-- /fcard-header -->

                <div class="fcard-footer justify-content-center">
                  <div class="w-100 text-center pb-1">Did you get it right?</div>
                  <div class="actions-row w-100 d-flex justify-content-center row gx-3">
                    <div class="col">
                      <button @click="setFlashcardResponse(flashcards[activeFlashcardIndex].ID, 'no')"
                        class="btn btn-outline-primary w-100">No</button>
                    </div>
                    <div class="col">
                      <button @click="setFlashcardResponse(flashcards[activeFlashcardIndex].ID, 'yes')"
                        class="btn btn-outline-primary w-100">Yes</button>
                    </div>
                  </div>
                </div><!-- /fcard-footer -->
              </div><!-- fcard-back -->

            </div><!-- /flashcard -->
          </div><!-- /flashcard-box -->
        </div>
      </div><!-- cd-body -->
      <div class="flashcard-list-sec" v-else>
        <div class="eb-header eb-header-auto border-bottom fc-list-header">

          <div class="row flash-list-subheader mx-0 w-100">

            <div class="col-4 fc-list-top-info ps-4 py-4">
              <b>{{ flashcards.length }}</b>
              <p>Number of Cards</p> 
            </div>
            <div class="col-4 fc-list-top-info ps-4 py-4">
              <b class="text-success">{{ getCorrectAnswersLength() }}</b>
              <p>Correct</p>
            </div>
            <div class="col-4 fc-list-top-info ps-4 py-4">
              <b class="text-danger">{{ getIncorrectAnswersLength() }}</b>
              <p>Incorrect</p>
            </div>
          </div>

        </div>
        <div class="flashcard-table-wrapper">
          <table class="table flashcard-table">
            <thead>
              <tr class="bg-light">
                <th scope="col" width="5%" class="font-medium bg-light">#</th>
                <th scope="col" width="20%" class="font-medium bg-light">Side 1</th>
                <th scope="col" class="font-medium bg-light">Side 2</th>
                <th scope="col" width="15%" class="font-medium bg-light">Response</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(flashcard, index) in flashcards">
                <td>{{ (index + 1) }} </td>
                <td>{{ flashcard.Slide1 }}</td>
                <td>{{ flashcard.Slide2 }}</td>
                <td>
                  <img v-if="showFlashcardResponse(flashcard) != 'Not Answered'"
                    :src="getStatusIconPath(showFlashcardResponse(flashcard))" class="status-icon">
                </td>
              </tr>


            </tbody>
          </table>
        </div>
      </div><!-- /eb-content -->


      <div class="flashcard-footer-row">
        <div class="fcard-progress">
          <div class="progress mb-1">
            <div class="progress-bar" role="progressbar" :style="{ width: `${getProgress}%` }"
              :aria-valuenow="getProgress" aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>

        <div class="fcard-footer-actions eb-sub-header bg-white py-1 justify-content-between row mx-0">
          <div class="fcard-actions-col-1 col-sm-4 col-0">

          </div>

          <div class="fcard-actions-col-2 col-sm-4 col-5">
            <div v-if="viewMode == 'study'" class="eb-chapter-arrows d-flex justify-content-center">
              <button :disabled="activeFlashcardIndex <= 0" @click="previousFlashcard" class="eb-chapter-prev"><i
                  class="fas fa-caret-left"></i></button>
              <div class="fcard-pages mx-2">
                <span class="fcard-cur-page">{{ (activeFlashcardIndex + 1) }}</span> / <span class="fcard-page-count">{{
                  flashcards.length }}</span>
              </div>
              <button :disabled="activeFlashcardIndex >= (flashcards.length - 1)" @click="nextFlashcard"
                class="eb-chapter-next"><i class="fas fa-caret-right"></i></button>
            </div>
          </div>

          <div class="fcard-actions-col-3 eb-chapter-controls ms-auto d-flex justify-content-end col-sm-4 col-7">

            <button @click="resetFlashcard" class="eb-rotate-control pt-1 me-1">
              <i class="fas fa-undo fa-flip-horizontal"></i>
            </button>
            <!-- <button @click="toggleViewMode" class="eb-view-expand ms-3">
              <img class="page-view-icon" src="@/assets/images/view-expand-icon.png" />              
            </button> -->
            <button @click="toggleViewMode" class="eb-filter-toggle ms-3">
              <img v-if="viewMode == 'study'" class="page-view-icon" src="@/assets/images/align-right-icon.svg" />
              <i v-else class="fa fa-credit-card"></i>
            </button>
            

          </div>
        </div><!-- eb-sub-header -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Flashcards",
  components: {},
  data() {
    return {
      flashcards: [],
      activeFlashcardIndex: 0,
      isActive: false,
      viewMode: "study",
      flashcardsPreparation: {},
      isCompleted: false,
    };
  },
  props: {
    contentData: Object
  },
  methods: {
    ...mapActions("content", ["getContentById", "setUserContentAction", "getUserContentAction"]),
    ...mapActions("productVariant", ["getProductVariantProgress"]),
    ...mapActions("content", ["updateAction"]),

    nextFlashcard: function () {
      if ((this.activeFlashcardIndex + 1) < this.flashcards.length) {
        this.activeFlashcardIndex++;
        this.isActive = false;
      }
    },
    toggleViewMode: function () {
      if (this.viewMode == "study") {
        this.viewMode = "list";
      } else {
        this.viewMode = "study";
      }
    },
    previousFlashcard: function () {
      if ((this.activeFlashcardIndex - 1) > -1) {
        this.activeFlashcardIndex--;
        this.isActive = false;
      }
    },
    toggleActive: function () {
      this.isActive = !this.isActive;
    },
    resetFlashcard: function () {
      this.isActive = false;
      this.activeFlashcardIndex = 0;
    },
    setFlashcardResponse: function (id, answer) {
      this.flashcardsPreparation[id] = answer;
      var contentAction = {
        ContentID: this.contentData.id,
        DataType: "flashcardResponse",
        Data: JSON.stringify(this.flashcardsPreparation)
      }

      this.setUserContentAction(contentAction).then((action) => {
        if (action && action.dataType && action.dataType.toLowerCase() == "flashcardresponse") {
          this.flashcardsPreparation = JSON.parse(action.data);
          var self = this.flashcardsPreparation;
          this.flashcardsPreparation = null;
          this.flashcardsPreparation = self;

          this.updateAction({
            "id": action.id,
            "userID": action.userID,
            "contentID": this.contentData.id,
            "dataType": action.dataType,
            "data": action.data
          });

          let completed = this.isAllCompleted()
          if (completed && !this.isCompleted) {
            this.markCompleted(completed);

          }
        }
      });
    },
    getFlashcardResponse: function () {
      this.getUserContentAction({ contentId: this.contentData.id, dataType: "flashcardResponse" }).then((userActions) => {
        var userAction = userActions ? userActions.find(x => x.dataType.toLowerCase() == "flashcardresponse") : null;
        if (userAction) {
          debugger;
          this.flashcardsPreparation = JSON.parse(userAction.data);
          var self = this.flashcardsPreparation;
          this.flashcardsPreparation = null;
          this.flashcardsPreparation = self;
        }
      });
    },
    isAllCompleted() {
      let response = this.flashcards.every(card => !!this.flashcardsPreparation[card.ID]);
      return response;
    },
    markCompleted: function (isCompleted) {
      if (this.contentData.id) {
        var contentAction = {
          ContentID: this.contentData.id,
          DataType: "completionStatus",
          Data: isCompleted ? "completed" : "uncompleted"
        }

        this.setUserContentAction(contentAction).then((action) => {
          if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
            this.getProductVariantProgress(this.$route.params.id);
            this.updateAction({ "id": action.id, "userID": action.userID, "contentID": this.contentData.id, "dataType": action.dataType, "data": action.data });

          }

        });
      }
    },
    getCompletedStatus: function () {
      if (this.contentData.id) {
        this.getUserContentAction({ contentId: this.contentData.id, dataType: "completionStatus" }).then((userActions) => {
          var userAction = userActions ? userActions.find(x => x.dataType == "completionStatus") : null;
          if (userAction) {
            this.isCompleted = userAction.data === 'completed'
          }
        });
      }
    },
    showFlashcardResponse(card) {
      var answer = this.flashcardsPreparation &&
        this.flashcardsPreparation[card.ID]
        ? this.flashcardsPreparation[card.ID] == 'yes' ? "Correct" : "Incorrect" : 'Not Answered';
      return answer;
    },
    getStatusIconPath(action) {
      return action === 'Correct' ? require('@/assets/images/check-icon-green-circle.svg') : require('@/assets/images/cross-icon.svg');
    },
    getCorrectAnswersLength() {
      let counter = 0;
      for (let card of this.flashcards) {
        if (this.showFlashcardResponse(card) == 'Correct') {
          counter++;
        }
      }
      return counter;
    },
    getIncorrectAnswersLength() {
      let counter = 0;
      for (let card of this.flashcards) {
        if (this.showFlashcardResponse(card) == 'Incorrect') {
          counter++;
        }
      }
      return counter;
    },

    getNotAnsweredLength() {
      let counter = 0;
      for (let card of this.flashcards) {
        if (this.showFlashcardResponse(card) === 'Not Answered') {
          counter++;
        }
      }
      return counter;
    },

  },
  computed: {
    getProgress() {
      if (this.flashcards.length === 0) {
        return "0";
      }

      const progress = ((this.activeFlashcardIndex+1) / this.flashcards.length) * 100;

      return `${progress.toFixed(0)}`;
    }

  },
  created() {
    var flashcardsData = this.contentData.data ? JSON.parse(this.contentData.data) : null;
    if (flashcardsData) {
      this.flashcards = flashcardsData.Flashcards;
    }
    this.getFlashcardResponse();
    this.getCompletedStatus()
  },
  mounted() {
  },
  // beforeDestroy() {
  // 	$("body").off("onmousedown");
  // },
  watch: {
    contentData: function () {

      var flashcardsData = this.contentData.data ? JSON.parse(this.contentData.data) : null;
      if (flashcardsData) {
        this.flashcards = flashcardsData.Flashcards;
        this.flashcards.sort(function (a, b) { return a.Sequence - b.Sequence })
      }
    }
  },
};
</script>

<style scoped>

.status-icon {
  margin-left: 20px;
  width: 24px;
  height: auto;
}

</style>
<style>
/*======= Flashcards =========*/
.flashcard-container {
  margin: 0px;
}

.has-floating-menu.Flashcards .floating-menu+.chapter-content-left {
  padding-right: 0;
  height: 100% !important;
}

.flashcard-box {
  height: 100%;
}

.flashcard {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;

}


.flashcard-box.active .flashcard {
  /* transform: rotateY(180deg); 
  animation: flipOut .25s ease-in-out 1; */
}

.fcard-front,
.fcard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex-direction: column;
  display: flex;
  background: white;
  animation-fill-mode: forwards;
  z-index: 1;
}

.fcard-front {
  animation: flipIn .25s ease-in-out 1;
  z-index: 4;
}

.fcard-back {
  animation: flipOut .25s ease-in-out 1;
  opacity: 0;
}

.flashcard-box.active .fcard-back {
  animation: flipIn .25s ease-in-out 1;
  z-index: 6;
  opacity: 1;

}

.flashcard-box.active .fcard-front {
  animation: flipOut .25s ease-in-out 1;
}

@keyframes flipIn {
  0% {
    transform: rotateX(180deg)
  }

  to {
    transform: rotateX(1turn)
  }
}

@keyframes flipOut {
  0% {
    transform: rotateX(0deg)
  }

  to {
    transform: rotateX(180deg)
  }
}


.fcard-header {
  /* border-bottom: solid 1px rgba(var(--primary-color-rgb), 0.25); */
  height: 60px;
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.fcard-pages span {
  font-size: 14px;
}

.fcard-pages span {
  font-weight: 600;
}

.fcard-footer {
  /* border-top: solid 1px rgba(var(--primary-color-rgb), 0.4); 
  height: 84px; */
  display: flex;
  padding: 10px 10px 20px;
  flex-wrap: wrap;
}

.fcard-body {
  height: 209px;
  overflow: hidden;
  /* background: linear-gradient(to bottom, white 29px, rgba(0, 0, 0, 0.09) 1px);
  background-size: 100% 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10%;
  text-align: center;
  cursor: pointer;
}

.fcard-front .fcard-body,
.fcard-back .fcard-body {
  flex-grow: 1;
}

.fcard-text {
  color: var(--flashcard-text-color);
  font-size: 2.55rem;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  line-height: 1.25;
}

.flashcard-table>:not(caption)>*>*,
.flashcard-table tr td {
  padding-bottom: 1.25rem;
  padding-top: 0.8rem;
}

.flashcard-table>:not(caption)>*>* {
  padding-bottom: 0.8rem;
}

.flashcard-table tbody tr:last-child td {
  border-bottom: none !important;
}

.fcard-progress .progress {
  height: 6px;
}

.fcard-progress .progress .progress-bar{
  background: var(--primary-color);
}

.Flashcards .floating-menu {
  top: 20px;
  margin: 0;
  right: 10px;
  position: absolute;
  z-index: 5;
}

.fcard-footer .actions-row .col {
  flex: 0 0 auto;
  width: 24%;
}

.flashcard-header-row {
  height: 36px;
}

.flashcard-footer-row {
  height: 50px;
  background-color: white;
}
.fcard-footer-actions .page-view-icon{
  width:auto;
  height: 18px;
}
.fcard-footer-actions .fa-credit-card{
  font-size: 1.4rem;
}
.flashcard-bg-row {
  height: calc(100% - 50px);
}

/*----------- Flashcard List -------*/
.flashcard-list-sec{
  height: calc(100% - 50px);
    overflow: auto;
}
.fc-list-header,
.flashcard-table-wrapper{
  /* width: calc(100% - 55px); */
  position: relative;
    background-color: white;
    z-index: 6;
}
.fc-list-top-info{
  border-right: solid 1px var(--border-color);
}
.fc-list-top-info b{
  font-size: 28px;
  line-height: 1;
}
.fc-list-top-info p{
  margin:0;
  color: var(--color-dark-gray);
  line-height: 1;
}
.flashcard-table-wrapper .flashcard-table thead tr th{
  padding-top:12px;
  padding-bottom: 10px;
}
.flashcard-table-wrapper .flashcard-table>tbody>tr>td {
    padding: 12px 8px;
    vertical-align: middle;
}
/* .flashcard-table-wrapper{
  border-right: solid 1px var(--border-color);
  width: calc(100% - 55px);
} */

@media (max-width:768px){
  .flashcard-bg-row {
      height: calc(100vh - 280px);
  }
  .fcard-text {
      font-size: 2rem;
  }
  .flashcard-list-sec {
    font-size: 1rem;
  }
  .flashcard-list-sec .eb-header{
    padding: 0;
  }
  .flashcard-table-wrapper .flashcard-table thead tr th:nth-child(2) {
      width: 30% !important;
  }
}

@media (max-width:576px){

   .flashcard-table .status-icon {
      margin-left: 6px;
      width: 20px;
   }
}
</style>
