<template>
	<div>
		<DashbooardBanner class="cart-banner">
			Shopping Cart Custom
		</DashbooardBanner>
		<!-- <div class="container cart-content-container">
			<div
				class="col-lg-8 cart-product-list container-fluid"
				v-if="!isCartEmpty"
			>
				<CartProductItem
					v-for="item in getCartItems"
					:key="item.id"
					:productVariantData="item"
				/>
			</div>
			<div
				class="col-lg-4 cart-payment-card container-fluid"
				v-if="!isCartEmpty"
			>
				<PaymentCard />
			</div>
			<div v-else class="container-fluid text-center p-5">
				<h2 class="text-bold text-muted">No items in cart.</h2>
			</div>
		</div> -->
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import DashbooardBanner from "../../components/Layout/InnerPageBanner.vue";
// import CartProductItem from "../components/Cart/CartProductItem.vue";
// import PaymentCard from "../components/Cart/PaymentCard.vue";
export default {
	name: "Cart",
	components: { DashbooardBanner },
	computed: {
		...mapGetters("cart", ["getCartItems", "isCartEmpty"]),
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.cart-content-container {
		flex-direction: column-reverse;
		padding: 0 !important;
	}
	.cart-payment-card {
		margin-bottom: -4rem;
	}

	.cart-banner {
		padding-bottom: 8rem !important;
	}
}

@media screen and (max-width: 768px) {
}

.cart-banner {
	padding-bottom: 6rem;
}

.cart-product-list {
	margin-top: 2rem;
}

.cart-content-container {
	display: flex;
	padding: 0;
}
</style>
