<template>
	<b-modal
		@change="closeModal"
		id="create-product-variant-modal"
		:visible="visible"
		size="xl"
		hide-header
		hide-footer
		centered
		content-class="create-product-variant-modal"
		body-class="modal-body"
		modal-class="custom-modal"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="custom-stepper-container">
			<!--		<div class="custom-stepper">
				<div class="custom-stepper-step">
					
					<div
						:class="[
							'step-icon first',
							{ active: currentStep == 1, complete: currentStep > 1 },
						]"
					>
						1
					</div>
					<div class="step-text">
						<h1 class="title">Basic information</h1>
						<h3 class="subtitle">Fill the main promise of your batch?</h3>
					</div>
				</div>
				<div v-if="!isEditMode" class="custom-stepper-step">
					<div
						:class="[
							'step-icon',
							{ active: currentStep == 2, complete: currentStep > 2 },
						]"
					>
						2
					</div>
					<div class="step-text">
						<h1 class="title">Subjects</h1>
						<h3 class="subtitle">Manage your subject</h3>
					</div>
				</div>
				<div class="custom-stepper-step">
					<div :class="['step-icon last', { active: currentStep == 3 }]">
						{{ isEditMode ? 2 : 3 }}
					</div>
					<div class="step-text">
						<h1 class="title">batch Description</h1>
						<h3 class="subtitle">Write your batch information</h3>
					</div>
				</div>
			</div>
			Custom Stepper-->
		</div>
		<div class="custom-stepper-body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-content-title">
				{{ isEditMode ? "Edit" : "Create New" }} Batch
			</h1>
			<div id="create-form">
				<div v-if="currentStep == 1" id="1" class="step-1">
					<div class="inline-group mb-2">
						<div class="col-lg-8 ps-0">
							<div class="form-group">
								<label class="main-label" for="title"
									>Your Title (required)</label
								>
								<input
									placeholder="Type your batch name here..."
									class="form-input"
									type="search"
									name="title"
									id="title"
									v-model="title"
									required
									@change="isEditMode && update($event)"
									autofocus
								/>
							</div>
						</div>
						<div class="col-lg-4 ps-0">
							<div class="form-group">
								<form id="image-form">
									<label class="main-label">Batch image (optional)</label>
									<div
										:class="[
											'upload-btn-wrapper',
											{ preview: thumbnailMediaPath },
										]"
									>
										<img
											:class="[{ 'd-none': !thumbnailMediaPath }]"
											id="preview"
											:src="thumbnailMediaPath"
											alt="preview"
										/>
										<div v-if="thumbnailMediaPath" class="right-menu">
											<a
												role="button"
												class="right-menu-icon"
												@click="openDialog"
											>
												<img src="@/assets/images/edit-icon.svg" />
											</a>
											<a
												@click="removeFile"
												role="button"
												class="right-menu-icon delete"
											>
												<img src="@/assets/images/delete-icon.svg" />
											</a>
										</div>
										<button
											v-if="!thumbnailMediaPath"
											type="button"
											class="upload-btn"
											@click="openDialog"
										>
											upload
										</button>
										<input
											class="form-input"
											type="file"
											accept="image/*,video/*"
											name="file"
											id="image-upload"
											@change="getFile"
										/>
									</div>
									<input
										type="search"
										value="ProductVariant"
										name="entity"
										hidden
									/>
									<input
										type="search"
										v-model="thumbnailMediaType"
										name="type"
										hidden
									/>
								</form>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label class="main-label" for="status">status (Required)</label>
						<div class="status-cards">
							<div class="status-card-container">
								<input
									type="radio"
									name="status"
									:value="ProductVariantStatus.DRAFT"
									id="draft"
									v-model="status"
									@change="isEditMode && update($event)"
								/>
								<label class="status-card" for="draft">
									<p>{{ ProductVariantStatus.DRAFT }}</p>
									The batch will be in authoring mode hence not
									published/visible.
								</label>
							</div>
							<div class="status-card-container">
								<input
									type="radio"
									name="status"
									:value="ProductVariantStatus.PUBLISHED"
									id="published"
									v-model="status"
									@change="isEditMode && update($event)"
								/>
								<label class="status-card" for="published">
									<p>{{ ProductVariantStatus.PUBLISHED }}</p>
									The batch will be in published mode hence visible.
								</label>
							</div>
							<div class="status-card-container">
								<input
									type="radio"
									name="status"
									id="coming_soon"
									:value="ProductVariantStatus.COMING_SOON"
									v-model="status"
									@change="isEditMode && update($event)"
								/>
								<label class="status-card" for="coming_soon">
									<p>{{ ProductVariantStatus.COMING_SOON }}</p>
									The batch will be in coming soon mode.
								</label>
							</div>
							<div class="status-card-container">
								<input
									type="radio"
									name="status"
									:value="ProductVariantStatus.PRIVATE"
									id="private"
									v-model="status"
									@change="isEditMode && update($event)"
								/>
								<label class="status-card" for="private">
									<p>{{ ProductVariantStatus.PRIVATE }}</p>
									The batch will be in private hence not visible to all.
								</label>
							</div>
						</div>
					</div>
					<div class="flex-row mt-5">
						<div class="col-lg-6 ps-0">
							<div class="form-group">
								<label for="start-date" class="main-label m-0"
									>start date (optional)</label
								>
								<input
									placeholder="Select start date"
									class="form-input"
									type="date"
									name="startDate"
									id="start-date"
									v-model="startDate"
									@change="isEditMode && update($event)"
								/>
							</div>
						</div>
						<div class="col-lg-6 ps-0">
							<div class="form-group">
								<label for="end-date" class="main-label m-0"
									>end date (optional)</label
								>
								<input
									placeholder="Select end date"
									class="form-input"
									type="date"
									name="endDate"
									id="end-date"
									v-model="endDate"
									@change="isEditMode && update($event)"
								/>
							</div>
						</div>
					</div>
					<div class="form-group mt-4">
						<label for="productVariantTrainers" class="main-label m-0"
							>Trainers (optional)</label
						>
						<v-select
							multiple
							v-model="productVariantTrainers"
							name="productVariantTrainers"
							id="productVariantTrainers"
							class="form-input"
							:options="filteredTrainers"
							:reduce="(option) => option.id"
							label="firstName"
							:clearable="true"
							:deselectFromDropdown="true"
							:appendToBody="true"
							:autoscroll="true"
							:calculate-position="withPopper"
							:closeOnSelect="false"
							placeholder="Select trainers for this batch"
							@input="isEditMode && updateTrainers($event)"
						>
							<template #selected-option="{ firstName, lastName }">
								<div
									style="
										display: flex;
										align-items: baseline;
										column-gap: 0.5rem;
									"
								>
									{{ firstName }} {{ lastName }}
								</div>
							</template>
							<template slot="option" slot-scope="option">
								<div
									style="
										display: flex;
										flex-direction: row;
										column-gap: 1rem;
										align-items: center;
										height: 3rem;
									"
									class="trainer-list-item"
								>
									<img
										v-if="option.profilePicturePath"
										class="user-image"
										:src="option.profilePicturePath"
									/>
									<div v-else class="user-avatar">
										<span>{{ option.firstName[0] }}</span>
									</div>
									{{ option.firstName }} {{ option.lastName }} (
									{{ option.email }} )
								</div>
							</template>
						</v-select>
					</div>
					<div v-if="showAdditionalOptions" class="form-group">
						<label for="short-description" class="main-label mt-4">
							Short Description (optional)
						</label>
						<textarea
							placeholder="Type your batch short description..."
							class="form-input"
							name="shortDescription"
							id="short-description"
							v-model="shortDescription"
							required
							rows="1"
							@change="isEditMode && update($event)"
						/>
					</div>
					<button
						@click="() => (showAdditionalOptions = true)"
						class="cursor-pointer d-flex mt-3"
						v-else
					>
						+ Show additional fields
					</button>
				</div>
				<div v-if="currentStep == 2" class="step-2">
					<h1 class="add-content-title">Add Subjects to this batch</h1>
					<label for="create-content" class="main-label mt-4">
						Add existing Subjects
					</label>
					<ProductVariantContentAddExisting
						@addContent="addContentToList"
						:contentList="contentList"
					/>
					<div class="form-group">
						<label for="create-content" class="main-label mt-4">
							Create Subjects
						</label>

						<ProductVariantContentCreate
							@addContentToList="addContentToList"
							@deleteContent="removeContentFromList"
							:contentList="contentList"
							:contentToCreate="contentToCreate"
							:availableTo="endDate"
							:availableFrom="startDate"
						/>
					</div>
				</div>
				<div v-if="currentStep == 3" class="step-3">
					<h1 class="add-content-title">Write your batch information</h1>
					<div class="form-group">
						<label for="short-description" class="main-label mt-4">
							Short Description (optional)
						</label>
						<input
							placeholder="Type your batch short description..."
							class="form-input"
							type="search"
							name="shortDescription"
							id="short-description"
							v-model="shortDescription"
							required
							@change="isEditMode && update($event)"
						/>
					</div>
					<div class="form-group">
						<label for="long-description" class="main-label mt-4">
							Long Description (optional)
						</label>
						<textarea
							placeholder="Type your batch short description..."
							class="form-input"
							name="longDescription"
							id="long-description"
							v-model="longDescription"
							required
							rows="1"
							@change="isEditMode && update($event)"
						/>
					</div>
					<div class="form-group mt-4">
						<div class="form-check form-switch">
							<input
								class="form-check-input"
								name="isFeatured"
								type="checkbox"
								role="switch"
								id="isFeatured"
								v-model="isFeatured"
								@change="isEditMode && update($event)"
							/>
							<label class="form-check-label" for="isFeatured"
								>Make this batch 'Featured'</label
							>
						</div>
					</div>
				</div>
			</div>
			<span class="text-danger" v-if="isError">
				<i class="fa fa-exclamation-circle"></i> {{ errorText }}
			</span>
			<div class="step-btn-container mt-4">
				<button v-if="showSkip" @click="skip" role="button" class="step-btn">
					skip
				</button>
				<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
					><i class="fas fa-long-arrow-alt-left"></i> prev
				</a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small> </b-spinner>
					{{ stepButtonContent }} <i class="fas fa-long-arrow-alt-right"></i>
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import ProductVariantContentCreate from "./ProductVariantContentCreate.vue";
import ProductVariantContentAddExisting from "./ProductVariantContentAddExisting.vue";
// import ProductVariant from "../../../models/productVariant";
// import { POST_FILE_API } from "../../../constants/urls";
import { mapActions, mapState } from "vuex";
// import cookieHelper from "../../../helpers/CookieHelpers";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import { createPopper } from "@popperjs/core";
import { ProductVariantStatus } from "../../../constants/productVariant";

export default {
	name: "CreateProductVariantModal",
	data() {
		return {
			currentStep: 1,
			title: null,
			thumbnailMediaType: "image",
			status: ProductVariantStatus.DRAFT,
			totalSteps: 1,
			thumbnailMediaPath: null,
			startDate: null,
			endDate: null,
			isFeatured: true,
			longDescription: null,
			shortDescription: null,
			contentList: [],
			contentToCreate: [],
			contentCreated: false,
			trainersAssigned: false,
			isError: false,
			errorText: "",
			mode: "create",
			updateObject: {},
			productVariantTrainers: [],
			showAdditionalOptions: false,
			ProductVariantStatus,
		};
	},
	components: { ProductVariantContentCreate, ProductVariantContentAddExisting },
	computed: {
		...mapState("productVariant", ["loading"]),
		...mapState("user", ["trainers"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			} else return "Finish";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		showSkip() {
			if (this.currentStep == 2) {
				return true;
			}
			return false;
		},
		isVisible() {
			if (this.status == "published") {
				return true;
			} else return false;
		},
		disableNext() {
			if (this.currentStep == 1) {
				if (
					this.title &&
					this.status
					//  &&
					// this.thumbnailMediaPath
					// &&
					// this.thumbnailMediaType &&
					// this.startDate &&
					// this.endDate
					// &&new Date(this.startDate) < new Date(this.endDate)
				) {
					return false;
				} else {
					return true;
				}
			} else if (this.currentStep == 2) {
				if (this.contentList.length == 0) {
					return true;
				} else return false;
			}
		},
		filteredTrainers() {
			return this.trainers ? this.trainers : [];
		},
	},
	methods: {
		...mapActions("productVariant", [
			"createProductVariant",
			"updateProductVariant",
		]),
		...mapActions("content", [
			"createDirectoryContent",
			"assignTrainersToContent",
			"uploadContentFile",
		]),
		...mapActions("user", ["fetchTrainersList"]),
		next() {
			if (this.currentStep == 1) {
				if (
					this.startDate &&
					this.startDate != "" &&
					this.endDate &&
					this.endDate != "" &&
					new Date(this.startDate) > new Date(this.endDate)
				) {
					this.isError = true;
					this.errorText = "Start Date cannot be after End Date!";
					return;
				} else {
					this.isError = false;
					this.errorText = "";
				}
				// if (this.isEditMode) {
				// 	this.currentStep = 3;
				// 	return;
				// }
			}
			if (this.currentStep == 2) {
				if (this.contentToCreate && this.contentToCreate.length > 0) {
					this.contentToCreate.map((content) => {
						// var contentObject = {};
						// delete content.id;
						content.data = {};
						content.type = "Directory";
						this.createDirectoryContent(content)
							.then((res) => {
								if (res) {
									this.contentList = this.contentList.map((c) => {
										if (content.id == c.id) {
											return res;
										}
										return c;
									});
									this.contentToCreate = this.contentToCreate.filter(
										(created) => created.id != content.id
									);
									this.contentCreated = true;
								}
							})
							.then(() => {
								var toAssignTrainer = this.contentList.find((c) => {
									if (!c.id.includes("new")) {
										if (c.trainers && c.trainers.length > 0) {
											return true;
										}
									}
								});

								if (toAssignTrainer) {
									this.contentList.map((content) => {
										if (!content.id.includes("new")) {
											if (content.trainers && content.trainers.length > 0) {
												var assignTrainersObject = {
													id: content.id,
													trainers: content.trainers,
												};
												this.assignTrainersToContent(assignTrainersObject).then(
													() => {
														console.log(
															"trainers assigned to existing content"
														);
														this.trainersAssigned = true;
														this.currentStep = 3;
													}
												);
												// .catch((error) => {
												// 	alert(error);
												// });
											}
										}
									});
								} else {
									this.trainersAssigned = true;

									this.currentStep = 3;
								}
							});
						// .catch((error) => alert(error));
						// return content;
					});
				} else {
					this.contentCreated = true;
					var toAssignTrainer = this.contentList.find((c) => {
						if (!c.id.includes("new")) {
							if (c.trainers && c.trainers.length > 0) {
								return true;
							}
						}
					});

					if (toAssignTrainer) {
						this.contentList.map((content) => {
							if (!content.id.includes("new")) {
								if (content.trainers && content.trainers.length > 0) {
									var assignTrainersObject = {
										id: content.id,
										trainers: content.trainers,
									};
									this.assignTrainersToContent(assignTrainersObject).then(
										() => {
											console.log("trainers assigned to existing content");
											this.trainersAssigned = true;
											this.currentStep = 3;
										}
									);
									// .catch((error) => {
									// 	alert(error);
									// });
								}
							}
						});
					} else {
						this.trainersAssigned = true;
						this.currentStep = 3;
					}
				}

				// if (this.trainersAssigned && this.contentCreated) {
				// 	this.currentStep = 3;
				// }
			} else if (this.currentStep < this.totalSteps) {
				this.currentStep += 1;
			} else if (this.currentStep == this.totalSteps) {
				// this.contentList.length > 0
				// 	? ()
				// 	: null;
				this.title = this.title.trim();
				if (this.title.length > 0) {
					if (this.isEditMode) {
						if (Object.keys(this.updateObject).length == 0) {
							Object.assign(this.$data, this.$options.data.call(this));
							this.closeModal();
							return;
						}
						this.updateObject.id = this.productVariantData.id;
						this.updateProductVariant(this.updateObject).then((response) => {
							this.$emit("updateProductVariant", response);
							this.$emit("updateModalState", false);
							Object.assign(this.$data, this.$options.data.call(this));
							return;
						});
						return;
					}
					var productVariant = {
						productId: this.productId,
						title: this.title,
						isVisible: this.isVisible,
						isFeatured: this.isFeatured,
						status: this.status,
						thumbnailMediaType: this.thumbnailMediaType,
						thumbnailMediaPath: this.thumbnailMediaPath,
						longDescription: this.longDescription,
						shortDescription: this.shortDescription,
						pricingStatus: "Paid",
					};
					if (this.startDate) {
						productVariant.startDate = new Date(this.startDate).toISOString();
					}
					if (this.endDate) {
						productVariant.endDate = new Date(this.endDate).toISOString();
					}
					if (this.contentList.length > 0) {
						this.contentList = this.contentList.map((content) => {
							var contentObject = {
								id: content.id,
								title: content.title,
								type: "Directory",
							};
							return contentObject;
						});
						productVariant.items = this.contentList;
					}
					if (this.productVariantTrainers.length > 0) {
						var trainersObject = {
							trainers: JSON.stringify(this.productVariantTrainers),
						};
						productVariant.extraproperties = JSON.stringify(trainersObject);
					}
					this.createProductVariant(productVariant).then(() => {
						this.closeModal();
						Object.assign(this.$data, this.$options.data.call(this));
					});
				} else {
					this.errorText = "Title is required field";
					this.isError = true;
				}
			}
		},
		prev() {
			if (this.isEditMode) {
				this.currentStep = 1;
				return;
			}
			if (this.currentStep > 1) {
				this.currentStep -= 1;
			}
		},
		skip() {
			console.log("step skipped");
			this.currentStep += 1;
			this.contentList = [];
		},
		getFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.thumbnailMediaPath = URL.createObjectURL(file);

				document.getElementById("preview").title = file.name;
				this.thumbnailMediaType = file.type.split("/")[0];

				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "ProductVariant");
				formData.append("type", "Image");
				formData.append("IsCompressionRequired", true);
				console.log(...formData);
				this.uploadContentFile(formData).then((data) => {
					console.log(data);
					this.thumbnailMediaPath = data;
					$(".upload-btn-wrapper").addClass("success");
					if (this.isEditMode) {
						this.updateObject.thumbnailMediaPath = this.thumbnailMediaPath;
						this.updateObject.thumbnailMediaType = this.thumbnailMediaType;
					}
				});
			}
			console.log("file: ", e.target.files);
		},
		addContentToList(item) {
			if (item.id.includes("new")) {
				console.log(item);
				this.contentToCreate.push(item);
			}

			this.contentList.push(item);
		},
		removeContentFromList(id) {
			console.log("removing content", id);
			this.contentList = this.contentList.filter((item) => item.id !== id);
			if (id.includes("new")) {
				this.contentToCreate.filter((item) => item.id !== id);
			}
		},
		closeModal() {
			this.$emit("updateModalState", false);
		},
		openDialog() {
			$("#image-upload").click();
		},
		update(e) {
			
			// console.log(typeof e)
			if (e.target.name == "status") {
				this.updateObject["isVisible"] = this.isVisible;
				this.updateObject["status"] = e.target.value;
				return;
			} else if (e.target.name == "isFeatured") {
				this.updateObject["isFeatured"] = this.isFeatured;
				return;
			} else if (e.target.name == "endDate" || e.target.name == "startDate") {
				this.updateObject[e.target.name] = e.target.value?new Date(
					e.target.value
				).toISOString():null;
				if(e.target.name == "startDate")
				{
					this.updateObject["isStartDateUpdate"] = true;
				}else{
					this.updateObject["isEndDateUpdate"] = true;
				}
				return;
			}
			this.updateObject[e.target.name] = e.target.value;
		},
		updateTrainers(value) {
			// console.log("updateTrainers", value);
			var trainersObject = {
				trainers: JSON.stringify(value),
			};
			// console.log("updateTrainers", trainersObject);
			this.updateObject.extraproperties = JSON.stringify(trainersObject);
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		removeFile() {
			this.thumbnailMediaPath = "";
			this.thumbnailMediaType = "";
			if (this.isEditMode) {
				this.updateObject.thumbnailMediaPath = this.thumbnailMediaPath;
				this.updateObject.thumbnailMediaType = this.thumbnailMediaType;
			}
		},
	},
	props: {
		showModal: Boolean,
		productId: String,
		isEditMode: Boolean,
		productVariantData: Object,
	},
	watch: {
		productId: function () {
			Object.assign(this.$data, this.$options.data.call(this));
		},
		visible: function () {
			// this.isEditMode ? (this.mode = "edit") : (this.mode = "create");
			if (this.visible) {
				setTimeout(() => {
					$("#title").focus();
				}, 300);

				if (this.isEditMode) {
					this.title = this.productVariantData.title;
					this.thumbnailMediaPath = this.productVariantData.thumbnailPath
						? this.productVariantData.thumbnailPath
						: "https://lms-v4.gocbeglobal.com/img/sample-thumbnail.d9584282.jpeg";
					this.thumbnailMediaType = this.productVariantData.thumbnailType
						? this.productVariantData.thumbnailType
						: "image";
					this.status = this.productVariantData.status
						? this.productVariantData.status
						: "draft";
					this.startDate = this.productVariantData.startDate
						? getInputDateFormat(this.productVariantData.startDate)
						: null; //this.productVariantData.startDate;

					this.endDate = this.productVariantData.endDate
						? getInputDateFormat(this.productVariantData.endDate)
						: null;
					this.isFeatured = this.productVariantData.isFeatured;
					this.longDescription = this.productVariantData.longDescription;
					this.shortDescription = this.productVariantData.shortDescription;
					if (
						this.productVariantData.properties &&
						this.productVariantData.properties.trainers
					) {
						var trainersList =
							// JSON.parse(
							this.productVariantData.properties.trainers;
						// );
						trainersList = JSON.parse(trainersList);
						// console.log(trainersList);
					}
					this.productVariantTrainers = trainersList;
				}
				// if (!this.trainers)
				this.fetchTrainersList().then(() =>
					console.log("trainers list fetched")
				);
			}

			// this.contentList = this.productVariantData.productVariantItems;
		},
	},
};

// {
//   "productID": "6",
//   "title": "English",
//   "extraProperties": "{\"features\":[\"32 hours on-demand video\",\"106 articles\",\"47 downloadable resources\", \"Full lifetime access\", \"Access on mobile and TV\", \"Certificate of Completion\"], \"objectives\": [\n\"Learn to use Python professionally, learning both Python 2 and Python 3!\", \"Learn advanced Python features, like the collections module and how to work with timestamps!\", \"Understand complex topics, like decorators.\", \"Get an understanding of how to create GUIs in the Jupyter Notebook system!\", \"Create games with Python, like Tic Tac Toe and Blackjack!\", \"Learn to use Object Oriented Programming with classes!\", \"Understand how to use both the Jupyter Notebook and create .py files\", \"Get an understanding of how to create GUIs in the Jupyter Notebook system!\"]}",
//   "isVisible": true,
//   "thumbnailMediaType": "image",
//   "thumbnailMediaPath": "https://lms-v4.gocbeglobal.com/img/sample-thumbnail.d9584282.jpeg",
//   "longDescription": "longDescription",
//   "shortDescription": "shortDescription",
//   "isFeatured": true,
//   "startDate": "2021-10-26T14:16:59.840Z",
//   "endDate": "2022-10-26T14:16:59.840Z"
// }
</script>

<style>
.create-product-variant-modal {
	/* height: 90vh; */
	width: 70vw;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.create-product-variant-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.create-product-variant-modal .modal-body .custom-stepper-container {
	background: #f5f5f5;
	border-radius: 8px 0px 0px 8px;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.create-product-variant-modal
	.modal-body
	.custom-stepper-container
	.custom-stepper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 7.5rem;
}

.custom-stepper-container .custom-stepper-step {
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
}

.custom-stepper-container .custom-stepper-step .step-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #ffffff;
	border: 2px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	font-weight: bold;
	font-size: 1.5rem;
	line-height: 1.5;
	color: #000000;
	flex-shrink: 0;
	width: 2.875rem;
	height: 2.875rem;
	cursor: pointer;
	position: relative;
}

.custom-stepper-container .custom-stepper-step .step-icon:before {
	content: "";
	background-color: #dcdcdc;
	width: 2px;
	height: 9.75rem;
	left: 1.375rem;
	top: -9.75rem;
	position: absolute;
}

.custom-stepper-container .custom-stepper-step .step-icon.first::before {
	display: none;
}

.custom-stepper-container .custom-stepper-step .step-icon.active:before,
.custom-stepper-container .custom-stepper-step .step-icon.complete:before {
	background-color: var(--primary-color);
}

.custom-stepper-container .custom-stepper-step .step-icon.active,
.custom-stepper-container .custom-stepper-step .step-icon.complete {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	color: #ffffff;
}

.custom-stepper-container .custom-stepper-step .step-text {
	display: flex;
	flex-direction: column;
	row-gap: 0.75rem;
	max-height: 5rem;
}

.custom-stepper-container .custom-stepper-step .step-text .title {
	font-weight: 450;
	font-size: 1.5rem;
	line-height: 1.5;
	color: #000000;
	margin: 0;
}

.custom-stepper-container .custom-stepper-step .step-text .subtitle {
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.37;
	color: #727272;
}

.create-product-variant-modal .modal-body .custom-stepper-body-container {
	display: flex;
	flex-direction: column;
	width: 70%;
	padding: 2.5rem 2.5rem 1.5rem;
	position: relative;
}

.step-1 {
	margin-top: 1.625rem;
	width: 100%;
	display: flex;
	flex-direction: column;
}

/* .step-1 .trainer-list-item {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
	align-items: center;
	height: 3rem;
} */

.step-2 {
	/* margin-top: 9.375rem; */
	width: 100%;
	display: flex;
	flex-direction: column;
}

#create-form .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	margin-bottom: 1rem;
}

#create-form .form-group {
	display: flex;
	flex-direction: column;
}

#create-form .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
}

.create-product-variant-modal .upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	background: #f5f5f5;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	width: fit-content;
	align-items: center;
	max-width: 10rem;
	max-height: 6.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.create-product-variant-modal .upload-btn-wrapper.preview .disabled {
	pointer-events: none;
}

.create-product-variant-modal .upload-btn-wrapper.preview.disabled {
	pointer-events: none;
}

.create-product-variant-modal .upload-btn-wrapper.fail {
	border: 1px solid var(--color-danger);
}

.create-product-variant-modal .upload-btn-wrapper.success {
	border: 1px solid var(--color-success);
}

.create-product-variant-modal .upload-btn-wrapper.preview {
	padding: 0;
	width: 10rem;
	height: 6.5rem;
}

#preview {
	max-width: 100%;
}

.upload-btn {
	padding: 0.5rem 1.25rem;
	font-weight: bold;
	cursor: pointer;
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 6px;
	text-transform: uppercase;
	color: #000000;
	font-weight: normal;
}

.upload-btn:hover,
.upload-btn:focus {
	background-color: var(--primary-color) !important;
	border-color: #ffffff !important;
	color: #ffffff !important;
}

.upload-btn-wrapper input[type="file"] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	padding: 0 !important;
	pointer-events: none;
}

.status-cards {
	display: flex;
	flex-direction: row;
	column-gap: 1.5rem;
}

.status-cards input[type="radio"] {
	opacity: 0;
	position: fixed;
	width: 0;
}

.status-cards input[type="radio"]:checked + label {
	border-color: var(--primary-color);
}

.status-cards input[type="radio"]:checked + label::after {
	content: url("~@/assets/images/check-icon.svg");
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	border-radius: 50%;
}

.status-cards .status-card {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 6px;
	padding: 1.125rem;
	cursor: pointer;
	flex-grow: 1;
	max-width: 13.75rem;
	font-size: 1rem;
	line-height: 1.37;
	color: #727272;
	position: relative;
	height: 100%;
}

/* .status-cards .status-card:focus {
	box-shadow: 0 3px 6px #00000050 !important;
} */

.status-cards .status-card p {
	color: #000000;
	font-weight: 500;
	margin-bottom: 0.5rem;
}

.step-btn-container {
	display: flex;
	flex-direction: row;
	align-self: flex-end;
	margin-top: auto;
	column-gap: 1.25rem;
}

.step-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 6px;
	padding: 0.875rem;
	filter: drop-shadow(3px 5px 6px rgba(197, 197, 197, 0.3));
	text-decoration: none;
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	font-size: 0.875rem;
	text-transform: uppercase;
	line-height: 1;
}

.step-btn:hover {
	text-decoration: none;
	color: #000000;
}

.step-btn.prev img {
	transform: rotate(180deg);
}

.skip-step-btn {
	color: #000000;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 0.875rem;
	text-transform: uppercase;
	line-height: 1;
	background: #f5f5f5;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 0.875rem 1.875rem;
	text-decoration: none;
}

.skip-step-btn:hover {
	text-decoration: none;
	color: #000000;
}

.add-content-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

button[disabled] {
	cursor: not-allowed;
	border-color: #ccc;
	opacity: 0.5;
	pointer-events: none;
}

#create-form .form-check-input {
	outline: none !important;
}

.create-product-variant-modal .upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.create-product-variant-modal .upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.create-product-variant-modal .upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.create-product-variant-modal .upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.create-product-variant-modal .upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

.create-product-variant-modal #profilePicturePath {
	pointer-events: none;
}
</style>
