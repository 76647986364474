<template>
	<div
		:class="[
			'product-content-tabs-container',
			{ sidebar: viewMode == 'sidebar' },
		]"
	>
		<div
			v-if="purchased && viewMode != 'sidebar'"
			:class="[
				'd-flex mb-4',
				{
					'flex-column': isMobile,
					'justify-content-between align-items-center': !isMobile,
				},
			]"
		></div>
		<ul
			v-if="
				filteredProductVariantItems && filteredProductVariantItems.length > 1
			"
			class="product-courses-tabs inline-list hide-tab-mobile"
		>
			<li
				@click="changeTab"
				:id="course.id"
				v-for="course in filteredProductVariantItems"
				:key="course.id"
				:class="[
					'course-tab',
					{ active: activeTab == course.id, sidebar: viewMode == 'sidebar' },
				]"
			>
				{{ course.title }}
			</li>
		</ul>
		<div
			v-for="courseTab in filteredProductVariantItems"
			:key="courseTab.id"
			:class="[
				'course-tab-body hide-tab-mobile',
				{
					'first-tab': activeTab == filteredProductVariantItems[0].id,
					'last-tab':
						activeTab ==
						filteredProductVariantItems[filteredProductVariantItems.length - 1]
							.id,
					'tab-hidden': activeTab != courseTab.id,
					sidebar: viewMode == 'sidebar',
				},
			]"
		>
			<ProductContentAccordianList
				v-if="tabContent && activeTab == courseTab.id"
				:tabData="tabData"
				:viewMode="viewMode"
				:purchased="purchased"
				:productVariantId="productVariantData.id"
			/>
			<div v-else class="skeleton">
				<div class="skeleton-body ms-0 ps-0 mt-2">
					<b-skeleton class="mt-4" width="20%"></b-skeleton>
					<b-skeleton
						class="mt-3"
						type="input"
						height="60px"
						width="100%"
					></b-skeleton>
					<b-skeleton
						class="mt-3"
						type="input"
						height="60px"
						width="100%"
					></b-skeleton>
					<b-skeleton
						class="mt-3"
						type="input"
						height="60px"
						width="100%"
					></b-skeleton>
				</div>
			</div>
		</div>
		<div
			v-if="isMobile"
			class="accordion mobile-only"
			id="product-content-accordian"
		>
			<div
				class="accordion-item content-mobile-accordian"
				v-for="course in filteredProductVariantItems"
				:key="course.id"
			>
				<h2 class="accordion-header" :id="'heading' + course.id">
					<button
						:id="course.id"
						@click="changeTab"
						class="accordion-button collapsed"
						type="button"
						data-bs-toggle="collapse"
						:data-bs-target="'#collapse' + course.id"
						:aria-controls="'collapse' + course.id"
					>
						{{ course.title }}
					</button>
				</h2>
				<div
					:id="'collapse' + course.id"
					class="accordion-collapse collapse"
					data-bs-parent="#product-content-accordian"
				>
					<div class="accordion-body">
						<ProductContentAccordianList
							v-if="tabContent && activeTab == course.id"
							:tabData="tabData"
							:viewMode="viewMode"
							:purchased="purchased"
							:productVariantId="productVariantData.id"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!filteredProductVariantItems.length" class="no-content p-3">
			No content found
		</div>
	</div>
</template>

<script>
import ProductContentAccordianList from "./ProductContentAccordianList.vue";
import { mapActions, mapState } from "vuex";
import ProductVariant from "../../models/productVariant";

export default {
	name: "ProductContentTabs",
	props: {
		productVariantData: ProductVariant,
		viewMode: String,
		purchased: Boolean,
	},
	components: { ProductContentAccordianList },
	data() {
		return {
			activeTab: null,
			isTabLoaded: false,
			tabData: null,
		};
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		changeTab(e) {
			console.log({ msg: "changing tab", e });

			this.activeTab = e.target.id;
			if (this.tabData.id != this.activeTab) {
				this.isTabLoaded = false;
			}
			// this.contentById(e.target.id);
			// this.$emit("activeTab", this.activeTab);
		},
	},
	computed: {
		...mapState("content", ["content", "loading"]),
		...mapState("appState", ["isMobile"]),
		tabContent() {
			this.isTabLoaded = false;
			if (this.content[this.activeTab]) {
				console.log({ msg: "content found in store" });
				this.isTabLoaded = true;
				this.tabData = this.content[this.activeTab];
				return true;
			} else {
				console.log({ msg: "content not found in store" });
				// if (!this.loading) {
				// 	const data = this.getContentById(this.activeTab).then(() => {
				// 		this.isTabLoaded = true;
				// 		this.tabData = this.content[this.activeTab];
				// 		console.log({ msg: "data from API call", data });
				// 	});
				// }
				return false;
			}
		},
		filteredProductVariantItems() {
			return this.productVariantData.productVariantItems.filter((item) => {
				if (!item.availableFrom && !item.availableTo) {
					return item;
				}
				if (item.availableFrom && new Date(item.availableFrom) < new Date()) {
					if (item.availableTo && new Date(item.availableTo) > new Date()) {
						return item;
					}
					if (!item.availableTo) {
						return item;
					}
				}
				if (item.availableTo && new Date(item.availableTo) > new Date()) {
					return item;
				}
			});
		},
	},
	created() {
		console.log("product content tabs created");
		if (
			this.filteredProductVariantItems &&
			this.filteredProductVariantItems.length
		) {
			this.activeTab = this.filteredProductVariantItems[0].id;
			this.getContentById({ id: this.activeTab }).then(() => {
				this.isTabLoaded = true;
				if (this.isMobile)
					document
						.querySelectorAll(
							`button[data-bs-target='#collapse${this.activeTab}']`
						)[0]
						.click();
			});
		}
	},
	watch: {
		activeTab: function () {
			const data = this.getContentById({ id: this.activeTab }).then(
				(status) => {
					this.isTabLoaded = true;

					this.tabData = this.content[this.activeTab];
					console.log({ msg: "data from API call", data });
				}
			);
		},
		filteredProductVariantItems: function () {
			this.activeTab = this.filteredProductVariantItems[0].id;
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.product-content-tabs-container {
		padding: 2.5rem 0 !important;
	}
}

.product-content-tabs-container {
	padding: 2.5rem 2.75rem 2.5rem 0;
}

.product-content-tabs-container.sidebar {
	padding: 0;
}

.product-courses-tabs {
	display: flex;
	overflow-x: hidden;
	overflow-y: visible;
	/* z-index: 100; */
	position: relative;
	top: 0.063rem;
}

.product-courses-tabs:hover {
	overflow-x: overlay;
}

.course-tab {
	padding: 1.25rem;
	color: var(--secondary-color);
	border-radius: 8px 8px 0 0;
	font-weight: 500;
	min-width: 9.375rem;
}

.course-tab.sidebar {
	padding: 1rem 0;
}

.course-tab.active {
	border: 1px solid #a7a9ac;
	border-bottom: 2px solid #ffffff;
	position: relative;
}

.course-tab.sidebar.active {
	border: 0;
	border-bottom: 0.25rem solid var(--primary-color);
}

.course-tab-body {
	padding: 2.125rem 1.875rem;
	border: 1px solid #a7a9ac;
	border-radius: 8px;

	/* position: relative;
	top: -1px; */
}

.course-tab-body.sidebar {
	padding: 0;
	border: 0;
	border-radius: 0;
	max-height: calc(100vh - 5rem);
	overflow: auto;
}

.course-tab-body.first-tab {
	border-top-left-radius: 0;
}

.tab-hidden {
	display: none;
}

.course-search {
	display: flex;
	align-items: center;
	position: relative;
	flex-grow: 10;
	margin: 0 !important;
}

.border-input {
	min-width: unset !important;
	padding: 1.125rem 1rem;
	font-size: 1rem;
	/* font-weight: 500 !important; */
	border-radius: 6px;
	line-height: 1;
	flex-grow: 1;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: #eeeeee;
}

#product-content-accordian {
	margin: 0 -1rem;
}

.content-mobile-accordian {
	border-radius: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
}

.content-mobile-accordian .accordion-body {
	padding: 0;
}

.content-mobile-accordian .accordion-header .accordion-button {
	padding: 1.5rem !important;
	font-weight: bold;
	color: var(--secondary-color);
	font-size: 1.25rem;
	background-color: #ffffff !important;
	outline: none !important;
}

.content-mobile-accordian .accordion-header .accordion-button:focus {
	box-shadow: none !important;
}

.content-mobile-accordian .accordion-header .accordion-button:after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

#category.mobile {
	margin-top: 0.75rem;
	margin-left: 0 !important;
}

.no-content {
	text-align: center;
	padding: 2rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}
</style>
