<template>
  <div>
    <div v-if="isReviewMode">
      <h5 v-if="isCorrect(selectedAnswer)" class="textbox-inputs correct">
        Correct
      </h5>
      <h5 v-if="isInCorrect(selectedAnswer)" class="textbox-inputs incorrect">
        Wrong! Possible Correct Answers are: {{ correctAnswer }}
      </h5>
      <h5
        v-if="!this.answers || this.answers.length <= 0"
        class="textbox-inputs incorrect"
      >
        Wrong! Possible Correct Answers are: {{ correctAnswer }}
      </h5>
    </div>
    <b-form-input
      class="textbox-inputs"
      v-model="userInput"
      placeholder="Type your answer"
      @blur="getUserInput"
      v-if="!isReviewMode"
    ></b-form-input>
    <b-form-input
      class="textbox-inputs"
      v-model="selectedAnswer"
      v-if="isReviewMode"
      disabled
    ></b-form-input>
  </div>
</template>

<script>
export default {
  name: "TextboxQuestion",
  data() {
    return {
      selectedAnswer: null,
      userInput: "",
    };
  },
  props: {
    isReviewMode: Boolean,
    options: Array,
    answers: Array,
    correctAnswers: Array,
  },
  computed: {
    correctAnswer: function () {
      return this.options.map((x) => x.option).toString();
    },
  },
  mounted() {},
  methods: {
    isCorrect(option) {
      // debugger;
      // if(this.isReviewMode && this.correctAnswers)
      // {
      //   return this.correctAnswers.indexOf(option.identifier)>-1;
      // }
      // return false;
      if (this.options && this.options.length > 0 && option) {
        var correctOption = this.options.filter((x) => x.option == option);
        return correctOption.length > 0 ? true : false;
      }
      return false;
    },
    isInCorrect(option) {
      if (this.isReviewMode && !this.isCorrect(option) && this.answers) {
        return true;
      }
      return false;
    },
    getUserInput() {
      this.selectedAnswer = this.userInput;
    },
  },
  created() {
    if (this.answers && this.answers.length > 0) {
      this.selectedAnswer = this.answers[0];
    }
  },
  watch: {
    selectedAnswer: function () {
      this.$emit("setAnswer", [this.selectedAnswer]);
    },
    answers: function () {
      if (this.answers && this.answers.length > 0) {
        this.selectedAnswer = this.answers[0];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-option {
  margin-left: 10px;
  font-weight: lighter;
}
.textbox-inputs {
  max-width: 40%;
  padding: 5px;
  border-radius: 5px;
}
.textbox-inputs.correct {
  background: #ddf1dd;
}
.textbox-inputs.incorrect {
  background: #fed2d2;
}
</style>

<style>
.quiz-textbox input[type="textbox"] {
  accent-color: var(--primary-color);
}

.textbox-inputs .custom-control-label {
  margin: 0px;
}
</style>
