import { productService } from "../services/product.service";
import productObject from "../models/product";
import Vue from "vue";
const state = {
	products: { items: {}, length: 0 },
	productsByCategory: {},
	loading: false,
};
import store from "./index";

const getters = {
	allProducts: (state) => {
		return state.products;
	},
	uncategorizedProducts: (state) => {
		return Object.values(state.products.items).filter(
			(product) => product.categories.length == 0
		);
	},
};

const actions = {
	fetchAllProducts: async ({ commit }) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("fetchAllProducts");

		const data = await productService.fetchAllProducts().then(
			(products) => {
				var data = products?.payload;
				data = data.map((product) => new productObject(product));
				return data;
			},
			(error) => {
				//alert(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		if (data) {
			commit("setProducts", data);
			return data;
		}
	},

	getProductById: async ({ commit }, id) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("getProductById", id);

		const data = await productService.fetchProductById(id).then(
			(product) => {
				var data = product?.payload;
				return new productObject(data);
			},
			(error) => {
				//alert(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		if (data) {
			commit("addProduct", { data, id });
			return data;
		}
	},
	getProductByCategoryId: async ({ commit }, id) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("getProductByCategoryId", id);
		const data = await productService.fetchProductByCategoryId(id).then(
			(product) => {
				var data = product?.payload;
				console.log("response", data);

				data.forEach((item) => {
					return new productObject(item);
				});
				return data;
			},
			(error) => {
				//alert(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		// console.log("id: ", id);
		if (data) {
			commit("addProductByCategory", { data, id });
			return data;
		}
		commit("setLoading", false);
	},

	createProduct: async ({ commit }, { product, id }) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("createProduct", product, id);
		const data = await productService.createProduct(product, id).then(
			(res) => {
				var data = res?.payload;
				console.log("response", data);
				// data = ;
				data = new productObject({ ...data, categories: product.categories });
				return data;
			},
			(error) => {
				//alert(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		// console.log("id: ", id);
		if (data) {
			commit("addProductToCategory", { data, id: id });
			return data;
		}
		commit("setLoading", false);
	},

	editProductSequence: async ({commit}, productObj) => {
		commit("setLoading", true);
		
		const data = await productService.editProductSequence(productObj).then(
			(res) => {
				if(res.ok) {
					Vue.toasted.success("Sequence Updated Successful", {
						theme: "outline",
						position: "bottom-center",
						duration: 5000,
					});
				} 
				else {
					Vue.toasted.error("Some error occurred", {
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-center",
						duration: 3000,
					});
				}
				commit("setLoading", false);
			},
			(error) => {
				//alert(error);
				Vue.toasted.error("Some error occurred", {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
	},

	deleteProduct: async ({ commit }, { product, id }) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("deleteProduct", product, id);
		const data = await productService.deleteProduct(product.id).then(
			() => {
				Vue.toasted.success("Course deleted successfully", {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				return true;
			},
			(error) => {
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		// console.log("product: ", product);
		if (data) {
			// var categories = product.categories.map((c) => c.id);
			commit("removeProduct", {
				productId: product.id,
				categoryId: id,
			});
			store.dispatch("productVariant/fetchProductVariants");
			// return data;
		}
		commit("setLoading", false);
	},

	updateProduct: async ({ commit }, { product, id }) => {
		commit("setLoading", true);
		// state.loading = true;
		console.log("updateProduct", id);
		const data = await productService.updateProduct(product).then(
			(res) => {
				// var data = product?.payload;
				// console.log("response", data);
				// data = new productObject(data);
				// return data;
				commit("updateProduct", { product, id });
				store.dispatch("productVariant/fetchProductVariants");
			},
			(error) => {
				// cons(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
		commit("setLoading", false);
	},
};

const mutations = {
	setProducts(state, data) {
		// console.log(data);
		data.forEach((product) => {
			state.products.items = { ...state.products.items, [product.id]: product };
		});
		// state.products = data;
		state.loading = false;
	},
	addProduct(state, { data, id }) {
		state.products.items[id] = data;
		state.products.length += 1;
		state.loading = false;
	},
	addProductByCategory(state, { data, id }) {
		// console.log(id);
		state.productsByCategory[id] = data;
		state.loading = false;
	},
	addProductToCategory(state, { data, id }) {
		console.log("addProductToCategory", id);

		state.productsByCategory[id].push(data);
		state.loading = false;
	},
	removeProduct(state, { productId, categoryId }) {
		console.log("removeProduct", productId, categoryId);
		if (categoryId != -1) {
			var newArray = state.productsByCategory[categoryId].filter(
				(p) => p.id != productId
			);
			state.productsByCategory = {
				...state.productsByCategory,
				[categoryId]: newArray,
			};
		}

		if (state.products.items[productId]) {
			var temp = { ...state.products.items };
			delete temp[productId];
			state.products = { ...state.products, items: temp };
		}
		state.loading = false;
	},
	updateProduct(state, { product, id }) {
		var updatedProducts = state.productsByCategory[id].map((item) => {
			if (item.id == product.id) {
				item.title = product.title;
			}
			return item;
		});
		console.log("updatedProducts", updatedProducts, id);

		state.productsByCategory = {
			...state.productsByCategory,
			[id]: updatedProducts,
		};

		if (state.products.items[product.id])
			state.products.items = { ...state.products.items, [product.id]: product };

		state.loading = false;
	},
	setLoading(state, data) {
		if (data) {
			console.log("products loading...");
		} else {
			console.log("products loading complete!");
		}
		state.loading = data;
	},
	setProductsLength(state, value) {
		state.products.length = value;
	},
	assignCategoryToProduct(state, productData) {
		console.log(
			"updating state, assignCategoryToProduct",
			productData.productID
		);
		var productToUpdate = state.products.items[productData.productID];

		if (productToUpdate) {
			productToUpdate.categories = productData.categories;

			state.products.items = {
				...state.products.items,
				[productData.productID]: productToUpdate,
			};

			productData.categories.forEach((cat) => {
				if (state.productsByCategory[cat])
					state.productsByCategory = {
						...state.productsByCategory,
						[cat]: [...state.productsByCategory[cat], productToUpdate],
					};
				else
					state.productsByCategory = {
						...state.productsByCategory,
						[cat]: [productToUpdate],
					};
			});
		}
		state.loading = false;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
