<template>
	<div>
		<div>
			<ProductPageBanner :productVariantData="productVariantData">
			</ProductPageBanner>

			<div class="container mt-3" v-if="courseMessage">
				<p class="text-danger">{{courseMessage}}</p>
			</div>

			<ProductContent
				:productVariantData="productVariantData"
				viewMode="description_page"
			>
				<ProductDetailsCard
					v-if="productVariantData && productVariantData.subscriptionStatus && productVariantData.subscriptionStatus.toLowerCase() == 'unenrolled'"
					@togglePlansModal="toggleModal"
					:productVariantData="productVariantData"
			/></ProductContent>
			<ProductDetailsCardMobile
				@togglePlansModal="toggleModal"
				v-if="isMobile"
				:productVariantData="productVariantData"
			/>
			<ProductPricingPlansModal
				:productVariantData="productVariantData"
				:showModal="showModal"
				@updateModalState="toggleModal"
			/>
			<div v-if="systemParameters && systemParameters.IsShowFeedbackOnProductDetail  && systemParameters.IsShowFeedbackOnProductDetail.toString()=='1'">
				<ProductFeedbackCard
					:productVariantData="productVariantData"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import productVariantData from "../constants/tempProductsData";
// import { contentData } from "../constants/tempProductsData";
import ProductPageBanner from "../components/Product/ProductPageBanner.vue";
import ProductDetailsCard from "../components/Product/ProductDetailsCard.vue";
import ProductContent from "../components/Product/ProductContent.vue";
import ProductDetailsTopCard from "../components/Product/ProductDetailsTopCard.vue";
import ProductDetailsCardMobile from "../components/Product/ProductDetailsCardMobile.vue";
import ProductPricingPlansModal from "../components/Product/ProductPricingPlansModal.vue";
import StarRating from 'vue-star-rating'
import ProductFeedbackCard from '../components/Product/ProductFeedbackCard.vue';
export default {
	name: "ProductDetails",
	data() {
		return {
			productVariantData: null,
			showModal: false,
			// contentData: contentData,
		};
	},
	components: {
		ProductPageBanner,
		ProductDetailsCard,
		ProductContent,
		ProductDetailsTopCard,
		ProductDetailsCardMobile,
		ProductPricingPlansModal,
		ProductFeedbackCard
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById"]),
		...mapActions("user", ["fetchUsersList","fetchTrainersList", "fetchUsersByProductVariant"]),				
		async getProductData() {
			const productId = this.productIdFromUrl;
			// console.log("id from url", productId);
			const data = await this.getProductVariantById(productId);
			// const data = this.allProductVariants.find((item) => {
			// 	if (item.productVariantID == productId) return item;
			// });
			this.productVariantData = data;
			// this.contentData =
			window.document.title = data.product.title;
		},
		toggleModal(state) {
			this.showModal = state;
		},
	},
	computed: {
		...mapState("productVariant", ["loading"]),
		...mapState("appState", ["isMobile"]),
		...mapState("user", ["user"]),
		...mapState("appState", [
			"systemParameters"
		]),
		courseMessage() {
			if(this.productVariantData && this.productVariantData.subscriptionStatus){
				return this.productVariantData.expiresAt && new Date() > new Date(this.productVariantData.expiresAt)  ? 'Your subscription for the course has expired. If you wish to continue, please consider re-enrolling process or contact admin for further assistance.'
				: this.productVariantData.subscriptionStatus.toLowerCase() == 'unenrolled' ? 'You are not enrolled in the course. If you wish to enroll, please buy-now or contact admin for further assistance.'
				: this.productVariantData.subscriptionStatus.toLowerCase() == 'blocked' ? 'Your access have been blocked from the course. Please contact Admin for further assistance.' 
				: null;
			} 
			return null
		},
		productIdFromUrl() {
			const urlID = this.$route.params.id.split("-");
			const productID = urlID[urlID.length - 1];
			return productID;
		},
	},
	watch: {
		$route(to, from) {
			this.getProductData();
		},
	},
	created() {
		if (this.user) {
			var isEnrolled = this.user.productVariants.filter(
				(variant) => variant.id == this.productIdFromUrl
			);
			// console.log(isEnrolled);
			if (isEnrolled && isEnrolled.length) {
				this.$router.push("/product/content/" + this.$route.params.id);
			}
		}
		this.getProductData();
		this.fetchTrainersList();
	},
};
</script>

<style scoped>
  
</style>
