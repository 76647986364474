import { appService } from "../services/app.service";
const getCountryISO3 = require("country-iso-2-to-3");
import { countryCurrency } from "../constants/countryCurrency";
import store from "./index";

window.addEventListener("resize", () => {
	state.isMobile = window.innerWidth <= 996;
});

const state = {
	loading: false,
	systemParameters: null,
	customFilesLoaded: false,
	isMobile: window.innerWidth <= 996,
	externalServicesList: {},
	currentCountry: null,
	currentCity: null,
	currentCurrency: null,
	apiRef: null,
	apiURL: null,
	amIOnline: true,
};

const getters = {
	isLoading: (state) => {
		return state.loading;
	},
	getTenantInfo(state) {
		return state.tenantInfo;
	},
};

const actions = {
	startLoading({ commit }) {
		commit("setLoading", true);
	},
	stopLoading({ commit }) {
		commit("setLoading", false);
	},
	async fetchSystemParameters({ commit }) {
		commit("setLoading", true);
		await appService.getSystemParameters().then(
			(systemParameters) => {
				var params = {
					// CustomRoutesPath: "edcentra.js",
					// tenantName: "GoCBE Global",
					// CustomPages: {
					// 	Home: [
					// 		"https://gocbe-lms-files.b-cdn.net/lms_v4_test/ASTCHome.117e72b9f9f8431ae924.umd.min.js",
					// 		"https://gocbe-lms-files.b-cdn.net/lms_v4_test/ASTCHome.117e72b9f9f8431ae924.css",
					// 	],
					// },
					...systemParameters?.payload,
					// CustomPages: {
					// 	Home: [
					// 		"http://localhost:8080/js/Home.fa4d19d88256bd3e901a.umd.min.js",
					// 		"http://localhost:8080/js/Home.fa4d19d88256bd3e901a.css",
					// 	],
					// },
				};
				if (params.Theme) {
					params.Theme = JSON.parse(params.Theme);
				} else {
					params.Theme = {
						PrimaryColor: "#CB222B",
						SecondaryColor: "#000000",
					};
				}
				if (params.TrainerUserRegisterExtraFields) {
					params.TrainerUserRegisterExtraFields = JSON.parse(
						params.TrainerUserRegisterExtraFields
					);
				}
				if (params.UserRegisterExtraFields) {
					params.UserRegisterExtraFields = JSON.parse(
						params.UserRegisterExtraFields
					);
				}
				if (params.TenantInfo) {
					params.TenantInfo = JSON.parse(params.TenantInfo);
				}
				if (params.SocialLinks) {
					params.SocialLinks = JSON.parse(params.SocialLinks);
				}
				if (params.Banners) {
					params.Banners = JSON.parse(params.Banners);
				}

				// if (params.CustomPages) {
				// 	params.CustomPages = JSON.parse(params.CustomPages);
				// }

				fetch(state.apiURL + "/user/locationbyip", {
					headers: {
						AuthToken: "System123",
					},
				}).then((res) => {
					res.json().then((val) => {
						console.log(val);
						if (val.city) commit("setCurrentCity", val.city);
						commit("setCurrentCountry", val.countrycode);
					});
				});
				commit("setParameters", params);
				commit("setLoading", false);
			},
			(error) => {
				console.error("app level error", error.message);
				commit("setLoading", false);
			}
		);
	},

	async editSystemParameters({ commit }, params) {
		commit("setLoading", true);
		await appService.editSystemParameters(params).then(
			(res) => {
				// $.get("https://iplist.cc/api").done();
				commit("setParameters", params);
				commit("setLoading", false);
			},
			(error) => {
				console.error("app level error", error.message);
				commit("setLoading", false);
			}
		);
	},

	async fetchExternalServices({ commit }, type) {
		commit("setLoading", true);
		const res = await appService.getExternalServices(type).then(
			(services) => {
				commit("setExternalServices", { services: services?.payload, type });
				commit("setLoading", false);
				return services;
			},
			(error) => {
				console.error(error);
				commit("setLoading", false);
			}
		);
		if (res) return res;
	},
	async createExternalServices({ commit }, {type,provider,data}) {
		const res = await appService.createExternalServices(type,provider,data).then(
			(response) => {
				
				return response;
			},
			(error) => {
				console.error(error);
			}
		);
		if (res) return res;
	},

	async fetchTenantInfo({ commit }) {
		setTimeout(() => {
			commit("setTenantInfo", {});
		}, 0);
	},
};

const mutations = {
	setLoading(state, loading) {
		state.loading = loading;
		// console.log(state.loading);
	},
	setParameters(state, parameters) {
		console.log("system parameters setting", parameters);
		state.systemParameters = parameters;
		console.log(state.systemParameters);
	},
	setExternalServices(state, { services, type }) {
		console.log({ services, type });
		var list = { ...state.externalServicesList };
		list[type] = services && services.length > 0 ? services : null;
		state.externalServicesList = list;
	},
	setTenantInfo(state, tenant) {
		state.tenantInfo = tenant;
	},
	setCurrentCountry(state, country) {
		console.log("setCurrentCountry", country);
		state.currentCountry = getCountryISO3(country);
		var currentCurr = countryCurrency.find(
			(c) => c.country == country
		).currency;
		state.currentCurrency = currentCurr;
		store.commit("cart/updateCartCurrency", currentCurr);
	},
	setCurrentCity(state, city) {
		state.currentCity = city;
	},
	setOnlineStatus(state, status) {
		state.amIOnline = status;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
