<template>
	<div class="course-detail-container">
		<router-view
			v-if="productVariantData"
			:productVariantData="productVariantData"
			:productVariantId="productVariantId"
		></router-view>
		<div class="minmaxCon"></div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ContentType } from "../../constants/content";

export default {
	name: "CourseDetail",
	data() {
		return {
			productVariantData: null,
		};
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById"]),
		...mapActions("appState", ["fetchExternalServices"]),
	},
	computed: {
		...mapState("productVariant", ["productVariants", "loading"]),
		...mapState("appState", ["externalServicesList"]),
		productVariantId() {
			return this.$route.params.id;
		},
	},
	created() {
		console.log("created");
		// if (!this.productVariants.items[this.productVariantId]) {
		this.getProductVariantById(this.productVariantId).then(() => {
			this.productVariantData =
				this.productVariants.items[this.productVariantId];
		});
		// }else {
		// 	this.productVariantData =
		// 		this.productVariants.items[this.productVariantId];
		// }
		if (
			this.externalServicesList &&
			!this.externalServicesList[ContentType.EXAM]
		) {
			this.fetchExternalServices(ContentType.EXAM);
		}
	},
	watch: {
		productVariants: function () {
			console.log("productVariantsUpdated");
			if (this.productVariants.items[this.productVariantId]) {
				this.productVariantData =
					this.productVariants.items[this.productVariantId];
			}
		},
	},
};
</script>

<style scoped></style>
