<template>
  <div ref='viewer'></div>
</template>

<script>
/* eslint-disable */
import WebViewer from '@pdftron/pdfjs-express'

export default {
  name: 'PDFExpressViewer',
  props: {
    path: String,
    url: String,
    apiKey: String
  },
  mounted: function () {
    WebViewer({
      path: this.path,
      licenseKey: this.apiKey,
      initialDoc: this.url, // replace with your own PDF file

    }, this.$refs.viewer).then((instance) => {
      console.log({instance});

      // call apis here
      instance.UI.disableFeatures([instance.UI.Feature.Print]);
      instance.UI.disableFeatures([instance.UI.Feature.Download]);

    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style>