<template>
	<b-modal
		id="edit-content-modal"
		@change="$emit('updateModalState', false)"
		:visible="visible"
		hide-header
		centered
		content-class="edit-content-modal"
		size="xl"
		body-class="modal-body"
		hide-footer
		modal-class="custom-modal"
		no-close-on-backdrop
	>
		<div class="body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="edit-content-title">Edit {{ entity }}</h1>
			<div :class="['info-container']">
				<div class="form-group">
					<label class="main-label" for="title">Title (required)</label>
					<input
						placeholder="Type your lecture name here..."
						class="form-input"
						type="search"
						name="title"
						id="title"
						v-model="title"
						required
						@keyup="updateModel"
						@keydown="updateModel"
						@update="updateModel"
					/>
				</div>
				<div class="form-group" v-if="entity.toLowerCase() != 'subject' && entity.toLowerCase() != 'section'">
					<div class="flex-row mt-5">
						<div class="col-lg-6 ps-0">
							<div class="form-group">
								<label for="start-date" class="main-label m-0"
									>start date (optional)</label
								>
								<input
									placeholder="Select start date"
									class="form-input"
									type="date"
									name="availableFrom"
									id="availableFrom"
									v-model="availableFrom"
									@change="updateModel"
									@input="updateModel"
								/>
							</div>
						</div>
						<div class="col-lg-6 pe-0">
							<div class="form-group">
								<label for="end-date" class="main-label m-0"
									>end date (optional)</label
								>
								<input
									placeholder="Select end date"
									class="form-input"
									type="date"
									name="availableTo"
									id="availableTo"
									v-model="availableTo"
									@change="updateModel"
									@input="updateModel"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group mt-4" v-if="entity.toLowerCase() != 'subject' && entity.toLowerCase() != 'section'">
					<div class="form-check form-switch">
						<input
							class="form-check-input"
							type="checkbox"
							role="switch"
							id="isContentAvailableForDemo"
							name="isContentAvailableForDemo"
							v-model="isContentAvailableForDemo"
							@change="updateModel"
						/>
						<label class="form-check-label" for="isContentAvailableForDemo"
							>Available for Demo</label
						>
					</div>
				</div>
			</div>
			<div
				v-if="entity.toLowerCase() == 'subject'"
				class="trainer-edit-container"
			>
				<UpdateContentTrainers
					v-if="(this.user.type || this.user.role).toLowerCase() != 'trainer'"
					ref="trainer_update"
					:assignedTrainers="assignedTrainers"
					:productVariantID="productVariantID"
					@updateTrainerPermissions="updatePermissions"
					@assignTrainer="assignTrainer"
					@unassignTrainer="unassignTrainer"
					@updateTrainersToUnassign="updateTrainersToUnassign"
					@updateTrainersToAssign="updateTrainersToAssign"
				/>
			</div>
			<span class="text-danger"
				>{{ dateErrorText }} {{ dateErrorText && errorText ? ", " : "" }}
				{{ errorText }}</span
			>
			<div class="step-btn-container">
				<!-- <a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
					><img src="@/assets/images/arrow-right.svg" /> prev
				</a> -->
				<button
					@click="update"
					role="button"
					class="step-btn"
					:disabled="disableNext() || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ stepButtonContent }}
					<img src="@/assets/images/arrow-right.svg" />
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import UpdateContentTrainers from "./UpdateContentTrainers.vue";

export default {
	name: "EditContentModal",
	data() {
		return {
			title: null,
			availableFrom: null,
			availableTo: null,
			isContentAvailableForDemo: false,
			updateObject: {},
			trainers: null,
			assignedTrainers: null,
			trainersToAssign: null,
			trainersToUnassign: null,
			errorText: "",
			allOk: true,
			dateErrorText: "",
		};
	},
	computed: {
		...mapState("content", ["loading", "trainersByContentId"]),
		...mapState("user", ["user"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			return "update";
		},
	},
	props: {
		showModal: Boolean,
		contentData: Object,
		entity: String,
		parentDirectoryID: String,
		productVariantID: String,
	},
	components: {
		UpdateContentTrainers,
	},
	methods: {
		...mapActions("content", [
			"updateContent",
			"getContentTrainers",
			"assignTrainersToContent",
			"unassignTrainersToContent",
		]),
		update() {
			this.updateObject.id = this.contentData.id;
			this.updateObject.title = this.title;
			this.updateObject.availableFrom =
				this.availableFrom != "" ? this.availableFrom : null;
			this.updateObject.availableTo =
				this.availableTo != "" ? this.availableTo : null;
			// isAvailableForDemo: this.isContentAvailableForDemo,
			// availableFrom: this.availableFrom,
			// availableTo: this.availableTo,
			// };
			this.updateObject.isAvailableForDemo = this.isContentAvailableForDemo;
			if (this.parentDirectoryID)
				this.updateObject.parentDirectoryID = this.parentDirectoryID;
			if (this.productVariantID)
				this.updateObject.productVariantID = this.productVariantID;

			if (this.availableFrom && this.availableTo) {
				this.allOk = new Date(this.availableFrom) <= new Date(this.availableTo);
				console.log(this.allOk);
				if (!this.allOk) {
					this.dateErrorText = "Start Date cannot be after End Date";
				} else {
					this.dateErrorText = "";
				}
			}

			if (this.trainersToAssign) {
				this.trainersToAssign.forEach((t) => {
					if (t.permissions.length == 0) {
						this.errorText = "Please assign permissions to trainer first!";
						this.allOk = false;
					} else {
						this.errorText = "";
						this.allOk = true;
					}
				});
			}

			if (this.allOk) {
				this.updateContent(this.updateObject).then(() => {
					console.log(this.entity, "updated!");
					if (this.trainersToAssign) {
						if (this.allOk) {
							var assignObj = {
								id: this.contentData.id,
								trainers: this.trainersToAssign,
							};
							this.assignTrainersToContent(assignObj).then(() => {
								console.log("trainers assigned");
							});
						}
					}
					if (this.trainersToUnassign && this.allOk) {
						var unassignObj = {
							id: this.contentData.id,
							trainers: this.trainersToUnassign,
						};
						this.unassignTrainersToContent(unassignObj).then(() => {
							console.log("trainers unassigned");
						});
					}
					if (this.allOk) {
						this.closeModal();
					}
				});
			}
		},
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		updateModel(e) {
			// console.log(e.target.name);
			if (e.target.name == "isContentAvailableForDemo") {
				console.log(e.target.checked);
				this.updateObject.isAvailableForDemo = e.target.checked;
			} else if (
				e.target.name == "availableFrom" ||
				e.target.name == "availableTo"
			) {
				if (e.target.value == "") this.updateObject[e.target.name] = null;
				else this.updateObject[e.target.name] = e.target.value;
			} else this.updateObject[e.target.name] = e.target.value;
		},
		disableNext() {
			// console.log(Object.keys(this.updateObject).length);
			if (
				Object.keys(this.updateObject).length == 0 &&
				this.trainersToAssign == null &&
				this.trainersToUnassign == null
			) {
				return true;
			}
			return false;
		},
		updatePermissions(value, id) {
			this.assignedTrainers = this.assignedTrainers.map((t) => {
				if (t.id == id) {
					t.permissions = value;
				}
				return t;
			});
		},
		assignTrainer(trainerObject) {
			trainerObject.id = trainerObject.trainerID;
			var found = this.assignedTrainers.find((t) => t.id == trainerObject.id);
			// console.log(found, trainerObject);
			if (!found) {
				if (this.assignedTrainers) this.assignedTrainers.push(trainerObject);
				else this.assignedTrainers = [trainerObject];
			}
		},
		unassignTrainer(trainerObject) {
			var found = this.assignedTrainers.find(
				(t) => t.id == trainerObject.trainerID
			);
			console.log("unAssignTraners", found, trainerObject);
			if (found)
				this.assignedTrainers = this.assignedTrainers.filter(
					(t) => t.id != trainerObject.trainerID
				);
		},
		updateTrainersToAssign(value) {
			this.trainersToAssign = value;
		},
		updateTrainersToUnassign(value) {
			this.trainersToUnassign = value;
		},
	},
	watch: {
		visible: function () {
			if (this.visible) {
				this.title = this.contentData.title ? this.contentData.title : null;
				this.availableFrom = this.contentData.availableFrom
					? getInputDateFormat(this.contentData.availableFrom)
					: null;
				this.availableTo = this.contentData.availableTo
					? getInputDateFormat(this.contentData.availableTo)
					: null;
				this.isContentAvailableForDemo =
					this.contentData.isAvailableForDemo ||
					this.contentData.isContentAvailableForDemo;
				this.getContentTrainers(this.contentData.id).then(() => {
					this.trainers = this.trainersByContentId[this.contentData.id];
					this.assignedTrainers = this.trainers.map((t) => {
						var trainerObject = {
							trainerID: t.user.id,
							id: t.user.id,
							firstName: t.user.firstName,
							lastName: t.user.lastName,
							IsShowInTrainerList: t.isShowInTrainerList,
							jobTitle: t.jobTitle,
							permissions: t.permissions.split(","),
							isActive: t.isActive,
							email: t.user.email,
						};
						return trainerObject;
					});
					console.log(this.trainersByContentId[this.contentData.id]);
				});
			}
		},
	},
};
</script>

<style>
.edit-content-modal {
	min-height: 40rem;
	width: 70vw;
}

.edit-content-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.edit-content-modal .modal-body .body-container {
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.edit-content-modal .edit-content-title {
	font-weight: 450;
	font-size: 2rem;
	line-height: 0.75;
	margin: 0.5rem 0 2rem;
	color: #000000;
}

.all-trainers-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.trainer-list-container {
	margin-top: 1.5rem;
}
</style>
