<template>
    <div class="tab-pane" id="confidenceLevelsPane" role="tabpanel" aria-labelledby="confidenceLevelsTab" tabindex="0">

        <center v-if="loading" class="loading">
            <b-spinner large></b-spinner>
        </center>
        <div v-else-if="hasConfidences()">
            <div class="eb-header">
                <h5>Understanding Levels</h5>
            </div>

            <div class="eb-content">
                <div class="accordion accordion-flush" id="confidenceLevels">

                    <div v-for="(confidence, index) in confidenceLevels">
                        <div class="accordion-item eb-chapter-toggle">
                            <h2 class="accordion-header eb-toggle-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#confidence-' + index" aria-expanded="false"
                                    :aria-controls="'confidence-' + index">
                                    <span>{{ confidence.title }}</span>
                                    <!-- <span>{{ confidence.marks }} marked</span> -->
                                </button>
                            </h2>
                            <div :id="'confidence-' + index" class="accordion-collapse eb-toggle-body collapse show">
                                <div class="accordion-body py-2 px-3">
                                    <div v-for="content in confidence.content">
                                        <p>
                                            <a role="button" @click="onConfidenceLinkTap(content)" class="red">
                                                {{ content.title }}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /eb-content -->

        </div>
        <center v-else class="no-content text-center">
            <h4 class="mt-4">No Understanding Level Listed</h4>
        </center>

    </div><!-- /tab-pane Confidence Levels-->
</template>

<script>

import { mapActions } from 'vuex';

export default {
    name: "ProductContentChaptersConfidence",
    props: {
        active: Boolean,
        productVariantID: String,
    },
    data() {
        return {
            loading: false,
            confidenceLevels: []
        };
    },
    methods: {
        ...mapActions("content", ["fetchUserContentActionsByID"]),
        fetchData() {
            this.loading = true;
            this.fetchUserContentActionsByID({ productVariantID: this.productVariantID, dataType: "confidence_level" }).then((data) => {
                console.log("Actions Data: ", data);

                var tempConfidenceLevels = [
                    { title: "High", content: [] },
                    { title: "Medium", content: [] },
                    { title: "Low", content: [] }
                ];

                for (let item of data) {
                    var content = item.content;

                    var tempContent = {
                        title: item.data,
                        content: [
                            {
                                "id": content.id,
                                "type": content.type,
                                "title": content.title,
                                "topLevelContentID": content.topLevelContentID,
                            }
                        ]
                    };

                    var existingIndex = tempConfidenceLevels.findIndex((existingItem) => existingItem.title === tempContent.title);

                    if (existingIndex !== -1) {
                        tempConfidenceLevels[existingIndex].content.push(...tempContent.content);
                    } else {
                        tempConfidenceLevels.push(tempContent);
                    }
                }

                for (let index = tempConfidenceLevels.length - 1; index >= 0; index--) {
                    if (tempConfidenceLevels[index].content.length === 0) {
                        tempConfidenceLevels.splice(index, 1);
                    }
                }

                this.confidenceLevels = tempConfidenceLevels;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.error("Error fetching user content actions: ", error);
            });
        },
        hasConfidences() {
            return this.confidenceLevels.length > 0;
        },
        onConfidenceLinkTap(content) {
            this.$router.push({
                query: {
                    tab: 'tableofcontents',
                    chapterId: content.topLevelContentID,
                    contentId: content.id
                }
            });
        }
    },
    computed: {
    },
    watch: {
        active: function () {
            if (this.active) {
                this.fetchData()
            }
        },
        productVariantID: function () { 
            if (this.active) {
                this.fetchData()
            }
        },
    },
    components: {},
    created() {
        if (this.active) {
            this.fetchData()
        }
    },
    mounted() { }
};
</script>

<style scoped>
.eb-chapter-toggle .accordion-button,
.eb-chapter-toggle .accordion-button:not(.collapsed) {
    font-size: 14px;
}

.accordion-body p>a {
    font-size: 12px;
}

.no-content {
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading {
    margin-top: 200px;
    margin-bottom: 200px;
}
</style>
