import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import {
	FETCH_POST_PUT_PRODUCT_API,
	FETCH_PRODUCT_BY_CATEGORY_API,UPDATE_PRODUCT_SEQUENCE
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const productService = {
	fetchAllProducts,
	fetchProductById,
	fetchProductByCategoryId,
	createProduct,
	updateProduct,
	deleteProduct,
	editProductSequence
};

async function fetchAllProducts() {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_API,
			"GET",
			null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function fetchProductById(id) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_API + `/${id}`,
			"GET",
			null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function fetchProductByCategoryId(id) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_PRODUCT_BY_CATEGORY_API + `/${id}`,
			"GET",
			null,
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function createProduct(product, categoryId) {
	store.dispatch("appState/startLoading");
	try {
		var requestBody = {
			...product,
			sequence: 1,
			thumbnailMediaType: "image",
			thumbnailMediaPath:
				"https://lms-v4.gocbeglobal.com/img/sample-thumbnail.d9584282.jpeg",
			isFeatured: true,
			isVisible: true,
			categories: [categoryId],
		};
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_API,
			"POST",
			JSON.stringify(requestBody),
			false
		).then(handleResponse);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function editProductSequence(producObj) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + UPDATE_PRODUCT_SEQUENCE,
			"PUT",
			JSON.stringify(producObj),
			false
		);
		store.dispatch("appState/stopLoading");
		return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function updateProduct(product) {
	store.dispatch("appState/startLoading");
	try {
		var requestBody = {
			...product,
		};
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_API,
			"PUT",
			JSON.stringify(requestBody),
			false
		);
		store.dispatch("appState/stopLoading");
		return product;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

async function deleteProduct(id) {
	store.dispatch("appState/startLoading");
	try {
		let body = await ApiHelpers.makeAPICall(
			store.state.appState.apiURL +
				FETCH_POST_PUT_PRODUCT_API +
				`?ProductID=${id}`,
			"DELETE",
			null,
			false
		);
		store.dispatch("appState/stopLoading");
		// return body;
	} finally {
		store.dispatch("appState/stopLoading");
	}
}

function handleResponse(response) {
	console.log("handling response", response);
	if (!response.ok) {
		// 	// if (response.status == 401) {
		// 	console.log("status", response.status);
		// 	store.dispatch("user/regenerateUserToken", {
		// 		token: helpers.getCookie("token"),
		// 	});
		// return;
		// }
		throw new Error(response.status);
	} else {
		return response.json();
	}
}
