<template>
	<div>
		<div class="form-group mt-4">
			<label for="plan" class="main-label"> Pricing Plan (required) </label>
			<select
				placeholder="Select a plan"
				class="form-input mb-4"
				v-model="selectedPlan"
				:disabled="selectedCycles.length > 0"
				:title="
					selectedCycles.length > 0
						? 'You cannot select plan-cycles from multiple plans. Please deselect any plan-cycles selected to change plan.'
						: ''
				"
				v-if="arePlansLoaded"
			>
				<option :value="null" disabled selected>Select a plan</option>
				<optgroup
					v-if="
						plansByType(planTypes.ONE_TIME) &&
						plansByType(planTypes.ONE_TIME).length > 0
					"
					label="One-Time Plans"
				>
					<option
						v-for="plan in plansByType(planTypes.ONE_TIME)"
						:value="plan"
						:key="plan.id"
					>
						{{ plan.title }}
					</option>
				</optgroup>
				<optgroup
					v-if="
						plansByType(planTypes.MULTIPLE_PAYMENT_CYCLES) &&
						plansByType(planTypes.MULTIPLE_PAYMENT_CYCLES).length > 0
					"
					label="Multiple-Cycle Plans"
				>
					<option
						:value="plan"
						v-for="plan in plansByType(planTypes.MULTIPLE_PAYMENT_CYCLES)"
						:key="plan.id"
					>
						{{ plan.title }}
					</option>
				</optgroup>
				<option
					disabled
					v-if="
						plansByType(planTypes.MULTIPLE_PAYMENT_CYCLES) &&
						plansByType(planTypes.MULTIPLE_PAYMENT_CYCLES).length == 0 &&
						plansByType(planTypes.ONE_TIME) &&
						plansByType(planTypes.ONE_TIME).length == 0
					"
					:value="null"
				>
					No pricing plans available. Please add a plan first...
				</option>
			</select>
			<div v-else>
				<b-skeleton width="100%" height="2.5rem" class="mb-3"></b-skeleton>
			</div>
		</div>
		<div v-if="planType == planTypes.MULTIPLE_PAYMENT_CYCLES">
			<!-- <div class="form-group"> -->
			<label for="plan" class="main-label"> Plan Cycles </label>
			<!-- </div> -->
			<table class="table table-hover table-borderless enrolled-users-table">
				<thead>
					<tr class="d-flex">
						<th class="col-1">
							<input
								type="checkbox"
								name="select_all"
								id="select_all"
								@click="toggleSelectAll()"
								:checked="isSelectedAll()"
							/>
						</th>
						<th class="col-2">Cycle Name</th>
						<th class="col">Price</th>
						<th class="col">Late Price</th>
						<th class="col">Start Date</th>
						<th class="col">End Date</th>
						<th class="col">Grace Period</th>
						<th class="col">Payments</th>
					</tr>
				</thead>
				<tbody>
					<tr class="d-flex" v-for="cycle in planCycles" :key="cycle.id">
						<td class="col-1">
							<input
								type="checkbox"
								name="select_1"
								:id="cycle.id"
								@click="togglePlanCycleSelect(cycle)"
								:checked="isChecked(cycle.id)"
							/>
						</td>
						<td class="col-2">{{ cycle.title }}</td>
						<td class="col">
							{{ currencyParser(cycle.amount) }}
						</td>
						<td class="col">
							{{ currencyParser(cycle.amount) }}
						</td>
						<td class="col">{{ dateParser(cycle.startAt) }}</td>
						<td class="col">{{ dateParser(cycle.endAt) }}</td>
						<td class="col">{{ cycle.gracePeriod }} days</td>
						<td class="col">
							<button
								type="button"
								:class="[
									'dropdown-toggle add-payment',
									{
										'success-btn': isPaid(cycle.id),
										'primary-btn': !isPaid(cycle.id),
									},
								]"
								name="select_1"
								:id="`payment-${cycle.id}`"
								:disabled="!isChecked(cycle.id)"
								data-bs-toggle="dropdown"
							>
								<!-- @click="markPaid(cycle.id)"
								:checked="isPaid(cycle.id)" -->
								{{ paymentButtonText(cycle.id) }}
							</button>
							<PlanCyclePaymentDropdown
								:cycle="cycle"
								:selectedCycles="selectedCycles"
								@payment="addMultiplePayment"
								:multiplePlanPayments="multiplePlanPayments"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<div
				v-if="Object.keys(multiplePlanPayments).length > 0"
				class="inline-group"
			>
				<div class="col">
					<label for="paymentMethod" class="main-label"
						>Payment Method (required)</label
					>
					<v-select
						placeholder="Select Payment Method"
						id="paymentMethod"
						class="form-input"
						:options="externalServicesList.PaymentGateway"
						label="serviceName"
						v-model="paymentMethod"
						required
						:appendToBody="true"
						:calculate-position="withPopper"
					></v-select>
				</div>
				<div class="col">
					<label for="paymentComments" class="main-label"
						>Comments (optional)</label
					>
					<input
						type="search"
						id="paymentComments"
						class="form-input"
						placeholder="Comments (if any)"
						v-model="paymentComments"
					/>
				</div>
			</div>
		</div>
		<div v-else-if="planType == planTypes.ONE_TIME">
			<div class="form-check form-switch mb-4">
				<input
					class="form-check-input"
					type="checkbox"
					:id="`isPaid-${productVariantId}`"
					role="switch"
					v-model="isSinglePlanPaid"
				/>
				<label class="form-check-label" :for="`isPaid-${productVariantId}`"
					>Mark as Paid</label
				>
			</div>
			<div v-if="isSinglePlanPaid" class="inline-group">
				<div class="col">
					<label for="amountPayable" class="main-label extended"
						>Course Price</label
					>
					<input
						placeholder="Enter payable amount"
						class="form-input"
						type="number"
						id="amountPayable"
						disabled
						:value="selectedPlan.details[0].amount"
					/>
				</div>
				<div class="col">
					<label
						for="amountReceived"
						:class="['main-label extended', { 'text-danger': isError }]"
						>Amount Received (required)</label
					>
					<input
						placeholder="Enter received amount"
						:class="['form-input', { 'text-danger': isError }]"
						type="number"
						id="amountReceived"
						v-model="singlePlanReceived"
						:max="selectedPlan.details[0].amount - singlePlanDiscount"
						@change="validateAmount"
					/>
				</div>
				<div class="col">
					<label
						for="amountDiscount"
						:class="['main-label extended', { 'text-danger': isError }]"
						>Discount (optional)</label
					>
					<input
						placeholder="Enter discount"
						:class="['form-input', { 'text-danger': isError }]"
						type="number"
						id="amountDiscount"
						v-model="singlePlanDiscount"
						:max="selectedPlan.details[0].amount - singlePlanReceived"
						@change="validateAmount"
					/>
				</div>
				<div class="col">
					<label
						for="amountBalance"
						:class="['main-label extended', { 'text-danger': isError }]"
						>Outstanding</label
					>
					<input
						placeholder="Enter balance"
						:class="['form-input', { 'text-danger': isError }]"
						type="search"
						id="amountBalance"
						:value="singlePlanOutstanding()"
						disabled
					/>
				</div>
			</div>
			<p v-if="isError" class="text-danger">
				<i class="fas fa-exclamation-circle"></i> {{ errorText }}
			</p>
			<div v-if="isSinglePlanPaid" class="inline-group">
				<div class="col">
					<label for="paymentMethod" class="main-label"
						>Payment Method (required)</label
					>
					<v-select
						placeholder="Select Payment Method"
						id="paymentMethod"
						class="form-input"
						:options="externalServicesList.PaymentGateway"
						label="serviceName"
						v-model="paymentMethod"
						:appendToBody="true"
						:calculate-position="withPopper"
					></v-select>
				</div>
				<div class="col">
					<label for="paymentComments" class="main-label"
						>Comments (optional)</label
					>
					<input
						type="search"
						id="paymentComments"
						class="form-input"
						placeholder="Comments (if any)"
						v-model="paymentComments"
					/>
				</div>
			</div>
		</div>
		<p v-if="missingError" class="text-danger">{{ errorText }}</p>
		<!-- <button
			v-if="showDone"
			class="step-btn ms-auto collapsed"
			@click="emitInvoiceDetails"
			data-bs-toggle="collapse"
			:data-bs-target="`#variant-collapse-${productVariantId}`"
			aria-expanded="false"
			:disabled="!selectedPlan"
		>
			Done
		</button> -->
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PlanCyclePaymentDropdown from "./PlanCyclePaymentDropdown.vue";
import formatter from "../../../helpers/FormatHelpers";
import { createPopper } from "@popperjs/core";
export default {
	name: "EnrollmentPricingPlans",
	data() {
		return {
			selectedCycles: [],
			isSinglePlanPaid: false,
			selectedPlan: null,
			singlePlanDiscount: null,
			singlePlanReceived: null,
			paymentMethod: null,
			paymentComments: null,
			multiplePlanPayments: {},
			arePlansLoaded: false,
			planTypes: {
				ONE_TIME: "OneTime",
				MULTIPLE_PAYMENT_CYCLES: "MultiplePaymentCycles",
			},
			errorText: "",
			missingError: false,
			isError: false,
		};
	},
	methods: {
		...mapActions("appState", ["fetchExternalServices"]),
		...mapActions("pricing", ["fetchPricingPlanByProductVariantId"]),
		plansByType(planType) {
			if (this.pricingPlanByProductVariantId[this.productVariantId]) {
				var filteredPlans = this.pricingPlanByProductVariantId[
					this.productVariantId
				].filter((plan) => plan.type == planType);
				return filteredPlans;
			}
			return [];
		},
		toggleSelectAll() {
			if (
				this.planCycles &&
				this.planCycles.length > 0 &&
				this.selectedCycles.length != this.planCycles.length
			) {
				this.selectedCycles = this.planCycles;
			} else {
				this.selectedCycles = [];
			}
		},
		isSelectedAll() {
			return this.planCycles.length == this.selectedCycles.length;
		},
		togglePlanCycleSelect(cycle) {
			var found = this.selectedCycles.find((s) => s.id == cycle.id);
			console.log(found, cycle);
			if (!found) {
				this.selectedCycles.push(cycle);
			} else {
				this.selectedCycles = this.selectedCycles.filter(
					(su) => su.id != cycle.id
				);
			}
		},
		isChecked(id) {
			var found = this.selectedCycles.find((cycle) => cycle.id == id);
			// console.log(found);
			if (found) {
				return true;
			} else return false;
		},
		markPaid(id) {
			this.selectedCycles = this.selectedCycles.map((cycle) => {
				if (cycle.id == id) {
					console.log(cycle.title);
					if (!cycle.isPaid) {
						var currentCycle = cycle;
						currentCycle.isPaid = true;
						cycle = currentCycle;
					}
				}
				return cycle;
			});
		},
		isPaid(id) {
			var found = this.multiplePlanPayments[id];
			if (found) {
				return true;
			} else return false;
		},
		singlePlanOutstanding() {
			var balance;
			if (this.selectedPlan)
				balance =
					this.selectedPlan.details[0].amount -
					this.singlePlanDiscount -
					this.singlePlanReceived;
			else balance = 0;
			if (balance >= 0) return balance;
			else {
				this.isError = true;
				this.errorText = "Paid amount cannot be greater than course amount";
				return "Invalid amount";
			}
		},
		addMultiplePayment(payment) {
			this.multiplePlanPayments[payment.id] = payment.amount;
			this.markPaid(payment.id);
			document.getElementById(`payment-${payment.id}`).click();
		},
		paymentButtonText(id) {
			if (this.multiplePlanPayments[id]) {
				var amount =
					parseInt(this.multiplePlanPayments[id].received) +
					parseInt(this.multiplePlanPayments[id].discount);
				// console.log(amount);

				return formatter.toCurrencyString(
					amount,
					this.selectedPlan.currency,
					0
				);
			} else {
				return "+ Add";
			}
		},
		currencyParser(amount) {
			return formatter.toCurrencyString(
				parseInt(amount),
				this.selectedPlan.currency,
				0
			);
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		emitInvoiceDetails() {
			var invoiceDetail = [];
			if (
				this.selectedPlan &&
				this.selectedPlan.type.toLowerCase() ==
					this.planTypes.ONE_TIME.toLowerCase()
			) {
				invoiceDetail = [
					{
						planDetailID: parseInt(this.selectedPlan.details[0].id),
						amount: 0, //this.selectedPlan.details[0].amount,
						status: "unpaid",
					},
				];
				var dueDate = new Date().setDate(
					new Date().getDate() + this.selectedPlan.details[0].gracePeriod
				);
				dueDate = new Date(dueDate).setHours(23, 59, 59);
				console.log(dueDate);
				invoiceDetail[0].dueDate = new Date(dueDate).toISOString();
				invoiceDetail[0].discount = 0.0;
				invoiceDetail[0].discountType = null;
				invoiceDetail[0].isPaid = this.isSinglePlanPaid;
				if (this.isSinglePlanPaid) {
					if (!this.singlePlanReceived) {
						this.errorText =
							"Please enter received amount or check if entered amount is valid";
						this.missingError = true;
						this.$emit("error", {
							text: "Please enter received amount or check if entered amount is valid",
							status: true,
							id: this.productVariantId,
						});
					} else if (!this.paymentMethod) {
						this.errorText = "Please select a payment method";
						this.missingError = true;
						this.$emit("error", {
							text: "Please select a payment method",
							status: true,
							id: this.productVariantId,
						});
					} else {
						this.missingError = false;
						this.errorText = "";
						this.$emit("error", {
							text: "",
							status: false,
							id: this.productVariantId,
						});
						invoiceDetail[0].payment = {
							customerID: this.user.id,
							paymentDate: new Date().toISOString(),
							method: this.paymentMethod.serviceName,
							methodProvider: this.paymentMethod.serviceProvider,
							methodProviderID: this.paymentMethod.serviceCode,
							details: [
								{
									amount: parseFloat(this.singlePlanReceived),
									type: "received",
								},
							],
							status: "Completed",
						};
					}
				}
				// var isDiscount = this.singlePlanDiscount;
				// console.log(isDiscount);
				// if (isDiscount) {
				// 	invoiceDetail[0].discount = isDiscount;
				// 	invoiceDetail[0].discountType = "amount";
				// }
			} else {
				this.selectedCycles.forEach((cycle) => {
					var detail = {
						planDetailID: parseInt(cycle.id),
						amount: 0, //cycle.amount,
						status: "unpaid",
					};
					var dueDate = new Date().setDate(
						new Date().getDate() + cycle.gracePeriod
					);
					dueDate = new Date(dueDate).setHours(23, 59, 59);

					console.log(dueDate);

					detail.dueDate = new Date(dueDate).toISOString();
					detail.discount = 0.0;
					detail.discountType = null;
					if (
						Object.values(this.multiplePlanPayments).length > 0 &&
						this.multiplePlanPayments[cycle.id]
					) {
						if (!this.paymentMethod) {
							this.errorText = "Please select a payment method";
							this.missingError = true;
							this.$emit("error", {
								text: "Please select a payment method",
								status: true,
								id: this.productVariantId,
							});
						} else if (!this.multiplePlanPayments[cycle.id].received) {
							this.errorText =
								"Please enter received amount or check if entered amount is valid";
							this.missingError = true;
							this.$emit("error", {
								text: "Please enter received amount or check if entered amount is valid",
								status: true,
								id: this.productVariantId,
							});
						} else {
							this.missingError = false;
							this.errorText = "";
							this.$emit("error", {
								text: "",
								status: false,
								id: this.productVariantId,
							});
							console.log(this.multiplePlanPayments[cycle.id]);
							var payAmount = this.multiplePlanPayments[cycle.id];
							detail.payment = {
								customerID: this.user.id,
								paymentDate: new Date().toISOString(),
								method: this.paymentMethod.serviceName,
								methodProvider: this.paymentMethod.serviceProvider,
								methodProviderID: this.paymentMethod.serviceCode,

								details: [
									{
										amount: parseFloat(payAmount.received),
										type: "received",
									},
								],
								status: "Completed",
							};
						}
					} // var isDiscount =
					// 	this.multiplePlanPayments[cycle.id] &&
					// 	this.multiplePlanPayments[cycle.id].discount;
					// if (isDiscount) {
					// 	detail.discount = isDiscount;
					// 	detail.discountType = "amount";
					// }
					invoiceDetail.push(detail);
				});
			}
			this.$emit("invoiceDetailAdd", invoiceDetail);
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		validateAmount(e) {
			console.log("validateAmount", e.target.value);
			if (e.target.value < 0) {
				this.isError = true;
				console.log("error", e.target.id);
				this.errorText = `Enter a valid amount ${
					e.target.id == "amountReceived"
						? "(Amount Received)"
						: e.target.id == "amountDiscount"
						? "(Discount)"
						: ""
				}`;
			} else {
				this.isError = false;
				this.errorText = "";
			}
		},
	},
	computed: {
		...mapState("appState", ["externalServicesList"]),
		...mapState("pricing", ["pricingPlanByProductVariantId"]),
		planType() {
			if (this.selectedPlan) return this.selectedPlan.type;
		},
		// isError() {
		// 	var error = this.singlePlanOutstanding() == "Invalid amount";
		// 	// if (error) {
		// 	// 	this.$emit("error", error);
		// 	// }
		// 	return error;
		// },
		planCycles() {
			// var planCycles = new Array();
			var planCycles = this.selectedPlan.details;
			planCycles = JSON.parse(JSON.stringify(planCycles));
			// Object.freeze(planCycles);
			return planCycles;
		},
		// selectedCycleReceived: {
		// 	get: function(e) {
		// 		const id = e.target.id;
		// 		console.log(id);
		// 		var currentCycle = this.selectedCycles.find((c) => c.id == id);
		// 		if (currentCycle.payments) {
		// 			return currentCycle.payments.receivedAmount;
		// 		} else return null;
		// 	},
		// 	set: function(e, value) {
		// 		const id = e.target.id;
		// 		var currentCycle = this.selectedCycles.find((c) => c.id == id);
		// 		if (currentCycle.payments) {
		// 			currentCycle.payments.receivedAmount = value;
		// 		}
		// 	},
		// },
	},
	created() {
		console.log(this.$parent.$data);
		if (!this.externalServicesList.PaymentGateway) {
			this.fetchExternalServices("PaymentGateway").then(
				console.log(this.externalServicesList.PaymentGateway)
			);
		}
		if (!this.pricingPlanByProductVariantId[this.productVariantId]) {
			this.fetchPricingPlanByProductVariantId(this.productVariantId).then(
				() => (this.arePlansLoaded = true),
				console.log(this.pricingPlanByProductVariantId[this.productVariantId])
			);
		} else this.arePlansLoaded = true;
	},
	props: {
		productVariantId: String,
		showDone: Boolean,
		user: Object,
		parent: String,
	},
	components: { PlanCyclePaymentDropdown },
	watch: {
		isError: function () {
			this.$emit("error", {
				text: this.errorText,
				status: this.isError,
				id: this.productVariantId,
			});
		},
		selectedPlan: function () {
			this.emitInvoiceDetails();
			this.$emit("updatePlan", this.selectedPlan);
		},
		selectedCycles: function () {
			if (this.parent == "userenroll") this.emitInvoiceDetails();
			else if (this.parent == "variantenroll") this.$emit("updatePayments");
			// this.emitInvoiceDetails();
		},
		paymentMethod: function () {
			if (this.paymentMethod) {
				if (this.parent == "userenroll") this.emitInvoiceDetails();
				else if (this.parent == "variantenroll") this.$emit("updatePayments");
				this.isError = false;
				this.missingError = false;
			}
		},
		isSinglePlanPaid: function () {
			if (!this.isSinglePlanPaid) {
				this.$emit("removePayments");
				this.isError = false;
				this.missingError = false;
				this.$emit("error", {
					text: "",
					status: this.isError,
					id: this.productVariantId,
				});
			} else if (this.parent == "variantenroll" && this.singlePlanReceived)
				this.$emit("updatePayments");
		},
	},
};
</script>

<style scoped>
.table-container {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
}

.enrolled-users-table thead th,
.enrolled-users-table td {
	min-height: 4rem;
	vertical-align: middle;
}

/* .enrolled-users-table td:first-of-type {
	padding-left: 0;
} */

.enrolled-users-table input[type="checkbox"] {
	/* margin-left: 1.5rem; */
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.enrolled-users-table tbody {
	display: block;
	max-height: 14rem !important;
	overflow-y: auto;
}

.enrolled-users-table thead tr,
.enrolled-users-table tbody tr {
	display: table !important;
	width: 100%;
	table-layout: fixed;
}

.add-payment:after {
	display: none !important;
}

.main-label.extended {
	min-height: 2.75rem;
}
</style>
