<template>
	<div class="product-details-card-container">
		<div
			class="product-details-card"
			v-if="!loading && productVariantData != null"
		>
			<div class="product-image hide-tab-mobile">
				<img
					:src="require(`@/${productVariantData.product.thumbnailPath}`)"
					alt=""
				/>
			</div>
			<div class="product-card-content">
				<h3 class="product-price text-bold">
					{{
						parseCurrency(
							productVariantData.pricing.amount,
							productVariantData.pricing.currency,
							0
						)
					}}
				</h3>
				(Price is inclusive of taxes)
				<p>
					<span>Course Start Date: </span>
					{{ new Date(productVariantData.startDate).toDateString() }}<br />
					<span>Course End Date: </span>
					{{ new Date(productVariantData.endDate).toDateString() }}<br />
				</p>
				<a
					role="button"
					@click="this.handleClick"
					class="btn-block-rounded-primary"
					>Buy Now</a
				>
				<div class="add-promo">
					<!-- <span class="input-icon-right">Remove</span> -->
					<a
						v-if="showPromoInput"
						@click="togglePromoInput"
						role="button"
						class="input-icon-right promo-delete"
					>
						<i class="far fa-trash-alt"></i
					></a>
					<input
						v-if="showPromoInput"
						type="text"
						name="promo"
						id="promo"
						v-model="promoValue"
						class="form-control promo-input"
						placeholder="Enter your promo code"
					/>
					<a
						class="add-promo-text"
						v-else
						role="button"
						@click="togglePromoInput"
						>Click here to add promo coupon</a
					>
				</div>
			</div>
		</div>
		<div
			class="product-desc-card hide-tab-mobile"
			v-if="!loading && productVariantData != null"
		>
			<ul class="tabs">
				<li
					@click="changeTab"
					id="0"
					:class="{ active: activeTab == '0', tab: true }"
				>
					Description
				</li>
				<li
					@click="changeTab"
					id="1"
					:class="{ active: activeTab == '1', tab: true }"
				>
					Instructor
				</li>
			</ul>
			<div class="tabs-body">
				<div v-if="activeTab == '0'" class="tabs-body-content desc-tab">
					{{ productVariantData.description }}
				</div>
				<div v-else-if="activeTab == '1'" class="tabs-body-content creator-tab">
					<div class="row">
						<div class="col-lg-2">
							<img
								:src="require(`@/${productVariantData.creator.imagePath}`)"
								alt=""
								class="profile"
							/>
						</div>
						<div class="col-lg-10">
							<!-- <router-link to="">{{
								productVariantData.creator.name
							}}</router-link> -->
						</div>
					</div>
					<div class="row creator-desc">
						{{ productVariantData.creator.description }}
					</div>
				</div>
			</div>
		</div>
		<div v-else class="product-details-card">
			<div class="product-image hide-tab-mobile">
				<b-skeleton-img></b-skeleton-img>
			</div>
			<div class="text-center loader-component">
				<div class="spinner-border" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from "../../router";
import { mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";

export default {
	name: "ProductDetailsCard",
	data() {
		return {
			// productVariantData: productVariantData,
			showPromoInput: false,
			promoValue: "",
			activeTab: "0",
		};
	},
	props: { productVariantData: Object },
	methods: {
		...mapActions("cart", ["addToCart"]),
		togglePromoInput() {
			this.showPromoInput = !this.showPromoInput;
			this.promoValue = "";
		},
		handleClick() {
			console.log("buy now clicked");
			this.addToCart(this.productVariantData);
			router.push("/shop/checkout");
		},
		changeTab(e) {
			this.activeTab = e.target.id;
		},
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
	},
	computed: {
		...mapState("cart", ["cart"]),
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.product-details-card {
		top: 1rem !important;
		min-height: unset !important;
		border-radius: 0 !important;
		border: none !important;
		/* width: 100vw !important; */
	}

	.product-details-card-container {
		padding: 0 !important;
		margin: 0 -12px 0 !important;
	}

	.product-card-content {
		padding-bottom: 0 !important;
	}
}

@media screen and (max-width: 768px) {
	.product-card-content {
		font-size: 1.1rem !important;
		padding: 1rem !important;
	}

	.product-card-content p {
		font-size: 1rem !important;
		margin-bottom: 0 !important;
		padding: 1rem !important;
	}

	.add-promo {
		margin-top: 1rem !important;
	}
}

@media screen and (max-width: 600px) {
	.product-details-card {
		top: 0 !important;
	}
}

.product-details-card-container {
	padding: 1rem;
}

.product-details-card {
	width: 100%;
	position: relative;
	min-height: 500px;
	background-color: var(--color-white);
	top: 11rem;
	border-radius: 1rem;
	z-index: 997;
	border: 2px solid var(--color-light-blue);
}

.product-image {
	display: flex;
	justify-content: center;
	margin: -3.5rem auto 0px;
	width: 100%;
}

.product-image .b-aspect {
	width: 100%;
}

.product-image .b-skeleton-img {
	width: 90%;
	border-radius: 1rem;
	margin: 0 auto;
	background-color: var(--color-gray);
}

.product-image img {
	height: auto;
	object-fit: cover;
	object-position: top;
	width: 90%;
	border-radius: 1rem;
}

.product-card-content {
	display: flex;
	justify-content: center;
	padding: 2rem;
	flex-direction: column;
	align-items: center;
	color: var(--secondary-color);
	font-size: 1.2rem;
}

.product-card-content p span {
	font-weight: 500;
}

.product-card-content p {
	font-size: 1.15rem;
	padding: 1rem;
	text-align: center;
	line-height: 1.2;
}

.add-promo {
	margin-top: 2rem;
	text-align: center;
	padding: 0 2rem;
	width: 100%;
}

.add-promo .add-promo-text {
	text-decoration: underline !important;
	font-weight: bold !important;
	font-size: 1rem;
	color: var(--color-black);
}

.promo-delete :hover,
.promo-delete:active,
.promo-delete:visited,
.promo-delete:focus {
	color: var(--color-danger);
}

.promo-delete {
	margin: 10px 84px 0 0 !important;
	color: var(--color-danger);
}

.product-desc-card {
	background-color: var(--color-white);
	border-radius: 1rem;
	z-index: 997;
	border: 2px solid var(--color-light-blue);
	position: relative;
	top: 10rem;
	margin-top: 2rem;
}

.product-desc-card .tabs {
	list-style: none;
	padding: 0;
}

.product-desc-card .tabs .tab {
	display: inline-flex;
	width: 50%;
	padding: 1rem;
	margin: 0;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
	font-weight: 500;
	border-bottom: 2px solid transparent;
	cursor: pointer;
}

.active {
	border-bottom: 2px solid var(--primary-color) !important;
}

.tabs-body {
	padding: 0 2rem 2rem;
}

.creator-tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.creator-tab .row {
	display: flex;
	align-items: center;
}

.creator-tab .creator-desc {
	justify-content: left;
}

.creator-tab a {
	font-size: 1.5rem;
	margin-left: 1.5rem;
	font-weight: bold;
	color: var(--color-text-default);
	text-decoration: none;
}

img.profile {
	border-radius: 50%;
	width: 100%;
}
</style>
