class LiveClass {
	constructor(liveClass) {
		console.log("data in LiveClass constructor", liveClass);
		this.id = liveClass.id;
		this.productVariantID = liveClass.productVariantID;
		this.parentDirectoryID = liveClass.parentDirectoryID ?? null;
		this.productVariantTitle = liveClass.productVariantTitle;
		this.title = liveClass.title;
		this.type = liveClass.type;
		this.availableFrom = liveClass.availableFrom;
		this.availableTo = liveClass.availableTo;
		this.isContentAvailableForDemo = liveClass.isContentAvailableForDemo;
		this.sequence = liveClass.sequence;
		if (liveClass.externalService) {
			this.externalService = {
				serviceCode: liveClass.externalService.serviceCode,
				serviceName: liveClass.externalService.serviceName,
				serviceData: liveClass.externalService.serviceData,
				serviceType: liveClass.externalService.serviceType,
				serviceProvider: liveClass.externalService.serviceProvider,
			};
		}
		this.extraProperties = liveClass.extraProperties
			? JSON.parse(liveClass.extraProperties)
			: null;
		if (liveClass.data == null) {
			this.data = null;
		} else {
			// this.data = {
			// 	apiKey: "6KwQg8BBQgyCs92WNNW1OA",
			// 	signature: "",
			// 	topic: liveClass.data.topic,
			// 	meetingId: 76568937394,
			// 	password: "123456",
			// 	joining_Url: liveClass.data.joining_Url,
			// 	openInSDK: liveClass.data.openInSDK,
			// 	registrantAllow: liveClass.data.registrantAllow,
			// 	start_Time: liveClass.data.start_Time,
			// 	duration: liveClass.data.duration,
			// };
			this.data = liveClass.data;
		}
	}
}

export default LiveClass;
