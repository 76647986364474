class ProductVariantStatus {
	static DRAFT = "Draft";
	static PUBLISHED = "Published";
	static PRIVATE = "Private";
	static COMING_SOON = "Coming Soon";
}

class ProductVariantEnrollmentType {
	static PRICING_PLAN = "PricingPlan";
	static DIRECT_ENROLLMENT = "DirectEnrollment";
}

class ProductVariantSubscriptionStatus {
	static BLOCKED = "Blocked";
	static ALLOWED = "Allowed";
	static UNENROLLED = "Unenrolled";
}

export {
	ProductVariantStatus,
	ProductVariantEnrollmentType,
	ProductVariantSubscriptionStatus,
};
