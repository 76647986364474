<template>
	<div class="py-3 px-md-3 px-0 col-lg-12 text-center exam-content">
		<img class="page-view-icon mb-3" src="@/assets/images/exam-test.png" />
		<h3>{{ contentData.title }}
			<!-- <br/>
				<small>Exam</small> -->
		</h3>
		<div v-if="contentData.type == ContentType.EXAM &&
			contentData.externalService.serviceProvider.toLowerCase() ==
			ServiceCodes.EXAM.V3ExamSystem.toLowerCase() &&
			contentExtraProperties
			">
			<!-- <div class="text-muted" v-if="JSON.parse(contentExtraProperties).Layout">
				<strong>Layout: 
					
				</strong>{{ JSON.parse(contentExtraProperties).Layout == "Start" ? "F5 to F9 + Professional Level ACCA Exam Format":"F1 to F4(ACCA)" }}
			</div> -->
			<div  class="text-muted">
				<span v-if="contentData.availableFrom && contentData.availableTo">
				{{ dateParser(contentData.availableFrom) }} -
				{{ dateParser(contentData.availableTo) }} |
				</span>
				<span>{{ examTimeParser(JSON.parse(contentExtraProperties).TotalTime) }}</span>
			</div>

			
				<div v-if="result && !loadingResult" class="exam-total-row d-flex justify-content-center my-4">
				<div class="col-md-2 col-xs-6 exam-total-card text-center">
					<h3 class="total-card-val">{{ result.totalQuestions }}</h3>
					<div class="total-card-label text-muted">Total Questions</div>
				</div>
				<div class="col-md-2 col-xs-6 exam-total-card text-center">
					<h3 class="total-card-val">{{ result.TotalMarks ? result.TotalMarks : "0" }}</h3>
					<div class="total-card-label text-muted">Total Marks</div>
				</div>
			</div>
			<center v-if="loadingResult" class="loading">
            <b-spinner large></b-spinner>
        	</center>
			<div v-else-if="result" class="exam-attempt-row d-flex justify-content-center flex-wrap">
				<h4 class="w-100 mb-3">Last Attempt Summary</h4>
				<div class="col-md-3 col-xs-6 ps-0 ps-md-3 exam-attempt-card d-flex text-left">
					<img class="page-view-icon" src="@/assets/images/flag-circle-blue-icon.svg" />
					<div>
						<h5 class="attempt-card-val">{{ result.attemptedCorrectly }} out of {{ result.totalQuestions }}</h5>
						<p class="attempt-card-label text-muted">Correctly Answered</p>
					</div>
				</div>
				<div class="col-md-3 col-xs-6 pe-0 pe-md-3 exam-attempt-card d-flex text-left justify-content-end">
					<img class="page-view-icon" src="@/assets/images/book-circle-cyan-icon.svg" />
					<div>
						<h5 class="attempt-card-val">{{ result.result }} 
							</h5>
						<p class="attempt-card-label text-muted">Marks Obtained</p>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center exam-actions-row mt-4">
				<button v-if="showAttemptButton(contentData) && !loadingResult"
					type="button" @click="handleClick" class="primary-btn attempt-btn btn-round mx-sm-2 mx-1">
					<img class="page-view-icon" src="@/assets/images/exam-paper-white-icon.svg" />
					{{ result ? "Reattempt Exam" : "Attempt Exam"}}
				</button>
				<button v-if="!isDisabled && result" @click="handleLegacyExamButtonClick" type="button"
					class="btn btn-outline-primary view-result-btn btn-round mx-sm-2 mx-1">
					<img class="page-view-icon" src="@/assets/images/exam-paper-icon.svg" />
					View Result
				</button>
			</div>

		</div>
	</div>
</template>

<script>

import { mapState, mapActions } from "vuex";
import { getCookie } from "../../../helpers/CookieHelpers";

import { LegacyExam } from "../../../constants/exam";
import { ContentType } from "../../../constants/content";

import { ServiceCodes } from "../../../constants/serviceCodes";
import {
	toShortDateString,
	getTimeIn12Hours,
	getInputDateFormat
} from "../../../helpers/FormatHelpers";

import formatter from "../../../helpers/FormatHelpers";
export default {
	name: "Exam",
	data() {
		return {
			ContentType,
			ServiceCodes,
			result: null,
			loadingResult: null,
		}
	},
	methods: {
		...mapActions('exam', ['getExamResultByProductVariant']),
		...mapActions("content", ["getContentById", "setUserContentAction", "getUserContentAction"]),
		dateParser(date) {
			return formatter.toShortDateString(date);
		},

		examTimeParser(time) {
			time = time.replace("00h","");
			time = time.replace("00m","");
			time = time.replace("00s","");
			time = time.replace("h", " Hr ");
			time = time.replace("m", " Min ");
			time = time.replace("s", " Sec ");
		
			return time;
		},

		getExamResult() {
			this.loadingResult = true;
			this.getExamResultByProductVariant({ productVariantID: this.$route.params.id })
				.then((data) => {
					let tempResult = null;

					for (const result of data) {
						if (result.content.id === this.contentData.id) {
							tempResult = result;
						}
					}
					
					this.result = tempResult;
					this.loadingResult = false;
				})
				.catch((error) => {
					console.error("Error fetching result: ", error);
					this.loadingResult = false;
				});
		},

		showAttemptButton(exam) {
			if (!exam) {
				return true;
			}

			const { availableFrom, availableTo } = exam;

			if (!availableFrom || !availableTo) {
				return true;
			}

			const currentDate = Date.now();
			return this.isBetweenDates(currentDate, availableFrom, availableTo);
		},
		isBetweenDates(checkDate, startDate, endDate) {

			const start = new Date(startDate)
			const end = new Date(endDate)

			return checkDate > start && checkDate < end
		},
		handleLegacyExamButtonClick() {

			this.getContentById({
				id: this.contentData.id,
				extraParams: { action: LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS },
			}).then((content) => {
				if (content && this.loggedIn) {
					var examAttemptUrl =
						content.data.redirectUrl + "&RedirectUrl=" + window.location.href;
					window.open(examAttemptUrl, "_blank");
					return;
				}
			});
		},
		handleClick: function () {
			if (
				this.contentData.externalService.serviceProvider ==
				ServiceCodes.EXAM.V3ExamSystem
			) {
				this.getContentById({
					id: this.contentData.id,
					extraParams: { action: LegacyExam.GET_EXAM_ACTION.ATTEMPT },
				}).then((content) => {
					if (content) {
						var examAttemptUrl =
							content.data.redirectUrl +
							"&RedirectUrl=" +
							window.location.href;
						window.open(examAttemptUrl, "_blank");
						// window.location.href = examAttemptUrl;
						return;
					}
				});
				return;
			}
		}
	},
	props: {
		contentData: Object,
	},
	computed: {

		...mapState("user", ["loggedIn", "user"]),
		contentLink() {

			return {
				path:
					"/content/liveclass/" +
					this.contentData.title
						.toLowerCase()
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.split(" ")
						.join("-") +
					"-" +
					this.contentData.id +
					"_" +
					this.$route.params.id,
			};

		},
		contentExtraProperties() {
			if (this.contentData.extraProperties) return this.contentData.extraProperties;
			else return null;
		},
		isDisabled() {
			return (

				(new Date(this.contentData.availableFrom) > new Date())
			);
		},
	},
	mounted() {
	},
	created() {
		this.getExamResult();
	},
};
</script>

<style>
.Exam .chapter-content-left {
	overflow: hidden;
}
</style>
<style scoped>
.total-card-val {
	margin: 0;
	font-size: 2.35rem;
}
.total-card-label {
	font-size: 1.35rem;
}

.exam-attempt-card h5 {
	margin: 0;
}

.exam-attempt-row {
	margin: 25px 0 20px;
}

.exam-actions-row .attempt-btn,
.exam-actions-row .view-result-btn {
	padding: 10px 25px;
	min-width: 172px;
}

.exam-actions-row .view-result-btn {
	border-color: darkgray !important;
}

.exam-actions-row .page-view-icon {
	width: 20px;
	height: auto;
	margin-right: 4px;
}

.loading {
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (min-width: 1200px) {
	.exam-attempt-row h4 {
		font-size: 1.25rem;
	}
}

@media (max-width: 768px) {
	.exam-actions-row .attempt-btn, 
	.exam-actions-row .view-result-btn{
		padding: 8px 15px;
		min-width: inherit;
	}
	
}
@media (max-width: 540px) {
	.exam-actions-row .page-view-icon {
		width: 18px;
		margin-right: 2px;
	}
	.total-card-label {
		font-size: 1.20rem;
	}
	.exam-attempt-card p{
		line-height: 1.2;
		font-size: 0.975rem;
		margin-top: 2px;
	}
}
</style>
