<template>
	<b-modal
		id="profile-edit-modal"
		@keydown="closeModal"
		:visible="visible"
		hide-header
		centered
		content-class="profile-edit-modal"
		size="xl"
		body-class="modal-body"
		ok-title="Update"
		footer-class="edit-modal-footer"
		:ok-disabled="disabled()"
		@ok="updateProfile"
		no-close-on-backdrop
		@cancel="closeModal"
	>
		<div class="top-icon">
			<div class="profile-edit-circle"><i class="far fa-user"></i></div>
			<h1 class="edit-modal-title mt-2">Edit Profile</h1>
		</div>
		<div class="modal-content-container">
			<form class="edit-profile-form">
				<div class="form-group row g-3">
					<div class="col-md-6">
						<label for="firstName" class="form-label">First Name</label>
						<input
							type="search"
							class="form-control edit-profile-input"
							id="firstName"
							placeholder="First Name"
							v-model="firstName"
							@input="update"
						/>
					</div>
					<div class="col-md-6">
						<label for="lastName" class="form-label">Last Name</label>
						<input
							type="search"
							class="form-control edit-profile-input"
							id="lastName"
							placeholder="Last Name"
							v-model="lastName"
							@input="update"
						/>
					</div>
				</div>
				<div class="form-group row g-3">
					<div class="col-md-12">
						<label for="email" class="form-label">Username</label>
						<input
							type="search"
							class="form-control edit-profile-input"
							id="username"
							placeholder="username123"
							v-model="username"
							@input="update"
						/>
					</div>
				</div>
				<div class="form-group row g-3">
					<div class="col-md-6">
						<label for="email" class="form-label">Email address</label>
						<input
							type="email"
							class="form-control edit-profile-input"
							id="email"
							placeholder="name@example.com"
							v-model="email"
							disabled
						/>
					</div>
					<div class="col-md-6">
						<label for="mobile" class="form-label">Phone Number</label>
						<vue-tel-input
							:class="`form-control edit-profile-input  ${
								isPhoneError && showError ? 'error' : ''
							}`"
							:inputOptions="telInputOptions"
							validCharactersOnly
							v-model="mobile"
							:defaultCountry="
								profileData.countryCode ? profileData.countryCode : ''
							"
							@input="validate"
							@country-changed="changeCountry"
							@blur="showError = true"
							@focus="showError = false"
							:autoFormat="true"
							mode="national"
							invalidMsg="Please enter a valid phone number"
							id="mobile"
							ref="phoneInput"
						></vue-tel-input>
					</div>
				</div>

				<div class="form-group row g-3">
					<div class="col-md-6">
						<label for="city" class="form-label">City</label>
						<input
							type="search"
							class="form-control edit-profile-input"
							id="city"
							v-model="city"
							@input="update"
						/>
					</div>
					<div class="col-md-6">
						<label for="country" class="form-label">Country</label>
						<v-select
							class="form-control edit-profile-input"
							id="country"
							v-model="countryCode"
							:options="countryCodes"
							label="name"
							:reduce="(option) => option['alpha-3']"
							@input="update"
							:appendToBody="true"
							:calculate-position="withPopper"
						/>
					</div>
				</div>
				<!-- <h1 class="edit-modal-title text-align-center mb-3">Change Password</h1>

				<div class="form-group row g-3">
					<div class="col-md-6">
						<label for="confirmPassword" class="form-label">Old Password</label>
						<input
							type="password"
							class="form-control edit-profile-input"
							id="confirmPassword"
							v-model="oldPassword"
							placeholder="Enter your old password"
							autocomplete
						/>
					</div>
					<div class="col-md-6">
						<label for="password" class="form-label">New Password</label>
						<input
							type="password"
							class="form-control edit-profile-input"
							id="password"
							v-model="newPassword"
							placeholder="Enter new password"
							@keydown="update"
							autocomplete
						/>
					</div>
				</div> -->
			</form>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { countryCodes } from "../../constants/countryCodes";
const getCountryISO3 = require("country-iso-2-to-3");
const getCountryISO2 = require("country-iso-3-to-2");

export default {
	name: "EditProfileModal",
	data() {
		return {
			...this.profileData,
			fullName: "",
			changePassword: false,
			newPassword: "",
			oldPassword: "",
			updatedUserData: {},
			countryCodes,
			country: null,
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: 10,
				placeholder: "Phone Number (required)",
				mode: "national",
			},
			isPhoneError: false,
			showError: false,
			phone: null,
			mobile: null,
		};
	},
	computed: {
		...mapState("user", ["loading"]),
		visible() {
			return this.showModal;
		},
		getUserGender() {
			var extra = this.profileData.extraProperties;
			return extra.CustomFields.Gender;
		},
	},
	props: { showModal: Boolean, profileData: Object },
	created() {
		this.gender = this.getUserGender;
		// this.fullName = this.getFullName;
		this.newPassword = "";
		this.oldPassword = "";
	},
	methods: {
		...mapActions("user", [
			"updateUserProfile",
			"changeUserPassword",
			"fetchCurrentUser",
		]),
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		updateProfile(e) {
			e.preventDefault();
			console.log("updateProfile");
			if (this.changePassword) {
				if (this.oldPassword !== this.newPassword) {
					this.updatedUserData.newPassword = this.newPassword;
					this.updatedUserData.oldPassword = this.oldPassword;
					this.changeUserPassword(this.updatedUserData);
				} else {
					// alert("New password cannot be the same as old password");
					this.$toasted.error(
						"New password cannot be the same as old password",
						{
							theme: "outline",
							position: "bottom-center",
							duration: 3000,
						}
					);
				}
			} else {
				this.updatedUserData.extraProperties = JSON.stringify({
					UserRegisterExtraFields: { Gender: this.gender },
				});
				this.updateUserProfile(this.updatedUserData).then((res) =>
					this.fetchCurrentUser().then((user) => {
						this.closeModal();
					})
				);
			}
		},
		update(e, target) {
			if (target) {
				this.updatedUserData[target] = e;
				return;
			}
			if (!e.target) {
				this.updatedUserData["countryCode"] = e;
				return;
			}
			// console.log(e);
			var data = e.target.value;
			this.updatedUserData[e.target.id] = data;
		},
		disabled() {
			if (this.loading) return true;
			return Object.entries(this.updatedUserData).length == 0;
		},
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		validate(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.number) {
				this.phone = obj.number;
				this.update(this.phone, "mobile");
			}
			return false;
		},
		changeCountry(obj) {
			console.log("changeCountry");
			this.country = getCountryISO3(obj.iso2);
			this.update(this.country, "country");
		},
	},
	watch: {
		newPassword: function (newVal, oldVal) {
			if (oldVal !== "") {
				// this.disabled = false;
				this.changePassword = true;
			}
		},
		visible: function () {
			if (this.visible) {
				this.phone = this.profileData.phone ? this.profileData.phone : null;
				this.mobile = this.profileData.phone ? this.profileData.phone : null;
			}
		},
	},
};
</script>

<style>
@media screen and (max-width: 480px) {
	.modal-body {
		padding: 0 2rem !important;
		display: flex;
		align-items: center;
	}

	.modal-content-container {
		max-height: 60vh !important;
	}
}

.profile-edit-modal {
	border-radius: 0.5rem !important;
	border: 0 !important;
	box-shadow: none !important;
}

.profile-edit-modal .modal-body {
	padding: 0 2.875rem;
	padding-top: 5rem !important;
	/* height: 70vh; */
}

.modal-content-container {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-y: auto !important;
	overflow-x: hidden !important;
	margin-right: -2rem;
	padding-right: 2rem;
}

.profile-edit-modal .top-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 0;
	right: 50%;
	transform: translateX(50%);
}

.profile-edit-circle {
	width: 4rem;
	height: 4rem;
	background-color: var(--primary-color);
	box-shadow: 0 5px 35px #a8a8a829;
	margin-top: -2rem;
	border-radius: 50%;
	/* margin-bottom: 1.25rem; */
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-white);
	font-size: 2rem;
	/* position: absolute; */
	/* top: 0; */
}

.edit-modal-title {
	font-weight: normal;
	font-size: 1.5rem;
	color: #000000;
}

.edit-profile-form {
	width: 100%;
}

.edit-profile-form .form-group {
	margin-bottom: 2.5rem;
}

.edit-profile-input {
	border: 1px solid #a7a9ac !important;
	box-shadow: 0 3px 6px #88888829 !important;
	border-radius: 0.375rem !important;
}

.edit-profile-input.error {
	border-color: var(--color-danger) !important;
}

.edit-modal-footer {
	border: none !important;
	background-color: #f7f7f7 !important;
	border-bottom-left-radius: 0.5rem !important;
	border-bottom-right-radius: 0.5rem !important;
	margin-top: 0 !important;
	padding: 1.5rem 2.875rem !important;
}

.edit-modal-footer .btn-primary {
	background-color: var(--secondary-color);
	border-color: var(--secondary-color);
	padding: 0.5rem 1.375rem !important;
	border-radius: 0.375rem !important;
	line-height: 1 !important;
	margin: 0.25rem !important;
	font-weight: normal;
}

.edit-modal-footer .btn-secondary {
	background-color: var(--color-white) !important;
	color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
	padding: 0.5rem 1.375rem !important;
	border-radius: 0.375rem !important;
	line-height: 1 !important;
	margin: 0.25rem !important;
	margin-right: 0.5rem !important;
	font-weight: normal;
}

.edit-modal-footer .btn-primary:hover,
.edit-modal-footer .btn-primary:focus,
.edit-modal-footer .btn-primary:active,
.edit-modal-footer .btn-primary:disabled {
	background-color: var(--secondary-color-hover) !important;
	outline: none !important;
	box-shadow: none !important;
	border-color: var(--secondary-color-hover) !important;
}

.edit-profile-input .vs__dropdown-toggle {
	border: none !important;
}
</style>
