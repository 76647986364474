<template>
	<li>
		<div class="form-group">
			<input
				type="checkbox"
				name="trainer"
				:id="`trainer${trainerData.id}_${productVariantId}`"
				@change="checkboxToggle($event, trainerData)"
				:checked="!isDisabled()"
			/>
			<label :for="`trainer${trainerData.id}_${productVariantId}`">
				<span class="text-black"
					>{{ trainerData.firstName }} {{ trainerData.lastName }}</span
				>
				&nbsp;({{ trainerData.email }})
			</label>

			<v-select
				multiple
				class="permission-selector"
				:value="permissionValue"
				:options="options"
				placeholder="Permission"
				@input="updatePermissions"
				:reduce="(permission) => permission.value"
				:disabled="isDisabled()"
				:closeOnSelect="false"
				deselectFromDropdown
				:appendToBody="true"
				:calculate-position="withPopper"
				hidden
			>
			</v-select>
		</div>
	</li>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
	name: "TrainerPermissionsListItem",
	data() {
		return {
			options: [
				{ label: "Read", value: "RD" },
				{ label: "Write", value: "WD" },
				{ label: "Edit", value: "ED" },
			],
		};
	},
	props: {
		trainerData: Object,
		trainersList: Array,
		productVariantId: String,
	},
	computed: {
		permissionValue() {
			var trainer = this.trainersList
				? this.trainersList.find((t) => t.id == this.trainerData.id)
				: null;
			if (trainer) {
				return trainer.permissions;
			} else return [];
		},
	},
	methods: {
		updatePermissions(value) {
			this.$emit("editPermissions", value, this.trainerData.id);
		},
		checkboxToggle(e, data) {
			this.$emit("checkboxToggle", e, data);
		},
		isDisabled() {
			var trainerAssigned = this.trainersList
				? this.trainersList.find((t) => t.trainerID == this.trainerData.id)
				: false;
			return !trainerAssigned;
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component.$refs.toggle, { width });
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
	},
};
</script>

<style scoped>
.all-trainers-list li .form-group {
	display: flex;
	flex-direction: row !important;
	column-gap: 0.75rem;
	width: 100%;
	align-items: center;
}

.all-trainers-list li .form-group input,
.all-trainers-list li .form-group label {
	margin: 0;
}

.all-trainers-list li .form-group .permission-selector {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 5px;
	width: 12.5rem;
	margin-left: auto;
	/* height: 2.25rem; */
}

.all-trainers-list li .form-group input[type="checkbox"] {
	background: #ffffff;
	border: 1px solid #b2b2b2;
	box-sizing: border-box;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	width: 1.25rem;
	height: 1.25rem;
}

.all-trainers-list li .form-group input[type="checkbox"]:checked::before {
	background-color: var(--primary-color);
}
</style>
