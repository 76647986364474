export const countryCurrency = [
	{ country: "AD", currency: "EUR" },
	{ country: "AE", currency: "AED" },
	{ country: "AF", currency: "AFN" },
	{ country: "AG", currency: "XCD" },
	{ country: "AI", currency: "XCD" },
	{ country: "AL", currency: "ALL" },
	{ country: "AM", currency: "AMD" },
	{ country: "AO", currency: "AOA" },
	{ country: "AR", currency: "ARS" },
	{ country: "AS", currency: "USD" },
	{ country: "AT", currency: "EUR" },
	{ country: "AU", currency: "AUD" },
	{ country: "AW", currency: "AWG" },
	{ country: "AX", currency: "EUR" },
	{ country: "AZ", currency: "AZN" },
	{ country: "BA", currency: "BAM" },
	{ country: "BB", currency: "BBD" },
	{ country: "BD", currency: "BDT" },
	{ country: "BE", currency: "EUR" },
	{ country: "BF", currency: "XOF" },
	{ country: "BG", currency: "BGN" },
	{ country: "BH", currency: "BHD" },
	{ country: "BI", currency: "BIF" },
	{ country: "BJ", currency: "XOF" },
	{ country: "BL", currency: "EUR" },
	{ country: "BM", currency: "BMD" },
	{ country: "BN", currency: "BND" },
	{ country: "BO", currency: "BOB" },
	{ country: "BQ", currency: "USD" },
	{ country: "BR", currency: "BRL" },
	{ country: "BS", currency: "BSD" },
	{ country: "BT", currency: "INR" },
	{ country: "BV", currency: "NOK" },
	{ country: "BW", currency: "BWP" },
	{ country: "BY", currency: "BYN" },
	{ country: "BZ", currency: "BZD" },
	{ country: "CA", currency: "CAD" },
	{ country: "CC", currency: "AUD" },
	{ country: "CD", currency: "CDF" },
	{ country: "CF", currency: "XAF" },
	{ country: "CG", currency: "XAF" },
	{ country: "CH", currency: "CHF" },
	{ country: "CI", currency: "XOF" },
	{ country: "CK", currency: "NZD" },
	{ country: "CL", currency: "CLP" },
	{ country: "CM", currency: "XAF" },
	{ country: "CN", currency: "CNY" },
	{ country: "CO", currency: "COP" },
	{ country: "CR", currency: "CRC" },
	{ country: "CU", currency: "CUP" },
	{ country: "CV", currency: "CVE" },
	{ country: "CW", currency: "ANG" },
	{ country: "CX", currency: "AUD" },
	{ country: "CY", currency: "EUR" },
	{ country: "CZ", currency: "CZK" },
	{ country: "DE", currency: "EUR" },
	{ country: "DJ", currency: "DJF" },
	{ country: "DK", currency: "DKK" },
	{ country: "DM", currency: "XCD" },
	{ country: "DO", currency: "DOP" },
	{ country: "DZ", currency: "DZD" },
	{ country: "EC", currency: "USD" },
	{ country: "EE", currency: "EUR" },
	{ country: "EG", currency: "EGP" },
	{ country: "EH", currency: "MAD" },
	{ country: "ER", currency: "ERN" },
	{ country: "ES", currency: "EUR" },
	{ country: "ET", currency: "ETB" },
	{ country: "FI", currency: "EUR" },
	{ country: "FJ", currency: "FJD" },
	{ country: "FK", currency: "FKP" },
	{ country: "FM", currency: "USD" },
	{ country: "FO", currency: "DKK" },
	{ country: "FR", currency: "EUR" },
	{ country: "GA", currency: "XAF" },
	{ country: "GB", currency: "GBP" },
	{ country: "GD", currency: "XCD" },
	{ country: "GE", currency: "GEL" },
	{ country: "GF", currency: "EUR" },
	{ country: "GG", currency: "GGP" },
	{ country: "GH", currency: "GHS" },
	{ country: "GI", currency: "GIP" },
	{ country: "GL", currency: "DKK" },
	{ country: "GM", currency: "GMD" },
	{ country: "GN", currency: "GNF" },
	{ country: "GP", currency: "EUR" },
	{ country: "GQ", currency: "XAF" },
	{ country: "GR", currency: "EUR" },
	{ country: "GS", currency: "GBP" },
	{ country: "GT", currency: "GTQ" },
	{ country: "GU", currency: "USD" },
	{ country: "GW", currency: "XOF" },
	{ country: "GY", currency: "GYD" },
	{ country: "HK", currency: "HKD" },
	{ country: "HM", currency: "AUD" },
	{ country: "HN", currency: "HNL" },
	{ country: "HR", currency: "HRK" },
	{ country: "HT", currency: "HTG" },
	{ country: "HU", currency: "HUF" },
	{ country: "ID", currency: "IDR" },
	{ country: "IE", currency: "EUR" },
	{ country: "IL", currency: "ILS" },
	{ country: "IM", currency: "GBP" },
	{ country: "IN", currency: "INR" },
	{ country: "IO", currency: "USD" },
	{ country: "IQ", currency: "IQD" },
	{ country: "IR", currency: "IRR" },
	{ country: "IS", currency: "ISK" },
	{ country: "IT", currency: "EUR" },
	{ country: "JE", currency: "GBP" },
	{ country: "JM", currency: "JMD" },
	{ country: "JO", currency: "JOD" },
	{ country: "JP", currency: "JPY" },
	{ country: "KE", currency: "KES" },
	{ country: "KG", currency: "KGS" },
	{ country: "KH", currency: "KHR" },
	{ country: "KI", currency: "AUD" },
	{ country: "KM", currency: "KMF" },
	{ country: "KN", currency: "XCD" },
	{ country: "KP", currency: "KPW" },
	{ country: "KR", currency: "KRW" },
	{ country: "KW", currency: "KWD" },
	{ country: "KY", currency: "KYD" },
	{ country: "KZ", currency: "KZT" },
	{ country: "LA", currency: "LAK" },
	{ country: "LB", currency: "LBP" },
	{ country: "LC", currency: "XCD" },
	{ country: "LI", currency: "CHF" },
	{ country: "LK", currency: "LKR" },
	{ country: "LR", currency: "LRD" },
	{ country: "LS", currency: "LSL" },
	{ country: "LT", currency: "EUR" },
	{ country: "LU", currency: "EUR" },
	{ country: "LV", currency: "EUR" },
	{ country: "LY", currency: "LYD" },
	{ country: "MA", currency: "MAD" },
	{ country: "MC", currency: "EUR" },
	{ country: "MD", currency: "MDL" },
	{ country: "ME", currency: "EUR" },
	{ country: "MF", currency: "EUR" },
	{ country: "MG", currency: "MGA" },
	{ country: "MH", currency: "USD" },
	{ country: "MK", currency: "MKD" },
	{ country: "ML", currency: "XOF" },
	{ country: "MM", currency: "MMK" },
	{ country: "MN", currency: "MNT" },
	{ country: "MO", currency: "MOP" },
	{ country: "MP", currency: "USD" },
	{ country: "MQ", currency: "EUR" },
	{ country: "MR", currency: "MRO" },
	{ country: "MS", currency: "XCD" },
	{ country: "MT", currency: "EUR" },
	{ country: "MU", currency: "MUR" },
	{ country: "MV", currency: "MVR" },
	{ country: "MW", currency: "MWK" },
	{ country: "MX", currency: "MXN" },
	{ country: "MY", currency: "MYR" },
	{ country: "MZ", currency: "MZN" },
	{ country: "NA", currency: "NAD" },
	{ country: "NC", currency: "XPF" },
	{ country: "NE", currency: "XOF" },
	{ country: "NF", currency: "AUD" },
	{ country: "NG", currency: "NGN" },
	{ country: "NI", currency: "NIO" },
	{ country: "NL", currency: "EUR" },
	{ country: "NO", currency: "NOK" },
	{ country: "NP", currency: "NPR" },
	{ country: "NR", currency: "AUD" },
	{ country: "NU", currency: "NZD" },
	{ country: "NZ", currency: "NZD" },
	{ country: "OM", currency: "OMR" },
	{ country: "PA", currency: "PAB" },
	{ country: "PE", currency: "PEN" },
	{ country: "PF", currency: "XPF" },
	{ country: "PG", currency: "PGK" },
	{ country: "PH", currency: "PHP" },
	{ country: "PK", currency: "PKR" },
	{ country: "PL", currency: "PLN" },
	{ country: "PM", currency: "EUR" },
	{ country: "PN", currency: "NZD" },
	{ country: "PR", currency: "USD" },
	{ country: "PS", currency: "JOD" },
	{ country: "PT", currency: "EUR" },
	{ country: "PW", currency: "USD" },
	{ country: "PY", currency: "PYG" },
	{ country: "QA", currency: "QAR" },
	{ country: "RE", currency: "EUR" },
	{ country: "RO", currency: "RON" },
	{ country: "RS", currency: "RSD" },
	{ country: "RU", currency: "RUB" },
	{ country: "RW", currency: "RWF" },
	{ country: "SA", currency: "SAR" },
	{ country: "SB", currency: "SBD" },
	{ country: "SC", currency: "SCR" },
	{ country: "SD", currency: "SDG" },
	{ country: "SE", currency: "SEK" },
	{ country: "SG", currency: "SGD" },
	{ country: "SH", currency: "SHP" },
	{ country: "SI", currency: "EUR" },
	{ country: "SJ", currency: "NOK" },
	{ country: "SK", currency: "EUR" },
	{ country: "SL", currency: "SLL" },
	{ country: "SM", currency: "EUR" },
	{ country: "SN", currency: "XOF" },
	{ country: "SO", currency: "SOS" },
	{ country: "SR", currency: "SRD" },
	{ country: "SS", currency: "SSP" },
	{ country: "ST", currency: "STD" },
	{ country: "SV", currency: "USD" },
	{ country: "SX", currency: "ANG" },
	{ country: "SY", currency: "SYP" },
	{ country: "SZ", currency: "SZL" },
	{ country: "TC", currency: "USD" },
	{ country: "TD", currency: "XAF" },
	{ country: "TF", currency: "EUR" },
	{ country: "TG", currency: "XOF" },
	{ country: "TH", currency: "THB" },
	{ country: "TJ", currency: "TJS" },
	{ country: "TK", currency: "NZD" },
	{ country: "TL", currency: "USD" },
	{ country: "TM", currency: "TMT" },
	{ country: "TN", currency: "TND" },
	{ country: "TO", currency: "TOP" },
	{ country: "TR", currency: "TRY" },
	{ country: "TT", currency: "TTD" },
	{ country: "TV", currency: "AUD" },
	{ country: "TW", currency: "TWD" },
	{ country: "TZ", currency: "TZS" },
	{ country: "UA", currency: "UAH" },
	{ country: "UG", currency: "UGX" },
	{ country: "UM", currency: "USD" },
	{ country: "US", currency: "USD" },
	{ country: "UY", currency: "UYU" },
	{ country: "UZ", currency: "UZS" },
	{ country: "VA", currency: "EUR" },
	{ country: "VC", currency: "XCD" },
	{ country: "VE", currency: "VEF" },
	{ country: "VG", currency: "USD" },
	{ country: "VI", currency: "USD" },
	{ country: "VN", currency: "VND" },
	{ country: "VU", currency: "VUV" },
	{ country: "WF", currency: "XPF" },
	{ country: "WS", currency: "EUR" },
	{ country: "YE", currency: "YER" },
	{ country: "YT", currency: "EUR" },
	{ country: "ZA", currency: "ZAR" },
	{ country: "ZM", currency: "ZMW" },
	{ country: "ZW", currency: "USD" },
];
