<template>
  
  <b-modal
      @change="closeModal()"
      :id="`upload-video-modal-${parentContentId}`"
      :visible="visible"
      size="xl"
      hide-header
      hide-footer
      centered
      :content-class="`upload-video-modal ${parentContentId}`"
      body-class="modal-body"
      modal-class="custom-modal"
      no-close-on-backdrop
      :data-id="parentContentId"
  >
    <div class="body-container">
      <b-spinner
          small
          class="close-button"
          v-if="loading && isMinimized"
      ></b-spinner>
      <button v-else @click="closeModal()" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>      
      <h1 class="add-content-title">
        {{ isEditMode ? "Edit" : "Upload" }} {{ (contentType=="RecordedVideos")?"Recorded Videos":"Study Material"}} 
      </h1>
      <div class="body-content-container">
        <div
            :class="[
						'info-container',
						{ expand: isFileAdded, link: isLinkUpload },
					]"
        >
          <div class="form-group">
            <label class="main-label" for="title">Video Title (required)</label>
            <input
                placeholder="Type your lecture name here..."
                class="form-input"
                type="search"
                name="title"
                id="title"
                v-model="title"
                required
            />
          </div>
          <div v-if="isLinkUpload" class="form-group mt-5">
            <label class="main-label" for="fileUrl"
            >Video Link (required)</label
            >
            <input
                placeholder="Enter Video Link to upload ..."
                class="form-input"
                type="search"
                name="fileUrl"
                id="fileUrl"
                v-model="fileUrl"
                required
                @change="validateURL"
            />
          </div>
          <div class="form-group">
            <div class="flex-row mt-5">
              <div class="col-lg-6 ps-0">
                <div class="form-group">
                  <label for="start-date" class="main-label m-0"
                  >start date (optional)</label
                  >
                  <input
                      placeholder="Select start date"
                      class="form-input"
                      type="date"
                      name="start-date"
                      id="start-date"
                      v-model="startDate"
                  />
                </div>
              </div>
              <div class="col-lg-6 pe-0">
                <div class="form-group">
                  <label for="end-date" class="main-label m-0"
                  >end date (optional)</label
                  >
                  <input
                      placeholder="Select end date"
                      class="form-input"
                      type="date"
                      name="end-date"
                      id="end-date"
                      v-model="endDate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mt-4">
            <div class="form-check form-switch">
              <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isAvailableForDemo"
                  v-model="isAvailableForDemo"
              />
              <label class="form-check-label" for="isAvailableForDemo"
              >Available for Demo</label
              >
            </div>
          </div>
        </div>
        <div
            :class="[
						'upload-container',
						{ shrink: isFileAdded, 'd-none': isLinkUpload },
					]"
        >
          <keep-alive>
            <vue-dropzone
                v-if="!isEditMode && this.showModal"
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                v-on:vdropzone-file-added="uploadVideo"
                v-on:vdropzone-removed-file="removeVideo"
                v-on:vdropzone-sending="sendingUploadRequest"
                v-on:vdropzone-total-upload-progress="updateVideoProgress"
                v-on:vdropzone-success="completeUpload"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drag and drop to upload content!
                </h3>
                <div class="subtitle">
                  ...or click to select a file from your computer
                </div>
                <button role="button" class="upload-btn mt-4">
                  <i class="fas fa-file-import"></i>
                  Select File
                </button>
                <button
                    @click="uploadWithLink"
                    role="button"
                    class="upload-btn ms-3 mt-4"
                >
                  <i class="fas fa-link"></i>
                  Upload Using Link
                </button>
              </div>
            </vue-dropzone>
            <b-overlay class="preview" v-else :show="previewLoading">
              <img
                  @click="showVideo"
                  v-b-tooltip.hover.bottom="'Preview Video'"
                  src="@/assets/images/recorded-lecture.png"
              />
            </b-overlay>
          </keep-alive>
        </div>
      </div>
      <p v-if="!allOk" class="text-danger">{{ dateErrorText }}</p>
      <p class="text-danger">{{ errorText }}</p>
      <div v-if="isFileAdded" class="step-btn-container">
        <button
            @click="upload"
            role="button"
            class="step-btn"
            :disabled="disableNext() || loading"
        >
          <b-spinner small v-if="loading"></b-spinner>
          {{ isEditMode ? "Update" : "Upload" }}
          <img src="@/assets/images/arrow-right.svg" />
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { mapActions, mapMutations, mapState } from "vuex"; // import CryptoJS from "crypto-js";
import RecordedVideo from "../../../models/content/recordedVideo";
import { contentService } from "../../../services/content.service";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";

export default {
  name: "UploadRecordedLectureModal",
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapState("content", ["loading"]),
    visible() {
      return this.showModal;
    },
  },
  props: {
    showModal: Boolean,
    parentContentId: String,
    serviceData: Object,
    service: Object,
    contentType: String,
    isEditMode: Boolean,
    contentData: RecordedVideo,
    productVariantId: String,
  },
  data: function () {
    return {
      dropzoneOptions: {
        url: " ",
        thumbnailWidth: 300,
        timeout: 100000000,
        uploadMultiple: true,
        autoProcessQueue: false,
        acceptedFiles: (this.contentType=="RecordedVideos") ? "video/*": ".pdf",
      },
      fileName: null,
      isFileAdded: false,
      type: "RecordedVideo",
      entity: "Content",
      title: null,
      startDate: null,
      endDate: null,
      isAvailableForDemo: false,
      filehash: "368B3C12BF01D191B690764EF94D962A",
      videoIsValid: true,
      isError: false,
      errorText: "",
      fileUrl: null,
      isLinkUpload: false,
      isMinimized: false,
      id: null,
      fileProgress: 0,
      videoDetailsList: [],
      allOk: true,
      dateErrorText: "",
      fileUploadSuccess: false,
      previewLoading: false,
      uploadURL: null,
      notifyURL: null,
    };
  },
  methods: {
    ...mapActions("content", [
      "createRecordedVideosContent",
      "updateContent",
      "getContentById",
    ]),    
    ...mapActions("bulkFileUploading", ["toggleBulkFileUploadingModel","addFileInUploadQueue"]),
    ...mapMutations("content", ["setUploading"]),
    uploadVideo(file) {

      // const reader = new FileReader()
      // reader.readAsText(file)
      // reader.onload = async () => {
      //   debugger;
      //   const fileContent = reader.result
      //   console.log(fileContent)
      // };
      this.closeModal();
      this.toggleBulkFileUploadingModel(true);
      this.addFileInUploadQueue({file:file, parentContentID:this.parentContentId, service:this.service});
      
    //   this.isFileAdded = true;
    //   // console.log("file uploaded", file);
    //   $(".dropzone .dz-preview:last-child").attr("id", "preview-" + this.id);

    //   this.fileName = file.name;
    //   var parsedFilename = this.fileName.split(".")[0];

    //   this.title = parsedFilename;
    //   // this.isAvailableForDemo = true;
    //   document.getElementById("title").focus();

    //   var recordedVideoObject = {
    //     title: this.title,
    //     data: {
    //       servicecode: "VSS",
    //       filehash: this.filehash,
    //       filename: this.fileName,
    //     },
    //     isAvailableForDemo: this.isAvailableForDemo,
    //     parentDirectoryID: this.parentContentId,
    //     type: "RecordedVideos",
    //   };

    //   var videoDetails = {
    //     title: this.fileName,
    //     startDate: this.startDate,
    //     endDate: this.endDate,
    //     isAvailableForDemo: this.isAvailableForDemo,
    //   };

    //   this.videoDetailsList.push(videoDetails);

    //   if (this.videoIsValid)
    //     this.createRecordedVideosContent(recordedVideoObject).then((video) => {
    //       // console.log("recorded video uploaded", video);
    //       this.id = video.id;
    //       if (video.data.uploadingUrl) {
    //         const { uploadURL, notifyURL } = this.processUploadingURL(
    //             video.data.uploadingUrl
    //         );
    //         this.uploadURL = uploadURL;
    //         this.notifyURL = notifyURL;
    //         this.$refs.myVueDropzone.setOption("url", uploadURL);

    //         // console.log(this.$refs);
    //         this.$refs.myVueDropzone.dropzone.processQueue();
    //         this.setUploading(true);
    //       } else {
    //         this.errorText = "Something went wrong, Upload URL not found!";
    //       }
    //     });
    //   else {
    //     this.errorText = "Please upload a valid video file!";
    //   }
    },
    removeVideo(file, error, xhr) {
      this.fileName = null;
      this.isFileAdded = false;
    },
    disableNext() {
      if (this.isEditMode) return false;
      if (!this.isLinkUpload && !this.fileUploadSuccess) {
        return true;
      }
      if (this.isLinkUpload) {
        if (this.title && this.fileUrl && this.allOk) {
          return false;
        } else return true;
      } else if (this.title) return false;
      return true;
    },
    upload() {
      if (this.startDate && this.endDate) {
        this.allOk = new Date(this.startDate) <= new Date(this.endDate);
        // console.log(this.allOk);
        if (!this.allOk) {
          this.dateErrorText = "Start Date cannot be after End Date";
        } else {
          this.allOk = true;
          this.dateErrorText = "";
        }
      }

      if (this.allOk) {
        if (this.isLinkUpload) {
          var recordedVideoObject = {
            title: this.title,
            data: {
              servicecode: "VSS",
              source: this.fileUrl,
              filename: this.title,
            },
            isAvailableForDemo: this.isAvailableForDemo,
            parentDirectoryID: this.parentContentId,
            type: "RecordedVideos",
          };
          this.createRecordedVideosContent(recordedVideoObject).then(
              (video) => {
                // console.log("recorded video uploaded");
                this.$toasted.success("Recorded Video uploaded successfully", {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                });
                this.closeModal();
              }
          );
          return;
        } else {
          var videoObj = {
            id: this.id,
            parentDirectoryID: this.parentContentId,
            title: this.title,
            availableFrom: this.startDate
                ? new Date(this.startDate).toISOString()
                : null,
            availableTo: this.endDate
                ? new Date(this.endDate).toISOString()
                : null,
            isAvailableForDemo: this.isAvailableForDemo,
          };
          this.updateContent(videoObj).then(() => this.closeModal());
        }
      }
    },
    validateURL(e) {
      console.log("video check", e);
      if (e.target.value == "") {
        this.isError = false;
        this.videoIsValid = true;
        this.errorText = "";
        this.allOk = true;
        return;
      }
      this.checkVideoLink(e.target.value).then((res) => {
        if (!res) {
          this.error();
          this.errorText =
              "The entered video link is not a valid video URL. Please enter a valid video URL.";
          return;
        }

        this.isError = false;
        this.videoIsValid = true;
        this.errorText = "";
        this.allOk = true;
      });
    },
    checkVideoLink(link) {
      return new Promise((resolve) => {
        var v = document.createElement("VIDEO");
        try {
          v.onloadedmetadata = () => {
            resolve(v.videoWidth > 0 && v.videoHeight > 0);
          };
          v.onerror = () => {
            resolve(false);
          };
        } catch (error) {
          resolve(false);
        }
        var isURL = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        if (!!isURL.test(link)) {
          v.src = link;
        } else {
          resolve(false);
        }
      });
    },
    error() {
      this.videoIsValid = false;
      this.isError = true;
      this.allOk = false;
    },
    uploadWithLink(e) {
      e.stopPropagation();
      // console.log("upload with link");
      this.isFileAdded = true;
      this.isLinkUpload = true;
      this.fileType = "link";
    },
    closeModal() {
      var confirmed = false;
      if (
          !this.fileUploadSuccess &&
          this.isFileAdded &&
          !this.isEditMode &&
          !this.isLinkUpload
      ) {
        confirmed = window.confirm(
            "Are you sure? You will lose all upload progress if you close this window now"
        );
      } else {
        confirmed = true;
      }
      if (confirmed) {
        $(`.upload-video-modal.${this.parentContentId}`).removeClass("min");
        $(".minmaxCon").remove(
            $(`.upload-video-modal.${this.parentContentId}`)
        );
        Object.assign(this.$data, this.$options.data.call(this));
        this.setUploading(false);
        this.$emit("updateModalState", false);
      }
    },
    hideModal() {
      this.$emit("updateModalState", false);
    },
    updateVideoProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      this.$emit("updateProgress", totaluploadprogress, this.id);
      this.fileProgress = totaluploadprogress;
    },
    uploadInBackground() {
      $(`.modal-backdrop`).addClass("display-none");
      $(`.upload-video-modal.${this.parentContentId}`).toggleClass("min");

      if ($(`.upload-video-modal.${this.parentContentId}`).hasClass("min")) {
        this.isMinimized = true;
        $(".minmaxCon").append(
            $(`.upload-video-modal.${this.parentContentId}`)
        );
        $(`#upload-video-modal-${this.parentContentId}`).remove(
            $(`.upload-video-modal.${this.parentContentId}`)
        );
        $(`#upload-video-modal-${this.parentContentId}`).addClass(
            "display-none"
        );
        $(
            `.upload-video-modal.${this.parentContentId} .body-content-container`
        ).addClass("display-none");
        $(`[data-id=${this.parentContentId}]`).css("z-index", 1050);
      } else {
        this.isMinimized = false;
        $(`#upload-video-modal-${this.parentContentId}`).removeClass(
            "display-none"
        );
        $(`#upload-video-modal-${this.parentContentId} .modal-dialog`).append(
            $(`.upload-video-modal.${this.parentContentId}`)
        );
        $(".minmaxCon").remove(
            $(`.upload-video-modal.${this.parentContentId}`)
        );
        $(
            `.upload-video-modal.${this.parentContentId} .body-content-container`
        ).removeClass("display-none");
        $(`[data-id=${this.parentContentId}]`).css("z-index", 9999);
      }
    },
    completeUpload(file, response) {
      if (this.notifyURL) {
        const uploadCompleteNotificationURL = this.notifyURL;
        console.log({event: 'completeUpload', notifyUrl: this.notifyURL, file, response});
        const filesizeInMB = file.size / 1024.0 / 1024.0;
        console.log({filesizeInMB});
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function() {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          console.log({uploadCompleteNotificationURL, duration});

          if(filesizeInMB && duration) {
            fetch(`${uploadCompleteNotificationURL}&FileSize=${filesizeInMB}&Duration=${duration}`);
          } else {
            fetch(uploadCompleteNotificationURL);
          }

        }

        video.src = URL.createObjectURL(file);

      }
      this.fileUploadSuccess = true;
      this.$toasted.success("Recorded Video File uploaded successfully", {
        theme: "outline",
        position: "bottom-center",
        duration: 3000,
      });
      this.setUploading(false);
      if (this.isMinimized) this.upload();
    },
    showVideo() {
      var contentLink =
          "/content/video/" +
          this.contentData.title
              .toLowerCase()
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .split(" ")
              .join("-") +
          "-" +
          this.contentData.id +
          "_" +
          this.productVariantId;
      // console.log("showVideo");
      this.previewLoading = true;
      this.getContentById({
        id: this.contentData.id,
        extraParams: { Platform: contentService.getCurrentPlatform() },
      }).then(() => {
        this.previewLoading = false;
        window.open(contentLink, "_blank");
      });
    },
    processUploadingURL(url) {
      var queryString = url.split("?")[1];
      var uploadURL = url;
      var notifyURL = null;
      var params = new URLSearchParams(queryString);
      for (const param of params) {
        if (param[0] == "notifyUploadCompletionAt") {
          notifyURL = param[1];
          uploadURL = url.slice(0, url.indexOf(param[0]) - 1);
          console.log(uploadURL, notifyURL);
        }
      }
      // console.log({ uploadURL, notifyURL });
      return { uploadURL, notifyURL };
    },
    sendingUploadRequest(file, xhr, formData) {
      console.log({event: 'sendingUploadRequest', xhr, formData});
      this.changeRequestHeadersForAWSPresignedUrlUpload(file, xhr, formData);
    },
    changeRequestHeadersForAWSPresignedUrlUpload(file, xhr, formData) {
      const isAWSFileUpload = this.notifyURL && this.notifyURL != null;

      console.log({isAWSFileUpload});

      if(isAWSFileUpload) {
        const _send = xhr.send;
        xhr.send = function() {
          xhr.setRequestHeader('Content-Type', "application/octet-stream");
          _send.call(xhr, file);
        }
      }
    }
  },
  watch: {
    visible: function () {   
      console.log(this.serviceData,"service");   
      if (this.visible) {
        if (this.isEditMode) {
          this.id = this.contentData.id;
          this.isFileAdded = true;
          this.title = this.contentData.title;
          this.startDate = this.contentData.availableFrom
              ? getInputDateFormat(this.contentData.availableFrom)
              : null;
          this.endDate = this.contentData.availableTo
              ? getInputDateFormat(this.contentData.availableTo)
              : null;
          this.isAvailableForDemo = this.contentData.isContentAvailableForDemo;
          return;
        }
        if (this.serviceData && this.serviceData.fileUploadLimit) {
          setTimeout(() => {
            this.$refs.myVueDropzone.setOption(
                "maxFilesize",
                this.serviceData.fileUploadLimit
            );
            // console.log("ref", this.$refs.myVueDropzone);
          }, 300);
        }
      }
    },
    // fileUrl: function () {
    // 	this.validateVideoLink();
    // },
  },
  // mounted() {
  // },
};
</script>

<style>
.upload-video-modal {
  height: 45.375rem;
  width: 70vw;
}
/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */

.upload-video-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
}

.upload-video-modal .modal-body .body-container {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.body-content-container {
  display: flex;
  flex-direction: row;
  /* column-gap: 1rem; */
  align-items: flex-start;
  margin-top: 1rem;
  height: 100%;
}

.body-content-container .info-container {
  width: 0;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.body-content-container .info-container.expand {
  width: 70% !important;
  opacity: 1 !important;
  padding-right: 4rem;
}
.body-content-container .info-container.expand.link {
  width: 100% !important;
  padding: 0;
}
.body-content-container .upload-container {
  width: 100%;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.body-content-container .upload-container.shrink {
  width: 30% !important;
  height: 50% !important;
}

.body-content-container .upload-container.shrink.multiple {
  width: 30% !important;
  height: 90% !important;
}

.body-content-container .upload-container #dropzone {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: unset;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 34.375rem;
}
.body-content-container .upload-container .preview {
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: unset;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 34.375rem;
  background: #eeeeee;
  border: 1px solid #ddd;
}

.body-content-container .upload-container .preview img {
  transition: all 0.3s ease;
}

.body-content-container .upload-container .preview img:hover {
  box-shadow: 0 0 3px 6px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
}

.body-content-container .upload-container.shrink #dropzone {
  justify-content: flex-start;
}

.dropzone .dz-preview {
  min-height: unset !important;
}

.info-container .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.info-container .form-group {
  display: flex;
  flex-direction: column;
}
.info-container .form-input {
  border: 0;
  border-bottom: 1.5px solid #b2b2b2;
  font-size: 1rem;
  outline: none;
  padding: 0.875rem 0;
  /* max-width: 21.625rem; */
  width: 100%;
}

.form-input:focus {
  border-color: var(--primary-color) !important;
}

.vue-dropzone > .dz-preview {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.vue-dropzone > .dz-preview .dz-details {
  /* color: #fff !important; */
  background-color: #dddddd !important;
  transition: all 0.6s linear;
  border-radius: 8px;
}

.vue-dropzone > .dz-preview.dz-success .dz-details {
  background-color: #21cf47 !important;
}

.vue-dropzone > .dz-preview.dz-error .dz-details {
  background-color: var(--color-danger) !important;
}

.vue-dropzone > .dz-preview .dz-remove {
  border-radius: 8px;
  /* background-color: #dcdcdc !important;
  color: #000000 !important;
  margin: 0 !important;
  transform: translateX(-50%) !important;
  left: 50%; */
  /* width: 100%; */
}

.vue-dropzone > .dz-preview .dz-filename,
.vue-dropzone > .dz-preview .dz-size {
  color: #000000;
}

.vue-dropzone > .dz-preview.dz-success .dz-filename,
.vue-dropzone > .dz-preview.dz-error .dz-filename,
.vue-dropzone > .dz-preview.dz-success .dz-size,
.vue-dropzone > .dz-preview.dz-error .dz-size {
  color: #ffffff;
}

.minimize-button {
  position: absolute;
  color: #000000;
  right: 5rem;
  font-size: 1.5rem;
  line-height: 1;
  margin-top: -0.25rem;
}

.min .minimize-button {
  right: 3rem !important;
  font-size: 1.25rem !important;
  top: 1.125rem;
}

.video-title {
  position: absolute;
  color: #000000;
  font-size: 1rem;
  left: 1rem;
  display: none;
  top: 0.675rem;
}

.min .video-title {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
}

.min {
  width: 22rem !important;
  height: 3rem !important;
  overflow: hidden !important;
  padding: 0px !important;
  margin: 0px;

  float: left;
  position: static !important;
}

.min .modal-dialog,
.min .modal-content {
  height: 100%;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

.display-none {
  display: none !important;
}

.minmaxCon {
  height: 3rem;
  bottom: 0.5rem;
  right: 0.5rem;
  position: fixed;
  /* right: 1px; */
  z-index: 9999;
  display: flex;
  column-gap: 0.5rem;
}

.min .close-button {
  position: absolute;
  top: 0.675rem;
  right: 1rem;
}

.min .close-button img {
  width: 1rem;
}
</style>
