<template>
	<div>
		<ul
			v-if="
				tabData.children && tabData.children.length && viewMode != 'sidebar'
			"
			class="info inline-list hide-tab-mobile"
		>
			<li class="info-item">
				<i class="fas fa-align-left list-item-icon"></i>
				{{ tabData.children.length }} chapters
			</li>
			<!-- <li class="info-item">
				<i class="fas fa-history list-item-icon"></i>
				{{ tabData.children.length }} lectures
			</li> -->
		</ul>
		<div
			v-if="viewMode == 'sidebar' && tabData"
			class="accordion sidebar-content-accordian"
			role="tablist"
		>
			<ProductContentAccordian
				v-for="section in tabData.children"
				:key="section.id"
				:section="section"
				:accordianActive="accordianActive"
				@accordianClick="accordianClick"
				:isFirstAccordian="section.id == tabData.children[0].id"
				:viewMode="viewMode"
				:purchased="purchased"
				:productVariantId="productVariantId"
			/>
		</div>
		<div
			v-if="viewMode != 'sidebar' && tabData"
			class="accordion product-content-accordian"
			role="tablist"
		>
			<ProductContentAccordian
				v-for="section in tabData.children"
				:key="section.id"
				:section="section"
				:accordianActive="accordianActive"
				@accordianClick="accordianClick"
				:isFirstAccordian="section.id == tabData.children[0].id"
				:purchased="purchased"
				:productVariantId="productVariantId"
				:viewMode="viewMode"
			/>
		</div>
		<div
			v-if="tabData.children == null || tabData.children.length == 0"
			class="no-data text-center p-3"
		>
			No content found
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductContentList from "./ProductContentList.vue";
import ProductContentAccordian from "./ProductContentAccordian.vue";

export default {
	name: "ProductContentAccordianList",
	props: {
		purchased: Boolean,
		viewMode: String,
		tabData: Object,
		productVariantId: String,
		// sidebarData: Object,
	},
	components: { ProductContentList, ProductContentAccordian },
	data() {
		return {
			accordianActive: "",
		};
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		accordianClick(e) {
			if (this.accordianActive == e.target.id) {
				this.accordianActive = "";
			} else {
				this.accordianActive = e.target.id;
			}
		},
		// ...mapActions("content", ["getContentById"]),
	},
	computed: {
		...mapState("content", ["content"]),
		firstAccordianId() {
			// if (this.viewMode == "sidebar") {
			// 	console.log("viewMode is sidebar");
			// 	if (this.sidebarData.productVariantItems.length > 0) {
			// 		console.log("productVariantItems are not empty");
			// 		this.accordianActive = this.sidebarData.productVariantItems[0].id;
			// 		return this.sidebarData.productVariantItems[0].id;
			// 	} else return null;
			// // } else {
			// if (this.tabData.children != null) {
			if (this.tabData.children && this.tabData.children.length > 0) {
				this.accordianActive = this.tabData.children[0].id;
				return this.tabData.children[0].id;
			} else return null;
			// } else return null;
			// }
		},
	},
	created() {
		if (this.firstAccordianId != null && !this.content[this.firstAccordianId]) {
			this.getContentById({ id: this.firstAccordianId });
			this.accordianActive = this.firstAccordianId;
		}
	},
};
</script>

<style scoped>
.list-item-icon {
	margin-right: 0.5rem;
	color: var(--color-gray);
}

.info-item {
	font-size: 0.875rem;
}

.info-item:last-child {
	padding-left: 1rem;
}

.product-content-accordian .card-header {
	background-color: var(--color-white);
	cursor: pointer;
	text-align: left;
	padding: 1.25rem 1.125rem !important;
	color: var(--text-color-default);
	/* border-bottom: 0 !important; */
	/* font-weight: 500; */
	border-radius: 0.5rem !important;
	border: 2px solid var(--color-light-gray);
	font-size: 1.125rem;
}

.product-content-accordian .card.active .card-header {
	background-color: var(--secondary-color);
	color: var(--color-white);
	border-color: var(--secondary-color) !important;
	border-radius: 0.5rem 0.5rem 0 0 !important;
}

.product-content-accordian .card {
	border: none;
	box-shadow: 0 3px 6px #d5d5d529;
}

.product-content-accordian .card.active .card-body {
	border: 2px solid var(--secondary-color);
	border-radius: 0 0 0.5rem 0.5rem;
}

.product-content-accordian .card-header .header-icon {
	display: inline-flex;
	width: 1.75rem;
	height: 1.75rem;
	background-color: var(--color-white);
	border-radius: 50%;
	margin-right: 1.25rem;
	border: 2px solid var(--primary-color);
	color: var(--secondary-color);
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	font-weight: 500;
	margin-top: 0;
}

.no-data {
	text-align: center;
	padding: 2rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}
</style>
