<template>
	<div>
		<BannerWithTabs @activeTab="changeTab" :activeTab="activeTab" />
		<!-- My Courses
		</DashbooardBanner> -->
		<div>
			<div class="dashboard-content-container container">
				<UserProducts
					:userProducts="userProducts"
					v-if="activeTab == 'courses'"
				/>
				<Forum v-if="activeTab == 'forum'" />
				<DashboardUpdatesCard />
			</div>
		</div>
	</div>
</template>

<script>
import ProductListItem from "../components/Product/ProductListItem.vue";
import BannerWithTabs from "../components/Layout/BannerWithTabs.vue";
import DashboardUpdatesCard from "../components/Dashboard/DashboardUpdatesCard.vue";
import UserProducts from "../components/Dashboard/UserProducts.vue";
import Forum from "../components/Dashboard/Forum.vue";
import { mapActions, mapState } from "vuex";
import { ProductVariantStatus } from "../constants/productVariant";

export default {
	name: "Dashboard",
	data() {
		return {
			// userProducts: [],
			productsLoaded: false,
			activeTab: "courses",
		};
	},
	components: {
		ProductListItem,
		BannerWithTabs,
		DashboardUpdatesCard,
		UserProducts,
		Forum,
	},
	methods: {
		changeTab(activeTab) {
			this.activeTab = activeTab;
		},
		...mapActions("user", ["fetchCurrentUser"]),
		...mapActions("pricing", ["fetchInvoiceByUserId"]),
	},
	computed: {
		...mapState("productVariant", ["productVariants"]),
		...mapState("user", ["user"]),
		...mapState("pricing", ["invoicesByUserId"]),
		userProducts() {
			var myProducts =
				this.user && this.user.productVariants
					? this.user.productVariants.filter(
							(productVariant) =>
								productVariant.status != ProductVariantStatus.DRAFT
							// &&
							// ? productVariant.endDate
							// 	? new Date() >= productVariant.endDate
							// 	: true
							// : false
							// productVariant.subscriptionStatus &&
							// productVariant.subscriptionStatus.toLowerCase() != "unenrolled"
					  )
					: [];

			return myProducts;
		},
	},
	mounted() {
		this.$nextTick(() => {
			window.postMessage({ event: "dashboard-loaded", data: null });
		});
	},
	created() {
		this.fetchCurrentUser().then(() => {
			// console.log("userdata");
			this.fetchInvoiceByUserId(this.user.id).then((res) => {
				console.log("invoices: ", res);
				var unenrolledCourses = this.user.productVariants.filter(
					(variant) =>
						variant.subscriptionStatus &&
						variant.subscriptionStatus.toLowerCase() == "unenrolled"
				);
				var userInvoices = this.invoicesByUserId[this.user.id];

				unenrolledCourses.forEach((course) => {
					var found = userInvoices.find((invoice) =>
						invoice.details.find(
							(detail) => detail.productSourceID == course.id
						)
					);
					// console.log(found);
					if (found) {
						console.log("Invoice found for unenrolled course", found);
					}
				});

				// console.log({
				// 	unenrolledCourses,
				// 	invoicesByUserId: this.invoicesByUserId[this.user.id],
				// });
			});

			checkCourses();
			this.$nextTick(() => {
				// const event = new CustomEvent("courses-fetched");
				// document.dispatchEvent(event);
				window.postMessage({ event: "courses-fetched", data: null });
			});
		});
	},
};

function checkCourses() {
	if (typeof getUserProductsCallback !== "undefined") {
		getUserProductsCallback();
	}
}
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.dashboard-content-container {
		flex-direction: column-reverse !important;
	}

	.announcement-card {
		margin-top: -1rem !important;
	}

	.dashboard-announcement-container {
		padding: 0 !important;
	}
}

.dashboard-content-container {
	display: flex;
	flex-direction: row;
	/* row-gap: 1rem; */
	padding: 0 1rem;
}
</style>
