<template>
	<div class="top-card-container">
		<div class="top-card">
			<h1 class="top-card-heading">What you'll learn</h1>
			<div class="top-card-list">
				<div
					v-for="(item, index) in listData"
					:key="index"
					class="top-card-list-item"
				>
					<i class="fas fa-check"></i> {{ item }}
				</div>
			</div>
			<a @click="showModal" role="button" class="view-all-btn mobile-only"
				>View All <i class="far fa-eye"></i
			></a>
		</div>
			<ProductDetailsModal
				v-if="modalVisible"
				@hideModal="hideModal"
				:listData="listData"
				:class="{ show: modalVisible, hide: !modalVisible }"
			/>
	</div>
</template>

<script>
import ProductDetailsModal from "./ProductDetailsModal.vue";
export default {
	name: "ProductDetailsTopCard",
	props: { listData: Array },
	data() {
		return {
			modalVisible: false,
		};
	},
	methods: {
		showModal() {
			this.modalVisible = true;
		},
		hideModal() {
			console.log(this.modalVisible);
			// setTimeout(() => (this.modalVisible = false), 1000);
			this.modalVisible = false;
		},
	},
	components: { ProductDetailsModal },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.top-card-container {
		padding-right: 0 !important;
	}
	.top-card-list > * {
		/* flex-grow: 1; */
		width: 100% !important;
		line-height: 1.5;
	}
	.top-card {
		overflow: hidden !important;
	}

	.top-card-list-item {
		border-top: none !important;
	}
	.top-card::before {
		width: 100%;
		height: 70%;
		background: linear-gradient(#ffffff1f, #ffffff);
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		z-index: 2;
		border-radius: 0 0 0.5rem 0.5rem;
	}
}

/* @keyframes slidein {
	from {
		
	}
	to {
		transform: translateY(0);
	}
} */

.top-card-container {
	padding-right: 2.75em;
}

.top-card {
	background-color: #ffffff;
	border: 1px solid #a3a3a3;
	border-radius: 8px;
	padding: 2.75rem 1.5rem 1.5rem;
	margin-top: -1.5rem;
	box-shadow: 0 0 16px #c9c9c929;
	position: relative;
}

.top-card-heading {
	font-size: 1.875rem;
	color: #000000;
	font-weight: bold;
	margin-bottom: 0.75rem;
	line-height: 1;
}

.top-card-heading::before {
	content: "";
	width: 2rem;
	height: 0.35rem;
	background-color: var(--primary-color);
	display: block;
	top: -1rem;
	left: 0;
	position: relative;
}

.top-card-list {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 17.5rem;
	column-gap: 1.25rem;
	align-content: flex-start;
	position: relative;
}
.top-card-list > * {
	/* flex-grow: 1; */
	width: 49%;
	line-height: 1.5;
}

.top-card-list::before {
	content: "";
	width: 49%;
	height: 1px;
	top: 0;
	right: 0;
	position: absolute;
	background-color: var(--color-white);
}

.top-card-list-item {
	padding: 0.625rem;
	display: flex;
	border-top: 1px solid #e5e5e5;
}

.top-card-list-item:first-of-type {
	border-top: 1px solid #fff;
}

.top-card-list-item i {
	margin-right: 1rem;
	margin-top: 0.325rem;
	font-size: 0.75rem;
}

.view-all-btn {
	position: absolute;
	bottom: 1.5rem;
	right: 1.5rem;
	z-index: 3;
	text-decoration: none;
	color: #000000;
	font-weight: 500;
}
</style>
