import store from "../store/index";
import {
    GET_POST_ASSIGNMENT_SUBMISSION
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";
import contentModule from "../store/content.module";

export const assignmentService = {
    submitAssignment, getSubmittedAssignment, getSubmittedAssignmentResults, submitAssignmentResult, submitAssignmentFeedback, updateAssignmentSubmission, getProductvariantAssignmentResults
};


async function getSubmittedAssignment(contentID) {
    try {
      let body = await ApiHelpers.makeAPICall(
        store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/'+contentID+'/submission',
        "GET",
        null,
        false
      );
      return body?.json();
    } finally {
    }
  }
async function submitAssignment(contentID, assignmentSubmissionFiles) {
  try {
    let requestBody = JSON.stringify(assignmentSubmissionFiles);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/'+contentID+'/submission',
      "POST",
      requestBody,
      false
    );
    return body?.json();
  } finally {
  }
}

async function updateAssignmentSubmission(contentID, submissionId, assignmentSubmissionFiles) {
  try {
    let requestBody = JSON.stringify(assignmentSubmissionFiles);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/'+contentID+'/submission/'+submissionId,
      "POST",
      requestBody,
      false
    );
    return body?.json();
  } finally {
  }
}
async function getSubmittedAssignmentResults(contentID) {
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/'+contentID+'/result',
      "GET",
      null,
      false
    );
    return body?.json();
  } finally {
  }
}
async function submitAssignmentResult(assignmentResult) {
  try {
    debugger;
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/'+assignmentResult.contentId+'/result',
      "POST",
      JSON.stringify(assignmentResult),
      false
    );
    return body?.json();
  } finally {
  }
}
async function submitAssignmentFeedback(assignmentFeedback) {
  try {
    let requestBody = JSON.stringify(assignmentFeedback);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/feedback/'+assignmentFeedback.submissionId,
      "POST",
      requestBody,
      false
    );
    return body?.json();
  }catch(e){
    console.log("Error: ",e);
  } 
  
  finally {
  }
}
async function getProductvariantAssignmentResults(productvariantID) {
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + GET_POST_ASSIGNMENT_SUBMISSION+'/productvariant/'+productvariantID+'/result',
      "GET",
      null,
      false
    );
    return body?.json();
  } finally {
  }
}