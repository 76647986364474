<template>
	<div class="user-modal-tab-body">
		<div class="inline-group">
			<div class="col">
				<div class="inline-group">
					<div class="col">
						<label for="firstName" class="main-label">First Name</label>
						<input
							type="search"
							class="form-input"
							id="firstName"
							name="firstName"
							v-model="firstName"
							placeholder="Enter First Name here..."
							:readonly="!isEditMode"
							@change="isEditMode && update($event)"
						/>
					</div>
					<div class="col">
						<label for="lastName" class="main-label">Last Name</label>
						<input
							type="search"
							class="form-input"
							id="lastName"
							name="lastName"
							v-model="lastName"
							placeholder="Enter Last Name here..."
							:readonly="!isEditMode"
							@change="isEditMode && update($event)"
						/>
					</div>
				</div>
				<div class="inline-group">
					<div class="col">
						<label for="email" class="main-label">Email</label>
						<input
							type="email"
							class="form-input"
							id="email"
							name="email"
							v-model="email"
							placeholder="Enter Email here..."
							:readonly="!isEditMode"
							@change="isEditMode && update($event)"
							disabled
						/>
					</div>
					<div v-if="user.type.toLowerCase() == 'management'" class="col">
						<label for="role" class="main-label">Role</label>
						<v-select
							class="form-input"
							id="role"
							name="role"
							v-model="managementRole"
							placeholder="Select Role..."
							:options="roleOptions"
							:readonly="!isEditMode"
							@change="isEditMode && update($event)"
							:appendToBody="true"
							:disabled="!isEditMode"
							:calculate-position="withPopper"
						/>
					</div>
				</div>
			</div>
			<div class="col-3 ps-5">
				<label class="main-label">Profile Picture</label>
				<div
					:class="[
						'upload-btn-wrapper',
						{ preview: profilePicturePath, disabled: !isEditMode },
					]"
				>
					<div v-if="isProfilePictureUploading">
						<b-spinner small> </b-spinner>
					</div>
					<img
						v-else
						:class="[{ 'd-none': !profilePicturePath, disabled: !isEditMode }]"
						id="preview"
						:src="profilePicturePath"
						alt="preview"
					/>
					<div v-if="profilePicturePath && !isProfilePictureUploading" class="right-menu">
						<a role="button" class="right-menu-icon" @click="editFile">
							<img src="@/assets/images/edit-icon.svg" />
						</a>
						<a @click="removeFile" role="button" class="right-menu-icon delete">
							<img src="@/assets/images/delete-icon.svg" />
						</a>
					</div>
					<button
						@click="editFile"
						v-if="!profilePicturePath"
						type="button"
						class="upload-btn"
					>
						upload
					</button>
					<input
						class="form-input"
						type="file"
						accept="image/*,video/*"
						name="profilePicturePath"
						id="profilePicturePath"
						@change="getFile"
						:disabled="!isEditMode"
					/>
				</div>
			</div>
		</div>
		<div class="inline-group">
			<div class="col">
				<label for="username" class="main-label">Username</label>
				<input
					type="search"
					class="form-input"
					id="username"
					name="username"
					v-model="username"
					placeholder="Enter Username here..."
					:readonly="!isEditMode"
					@change="isEditMode && update($event)"
				/>
			</div>
			<div class="col">
				<label for="phone" class="main-label">Phone Number</label>
				<vue-tel-input
					:class="`form-input ${isPhoneError && showError ? 'error' : ''}`"
					:inputOptions="telInputOptions"
					validCharactersOnly
					v-model="mobile"
					:defaultCountry="user.countryCode ? user.countryCode : ''"
					@input="validate"
					@country-changed="changeCountry"
					@blur="showError = true"
					@focus="showError = false"
					:autoFormat="true"
					mode="national"
					invalidMsg="Please enter a valid phone number"
					id="mobile"
					:disabled="!isEditMode"
					ref="phoneInput"
				></vue-tel-input>
			</div>
		</div>
		<div class="inline-group">
			<div class="col">
				<label for="address" class="main-label">Address</label>
				 <textarea
				type="search"
					class="form-input" 
					placeholder="Enter Address here..."
					v-model="address"
					id="address"
					name="address"
					:readonly="!isEditMode"
					@change="isEditMode && update($event)"
				></textarea>
			</div>
			<div class="col">
				<label for="city" class="main-label">City</label>
				<input
					type="search"
					class="form-input"
					id="city"
					name="city"
					v-model="city"
					placeholder="Enter city here..."
					:readonly="!isEditMode"
					@change="isEditMode && update($event)"
				/>
			</div>
			<div class="col">
				<label for="country" class="main-label">Country</label>
				<v-select
					type="password"
					class="form-input"
					id="country"
					name="country"
					v-model="country"
					placeholder="Select Country..."
					:options="countryCodes"
					label="name"
					:reduce="(option) => option['alpha-3']"
					:disabled="!isEditMode"
					@input="isEditMode && update($event, 'country')"
					:appendToBody="true"
					:calculate-position="withPopper"
				></v-select>
			</div>
		</div>
		<div class="inline-group">
			<div v-for="(input, index) in CustomFields" :key="index" class="col">
				<DynamicFormInput
					:inputFieldOptions="input"
					:hasIcon="false"
					:customInputClass="'form-input'"
					:showLabel="true"
					:customLabelClass="'main-label'"
					@inputValue="
						(value) => {
							extraProperties.CustomFields[input.Key] = value;
						}
					"
					:inputSetValue="
						extraProperties.CustomFields &&
						extraProperties.CustomFields[input.Key]
					"
					:readonly="!isEditMode"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { countryCodes } from "../../../constants/countryCodes";
import { mapActions, mapState } from "vuex";
import { createPopper } from "@popperjs/core";
import DynamicFormInput from "../../Layout/DynamicFormInput.vue";
const getCountryISO3 = require("country-iso-2-to-3");
const getCountryISO2 = require("country-iso-3-to-2");

export default {
	name: "UserDetails",
	props: { user: Object, mode: Number },
	components: { DynamicFormInput },
	data() {
		return {
			firstName: null,
			lastName: null,
			email: null,
			username: null,
			phone: null,
			mobile: null,
			city: null,
			countryCodes,
			country: null,
			managementRole: null,
			roleOptions: [{ id: "1", value: "admin", label: "Admin" }],
			profilePicturePath: null,
			profilePictureType: "image",
			errorText: "",
			address: null,
			extraProperties: { CustomFields: {} },
			// mode: 0,
			updateObj: {},
			telInputOptions: {
				autocomplete: "on",
				maxlength: 15,
				tabindex: 10,
				placeholder: "Phone Number (required)",
				mode: "national",
			},
			showPassword: false,
			isPhoneError: false,
			showError: false,
			CustomFields: null,
			isProfilePictureUploading: false
		};
	},
	methods: {
		...mapActions("content", ["uploadContentFile"]),
		getFile(e) {
			const [file] = e.target.files;
			if (file) {
				this.isProfilePictureUploading = true;
				this.$emit("disableSaveBtn", this.isProfilePictureUploading);
				this.profilePicturePath = URL.createObjectURL(file);
				document.getElementById("preview").title = file.name;
				this.profilePictureType = file.type.split("/")[0];
				let formData = new FormData();
				formData.append("file", file);
				formData.append("entity", "ProductVariant");
				formData.append("type", "Image");
				formData.append("IsCompressionRequired", true);

				console.log(...formData);
				this.uploadContentFile(formData).then((data) => {
					console.log(data);
					this.profilePicturePath = data;
					$(".upload-btn-wrapper").addClass("success");
					this.isProfilePictureUploading = false;
					this.$emit("disableSaveBtn", this.isProfilePictureUploading);
					if (this.isEditMode) {
						this.updateObj.profilePicture = this.profilePicturePath;
					}
				});
			}
			console.log("file: ", e.target.files);
		},
		update(e, value) {
			console.log(e, value);
			if (value == "country") {
				this.updateObj["countryCode"] = e;
			} else if (value == "mobile") {
				this.updateObj["mobile"] = e;
			} else this.updateObj[e.target.name] = e.target.value;
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		editFile() {
			document.getElementById("profilePicturePath").click();
		},
		removeFile() {
			this.profilePicturePath = null;
		},
		validate(num, obj) {
			// console.log(num, obj);
			if (obj.valid != null && obj.valid === false) {
				this.isPhoneError = true;
				return;
			}
			this.isPhoneError = false;
			if (obj.number) {
				this.phone = obj.number;
				this.update(this.phone, "mobile");
			}
			return false;
		},
		changeCountry(obj) {
			console.log("changeCountry");
			this.country = getCountryISO3(obj.iso2);
			this.update(this.country, "country");
		},
	},
	computed: {
		...mapState("appState", ["systemParameters"]),
		isEditMode() {
			return this.mode == 1;
		},
	},
	created() {
		// console.log("user detail created");
		if (this.user) {
			this.firstName = this.user.firstName ? this.user.firstName : null;
			this.lastName = this.user.lastName ? this.user.lastName : null;
			this.email = this.user.email ? this.user.email : null;
			this.password = this.user.password ? this.user.password : null;
			this.confirmPassword = this.user.password ? this.user.password : null;
			this.phone = this.user.phone ? this.user.phone : null;
			this.mobile = this.user.phone ? this.user.phone : null;
			this.managementRole = this.user.managementRole
				? this.user.managementRole
				: null;
			this.country = this.user.countryCode ? this.user.countryCode : null;
			this.city = this.user.city ? this.user.city : null;
			this.profilePicturePath = this.user.profilePicture
				? this.user.profilePicture
				: require("@/assets/images/user_img_placeholder.jpg");
			this.address = this.user.address ? this.user.address : null;
			this.username = this.user.username ? this.user.username : null;
			this.extraProperties = this.user.extraProperties
				? JSON.parse(this.user.extraProperties)
				: { CustomFields: {} };
			if (this.user.type && this.user.type.toLowerCase() == "student") {
				this.CustomFields = this.systemParameters.UserRegisterExtraFields
					? this.systemParameters.UserRegisterExtraFields
					: null;
			}
			if (this.user.type && this.user.type.toLowerCase() == "trainer") {
				this.CustomFields = this.systemParameters.TrainerUserRegisterExtraFields
					? this.systemParameters.TrainerUserRegisterExtraFields
					: null;
			}
		}
	},
	watch: {
		country: function () {
			var phoneInput = this.$refs.phoneInput;
			if (phoneInput) {
				phoneInput.$data.activeCountryCode = getCountryISO2(this.country);
				if (phoneInput.phoneObject.number) {
					this.mobile = phoneInput.phoneObject.number;
					phoneInput.focus();
				}
			}
			// console.log(phoneInput);
		},
		isPhoneError: function () {
			this.$emit("error", this.isPhoneError);
		},
		showError: function () {
			if (this.showError && this.isPhoneError) {
				this.$toasted.error("Please enter a valid phone number", {
					icon: "exclamation-triangle",
					duration: 3000,
					theme: "outline",
					position: "bottom-center",
				});
			}
		},
	},
};
</script>

<style scoped>
.view-edit-user-modal .upload-btn-wrapper {
	width: 11rem;
	max-width: 11rem;
	max-height: 11rem;
	height: 10rem;
	position: relative;
	overflow: hidden;
	background: #f5f5f5;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	border-radius: 8px;
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.upload-btn-wrapper.preview {
	padding: 0;
}

.upload-btn-wrapper.preview .disabled {
	pointer-events: none;
}
.upload-btn-wrapper.preview.disabled {
	pointer-events: none;
}

#preview {
	max-width: 100%;
}

.upload-btn {
	padding: 0.5rem 1.25rem;
	font-weight: bold;
	cursor: pointer;
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 6px;
	text-transform: uppercase;
	color: #000000;
	font-weight: normal;
}

.form-input {
	color: #000000 !important;
}

.form-input.error {
	border-color: var(--color-danger) !important;
	color: var(--color-danger) !important;
}

.upload-btn-wrapper .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: absolute;
}

.upload-btn-wrapper:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

.upload-btn-wrapper .right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

.upload-btn-wrapper .right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.upload-btn-wrapper .right-menu-icon.delete {
	border-color: #ff0000;
}

#profilePicturePath {
	pointer-events: none;
}
</style>
