<template>
  <div class="page-content-container">
    <div class="title-container mb-4">
      <h1 class="page-title">System Preferences</h1>
    </div>

    <div class="preferences-card">
      <div class="heading-section preferences-card-header">
        <div>
          <h1 class="preferences-heading">
            <i class="fas fa-palette sys-icon"></i> User Interface
          </h1>
        </div>
        <div>
          <span
            @click="showUserInterfaceDivIcon()"
            v-b-toggle.collapse-user-interface
            class="icon-size-style m-1"
          >
            <i
              class="fa fa-solid fa-angle-down"
              v-if="showUserInterfaceIconDown"
            ></i>
            <i class="fa fa-solid fa-angle-up" v-else></i>
          </span>
        </div>
      </div>
      <b-collapse visible id="collapse-user-interface">
        <!-- <b-card>I should start open!</b-card> -->
        <div class="content-section content-section-ui">
          <LogoFaviconSettings />
          <BannerSettings />
          <hr class="w-50 my-4" />
          <ColorThemeSettings />
        </div>
      </b-collapse>
    </div>
    <div class="preferences-card">
      <div class="heading-section preferences-card-header">
        <div>
          <h1 class="preferences-heading">
            <i class="fas fa-edit sys-icon"></i> Company Information
          </h1>
        </div>
        <div>
          <span
            v-b-toggle.collapse-company-info-settings
            class="icon-size-style m-1"
            @click="showCompanyInfoIcon()"
          >
            <i
              class="fa fa-solid fa-angle-down"
              v-if="showcompanyInfoIconDown"
            ></i>
            <i class="fa fa-solid fa-angle-up" v-else></i>
          </span>
        </div>
      </div>
      <b-collapse visible id="collapse-company-info-settings">
        <!-- <b-card>I should start open!</b-card> -->
        <div class="content-section">
          <CompanyInfoSettings />
        </div>
      </b-collapse>
    </div>
    <div class="preferences-card">
      <div class="heading-section preferences-card-header">
        <div>
          <h1 class="preferences-heading">
            <i class="fas fa-share-alt-square sys-icon"></i> Social Links
          </h1>
        </div>
        <div>
          <span
            v-b-toggle.collapse-company-social-links
            class="icon-size-style m-1"
            @click="showSocialLinksIcon()"
          >
            <i
              class="fa fa-solid fa-angle-down"
              v-if="showSocialLinksIconDown"
            ></i>
            <i class="fa fa-solid fa-angle-up" v-else></i>
          </span>
        </div>
      </div>
      <b-collapse visible id="collapse-company-social-links">
        <!-- <b-card>I should start open!</b-card> -->
        <div class="content-section">
          <SocialLinksSettings />
        </div>
      </b-collapse>
    </div>
		
		<div class="preferences-card">
			<div class="heading-section">
				<h1 class="preferences-heading">
					<i class="fas fa-cloud sys-icon"></i> Integrations
				</h1>
			</div>
			<div class="content-section content-section-ui">
				<ZoomServiceAccountsIntegration></ZoomServiceAccountsIntegration>
			</div>
		</div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BannerSettings from "../../components/Admin/Layout/Banners/BannerSettings.vue";
import LogoFaviconSettings from "../../components/Admin/Layout/LogoFaviconSettings.vue";
import CompanyInfoSettings from "../../components/Admin/Layout/CompanyInfoSettings.vue";
import SocialLinksSettings from "../../components/Admin/Layout/SocialLinksSettings.vue";
import ColorThemeSettings from "../../components/Admin/Layout/ColorThemeSettings.vue";
import ZoomServiceAccountsIntegration from "../../components/Admin/Layout/ZoomServiceAccountsIntegration.vue";

export default {
  name: "SystemPreferences",
  data() {
    return {
      showUserInterfaceIconDown: true,
      showcompanyInfoIconDown: true,
      showSocialLinksIconDown: true,
    };
  },
  methods: {
    showSocialLinksIcon() {
      if (this.showSocialLinksIconDown) {
        this.showSocialLinksIconDown = false;
        return;
      }
      this.showSocialLinksIconDown = true;
    },

    showCompanyInfoIcon() {
      if (this.showcompanyInfoIconDown) {
        this.showcompanyInfoIconDown = false;
        return;
      }
      this.showcompanyInfoIconDown = true;
    },

    showUserInterfaceDivIcon() {
      if (this.showUserInterfaceIconDown) {
        this.showUserInterfaceIconDown = false;
        return;
      }
      this.showUserInterfaceIconDown = true;
    },
  },
  computed: {
    // ...mapState("appState", ["systemParameters"]),
  },
  components: {
    BannerSettings,
    LogoFaviconSettings,
    CompanyInfoSettings,
    SocialLinksSettings,
    ColorThemeSettings,
  ZoomServiceAccountsIntegration },
};
</script>

<style scoped>
/* .content-section-ui{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
} */

.icon-size-style {
  font-size: 16px;
}

.preferences-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-content-container {
  background-color: #f5f5f5;
  height: calc(100vh - 5rem);
  padding: 1.875rem;
  overflow-y: auto;
}

.title-container {
  display: flex;
  align-items: center;
}

.page-title {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #000000;
  display: inline-block;
  /* margin: 0; */
}

.preferences-card {
  border-radius: 8px;
  box-shadow: 0 3px 6px #d5d5d529;
  background-color: #f9f9f9;
  margin-bottom: 1.5rem;
  border: 1px solid #dcdcdc;
}

.preferences-card .heading-section {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  margin: 0 0 1rem;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
}
.preferences-card .content-section {
  padding: 1rem;
}

.preferences-card .main-label {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.37;
  text-transform: uppercase;
  color: #727272;
  margin-bottom: 1rem;
}

.preferences-heading {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 500;
}

.sys-icon {
  color: var(--primary-color);
}

.banners-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.banner-item {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
  border-radius: 6px;
  cursor: pointer;
  width: 12.5rem;
  height: 7rem;
  font-size: 1rem;
  line-height: 1.37;
  color: #727272;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.875rem;
}

.banner-item .add-icon {
  font-size: 2rem;
}
</style>
