<template>
	<div class="list-item-container">
		<div @click="handleClick" class="product-listing-item">
			<div class="thumbnail">
				<a>
					<img
						:src="thumbnailPath"
						:alt="data.product.friendlyTitle"
						:class="`thumbnail-img ${isThumbnailError ? 'error' : ''}`"
						@error="
							() => {
								isThumbnailError = true;
							}
						"
					/>
				</a>
			</div>
			<div
				v-if="data.status == ProductVariantStatus.COMING_SOON"
				class="coming-soon-badge"
			>
				Coming Soon
			</div>
			<div class="product-content">
				<div :id="'sb-selected-batch-'+data.id" class="item-product-title">
					<h1>
						{{ data.product.title }} - {{ data.title }}
						<br />
						<!-- <span>
							{{ data.title }}
						</span> -->
					</h1>
					<b-tooltip :target="'sb-selected-batch-'+data.id" triggers="hover">
							<b>{{ data.product.title }}</b><br/>
						    {{ data.title }}
						</b-tooltip>
				</div>
				<span
					class="product-variant-desc"
					v-b-tooltip.hover
					:title="data.shortDescription"
					>{{ data.shortDescription ? data.shortDescription : "" }}</span
				>
				<h2
					v-b-tooltip.hover
					:title="
						productTrainersList
							.map((p) => {
								return p.firstName + ' ' + p.lastName;
							})
							.toString()
							.split(',')
							.join(',\n')
					"
					class="creator-details"
					v-if="!isAdmin && productTrainersList.length > 0"
				>
					<div class="tutor-images">
						<div
							class="tutor-image"
							v-for="t in productTrainersList.slice(0, 2)"
							:key="t.id"
						>
							<img
								v-if="t.profilePicture"
								style=""
								class="ls-is-cached lazyloaded"
								data-src="../../assets/images/user_img_placeholder.jpg"
								alt=""
								:src="t.profilePicture"
							/>
							<!-- <div > -->
							<div v-else class="user-avatar">
								<span>{{ t.firstName[0] }}</span>
							</div>
							<!-- </div> -->
						</div>
						<div v-if="productTrainersList.length > 2" class="tutor-image">
							<div class="user-avatar extra">
								{{ `+${productTrainersList.length - 2}` }}
							</div>
						</div>
					</div>
					<span
						>{{ productTrainersList[0].firstName }}
						{{ productTrainersList[0].lastName }}
						<span v-if="productTrainersList.length > 1"
							>{{
								`+${productTrainersList.length - 1} trainer${
									productTrainersList.length > 2 ? "s" : ""
								}`
							}}
						</span>
					</span>
				</h2>
				<div v-if="!isAdmin" class="ratings">
					<div class="ratings-left">
						<div v-if="data.ratings.value" class="rating-container">
							<b-form-rating
								:value="data.ratings.value"
								readonly
							></b-form-rating>
						</div>

						<div v-else>&nbsp;</div>
					</div>

					<div class="ratings-right">
						<h3>
							<span class="text-bold">{{
								data.ratings.value
									? parseInt(data.ratings.value).toFixed(1)
									: "&nbsp;"
							}}</span>
							<!-- ({{ data.ratings.numberOfRatings }}) -->
						</h3>
					</div>
				</div>

				<!-- <div v-if="!purchased" class="row category">
					<div class="col-sm-8 offset-sm-4">
						<h3 class="text-bold" title="ACCA Professional Level">
							<span>ACCA Professional Level</span>
							<span> {{ data.categories[0].title }} </span>
						</h3>
					</div>
				</div> -->
				<!-- <div v-if="!purchased && !isAdmin">
					<h4 class="product-price">
						{{ parseCurrency(data.pricing.amount, data.pricing.currency, 2) }}
					</h4>
				</div> -->
				<!-- <div class="btn-container">
					<router-link
						:to="
							getProductDetailsUrl + data.product.friendlyTitle + '-' + data.id
						"
						role="button"
						class="primary-btn product-details-btn"
					>
						View {{ !purchased ? "Details" : "" }}
					</router-link>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
import Swal from "sweetalert2";
import formatter from "../../helpers/FormatHelpers";
import { mapActions, mapState } from "vuex";
import { parseForURL } from "../../helpers/FormatHelpers";
import {
	ProductVariantStatus,
	ProductVariantSubscriptionStatus,
} from "../../constants/productVariant";

export default {
	name: "ProductListItem",
	data() {
		return {
			// trainersList: null,
			// purchased: false,
			isThumbnailError: false,
			ProductVariantStatus,
			ProductVariantSubscriptionStatus,
		};
	},
	props: {
		data: Object,
		// purchased: Boolean,
		isAdmin: Boolean,
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
		handleClick(e) {
			if (
				this.isProductVariantBlocked ||
				this.isProductVariantEnded ||
				this.isProductVariantExpired
			) {
				e.stopPropagation();
				e.preventDefault();
				Swal.fire({
					title: this.isProductVariantBlocked
						? "Course Blocked!"
						: this.isProductVariantEnded
						? "Course ended!"
						:this.isProductVariantExpired && this.data.enrollmentType  && this.data.enrollmentType.toLowerCase()=="directenrollment"
						? "Course Access Expired!"
						: this.isProductVariantExpired
						? "Payments due!"
						: "",
					text: this.isProductVariantBlocked
						? "Your course has been blocked. Please contact admin for more information"
						: this.isProductVariantEnded
						? "This course has ended, you can no longer view its contents"
						: this.isProductVariantExpired && this.data.enrollmentType  && this.data.enrollmentType.toLowerCase()=="directenrollment"
						? "Your course access has been expired. Please contact admin for more information "
						:this.isProductVariantExpired
						? "You have pending unpaid invoices for this course. Please clear your dues to access this course "
						: "",
					icon: "error",
					type: "error",
					preConfirm: this.isProductVariantExpired && this.data.enrollmentType.toLowerCase() == "pricingplan"
						? () => {
								this.$router.push("/user/invoices");
						  }
						: null,
					confirmButtonText:
						this.isProductVariantExpired && this.data.enrollmentType.toLowerCase() == "pricingplan"
								? "View Invoices"
								: "OK",
					showCancelButton: this.isProductVariantExpired,
				});
				return;
			}
			this.$router.push(this.productVariantURL);
		},
	},
	created() {
		// console.log(this.data);
	},
	computed: {
		...mapState("user", ["user", "trainers"]),
		purchased() {
			if (
				this.user &&
				((this.user.type && this.user.type.toLowerCase() == "student") ||
					(this.user.role && this.user.role.toLowerCase() == "student")) &&
				this.user.productVariants.length > 0
			) {
				var found = this.user.productVariants.find((p) => p.id == this.data.id);
				return found ? true : false;
			}
		},
		getProductDetailsUrl() {
			if (this.purchased) {
				return "/product/content/";
			} else {
				return "/product/details/";
			}
		},
		thumbnailPath() {
			if (this.isThumbnailError) {
				console.log("thumbnail error");
				return require(`@/assets/images/image-error.png`);
			} else {
				if (this.data.thumbnailPath) {
					var path = !this.data.thumbnailPath.includes("http")
						? require(`@/${this.data.product.thumbnailPath}`)
						: this.data.thumbnailPath;
					return path;
				} else return require(`@/${this.data.product.thumbnailPath}`);
			}
		},
		productTrainersList() {
			var trainerList = [];
			if (
				this.data.properties &&
				this.data.properties.trainers &&
				this.trainers
			) {
				var list = JSON.parse(this.data.properties.trainers);
				// console.log(list);
				list.forEach((trainerID) => {
					var found = this.trainers.find((t) => t.id == trainerID);
					if (found) {
						trainerList.push(found);
					}
				});
			}
			// console.log(trainerList);
			return trainerList;
		},
		productVariantURL() {
			return this.isAdmin
				? "course/" + this.data.id + "/content"
				:( this.purchased?"/product/dashboard/chapters/":
				this.getProductDetailsUrl +
						parseForURL(this.data.product.friendlyTitle) +
						"-" )+
						this.data.id;
		},
		isProductVariantBlocked() {
			return (
				this.data.subscriptionStatus == ProductVariantSubscriptionStatus.BLOCKED
			);
		},
		isProductVariantEnded() {
			return this.data.endDate
				? new Date(this.data.endDate) < new Date()
				: false;
		},
		isProductVariantExpired() {
			return this.data.expiresAt
				? new Date(this.data.expiresAt) < new Date()
				: false;
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
	.grid .product-listing-item {
		flex-direction: row !important;
		width: 100% !important;
		justify-content: space-between;
		border: 1px solid #959595;
		padding: 0.875rem !important;
		border-radius: 0.5rem;
		box-shadow: 0 -6px 8px #e0e0e029;
		min-height: fit-content !important;
		margin: 0 !important;
	}

	.grid .product-listing-item .thumbnail {
		margin: 0 !important;
		/* width: unset !important; */
		/* flex-grow: 1 !important; */
		width: 45% !important;
		min-height: 8.75rem !important;
		/* padding: 1rem 0; */
	}

	.grid .product-listing-item .thumbnail-img {
		width: 100% !important;
	}

	.grid .product-listing-item .product-content {
		width: 55% !important;
		padding-left: 1rem !important;
	}

	.grid .product-listing-item .item-product-title {
		height: fit-content !important;
	}

	.grid .product-listing-item .item-product-title h1 {
		padding-top: 0 !important;
		margin-bottom: 4px !important;
		font-size: 12px !important;
		margin-bottom: 0.25rem !important;
	}

	.grid .product-listing-item .creator-details {
		padding-top: 0 !important;
		font-size: 12px !important;
		margin-bottom: 0.25rem !important;
	}

	.grid .product-listing-item .creator-details img {
		display: none;
	}

	.grid .product-listing-item .ratings {
		margin-top: 0 !important;
		font-size: 10px !important;
	}

	.grid .product-listing-item .btn-container {
		display: none !important;
	}

	.product-listing-item .thumbnail {
		height: auto !important;
	}

	.blocked-message {
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	.list-view .product-listing-item .thumbnail {
		max-width: 40% !important;
	}
	.list-view .product-listing-item .product-content {
		max-width: 60% !important;
	}
}

.list-item-container {
	display: inline-flex !important;
	position: relative !important;
}

.product-listing-item {
	display: inline-flex;
	flex-direction: column;
	min-height: 22.75rem;
	margin: 0;
	flex-grow: 1;
	padding: 0 1.25rem;
	position: relative;
	cursor: pointer;
}

.product-listing-item.blocked {
	opacity: 0.25;
	cursor: not-allowed !important;
	pointer-events: auto !important;
}

.product-listing-item.blocked:active {
	pointer-events: none !important;
}

.blocked-message {
	position: absolute;
	width: 100%;
	top: 3.5rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 20;
	font-size: 0.875rem;
	padding: 0 1rem;
	font-weight: bold;
}

.blocked-message i {
	font-size: 3rem;
	margin-bottom: 1rem;
}

.product-listing-item .thumbnail {
	display: flex;
	justify-content: center;
	/* margin: -4.75rem auto 0; */
	margin-bottom: 0;
	background-color: #efefef !important;
	border: none !important;
	width: 100% !important;
	padding: 0 !important;
	height: 10.625rem !important;
}

.product-listing-item .thumbnail a {
	width: 100% !important;
	display: flex;
	justify-content: center;
}

.product-listing-item .thumbnail-img {
	/* height: 10.25rem; */
	object-fit: cover;
	object-position: top;
	width: 100%;
	border-radius: 0.625rem;
}

.product-listing-item .thumbnail a img.thumbnail-img.error {
	object-fit: contain !important;
}

.product-content {
	display: flex;
	flex-direction: column;
}

.item-product-title {
	text-decoration: none !important;
	color: var(--color-black);
	height: 4.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
}

.item-product-title h1 {
	text-align: left;
	white-space: normal;
	font-size: 1.25rem;
	line-height: 1.33;
	padding: 1rem 0 0.25rem;
	font-weight: 500;
	cursor: pointer;

	/* white-space: nowrap; */
}

.item-product-title span {
	font-size: 0.875rem;
	color: #777;
}

.creator-details {
	color: #959595;
	text-align: left;
	font-size: 1rem;
	line-height: 1.3;
	padding: 0.625rem 0 0.25rem;
	font-weight: 500;
	margin-bottom: 1rem;
	display: flex !important;
	column-gap: 0.5rem;
	align-items: center;
}

.creator-details img {
	vertical-align: middle;
	margin-right: 0.625rem;
	width: 2.3rem;
	height: 2.3rem;
	border-radius: 50%;
	object-fit: cover;
	object-position: center;
}

.ratings {
	/* margin-top: 1.5rem; */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	/* margin-bottom: 1rem; */
}

.ratings-left {
	display: flex;
	pointer-events: none;
	/* padding-left: 1rem; */
	align-items: center;
}

.ratings-right {
	display: flex;
	align-items: center;
}

.ratings-right h3 {
	display: block;
	padding: 0 0.5rem;
	width: 100%;
	float: right;
	text-align: right;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: #959595;
	font-size: 0.875rem;
	line-height: 1.125rem;
	cursor: pointer;
	margin: 0;
}

.ratings-right h3 span {
	color: #000000;
}

/* .product-content h4 {
	color: #959595;
	font-size: 1.125rem;
	line-height: 1.25;
	text-align: left;
	clear: both;
	text-decoration: line-through;
	padding: 1.25rem 1rem 0.25rem;
} */

.product-price {
	color: #000000;
	font-size: 1.375rem;
	line-height: 1;
	text-align: left;
	text-decoration: none;
	font-weight: bold;
	margin-top: 1rem;
}

.product-price span {
	font-size: 1.25rem;
}

.product-details-btn {
	font-size: 1rem;
	line-height: 1.25rem;
	font-weight: 500;
	/* font-family: "Poppins-Medium"; */
	text-align: center;
	border-radius: 0.25rem;
	padding: 0.625rem;
	width: 92%;
}

.btn-container {
	display: flex;
	justify-content: center;
	padding: 1rem 0;
}

.rating-container .form-control {
	height: fit-content;
	background-color: transparent !important;
	border: none;
	height: unset !important;
	margin: 0;
	padding: 0;
	font-size: 1rem !important;
	width: fit-content;
	box-shadow: none;
}

.rating-container .form-control:focus {
	box-shadow: none !important;
}

.tutor-images {
	display: flex;
	flex-direction: row;
}

.tutor-image:first-of-type {
	margin: 0;
}

.tutor-image img {
	margin: 0;
}

.tutor-image {
	margin-left: -0.75rem;
	margin-right: 0;
}

.tutor-image .user-avatar {
	border: 1px solid #fff;
	flex-shrink: 0;
}

.tutor-image:last-of-type .user-avatar {
	margin-right: 0;
}

.tutor-image .user-avatar.extra {
	background-color: #666666;
	color: #ffffff;
	padding: 0 !important;
}

.product-variant-desc {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	transition: all 0.3s ease;
}

.coming-soon-badge {
	position: absolute;
	top: 1rem;
	background-color: var(--primary-color);
	color: var(--color-white);
	padding: 0.375rem 1rem;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
	border-radius: 0 0.25rem 0.25rem 0;
}

.dashboard-products-container .product-listing-item {
	min-height: fit-content !important;
}

.list-view .product-listing-item {
	flex-direction: row !important;
	width: 100% !important;
	justify-content: space-between;
	border-bottom: 1px solid #d3d4d5;
	padding: 0 0.875rem 1.125rem !important;
	border-radius: 0 !important;
	box-shadow: none;
	min-height: fit-content !important;
	margin: 0 !important;
}

.list-view .list-item-container:last-of-type .product-listing-item {
	border: none !important;
}

.list-view .product-listing-item .thumbnail {
	margin: 0 !important;
	/* width: unset !important; */
	/* flex-grow: 1 !important; */
	max-width: 30%;
	min-height: 8.75rem !important;
	/* padding: 1rem 0; */
}

.list-view .product-listing-item .thumbnail-img {
	width: 100% !important;
}

.list-view .product-listing-item .product-content {
	max-width: 70%;
	padding-left: 1rem !important;
	flex-grow: 1;
}

.list-view .product-listing-item .item-product-title {
	height: fit-content !important;
}

.list-view .product-listing-item .item-product-title h1 {
	padding-top: 0 !important;
	margin-bottom: 4px !important;
	margin-bottom: 0.25rem !important;
}

.list-view .product-listing-item .creator-details {
	padding-top: 0 !important;
	margin-bottom: 0.25rem !important;
}

.list-view .product-listing-item .ratings {
	margin-top: 0 !important;
	/* font-size: 10px !important; */
}

/* .product-listing-item .thumbnail {
	height: auto !important;
} */
</style>
