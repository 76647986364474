import store from "../store/index";
import {
  FETCH_POST_PUT_DELETE_CATEGORIES_API,
  ASSIGN_PRODUCT_TO_CATEGORY_POST_API,
  UPDATE_CATEGORY_SEQUENCE,
  UNASSIGN_PRODUCT_TO_CATEGORY_POST_API
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const categoryService = {
  fetchAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  assignCategoryToProduct,
  editProductCategorySequence,
  unassignCategoryToProduct
};

async function fetchAllCategories() {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_PUT_DELETE_CATEGORIES_API,
      "GET",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function createCategory(title) {
  store.dispatch("appState/startLoading");
  try {
    let requestBody = JSON.stringify({ title: title, isVisible: true });
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_PUT_DELETE_CATEGORIES_API,
      "POST",
      requestBody,
      false
    );
    store.dispatch("appState/stopLoading");
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function editProductCategorySequence(categoryObj) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + UPDATE_CATEGORY_SEQUENCE,
      "PUT",
      JSON.stringify(categoryObj),
      false
    );
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function updateCategory(updatedObject) {
  store.dispatch("appState/startLoading");
  try {
    let requestBody = JSON.stringify(updatedObject);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_PUT_DELETE_CATEGORIES_API,
      "PUT",
      requestBody,
      false
    );
    store.dispatch("appState/stopLoading");
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function deleteCategory(id) {
  store.dispatch("appState/startLoading");
  try {
    // let requestBody = JSON.stringify(id);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_PUT_DELETE_CATEGORIES_API +
        `/${id}`,
      "DELETE",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function assignCategoryToProduct(productData) {
  store.dispatch("appState/startLoading");
  try {
    // let requestBody = JSON.stringify(id);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + ASSIGN_PRODUCT_TO_CATEGORY_POST_API,
      "POST",
      JSON.stringify(productData),
      false
    );
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}

async function unassignCategoryToProduct(productData) {
  store.dispatch("appState/startLoading");
  try {
    // let requestBody = JSON.stringify(id);
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + UNASSIGN_PRODUCT_TO_CATEGORY_POST_API,
      "DELETE",
      JSON.stringify(productData),
      false
    );
    return body?.json();
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
