<template>
	<b-card-body class="p-0">
		<ul class="content-item-list" v-if="contentData">
			<ProductContentListItem
				v-for="item in filteredContentData"
				:key="item.id"
				:item="item"
				:productVariantId="productVariantId"
				:class="[
					'content-item',
					{
						active: contentActive == item.id,
					},
				]"
				:purchased="purchased"
				:viewMode="viewMode"
				:parentContentId="parentContentId"
			/>
		</ul>
		<div v-if="filteredContentData.length == 0" class="no-data">
			No content found
		</div>
	</b-card-body>
</template>

<script>
import ProductContentListItem from "./ProductContentListItem.vue";
import { ContentType, RecordedVideoStatus } from "../../constants/content";
import { getInputDateFormat } from "../../helpers/FormatHelpers";

import { ServiceCodes } from "../../constants/serviceCodes";
export default {
	name: "ProductContentList",
	props: {
		purchased: Boolean,
		viewMode: String,
		contentData: Array,
		productVariantId: String,
		parentContentId: String,
	},
	components: { ProductContentListItem },
	data() {
		return {
			contentActive: "",
		};
	},
	methods: {},
	computed: {
		filteredContentData() {
			return this.contentData.filter((content) => {

				
        var startDate = content.availableFrom ? new Date(content.availableFrom) : undefined;
        var endDate = content.availableTo ? new Date(content.availableTo) : undefined;
        const currentDate = new Date();

        // checking if content is within start and end date, if, there is any start or end date
        if(startDate && currentDate < startDate) return false;

		if (content.type == ContentType.EXAM && content.externalService.serviceProvider == ServiceCodes.EXAM.V3ExamSystem) {
			
			return true;
		}
        if(endDate && currentDate > endDate) return false;

				if (!content.availableTo) {
					if (content.type == ContentType.RECORDED_VIDEOS) {
						if (
							content.extraProperties &&
							content.extraProperties.Status !=
								RecordedVideoStatus.PROCESSING &&
							content.extraProperties.Status != RecordedVideoStatus.NOT_UPLOADED
						) {
							return content;
						}
						return;
					}
					return content;
				} else if (content.availableFrom && content.availableTo) {

					const tomorrowDate = new Date(currentDate).setDate(
						currentDate.getDate() + 1
					);

					if (
						(tomorrowDate >= startDate || currentDate >= startDate) &&
						currentDate <= endDate
					) {
						if (content.type == ContentType.LIVE_CLASS) {
							var startTime = startDate.getTime() - 900000;
							var endTime = endDate.getTime();
							var currentTime = currentDate.getTime();
							if (
								getInputDateFormat(currentDate) == getInputDateFormat(startDate)
							) {
								if (currentTime >= startTime && currentTime <= endTime) {
									return content;
								}
							} else if (
								getInputDateFormat(tomorrowDate) ==
								getInputDateFormat(startDate)
							) {
								if (content.type == ContentType.RECORDED_VIDEOS) {
									if (
										content.extraProperties &&
										content.extraProperties.Status != "NotUploaded"
									) {
										return content;
									}
									return;
								}
								return content;
							}
						} else {
							if (content.type == ContentType.RECORDED_VIDEOS) {
								if (
									content.extraProperties &&
									content.extraProperties.Status != "NotUploaded"
								) {
									return content;
								}
								return;
							}
							return content;
						}
					}
				}
			});
		},
	},
};
</script>

<style scoped>
.product-content-heading {
	font-weight: bold;
}

.list-item-icon {
	margin-right: 0.5rem;
	color: var(--primary-color);
}

.info-item {
	font-size: 0.875rem;
}

.content-item-list {
	list-style: none;
	font-size: 0.85rem;
	padding: 0;
	margin-bottom: 0;
}

.no-data {
	text-align: center;
	padding: 2rem;
	font-weight: 500;
	color: var(--color-gray);
	font-size: 1.5rem;
}
</style>
