<template>
    <div class="planner-tasks">
      <header class="planner-header">
        <h2 class="planner-title">Planner: ICAP Class <span class="new-label">NEW</span></h2>
        <p class="planner-description">Planner subsequent narration will be paste here</p>
        <div class="planner-meta">
          <span class="planner-duration">📅 10 Days Planner - 03 Aug to 10 Sep</span>
          <span class="planner-lessons">📘 14 Lessons Items</span>
        </div>
      </header>
  
      <div class="progress-indicator">
        <div class="progress-bar">
          <div class="progress" :style="{ width: progress + '%' }"></div>
        </div>
        <span class="progress-percentage">{{ progress }}%</span>
      </div>
  
      <div class="tasks-grid">
        <div class="task-column" v-for="(column, index) in columns" :key="index">
          <h3 class="column-title">{{ column.title }}</h3>
           <TaskCard :task="task" v-for="(task, index) in column.tasks" :key="index" class="task-card"/>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import TaskCard from "./TaskCard.vue"

  export default {
    name: 'PlannerTasks',
    components: { TaskCard },
    data() {
      return {
        progress: 90,
        columns: [
          {
            title: 'To Do',
            tasks: [
              { title: 'Read Chapter 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: false },
              { title: 'Watch Lecture 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: false },
              { title: 'Quiz 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: false }
            ]
          },
          {
            title: 'In Progress',
            tasks: [
              { title: 'Read Chapter 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: false },
              { title: 'Watch Lecture 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: false }
            ]
          },
          {
            title: 'Completed',
            tasks: [
              { title: 'Read Chapter 1', subtitle: 'Planner subsequent narration come', duration: '2 Days', dates: '10 Days Planner - 03 Aug to 10 Sep', isCompleted: true }
            ]
          }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .planner-tasks {
    font-family: Arial, sans-serif;
    padding: 16px;
  }
  
  .planner-header {
    margin-bottom: 16px;
  }
  
  .planner-title {
    font-size: 24px;
    font-weight: bold;
  }
  
  .new-label {
    font-size: 14px;
    color: #fff;
    background-color: #28a745;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 8px;
  }
  
  .planner-description {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }
  
  .planner-meta {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }
  
  .progress-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .progress-bar {
    flex: 1;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-right: 8px;
    overflow: hidden;
  }
  
  .progress {
    height: 100%;
    background-color: #28a745;
    border-radius: 4px;
  }
  
  .progress-percentage {
    font-size: 14px;
    color: #666;
  }
  
  .tasks-grid {
    display: flex;
    justify-content: space-between;
  }
  
  .task-column {
    flex: 1;
    margin-right: 16px;
  }
  
  .column-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .task-card {
    width: 430px;
    margin-bottom: 16px;
  }
  
  </style>
  