<template>
    <div class="tab-pane" id="highlightsPane" role="tabpanel" aria-labelledby="highlightsTab" tabindex="0">
        <center v-if="loading" class="loading">
            <b-spinner large></b-spinner>
        </center>
        <div v-else-if="hasHighlights()">
            <div class="eb-header">
                <h5>Highlights</h5>
            </div>

            <div class="eb-content">
                <div class="accordion accordion-flush" id="highlights">

                    <div v-for="(highlight, index) in highlights">
                        <div class="accordion-item eb-chapter-toggle">
                            <h2 class="accordion-header eb-toggle-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#highlight-' + index" aria-expanded="false"
                                    :aria-controls="'highlight-' + index">
                                    <span>{{ highlight.title }}</span>
                                    <span>{{ highlight.content.length }} {{ (highlight.content.length > 1 ? "Highlights" : "Highlight")  }} </span>
                                </button>
                            </h2>
                            <div :id="'highlight-' + index" class="accordion-collapse eb-toggle-body collapse show">
                                <div class="accordion-body py-2 px-3">
                                    <div v-for="content in highlight.content">
                                        <div class="eb-single-highlight">
                                            <p class="sub-title">
                                                <a role="button"
                                                    @click="onHightlightsContentView(highlight.topLevelContentID, content)"
                                                    data-bs-toggle="modal" data-bs-target="#chapterModal" class="red">
                                                    {{ content.title }}
                                                </a>
                                                <span class="sub-highlights">{{ content.contentTexts.length }}
                                                    sub-highlights</span>
                                            </p>


                                            <div v-for="(contentText, index) in content.contentTexts">
                                                <div class="row pt-2 pb-2 px-4">
                                                    <div class="col-lg-10 col-md-8">
                                                        <p class="highlight-text" v-html="contentText.text">
                                                        </p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-4">
                                                        <div class="eb-edit-bar">
                                                            <button class="eb-notes-view"
                                                                @click="onHightlightsContentView(highlight.topLevelContentID, content)"><i
                                                                    class="fas fa-eye"></i></button>
                                                            <button class="eb-notes-delete"
                                                                @click="removeSingleHighlight(highlight, content, contentText)">
                                                                <i class="fas fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <!-- /eb-single-highlight -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div><!-- /eb-content -->
        </div><!-- /tab-pane Highlights -->
        <center v-else class="no-content text-center">
            <h4 class="mt-4">No Highlights Found</h4>
        </center>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    name: "ProductContentChaptersHighlights",
    props: {
        active: Boolean,
        productVariantID: String,
    },
    data() {
        return {
            loading: false,
            highlights: []
        };
    },
    methods: {
        ...mapActions("content", ["fetchUserContentActionsByID", "setUserContentAction"]),
        fetchData() {
            this.loading = true;
            this.fetchUserContentActionsByID({ productVariantID: this.productVariantID, dataType: "highlights" }).then((data) => {

                var tempHighlights = [];

                if (this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantID + ""]) {
                    var selectedProductVariant = this.productVariants.items[this.productVariantID + ""];
                    console.log("Selected Product Variants: ",selectedProductVariant)

                    for (let item of data) {
                        var content = item.content;

                        var title = selectedProductVariant.productVariantItems.find(x => x.id == content.topLevelContentID).title;

                        var tempContent = {
                            id: item.id,
                            title: title,
                            topLevelContentID: content.topLevelContentID,
                            content: []
                        };

                        var parsedDataList = JSON.parse(item.data);

                        var contentList = [];
                        var contentTexts = [];

                        for (let contentItem of parsedDataList) {
                            if (contentItem.text === undefined) {
                                contentItem.text = "No text provided from server that was highlighted";
                            }
                            contentTexts.push(contentItem);
                        }

                        if (contentTexts.length > 0) {
                            contentList.push({
                                id: content.id,
                                title: content.title,
                                contentTexts: contentTexts
                            })
                        }

                        tempContent.content = contentList;

                        var existingIndex = tempHighlights.findIndex((existingItem) => existingItem.topLevelContentID === tempContent.topLevelContentID);

                        if (tempContent.content.length > 0) {
                            if (existingIndex !== -1) {
                                tempHighlights[existingIndex].content.push(...tempContent.content);
                            } else {
                                tempHighlights.push(tempContent);
                            }
                        }
                    }
                }

                this.highlights = tempHighlights;
                this.loading = false;
                console.log('Highlights: ', tempHighlights);

            }).catch((error) => {
                this.loading = false;
                console.error("Error fetching user content actions: ", error);
            });
        },
        removeSingleHighlight(highlight, content, contentText) {
            var contentAction = {
                ContentID: content.id,
                DataType: "highlights",
                Data: undefined
            };

            var tempHighlights = [...this.highlights];

            var highlightIndex = tempHighlights.findIndex((item) => item.id === highlight.id);

            if (highlightIndex !== -1) {
                var contentIndex = tempHighlights[highlightIndex].content.findIndex((item) => item.title === content.title);

                if (contentIndex !== -1) {
                    var contentTextIndex = tempHighlights[highlightIndex].content[contentIndex].contentTexts.findIndex((item) => item.id === contentText.id);

                    if (contentTextIndex !== -1) {
                        tempHighlights[highlightIndex].content[contentIndex].contentTexts.splice(contentTextIndex, 1);
                        contentAction.Data = JSON.stringify(tempHighlights[highlightIndex].content[contentIndex].contentTexts)
                    }

                    if (tempHighlights[highlightIndex].content[contentIndex].contentTexts.length === 0) {
                        tempHighlights[highlightIndex].content.splice(contentIndex, 1);
                    }
                }

                if (tempHighlights[highlightIndex].content.length === 0) {
                    tempHighlights.splice(highlightIndex, 1);
                }
            }

            this.setUserContentAction(contentAction).then((action) => {
                if (action && action.dataType && action.dataType.toLowerCase() === "highlights") {
                    this.highlights = tempHighlights;
                }
            });
        },
        onHightlightsContentView(topLevelContentID, content) {
            this.$router.push({
                query: {
                    tab: 'tableofcontents',
                    chapterId: topLevelContentID,
                    contentId: content.id
                }
            });
        },

        hasHighlights() {
            return this.highlights.length > 0;
        },
    },
    computed: {
        ...mapState("productVariant", ["productVariants"]),
    },
    watch: {
        active: function () {
            if (this.active) {
                this.fetchData()
            }
        },
        productVariantID: function () { 
            if (this.active) {
                this.fetchData()
            }
    }
    },
    components: {},
    created() {
        if (this.active) {
            this.fetchData()
        }
    },
    mounted() { }
};
</script>

<style scoped>
.tab-pane{
    border: none;
}

.eb-chapter-toggle .accordion-button,
.eb-chapter-toggle .accordion-button:not(.collapsed) {
    font-size: 14px;
}

.accordion-body p>a {
    font-size: 12px;
}

.no-content {
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading {
    margin-top: 200px;
    margin-bottom: 200px;
}

.sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .sub-highlights {
    padding-right: 30px;
  }
</style>
