<template>
	<div class="page-content-container">
		<div class="title-container">
			<h1 class="page-title">Course Management</h1>
			<CreateCategoryDropdown v-if="!isTrainer" />
		</div>
		<div v-if="isTrainer" class="product-variant-list mt-4">
			<ProductListItem
				class="grid"
				v-for="productVariant in productListData"
				:key="productVariant.id"
				:data="productVariant"
				:purchased="false"
				:isAdmin="isAdmin"
			/>
			<div class="no-content" v-if="productListData.length == 0">
				No courses are assigned
			</div>
		</div>
		<div
			v-else
			:class="`category-list-container ${
				categories.length == 0 ? 'd-flex justify-content-center' : ''
			}`"
		>
			<CategoryList @toggleModal="toggleModal" />
		</div>
	</div>
</template>

<script>
import CategoryList from "../../components/Admin/Category/CategoryList.vue";
import CreateProductVariantModal from "../../components/Admin/ProductVariant/CreateProductVariantModal.vue";
import CreateCategoryDropdown from "../../components/Admin/Category/CreateCategoryDropdown.vue";
import ProductListItem from "../../components/Product/ProductListItem.vue";
// import ProductVariant from "../../models/productVariant";

import { mapState, mapActions } from "vuex";

export default {
	name: "CourseManagement",
	components: {
		CategoryList,
		CreateProductVariantModal,
		CreateCategoryDropdown,
		ProductListItem,
	},
	data() {
		return {
			showModal: false,
			productId: "",
			isAdmin: true,
			isTrainer: false,
			productListData: [],
		};
	},
	methods: {
		...mapActions("user", ["fetchTrainersList", "fetchCurrentUser"]),
		...mapActions("product", ["fetchAllProducts"]),
		toggleModal(e) {
			e.stopPropagation();
			var id = e.target.parentNode.parentNode.parentNode.id.split("-").at(-1);
			// console.log("Clicked on: ", productId);
			this.productId = id;
			this.showModal = !this.showModal;
		},
		updateModalState(value) {
			this.showModal = value;
		},
	},
	computed: {
		...mapState("category", ["categories"]),
		...mapState("user", ["trainers", "user"]),
	},
	created() {
		// var user = JSON.parse(localStorage.getItem("user"));
		// console.log("user", user);
		this.fetchAllProducts();

		if (this.user.type) {
			this.isTrainer = this.user.type.toLowerCase() == "trainer";
		}

		if (this.user.role) {
			this.isTrainer = this.user.role.toLowerCase() == "trainer";
		}

		console.log("istrainer", this.isTrainer);
		if (this.isTrainer) {
			this.fetchCurrentUser().then(() => {
				var trainersProductVariants = this.user.productVariants;
				this.productListData = trainersProductVariants;
			});
		}
	},
};
</script>

<style scoped>
.page-content-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 1.875rem;
	overflow-y: auto;
}

.title-container {
	display: flex;
	align-items: center;
}

.page-title {
	font-weight: 500;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #000000;
	display: inline-block;
	margin: 0;
}

.no-content {
	display: flex;
	align-items: center;
	/* justify-content: center; */
	margin-top: 1.5rem;
	height: 100%;
}

.category-list-container {
	/* display: flex; */
	flex-grow: 1;
	align-items: center;
	/* justify-content: center; */
	height: 92%;
}
</style>
