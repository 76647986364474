<template>
	<div class="why-us-container">
		<!-- <img
			src="../../assets/images/banner-right-vector.svg"
			class="banner-right-img vector"
		/> -->
		<div class="container">
			<div class="why-us-content d-flex">
				<div class="content-text col-lg-5">
					<h1 class="title">Why <span> GoCBE</span> Global?</h1>
					<p class="text">
						On one hand, eLearning courses have become hugely popular by the
						simple virtue of being so much more convenient than traditional face
						to face courses.
					</p>
					<a type="button" role="button" class="action-btn"
						>Get a free consultation <i class="fas fa-long-arrow-alt-right"></i
					></a>
				</div>
				<div class="content-img col-lg-7">
					<img src="@/assets/images/why-us.png" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HomePageWhyUs",
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.why-us-content {
		flex-direction: column-reverse !important;
	}
	.content-img {
		padding: 0 !important;
		margin-bottom: 2rem !important;
	}

	.why-us-content .content-text .text {
		background-color: #ffffff !important;
	}

	.why-us-container {
		padding: 8rem 0 !important;
	}
}

.why-us-container {
	background-image: url("../../assets/images/banner-right-vector.svg");
	padding: 10.625rem 0;
	position: relative;
	/* overflow-x: hidden; */
	background-repeat: no-repeat;
	background-attachment: local;
	background-position: 59vw;
	background-size: 58.75rem;
}

.why-us-content {
	display: flex;
	align-items: center;
}

.why-us-content .content-text {
	padding-right: 2rem;
	display: flex;
	flex-direction: column;
}

.why-us-content .content-text .title {
	font-size: 3.438rem;
	color: var(--secondary-color);
	font-weight: 500;
	letter-spacing: -0.55px;
	margin-bottom: 2.25rem;
}

.why-us-content .content-text .title span {
	color: var(--primary-color);
}

.why-us-content .content-text .text {
	color: #898989;
	text-shadow: 0 15px 50px #00000029;
	font-size: 1.5rem;
	font-weight: normal;
	margin-bottom: 3rem;
}
.why-us-content .content-text .action-btn {
	color: var(--secondary-color);
	text-shadow: 0 15px 50px #00000029;
	font-size: 1.5rem;
	font-weight: normal;
	text-decoration: underline;
}

.why-us-content .content-text .action-btn i {
	margin-left: 1rem;
}

.content-img img {
	width: 100%;
	border-radius: 10px;
	box-shadow: 2px 2px 50px #a8a8a829;
}
</style>
