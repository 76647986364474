<template>
	<div class="add-btn-container">
		<a
			v-b-modal.pricing-plan-sidebar
			id="add-pricing-btn"
			class="add-btn"
			role="button"
			@click="$emit('updateModalState', true)"
		>
			<img src="@/assets/images/add-border-icon.svg" class="add-btn-icon" />
			create pricing plan
		</a>
		<b-modal
			id="pricing-plan-sidebar"
			size="xl"
			hide-header
			hide-footer
			centered
			content-class="create-pricing-plan-modal"
			modal-class="custom-modal"
			body-class="sidebar-body"
			:visible="visible"
			no-close-on-backdrop
			no-close-on-esc
		>
			<div class="custom-stepper-container">
				<div class="custom-stepper">
					<div class="custom-stepper-step">
						<div
							:class="[
								'step-icon first',
								{ active: currentStep == 1, complete: currentStep > 1 },
							]"
						>
							1
						</div>
						<div class="step-text">
							<h1 class="title">Plan information</h1>
							<h3 class="subtitle">Fill in the plan information</h3>
						</div>
					</div>
					<div
						v-if="planType == planTypes.MULTIPLE_PAYMENT_CYCLES"
						class="custom-stepper-step"
					>
						<div :class="['step-icon last', { active: currentStep == 2 }]">
							2
						</div>
						<div class="step-text">
							<h1 class="title">Add Payment Cycles</h1>
							<h3 class="subtitle">Create recurring payment cycles</h3>
						</div>
					</div>
				</div>
				<!-- Custom Stepper -->
			</div>
			<div class="custom-stepper-body-container">
				<button @click="closeSidebar" class="close-button" role="button">
					<img src="@/assets/images/cancel-icon.svg" />
				</button>
				<div class="step1" v-if="currentStep == 1">
					<h1 class="add-pricing-title">
						Create a Pricing Plan for this Course
					</h1>
					<span class="add-pricing-sub-title mb-5">
						Fill in the basic information
					</span>
					<div class="inline-group">
						<div class="col">
							<label class="main-label" for="planName"
								>Plan Name (required)</label
							>
							<input
								id="planName"
								type="search"
								class="form-input"
								placeholder="Type your plan name"
								required
								v-model="planName"
							/>
						</div>
						<div class="col position-relative">
							<label class="main-label" for="country">Country (required)</label>
							<v-select
								multiple
								class="form-input"
								placeholder="Select country"
								v-model="planCountry"
								name="country"
								id="country"
								:options="countryCodes"
								label="name"
								:reduce="(country) => country['alpha-3']"
								:closeOnSelect="false"
								:clearable="true"
								:deselectFromDropdown="true"
								:appendToBody="true"
								:autoscroll="true"
								:calculate-position="withPopper"
							>
							</v-select>

							<button
								class="select-all"
								type="button"
								@click="selectAllCountry"
							>
								{{ selectAllText }}
							</button>
						</div>
					</div>
					<label class="main-label" for="planType">Plan Type</label>
					<div class="type-buttons-container">
						<div
							@click="changeType(planTypes.ONE_TIME)"
							:class="[
								'type-card',
								{ checked: planType == planTypes.ONE_TIME },
							]"
						>
							<p>One-Time Payment Plan</p>
							The course will be in authoring mode hence not published/visible.
							This is the preferable mode when a course is under development.
						</div>
						<div
							@click="changeType(planTypes.MULTIPLE_PAYMENT_CYCLES)"
							:class="[
								'type-card',
								{ checked: planType == planTypes.MULTIPLE_PAYMENT_CYCLES },
							]"
						>
							<p>Multiple Payment Cycles Plan</p>
							The course will be in authoring mode hence not published/visible.
							This is the preferable mode when a course is under development.
						</div>
					</div>
					<form v-if="planType == planTypes.ONE_TIME" id="createSinglePlan">
						<div class="inline-group">
							<div class="col">
								<label class="main-label" for="planCurrency"
									>Amount (required)</label
								>
								<div class="input-container p-0 border-0">
									<div class="col-4">
										<v-select
											class="form-input"
											placeholder="Select currency"
											v-model="planCurrency"
											name="currency"
											id="planCurrency"
											:options="Object.values(currencyCodes)"
											label="code"
											:reduce="(currency) => currency.code"
											:filterBy="currencyFilterBy"
											:appendToBody="true"
											:calculate-position="withPopper"
											:autoscroll="true"
										>
											<template v-slot:option="option">
												<span></span>
												{{ option.code }} - {{ option.name }} ({{
													option.symbol_native
												}})
											</template>
										</v-select>
									</div>
									<div class="col-7">
										<input
											name="singlePlanPrice"
											type="number"
											class="form-input"
											placeholder="Type your invoice amount"
											required
											v-model="singlePlanAmount"
											@change="validateForm"
										/>
									</div>
								</div>
							</div>
							<div class="col position-relative">
								<label class="main-label" for="gracePeriod"
									>Grace Period (required)</label
								>
								<input
									name="gracePeriod"
									type="number"
									class="form-input"
									placeholder="Type grace period (in days)"
									id="gracePeriod"
									required
									v-model="singlePlanGracePeriod"
									@change="validateForm"
								/>
							</div>
						</div>
					</form>
					<form
						v-if="planType == planTypes.MULTIPLE_PAYMENT_CYCLES"
						id="createMultiplePlan"
					>
						<label class="main-label" for="multiplePlanCurrency"
							>Currency (required)
						</label>

						<v-select
							class="form-input"
							placeholder="Select currency"
							v-model="planCurrency"
							name="currency"
							id="multiplePlanCurrency"
							:options="Object.values(currencyCodes)"
							label="name"
							:reduce="(currency) => currency.code"
							:filterBy="currencyFilterBy"
							:appendToBody="true"
							:calculate-position="withPopper"
							:autoscroll="true"
						>
							<template v-slot:option="option">
								<span></span>
								{{ option.code }} - {{ option.name }} ({{
									option.symbol_native
								}})
							</template>
							<template #selected-option="{ name, code }">
								<div
									style="
										display: flex;
										align-items: baseline;
										column-gap: 0.5rem;
									"
								>
									{{ name }} <strong>({{ code }}) </strong>
								</div>
							</template>
						</v-select>
					</form>
				</div>

				<div class="step3" v-if="currentStep == 2">
					<h1 class="add-pricing-title mb-4">Create Payment Cycles</h1>
					<table v-if="planDetailItems.length > 0" id="payment-cycles">
						<tr class="head">
							<th>Payment Cycle Name</th>
							<th>Price</th>
							<th>Content Only</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Grace Period</th>
						</tr>
						<tr v-for="(cycle, index) in planDetailItems" :key="index">
							<td>{{ cycle.title }}</td>
							<td>{{ cycle.amount ? currencyParser(cycle.amount) : "-" }}</td>
							<td>
								{{ cycle.latePrice ? currencyParser(cycle.latePrice) : "-" }}
							</td>
							<td>{{ new Date(cycle.startAt).toDateString() }}</td>
							<td>{{ new Date(cycle.endAt).toDateString() }}</td>
							<td>{{ cycle.gracePeriod }} days</td>
							<div class="right-menu">
								<a
									@click="editPlanDetailItem(index)"
									role="button"
									class="right-menu-icon"
								>
									<img src="@/assets/images/edit-icon.svg" />
								</a>
								<a
									@click="removePlanDetailItem(index)"
									role="button"
									class="right-menu-icon delete"
								>
									<img src="@/assets/images/delete-icon.svg" />
								</a>
							</div>
						</tr>
					</table>

					<h4
						v-if="planDetailItems.length == 0 && !showPlanCycleForm"
						class="text-center mt-3 mb-4"
					>
						No payment cycles created
					</h4>

					<form
						id="editPlanCycle"
						@submit="addPlanDetailItem"
						v-if="showPlanCycleForm"
					>
						<div class="form-group">
							<div class="col-4 label-container">
								<label class="main-label" for="planCycleName"
									>Payment Cycle Name (required)</label
								>
							</div>
							<div class="col-8 input-container">
								<div class="col-12">
									<input
										id="planCycleName"
										type="search"
										class="form-input"
										placeholder="Type your payment cycle name"
										required
										v-model="planCycleName"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="col-4 label-container">
								<label class="main-label" for="amount">Price (required)</label>
							</div>
							<div class="col-8 input-container">
								<div class="col-12">
									<input
										name="cyclePrice"
										type="number"
										class="form-input"
										placeholder="Type amount"
										required
										v-model="planCyclePrice"
										@change="validateForm"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="col-4 label-container">
								<label class="main-label" for="amount"
									>Content-only amount (required)</label
								>
							</div>
							<div class="col-8 input-container">
								<div class="col-12">
									<input
										name="contentOnlyPrice"
										type="number"
										class="form-input"
										placeholder="Type amount"
										required
										v-model="planCycleContentPrice"
										@change="validateForm"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="col-4 label-container">
								<label class="main-label" for="amount">Dates (required)</label>
							</div>
							<div class="col-8 input-container">
								<div class="col-6">
									<input
										name="startDate"
										type="date"
										class="form-input"
										placeholder="Select start date"
										required
										v-model="planCycleStartDate"
										@change="validateForm"
									/>
								</div>
								<div class="col-6">
									<input
										name="endDate"
										type="date"
										class="form-input"
										placeholder="Select end date"
										required
										v-model="planCycleEndDate"
										@change="validateForm"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="col-4 label-container">
								<label class="main-label" for="gracePeriod"
									>Grace Period (required)</label
								>
							</div>
							<div class="col-8 input-container">
								<div class="col-12">
									<input
										name="gracePeriod"
										type="number"
										class="form-input"
										placeholder="Type grace period (in days)"
										required
										v-model="planCycleGracePeriod"
										@change="validateForm"
									/>
								</div>
								<span class="unit-label">days</span>
							</div>
						</div>
					</form>
					<div v-else>
						<button @click="togglePlanCycleForm" class="add-payment-cycle-btn">
							<img src="@/assets/images/plus-icon.svg" alt="" />
							Add Payment Cycle
						</button>
					</div>
				</div>
				<div v-if="isError" class="no-content text-danger">
					<i class="fa fa-exclamation-circle"></i> {{ errorText }}
				</div>
				<div class="d-flex flex-row justify-content-end mt-auto">
					<div v-if="showPlanCycleBtn" class="step-btn-container">
						<button
							v-if="showPlanCycleBtn"
							@click="togglePlanCycleForm"
							role="button"
							class="skip-step-btn"
						>
							cancel
						</button>
						<!-- <a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
							><i class="fas fa-long-arrow-alt-left"></i> prev
						</a> -->
						<button
							:disabled="disableSave || isError"
							@click="addPlanDetailItem(editingCycle)"
							role="button"
							class="step-btn"
						>
							save
							<i class="fas fa-long-arrow-alt-right"></i>
						</button>
					</div>
					<div v-else class="step-btn-container">
						<a @click="prev" v-if="showPrev" role="button" class="step-btn prev"
							><i class="fas fa-long-arrow-alt-left"></i> prev
						</a>
						<button
							@click="next"
							role="button"
							class="step-btn"
							:disabled="disableNext || loading || isError"
						>
							<b-spinner small v-if="loading"></b-spinner>
							{{ stepButtonContent }}
							<i class="fas fa-long-arrow-alt-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { currencyCodes } from "../../../constants/currencyCodes";
import { countryCodes } from "../../../constants/countryCodes";
import formatter from "../../../helpers/FormatHelpers";
import { createPopper } from "@popperjs/core";
import { mapActions, mapState } from "vuex";
export default {
	name: "CreatePricingPlanSidebar",
	data() {
		return {
			currentStep: 1,
			planType: "OneTime",
			totalSteps: 1,
			selectAllText: "Select All",
			// showModal: false,
			currencyCodes,
			countryCodes,
			id: null,
			planCurrency: null,
			planCountry: null,
			planName: null,
			singlePlanAmount: null,
			singlePlanGracePeriod: null,
			showPlanCycleForm: false,
			planCycleName: null,
			planCyclePrice: null,
			planCycleContentPrice: null,
			planCycleStartDate: null,
			planCycleEndDate: null,
			planCycleGracePeriod: null,
			planDetailItems: [],
			isPlanCycleEdit: false,
			editingCycle: null,
			planTypes: {
				ONE_TIME: "OneTime",
				MULTIPLE_PAYMENT_CYCLES: "MultiplePaymentCycles",
			},
			isError: false,
			errorText: "",
		};
	},
	methods: {
		...mapActions("pricing", [
			"createPricingPlan",
			"updatePricingPlan",
			"fetchPricingPlanByProductVariantId",
		]),
		changeType(type) {
			this.planType = type;
			this.totalSteps = this.planType == this.planTypes.ONE_TIME ? 1 : 2;
		},
		selectAllCountry() {
			var allCountries = countryCodes.map((country) => country["alpha-3"]);
			// console.log(allCountries);
			if (this.planCountry && this.planCountry.length > 1) {
				this.planCountry = null;
				this.selectAllText = "Select All";
			} else {
				this.planCountry = allCountries;
				this.selectAllText = "Deselect All";
			}
		},
		currencyFilterBy(option, label, search) {
			var result =
				(option.code || "")
					.toLocaleLowerCase()
					.indexOf(search.toLocaleLowerCase()) > -1 ||
				(option.name || "")
					.toLocaleLowerCase()
					.indexOf(search.toLocaleLowerCase()) > -1;

			return result;
		},
		next() {
			if (!this.isError)
				if (this.currentStep < this.totalSteps) {
					this.currentStep += 1;
				} else if (this.currentStep == this.totalSteps) {
					// var pricingPlan;
					// console.log(this.planType);
					if (this.planType == this.planTypes.ONE_TIME) {
						this.planDetailItems = [
							{
								title: this.planName,
								amount: parseFloat(this.singlePlanAmount),
								amountDetail: [
									{ title: "tax", amount: 0 },
									{ title: "price", amount: parseFloat(this.singlePlanAmount) },
								],
								startAt: this.productVariantData.startDate
									? new Date(this.productVariantData.startDate).toISOString()
									: new Date().toISOString(),
								// endAt: this.productVariantData.endDate
								// 	? new Date(this.productVariantData.endDate).toISOString()
								// 	: new Date("2099-01-01").toISOString(),
								gracePeriod: parseInt(this.singlePlanGracePeriod),
								isApplicableOnPreviousInvoices: true,
								willContentGetExpiredAfterPlan: true,
							},
						];
					}

					var pricingPlan = {
						productVariantID: this.productVariantData.id,
						type: this.planType,
						title: this.planName,
						country: this.planCountry,
						selectedCountryAllowed: true,
						currency: this.planCurrency,
						details: null,
					};
					pricingPlan.details = this.planDetailItems;
					// console.log(pricingPlan);
					if (this.editMode) {
						pricingPlan.id = this.id;
						this.planDetailItems = this.planDetailItems.map((item, index) => {
							if (
								this.planData.details &&
								this.planData.details[index] &&
								this.planData.details[index].id
							) {
								// console.log(this.planData.details[index].id.toString());
								item.id = this.planData.details[index].id.toString();
							}
							item.amountDetail = item.amountDetail.map((amount, i) => {
								if (
									this.planData.details &&
									this.planData.details[index] &&
									this.planData.details[index].amountDetail[i].id
								) {
									// console.log(
									// 	this.planData.details[index].amountDetail[i].id.toString()
									// );
									amount.id =
										this.planData.details[index].amountDetail[i].id.toString();
								}
								return amount;
							});
							return item;
						});
						// console.log(pricingPlan);
						this.updatePricingPlan(pricingPlan).then(() => {
							this.$emit("updatePlans", this.planType);
							this.fetchPricingPlanByProductVariantId(
								this.productVariantData.id
							).then(() => {
								setTimeout(() => {
									var objDiv =
										this.planType == this.planTypes.ONE_TIME
											? document.querySelectorAll(".pricing-plan-item.single")
											: document.querySelectorAll(
													".pricing-plan-item.multiple"
											  );
									// console.log(objDiv);
									objDiv[objDiv.length - 1].scrollIntoView({
										behavior: "smooth",
									});
									Object.assign(this.$data, this.$options.data.call(this));
									this.$emit("updateModalState", false);
								}, 300);
							});
							// this.$emit("updateModalState", false);
						});
					} else {
						this.createPricingPlan({
							plan: pricingPlan,
							id: this.productVariantData.id,
						}).then(() => {
							this.$emit("updatePlans", this.planType);
							setTimeout(() => {
								var objDiv =
									this.planType == this.planTypes.ONE_TIME
										? document.querySelectorAll(".pricing-plan-item.single")
										: document.querySelectorAll(".pricing-plan-item.multiple");
								// console.log(objDiv);
								objDiv[objDiv.length - 1].scrollIntoView({
									behavior: "smooth",
								});
								Object.assign(this.$data, this.$options.data.call(this));
								this.$emit("updateModalState", false);
							}, 300);
							// this.$emit("updateModalState", false);
						});
					}
				}
		},
		prev() {
			if (this.currentStep > 1) {
				this.currentStep -= 1;
			}
		},
		addPlanDetailItem(id) {
			// console.log("plan cycle addPlanDetailItem", id);
			var planCycle = {
				title: this.planCycleName,
				amount: parseFloat(this.planCyclePrice),
				amountDetail: [
					{ title: "tax", amount: 0 },
					{ title: "price", amount: parseFloat(this.planCyclePrice) },
				],
				latePrice: parseFloat(this.planCycleContentPrice),
				startAt: new Date(this.planCycleStartDate).toISOString(),
				endAt: this.planCycleEndDate
					? new Date(
							new Date(this.planCycleEndDate).setHours(23, 59, 59, 59)
					  ).toISOString()
					: null,
				gracePeriod: parseInt(this.planCycleGracePeriod),
				isApplicableOnPreviousInvoices: true,
				willContentGetExpiredAfterPlan: true,
			};
			if (this.isPlanCycleEdit) {
				// console.log("editing plan index = ", id);
				planCycle.id = this.planDetailItems[id].id ?? id;
				planCycle.amountDetail = planCycle.amountDetail.map((detail, index) => {
					if (this.planData)
						detail.id = this.planData.details[id].amountDetail[index].id;
					return detail;
				});
				this.planDetailItems[id] = planCycle;
				this.isPlanCycleEdit = false;
			} else {
				this.planDetailItems.push(planCycle);
			}
			this.togglePlanCycleForm();
		},
		removePlanDetailItem(index) {
			this.planDetailItems.splice(index, 1);
		},
		editPlanDetailItem(index) {
			var planToEdit = this.planDetailItems[index];
			this.planCycleName = planToEdit.title;
			this.planCyclePrice = planToEdit.amount;
			this.planCycleStartDate = formatter.getInputDateFormat(
				planToEdit.startAt
			);
			this.planCycleEndDate = formatter.getInputDateFormat(planToEdit.endAt);
			this.planCycleGracePeriod = planToEdit.gracePeriod;
			this.planCycleContentPrice = planToEdit.latePrice;
			this.showPlanCycleForm = true;
			this.isPlanCycleEdit = true;
			this.editingCycle = index;
		},
		togglePlanCycleForm() {
			this.showPlanCycleForm = !this.showPlanCycleForm;
			this.resetPlanCycle();
		},
		closeSidebar() {
			if (this.editMode) {
				Object.assign(this.$data, this.$options.data.call(this));
				// this.editMode = false;
			}
			this.$emit("updateModalState", false);
		},
		resetPlanCycle() {
			this.planCycleName = null;
			this.planCyclePrice = null;
			this.planCycleContentPrice = null;
			this.planCycleStartDate = null;
			this.planCycleEndDate = null;
			this.planCycleGracePeriod = null;
		},
		currencyParser(amount) {
			// console.log(formatter.toCurrencyString(amount, this.planCurrency, 0));
			return formatter.toCurrencyString(amount, this.planCurrency, 0);
		},
		withPopper(dropdownList, component, { width }) {
			// console.log(dropdownList, component, { width });
			if (
				component.$attrs.name == "currency" &&
				this.planType.toLowerCase() == "onetime"
			) {
				var widthValue = width.split("px")[0];
				// console.log(component.$attrs.name, widthValue * 2);
				dropdownList.style.width = (widthValue * 2.25).toString() + "px";
			} else dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		validateForm(e) {
			var isAmountInvalid =
				this.singlePlanAmount < 0 || this.planCyclePrice < 0;
			var isContentPriceInvalid = this.planCycleContentPrice < 0;
			var isGracePeriodInvalid =
				this.singlePlanGracePeriod < 0 || this.planCycleGracePeriod < 0;
			var isDateInvalid =
				this.planCycleStartDate &&
				this.planCycleEndDate &&
				new Date(this.planCycleStartDate) > new Date(this.planCycleEndDate);

			if (
				isAmountInvalid ||
				isContentPriceInvalid ||
				isGracePeriodInvalid ||
				isDateInvalid
			) {
				this.isError = true;
				this.errorText = `Please enter a valid value ${
					isAmountInvalid ? "( Amount )" : ""
				}
					${isContentPriceInvalid ? "( ContentOnly Price )" : ""}
					${isGracePeriodInvalid ? " (GracePeriod )" : ""}
					${isDateInvalid ? "( Start & End Date )" : ""}
				`;
				// e.target.classList.add("text-danger");
			} else {
				this.isError = false;
				this.errorText = "";
				// e.target.classList.remove("text-danger");
			}
		},
		checkPlanCurrencyValidity() {
			if (!this.planCurrency) return;
			Object.entries(this.planCountryAndCurrency).forEach(([key, value]) => {
				if (this.planCurrency == key) return;
				if (this.editMode && this.planOriginalCurrency == key) return;
				value.forEach((country) => {
					if (this.planCountry && this.planCountry.includes(country)) {
						this.$toasted.info(
							`${
								country + " already has a plan in " + key
							}. ${country} has been removed from selected countries`,
							{
								theme: "outline",
								position: "bottom-center",
								action: {
									text: "OK",
									onClick: (e, toastObject) => {
										toastObject.goAway(0);
									},
								},
							}
						);
						this.planCountry = this.planCountry.filter((c) => c != country);
					}
				});
			});
		},
	},
	computed: {
		...mapState("pricing", ["loading"]),
		visible() {
			return this.showModal;
		},
		stepButtonContent() {
			if (this.currentStep != this.totalSteps) {
				return "Next";
			} else return "Finish";
		},
		showPrev() {
			return this.currentStep > 1;
		},
		showPlanCycleBtn() {
			if (this.currentStep == 2 && this.showPlanCycleForm) {
				return true;
			}
			return false;
		},
		disableNext() {
			if (this.currentStep == 1) {
				if (this.planName && this.planCurrency && this.planCountry) {
					if (this.planType == this.planTypes.ONE_TIME) {
						return !this.singlePlanAmount;
					}
					return false;
				} else {
					return true;
				}
			} else if (this.currentStep == 2) {
				if (this.planDetailItems.length < 2) {
					return true;
				} else return false;
			}
			return false;
		},
		disableSave() {
			if (
				this.planCycleName &&
				this.planCyclePrice &&
				this.planCycleContentPrice &&
				this.planCycleStartDate &&
				this.planCycleGracePeriod
			) {
				return false;
			} else return true;
		},
	},
	props: {
		productVariantData: Object,
		planData: Object,
		editMode: Boolean,
		planDetailToEdit: String,
		showModal: Boolean,
		planCountryAndCurrency: Object,
	},
	created() {
		// if (this.editMode) {
		// 	this.planName = this.planData.title;
		// 	this.planCountry = this.planData.country;
		// 	this.planCurrency = this.planData.currency;
		// 	if (this.planData.details.length == 1) {
		// 		this. < 0 || this. = this.planData.details[0].amount;
		// 		this.singlePlanGracePeriod = this.planData.details[0].gracePeriod;
		// 	} else {
		// 		this.changeType("multiple");
		// 		this.planDetailItems = this.planData.details;
		// 	}
		// 	if (this.planDetailToEdit) {
		// 		this.editPlanDetailItem(parseInt(this.planDetailToEdit));
		// 	}
		// }
	},
	watch: {
		editMode: function () {
			if (this.editMode) {
				this.id = this.planData.id;
				this.planName = this.planData.title;
				this.planCountry = this.planData.country;
				this.planCurrency = this.planData.currency;
				this.planOriginalCurrency = this.planData.currency;
				if (this.planData.details.length == 1) {
					this.singlePlanAmount = this.planData.details[0].amount;
					this.singlePlanGracePeriod = this.planData.details[0].gracePeriod;
				} else {
					this.changeType(this.planTypes.MULTIPLE_PAYMENT_CYCLES);
					this.planDetailItems = this.planData.details;
				}

				if (this.planDetailToEdit != null) {
					this.editPlanDetailItem(parseInt(this.planDetailToEdit));
					this.currentStep = 2;
					setTimeout(() => {
						document.getElementById("editPlanCycle").scrollIntoView();
						document.getElementById("planCycleName").focus();
					}, 300);
				}
			}
		},
		planCurrency: function (newVal, oldVal) {
			// console.log(newVal, oldVal);
			this.checkPlanCurrencyValidity();
		},
		planCountry: function (newVal, oldVal) {
			// console.log(newVal, oldVal);
			if (!newVal) return;
			this.checkPlanCurrencyValidity();
		},
	},
};
</script>

<style>
.create-pricing-plan-modal {
	height: 90vh;
	width: 90vw;
}
#pricing-plan-sidebar .modal-dialog {
	max-width: 90vw;
}

.create-pricing-plan-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
}
.sidebar-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
}

.add-btn-container {
	margin-left: auto;
}

.add-btn-container .add-btn {
	background: #ffffff;
	border: 1px solid var(--primary-color);
	box-sizing: border-box;
	border-radius: 8px;
	display: flex !important;
	align-items: center;
	justify-content: center;
	padding: 0.625rem 1rem;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 1.125rem;
	letter-spacing: 0.05em;
	color: #000000;
	text-decoration: none;
	transition: all 0.3s ease;
}

.add-btn-container .add-btn:hover {
	box-shadow: 4px 6px 5px 0px rgb(197 197 197 / 20%);
}

.add-btn-container .add-btn-icon {
	margin-right: 0.625rem;
	width: 1rem;
	height: 1rem;
}

.custom-stepper-container {
	background: #f5f5f5;
	border-radius: 8px 0px 0px 8px;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 100%; */
	padding: 0 2rem;
}
.custom-stepper-container .custom-stepper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 7.5rem;
}

.custom-stepper-container .custom-stepper-step {
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
}

.custom-stepper-container .custom-stepper-step .step-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #ffffff;
	border: 2px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.25);
	font-weight: bold;
	font-size: 1.5rem;
	line-height: 1.5;
	color: #000000;
	flex-shrink: 0;
	width: 2.875rem;
	height: 2.875rem;
	cursor: pointer;
	position: relative;
}

.custom-stepper-container .custom-stepper-step .step-icon:before {
	content: "";
	background-color: #dcdcdc;
	width: 2px;
	height: 9.75rem;
	left: 1.375rem;
	top: -9.75rem;
	position: absolute;
}

.custom-stepper-container .custom-stepper-step .step-icon.first::before {
	display: none;
}

.custom-stepper-container .custom-stepper-step .step-icon.active:before,
.custom-stepper-container .custom-stepper-step .step-icon.complete:before {
	background-color: var(--primary-color);
}

.custom-stepper-container .custom-stepper-step .step-icon.active,
.custom-stepper-container .custom-stepper-step .step-icon.complete {
	background-color: var(--primary-color);
	border-color: var(--primary-color);
	color: #ffffff;
}

.custom-stepper-container .custom-stepper-step .step-text {
	display: flex;
	flex-direction: column;
	row-gap: 0.75rem;
	max-height: 5rem;
}

.custom-stepper-container .custom-stepper-step .step-text .title {
	font-weight: 450;
	font-size: 1.5rem;
	line-height: 1.5;
	color: #000000;
	margin: 0;
}

.custom-stepper-container .custom-stepper-step .step-text .subtitle {
	font-weight: 400;
	font-size: 1.25rem;
	line-height: 1.37;
	color: #727272;
}

#pricing-plan-sidebar .step-btn-container.start {
	align-self: flex-start;
}

.custom-stepper-body-container {
	display: flex;
	flex-direction: column;
	width: 70%;
	padding: 2.75rem 2.25rem 1.5rem;
	background-color: #ffffff;
	position: relative;
}

.custom-stepper-body-container .step1 {
	/* margin-top: 1.625rem; */
	width: 100%;
	display: flex;
	flex-direction: column;
}

.add-pricing-title {
	font-weight: 500;
	font-size: 2rem;
	line-height: 1;
	margin: 0.5rem 0 1rem;
	color: #000000;
}

.add-pricing-sub-title {
	font-size: 1.25rem;
	line-height: 1.37;
	color: #727272;
}

.type-buttons-container {
	margin-top: 1rem;
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
	/* margin-bottom: 3rem; */
}

.type-card {
	background: #ffffff;
	border: 1px solid #dcdcdc;
	box-sizing: border-box;
	box-shadow: 2px 3px 5px rgba(197, 197, 197, 0.15);
	border-radius: 6px;
	padding: 1.5rem;
	cursor: pointer;
	flex-grow: 1;
	font-size: 1rem;
	line-height: 1.37;
	color: #727272;
	position: relative;
}

.type-card.checked::after {
	content: url("~@/assets/images/check-icon.svg");
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	border-radius: 50%;
}

.type-card.checked {
	border-color: var(--primary-color);
}

.type-card p {
	color: #000000;
	margin-bottom: 1rem;
	font-size: 1.5rem;
}

.close-button {
	position: absolute;
	top: 2.5rem;
	right: 2rem;
}

.close-button img {
	width: 1.5rem;
}

#pricing-plan-sidebar .custom-stepper-body-container .form-group {
	display: flex;
	flex-direction: row;
	margin-bottom: 0;
	border-bottom: 1px solid #ebebeb;
}

#pricing-plan-sidebar .custom-stepper-body-container .form-group:last-of-type {
	border-bottom: 0;
}

.custom-stepper-body-container .form-input {
	border: 0;
	border-bottom: 1.5px solid #b2b2b2;
	font-size: 1rem;
	outline: none;
	padding: 0.875rem 0;
	/* max-width: 21.625rem; */
	width: 100%;
	line-height: 1.5;
	min-height: 3.845rem;
	border-radius: 0;
	max-height: 4.5rem;
	overflow-y: auto;
}

.custom-stepper-body-container select.form-input {
	margin-right: 0.5rem;
}

.custom-stepper-body-container .form-input .vs__dropdown-toggle {
	border: none !important;
}

.input-container,
.label-container {
	padding: 2rem;
	display: flex;
	align-items: center;
	/* border-bottom: 1px solid #ebebeb; */
}

.input-container {
	flex-direction: row;
	column-gap: 1rem;
	border-left: 1px solid #ebebeb;
	position: relative;
}

.label-container label {
	margin: 0;
}

.unit-label {
	font-size: 1rem;
	right: 2rem;
	position: absolute;
	color: #727272;
}

.custom-stepper-body-container .main-label {
	font-weight: 500;
	font-size: 1rem;
	line-height: 1.37;
	text-transform: uppercase;
	color: #727272;
	/* margin-bottom: 1rem; */
}

#createSinglePlan,
#createMultiplePlan {
	margin-top: 2rem;
}

.vs__dropdown-menu .vs__dropdown-option--selected {
	background-color: #99f1a2 !important;
}

.vs__dropdown-menu .vs__dropdown-option--selected:hover {
	background-color: #fd6e6e !important;
}

#pricing-plan-sidebar .select-all {
	position: absolute;
	top: 0;
	font-size: 1rem;
	color: var(--primary-color);
	right: 0;
}

#payment-cycles {
	width: 100%;
	margin-top: 2rem;
}

#payment-cycles tr,
.add-payment-cycle-btn {
	display: flex;
	flex-direction: row;
	padding: 0.75rem 1.125rem;
	background: #ffffff;
	border: 1px solid #d8d8d8;
	border-radius: 6px;
	margin-bottom: 1rem;
}

.add-payment-cycle-btn {
	width: 100%;
	justify-content: center;
	font-size: 1rem;
	column-gap: 0.5rem;
	align-items: center;
}

#payment-cycles tr td:first-of-type,
#payment-cycles tr th:first-of-type {
	flex-basis: 25%;
}

#payment-cycles tr.head {
	border: none;
	margin: 0;
}

#payment-cycles tr td,
#payment-cycles tr th {
	font-weight: 500;
	flex-shrink: 0;
	flex-basis: 15%;
	font-size: 1rem;
}

#payment-cycles tr th {
	text-transform: uppercase;
	color: #727272;
}

#payment-cycles tr td {
	color: #000000;
}

#payment-cycles .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	margin-right: 0.75rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
	position: relative;
	right: 4rem;
}

#payment-cycles tr:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

#payment-cycles .right-menu-icon {
	width: 1.625rem;
	height: 1.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
}

#payment-cycles .right-menu-icon.delete {
	border-color: #ff0000;
}

#payment-cycles .right-menu-icon img {
	max-height: 0.75rem;
}

.vs__dropdown-menu {
	z-index: 2000 !important;
}
</style>
