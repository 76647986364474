<template>
	<div class="course-feedback-container">
		<div class="container" v-if="feedbacks!=null">
			<div class="col-lg-8 col-sm-12 feedback-div">
				<div class="d-flex justify-content-between">
					<h2>Students Feedback</h2>
				</div>
				<div v-if="feedbacks.length>0">
					<div class="feedback-box" v-for="feedback in feedbacks">
						<a
							title="Delete FeedBack"
							@click="showDeleteConfirmPopup(feedback)"
							role="button"
							class="right-menu-icon delete feedback-delete"
						>
							<img src="@/assets/images/delete-icon.svg" />
						</a>
						<star-rating  v-bind:rating="feedback.ratingPoint" v-bind:star-size="20" active-color="#FFBF00" v-bind:increment="0.1" v-bind:read-only="true" v-bind:show-rating="false" ></star-rating>
						<p class="feedback-text"> {{ feedback.review }}</p>
						<p class="feedback-text-user">- {{ feedback.user.firstName }} {{ feedback.user.lastName }}</p>
					</div>
				</div>				
				<div v-else>
					<h5><small>No feedback found</small></h5>
				</div>
	
			</div>
		</div>
		<div v-else>
			<h5><center>Loading....</center></h5>
		</div>
	
	</div>
</template>

<script>
import ProductFeedbackCard from '../../Product/ProductFeedbackCard.vue';
import { mapActions, mapState } from "vuex";
import StarRating from "vue-star-rating"
export default {
	data() {
		return {
			feedbackId:null,
			feedbacks:null
		};
	},
	components:{		
		StarRating
	},
	props: { productVariantData: Object },
	methods: {
		...mapActions("productVariant", ["fetchRatingByProductVariant","deleteStudentFeedback"]),
		next(){
			this.mainFeedbackIndex++;
			
			if(this.mainFeedbackIndex>(this.feedbacks.length-1))
			{
				this.mainFeedbackIndex=0;
			}
		},
		previous(){
			this.mainFeedbackIndex--;
			if(this.mainFeedbackIndex<0)
			{
				this.mainFeedbackIndex=this.feedbacks.length-1;
			}
		},
		showDeleteConfirmPopup(feedback) {
			this.feedbackId = feedback.id; 
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete this feedback?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					if (value) {
						this.deleteStudentsFeedback();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		},

		deleteStudentsFeedback() {
			this.deleteStudentFeedback(this.feedbackId).then(() => {
				this.$toasted.success(`Feedback deleted successfully!`, {
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				var id = this.productVariantData.id + "";
				this.fetchRatingByProductVariant(id).then((val) => {
					this.feedbacks = val;
				});
			});
			
		}

	},
	computed: {
		
	},
	created() {
		var id=this.productVariantData.id+"";
			this.fetchRatingByProductVariant(id).then((val)=>{
				this.feedbacks=val;
			});
	},
	watch: {
		productVariantData: function () {
			var id=this.productVariantData.id+"";
			this.fetchRatingByProductVariant(id).then((val)=>{
				this.feedbacks=val;
			});
		},
	},

};
</script>

<style scoped>
.course-feedback-container {
	background-color: #f5f5f5;
	height: calc(100vh - 5rem);
	padding: 2rem 1.5rem 0;
	overflow-y: auto;
}
.feedback-text{
	color: rgb(153, 153,153);
	font-size: larger;
	margin-top: 20px;
}
.feedback-div{
	padding: 0px;
	padding-right: 30px;
}
.feedback-arrow{
	font-size: 2rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    border: 2px solid var(--secondary-color);
    background-color: #ffffff;
    box-shadow: 3px 3px 6px #00000029;
    color: var(--secondary-color);
    text-decoration: none;
	margin-left: 10px;
}
.feedback-text-user{
	color: black;	
	font-size: larger;
	font-weight: bold;
	margin-top: 20px;
	margin: 0px;
	padding: 0px;
}
.feedback-box{
	margin-top: 10px;
	margin-bottom: 5px;
	padding: 20px 1.875rem;
    border: 1px solid #a7a9ac;
    border-radius: 8px;
	background: #ffffff;
}
.feedback-delete{
	position: absolute;
	right: 65px	;
}
.right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
	color: #000000;
	text-decoration: none;
}
.right-menu-icon img {
	width: 1rem;
	height: 1rem;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}

</style>
