<template>
	<div class="product-variant-list">
		<draggable
			v-if="productVariantList"
			v-model="productVariantList"
			group="product_variant"
			handle=".handle"
			ghost-class="ghost"
			:move="checkMove"
			@change="update($event, productId)"
		>
			<!-- <transition-group> -->
			<ProductVariantListItem
				v-for="item in productVariantList"
				:key="item.id"
				:productVariantData="item"
				@updateProductVariant="updateVariant"
				:categoryId="categoryId"
			/>
			<div class="ps-2 text-muted" v-if="productVariantList.length == 0">
				No content found!
			</div>
			<!-- </transition-group> -->
		</draggable>
		<div v-else>
			<b-skeleton height="3rem" class="mb-3"></b-skeleton>
			<b-skeleton height="3rem" class="mb-3"></b-skeleton>
			<b-skeleton height="3rem" class="mb-3"></b-skeleton>
		</div>
	</div>
</template>

<script>
import ProductVariantListItem from "./ProductVariantListItem.vue";
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";

export default {
	name: "ProductVariantList",
	components: { ProductVariantListItem, draggable },
	props: { productVariantList: Array, productId: String, categoryId: String },
	methods: {
		...mapActions("productVariant", [
			"updateProductVariant",
			"editProductVariantSequence",
		]),
		checkMove: function (evt) {
			console.log(evt.draggedContext);
		},

		update(e, productId) {
	
			var productVariantObj = {};
			var productVariantID;
			var afterProductVariantID;
			if (e.added) {
				if (e.added.newIndex != 0) {
					afterProductVariantID =
						this.productVariantList[e.added.newIndex - 1].id;
					productVariantObj.afterProductVariantID = afterProductVariantID;
				}
				productVariantObj = {
					...productVariantObj,
					productID: productId,
				};
				productVariantID = e.added.element.id;
			}
			if (e.moved) {
				// console.log(e.moved);
				if (e.moved.newIndex != 0) {
					afterProductVariantID =
						this.productVariantList[e.moved.newIndex - 1].id;
					productVariantObj.afterProductVariantID = afterProductVariantID;
				}
				productVariantObj = {
					...productVariantObj,
					productID: productId,
				};
				productVariantID = e.moved.element.id;
			}
			if (!e.removed) {
				this.editProductVariantSequence({
					productVariant: productVariantObj,
					id: productVariantID,
				}).then(() => {
					console.log("editProductVariantSequence", productVariantObj);
				});
			}
			console.log(e, productId);
		},
		updateVariant(response) {
			this.$emit("updateProductVariant", response);
		},
	},
};
</script>

<style scoped>
.product-variant-list {
	margin-bottom: 1rem;
	padding-left: 0.75rem;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}
</style>
