import {
	sampleQuestions,
	sampleQuestionCategories,
	sampleQuestionCategoriesByParent,
	sampleQuestionTypes,
} from "../constants/question";

import store from ".";

import { examService } from "../services/exam.service";

import { sampleExamThemes } from "../constants/exam";

const state = {
	questionCategories: {},
	questionCategoriesByParent: sampleQuestionCategoriesByParent,
	allQuestions: [],
	totalPages: {},
	questionTypes: sampleQuestionTypes,
	examThemes: [],

	loading: false,
	// examCategories: [
	// 	{
	// 		categoryID: 0,
	// 		parentCategoryID: 1,
	// 		sourceID: "cat1",
	// 		categoryName: "Uncategorized Exams",
	// 		categoryType: "Exam",
	// 	},
	// ],
};

const getters = {};

const actions = {
	async fetchQuestions(
		{ commit },
		{ productVariantID, categoryID, pageSize, currentPage }
	) {
		commit("setLoading", true);
		var response = await examService
			.fetchQuestions({
				productVariantID,
				categoryID,
				pageSize: pageSize ?? 10,
				currentPage: currentPage ?? 1,
			})
			.then((res) => {
				commit("setLoading", false);
				commit("setQuestions", {
					questions: res?.Payload,
					totalPages: res?.TotalPages,
					productVariantID,
					categoryID,
				});
				// state.allQuestions = res.payload;
				return res?.Payload;
			});
		return response;
	},

	async fetchQuestionCategories({ commit }, { productVariantID, categoryID }) {
		console.log("fetchQuestionCategories", { productVariantID, categoryID });
		commit("setLoading", true);
		var response = await examService
			.fetchQuestionCategories({ productVariantID, categoryID })
			.then((res) => {
				commit("setLoading", false);
				commit("addQuestionCategory", {
					productVariantID,
					categoryID,
					category: res?.payload,
				});
				// state.allQuestions = res.payload;
				return res?.payload;
			});
		return response;
	},

	async getExamResultByProductVariant({ commit }, { productVariantID }) {
		commit("setLoading", true);
		
		 var response  = await examService.getExamResultByProductVariant({productVariantID})
			.then((res) => {
				commit("setLoading", false);
				
				return res?.payload;
			});

			return response;
	},

	async getExamContentResult({ commit }, { examID, contentID }) {
		commit("setLoading", true);
		
		var response  = await examService.getExamContentResult({ examID, contentID })
			.then((res) => {
				commit("setLoading", false);
				return res?.payload;
			});

			return response;
	},

	async createQuestion({ commit }, productVariantID) {
		commit("setLoading", true);
		var response = await examService
			.createQuestion(productVariantID)
			.then((res) => {
				commit("setLoading", false);
				return res?.payload;
			});
		return response;
	},

	async deleteQuestion({ commit }, questionID) {
		commit("setLoading", true);
		var response = await examService.deleteQuestion(questionID).then((res) => {
			commit("setLoading", false);
			commit("removeQuestion", questionID);
			return true;
		});
		return response;
	},

	async createExam({ commit }, examObject) {
		console.log(examObject.parentDirectoryID, store.state);
		if (examObject.parentDirectoryID) {
			var nextSequence =
				store.state.content.content[examObject.parentDirectoryID].children &&
				store.state.content.content[examObject.parentDirectoryID].children
					.length > 0
					? store.state.content.content[
							examObject.parentDirectoryID
					  ].children.at(-1).sequence + 1
					: 1;
			console.log(nextSequence);
			examObject.sequence = nextSequence;
		}

		commit("setLoading", true);
		var response = await examService.createExam(examObject).then((res) => {
			commit("setLoading", false);
			return res?.payload;
		});
		if (Array.isArray(response)) {
			var contentObj = {
				data: response,
				parentDirectoryID: examObject.parentDirectoryID,
			};
			commit("content/addContentArrayToParentDirectory", contentObj, {
				root: true,
			});
		} else {
			response.parentDirectoryID = examObject.parentDirectoryID;
			commit("content/addContentToParentDirectory", response, { root: true });
		}
		commit("setLoading", false);
		// return data;
		return response;
	},
	async createLegacyExam({ commit }, examObject) {
		console.log(examObject.parentDirectoryID, store.state);
		if (examObject.parentDirectoryID) {
			var nextSequence =
				store.state.content.content[examObject.parentDirectoryID].children &&
				store.state.content.content[examObject.parentDirectoryID].children
					.length > 0
					? store.state.content.content[
							examObject.parentDirectoryID
					  ].children.at(-1).sequence + 1
					: 1;
			console.log(nextSequence);
			examObject.sequence = nextSequence;
		}

		commit("setLoading", true);
		var response = await examService
			.createLegacyExam(examObject)
			.then((res) => {
				commit("setLoading", false);
				return res?.payload;
			});
		if (Array.isArray(response)) {
			var contentObj = {
				data: response,
				parentDirectoryID: examObject.parentDirectoryID,
			};
			commit("content/addContentArrayToParentDirectory", contentObj, {
				root: true,
			});
		} else {
			response.parentDirectoryID = examObject.parentDirectoryID;
			commit("content/addContentToParentDirectory", response, { root: true });
		}
		commit("setLoading", false);
		// return data;
		return response;
	},
	async createQuiz({ commit }, obj) {
		console.log(obj.parentDirectoryID, store.state);
		if (obj.parentDirectoryID) {
			var nextSequence =
				store.state.content.content[obj.parentDirectoryID].children &&
				store.state.content.content[obj.parentDirectoryID].children
					.length > 0
					? store.state.content.content[
						obj.parentDirectoryID
					  ].children.at(-1).sequence + 1
					: 1;
			console.log(nextSequence);
			obj.sequence = nextSequence;
		}

		commit("setLoading", true);
		var response = await examService
			.createQuiz(obj)
			.then((res) => {
				commit("setLoading", false);
				return res?.payload;
			}).finally(() => {
				commit("setLoading", false);
			});
		if (Array.isArray(response)) {
			var contentObj = {
				data: response,
				parentDirectoryID: obj.parentDirectoryID,
			};
			commit("content/addContentArrayToParentDirectory", contentObj, {
				root: true,
			});
		} else {
			response.parentDirectoryID = obj.parentDirectoryID;
			commit("content/addContentToParentDirectory", response, { root: true });
		}
		commit("setLoading", false);
		// return data;
		return response;
	},

	async updateExam({ commit }, examObject) {
		commit("setLoading", true);
		var response = await examService.updateExam(examObject).then((res) => {
			commit("setLoading", false);
			return res?.payload;
		});
		return response;
	},

	async createQuestionCategory({ commit }, categoryObject) {
		commit("setLoading", true);
		var response = await examService
			.createQuestionCategory(categoryObject)
			.then((res) => {
				commit("setLoading", false);
				// commit("addQuestionCategory", res?.payload);
				return res?.payload;
			});
		return response;
	},

	async updateQuestionCategory(
		{ commit },
		{ createCategoryObject, productVariantID }
	) {
		commit("setLoading", true);
		var response = await examService
			.updateQuestionCategory(createCategoryObject)
			.then((res) => {
				commit("setLoading", false);
				commit("addQuestionCategory", {
					productVariantID,
					categoryID: res.payload.id,
					category: res?.payload,
				});
				return res?.payload;
			});
		return response;
	},

	async deleteQuestionCategory({ commit }, categoryID) {
		commit("setLoading", true);
		var response = await examService
			.deleteQuestionCategory(categoryID)
			.then((res) => {
				commit("setLoading", false);
				return true;
			});
		return response;
	},

	async fetchExamThemes({ commit }, themeID) {
		commit("setLoading", true);
		var response = await examService.fetchExamThemes(themeID).then((res) => {
			commit("setLoading", false);
			commit("setExamThemes", res?.payload);
			return res?.payload;
		});
		return response;
	},
};

const mutations = {
	setLoading(state, data) {
		if (data) {
			console.log("exam loading...");
		} else {
			console.log("exam loading complete!");
		}
		state.loading = data;
	},
	setQuestions(state, data) {
		console.log(data, "in mutation");
		data.questions.forEach((q) => {
			var found = state.allQuestions.find((question) => {
				if (question.ID == q.ID) {
					return question;
				}
			});
			if (!found) {
				state.allQuestions = [...state.allQuestions, q];
			}
		});
		if (data.categoryID) state.totalPages[data.categoryID] = data.totalPages;
		else if (data.productVariantID) {
			state.totalPages[data.productVariantID] = data.totalPages;
		}
	},
	removeQuestion(state, id) {
		state.allQuestions = state.allQuestions.filter((q) => q.id !== id);
		this.loading = false;
	},
	addQuestionCategory(state, data) {
		console.log(data);
		if (
			data.categoryID &&
			data.categoryID != state.questionCategories[data.productVariantID].root.id
		) {
			state.questionCategories = {
				...state.questionCategories,
				[data.productVariantID]: {
					...state.questionCategories[data.productVariantID],
					[data.categoryID]: data.category,
				},
			};

			if (
				data.category.parentID &&
				data.category.parentID !=
					state.questionCategories[data.productVariantID].root.id
			) {
				state.questionCategories = {
					...state.questionCategories,
					[data.productVariantID]: {
						...state.questionCategories[data.productVariantID],
						[data.category.parentID]: {
							...state.questionCategories[data.productVariantID][
								data.category.parentID
							],
							childCategories: state.questionCategories[data.productVariantID][
								data.category.parentID
							].childCategories.map((cat) => {
								if (cat.id == data.category.id) {
									return data.category;
								} else {
									return cat;
								}
							}),
						},
					},
				};
			}
		} else if (
			data.categoryID &&
			data.categoryID == state.questionCategories[data.productVariantID].root.id
		) {
			state.questionCategories = {
				...state.questionCategories,
				[data.productVariantID]: {
					...state.questionCategories[data.productVariantID],
					root: data.category,
				},
			};
		} else if (data.productVariantID) {
			state.questionCategories = {
				...state.questionCategories,
				[data.productVariantID]: {
					...state[data.productVariantID],
					root: data.category,
				},
			};
		}

		// data.forEach((c) => {
		// 	var found = state.questionCategories.find((category) => {
		// 		if (category.categoryID == c.categoryID) {
		// 			return category;
		// 		}
		// 	});
		// 	if (!found) {
		// 		state.questionCategories = [...state.questionCategories, c];
		// 	}
		// });
	},
	setExamThemes(state, data) {
		data.forEach((t) => {
			var found = state.examThemes.find((theme) => {
				if (theme.themeID == t.themeID) {
					return theme;
				}
			});
			if (!found) {
				state.examThemes = [...state.examThemes, t];
			}
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
