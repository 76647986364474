<template>
	<div class="about-product-modal">
		<div class="about-product-modal-header">
			<a role="button" @click="$emit('hideModal')" class="close-btn"
				><i class="fa fa-times"></i
			></a>
			<h1 class="top-card-heading">What you'll learn</h1>
		</div>
		<div class="modal-body-container">
			<div
				v-for="(item, index) in listData"
				:key="index"
				class="top-card-list-item"
			>
				<i class="fas fa-check"></i> {{ item }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProductDetailsModal",
	props: { listData: Array },
};
</script>

<style scoped>

.about-product-modal {
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	transition: all 0.5s ease-in-out;
}

.about-product-modal-header {
	padding: 1.75rem 1.75rem 1.25rem;
	box-shadow: 6px 3px 6px #b9b9b929;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.close-btn {
	margin-right: 2rem;
	font-size: 1.75rem;
	color: #000000;
	font-weight: 300;
}

.modal-body-container {
	padding: 1.5rem 1.25rem;
}

.top-card-heading {
	font-size: 1.875rem;
	color: #000000;
	font-weight: bold;
	margin-bottom: 0;
	line-height: 1;
	display: inline-block;
}

.top-card-heading::before {
	content: "";
	width: 1.75rem;
	height: 0.3rem;
	background-color: var(--primary-color);
	display: block;
	top: -0.75rem;
	left: 0;
	position: relative;
}

.top-card-list {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
}

.top-card-list-item {
	padding: 0.625rem;
	display: flex;
	font-size: 1.125rem;
}

.top-card-list-item i {
	margin-right: 1rem;
	margin-top: 0.325rem;
	font-size: 1rem;
}
</style>
