<template>
	<div>
		<div class="gradientImage">
			<div class="container flex-row">
				<img
					src="./Web Assets/Group 3971.png"
					alt=""
					class="gradientInsideImage"
				/>

				<img src="./Web Assets/HZE7F6.tif.png" alt="" class="arrowImage" />
				<div class="gradient-right-div">
					<p class="become-instructor-text">
						<strong
							>Become an
							<span style="background-color: #fdb12a; padding: 3px 0">
								Instructor</span
							>
							today</strong
						>
					</p>
					<p class="become-instructor-after-text">
						Teach what you love we gives you the tools to <br />Create an online
						courses.
					</p>
					<button class="gradientButton">
						Click here and start teaching today
					</button>
				</div>
			</div>
		</div>

		<br />
		<br />
		<br />

		<div class="container featureBoxContainer">
			<div class="featureBox">
				<img
					src="./Web Assets/Group 3957.svg"
					alt=""
					style="width: 150px; height: 130px"
				/>
				<p style="font-size: 20px">
					<strong>Teach us way</strong>
				</p>
				<p style="font-size: 15px">
					Publish the course you want,<br />
					in the way you want, and<br />
					always have of control your<br />
					own content
				</p>
			</div>

			<div class="featureBox">
				<img
					src="./Web Assets/Group 3959.svg"
					alt=""
					style="width: 150px; height: 130px"
				/>
				<p style="font-size: 20px">
					<strong>Inspire learners</strong>
				</p>
				<p style="font-size: 15px">
					Teach what you know and help<br />
					learners explore their interest,<br />
					gain new skills, and advance<br />
					their career.
				</p>
			</div>

			<div class="featureBox">
				<img
					src="./Web Assets/Group 3960.svg"
					alt=""
					style="width: 150px; height: 130px"
				/>
				<p style="font-size: 20px">
					<strong>Get Awarded</strong>
				</p>
				<p style="font-size: 15px">
					Expand your professional<br />
					networks, build your<br />
					expertise, and earn money on <br />
					each paid enrollment.
				</p>
			</div>
		</div>
		<br />
		<div class="container counterContainer">
			<div class="headCounterText">
				<p class="counterText"><strong>04M</strong></p>
				<p class="counterTextBody">Students</p>
			</div>
			<div class="headCounterText">
				<p class="counterText"><strong>65+</strong></p>
				<p class="counterTextBody">Languages</p>
			</div>
			<div class="headCounterText">
				<p class="counterText"><strong>480M</strong></p>
				<p class="counterTextBody">Enrollments</p>
			</div>
			<div class="headCounterText">
				<p class="counterText"><strong>180+</strong></p>
				<p class="counterTextBody">Countries</p>
			</div>
			<div class="headCounterText">
				<p class="counterText">
					<strong>7000+</strong>
				</p>
				<p class="counterTextBody">Enterprise customers</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TeachWithUs",
	data() {
		return {};
	},
	created() {},
};
</script>
