<template>
	<b-modal
		@change="closeModal"
		id="add-exam-question-modal"
		:visible="visible"
		size="xl"
		hide-header
		hide-footer
		centered
		content-class="add-exam-question-modal"
		body-class="modal-body"
		modal-class="custom-modal"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div class="custom-stepper-body-container">
			<button @click="closeModal" class="close-button" role="button">
				<img src="@/assets/images/cancel-icon.svg" />
			</button>
			<h1 class="add-content-title">Add Exam Question</h1>
			<div class="add-question-container">
				<div class="inline-group mb-1">
					<div
						v-if="
							examQuestionType == ExamQuestionType.QUESTION &&
							examQuestionSequence
						"
						class="form-group w-100"
					>
						<label for="selectedQuestions" class="main-label mb-3">
							Select Question
						</label>
						<div class="d-flex select-question-container">
							<CategoriesColumn
								@selectCategory="selectCategory"
								:selectedCategory="searchCategory"
								:productVariantId="productVariantId"
								:selectMode="true"
							/>
							<QuestionsColumn
								:selectedCategory="searchCategory"
								:productVariantId="productVariantId"
								:selectMode="true"
								@selectQuestions="selectQuestions"
							/>
						</div>
					</div>
					<div
						v-if="
							examQuestionType == ExamQuestionType.CRITERIA &&
							examQuestionSequence
						"
						class="form-group col mb-0"
					>
						<label for="examCriteriaSelected" class="main-label mb-3"
							>Select Criteria
						</label>
						<div
							v-for="criteria in examCriteriaOptions"
							:key="criteria.value"
							class="exam-criteria-selectable-item"
						>
							{{ criteria.label }}
							<div
								v-if="criteria.value == ExamCriteria.CATEGORY"
								class="col-6 ms-auto"
							>
								<treeselect
									:multiple="true"
									:options="categoryOptions"
									:load-options="loadOptions"
									:placeholder="`Select ${criteria.label}`"
									v-model="$data[criteria.value + 'Criteria']"
									:flat="true"
									sort-value-by="INDEX"
									class="form-input small"
									:appendToBody="true"
									z-index="1060"
									searchNested
									autoFocus
									@select="optionSelect"
								/>
							</div>

							<div v-else class="col-6 ms-auto">
								<v-select
									v-model="$data[criteria.value + 'Criteria']"
									:name="`${criteria.value}Criteria`"
									:id="`${criteria.value}Criteria`"
									class="form-input small"
									:options="criteriaOptions(criteria.value)"
									:appendToBody="true"
									:calculate-position="withPopper"
									:placeholder="`Select ${criteria.label}`"
									multiple
									:closeOnSelect="false"
									:clearable="true"
									:deselectFromDropdown="true"
									:label="criteriaLabel(criteria.value)"
									:reduce="(option) => criteriaValue(option, criteria.value)"
									:taggable="criteria.value == ExamCriteria.MARKS_RANGE"
								></v-select>
							</div>
						</div>
						<div class="d-flex gap-1 flex-row align-items-start mt-4 col-6">
							<span class="col-6">Number of questions to generate:</span>
							<input
								type="number"
								name="numberOfQuestions"
								id="numberOfQuestions"
								class="form-input small"
								v-model="numberOfQuestions"
							/>
							<div class="col-5"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="step-btn-container">
				<a @click="closeModal" role="button" class="step-btn">Cancel </a>
				<button
					@click="next"
					role="button"
					class="step-btn"
					:disabled="disableNext || loading"
				>
					<b-spinner v-if="loading" small></b-spinner>
					{{ isEditMode ? "Save" : "Add" }}
					<i class="fas fa-long-arrow-alt-right"></i>
				</button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { mapActions, mapState } from "vuex";
import {
	ExamQuestionType,
	examQuestionTypeOptions,
	ExamCriteria,
	examCriteriaOptions,
} from "../../../constants/exam";
import {
	QuestionDifficultyLevel,
	questionDifficultyLevelOptions,
} from "../../../constants/question";
import QuestionsColumn from "../QuestionBank/QuestionsColumn.vue";
import CategoriesColumn from "../QuestionBank/CategoriesColumn.vue";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LOAD_CHILDREN_OPTIONS } from "@riophae/vue-treeselect";
import store from "../../../store/index";

export default {
	name: "AddExamQuestionModal",
	data() {
		return {
			examCriteriaSelected: [],
			CategoryIDsCriteria: [],
			DifficultyLevelsCriteria: [],
			MarksRangeCriteria: [],
			QuestionTypesCriteria: [],
			questionSearchText: "",
			searchCategory: [],
			selectedQuestions: null,
			numberOfQuestions: 1,
			ExamQuestionType,
			ExamCriteria,
			QuestionDifficultyLevel,
			examQuestionTypeOptions,
			examCriteriaOptions,
			questionDifficultyLevelOptions,
			categoryOptions: [],
		};
	},
	computed: {
		...mapState("exam", [
			"loading",
			"questionCategories",
			"allQuestions",
			"questionTypes",
		]),
		visible() {
			return this.showModal;
		},
		disableNext() {
			if (this.examQuestionType == ExamQuestionType.CRITERIA) {
				var disabled = false;
				if (
					(this.CategoryIDsCriteria && this.CategoryIDsCriteria.length > 0) ||
					(this.MarksRangeCriteria && this.MarksRangeCriteria.length > 0) ||
					(this.QuestionTypesCriteria &&
						this.QuestionTypesCriteria.length > 0) ||
					(this.DifficultyLevelsCriteria &&
						this.DifficultyLevelsCriteria.length > 0)
				) {
					disabled = false;
				} else disabled = true;
				if (this.numberOfQuestions < 1) {
					disabled = true;
				}
				return disabled;
				// } else return true;
			} else if (this.examQuestionType == ExamQuestionType.QUESTION) {
				if (this.selectedQuestions == null) {
					return true;
				} else return false;
			}
		},
		isCriteria() {
			return (
				this.examQuestionType &&
				this.examQuestionType == ExamQuestionType.CRITERIA
			);
		},
		marksRangeOptions() {
			return [1, 2, 3, 4, 5, 10, 15, 20, 25];
		},
	},
	components: { QuestionsColumn, CategoriesColumn, Treeselect },
	props: {
		showModal: Boolean,
		examQuestionSequence: Number,
		isEditMode: { type: Boolean, default: false },
		questionData: { type: Object, default: null },
		productVariantId: String,
		allowedQuestionTypes: Array,
		examQuestionType: { type: String, default: ExamQuestionType.QUESTION },
	},
	methods: {
		...mapActions("exam", ["fetchQuestionCategories", "fetchQuestions"]),
		closeModal() {
			Object.assign(this.$data, this.$options.data.call(this));
			this.$emit("updateModalState", false);
		},
		criteriaOptions(criteria) {
			switch (criteria) {
				case ExamCriteria.DIFFICULTY_LEVEL:
					return this.questionDifficultyLevelOptions;
				case ExamCriteria.MARKS_RANGE:
					return this.marksRangeOptions;
				case ExamCriteria.QUESTION_TYPE:
					return this.allowedQuestionTypes;
			}
		},
		criteriaLabel(criteria) {
			switch (criteria) {
				case ExamCriteria.CATEGORY:
					return "categoryName";
				case ExamCriteria.DIFFICULTY_LEVEL:
					return "label";
				case ExamCriteria.QUESTION_TYPE:
					return "questionTypeName";
			}
		},
		criteriaValue(option, criteria) {
			switch (criteria) {
				case ExamCriteria.CATEGORY:
					return option.id;
				case ExamCriteria.DIFFICULTY_LEVEL:
					return option.value;
				case ExamCriteria.QUESTION_TYPE:
					return option.questionTypeID;
				default:
					return option;
			}
		},
		isDisabled(criteria) {
			return (
				this.examCriteriaSelected &&
				this.examCriteriaSelected.indexOf(criteria) == -1
			);
		},
		next() {
			var examQuestionList = [];
			if (this.examQuestionType == ExamQuestionType.QUESTION) {
				examQuestionList = this.selectedQuestions.map((question, index) => {
					return {
						ExamQuestionType: this.examQuestionType,
						QuestionSequenceNo: this.examQuestionSequence + index,
						Question: {
							QuestionID: question,
						},
					};
				});
			} else if (this.examQuestionType == ExamQuestionType.CRITERIA) {
				var obj = {
					ExamQuestionType: this.examQuestionType,
					QuestionSequenceNo: this.examQuestionSequence,
					NoOfQuestions: this.numberOfQuestions,
				};

				obj.Criteria = {};
				if (this.CategoryIDsCriteria && this.CategoryIDsCriteria.length > 0) {
					obj.Criteria.CategoryIDs = this.CategoryIDsCriteria.toString();
				}
				if (
					this.DifficultyLevelsCriteria &&
					this.DifficultyLevelsCriteria.length > 0
				) {
					obj.Criteria.DifficultyLevels =
						this.DifficultyLevelsCriteria.toString();
				}
				if (this.MarksRangeCriteria && this.MarksRangeCriteria.length > 0) {
					obj.Criteria.MarksRange = this.MarksRangeCriteria.toString();
				}
				if (
					this.QuestionTypesCriteria &&
					this.QuestionTypesCriteria.length > 0
				) {
					obj.Criteria.QuestionTypes = this.QuestionTypesCriteria.toString();
				}
				examQuestionList.push(obj);
			}
			console.log(examQuestionList);
			this.$emit("addExamQuestions", examQuestionList);
			this.closeModal();
		},
		withPopper(dropdownList, component, { width }) {
			dropdownList.style.width = width;
			const popper = createPopper(component.$refs.toggle, dropdownList);

			return () => popper.destroy();
		},
		selectCategory(category) {
			this.searchCategory = category;
		},
		selectQuestions(questions) {
			this.selectedQuestions = questions;
		},
		loadOptions({ action, parentNode, callback }) {
			console.log(action, parentNode);
			if (action === LOAD_CHILDREN_OPTIONS) {
				this.fetchQuestionCategories({
					productVariantID: this.productVariantId,
					categoryID: parentNode.id,
				}).then((res) => {
					if (res) {
						parentNode.children = res.childCategories.map((c) => ({
							id: c.id,
							label: c.categoryName,
							children:
								c.childCategories && c.childCategories.length > 0
									? c.childCategories.map((cc) => ({
											id: cc.id,
											label: cc.categoryName,
											children:
												cc.childCategories && cc.childCategories.length > 0
													? cc.childCategories
													: null,
									  }))
									: null,
						}));
						console.log(parentNode.children);
						callback();
					}
				});
			}
		},
		optionSelect(node) {
			if (!this.questionCategories[this.productVariantId][node.id]) {
				this.fetchQuestionCategories({
					categoryID: node.id,
					productVariantID: this.productVariantId,
				});
			}
		},
	},
	watch: {
		visible: function () {
			if (this.visible) {
				if (
					!this.questionCategories[this.productVariantId] ||
					!this.questionCategories[this.productVariantId].root
				) {
					this.fetchQuestionCategories({
						productVariantID: this.productVariantId,
					}).then((res) => {
						if (res) {
							this.categoryOptions = res.childCategories.map((c) => {
								return {
									id: c.id,
									label: c.categoryName,
									children:
										c.childCategories && c.childCategories.length > 0
											? c.childCategories.map((cc) => ({
													id: cc.id,
													label: cc.categoryName,
													children:
														cc.childCategories && cc.childCategories.length > 0
															? cc.childCategories
															: null,
											  }))
											: null,
								};
							});
							this.fetchQuestions({ productVariantID: this.productVariantId });
						}
					});
				} else {
					console.log("visible");
					this.categoryOptions = this.questionCategories[
						this.productVariantId
					].root.childCategories.map((c) => {
						return {
							id: c.id,
							label: c.categoryName,
							children:
								c.childCategories && c.childCategories.length > 0
									? c.childCategories.map((cc) => ({
											id: cc.id,
											label: cc.categoryName,
											children:
												cc.childCategories && cc.childCategories.length > 0
													? cc.childCategories
													: null,
									  }))
									: null,
						};
					});
				}
			}
			if (this.visible && this.isEditMode) {
				this.examQuestionSequence = this.questionData.QuestionSequenceNo;
				if (this.examQuestionType == ExamQuestionType.QUESTION) {
					this.selectedQuestions = this.questionData.Question.QuestionID;
				} else if (this.examQuestionType == ExamQuestionType.CRITERIA) {
					this.numberOfQuestions = this.questionData.NoOfQuestions;
					Object.entries(this.questionData.Criteria).forEach(([key, value]) => {
						this.examCriteriaSelected.push(key);
						if (key == ExamCriteria.CATEGORY) {
							this[key + "Criteria"] = value
								.split(",")
								.map((id) => parseInt(id));
						} else if (key == ExamCriteria.MARKS_RANGE) {
							this[key + "Criteria"] = value
								.split(",")
								.map((id) => parseFloat(id));
						} else this[key + "Criteria"] = value.split(",");
					});
				}
			}
		},
	},
};
</script>

<style>
.add-exam-question-modal {
	min-height: 85vh;
	height: fit-content;
	width: 70vw;
}
#add-exam-question-modal.custom-modal .modal-dialog {
	max-width: 70vw;
}

.add-exam-question-modal .modal-body {
	display: flex;
	flex-direction: row;
	padding: 0 !important;
	font-size: 1rem;
}

.add-exam-question-modal .modal-body .custom-stepper-container {
	background: #f5f5f5;
	border-radius: 8px 0px 0px 8px;
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.add-exam-question-modal .modal-body .custom-stepper-container .custom-stepper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	row-gap: 7.5rem;
}

.add-exam-question-modal .modal-body .custom-stepper-body-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 2.5rem 2.5rem 1.5rem;
	position: relative;
	border-radius: 8px;
}

.add-exam-question-modal .add-question-container {
	margin-top: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.exam-question-list {
	display: flex;
	flex-direction: column;
	margin: 1rem -1rem 0;
	padding: 0 1rem 1rem;
	max-height: 50vh;
	overflow-y: auto;
	row-gap: 1rem;
	overflow-y: auto;
}

.exam-question-selectable-item .form-check {
	display: flex;
	align-items: center;
	column-gap: 1rem;
	margin: 0;
}

.exam-criteria-selectable-item {
	/* border: 1px solid #ddd; */
	padding: 0.5rem 1rem;
	/* margin-bottom: 0.5rem; */
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	column-gap: 1rem;
}

.question-search .search-icon {
	position: absolute;
	margin-right: 1rem;
	right: 0;
	width: 1.375rem;
	bottom: 2.25rem;
}

.add-exam-question-modal .form-input {
	max-height: 4rem !important;
}

.add-exam-question-modal .select-question-container {
	height: 58vh;
	border: 1px solid #ddd;
	border-radius: 6px;
}

.add-exam-question-modal .vue-treeselect__control {
	border: none;
}
.add-exam-question-modal .vue-treeselect__value-remove svg {
	color: #999;
	height: 0.675rem;
	width: 0.675rem;
}

.add-exam-question-modal .vue-treeselect__value-remove {
	padding-left: 0.375rem !important;
	border: 0;
}

.add-exam-question-modal .vue-treeselect__multi-value-item {
	display: flex;
	align-items: center;
	background-color: #f0f0f0;
	border: 1px solid rgba(60, 60, 60, 0.26);
	border-radius: 4px;
	color: #333;
	line-height: 1.4;
	margin: 4px 2px 0;
	padding: 0 0.25em;
	z-index: 0;
	font-size: 1rem;
}

.add-exam-question-modal .vue-treeselect__value-remove,
.vue-treeselect__multi-value-label {
	padding: 0;
}
</style>
