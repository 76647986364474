<template>
	<div class="dashboard-forum-container">
		<div class="d-flex justify-content-between align-items-center">
			<div class="forum-search">
				<input
					type="search"
					class="border-input forum-search"
					placeholder="Search"
				/>
				<i class="fas fa-search search-icon"></i>
			</div>
			<!-- <select name="ratings" id="ratings" class="border-input flex-grow-1">
				<option value="all">All Rating</option>
			</select> -->
			<a type="button" class="border-input start-conversation-button"
				><i class="far fa-edit button-icon"></i> Start Conversation</a
			>
		</div>
		<!-- <NewPost /> -->
		<div class="forum-post-list">
			<div
				class="forum-post-item with-threadline"
				v-for="item in 4"
				:key="item"
			>
				<div class="post-content">
					<div class="user-avatar">
						<span>A</span>
					</div>
					<div class="d-flex flex-column">
						<p class="creator-name">Alber Afzal Khan</p>
						<span class="post-time">30 minutes ago</span>
						<h5 class="post-heading">
							How was the Meetups, Study Groups, and Lecture Screenings ?
						</h5>
						<div class="post-text">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
							eiusmod tempor incididunt ut labore et dolore magna aliqua.….
						</div>
						<div class="post-buttons-container">
							<a role="button" class="post-btn"
								><i class="fas fa-thumbs-up post-btn-icon"></i> Likes 23</a
							>
							<a role="button" class="post-btn"
								><i class="far fa-comment post-btn-icon"></i> Reply 32</a
							>
							<span class="last-comment"
								>Last comment: replied 3 months ago</span
							>
						</div>
						<div class="post-reply-item">
							<div class="post-reply-content">
								<div class="user-avatar avatar-answer">
									<span>H</span>
								</div>
								<div class="d-flex flex-column">
									<div class="answer-tag">
										<i class="fas fa-check answer-tag-icon"></i> Answer
									</div>
									<p class="creator-name">Hamza Abdul Haq</p>
									<span class="post-time">30 minutes ago</span>
									<div class="post-text mt-2 mb-0">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod tempor incididunt ut labore et dolore magna
										aliqua.….
									</div>
								</div>
							</div>
						</div>
						<a role="button" class="view-more-comments">
							View 10 more 876 comments
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NewPost from "../Forum/NewPost.vue";
export default {
	name: "Forum",
	components: { NewPost },
};
</script>

<style>
@media screen and (min-width: 1440px) {
	.dashboard-forum-container {
		width: 70% !important;
	}
}

@media screen and (max-width: 996px) {
	.dashboard-forum-container {
		width: 100% !important;
	}
}

.dashboard-forum-container {
	width: 65%;
	padding: 2rem 1.5rem 2rem 0;
}

.forum-search {
	display: flex;
	align-items: center;
	position: relative;
	flex-grow: 4;
	margin: 0 !important;
}

.dashboard-forum-container .border-input {
	min-width: unset !important;
	padding: 1.125rem 1rem;
	font-size: 1rem;
	/* font-weight: 500 !important; */
	border-radius: 6px;
	line-height: 1;
}

.button-icon {
	font-size: 1rem;
}

.start-conversation-button {
	border-color: var(--color-dark-gray) !important;
	background-color: var(--color-off-white) !important;
	text-decoration: none !important;
	box-shadow: 0 3px 6px #88888829;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 12px;
	right: 0;
}

.forum-post-list {
	margin-top: 2rem;
}

.forum-post-item {
	border-radius: 6px;
	border: 1px solid #a7a9ac;
	background-color: #f9f9f9;
	opacity: 0.8;
	padding: 1.6rem 1.5rem;
	position: relative;
	margin-bottom: 2.125rem;
}

.post-content {
	display: flex;
	flex-direction: row;
	column-gap: 1.2rem;
}

.post-reply-content {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;
}

.user-avatar {
	width: 2.3rem;
	height: 2.3rem;
	color: var(--color-white);
	background-color: #231f20;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2.3rem;
	z-index: 10;
}

.avatar-answer {
	border: 3px solid #1dc321;
	margin-top: 0.5rem;
}

.with-threadline::before {
	content: " ";
	width: 3px;
	background-color: #ccc;
	height: calc(100% - 7rem);
	position: absolute;
	top: 3rem;
	left: 2.5rem;
}

.answer-tag {
	font-size: 0.625rem;
	background-color: #1dc321;
	border-radius: 3px;
	padding: 0.175rem 0.25rem;
	color: #fafafa;
	align-self: flex-start;
	line-height: 1;
}

.answer-tag-icon {
	font-size: 0.5rem;
}

.creator-name {
	font-size: 1.125rem;
	line-height: 1;
	font-weight: 500;
	margin-bottom: 4px;
	margin-top: 6px;
	color: #000;
}

.post-time {
	font-size: 0.75rem;
	color: #959595;
}

.post-heading {
	color: #000;
	margin-top: 1rem;
	margin-bottom: 0.8rem;
	/* font-size: 1.375rem; */
}

.post-text {
	overflow: hidden;
	text-overflow: ellipsis;
	color: #7c7c7c;
	font-weight: 450;
	font-size: 0.9rem;
	margin-bottom: 1rem;
}

.post-btn {
	padding: 0.3rem 0.6rem !important;
	font-size: 0.625rem !important;
	color: #000 !important;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	background-color: transparent !important;
	border: 1px solid var(--color-gray);
	transition: all 0.3s ease-in-out;
	border-radius: 4px;
	font-weight: 450;
	margin-right: 1rem;
}

.post-btn:hover {
	background-color: var(--color-light-blue) !important;
	color: var(--color-navy) !important;
}

.post-btn-icon {
	font-size: 0.8rem;
}

.last-comment {
	font-size: 0.75rem;
	margin: 0;
	font-weight: 500;
}

.post-reply-item {
	background-color: #f0f0f0;
	/* height: 100px; */
	border-radius: 19px;
	margin-top: 1.5rem;
	padding: 1.25rem;
	position: relative;
}

.post-reply-item::before {
	content: "";
	width: 5rem;
	height: 2rem;
	border-left: 3px solid #ccc;
	border-bottom: 3px solid #ccc;
	position: absolute;
	top: 2rem;
	left: -2.5rem;
	border-radius: 0 18px;
	z-index: -1;
}

.view-more-comments {
	margin-top: 1.5rem;
	text-decoration: underline;
	color: #000;
	font-size: 0.875rem;
	padding-left: 1.5rem;
	font-weight: 500;
	align-self: flex-start;
	position: relative;
	background-color: #f9f9f9;
}

.view-more-comments::before {
	content: "";
	width: 5rem;
	height: 2rem;
	border-left: 3px solid #ccc;
	border-bottom: 3px solid #ccc;
	position: absolute;
	top: -1.25rem;
	left: -2.5rem;
	border-radius: 0 18px;
	z-index: -1;
}
</style>
