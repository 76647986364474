import Vue from "vue";
import helpers from "../helpers/CookieHelpers";
import { supportService } from "../services/support.service";
const user = JSON.parse(localStorage.getItem("user"));
const isToken = helpers.checkCookie("token");

const state = { loading: false, tickets: null, ticketServices: null };

const getters = {
  allTickets: (state) => {
		return state.tickets;
	},
};

const actions = {
  async fetchTickets({ commit },filter) {
    commit("setLoading", true);
    if(!filter)
    {
      filter={
        serviceID:null,
        status:null
      };
    }
    const data = await supportService.fetchTickets(filter.serviceID,filter.status).then(
      (res) => {
        var data = res.payload;
        commit("setTickets", data);
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async fetchTicketByID({ commit },id) {
    commit("setLoading", true);
    const data = await supportService.fetchTicketByID(id).then(
      (res) => {
        var data = res.payload;       
        commit("setTicket", data);
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async fetchTicketReplies({ commit },id) {
    commit("setLoading", true);
    const data = await supportService.fetchTicketReplies(id).then(
      (res) => {
        var data = res.payload;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async addTicketReply({ commit },supportTicketReply) {
    commit("setLoading", true);
    const data = await supportService.addSupportTicketReply(supportTicketReply).then(
      (res) => {
        var data = res.payload;
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  
  async closeSupportTicket({ commit },supportTicketID) {
    commit("setLoading", true);
    debugger;
    const data = await supportService.closedSupportTicket(supportTicketID).then(
      (res) => {
        var data = res.payload;    
        commit("setTicket", data);
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async fetchTicketServices({ commit }) {
    commit("setLoading", true);
    const data = await supportService.fetchTicketServices().then(
      (res) => {
        var data = res.payload;
        commit("setTicketServices", data);
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
   createSupportTicket: async ({ commit }, supportTicket ) => {
   	commit("setLoading", true);
		const data = await supportService.createSupportTicket(supportTicket).then(
			(res) => {
				var data = res?.payload;
        debugger;
				return data;
			},
			(error) => {
				//alert(error);
				Vue.toasted.error(error, {
					icon: "exclamation-triangle",
					theme: "outline",
					position: "bottom-center",
					duration: 3000,
				});
				commit("setLoading", false);
			}
		);
    return data;
	},

};

const mutations = {
  setLoading(state, data) {
    if (data) {
      console.log("Tickets loading...");
    } else {
      console.log("Tickets loading complete!");
    }
    state.loading = data;
  },
  
  setTickets(state, data) {
    state.tickets = data;
  },
  
  setTicket(state, data) {
    var ticket=state.tickets.find(x=>x.id==data.id);
    if(ticket)
    {
      ticket.closedAt=data.closedAt;
      ticket.closedBy=data.closedBy;
      ticket.lastTicketReply = data.lastTicketReply;
      ticket.status = data.status;
    }
  },
  setTicketServices(state, data) {
    state.ticketServices = data;
  },
};

function handleError(error) {
  // console.log(error);
  // Vue.toasted.error(error.message, {
  //   icon: "exclamation-triangle",
  //   theme: "outline",
  //   position: "bottom-center",
  //   duration: 3000,
  // });
  return Promise.reject(error);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
