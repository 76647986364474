<template>
	<div class="instructor-list-item cursor-pointer" @click="redirectToProfile">
		<div>
			<img class="instructor-image" :src="
			instructorData.image_path && !isThumbnailError
				? instructorData.image_path
				: require('@/assets/images/user_img_placeholder.jpg')
				" @error="
				() => {
					isThumbnailError = true;
				}
			" />
		</div>
		<div>
			<span class="instructor-position">Instructor</span>
		</div>
		<div>
			<p class="instructor-name" :title="instructorData.name">
				{{ instructorData.name.slice(0, 32) }}{{ instructorData.name.length > 32 ? "..." : "" }}
			</p>
		</div>
		<div class="instructor-about-container">
			<!-- <div class="instructor-about-item">
				<span class="instructor-about-item-info">
					{{ instructorData.ratings.value }}
				</span>
				<span class="instructor-about-item-label"> Ratings </span>
			</div> -->
			<!-- <div class="instructor-about-item center">
				<span class="instructor-about-item-info">
					{{ instructorData.ratings.number }}
				</span>
				<span class="instructor-about-item-label"> Reviews </span>
			</div> -->
			<div class="instructor-about-item">
				<span class="instructor-about-item-info">
					{{ instructorData.courses.length ? instructorData.courses.length : "0" }}
				</span>
				<span class="instructor-about-item-label"> Courses </span>
			</div>
		</div>
		<div>
			<p :title="instructorData.short_description" class="instructor-short-description">
				{{ instructorData.short_description.slice(0, 50)
				}}{{ instructorData.short_description.length > 50 ? "..." : "" }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "InstructorListItem",
	data() {
		return {
			isThumbnailError: false,
		};
	},
	methods: {
		redirectToProfile() {
			this.$router.push({
				path: '/instructor/profile',
				query:{
					'trainersid':this.instructorData.id
				}
			});
		},	
	},
	props: { instructorData: Object },
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.instructor-list-item {
		padding: 0 1.25rem 2.25rem !important;
	}
}

@media screen and (max-width: 480px) {
	.instructor-list-item {
		padding: 0 2rem 2.25rem !important;
	}
}

.instructor-list-item {
	background-color: #ffffff;
	border-radius: 10px;
	background-color: #fafafa;
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 0 1.5rem 2.25rem;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	overflow: visible;
	height: 28rem;
}

.instructor-list-item .instructor-image {
	width: 9.75rem;
	height: 9.75rem;
	border-radius: 50%;
	margin-top: 10px;
	margin-bottom: 1.25rem;
	box-shadow: 0 1px 16px #a2a0a029;
	object-fit: cover;
}

.instructor-position {
	color: #959595;
	font-size: 1rem;
	line-height: 1.375rem;
}

.instructor-name {
	font-size: 1.125rem;
	margin-bottom: 1.25rem;
}

.instructor-about-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.instructor-about-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.instructor-about-item.center {
	padding: 0 1.125rem;
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
}

.instructor-about-item-info {
	font-weight: bold;
	/* margin-bottom: 0.25rem; */
}

.instructor-about-item-label {
	color: #959595;
	font-size: 0.875rem;
}

.instructor-short-description {
	text-align: center;
	color: #959595;
	letter-spacing: 0.42px;
	font-size: 0.875rem;
	margin-top: 1.25rem;
	margin-bottom: 0;
}
</style>
