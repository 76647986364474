export const HEADER_DASHBOARD = "Dashboard";
export const HEADER_LOGIN = "Login";
export const HEADER_REGISTER = "Register";
export const HEADER_COURSES = "Courses";
export const HEADER_PROFILE = "Profile";
export const HEADER_LOGOUT = "Logout";
export const DIRECTORY = "Directory";
export const RECORDED_VIDEO = "RecordedVideos";
export const LIVE_CLASS = "LiveClass";
export const ASSIGNMENT = "Assignment";
export const STUDY_MATERIAL = "StudyMaterial";
// export const LIVE_CLASS = "Profile";
// export const HEADER_PROFILE = "Profile";
// export const HEADER_PROFILE = "Profile";
