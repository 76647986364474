<template>
	<div class="banner-small">
		<div class="container d-flex">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "BannerSmall",
};
</script>

<style scoped>
.banner-small {
	padding: 0;
	background-color: #ebebeb;
	color: #231f20;
	/* font-size: 2.5rem; */
	font-weight: 500;
}
</style>
