<template>
	<b-modal
		id="profile-edit-modal"
		@change="closeModal"
		:visible="visible"
		hide-header
		centered
		content-class="profile-edit-modal"
		size="lg"
		body-class="modal-body"
		ok-title="Enroll"
		footer-class="edit-modal-footer"
		:ok-disabled="disabled"
		@ok="enrollCourse"
		no-close-on-backdrop
	>
		<div class="body-container">
			<h1 class="edit-modal-title mb-4">Select a Plan</h1>
			<div v-if="plansLoaded" class="plan-list">
				<ProductPricingPlanItem
					v-for="(plan, index) in filteredPricingPlans"
					:key="index"
					:plan="plan"
					:selectedPlan="selectedPlan"
					:productVariantId="productVariantData.id"
					@updatePlanSelection="selectPlan"
					@updateSelectedCycles="updateCycles"
				/>
				<div
					v-if="
						pricingPlanByProductVariantId[productVariantData.id] &&
						pricingPlanByProductVariantId[productVariantData.id].length == 0
					"
					class="no-content text-center"
				>
					Sorry! No plans found
				</div>
			</div>
			<div v-else>
				<b-skeleton
					class="width-100 mb-3"
					height="3rem"
					type="button"
				></b-skeleton>
				<b-skeleton
					class="width-100 mb-3"
					height="3rem"
					type="button"
				></b-skeleton>
				<b-skeleton
					class="width-100 mb-3"
					height="3rem"
					type="button"
				></b-skeleton>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatter from "../../helpers/FormatHelpers";
import router from "../../router";
import ProductPricingPlanItem from "./ProductPricingPlanItem.vue";
import Vue from "vue";

export default {
	name: "ProductPricingPlansModal",
	data() {
		return {
			selectedPlan: null,
			plansLoaded: false,
			source: {
				REGISTER: 0,
				CART: 1,
			},
			selectedCycles: [],
		};
	},
	props: { productVariantData: Object, showModal: Boolean },
	components: { ProductPricingPlanItem },
	computed: {
		...mapState("cart", ["cart"]),
		...mapState("pricing", ["pricingPlanByProductVariantId"]),
		...mapState("appState", ["currentCurrency", "currentCountry"]),
		disabled() {
			if (!this.selectedPlan) {
				return true;
			} else if (
				this.selectedPlan &&
				this.selectedPlan.type.toLowerCase() != "onetime" &&
				this.selectedCycles &&
				this.selectedCycles.length == 0
			) {
				return true;
			} else return false;
		},
		visible() {
			return this.showModal;
		},
		filteredPricingPlans() {
			if (
				this.productVariantData &&
				this.pricingPlanByProductVariantId[this.productVariantData.id]
			) {
				return this.pricingPlanByProductVariantId[
					this.productVariantData.id
				].filter(
					(plan) =>
						this.currentCountry &&
						plan.country &&
						plan.country.indexOf(this.currentCountry.toUpperCase()) != -1
				);
			} else {
				return [];
			}
		},
	},
	methods: {
		...mapActions("cart", ["addToCart"]),
		...mapActions("pricing", ["fetchPricingPlanByProductVariantId"]),
		enrollCourse() {
			var selectedPlanAmount = 0;
			// var invoiceDetail = [];
			if (this.selectedPlan.type.toLowerCase() == "onetime") {
				selectedPlanAmount = this.selectedPlan.details[0].amountDetail.find(
					(detail) => detail.title.toLowerCase() == "price"
				).amount;
			} else {
				this.selectedCycles.forEach((detail) => {
					selectedPlanAmount += detail.amountDetail.find(
						(d) => d.title.toLowerCase() == "price"
					).amount;
				});
				this.selectedPlan.details = this.selectedCycles;
			}
			var isCartItemsCurrenyIsSame = this.cart.items.find(val => val.pricing.currency == this.selectedPlan.currency) != null;

			if (this.cart.items.length > 0 && !isCartItemsCurrenyIsSame) {
				this.$toasted.error(
					"You can only add courses of one currency in your cart.",
					{
						icon: "exclamation-triangle",
						theme: "outline",
						position: "bottom-right",
						duration: 2000,
					});
				return;
			}
			this.productVariantData.selectedPlan = this.selectedPlan;
			this.productVariantData.pricing.amount = selectedPlanAmount;
			this.productVariantData.pricing.currency = this.selectedPlan.currency;
			this.addToCart(this.productVariantData);

			if (this.caller == this.source.CART) {
				router.push("/shop/checkout");
			} else {
				this.$emit("updateModalState", false);
			}
		},
		addInvoiceDetail(details) {
			console.log(details);
			details.forEach((detail) => {
				var found = this.invoiceDetails.find(
					(invoice) => invoice.planDetailID == detail.planDetailID
				);
				if (!found) {
					this.invoiceDetails = [...this.invoiceDetails, detail];
				} else {
					this.invoiceDetails = this.invoiceDetails.map((d) => {
						if (detail.planDetailID == d.planDetailID) {
							return detail;
						} else return d;
					});
				}
			});
		},
		paymentTypeText(plan) {
			return plan.type == "OneTime"
				? "Single Payment"
				: `${plan.details.length} Installments`;
		},
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
		closeModal() {
			this.$emit("updateModalState", false);
			Object.assign(this.$data, this.$options.data.call(this));
		},
		selectPlan(plan) {
			this.selectedPlan = { ...plan };
		},
		updateCycles(cycles) {
			this.selectedCycles = cycles;
		},
	},
	created() {},
	watch: {
		visible: function () {
			if (this.visible) {
				if (!this.pricingPlanByProductVariantId[this.productVariantData.id]) {
					this.fetchPricingPlanByProductVariantId(
						this.productVariantData.id
					).then(() => {
						if (
							this.pricingPlanByProductVariantId[this.productVariantData.id] &&
							this.pricingPlanByProductVariantId[this.productVariantData.id]
								.length > 0
						)
							this.selectedPlan = {
								...this.pricingPlanByProductVariantId[
									this.productVariantData.id
								][0],
							};
						this.plansLoaded = true;
					});
				} else {
					this.plansLoaded = true;
					if (
						this.pricingPlanByProductVariantId[this.productVariantData.id] &&
						this.pricingPlanByProductVariantId[this.productVariantData.id]
							.length > 0
					)
						this.selectedPlan = {
							...this.pricingPlanByProductVariantId[
								this.productVariantData.id
							][0],
						};
				}
			}
		},
		plansLoaded: function () {
			if (this.plansLoaded) {
				console.log("plans loaded");
				this.$nextTick(() => {
					const event = new CustomEvent("plans-loaded");
					document.dispatchEvent(event);
				});
			}
		},
	},
};
</script>

<style>
#profile-edit-modal .body-container {
	padding: 0 0 1rem;
	width: 100%;
}

.plan-list {
	max-height: 24rem;
	overflow-y: auto;
}

.no-content {
	font-size: 1.25rem;
}
</style>
