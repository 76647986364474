<template>
  <div class="ms-5">
    <h2 class="pt-5 pb-5">Ngenius (Debit/Credit Card)</h2>
    <h5 v-if="message">{{ message }}</h5>
    <h5 v-if="error" class="text-danger">{{ error }}</h5>

    <div v-if="!showOtpUI">
      <div style="text-align: center; font-size: 18px">
        <span style="color: red">Request Timer: </span
        >{{ minutes + ":" + seconds }}
      </div>

      <div style="text-align: center">
        <b-spinner type="grow" label="Spinning"></b-spinner>
      </div>

      <hr style="background-color: grey; margin-end: 35px" />

      <div class="payment-heading-div">
        <h3>Enter Payment Details</h3>
      </div>

      <div style="text-align: center">
        <div id="mount-id"></div>
      </div>

      <!-- <div v-if="showCheckoutBtn"> -->
      <button
        id="checkoutBtn"
        class="btn-block-rounded-primary mt-2"
        @click="createSession()"
        disabled
        style="margin: 0 auto; width: 10%"
      >
        Pay Now
        <b-spinner label="Spinning" class="ms-2" v-if="loading"></b-spinner>
      </button>
    </div>

    <div>
      <div id="3ds_iframe" style="height: 70vh"></div>
    </div>
  </div>
  <!-- </div> -->
</template>

<!-- Ngenius Payment Gateway SDK -->
<!-- <script src="https://paypage.sandbox.ngenius-payments.com/hosted-sessions/sdk.js"></script> -->
<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Ngenius",
  data() {
    return {
      message: undefined,
      error: undefined,
      showCheckoutBtn: false,
      isCredentialsCorrect: false,
      loading: false,
      minutes: 4,
      seconds: 30,
      showOtpUI: false,
      sessionId: null,
      orderReference: null,
      ngeniusCdnScriptUrl: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("pricing", ["invoicesByUserId", "loading"]),
    isPostPayment() {
      let action = this.$route.params.action;

      return action && action.toLowerCase() == "postPayment";
    },
  },
  methods: {
    ...mapActions("pricing", [
      "fetchInvoiceByUserId",
      "processPayment",
      "completePayment",
    ]),

    showCountdown(isEndCountDown) {
      let totalSeconds = this.minutes * 60 + this.seconds;

      const timer = setInterval(() => {
        if (isEndCountDown) {
          clearInterval(timer);
          return;
        }

        this.minutes = Math.floor(totalSeconds / 60);
        this.seconds = totalSeconds % 60;

        this.minutes = this.minutes.toString().padStart(2, "0");
        this.seconds = this.seconds.toString().padStart(2, "0");

        if (totalSeconds <= 0) {
          clearInterval(timer);

          this.showSwalErrorMessage(
            "Request Timeout",
            `You may navigate to your courses.`
          );
        } else {
          totalSeconds--;
        }
      }, 1000);
    },

    async createSession() {
      try {
        this.loading = true;
        const response = await window.NI.generateSessionId();

        if (response.session_id) {
          this.sessionId = response.session_id;
          document.getElementById("checkoutBtn").disabled = true;
          this.completePayment({
            serviceProvider: "ngenius",
            paymentReference: this.paymentReference,
            sessionId: response.session_id,
            action: "createorder",
          })
            .then((res) => {
              this.loading = false;
              if (res) {
                console.log(JSON.stringify(res));
                if (res.state.toLowerCase() == "await_3ds") {
                  this.orderReference = res.orderReference;
                  this.showOtpUI = true;
                  this.showCountdown(true);
                  this.mountOtpUI(res);
                  return;
                }
                this.showSwalErrorMessage(
                  "Transaction Failed",
                  `You may navigate to your courses.`
                );
              }
              // code
            })
            .catch((err) => {
              this.loading = false;
              console.log({ err });
              _this.showSwalErrorMessage(
                "Transaction Failed",
                "Some error occurred"
              );
            });
        }
      } catch (err) {
        this.loading = false;
      }
    },

    mountNgeniusGatewayUI(apiKey, outletReference) {
      // let showCheckoutBtn = false;
      ("use strict");
      window.NI.mountCardInput(
        "mount-id",
        /* the mount id*/
        {
          style: {},
          // Style configuration you can pass to customize the UI
          apiKey: apiKey,
          // API Key for WEB SDK from the portal
          outletRef: outletReference,
          // outlet reference from the portal
          // onSuccess: () => {
          //   this.isCredentialsCorrect = true;
          // },
          // Success callback if apiKey validation succeeds
          // onFail: () => {
          //   this.isCredentialsCorrect = false;
          // }, // Fail callback if apiKey validation fails
          onChangeValidStatus: function (_ref) {
            var isCVVValid = _ref.isCVVValid,
              isExpiryValid = _ref.isExpiryValid,
              isNameValid = _ref.isNameValid,
              isPanValid = _ref.isPanValid;

            if (isCVVValid && isExpiryValid && isNameValid && isPanValid) {
              console.log(isPanValid, isExpiryValid, isCVVValid, isNameValid);
              document.getElementById("checkoutBtn").disabled = false;
            } else {
              document.getElementById("checkoutBtn").disabled = true;
            }
          },
        }
      );

      this.showCountdown(false);
    },

    async mountOtpUI(paymentResponse) {
      var _this = this;

      ("use strict");
      window.NI.handlePaymentResponse(paymentResponse, {
        mountId: "3ds_iframe",
        style: {
          width: 500,
          height: 800,
        },
      })
        .then(async function (response) {
          console.log({ response });
          if (response.status.toLowerCase() == "purchased") {
            _this
              .completePayment({
                serviceProvider: "ngenius",
                paymentReference: _this.paymentReference,
                sessionId: _this.sessionId,
                action: "markpayment",
                orderReference: _this.orderReference,
              })
              .then((res) => {
                _this.loading = false;
                if (res) {
                  console.log(JSON.stringify(res));
                  if (res.status.toLowerCase() == "failed") {
                    _this.showSwalErrorMessage(
                      "Transaction Failed",
                      `${res.message} You may navigate to your courses.`
                    );
                  } else if (res.status.toLowerCase() == "success") {
                    _this.showSwalSuccessMessage(
                      "Transaction Successful",
                      `${res.message} You may navigate to your courses.`
                    );
                  }
                } else {
                  _this.showSwalErrorMessage(
                    "Transaction Failed",
                    "Some error occurred"
                  );
                }
                // code
              })
              .catch((err) => {
                _this.loading = false;
                console.log({ err });
                _this.showSwalErrorMessage(
                  "Some error occured",
                  "You may navigate to your courses."
                );
              });
          } else if (response.status.toLowerCase() == "error") {
            _this.showSwalErrorMessage(
              "Transaction Failed",
              `${response.error} You may navigate to your courses.`
            );
          } else {
            _this.showSwalErrorMessage(
              "Transaction Failed",
              `Some error occurred. You may navigate to your courses.`
            );
          }
        })
        .catch(() => {
          _this.showSwalErrorMessage(
            "Transaction Failed",
            `Some error occurred. You may navigate to your courses.`
          );
        });
    },

    showSwalErrorMessage(title, message) {
      Swal.fire({
        title: title,
        text: message,
        icon: "error",
        button: "Ok",
        dangerMode: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((confirm) => {
        if (confirm) {
          this.$router.push("/user/dashboard");
        }
      });
    },

    showSwalSuccessMessage(title, message) {
      Swal.fire({
        title: title,
        text: message,
        icon: "success",
        button: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((confirm) => {
        if (confirm) {
          this.$router.push("/user/dashboard");
        }
      });
    },
  },
  created() {},

  beforeRouteEnter(to, from, next) {
    window.NI = undefined;
    next();
  },

  mounted() {
    this.action = this.$route.query.action;
    console.log({ action: this.action });
    if (this.action && this.action.toLowerCase() == "postpayment") {
      let status = this.$route.query.status;
      let ordId = this.$route.query.ordId;
      let msg = this.$route.query.msg;
      msg = msg.replace(/\+/g, "%20");
      msg = decodeURIComponent(msg);
      if (status.toLowerCase() == "failure") {
        this.error = `${msg} - ${ordId}`;
      } else if (status.toLowerCase() == "success") {
        this.message = `${msg} - ${ordId}. You may navigate to your courses now to access the content.`;
        this.error = `Note: It may take few minutes before your payment is updated in our system. If you are unable to view your purchased course, please wait for few minutes and then refresh.`;
      }
    } else {
      let invoiceID = this.$route.params.id;
      if (invoiceID) {
        let invoice;
        if (this.invoicesByUserId && this.invoicesByUserId[this.user.id]) {
          invoice = this.invoicesByUserId[this.user.id].find(
            (inv) => inv.id == invoiceID
          );
        } else {
          this.fetchInvoiceByUserId(this.user.id).then(() => {
            invoice = this.invoicesByUserId[this.user.id].find(
              (invoice) => invoice.id == invoiceID
            );
          });
        }
        if (invoiceID == invoice.id) {
          // this.message = "Fetching payment details, please wait...";
          this.processPayment({
            invoiceId: invoiceID,
            serviceCode: "Ngenius",
            data: {
              redirectUrl: `${window.location.origin}/payment/Ngenius?action=postPayment`,
            },
          })
            .then(async (response) => {
              console.log({ response });
              this.paymentReference = response.data.paymentReference;
              this.ngeniusCdnScriptUrl = response.data.ngeniusCdnScriptUrl;

              let recaptchaScript = document.createElement("script");
              recaptchaScript.setAttribute("src", this.ngeniusCdnScriptUrl);
              document.head.appendChild(recaptchaScript);
              recaptchaScript.addEventListener("load", async () => {
                console.log("File loaded");
                await this.mountNgeniusGatewayUI(
                  response.data.apiKey,
                  response.data.outletReference
                );
              });
              console.log({ response });
            })
            .catch((error) => {
              console.log(error);
              this.error = "Some error occurred in payment process";
            });
        } else {
          this.error = "Invoice not found.";
        }
      } else {
        this.error = "Invalid parameters";
      }
    }
  },
};
</script>

<style scoped>
.payment-heading-div {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

#mount-id {
  height: 300px;
  background-color: #f0eaea;
  width: 55%;
  margin: auto;
  /* background-image: linear-gradient(to left, rgb(148 125 125 / 0%), rgb(190 228 255)); */
  border-radius: 50px;
  /* box-shadow: 6px 3px 6px #8c8c8c29; */
  padding: 30px;
}
</style>
